import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import CSEAITab from './CSEDepartmentTab';


const CseAIMl = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <CSEAITab/>
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Computer Science and Engineering in Artificial Intelligence and Machine Learning (CSE – AI & ML)
                                </h2>
                                <p>The Department of CSE is headed by Dr. A. Ravi Kumar Department of CSE (AI&ML) offers 4 year B.Tech programme. 

                                </p>
                                <p>Artificial Intelligence & Machine Learning is designed to make the students technically sound in emerging areas of Artificial Intelligence. The primary aim of the Dept of AI&ML is to forefront the advancement and novel developments in various application areas and promotion of the advanced research and education in the field of Artificial Intelligence.

                                </p>
                                <p>The department has skilled and dedicated faculty to teach specialized courses and elective courses like Machine Learning, Deep Learning, Robotics, etc.

                                </p>
                                <div class="apply-list border-0">
                                    The department is facilitated with:


                                    <ul className='pt-4'>
                                        <li><i class="ri-checkbox-circle-line"></i>State-of-the-art of Computer Labs
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Infrastructure with Advanced Laboratories
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Academic/Teaching-Learning and Research Infrastructure
                                        </li>

                                    </ul>
                                </div>
                                <p>Students of this programme can get promising work opportunities in the areas of Machine Learning and Artificial Intelligence, Big Data & AI Architect, Robotics and Software Engineering.

                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CseAIMl;

