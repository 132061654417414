import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';


const EEEAcademicSponser = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Academic/sponsored research and consultancy
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray bg-light">
                                                <td className='bg-light' align="center" valign="top">
                                                    <p>
                                                        <strong>Name ofthe faculty</strong>
                                                    </p>
                                                </td>
                                                <td className='bg-light' align="center" valign="top"><p><strong>Project Title</strong></p></td>
                                                <td className='bg-light' align="center" valign="top"><p><strong>Project Type Research/Consultancy</strong></p></td>
                                                <td className='bg-light' align="center" valign="top"><p><strong>Funding Agency</strong></p></td>
                                                <td className='bg-light' align="center" valign="top"><p><strong>Amount</strong></p></td>
                                                <td className='bg-light' align="center" valign="top"><p><strong>Duration</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Dr D Padmavathi (2019-20)
                                                </td>
                                                <td>Application of AI and IoT to Power systems including smart grid</td>
                                                <td>STTP</td>
                                                <td>AICTE</td>
                                                <td>Rs 278000</td>
                                                <td>1 year</td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    Dr A.G Dinesh Kumar
                                                    (2018-2019)
                                                </td>
                                                <td>Smart street lighting </td>
                                                <td>Consultancy</td>
                                                <td>Techniq Design Group</td>
                                                <td>Rs 30000</td>
                                                <td>1 year</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Dr D Padmavathi
                                                    (2019-2020)
                                                </td>
                                                <td>
                                                    Grid low voltage compensation using solar Power
                                                    And batteries
                                                </td>
                                                <td>Consultancy</td>
                                                <td>Techniq Design Group</td>
                                                <td>Rs 50000</td>
                                                <td>1 year</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Dr.D Padmavathi
                                                    (2019-2021)
                                                </td>
                                                <td>
                                                    Integration of EVs
                                                    to Electric Grid
                                                </td>
                                                <td>Consultancy</td>
                                                <td>
                                                    Eifer,
                                                    India
                                                </td>
                                                <td>Rs 100000</td>
                                                <td>2 years</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Dr A G Dinesh Kumar
                                                    (2019-2021)
                                                </td>
                                                <td>Impact of Electric vehicle charging on grid-reliability</td>
                                                <td>Consultancy</td>
                                                <td>
                                                    Eifer,
                                                    India
                                                </td>
                                                <td>Rs 100000</td>
                                                <td>2 years</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEEAcademicSponser
