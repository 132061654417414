import React, { useState } from 'react';
import Footer from './footer/Footer';
import Navbar from './navbar/Navbar';

const Login = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        if (email === 'Sridevi@123') {
            window.location.href='/NAAC-2021';
        } else {
            alert('Invalid Password.');
        }
    };

    return (
        <div>
            <Navbar />
            <div className="box contacts-form">
                <form onSubmit={handleSubmit} className='form-group'>
                    <label>Password:</label>
                    <input type="password" className='form-control' placeholder='Enter Password' value={email} onChange={(event) => setEmail(event.target.value)} />
                    <button type="submit" className='default-btn btn-sm btn-block w-100 mt-4 p-2'>Login</button>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default Login;
