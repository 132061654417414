import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Principal from '../About/Principal/principal.jpg'
import Elsevier from '../About/Principal/Elsevier SSRN 2021.jpg'
import Group from '../About/Principal/Group_img.jpg'
import Certificate from '../About/Principal/Certificate.jpg'
import PDoc from '../About/Principal/principal-profile.pdf'
import pdf from '../About/Principal/certificate_2020610165542.pdf'

const Prncipal = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-xl-2 order-lg-2 order-md-2 order-sm-1 order-1">
                            <div className="how-to-apply">
                                <h2>Principal</h2>
                                <img className='img-fluid pb-3' src={Principal} alt='principal' loading='lazy' />
                                <p><strong>Dr. A. Narmada</strong> assumed the role of Principal in 2023, bringing with her an extensive educational background. She earned her B.Tech from Nagarjuna University, followed by an M.E from Osmania University and a Ph.D from JNTU, Hyderabad. Throughout her career, Dr. A. Narmada has held diverse positions in various colleges, showcasing her versatility and expertise.</p>
                                <p>With over 26 years of experience in Teaching and Research projects, Dr. A. Narmada is recognized as an action-oriented and result-focused professional. Her expertise spans areas such as Wireless Sensor Networks, IoT protocols, and Microcontroller-based embedded system design. Beyond teaching, she has actively contributed to research and development, successfully completing 2 DST sponsored Research projects and a consultancy project.
                                </p>
                                <p>Dr. A. Narmada's scholarly achievements are noteworthy, with a publication record of 45 papers in SCI, International, National journals, and IEEE Conferences, including UGC care listed journals. Her intellectual contributions extend to the realm of patents, having secured 2 patent grants, and the publication of 11 E-Books.</p>
                                <p>The impact of her guidance is evident in the successful completion of Ph.D degrees by two research scholars under her guidance. Currently, she is actively guiding two more research scholars. Dr. A. Narmada's commitment to education and research is further highlighted by her role in organizing various outreach programs in collaboration with ISRO.</p>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href={PDoc} target='_blank'><i class="fa-solid fa-file-pdf"></i> Complete Profile Document </a>
                                                {/* <iframe src={PDoc} width="100%" height="500" className='pt-4'></iframe> */}
                                            </li>
                                            {/* <li>
                                                <a href="/assets/documents/BL Malleswari-Google scholor.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  Google scholar </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className='pt-2'>
                                    <p><strong>DR. LAKSHMI MALLESWARI BAROORU has been awarded for completed the online course on "Satellite Photogrammetry and its Application".</strong></p>
                                    

                                    <div class="accordion mb-3 mb-lg-4" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    Satellite Photogrammetry and its Application
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <embed src="/assets/documents/certificate_2020610165542.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" width="100%" height="515" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='pt-3'>
                                        <h6>Principal's meet participation on Sept 27th 2019
                                        </h6>

                                        <img className='img-fluid py-3' src={Certificate} alt='principal' loading='lazy' />
                                        <img className='img-fluid py-3' src={Group} alt='principal' loading='lazy' />
                                    </div>
                                    <div>
                                        <h6 className='py-3'>For receiving best Researcher award from Elsevier SSRN for the year 2021
                                        </h6>
                                        <img className='img-fluid pt-1' src={Elsevier} alt='principal' loading='lazy' />
                                    </div>
                                </div> */}


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Prncipal