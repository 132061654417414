import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Chairman from '../About/images/Chairman.jpg'
import ChairPerson from '../About/images/chairperson.png'
// import Secretary from '../About/images/secretary.jpg'
import Treasurer from '../About/images/Treasurer.jpg'
import Srinivas from '../About/images/Srinivas_Rao.jpg'
import Manjeera from '../About/images/Manjeera.jpg'
import Deepa from '../About/images/Deepa.jpg'
import Krishna from '../About/images/Krishna.jpg'

const Management = () => { 
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Management
                                </h2>
                                <p>Sridevi Women's Engineering College(SWEC) was established in the Academic year 2001-2002 with the approval of AICTE, New Delhi and with the recognition of Govt. of A.P under the affiliation of J.N.T.U.Hyderabad. SWEC is sponsored by Sridevi Educational Society, Hyderabad, which is registered (Reg.No:4973/97) under the Societies Registration Act in 1997, in furtherance of its objectives to fulfill the long felt educational needs of the state and the nation, has started this Institute from in Academic year 2001-02,with an annual intake of 180 students.
                                </p>
                                <p>The Institute, besides aiming at shaping its students into healthy and disciplined young citizens of character and culture, places emphasis on practical and professional experience so as to enable them to secure employment in industries immediately after completion of their studies and also become entrepreneurs. The Institute is developing close contact with a large number of industries situated in and around Hyderabad for mutual benefit.
                                </p>

                                <div className="apply-list">
                                </div>
                                <div className="submit">
                                    

                                    <img width="150" src={Chairman} alt='ChairmanImg' className='my-3 me-4 Img-fluid float-start' loading='lazy' />
                                    <h4 className='pt-4 pb-2'>Founder
                                    </h4>
                                    <h5>Late Sri. K. Ashoka Rao | B.A.,L.L.B.
                                    </h5>
                                    <div className='pt-3'>
                                        <p>Our Chairman and Founder Late. Shri Ashok Rao was a visionary and a noble person. He was a lawyer by profession. He established this institute with a vision to educate and promote girl child education. The main objective of our engineering college is to integrate class room learning with practical work experience in the industry.

                                        </p>
                                        <p>He strongly believed that education is fundamental to the development and growth of any individual.He dreamt of educating and empowering women, who play a crucial role in moulding the character of a child and lay the foundation to morals and values that a child carries throughout it's life. Shri.Ashok Rao always said that the drivers of our future are the engineers and the inventions that will simplify our lives and improve the quality of our life. He wanted to unleash this potential of the students and channelize it in the right direction through SWEC.

                                        </p>
                                        <p>For him, personal development was just as important as professional development.He believed that each student comes with a different set of experiences, values, needs, goals and ideas that differentiate them from each other.

                                        </p>
                                        <p>He stressed the importance of having dedicated, sincere and knowledgeable faculty who put their heart and soul in grooming the student abilities, skills and talent. He continues to inspire all of us to work with a common goal of "making a difference" in the lives of the student not only academically but also personally.

                                        </p>
                                        <p>At Sridevi, we try to tap this potential and help students realize their worth and capabilities. We train our students in soft skills that are now critical to the succeed in the corporate world and make them employable and industry ready.

                                        </p>
                                        <p>The spirit and teamwork of our institution is exemplary and has been the key driver of the development and growth of our institution in the last 16 years.We promise to continue his legacy of success and make a mark in the field of education.

                                        </p>
                                    </div>
                                </div>
                                <div className="submit">
                                    <img height="150" src={ChairPerson} alt='ChairpersonImg' className='my-3 me-4 Img-fluid float-start' loading='lazy' />
                                    <h4 className='pt-3 pb-2'>Chair Person
                                    </h4>
                                    <h5>Smt. K. Jyothi Devi

                                    </h5>
                                    <div className='pt-3'>
                                        <p>We at our institute believe that learning is a life long process and encourage learning in every form. Simply put, we believe engineers create, design and provide solutions to day to day problems. We encourage research and entrepreneurship and hence started emphasizing on practical knowledge in addition to expertise in the subject.

                                        </p>
                                        <p>We want every student of our college to stand on her own, confident enough to lead an independent life while contributing her bit of the society. Inventiveness, flexibility, adaptability and soft skills are the drivers of the future employability which we strive to inculcate these skills in the students in the four years. Education is an empowering process that guides students to develop their passions, critical thinking, compassion, and wisdom for timely action.</p>

                                    </div>
                                </div>
                                <div className="submit">
                                    

                                    <img height="150" src='/assets/images/governing-images/secretary.jpeg' width={100} alt='ChairpersonImg' className='my-3 me-4 Img-fluid float-start' loading='lazy' />
                                    <h4 className='pt-3 pb-2'>Secretary 
                                    </h4>
                                    <h5>Dr. K. Radha Kishan Rao | M.D.
                                    </h5>
                                    <div className='pt-3'>
                                        <p>“Give a man a fish and you feed him one day, teach him how to catch fishes and you feed him for a lifetime.” We at Sridevi believe that if you teach a student a skill, you enable him for a lifetime.Albert Einstein once said “I never teach my pupils, I only provide the conditions in which they can learn”. Our institute follows this and has always provided the best infrastructure and facilities for better learning.

                                        </p>
                                        <p>We strive to impart intellectual vision, aesthetic environment and practical power to the students. We ensure that students are not confined to simply memorizing their lessons in rote learning, but rather exposed to learning while doing which leads better understanding of the engineering concepts.The various internships and projects add value in terms of practical knowledge and hands on experience. We provide knowledge and expose students to the latest technology in the form of workshops and incubation labs.

                                        </p>
                                        <p>The prime thrust of our institute is the total transformation of the individual through education and skill building that creates entrepreneurs, scientists and thinkers who are capable of changing our future. </p>

                                    </div>
                                </div>
                                <div className="submit">
                                    <div className='pt-3'>
                                        <div className='row'>
                                            <div className='col-6 col-lg-4 text-center pb-3'>
                                                <img width="150" src={ChairPerson} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                                <div className=' managementText'> 
                                                    <h6>Srmt. K.Jyothi Devi
                                                         </h6>
                                                    <p>Chair Person</p>
                                                </div>

                                            </div>
                                            <div className='col-6 col-lg-4 text-center pb-3'>
                                                <img style={{height:"170px", width:"46%"}} src='/assets/images/governing-images/secretary.jpeg' alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                                <div className=' managementText'> 
                                                    <h6>Dr.K.Radha Kishan Rao, M.D.
                                                         </h6>
                                                    <p>Secretary </p>
                                                </div>

                                            </div>
                                            <div className='col-6 col-lg-4 text-center pb-3'>
                                                <img width="150" src={Treasurer} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                                <div className=' managementText'> 
                                                    <h6>Dr.Madiraju Sridevi

                                                         </h6>
                                                    <p>Member</p>
                                                </div>

                                            </div>
                                            <div className='col-6 col-lg-4 text-center pb-3'>
                                                <img width="150" src={Srinivas} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                                <div className=' managementText'> 
                                                    <h6>Dr.K.P.Srinivasa Rao. M. S.
                                                         </h6>
                                                    <p>Member</p>
                                                </div>
                                            </div>
                                            <div className='col-6 col-lg-4 text-center pb-3'>
                                                <img width="150" src='/assets/images/governing-images/Dr. K Manjeera - Member.jpeg' alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                                <div className=' managementText'> 
                                                    <h6>Dr.K.Manjeera

                                                         </h6>
                                                    <p>Member</p>
                                                </div>
                                            </div>
                                            <div className='col-6 col-lg-4 text-center pb-3'>
                                                <img width="150" src={Deepa} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                                <div className=' managementText'> 
                                                    <h6>Dr.K.Deepa


                                                         </h6>
                                                    <p>Member</p>
                                                </div>
                                            </div>
                                            <div className='col-6 col-lg-4 text-center pb-3'>
                                                <img width="150" src={Krishna} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                                <div className=' managementText'> 
                                                    <h6>Dr.Krishna Prasad M.D


                                                         </h6>
                                                    <p>Member</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Management
