import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const EngineeringEducatorsAwardsNews = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>Engineering Educators Awards
                                    </h2>
                                    <div className='pb-2'>
                                        <p>Trinity College Dublin and Education Matters presented the <strong>"ENGINEERING EDUCATORS AWARDS"</strong> on 26th February 2019 at the Taj Deccan Hotel, Synergy Banquette Hall to our Toppers in each department and also to the College for its contribution in the field of Technical Education.
                                        </p>
                                        <p>
                                        Shri Navin Mittal, IAS, Commissioner, Collegiate Education & Technical Education Government of Telangana and Professor. V. Venkata Ramana, Vice Chairman , Telangana State Council of Higher Education Government of Telangana were the CHIEF GUESTS for the ceremony.
                                        </p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Education Matters/Education Matters - SWEC received award for  Academic Excellence.jpg">
                                                    <img src="/assets/img/gallery/Education Matters/Education Matters - SWEC received award for  Academic Excellence.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Education Matters/Education Matters - SWEC received award for  Academic Excellence.jpg">
                                                    <img src="/assets/img/gallery/Education Matters/Education Matters - SWEC Toppers Felicitation.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Education Matters/EM Guest - IAS officer - Mr Navin Mittal.jpg">
                                                    <img src="/assets/img/gallery/Education Matters/EM Guest - IAS officer - Mr Navin Mittal.jpg" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Education Matters/Education Matters - Our College received award for  Academic Excellence.jpg">
                                                    <img src="/assets/img/gallery/Education Matters/Education Matters - Our College received award for  Academic Excellence.jpg" />
                                                </a>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default EngineeringEducatorsAwardsNews
