import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import JntuhLefttab from './JntuhLefttab';

const HolidaysList = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-5'>
                            <JntuhLefttab />
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="how-to-apply">
                                <h2>Holidays list

                                </h2>
                            </div>
                            <div className='pt-2 pt-lg-3'>

                                <div class="detgails-right-content ">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="https://ik.imagekit.io/ckjdgz4vmo/JNTUH_Holiday%20List_2023.pdf?updatedAt=1696850945194" target='_blank'><i class="fa-solid fa-file-pdf"></i>Holiday list 2023    </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/generalholidaysandoptionalholidays2021.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>Holiday list 2021    </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/GeneralHolidaysOptionalHolidaysfortheyear2020.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  Holiday list 2020   </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/holiday.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> Holiday list 2019    </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default HolidaysList
