import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';

const ItVision = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Information Technology (IT)
                                </h2>
                                <div className='pt-3'>
                                    <div class="submit">
                                        <h5><i class="fa fa-eye text-red"></i> Vision</h5>
                                        <div class="pt-2">
                                            <p>
                                                To produce industry ready IT professionals having global competence, sound knowledge, soft skills required to excel and succeed at work.
                                            </p>

                                        </div>
                                    </div>

                                    <div class="">
                                        <h5><i class="fa fa-bullseye  text-red"></i> Mission</h5>
                                        <div class="pt-2">
                                            <div class="apply-list ">

                                                <ul class="pt-2">
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                    To indulge efficient IT professionals by providing quality education using innovative and practical teaching methodology.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                    To provide conducive to learning environment to enhance innovation, problem solving skills, leadership qualities, team spirit & ethical values.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                    Developing and inculcating a spirit of entrepreneurship through sustained efforts.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-4'>
                                        <h5>Programme Educational Objectives (Peo's)</h5>
                                        <div class="apply-list ">
                                            <ul class="pt-2">
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                   <strong>PEO-I : </strong> To acquire Mathematical, Scientific and Soft skills required for professional development.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                <strong>PEO-II :  </strong> Applying knowledge of societal impacts of information technology in the course of their career related activities ethically and appropriately
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                <strong>PEO-III : </strong> To instill students to know environmental issues, managerial skills and quality control that is required to maintain successful entrepreneurs in the IT industries
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                <strong>PEO-IV : </strong> To promote lifelong self-learning ability to remain effective for solving global issues
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default ItVision
