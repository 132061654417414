import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import SLeftTab from './SLeftTab';


const Svision = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <SLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Master of Business Administration (MBA)
                                </h2>
                                <div className='pt-3'>
                                    <div class="submit">
                                        <h5><i class="fa fa-eye text-red"></i> Vision</h5>
                                        <div class="pt-2">
                                            <p>
                                            To attract the finest talent by creating an atmosphere conducive to learning and to train and empower female professionals with global skills thereby assigning their legitimate place of honour in the society.
                                            </p>

                                        </div>
                                    </div>

                                    <div class="">
                                        <h5><i class="fa fa-bullseye  text-red"></i> Mission</h5>
                                        <div class="pt-2">
                                            <div class="apply-list ">

                                                <ul class="pt-2">
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                    To create a center for excellence in engineering by imparting knowledge and skills.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                    To create a center for excellence in engineering by imparting knowledge and skills.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                    To create a conducive work environment with an equally competent and experienced team that derives strength from each other.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Svision
