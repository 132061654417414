import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const HigherEducationseminar = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2>
                                        Higher Education seminar
                                    </h2>
                                    <div className='pb-2'>
                                        <h6 className='
                                        '>Seminar by Manhattan Review about Rowen University opportunities</h6>
                                        <p>Interested students from SWEC attended this seminar in Nov 2019 conducted by Manhattan Review

                                        </p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/rowan-university.jpg">
                                                    <img src="/assets/img/gallery/rowan-university.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default HigherEducationseminar
