import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';


const CSEAcademicSponser = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Academic/sponsored research and consultancy
                                </h2>
                                <h6 className='pt-3 pb-2'>Consultancy Project
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray bg-light">
                                                <td className='bg-light'><p class="text-center"><strong>COORDINATOR </strong></p></td>
                                                <td className='bg-light'><p><strong>PROJECT TITLE </strong></p></td>
                                                <td className='bg-light'><p><strong>FUNDING AGENCY </strong></p></td>
                                                <td className='bg-light'><p><strong>AMOUNT IN LAKHS </strong></p></td>
                                                <td className='bg-light'><p><strong>DURATION </strong></p></td>
                                            </tr>
                                            <tr>
                                                <td>DR V ANANTHA KRISHNA </td>
                                                <td>CONSUMER ATTITUDES TOWARDS INSURANCE PRODUCTS </td>
                                                <td>BRAINOVISION SOLUTION INDIA PVT LTD</td>
                                                <td>3.90</td>
                                                <td>0.9 YEARS</td>
                                            </tr>
                                            <tr>
                                                <td>DR K C RAVIKUMAR</td>
                                                <td>E-LEARNING PORTAL DESIGN</td>
                                                <td>COIGN CONSULTANT PVT LTD</td>
                                                <td>3.05</td>
                                                <td>0.8 YEARS</td>
                                            </tr>
                                            <tr>
                                                <td>DR V ANANTHA KRISHNA</td>
                                                <td>PERFORMANC E MANAGEMEN T SYSTEM</td>
                                                <td>BRAINOVISION SOLUTION INDIA PVT LTD</td>
                                                <td>3.10</td>
                                                <td>2 YEARS</td>
                                            </tr>
                                            <tr>
                                                <td>DR A GAUTAMI LATHA</td>
                                                <td>ONLINE CONSULTANCY EDUCATION IN ABROAD</td>
                                                <td>COIGN CONSULTANT PVT LTD</td>
                                                <td>4.40</td>
                                                <td>2 YEARS</td>
                                            </tr>
                                            <tr>
                                                <td>DR M RAMASUBRAM ANIAM</td>
                                                <td>IOT BASED HEALTH CARE DESIGN</td>
                                                <td>PANTECH PROED PVT LTD</td>
                                                <td>1.80</td>
                                                <td>2 YEARS</td>
                                            </tr>
                                            <tr>
                                                <td>DR TKS RATHISH BABU</td>
                                                <td>DESIGNING A MACHINE LEARNING MODEL FOR EARLY DETECTION OF CHRONIC KIDNEY DISEASE</td>
                                                <td>S CUBE FUTURE TECHNOLOGIES</td>
                                                <td>1.40</td>
                                                <td>2 YEARS</td>
                                            </tr>
                                            <tr>
                                                <td>DR TKS RATHISH BABU</td>
                                                <td>E-COMMERCE PORTAL FOR REAL ESTATE INDUSTRY</td>
                                                <td>TRU PROJECTS EDUCATIONAL SERVICES PRIVATE LTD</td>
                                                <td>3.95</td>
                                                <td>2.2 YEARS</td>
                                            </tr>
                                            <tr>
                                                <td>DR A GAUTAMI LATHA</td>
                                                <td>CHARACTERISI NG AND PREDICTING EARLY REVIEWWERS FOR EFFECTIVE PRODUCT MARKETING ON ECOMMERCE WEBSITES</td>
                                                <td>CLOUD RAIZE IT SERVICES PVT LTD</td>
                                                <td>3.70</td>
                                                <td>2 YEARS</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default CSEAcademicSponser
