import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DpeLeftTab from './DpeLeftTab';



const DpeHod = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DpeLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>HOD Profile
                                </h2>
                                <div className=' pb-4 pb-lg-5'>
                                    <p>The Director of Physical Education, <strong>Mr. Saibaba</strong> is the chief organizer with more than 15 years of experience in various organizations. The Director of Physical Education of the sports event conducts and supervises various events throughout the year with the support of department faculty sports coordinators.
                                    </p>
                                    <p> Under his guidance and training, we have two teams each for Kabaddi, Throwball, Volley ball, Kho-Kho, Tennicoit, Chess, Carrom, Table tennis, Badminton and Basketball. Our college sports team participate in all events and have won several trophies in the inter college competitions. We also encourage our faculty to participate in various sports and inter college faculty competitions.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default DpeHod
