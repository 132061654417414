import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';

const ECEworkshops = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Workshop and Seminar
                                </h2>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/ece/Workshops and Seminars in ECE.docx" target='_blank'><i class="fa-solid fa-download"></i>  Workshop and Seminar </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Number of workshops/seminars conducted on Intellectual Property Rights (IPR) and Industry-Academia Innovative practices during the last five years
                                </p>
                                <div className='mt-4'>
                                    <h6 className='pb-2'>FPDs/STTPS/Workshops Attended by ECE Faculty</h6>
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered">
                                            <thead class="bg-gray">
                                                <tr>
                                                    <th><strong>S.No</strong></th>
                                                    <th><strong>Academic Year </strong></th>
                                                    <th><strong>No. of FDP’s </strong></th>
                                                    <th><strong>No. of Participants  </strong></th>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>2020-2021 </td>
                                                    <td>19 </td>
                                                    <td>42 </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>2019-2020 </td>
                                                    <td>7</td>
                                                    <td>52</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>2018-2019 </td>
                                                    <td>6</td>
                                                    <td>50</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>2017-2018 </td>
                                                    <td>3</td>
                                                    <td>11</td>
                                                </tr>
                                                <tr>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className='mt-4'>
                                    <h6 className='pb-2'>List of FPDs/STTPS/Workshops Organized by ECE Department</h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead class="bg-gray">
                                                <tr>
                                                    <th><strong>Type of Event </strong></th>
                                                    <th><strong>Title </strong></th>
                                                    <th><strong>Date/Duration</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td colspan="3" valign="top" >
                                                        <p align="center" ><b><span>2020-2021<span ></span></span></b></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p >
                                                            <b>
                                                                <span>
                                                                    60th IIRS-ISRO Outreach Programme
                                                                    (FDP)<span ></span>
                                                                </span>
                                                            </b>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <strong>
                                                                <span>
                                                                    Application
                                                                    of Geoinformatics in Ecological Studies
                                                                </span>
                                                            </strong><strong><span ></span></strong>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span>
                                                                13-07-2020
                                                                to 24-07-2020
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p >
                                                            <b>
                                                                <span>
                                                                    61st IIRS-ISRO Outreach Programme
                                                                    (FDP)<span ></span>
                                                                </span>
                                                            </b>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><strong><span>Satellite Photogrammetry and its Applications</span></strong><strong><span ></span></strong></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>29-06-2020 to 03-07-2020</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p >
                                                            <b>
                                                                <span>
                                                                    62nd IIRS-ISRO Outreach Programme
                                                                    (FDP)<span ></span>
                                                                </span>
                                                            </b>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <strong>
                                                                <span>
                                                                    Geospatial
                                                                    Inputs for Enabling Master plan Formulation
                                                                </span>
                                                            </strong><strong><span ></span></strong>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span>
                                                                27-07-2020
                                                                to 31-07-2020
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p ><b><span>65th IIRS-ISRO Outreach Programme<span ></span></span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><strong><span>Remote Sensing and Digital Image Analysis</span></strong><strong><span ></span></strong></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>17-08-2020 to 11-09-2020</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p ><b><span>68th IIRS-ISRO Outreach Programme</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <strong>
                                                                <span>
                                                                    Geographical
                                                                    Information System
                                                                </span>
                                                            </strong><strong><span ></span></strong>
                                                        </p>
                                                        <p ><strong><span >&nbsp;</span></strong></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span>
                                                                28-09-2020
                                                                to
                                                            </span>
                                                        </p>
                                                        <p ><span>15-10-2020</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p ><b><span>71st IIRS-ISRO Outreach Programme</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><strong><span>Advances in SAR-Polarimetry &amp; Interferometry</span></strong><strong><span ></span></strong></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>14-12-2020 to </span></p>
                                                        <p ><span>18-12-2020</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p ><b><span>FDP</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <strong>
                                                                <span>
                                                                    Cyber
                                                                    Security
                                                                </span>
                                                            </strong><b><span></span></b>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>04-05-2020</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p ><b><span>Workshop</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><strong><span>Angular JS (to develop web apps-faster)</span></strong><b><span></span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>26-06-2020</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p ><b><span>FDP</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><strong><span>Arduino</span></strong><b><span></span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span>
                                                                04-08-2020
                                                                to 10-08-2020
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" >
                                                        <p ><b><span>FDP</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <strong>
                                                                <span>
                                                                    Recent Trends in Electronics and Communications for Teaching Learning
                                                                    and Research
                                                                </span>
                                                            </strong><b><span></span></b>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>12-08-2020 to 18-08-2020</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td colspan="3" valign="top" >
                                                        <p align="center" ><b><span>2019-2020</span></b></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><b><span>FDP</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><strong><span>Outcome Based Education</span></strong><strong><span ></span></strong></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span>11-03-</span><span>201</span><span>9</span><span> </span><span>to</span><span>
                                                            </span><span>16-03-</span><span>201</span><span>9</span><span></span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td colspan="3" valign="top" >
                                                        <p align="center" ><b><span>2018-2019</span></b><b><span></span></b></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><b><span>FDP</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><strong><span>National level Faculty development workshop on IPR</span></strong><strong><span ></span></strong></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>15-07-2018</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><b><span>Workshop</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <strong>
                                                                <span>
                                                                    Virtual
                                                                    reality in electronic manufacturing
                                                                </span>
                                                            </strong><strong><span ></span></strong>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>28-01-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><b><span>Workshop</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <strong>
                                                                <span>
                                                                    Intellectual Property Laws for the Traditional and Internet Based
                                                                    Businesses
                                                                </span>
                                                            </strong><strong><span ></span></strong>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>26-04-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><b><span>FDP</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <strong>
                                                                <span>
                                                                    Outcome Based
                                                                    Education
                                                                </span>
                                                            </strong><strong><span ></span></strong>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>01-10-2018 to 06-10-2018</span><span></span></p>
                                                        <p ><span>&nbsp;</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td colspan="3" valign="top" >
                                                        <p align="center" ><b><span>2017-2018</span></b><b><span></span></b></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><b><span>Workshop<span ></span></span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <strong>
                                                                <span>
                                                                    WebVR-Virtual
                                                                    Reality
                                                                </span>
                                                            </strong><strong><span ></span></strong>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>24-03-2018</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><b><span>FDP</span></b></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><strong><span>Advanced Communication and Signal processing</span></strong><strong><span ></span></strong></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>7-05-2018 &nbsp;to </span></p>
                                                        <p ><span>12-05-2018</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="table-responsive mt-3">
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th width="65"><strong>S.No</strong></th>
                                                <th><strong>Name of the workshop/ seminar</strong></th>
                                                <th width="220"><strong>Date</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td> Workshop on 'Intellectual Property Laws for the Traditional and Internet Based Businesses' </td>
                                                <td>26-04-2019</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td> Workshop on virtual reality in electronic manufacturing </td>
                                                <td>28-01-2019 </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td> Intellectual Property Rights </td>
                                                <td>05-12-2018 to 06-12-2018</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td> Seminar on role of electronics in modern industry </td>
                                                <td>11-09-2018 </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td> National level Faculty development workshop on IPR </td>
                                                <td>15-07-2018 </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td> Seminar on the product designing using electronics and embedded systems </td>
                                                <td>23-08-2018</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td> Technical Talk on Electronics Prototyping </td>
                                                <td>12-05-2018 </td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td> Industrial Expectations from Graduate Engineers </td>
                                                <td>02-04-2018</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td> Workshop on WebVR-Virtual Reality </td>
                                                <td>24-03-2018</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td> Seminar on innovative idea on electronic conducting polymers </td>
                                                <td>19-01-2018</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td> Technical Talk on Applications of Signals and Systems </td>
                                                <td>16-05-2017 </td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td> Awareness Workshop on Intellectual Property Rights </td>
                                                <td>26-04-2017</td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td> Workshop on Quadcopter </td>
                                                <td>14-02-2017 to 15-02-2017 </td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td> Seminar on embedded systems for aeronatical and allied sectrors </td>
                                                <td>07-01-2017 </td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td> Workshop on fundamentals of analog VLSI Design </td>
                                                <td>18-08-2016</td>
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td> Intel Galileo - Hands-on embedded system </td>
                                                <td>08-08-2016 </td>
                                            </tr>
                                            <tr>
                                                <td>17</td>
                                                <td> Seminar on IPR for Start Ups and Entrepreneurs in Software &amp; IT Enabled Industries </td>
                                                <td>15-07-2016</td>
                                            </tr>
                                            <tr>
                                                <td>18</td>
                                                <td> A Two day workshop on advanced in VLSI Design and Embedded System </td>
                                                <td>23-08-2016</td>
                                            </tr>
                                            <tr>
                                                <td>19</td>
                                                <td> Seminar on wireless technology for embedded system </td>
                                                <td>15-09-2016 </td>
                                            </tr>
                                            <tr>
                                                <td>20</td>
                                                <td> Seminar on Role of Intellectual Property Rights (IPR) in Technology Transfer </td>
                                                <td>26-05-2016 </td>
                                            </tr>
                                            <tr>
                                                <td>21</td>
                                                <td> Exploring Patent, Designs, Trademarks and Copyrights Studies and Opportunities </td>
                                                <td>10-03-2016</td>
                                            </tr>
                                            <tr>
                                                <td>22</td>
                                                <td> Industrial Automation and Advances in Technology Systems </td>
                                                <td>08-03-2016</td>
                                            </tr>
                                            <tr>
                                                <td>23</td>
                                                <td> Intellectual property rights challenges and issues </td>
                                                <td>26-12-2015</td>
                                            </tr>
                                            <tr>
                                                <td>24</td>
                                                <td> Analog Instruments &amp; PCB Soldering </td>
                                                <td>08-09-2015 </td>
                                            </tr>
                                            <tr>
                                                <td>25</td>
                                                <td> Strategies for IPR and Plagiarism Risks </td>
                                                <td>02-04-2015 to 03-04-2015 </td>
                                            </tr>
                                            <tr>
                                                <td>26</td>
                                                <td> Design Awareness Seminar in VLSI </td>
                                                <td>01-03-2015 </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='mt-3'>
                                    <h6 className='pb-2'> ECE Department List of Events Conucted
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr class="bg-gray">
                                                    <th width="250"><strong>Type of Event</strong></th>
                                                    <th width="350"><strong>Title</strong></th>
                                                    <th><strong>Date/Duration</strong></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>FDP</td>
                                                    <td><strong>Cyber Security</strong></td>
                                                    <td>04-05-2020 </td>
                                                    <td><a href="/assets/documents/ECE FDP_MAY2020-01.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>Faculty Webinar</td>
                                                    <td><strong>Drone Applications on IOT(IEEE Blended Learning)</strong></td>
                                                    <td>04-06-2020 </td>
                                                    <td><a href="/assets/documents/ECE FACULTY WEBINAR_JUN 2020-01.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>Faculty Webinar</td>
                                                    <td><strong>Robotic Process Automation</strong></td>
                                                    <td>09-06-2020</td>
                                                    <td><a href="/assets/documents/ECE FACULTY WEBINAR_JUN 2020-02.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>Faculty Workshop</td>
                                                    <td><strong>Angular JS( to develop web apps-faster)</strong></td>
                                                    <td>26-06-2020</td>
                                                    <td><a href="/assets/documents/ECE  FACULTY WORKSHOP_JUN2020_01.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>61st IIRS-ISRO Outreach Programme(FDP)</td>
                                                    <td><strong>Satellite Photogrammetry and its Applications</strong></td>
                                                    <td>29-06-2020 to 03-07-2020</td>
                                                    <td><a href="/assets/documents/ECE FDP_JUN2020_01.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>60th IIRS-ISRO Outreach Programme (FDP) </td>
                                                    <td><strong>Application of Geoinformatics in Ecological Studies</strong></td>
                                                    <td>13-07-2020 to 24-07-2020 </td>
                                                    <td><a href="/assets/documents/ECE FDP_JULY2020_01.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>62nd IIRS-ISRO Outreache Programme(FDP) </td>
                                                    <td><strong>Geospatial Inputs for Enabling Master plan Formulation</strong></td>
                                                    <td>27-07-2020 to 31-07-2020 </td>
                                                    <td><a href="/assets/documents/ECE FDP_JULY2020_02.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>SDP </td>
                                                    <td><strong>Python</strong></td>
                                                    <td>27-07-2020 to 31-07-2020 </td>
                                                    <td><a href="/assets/documents/ECE SDP_JULY2020_01.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>FDP </td>
                                                    <td><strong>Arduino</strong></td>
                                                    <td>04-08-2020 to 10-08-2020 </td>
                                                    <td><a href="/assets/documents/ECE FDP_AUG2020_01.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>FDP </td>
                                                    <td><strong>Recent Trends in Electronics and Communications for Teaching Learning and Research</strong></td>
                                                    <td>12-08-2020 to 18-08-2020</td>
                                                    <td><a href="/assets/documents/ECE FDP_AUG2020-02.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                                <tr>
                                                    <td>65th IIRS-ISRO Outreach Programme </td>
                                                    <td><strong>Remote Sensing and Digital Image Analysis</strong></td>
                                                    <td>17-08-2020 to 11-09-2020 </td>
                                                    <td><a href="/assets/documents/ECE SDP_AUG2020_01.pdf" target="_blank"><i class="fa fa-file-pdf text-red"></i> PDF</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <h6 className='pb-1'>Department of Electronics and Communication Engineering (ECE)
                                    </h6>                   
                                    <p> The <strong class="text-primary"> Department of Electronics and Communication Engineering</strong> was started in 2001 with an intake of 60 and subsequently the department has upgraded its sanctioned intake to 240.</p>
                                    <p> The Department of ECE is headed by<strong class="text-primary"> Dr.Narmada Alaparthi,</strong> under her leadership, the department is strengthened by well experienced and highly qualified teaching faculty and non-teaching staff. The Department has good infrastructure with spacious classrooms, well equipped laboratories, seminar hall, and staff room with individual staff cabins, HOD chamber, department library, centralized internet centre and R &amp; D centre. </p>
                                    <p> The department has 180 computers, installed with the required software (MENTOR GRAPHICS TOOLS, MATLAB, MASM, XILINX, MULTISIM, LABVIEW etc). All the students and staff of the department are facilitated with a high-speed Internet facility of 10 Mbps round the clock. </p>
                                    <p>The department is equipped with well-established laboratories with advanced software and hardware in order to help our students to develop the technical skills needed to bridge the curricular gap</p>
                                    <p>Many students of ECE Department are placed in Top MNCs like IBM, Tech Mahindra, Infosys, Wipro, Microsoft, Oracle, HSBC, HCL, Syntel and so on. Department encourages students and staff to present and publish the papers, research articles in reputed international / national conferences and journals.</p>
                                    <p> Apart from this the Students of ECE Department actively participate through department Association ELECTRO SPARKZ in achieving remarkable appreciations &amp; Prizes in Co-curricular activities like Paper Presentations, Project Expos, National Level Symposiums, Internship Programs, Sports and other cultural activities in numerous engineering colleges. They also participate in social activities like NCC (National Cadet Corp) Village Campaigns, Blood Donation Camps and Environmental Awareness Programs. </p>
                                    <p>We welcome student aspirants and their parents to visit the SWEC Campus and Department of ECE to observe abundant resources with pleasant Educational atmosphere.</p>
                                    <p>The department Library contains 201 books. During these 16 years of College history, the students of ECE have performed well in their Academics by securing good pass percentage and owning University Top Ranks.</p>
                               
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ECEworkshops
