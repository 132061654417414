import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import SLeftTab from '../SandH/SLeftTab';

const SandH = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <SLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>
                                    Department of Science & Humanities
                                </h2>

                                <p>
                                    The department of Science & Humanities was established with the aim of imparting fundamental knowledge to the students pursuing various courses of B. Tech first year.
                                </p>
                                <p>The Department of Science and Humanities is headed by <strong>Dr. Mr. K.C.Ravi Kumar</strong>

                                </p>
                                <p>The Science and Humanities Department is one of the most successful and multi - faceted Departments. It is housed in a separate building with excellent classrooms, laboratory facilities and a conference hall with excellent acoustics. The Department imparts in-depth knowledge on a wide range of subjects namely Mathematics, Physics, Chemistry, Engineering Graphics, Engineering Mechanics, C Programming, Basic Electrical & Electronics Engineering and English. Essential communication skills are developed and polished in this Department. The Department develops in each student, a mastery of the fundamentals, the capacity to think analytically, motivation for learning, an intellectual discipline and self-reliance that are the cornerstones for continuing professional achievement.

                                </p>
                                <p>The main objective of the department is to make the students to understand the basic concepts of Mathematics, Science, and Language, so as to enable them to improve their aptitude skills.

                                </p>
                                <p>Programs on creativity on project demonstrations, personality development, career development and counseling are arranged by inviting eminent resource persons.

                                </p>
                                <h5 className='py-3'>
                                    Various Desciplines of Science and Humanities

                                </h5>
                                <p> The department comprises of nine disciplines namely Professional Communication in English, Mathematics, Applied Physics, Chemistry, Engineering drawing, Computer programming C, BEEE, Engineering Mechanics, Engineering Graphics and Environmental science.

                                </p>
                                <p> The objective of S&H dept is to provide a strong foundation in basic sciences & communication skills for the students.

                                </p>
                                <p>The Dept has experienced faculty members who are highly qualified & knowledgeable in their concerned subjects. They help the students to understand the subject and gain knowledge. They also help them to be a responsible student by molding their behavior with moral, intellectual and ethical values. The S &H department lays the foundation of the students by grooming them in their respective core subjects.

                                </p>
                                <p>The Professional Communication in English helps the students improve their listening, speaking, reading and writing (LSRW) skills needed in today's global workplaces with essential vocabulary and grammar.

                                </p>
                                <p>The English Department has well equipped call lab to train students in pronunciation & activity lab where they help the students in their communication skills through activities like Roleplay, JAM, Debates and Group discussions. They improve their writing skills by conducting essay writing competitions.

                                </p>
                                <p>Activity hour helps the students to build up their confidence levels during presentations & improve their soft skills to contest for global opportunities & imbibe leadership qualities.

                                </p>
                                <p>Mathematics is the mother of all science and governs the world."The Engineer builds the Mathematical models-the mathematician solves them". Almost all branches of engineering rely on mathematics as a language of description and analysis. Engineering is the application of Mathematics.

                                </p>
                                <p>Physics is the backbone of Engineering and Technology. Technically, Engineering has applied physics. All the branches of Engineering Electrical, Electronics, Mechanical, and computers are all derivatives of Physics. It bridges the gap between theoretical science and practical Engineering through enhanced understanding of basic laws of nature. All the advancements in new technologies arise from theoretical breakthroughs in physics.The physics lab helps the students to develop experimental skills to design new experiments in engineering to compare the theory and correlate with experiment.

                                </p>
                                <p>Chemistry is the branch of science that deals with composition and properties of substances which helps to produce new chemical compounds. Now-adays chemistry is greatly involved in the engineering field.

                                </p>
                                <p>Engineering Graphics is a dimensional representation of a three-dimensional object. Engineering graphics produces the drawing documents. It is a graphical language that communicates ideas and information from one mind to another. Engineering Graphics mainly deal's with geometrical drawing & represents the geometrical objects on a drawing sheet.

                                </p>
                                <p>Computer programming is one of the basic subjects that covers the basic computer language called 'C'. The 'C' language helps to develop programs and is a platform to learn other computer languages. The CP lab empowers the student to practice programs using 'C' language.

                                </p>
                                <p>New subject Basic Electrical And Electronics Engineering is introduced to solve the problems of electrical circuits using network laws and theorems.EWS lab is useful in learning the mechanical work which is involved in daily life. It would give them an idea about handling workshop material and tools.

                                </p>
                                {/* <h5 className='py-3'>Development of the Department
                                </h5>
                                <p>Apart from academic, the department conducts programs like Vedic math to enhance the aptitude skills and also invites Professionals to help the students by Guest Lectures on various subjects.

                                </p>
                                <p>The Management not only encourages students but also gives an opportunity to the faculty to attend Workshops on FACULTY DEVELOPMENT PROGRAM to update their knowledge conducted by JNTUH and other Engineering colleges.

                                </p>
                                <p>Recently, two faculties from English Dept. attended a two-day workshop on "Faculty Development Program” associated with CAMBRIDGE ENGLISH LANGUAGE at CBIT conducted by TASK -Telangana Academic Skills Knowledge.

                                </p>
                                <p>Faculty from Chemistry attended one day workshop at VJIT on “Enviro Chemistry in day to day life”.

                                </p>
                                <p>Faculty from Physics recently attended Three-week workshop at JNTU on Refresher course on <strong>“Recent Innovations in Physics”</strong> sponsored by University Grants Commission.

                                </p>
                                <p>The department not only caters to the academics of the students but also to the extracurricular activities like sports and library. Students are encouraged to take part in sports which helps in the physical development and intellectual growth.

                                </p> */}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SandH
