import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const IndependentDay2022 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2>Independence Day Celebration
                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class=" row">
                                                <div class="col-md-6 mb-3">
                                                    <a class="spotlight" href="https://ik.imagekit.io/we26mxcya/independence-day-celebration/1.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699704">
                                                        <img src="https://ik.imagekit.io/we26mxcya/independence-day-celebration/1.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699704" alt="Image" class="img-responsive center-block" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <a class="spotlight" href="https://ik.imagekit.io/we26mxcya/independence-day-celebration/5.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699709">
                                                        <img src="https://ik.imagekit.io/we26mxcya/independence-day-celebration/5.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699709" alt="Image" class="img-responsive center-block" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <a class="spotlight" href="https://ik.imagekit.io/we26mxcya/independence-day-celebration/2.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699441">
                                                        <img src="https://ik.imagekit.io/we26mxcya/independence-day-celebration/2.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699441" alt="Image" class="img-responsive center-block" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <a class="spotlight" href="https://ik.imagekit.io/we26mxcya/independence-day-celebration/6.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699461">
                                                        <img src="https://ik.imagekit.io/we26mxcya/independence-day-celebration/6.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699461" alt="Image" class="img-responsive center-block" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 mb-3">
                                                    <a class="spotlight" href="https://ik.imagekit.io/we26mxcya/independence-day-celebration/3.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699268">
                                                        <img src="https://ik.imagekit.io/we26mxcya/independence-day-celebration/3.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1670494699268" alt="Image" class="img-responsive center-block" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default IndependentDay2022
