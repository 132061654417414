import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';


const CSEWorkShop = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Faculty Developments
                                </h2>
                                <div className='pt-3'>
                                    <h6 className="pb-2">
                                        STTP Sanction Letter</h6>
                                    <object data="https://swec.ac.in/documents/cse/STTP%20Sanction%20Letter%20CSE%20Grants.pdf#toolbar=0&navpanes=0&scrollbar=0" type="application/pdf" width="100%" height="500">
                                        <p>Alternative text - include a link <a href="https://swec.ac.in/documents/cse/STTP%20Sanction%20Letter%20CSE%20Grants.pdf#toolbar=0&navpanes=0&scrollbar=0">to the PDF!</a></p>
                                    </object>
                                </div>
                                <div className='pt-4'>
                                    <h6 className="pb-2">Faculty Development Program 2020-2021
                                    </h6>
                                    <div class="table-responsive" >
                                        <table class="table table-bordered">

                                            <tbody>
                                                <tr class="bg-gray" >
                                                    <td width="414" nowrap="" colspan="4" valign="bottom" className='bg-light text-center align-items-center' >
                                                        <p class="MsoNormal" align="center" className='text-center'>
                                                            <span lang="EN-US" >
                                                                <span zeum4c2="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">summarysheet</span> - Faculty Development
                                                                Program
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="414" nowrap="" colspan="4" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2020-21</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" rowspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >S.No</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" rowspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >Faculty Name</span></p>
                                                    </td>
                                                    <td width="141" nowrap="" colspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >FDP's Attended</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="81" nowrap="" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5 day</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >others</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" ><span lang="EN-US" >Dr.A.GauthamiLatha</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.
                                                                M.Ramasubramanian
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >3</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.V.
                                                                Anantha Krishna
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >4</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.T.K.S.
                                                                Rathish Babu
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.K.C.
                                                                Ravi Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >6</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.B.Narendra
                                                                Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >7</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.Ch.V.
                                                                Rama Padmaja
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >8</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                A.Ravi Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >9</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                E. Krishnaveni Reddy
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >3</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >&nbsp;</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >10</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.D.
                                                                Madhavi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >11</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                K.Sreenivasa Reddy
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >4</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >12</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                P.Prashanth Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >13</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                M. Pragathi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >14</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                N. Sujata Kumari
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >15</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                K. Rajasekhar Rao
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >16</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S. Radhika
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >17</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                B.Suman
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >7</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >18</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Ms.
                                                                K. Anusha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >19</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                T. Kavitha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >20</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S. Anitha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >21</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                A. Saibabu
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >22</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                Pratibha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >23</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S. Mounasri
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >24</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                B.Laxmi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >25</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                V.Radhika
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >26</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                R. Bhargavi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >27</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                L.Manikandan
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >3</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >28</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                N.Sirisha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >29</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                K.Laxmi Shravani
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >30</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" ><span lang="EN-US" >Mrs. M.Chandrakala</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >31</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                K.Kavitha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >32</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                Y.Divya
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="73" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >33</span></p>
                                                    </td>
                                                    <td width="200" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S.Sowmya
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >3</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <h6 className="pb-2">Faculty Development Program 2019-2020
                                    </h6>
                                    <div class="table-responsive" >
                                        <table class="table table-bordered">

                                            <tbody>
                                                <tr class="bg-gray" >
                                                    <td width="433" nowrap="" colspan="4" valign="bottom" className='bg-light'>
                                                        <p class="MsoNormal text-center" align="center" >
                                                            <span lang="EN-US" >
                                                                <span zeum4c5="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">summarysheet</span>
                                                                - Faculty Development Program
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="433" nowrap="" colspan="4" valign="bottom">
                                                        <p class="MsoNormal" align="center" className='text-center' ><span lang="EN-US" >2019-20</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" rowspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >S.No</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" rowspan="2" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US" >
                                                                Faculty
                                                                Name
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="124" nowrap="" colspan="2" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US" >
                                                                FDP's
                                                                Attended
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="64" nowrap="" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5 day</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >others</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" ><span lang="EN-US" >Dr.A.GauthamiLatha</span></p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.
                                                                M.Ramasubramanian
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >3</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.V.
                                                                Anantha Krishna
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >4</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.T.K.S.
                                                                Rathish Babu
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.K.C.
                                                                Ravi Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >6</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.B.Narendra
                                                                Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >6</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >7</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.Ch.V.
                                                                Rama Padmaja
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >8</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                A.Ravi Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >9</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                E. Krishnaveni Reddy
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >6</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >10</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.D.
                                                                Madhavi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >11</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                K.Sreenivasa Reddy
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >6</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >12</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                P.Prashanth Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >13</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                M. Pragathi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >14</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                N. Sujata Kumari
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >15</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                K. Rajasekhar Rao
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >16</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S. Radhika
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >3</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >17</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                B.Suman
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >6</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >18</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Ms.
                                                                K. Anusha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >19</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                T. Kavitha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >20</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S. Anitha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >21</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                A. Saibabu
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >22</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                Pratibha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >23</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S. Mounasri
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >24</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                B.Laxmi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >25</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                V.Radhika
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >26</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                R. Bhargavi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >27</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                L.Manikandan
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >28</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                N.Sirisha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >29</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                K.Laxmi Shravani
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >30</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                M.Chandrakala
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >31</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                K.Kavitha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >32</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                Y.Divya
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="79" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >33</span></p>
                                                    </td>
                                                    <td width="230" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S.Sowmya
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="64" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="60" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <h6 className="pb-2">Faculty Development Program 2018-19
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">

                                            <tbody>
                                                <tr class="bg-gray" >
                                                    <td width="426" nowrap="" colspan="4" valign="bottom" className='bg-light text-center' >
                                                        <p class="MsoNormal" align="center" className='text-center' >
                                                            <span lang="EN-US" >
                                                                <span zeum4c8="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">summarysheet</span> - Faculty Development
                                                                Program
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="426" nowrap="" colspan="4" valign="bottom" className=' text-center' >
                                                        <p class="text-center" align="center" ><span lang="EN-US" >2018-19</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" rowspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >S.No</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" rowspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >Faculty Name</span></p>
                                                    </td>
                                                    <td width="135" nowrap="" colspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >FDP's Attended</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="54" nowrap="" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5 day</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >others</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" ><span lang="EN-US" >Dr.A.GauthamiLatha</span></p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.
                                                                M.Ramasubramanian
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >3</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.V.
                                                                Anantha Krishna
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >4</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.T.K.S.
                                                                Rathish Babu
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.K.C.
                                                                Ravi Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >6</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.B.Narendra
                                                                Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >7</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.Ch.V.
                                                                Rama Padmaja
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >8</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                A.Ravi Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >9</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                E. Krishnaveni Reddy
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >10</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Dr.D.
                                                                Madhavi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >11</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                K.Sreenivasa Reddy
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >12</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                P.Prashanth Kumar
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >13</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                M. Pragathi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >14</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                N. Sujata Kumari
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >15</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                K. Rajasekhar Rao
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >16</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S. Radhika
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >17</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                B.Suman
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >18</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Ms.
                                                                K. Anusha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >19</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                T. Kavitha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >20</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S. Anitha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >21</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                A. Saibabu
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >22</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                Pratibha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >23</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S. Mounasri
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >24</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                B.Laxmi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >25</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                V.Radhika
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >26</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                R. Bhargavi
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >27</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mr.
                                                                L.Manikandan
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >28</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                N.Sirisha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >29</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                K.Laxmi Shravani
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >30</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                M.Chandrakala
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >31</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                K.Kavitha
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >32</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                Y.Divya
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="55" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >33</span></p>
                                                    </td>
                                                    <td width="236" nowrap="" valign="bottom">
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US" >
                                                                Mrs.
                                                                S.Sowmya
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="54" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                    <td width="81" nowrap="" valign="bottom" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray" >
                                                <td width="373" nowrap="" colspan="4" valign="bottom" className='bg-light' >
                                                    <p class="MsoNormal" align="center" className='text-center' >
                                                        <span lang="EN-US" >
                                                            <span zeum4c11="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">summarysheet-</span> Faculty Development
                                                            Program - Attended
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td width="42" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" ><span lang="EN-US" >S.No</span></p>
                                                </td>
                                                <td width="112" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" >
                                                        <span lang="EN-US" >
                                                            Academic
                                                            Year
                                                        </span>
                                                    </p>
                                                </td>
                                                <td width="79" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" ><span lang="EN-US" >Telangana</span></p>
                                                </td>
                                                <td width="141" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" >
                                                        <span lang="EN-US" >
                                                            Outside
                                                            Telangana
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td width="42" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                </td>
                                                <td width="112" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" ><span lang="EN-US" >2020-21</span></p>
                                                </td>
                                                <td width="79" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >42</span></p>
                                                </td>
                                                <td width="141" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >9</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td width="42" nowrap="" valign="bottom">
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                </td>
                                                <td width="112" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" ><span lang="EN-US" >2019-20</span></p>
                                                </td>
                                                <td width="79" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >63</span></p>
                                                </td>
                                                <td width="141" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >18</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td width="42" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >3</span></p>
                                                </td>
                                                <td width="112" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" ><span lang="EN-US" >2018-19</span></p>
                                                </td>
                                                <td width="79" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >14</span></p>
                                                </td>
                                                <td width="141" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td width="42" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >4</span></p>
                                                </td>
                                                <td width="112" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" ><span lang="EN-US" >2017-18</span></p>
                                                </td>
                                                <td width="79" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >18</span></p>
                                                </td>
                                                <td width="141" nowrap="" valign="bottom" >
                                                    <p class="MsoNormal" align="center" ><span lang="EN-US" >-</span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='pt-4'>
                                    <h5>Workshop and Seminar</h5>
                                    <h6 className='pt-3'>Summarysheet - Events Conducted
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">

                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td colspan="4" class="text-center"><strong>2020-21<strong> </strong></strong></td>
                                                </tr>
                                                <tr>
                                                    <td>1 </td>
                                                    <td>Yuvasamarthya - 2021  </td>
                                                    <td>2 </td>
                                                    <td>2 Days </td>
                                                </tr>
                                                <tr>
                                                    <td>2 </td>
                                                    <td>Virtual Industrial Visit at Tata Enterprise Business, TTSL </td>
                                                    <td>1 </td>
                                                    <td>1 Day </td>
                                                </tr>
                                                <tr>
                                                    <td>3 </td>
                                                    <td>FDP- Moodle Learning Management System </td>
                                                    <td>8 </td>
                                                    <td>5 Days </td>
                                                </tr>
                                                <tr>
                                                    <td>4 </td>
                                                    <td>Webinar - Project Development in Dot NET. </td>
                                                    <td>8 </td>
                                                    <td>1 Day </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Amazon Web Services </td>
                                                    <td>8 </td>
                                                    <td>12 Weeks </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Amazon Web Services </td>
                                                    <td>7 </td>
                                                    <td>5 Days </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4" class="text-center"><strong>2019-20<strong> </strong></strong></td>
                                                </tr>
                                                <tr>
                                                    <td>1 </td>
                                                    <td>Webinar - Patent Writing and Filling  </td>
                                                    <td>6-17-2021 </td>
                                                    <td>1 Day </td>
                                                </tr>
                                                <tr>
                                                    <td>2 </td>
                                                    <td>Hackathon  </td>
                                                    <td>2 </td>
                                                    <td>3 Days</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li><a href="/assets/documents/cse/Workshops and Seminars cse.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> Workshop and Seminar </a></li>
                                        </ul>
                                    </div>
                                </div>
                                <p>Number of workshops/seminars conducted on Intellectual Property Rights (IPR) and Industry-Academia Innovative practices during the last five years
                                </p>
                                <div class="table-responsive pt-2">
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th width="65"><strong>S.No</strong></th>
                                                <th width="180"><strong>Name of the workshop/ seminar</strong></th>
                                                <th width="220"><strong>Date</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    Workshop On" IPR PATENTS"
                                                </td>
                                                <td>03-05-2019 </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>
                                                    Workshop On" IPR and PLAGIARISM"
                                                </td>
                                                <td>13-12-2018 to 14-12-2018 </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>
                                                    Seminar on cyber risk insurance
                                                </td>
                                                <td>31-10-2018</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>
                                                    Workshop On "Big data"
                                                </td>
                                                <td>29-05-2017 to 31-05-2017</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>
                                                    Workshop on challenges overcoming complexity from
                                                    Big Data to Cyber Physical Systems
                                                </td>
                                                <td>22-10-2016 </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>
                                                    A seminar on Virtual Reality
                                                </td>
                                                <td>02-04-2016 </td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>
                                                    Guest Lecture on Nano Technology
                                                </td>
                                                <td>23-02-2016 </td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>
                                                    Copyright protection for database in india
                                                </td>
                                                <td>19-01-2016 </td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>
                                                    Improving innovation through academic industry
                                                    collaboration
                                                </td>
                                                <td> 03-07-2015</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>
                                                    Workshop on “Java Code Camp”
                                                </td>
                                                <td>12-09-2014 to 13-09-2014 </td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>
                                                    Opportunities &amp; Challenges for Collaboration industry-
                                                    Academia
                                                </td>
                                                <td>11-09-2014</td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>
                                                    Seminar on augmented reality and virtual reality
                                                    technologies
                                                </td>
                                                <td>28-08-2014</td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>
                                                    Collaboration between Academics &amp; Industry is
                                                    important
                                                </td>
                                                <td>28-06-2014 </td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>
                                                    Workshop on “Linux Essentials”
                                                </td>
                                                <td>03-03-14 to 04-03-2014 </td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td>
                                                    Programming Fundamentals
                                                </td>
                                                <td>31-07-2014</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CSEWorkShop
