import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const FDPandWEBINARS = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> FDP and WEBINARS
                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div className='col-12 py-3'>
                                                <h5>WEBINAR</h5>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_02.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_02.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_03.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_03.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0003.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0003.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0002.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0002.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0001.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0001.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div className='col-12 py-3'>
                                                <h5>FDP(Faculty Development Program)
                                                </h5>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_02.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_02.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0003.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0003.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0002.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0002.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0001.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0001.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/fdp.jpg">
                                                    <img src="/assets/img/gallery/FDP and WEBINARS/fdp.jpg" alt="Image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div className='col-12 py-3'>
                                                <h5>ECE Department List of Events Conucted
                                                </h5>
                                            </div>
                                            <div className='col-12'>
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr class="bg-gray">
                                                                <th width="250"><strong>Type of Event</strong></th>
                                                                <th width="350"><strong>Title</strong></th>
                                                                <th><strong>Date/Duration</strong></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>FDP</td>
                                                                <td><strong>Cyber Security</strong></td>
                                                                <td>04-05-2020 </td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE FDP_MAY2020-01.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Faculty Webinar</td>
                                                                <td><strong>Drone Applications on IOT(IEEE Blended Learning)</strong></td>
                                                                <td>04-06-2020 </td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE FACULTY WEBINAR_JUN 2020-01.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>

                                                            <tr>
                                                                <td>Faculty Webinar</td>
                                                                <td><strong>Robotic Process Automation</strong></td>
                                                                <td>09-06-2020</td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE FACULTY WEBINAR_JUN 2020-02.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Faculty Workshop</td>
                                                                <td><strong>Angular JS( to develop web apps-faster)</strong></td>
                                                                <td>26-06-2020</td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE  FACULTY WORKSHOP_JUN2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>61st IIRS-ISRO Outreach Programme(FDP)</td>
                                                                <td><strong>Satellite Photogrammetry and its Applications</strong></td>
                                                                <td>29-06-2020 to 03-07-2020</td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE FDP_JUN2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>60th IIRS-ISRO Outreach  Programme (FDP) </td>
                                                                <td><strong>Application of Geoinformatics in Ecological Studies</strong></td>
                                                                <td>13-07-2020  to 24-07-2020 </td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE FDP_JULY2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>62nd  IIRS-ISRO Outreache Programme(FDP) </td>
                                                                <td><strong>Geospatial Inputs for Enabling Master plan Formulation</strong></td>
                                                                <td>27-07-2020 to 31-07-2020 </td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE FDP_JULY2020_02.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>SDP </td>
                                                                <td><strong>Python</strong></td>
                                                                <td>27-07-2020 to 31-07-2020 </td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE SDP_JULY2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>FDP </td>
                                                                <td><strong>Arduino</strong></td>
                                                                <td>04-08-2020 to 10-08-2020 </td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE FDP_AUG2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>FDP </td>
                                                                <td><strong>Recent Trends in Electronics and Communications for Teaching Learning and Research</strong></td>
                                                                <td>12-08-2020  to 18-08-2020</td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE FDP_AUG2020-02.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                            <tr>
                                                                <td>65th IIRS-ISRO Outreach Programme </td>
                                                                <td><strong>Remote Sensing and Digital Image Analysis</strong></td>
                                                                <td>17-08-2020 to 11-09-2020 </td>
                                                                <td classname="d-flex"><a href="/assets/documents/ECE SDP_AUG2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf text-red me-1"></i> PDF</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='col-12 py-3'>
                                                <h5>IT FDP
                                                </h5>
                                                <div class="detgails-right-content my-3">
                                                    <div class="related-download bg-gray">
                                                        <ul>
                                                            <li>
                                                                <a href="/assets/documents/it/IT FDP_JUNE2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IT FDP JUNE 2020-01 </a>
                                                            </li>
                                                            <li>
                                                                <a href="/assets/documents/it/IT FDP_JUNE2020_02.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  IT FDP JUNE 2020-02</a>
                                                            </li>
                                                            <li>
                                                                <a href="/assets/documents/it/IT FDP_JULY2020_03.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  IT FDP JULY 2020-03</a>
                                                            </li>
                                                        </ul>
                                                        
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-12 py-3'>
                                                <h5>IT Department Training Programme
                                                </h5>
                                                <div class="col-md-6 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/IT STUDENT PROGRAM_AUG2020_06.jpg">
                                                        <img src="/assets/img/gallery/FDP and WEBINARS/IT STUDENT PROGRAM_AUG2020_06.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/IT STUDENT PROGRAM_AUG2020_07.jpg">
                                                        <img src="/assets/img/gallery/FDP and WEBINARS/IT STUDENT PROGRAM_AUG2020_07.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/IT STUDENT PROGRAM_JUNE2020_01.jpg">
                                                        <img src="/assets/img/gallery/FDP and WEBINARS/IT STUDENT PROGRAM_JUNE2020_01.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                    </a>

                                                </div>
                                            </div>
                                            <div class="detgails-right-content my-3 ">
                                                <div class="related-download bg-gray">
                                                    <ul>
                                                        <li>
                                                            <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_02.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IT STUDENT PROGRAM JULY 2020-02  </a>
                                                        </li>
                                                        <li>
                                                            <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_03.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IT STUDENT PROGRAM JULY 2020-03 </a>
                                                        </li>
                                                        <li>
                                                            <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_04.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IT STUDENT PROGRAM JULY 2020-04 </a>
                                                        </li>
                                                        <li>
                                                            <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_05.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  IT STUDENT PROGRAM JULY 2020-05 </a>
                                                        </li>
                                                        <li>
                                                            <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_06.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>TRAINING PROGRAM FOR FACULTY AND STUDENTS JULY 2020-01  </a>
                                                        </li>
                                                        <li>
                                                            <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_07.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> TRAINING PROGRAM FOR FACULTY AND STUDENTS AUG 2020-02  </a>
                                                        </li>
                                                        <li>
                                                            <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_08.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  TRAINING PROGRAM FOR FACULTY AND STUDENTS AUG2020 03  </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default FDPandWEBINARS
