import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'


const RandDLeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul>
                    <Link to="/r-and-d/innovation-incubation" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Innovation Incubation</li></Link>
                    <Link to="/r-and-d/grants-received" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> Grants Received  </li></Link>
                    <Link to="/r-and-d/research-guide" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Research Guide</li></Link>
                 

                    <Link to="/r-and-d/industry-institute-interaction" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i>   Industry Institute Interaction</li></Link>
                
                </ul>
            </div>
        </div>
    )
}

export default RandDLeftTab
