import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Principal from '../About/images/S Jagadeesh.png'

const DeanAdmissions = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-xl-2 order-lg-2 order-md-2 order-sm-1 order-1">
                            <div className="how-to-apply">
                                <h2>Dean Admissions and R&D</h2>
                                <p><img className='pb-3 me-4 float-start' src={Principal} alt='principal' width="100" loading='lazy' />
                                <strong>Dr. S Jagadeesh <br />Dean Admissions and R&D</strong></p>
                                <p>Dr. S Jagadeesh is an eminent figure in the realm of Electronics and Communication Engineering, boasting a distinguished career marked by academic excellence and groundbreaking research. His educational journey, commencing with a B.Tech degree from Srikrishna Devaraya University, followed by an M.Tech from JNTUH Hyderabad, and culminating in a Ph.D. from Jawaharlal Nehru Technological University, underscores his unwavering dedication to intellectual advancement.
                                </p>
                                <p>Presently, as the Dean of Admissions, R&D, and HOD in the Department of Electronics and Communication Engineering, Dr. S Jagadeesh brings forth a wealth of experience spanning over 25 years. His multifaceted contributions encompass not only teaching and research but also effective administration, significantly influencing the academic landscape.</p>
                                <p>Dr. S Jagadeesh's impact transcends the confines of academia, resonating prominently in his achievements in patenting and scholarly publication. With 3 patents granted and a plethora of articles published in esteemed journals such as IEEE, SCI, Scopus, and WoS, alongside presentations at renowned international conferences, he epitomizes innovation and scholarly excellence within his field.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DeanAdmissions