import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const Maithri2019FlashmobandSong = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2>Maithri 2019 Flash mob and Song
                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row pt-3">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <h6 className='pb-3'>First year IT and EEE
                                                </h6>
                                                <iframe src="https://www.youtube.com/embed/mOWgGX9874g?autoplay=0&amp;showinfo=0&amp;controls=0" width="100%" height="250" className="lazy-youtube"></iframe>

                                            </div>
                                            <div class="col-md-6  mb-3 mb-lg-4">
                                                <h6 className='pb-3'>First year CSE C and D
                                                </h6>
                                                <iframe src="https://www.youtube.com/embed/wva4gl0iBDE?autoplay=0&showinfo=0&controls=0" width="100%" height="250" className="lazy-youtube"></iframe>
                                            </div>
                                            <div class=" mb-3 mb-lg-4">
                                                <h6 className='pb-3'>First year ECE C and D
                                                </h6>
                                                <iframe src="https://www.youtube.com/embed/70oy5bgQS54?autoplay=0&showinfo=0&controls=0" width="100%" height="250" className="lazy-youtube"></iframe>
                                            </div>
                                            <div class=" mb-3 mb-lg-4 mt-3">
                                                <h6 className='pb-3'>Faculty Song Performance - Dr Ramasubramaniam (CSE)
                                                </h6>
                                                <iframe src="https://www.youtube.com/embed/rGvYX8CRNfU?autoplay=0&showinfo=0&controls=0" width="100%" height="250" className="lazy-youtube"></iframe>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default Maithri2019FlashmobandSong
