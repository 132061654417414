import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';
import One from './cseD/1.jpeg'
import Two from './cseD/2.jpeg'


const CSEProffessional = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Professional bodies and its activities
                                </h2>
                                <h6 className='pt-2'>Yuva Samarthya - 2021
                                </h6>
                                <p>CSE department is hosting the Yuva Samarthya - 2021 in association with CSI Student Chapter,  which will provide a great opportunity for displaying the talents of students from different departments on 30th and 31st March 2021.
                                </p>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <img src={One} className='img-fluid img-fluid' alt='yuva-samarthya'/>
                                </div>
                                <div className='col-lg-6'>
                                <img src={Two} className='img-fluid img-fluid' alt='yuva-samarthya'/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default CSEProffessional
