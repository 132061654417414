import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const EngineeringEducatorsAwards = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Engineering Educators Awards
                                    </h2>
                                    <div className='pb-2'>
                                        <h6>FDP program by Education Matters Nov 2019</h6>
                                    </div>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/1.jpg">
                                                    <img src="/assets/img/gallery/fdp/1.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/2.jpg">
                                                    <img src="/assets/img/gallery/fdp/2.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/3.jpg">
                                                    <img src="/assets/img/gallery/fdp/3.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/4.jpg">
                                                    <img src="/assets/img/gallery/fdp/4.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/5.jpg">
                                                    <img src="/assets/img/gallery/fdp/5.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/6.jpg">
                                                    <img src="/assets/img/gallery/fdp/6.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/8.jpg">
                                                    <img src="/assets/img/gallery/fdp/8.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-12">
                                                <p>&nbsp;</p>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/9.jpg">
                                                    <img src="/assets/img/gallery/fdp/9.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/11.jpg">
                                                    <img src="/assets/img/gallery/fdp/11.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/12.jpg">
                                                    <img src="/assets/img/gallery/fdp/12.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/10.jpg">
                                                    <img src="/assets/img/gallery/fdp/10.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-12">
                                                <p>&nbsp;</p>
                                            </div>
                                            <h6 class="col-md-12 pb-2">FDP conducted by Education Matters was attended by SWEC faculty in Nov 2019
                                            </h6>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/13.jpg">
                                                    <img src="/assets/img/gallery/fdp/13.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/14.jpg">
                                                    <img src="/assets/img/gallery/fdp/14.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/fdp/15.jpg">
                                                    <img src="/assets/img/gallery/fdp/15.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <h6 class="col-md-12 pb-2">Award and Felicitation by Education Matters on 26th Feb 2019
                                            </h6>
                                            <p>Trinity College Dublin and Education Matters presented the “ENGINEERING EDUCATORS AWARDS” on 26th February 2019 at the Taj Deccan Hotel, Synergy Banquette Hall to our Toppers in each department and also to the College for its contribution in the field of Technical Education.

                                            </p>
                                            <p>
                                                Shri Navin Mittal, IAS, Commissioner, Collegiate Education & Technical Education Government of Telangana and Professor. V. Venkata Ramana, Vice Chairman , Telangana State Council of Higher Education Government of Telangana were the CHIEF GUESTS for the ceremony.
                                            </p>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/Education Matters/Education Matters - SWEC received award for  Academic Excellence.jpg">
                                                    <img src="/assets/img/gallery/Education Matters/Education Matters - SWEC received award for  Academic Excellence.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/Education Matters/Education Matters - SWEC Toppers Felicitation.jpg">
                                                    <img src="/assets/img/gallery/Education Matters/Education Matters - SWEC Toppers Felicitation.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/Education Matters/EM Guest - IAS officer - Mr Navin Mittal.jpg">
                                                    <img src="/assets/img/gallery/Education Matters/EM Guest - IAS officer - Mr Navin Mittal.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a href="/assets/img/gallery/Education Matters/Education Matters - Our College received award for  Academic Excellence.jpg">
                                                    <img src="/assets/img/gallery/Education Matters/Education Matters - Our College received award for  Academic Excellence.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default EngineeringEducatorsAwards
