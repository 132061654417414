import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const FreshersDay = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>Fresher’s Day
                                    </h2>
                                    <p className='pb-2'>Every year the second year students welcome the first year students into the SWEC family and celebrate it with great pomp and show. The students participate in the competitions and cultural events that are held on that day. This is an ice-breaking session and helps student build friendship and relationships that last a lifetime. This year the Fresher’s Day was celebrated on August 31st 2019.
                                    </p>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/Fresher's Day 2019.jpg">
                                                    <img src="/assets/img/latest-events/Fresher's Day 2019.jpg" alt="Image" class="img-responsive center-block" width="100%" height="100%" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/Fresher's Day 2019 - 1.jpg">
                                                    <img src="/assets/img/latest-events/Fresher's Day 2019 - 1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/Fresher's Day 2019 - 2.jpg">
                                                    <img src="/assets/img/latest-events/Fresher's Day 2019 - 2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/Fresher's Day 2019 - 3.jpg">
                                                    <img src="/assets/img/latest-events/Fresher's Day 2019 - 3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default FreshersDay
