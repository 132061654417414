import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';


const PatentPublications = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Faculty performance and appraisal development system

                                </h2>
                                <h6 className='pt-3 pb-2'>Patent Publications

                                </h6>
                                <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead class="bg-gray">
                                                    <tr>
                                                        <th><strong>S.No</strong></th>
                                                        <th><strong>Name </strong></th>
                                                        <th><strong>Patent Title  </strong></th>
                                                        <th><strong>Patent Number  </strong></th>
                                                        <th><strong>Year  </strong></th>
                                                        <th><strong>Patent </strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Dr .V.ANANTHA KRISHNA, Dr.TKS RATHISH BABU, Dr M RAMASUBRAMANIAM, Dr A GAUTAMI LATHA, DR MADHAVI</td>
                                                        <td>AN APPARATUS AND METHOD OF ARTIFICIAL INTELLIGENCE SECURITY WEARABLE SYSTEM WITH INTERNET OF THINGS</td>
                                                        <td>201941031483A</td>
                                                        <td>2019-20</td>
                                                        <td>PATENT PUBLISHED</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Dr B NARENDRA KUMAR,DR K C RAVIKUMAR</td>
                                                        <td>MACHINE LEARNING BASED FISH MONITORING MACHINE AND METHOD THEREOF </td>
                                                        <td>2020102433</td>
                                                        <td>2020-21</td>
                                                        <td>AUSTRALIAN  PATENT GRANT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>DR A GAUTHAMI LATHA</td>
                                                        <td>ARTIFICIAL INTELLIGENCE APPROACH TO HEALTH CARE MONITORING SYSTEM USING INTERNET OF THINGS</td>
                                                        <td>202141006202A</td>
                                                        <td>2020-21</td>
                                                        <td>PATENT PUBLISHED</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Dr AGAUTAMI LATHA ,Dr V ANANTHA KRISHNA, Dr TKS RATHISH BABU, Dr M RAMASUBRAMANIAM, Dr B NARENDRA KUMAR</td>
                                                        <td>A DEVICE TO TRACK THE VEHICLES THAT  PASS THROUGH LANES VIOLATING THE TRAFFIC RULES </td>
                                                        <td>333867-001</td>
                                                        <td>2020-21</td>
                                                        <td>DESIGN PATENT GRANT UNDER EXAMINATION</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default PatentPublications
