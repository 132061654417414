import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const AICTESponsoredSTTP = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> AICTE Sponsored STTP on Blockchain Technology
                                    </h2>
                                    <div className='py-2'>
                                        <h6>AICTE Sponsored STTP on Blockchain Technology and its applications by CSE dept March 2021</h6>
                                        <p>1)&nbsp;Principal Mam Addressing the participants @ AICTE Sponsored STTP on Blockchain Technology and its applications.
                                        </p>
                                        <p>2)&nbsp;STTP Coordinator @ Dr . V. Anantha Krishna addressing the participants
                                        </p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/aicte/AICTE Sponsored STTP on Blockchain Technology and its applications.jpg">
                                                    <img src="/assets/img/gallery/aicte/AICTE Sponsored STTP on Blockchain Technology and its applications.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/aicte/STTP Coordinator@ Dr . V. Anantha Krishna  addressing the participants.jpg">
                                                    <img src="/assets/img/gallery/aicte/STTP Coordinator@ Dr . V. Anantha Krishna  addressing the participants.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default AICTESponsoredSTTP
