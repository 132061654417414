import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import FooterTab from './FooterTab'



const ExtensionActivities = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row mx-auto">
                        {/* <div className='col-lg-4 pe-lg-5'>
                            <FooterTab />
                        </div> */}
                        <div className="col-lg-12  mx-auto justify-content-center">
                            <div className="how-to-apply">
                                <h2>Extension Activities in the neighbourhood community
                                </h2>
                            </div>
                            <div className='pt-2'>
                                <p>Sridevi Women’s Engineering College is emerging as one of the premier institution of higher learning and play a vital role in fostering social responsibility among its students. We realize that the purpose of education is not just preparing the students to earn a livelihood but more so to make them good citizens and above all good human beings. Values of caring and sharing with the under privileged are one of the important elements to be inculcated through education. It is our collective responsibility to empower the underprivileged to become self-reliant so that they can live with respect and dignity.
                                </p>
                                <p>The institution encourages a lifelong journey of community service. Institutions’ Community Service Scheme provides the students an opportunity to understand the community; identify its needs and problems as well as the solutions in which they can be involved by assuming social and civic responsibilities. Institutions’ Education/ Research/Extension are based upon the basic human values to help community as a whole to lead a successful life in changing our neighborhood society which is also the surviving strength of our institute.
                                </p>
                                <p>The Institute makes the best possible efforts to bring about social and economic transformation of neighborhood community, so as to promote overall development and well-being of society. The Institute inculcates desirable sensitivity, empathy and the virtue of social participation among all its stake holders. Institute is well aware of its social responsibility and hence it renders community service, especially to marginalized sections. It perceives formal education as an instrument of bringing about positive social transformation which leads to the betterment of social culture and spiritual fabric of human community.
                                </p>
                                <p>The students are sensitized towards humanity so that they understand the problems of society and find out the solutions of issues like poverty, alcoholism, unemployment, female feticide etc. The students try to solve the local problems of communities through various activities of the departmental forums, associations, National Service Scheme (NSS) and National Cadet Core (NCC) units. In an attempt to reach the society, various departments of the the Institute organize social, cultural and spiritual activities. Students are motivated to contribute to various social causes. To create awareness about digital India, staff and students of various forums regularly visit the nearby schools and give seminars on the importance of making India digital.
                                </p>
                                <p>To impart and sensitize students to social issues and holistic development and to move social responsibility from theoretical foundation to practical reality SWEC established a very strong NSS unit to connect with neighborhood community through extension activities. Faculty members and students are involved in organizing extension activities for the neighborhood community. The institute is conscious of its role in campus-community connection and well-being of its neighborhood. It has initiated a number of community development activities.
                                </p>
                                <div class="how-to-apply detgails-right-content ">


                                    <div class="apply-list">
                                        <h6 className='pt-3'>Extension Activities Organized
                                        </h6>
                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>
                                                Organizing blood donation camps periodically. Also blood is donated by college students on life saving emergency calls.

                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                                Health check-up camps are organized for local community.

                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                                Faculty and students and the neighboring institute members were involved for raising funds in charity activities and emergency relief operations.

                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                                Awareness campaign on Environment and Sustainability is conducted. Issues like impact of plastics on environment and promoting green practices are discussed.

                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                                Conduct of Socio economic surveys, programs on adult education and reduction in school dropouts.

                                            </li>

                                        </ul>


                                        <h6 className='pt-3 pb-2'>Impact of Extension Activities on students and neighborhood community
                                        </h6>
                                        <p>Extension activities conducted by the institute always combine academic learning experience with a taste of the real life situations thereby making the students fuller, better and more understanding human beings. The students who have been a part of this process have been spreading awareness in the institution and motivating other students as well to stand tall for the social cause.

                                        </p>
                                        <ul>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                                Increased participation of students in community activities and taking up technical projects relevant to social issues.

                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                                Decreased use of plastics in the locality Increase in greenery in the surrounding areas due to plantation.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                                Increase in greenery in the surrounding areas due to plantation.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                                Empowering rural school students.
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default ExtensionActivities
