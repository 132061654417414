import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import DepartmentTab from './CSEDepartmentTab';
import EceleftTab from '../ECELinks/EceleftTab';


const DECE = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Electronics and Communication Engineering (ECE)

                                </h2>
                                <p>
                                    The <strong>Department of Electronics and Communication Engineering </strong> was
                                    started in 2001 with an intake of 60 and currently, the department has
                                    a sanctioned intake of 120.
                                </p>
                                <p>
                                    The Department of ECE is headed by <strong>Dr.S Jagadeesh</strong>, under her leadership, the department is strengthened by well experienced and highly qualified teaching faculty and non-teaching staff. The Department has good infrastructure with spacious classrooms, well equipped laboratories, seminar hall, and staff room with individual staff cabins, HOD chamber, department library, centralized internet centre and R & D centre.
                                </p>
                                <p>
                                    The department has180 computers, installed with the required software (MENTOR GRAPHICS TOOLS, MATLAB, MASM, XILINX, MULTISIM, LABVIEW etc). All the students and staff of the department are facilitated with a high-speed Internet facility of 10 Mbps round the clock.
                                </p>
                                <p>
                                    The department is equipped with well-established laboratories with advanced software and hardware in order to help our students to develop the technical skills needed to bridge the curricular gap
                                </p>
                                <p>
                                    Many students of ECE Department are placed in Top MNCs like IBM, Tech Mahindra, Infosys, Wipro, Microsoft, Oracle, HSBC, HCL, Syntel and so on. Department encourages students and staff to present and publish the papers, research articles in reputed international / national conferences and journals.
                                </p>
                                <p>
                                    Apart from this the Students of ECE Department actively participate through department Association ELECTRO SPARKZ in achieving remarkable appreciations & Prizes in Co-curricular activities like Paper Presentations, Project Expos, National Level Symposiums, Internship Programs, Sports and other cultural activities in numerous engineering colleges. They also participate in social activities like NCC (National Cadet Corp) Village Campaigns, Blood Donation Camps and Environmental Awareness Programs.
                                    <p>
                                        We welcome student aspirants and their parents to visit the SWEC Campus and Department of ECE to observe abundant resources with pleasant Educational atmosphere.


                                    </p>
                                    <p>The department Library contains 201 books. During these 16 years of College history, the students of ECE have performed well in their Academics by securing good pass percentage and owning University Top Ranks.

                                    </p>
                                </p>


                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default DECE
