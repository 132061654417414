import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import PlacementsLeftTab from './PlacementsLeftTab';

const SkillEnhancement = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <PlacementsLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Life Skills
                                </h2>
                                <div>
                                    <div class="row">
                                        <div class="col-md-12 ">
                                            <h6 className="pt-3 pb-1">Meditation</h6>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/Life skills/Meditation 20.jpg">
                                                <img src="/assets/img/Life skills/Meditation 20.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-12  pbottom15">
                                            <h6 className="pt-3 pb-1">Physical Fitness</h6>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img/Life skills/Physical Fitness 1.jpg">
                                                <img src="/assets/img/Life skills/Physical Fitness 1.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img/Life skills/Physical Fitness 2.jpg">
                                                <img src="/assets/img/Life skills/Physical Fitness 2.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img/Life skills/Physical Fitness 3.jpg">
                                                <img src="/assets/img/Life skills/Physical Fitness 3.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-lg-12 pbottom15">
                                            <h6 className="pt-3 pb-1">Yoga</h6>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/Life skills/Yoga 2018-19.jpg">
                                                <img src="/assets/img/Life skills/Yoga 2018-19.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img/Life skills/Yoga 2019-20.jpg">
                                                <img src="/assets/img/Life skills/Yoga 2019-20.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img/Life skills/Yoga 2019-20-2.jpg">
                                                <img src="/assets/img/Life skills/Yoga 2019-20-2.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/Life skills/Yoga 2020-21 -1.jpg">
                                                <img src="/assets/img/Life skills/Yoga 2020-21 -1.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img/Life skills/Yoga 2020-21 -2.jpg">
                                                <img src="/assets/img/Life skills/Yoga 2020-21 -2.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img/Life skills/Yoga 2020-21 -3.jpg">
                                                <img src="/assets/img/Life skills/Yoga 2020-21 -3.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img/Life skills/Yoga 2020-21 -4.jpg">
                                                <img src="/assets/img/Life skills/Yoga 2020-21 -4.jpg" alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-lg-12 pbottom15">
                                            <h6 className="pt-3 pb-1">Language Skills</h6>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/Language Skills/Step plus Language training 1.jpg">
                                                <img src="/assets/img//Life skills/Language Skills/Step plus Language training 1.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                            <a class="spotlight" href="/assets/img//Life skills/Language Skills/Step plus Language training 2.jpg">
                                                <img src="/assets/img//Life skills/Language Skills/Step plus Language training 2.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/Language Skills/Heartfulness Essay-Event-Mailer.jpg">
                                                <img src="/assets/img//Life skills/Language Skills/Heartfulness Essay-Event-Mailer.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">

                                        </div>



                                        <div class="col-lg-12 pbottom15">
                                            <h6 className="pt-3 pb-1">ICT Skills</h6>
                                        </div>


                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/TSCoE Masterclass - Mar 17, 2022.png">
                                                <img src="/assets/img//Life skills/ICT Skills/TSCoE Masterclass - Mar 17, 2022.png" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Internshala Hackathon Nov 2020.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Internshala Hackathon Nov 2020.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Talentsprint - AI in Healthcare Masterclass June 18th 2021.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Talentsprint - AI in Healthcare Masterclass June 18th 2021.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>


                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Talentsprint - Masterclass Sat aug 14th Java.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Talentsprint - Masterclass Sat aug 14th Java.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-4 mb-3">
                                            <a
                                                href="/assets/img//Life skills/ICT Skills/Talentsprint Database modelling and Interview questions March 26 21.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Talentsprint Database modelling and Interview questions March 26 21.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Coign Webcast May 2020.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Coign Webcast May 2020.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a
                                                href="/assets/img//Life skills/ICT Skills/Talentsprint Database modelling and Interview questions March 26 2021.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Talentsprint Database modelling and Interview questions March 26 2021.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Talentsprint masterclass-orange-with-speaker-Jan 27,2021.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Talentsprint masterclass-orange-with-speaker-Jan 27,2021.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Talentsprint Masterclass feb 25 2021.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Talentsprint Masterclass feb 25 2021.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Talentsprint Masterclass Nov 27, 2020.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Talentsprint Masterclass Nov 27, 2020.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Coign webcast 8 May 2020 -3.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Coign webcast 8 May 2020 -3.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Talentsprint webinar-banner  Aug 14th 2021 3pm.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Talentsprint webinar-banner  Aug 14th 2021 3pm.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/Coign Webinar on Cloud computing July 2020.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/Coign Webinar on Cloud computing July 2020.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/EM Data science demand Jan 18 2021.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/EM Data science demand Jan 18 2021.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/GECF UK computing seminar Oct 21.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/GECF UK computing seminar Oct 21.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/SWEC Credit Suisse Invite Nov 2019.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/SWEC Credit Suisse Invite Nov 2019.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/TASK CISCO event March 2021.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/TASK CISCO event March 2021.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/ICT Skills/TASK CISCO event March 2021-1.jpg">
                                                <img src="/assets/img//Life skills/ICT Skills/TASK CISCO event March 2021-1.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-lg-12 pbottom15">
                                            <h6 className="pt-3 pb-1">Soft Skills</h6>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/softskills/ANT Interview Skills Webinar for SWEC April 21.png">
                                                <img src="/assets/img//Life skills/softskills/ANT Interview Skills Webinar for SWEC April 21.png" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/softskills/Coign How to crack dream IT job - Dec 2020.jpg">
                                                <img src="/assets/img//Life skills/softskills/Coign How to crack dream IT job - Dec 2020.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/softskills/EM Global Employability Talk 19 Feb 2021.jpg">
                                                <img src="/assets/img//Life skills/softskills/EM Global Employability Talk 19 Feb 2021.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/softskills/Internshala Career Story Dec 2020.jpg">
                                                <img src="/assets/img//Life skills/softskills/Internshala Career Story Dec 2020.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/softskills/Internshala soft skills workshop -20 april 2021.jpg">
                                                <img src="/assets/img//Life skills/softskills/Internshala soft skills workshop -20 april 2021.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/softskills/Coign Webcast 1 April 2020.jpg">
                                                <img src="/assets/img//Life skills/softskills/Coign Webcast 1 April 2020.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/softskills/Coign webcast 7  May 2020 - 2.jpg">
                                                <img src="/assets/img//Life skills/softskills/Coign webcast 7  May 2020 - 2.jpg" alt="Image"
                                                    class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/softskills/Interview Skills Webinar for SWEC- April 7th 2021.jpg">
                                                <img src="/assets/img//Life skills/softskills/Interview Skills Webinar for SWEC- April 7th 2021.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-lg-12 pbottom15">
                                            <h6 className="pt-3 pb-1">Career Guidance Competitive Exam posters</h6>
                                        </div>
                                        <div class="col-md-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img//Life skills/Career Guidance Competitive Exam posters/Coign INFYTQ 2022 training.jpg">
                                                <img src="/assets/img//Life skills/Career Guidance Competitive Exam posters/Coign INFYTQ 2022 training.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/Career Guidance Competitive Exam posters/GATE Course - May 2020.jpg">
                                                <img src="/assets/img//Life skills/Career Guidance Competitive Exam posters/GATE Course - May 2020.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/Career Guidance Competitive Exam posters/GATEQUEST Test Dec 2020.jpg">
                                                <img src="/assets/img//Life skills/Career Guidance Competitive Exam posters/GATEQUEST Test Dec 2020.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/Career Guidance Competitive Exam posters/Coign Webcast 2 April 2020.jpg">
                                                <img src="/assets/img//Life skills/Career Guidance Competitive Exam posters/Coign Webcast 2 April 2020.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a class="spotlight" href="/assets/img//Life skills/Career Guidance Competitive Exam posters/Coign Webcast 3 April 2020 -1.jpg">
                                                <img src="/assets/img//Life skills/Career Guidance Competitive Exam posters/Coign Webcast 3 April 2020 -1.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <a
                                                href="/assets/img//Life skills/Career Guidance Competitive Exam posters/Coign Webinar on TCS CodevitaMay 2020.jpg">
                                                <img src="/assets/img//Life skills/Career Guidance Competitive Exam posters/Coign Webinar on TCS CodevitaMay 2020.jpg"
                                                    alt="Image" class="img-responsive img-fluid center-block" />
                                            </a>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default SkillEnhancement
