import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';

const ECEFacultyInnovations = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Innovative Teaching Learning Methodologies
                                </h2>
                                <div class="table-responsive" >
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th width="65"><strong>S.No</strong></th>
                                                <th><strong>Name of the Full-time teacher</strong></th>
                                                <th width="220"><strong>Designation</strong></th>
                                                <th width="120"><strong>Subject</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr.B.L.Malleswari</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/1.Analog Electronics-Dr.B.L.Malleswari.pdf" target="_blank">&nbsp; Analog Electronics</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Dr.V.Balaji</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/2.Pulse Digital Circuit-Dr.V.balaji.pdf" target="_blank">&nbsp; Pulse &amp; Digital Circuits</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Dr.V.R.S.Rajesh Kumar</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/3.Analog Communication-V.R.S.Rajesh Kumar.pdf" target="_blank">&nbsp; Analog Communication</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Dr.Indrajeet Singh</td>
                                                <td>Associate Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/4.Principle Of Communication-Dr.Indrajeet Singh.pdf" target="_blank">&nbsp; Principal of Electronics Communication</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Dr.P.Suneel Kumar</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/5.Radar System-Dr.P.Suneel kumar.pdf" target="_blank">&nbsp; Radar System</a></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Dr.K.Mohan Ram</td>
                                                <td>Associate Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/6.Satellite Communications-Dr,K.Mohanram.pdf" target="_blank">&nbsp; Satellite Communication</a></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Dr.C.Karthik</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/7.Analog Electronics-Dr.C.Karthik.pdf" target="_blank">&nbsp; Analog Electronics</a></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Dr.V.Prasad</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/8.Pulse &amp; Digital Circuit-Dr.V.Prasad.pdf" target="_blank">&nbsp; Pulse &amp; Digital Circuits</a></td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>Dr.G.Ramprabu</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/9.Computer Networks-Dr.G.Ramprabu.pdf" target="_blank">&nbsp; Computer Networks</a></td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>Dr.R.Siva Kumar</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/10.Antenna Wave Propagation_R.Siva Kumar.pdf" target="_blank">&nbsp; Antenna &amp; Wave Propagation</a></td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>Dr.K.Murali Babu</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/11.Digital Image Processing-Dr.K.Murali Babu.pdf" target="_blank">&nbsp; Digital Image processing</a></td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>Dr.N.Veda Kumar</td>
                                                <td>Associate Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/12.Digital Image Processing-Dr.N.Vedakumar.pdf" target="_blank">&nbsp; Digital Image processing</a></td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>Dr. S. Jagadeesh</td>
                                                <td>Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/13.Digital Signal Processing-Mr.S.Jagadeesh.pdf" target="_blank">&nbsp; Digital Signal Processing</a></td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>Mr.K.Tulasi Ram</td>
                                                <td>Associate Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/14.analog Electronics-Mr.K.Tulasi Ram.pdf" target="_blank">&nbsp; Analog Electronics</a></td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td>Mrs.Laka Maya Vital Rao</td>
                                                <td>Associate Professo</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/15.Microprocessors &amp; Microcontrollers-Mrs.Laka Maya Vital Rao.pdf" target="_blank">&nbsp; Microprocessors &amp; Microcontrollers</a></td>
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td>Mr.Siva Nagi Reddy Kalli</td>
                                                <td>Associate Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/16.Digital Image Processing-K.Shiv Nagi Reddy.pdf" target="_blank">&nbsp; Digital Image processing</a></td>
                                            </tr>
                                            <tr>
                                                <td>17</td>
                                                <td>Mr.B.J.Prem Prasanna Kumar</td>
                                                <td>Associate Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/17.Cellular Mobile Communication-Mr.B.J.Prem Prasanna kumar.pdf" target="_blank">&nbsp; Cellular Mobile Communication</a></td>
                                            </tr>
                                            <tr>
                                                <td>18</td>
                                                <td>Ms.Kavitha Boddupally</td>
                                                <td>Associate Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/18.Signals &amp; Stochastic Process-Ms.B.Kavitha.pdf" target="_blank">&nbsp; Signals &amp; stochastic Process</a></td>
                                            </tr>
                                            <tr>
                                                <td>19</td>
                                                <td>Mr.S.Bhargav Kumar</td>
                                                <td>Associate Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/19.Pulse &amp; Digital Circuits-Mr.S.Bhargav Kumar.pdf" target="_blank">&nbsp; Pulse &amp; Digital Circuits</a></td>
                                            </tr>
                                            <tr>
                                                <td>20</td>
                                                <td>Mr.Boya Ramanjaneyulu</td>
                                                <td>Associate Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/20.Switching Theory &amp; Logic Design-Mr.B.Ramanjeneyulu.pdf" target="_blank">&nbsp; Switching Theory &amp; Logic Design</a></td>
                                            </tr>
                                            <tr>
                                                <td>21</td>
                                                <td>Ms.B Rajeswari</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/21.Switching Theory &amp; Logic Design-Ms.B..Rajeswari.pdf" target="_blank">&nbsp; Switching Theory &amp; Logic Design</a></td>
                                            </tr>
                                            <tr>
                                                <td>22</td>
                                                <td>Ms.Sadhu Satya Sravani</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/22.Microwave Engineering-Ms.Sadhu Satya sravani.pdf" target="_blank">&nbsp; Microwave Engineering</a></td>
                                            </tr>
                                            <tr>
                                                <td>23</td>
                                                <td>Mrs.H.Shanthi</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/23.Analog Communications-Mrs.H.Shanthi.pdf" target="_blank">&nbsp; Analog Communication</a></td>
                                            </tr>
                                            <tr>
                                                <td>24</td>
                                                <td>Mrs.VelpuriNagalakshmi</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/24.Digital Signal Processing-V.Nagalakshmi.pdf" target="_blank">&nbsp; Digital Signal Processing</a></td>
                                            </tr>
                                            <tr>
                                                <td>25</td>
                                                <td>Mr.D.Sudharshan Reddy</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/25.Antenna Wave Propagation-D SUDARSHAN REDDY.pdf" target="_blank">&nbsp; Antenna &amp; Wave Propagation</a></td>
                                            </tr>
                                            <tr>
                                                <td>26</td>
                                                <td>Mr.Kiran Kokkonda</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/26.Microprocessors &amp; Microcontrollers-K.Kiran.pdf" target="_blank">&nbsp; Microprocessors &amp; Microcontrollers</a></td>
                                            </tr>
                                            <tr>
                                                <td>27</td>
                                                <td>Mr.Sangala Ashok</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/27.Digital Signal Processing-S.Ashok.pdf" target="_blank">&nbsp; Digital Signal Processing</a></td>
                                            </tr>
                                            <tr>
                                                <td>28</td>
                                                <td>Mrs.G.Bavitha</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/28CONTROL SYSTEM-G.Bavitha.pdf" target="_blank">&nbsp; Control Systems</a></td>
                                            </tr>
                                            <tr>
                                                <td>29</td>
                                                <td>Mrs.Urvashi Gupta</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/29.Electromagnetic Theory &amp; Transmission Line-Mrs.Urvashi Gupta.pdf" target="_blank">&nbsp; Electromagnetic Theory</a></td>
                                            </tr>
                                            <tr>
                                                <td>30</td>
                                                <td>Mrs.R.Mohana Leela</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/30.Analog Electronics-Mrs.R.Mohanleela.pdf" target="_blank">&nbsp; Analog Electronics</a></td>
                                            </tr>
                                            <tr>
                                                <td>31</td>
                                                <td>Mrs.Steffi Philip Mulamoottil</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/31.Digital Communication-M.Steffi Philip.pdf" target="_blank">&nbsp; Digital Communication</a></td>
                                            </tr>
                                            <tr>
                                                <td>32</td>
                                                <td>Mrs.Deepa Patnaik</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/32.Control systems -Mrs.Deepa Patnaik.pdf" target="_blank">&nbsp; Control Systems</a></td>
                                            </tr>
                                            <tr>
                                                <td>33</td>
                                                <td>Ms.V.Venkata Nandini</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/33.Wireless Communication Networks-Ms.V.Venkat Nandini.pdf" target="_blank">&nbsp; Digital Logic Design</a></td>
                                            </tr>
                                            <tr>
                                                <td>34</td>
                                                <td>Mr.Sampath Kumar Koppaka</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/34Digital Signal processing-K.Sampath Kumar.pdf" target="_blank">&nbsp; Digital Signal Processing</a></td>
                                            </tr>
                                            <tr>
                                                <td>35</td>
                                                <td>Mrs.Y. Manasa</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/35.Cellular Mobile Communication-Mrs.Y.Manasa.pdf" target="_blank">&nbsp; Cellular Mobile Communication</a></td>
                                            </tr>
                                            <tr>
                                                <td>36</td>
                                                <td>Mrs.Manda Padma Latha</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/36.Embedded System Design-Mrs.M.Padmalatha.pdf" target="_blank">&nbsp; Embedded System Design</a></td>
                                            </tr>
                                            <tr>
                                                <td>37</td>
                                                <td>Mrs.P.Sravanthi</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/37.Cellular Mobile Communication-Mrs.P.Sravanthi.pdf" target="_blank">&nbsp; Cellular Mobile Communication</a></td>
                                            </tr>
                                            <tr>
                                                <td>38</td>
                                                <td>Ms.Ragiphani Shravya</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/38.Embedded System Design-Ms.R.Shravya.pdf" target="_blank">&nbsp; Embedded System Design</a></td>
                                            </tr>
                                            <tr>
                                                <td>39</td>
                                                <td>Mrs.Vani J</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/39.Embedded System Design-Mrs.J.Vani.pdf" target="_blank">&nbsp; Embedded System Design</a></td>
                                            </tr>
                                            <tr>
                                                <td>40</td>
                                                <td>Ms.J.Madhuri</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/40.Computer Networks-Mrs.J.Madhuri.pdf" target="_blank">&nbsp; Computer Netowrks</a></td>
                                            </tr>
                                            <tr>
                                                <td>41</td>
                                                <td>Mrs.B.Jyothsna</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/41.Digital Logic Design-B.jyosthna.pdf" target="_blank">&nbsp; Digital Logic Design</a></td>
                                            </tr>
                                            <tr>
                                                <td>42</td>
                                                <td>Mrs. K. Sindhu</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/42.Linear Digital IC Applications-Mrs.k.Sindhu.pdf" target="_blank">&nbsp; Linear &amp; Difital IC Applications</a></td>
                                            </tr>
                                            <tr>
                                                <td>43</td>
                                                <td>Mrs.S.Manjula</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/43.Principles of Computers &amp; Communication Networks-Mrs.S,Manjula.pdf" target="_blank">&nbsp; Principal of Computer &amp; Communication Network</a></td>
                                            </tr>
                                            <tr>
                                                <td>44</td>
                                                <td>Ms.P Sai Spandana</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/44.Principles of Computers &amp; Communication Networks -Mrs.P.Spandana.pdf" target="_blank">&nbsp; Principal of Computer &amp; Communication Network</a></td>
                                            </tr>
                                            <tr>
                                                <td>45</td>
                                                <td>Shastri Vishwashree</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/45.Principles of Computers &amp; Communication Networks-S.Viswashree.pdf" target="_blank">&nbsp; Principal of Computer &amp; Communication Network</a></td>
                                            </tr>
                                            <tr>
                                                <td>46</td>
                                                <td>Mrs.Shridevi</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/46.Control Systems-Mrs.K.Shridevi.pdf" target="_blank">&nbsp; Control Systems</a></td>
                                            </tr>
                                            <tr>
                                                <td>47</td>
                                                <td>Mrs.V.Lavanya</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/47.Principles Of Computers &amp; Communication Networks-Mrs.V LAVANYA.pdf" target="_blank">&nbsp; OPrincipal of Computer &amp; Communication Network</a></td>
                                            </tr>
                                            <tr>
                                                <td>48</td>
                                                <td>T.E.Haritha</td>
                                                <td>Assistant Professor</td>
                                                <td><i class="fa-solid fa-file-pdf text-red"></i><a className='text-black'  href="/assets/documents/ece/itm/48.Switching Theory &amp; Logic Design-Mrs.T.E.Hariitha.pdf" target="_blank">&nbsp; Switching Theory &amp; Logic Design</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='pt-3'>
                                    <h6>MOOC resources
                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="https://www.coursera.org/" target='_blank'><i class="fa-solid fa-link"></i>  https://www.coursera.org/ </a></li>
                                                <li><a href=" http://www.saylor.org/" target='_blank'><i class="fa-solid fa-link"></i>  http://www.saylor.org/ </a></li>
                                                <li><a href="https://www.edx.org/" target='_blank'><i class="fa-solid fa-link"></i>   https://www.edx.org/</a></li>
                                                <li><a href=" https://www.kadenze.com/" target='_blank'><i class="fa-solid fa-link"></i>  https://www.kadenze.com/ </a></li>
                                                <li><a href=" http://www.openuped.eu/15-news/62-106-new-free-courses-by-uninettuno" target='_blank'><i class="fa-solid fa-link"></i>  http://www.openuped.eu/15-news/62-106-new-free-courses-by-uninettuno</a></li>
                                                <li><a href=" http://www.openuped.eu/15-news/62-106-new-free-courses-by-uninettuno" target='_blank'><i class="fa-solid fa-link"></i>  https://www.udacity.com/ </a></li>
                                                <li><a href=" http://www.openuped.eu/15-news/62-106-new-free-courses-by-uninettuno" target='_blank'><i class="fa-solid fa-link"></i> http://www.instreamia.com/class/  </a></li>
                                                <li><a href=" http://www.openuped.eu/15-news/62-106-new-free-courses-by-uninettuno" target='_blank'><i class="fa-solid fa-link"></i> http://worldmentoringacademy.com/www/index.php </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Google classroom Links
                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="https://classroom.google.com/c/MTk5OTYyMDM0NTVa" target='_blank'><i class="fa-solid fa-link"></i>Microwave Engineering
                                                </a></li>
                                                <li><a href="https://classroom.google.com/c/MTk5OTg1OTgxMDFa" target='_blank'><i class="fa-solid fa-link"></i>Radar Systems

                                                </a></li>
                                                <li><a href="https://classroom.google.com/c/MTk3NjY5ODE3NzNa" target='_blank'><i class="fa-solid fa-link"></i>Computer Networks

                                                </a></li>
                                                <li><a href="https://classroom.google.com/r/MjAwNzA3NjI4MjZa/sort-last-name" target='_blank'><i class="fa-solid fa-link"></i>Microprocessors & Microcontrollers
                                                </a></li>
                                                <li><a href="https://classroom.google.com/w/MjAwNjY0ODE1NjNa/t/all" target='_blank'><i class="fa-solid fa-link"></i>Microwave Engineering
                                                </a></li>
                                                <li><a href="https://classroom.google.com/r/MTk5NjM3NDMwNzla/sort-last-name" target='_blank'><i class="fa-solid fa-link"></i>Digital Logic Design
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Digital Communications
                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="https://classroom.google.com/r/MjAwNjU5MTQ1OTZa/sort-last-name" target='_blank'><i class="fa-solid fa-link"></i>https://classroom.google.com/r/MjAwNjU5MTQ1OTZa/sort-last-name
                                                </a></li>
                                                <li><a href="https://classroom.google.com/w/MjAxNjI1NTM1MDda/t/all" target='_blank'><i class="fa-solid fa-link"></i>https://classroom.google.com/w/MjAxNjI1NTM1MDda/t/all
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Signals & Stochastic Process
                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="https://classroom.google.com/c/MTk5OTk1NzU2Mzda" target='_blank'><i class="fa-solid fa-link"></i>https://classroom.google.com/c/MTk5OTk1NzU2Mzda
                                                </a></li>
                                                <li><a href="https://classroom.google.com/w/MjAxNjI1NTM1MDda/t/all" target='_blank'><i class="fa-solid fa-link"></i>https://classroom.google.com/w/MjAxNjI1NTM1MDda/t/all
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Linear & Digital IC Applications
                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href=" https://classroom.google.com/r/MjAwNzA3NjI4MjZa/sort-last-name" target='_blank'><i class="fa-solid fa-link"></i> https://classroom.google.com/r/MjAwNzA3NjI4MjZa/sort-last-name
                                                </a></li>
                                                <li><a href=" https://classroom.google.com/r/MjAwNzA3NjI4MjZa/sort-last-name" target='_blank'><i class="fa-solid fa-link"></i> https://classroom.google.com/r/MjAwNzA3NjI4MjZa/sort-last-name
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Embedded System Design
                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="https://classroom.google.com/c/MjAwNjU5NzMyNjda" target='_blank'><i class="fa-solid fa-link"></i>  https://classroom.google.com/c/MjAwNjU5NzMyNjda
                                                </a></li>
                                                <li><a href=" https://classroom.google.com/c/MTk5NjM3NDMyMjJa" target='_blank'><i class="fa-solid fa-link"></i>  https://classroom.google.com/c/MTk5NjM3NDMyMjJa
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Some Useful Websites Online Educational Support
                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="http://www.khanacademy.org" target='_blank'><i class="fa-solid fa-link"></i> www.khanacademy.org
                                                </a></li>
                                                <li><a href=" www.academicearths.org" target='_blank'><i class="fa-solid fa-link"></i>   www.academicearths.org
                                                </a></li>
                                                <li><a href="http://www.coursera.com" target='_blank'><i class="fa-solid fa-link"></i>  www.coursera.com
                                                </a></li>
                                                <li><a href="http://www.edx.org" target='_blank'><i class="fa-solid fa-link"></i>   www.edx.org
                                                </a></li>
                                                <li><a href="http://www.open2study.com" target='_blank'><i class="fa-solid fa-link"></i> www.open2study.com
                                                </a></li>
                                                <li><a href="http://www.academicjournals.org" target='_blank'><i class="fa-solid fa-link"></i>  www.academicjournals.org
                                                </a></li>
                                                <li><a href="http://codeacademy.org" target='_blank'><i class="fa-solid fa-link"></i>  codeacademy.org
                                                </a></li>
                                                <li><a href="http://codeacademy.org" target='_blank'><i class="fa-solid fa-link"></i>  codeacademy.org
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Book Sites
                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="http://www.bookboon.com" target='_blank'><i class="fa-solid fa-link"></i>  www.bookboon.com
                                                </a></li>
                                                <li><a href=" http://ebookee.org" target='_blank'><i class="fa-solid fa-link"></i>  http://ebookee.org
                                                </a></li>
                                                <li><a href=" http://sharebookfree.com" target='_blank'><i class="fa-solid fa-link"></i>  http://sharebookfree.com
                                                </a></li>
                                                <li><a href=" http://m.freebooks.com" target='_blank'><i class="fa-solid fa-link"></i>  http://m.freebooks.com
                                                </a></li>
                                                <li><a href="http://www.obooko.com" target='_blank'><i class="fa-solid fa-link"></i> www.obooko.com
                                                </a></li>
                                                <li><a href="http://www.manybooks.net" target='_blank'><i class="fa-solid fa-link"></i>  www.manybooks.net
                                                </a></li>
                                                <li><a href=" http://www.epubbud.com" target='_blank'><i class="fa-solid fa-link"></i>  www.epubbud.com
                                                </a></li>
                                                <li><a href=" http://www.bookyards.com" target='_blank'><i class="fa-solid fa-link"></i>  www.bookyards.com
                                                </a></li>
                                                <li><a href="http://www.getfreeebooks.com" target='_blank'><i class="fa-solid fa-link"></i> www.getfreeebooks.com
                                                </a></li>
                                                <li><a href=" http://freecomputerbooks.com" target='_blank'><i class="fa-solid fa-link"></i>  http://freecomputerbooks.com
                                                </a></li>
                                                <li><a href=" http://www.essays.se" target='_blank'><i class="fa-solid fa-link"></i>  www.essays.se
                                                </a></li>
                                                <li><a href="http://www.pink.monkey.com" target='_blank'><i class="fa-solid fa-link"></i> www.pink.monkey.com
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Online Educational Support
                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href=" http://www.ocwconsortium.org/" target='_blank'><i class="fa-solid fa-link"></i>   http://www.ocwconsortium.org/
                                                </a></li>
                                                <li><a href=" http://www.ocwconsortium.org/en/courses/ocwsites" target='_blank'><i class="fa-solid fa-link"></i>  http://www.ocwconsortium.org/en/courses/ocwsites
                                                </a></li>
                                                <li><a href=" http://ocw.tufts.edu" target='_blank'><i class="fa-solid fa-link"></i>   http://ocw.tufts.edu
                                                </a></li>
                                                <li><a href="http://ocw.upm.es" target='_blank'><i class="fa-solid fa-link"></i> http://ocw.upm.es
                                                </a></li>
                                                <li><a href=" http://www.open.edu/openlearn/" target='_blank'><i class="fa-solid fa-link"></i>   http://www.open.edu/openlearn/
                                                </a></li>
                                                <li><a href=" http://www.open.edu/openlearn/" target='_blank'><i class="fa-solid fa-link"></i> http://www.open.edu/openlearn/
                                                </a></li>
                                                <li><a href=" http://www.open.edu/openlearn/" target='_blank'><i class="fa-solid fa-link"></i>  http://www.open.edu/openlearn/ 
                                                </a></li>
                                                <li><a href=" http://ocw.nd.edu/" target='_blank'><i class="fa-solid fa-link"></i> http://ocw.nd.edu/
                                                </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ECEFacultyInnovations
