import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import MbaLeftTab from '../mbaLinks/MbaLeftTab';

const MBA = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>
                                    Master of Business Administration (MBA)

                                </h2>

                                <p>
                                    The Department of Management Studies was established in the year 2006 and introduced the MBA course with two specializations; Human Resources (HR) and Finance (Fin). The faculty comprises of a team of dedicated professionals with vast experience in their respective disciplines. To bridge the gap between the Institution and the Industry, a student chapter named Hyderabad Management Association (HMA) was opened in the college in the year 2012. This association offers the students with an opportunity to understand the industrial environment, work culture and prepares them for the corporate life.


                                </p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MBA
