import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const SportsAwards201819 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2>Sports Awards 2018-19
                                    </h2>
                                    <div class="py-3">
                                        <p>
                                            We are extremely proud of the achievements of our sports students who have brought laurels to our college.
                                        </p>
                                        <p class="">
                                            <i class="fa fa-trophy text-red"></i> <strong>Anurag Group of Institutions</strong> - Kho-Kho - Runners Up
                                        </p>
                                        <p class="">
                                            <i class="fa fa-trophy text-red"></i>  <strong>GRIET</strong> - Kho-Kho- Runners Up
                                        </p>
                                        <p class="">
                                            <i class="fa fa-trophy text-red"></i>  <strong>SVWEC (Bhimavaram)</strong> - Kho-Kho - Runners Up
                                        </p>
                                        <p class="">
                                            <i class="fa fa-trophy text-red"></i>  <strong>BVRIT Women's Engg College</strong>, Hyd - Kabaddi Winners
                                        </p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4 ">
                                                <a class="spotlight" href="/assets/img/gallery/Sports Awards 2018-19/ANURAG KHOKHO RUNNERS.jpg">
                                                    <img src="/assets/img/gallery/Sports Awards 2018-19/ANURAG KHOKHO RUNNERS.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sports Awards 2018-19/BVRIT KABADDI WINNERS.jpg">
                                                    <img src="/assets/img/gallery/Sports Awards 2018-19/BVRIT KABADDI WINNERS.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sports Awards 2018-19/GRIET - KHOKHO RUNNERS.jpg">
                                                    <img src="/assets/img/gallery/Sports Awards 2018-19/GRIET - KHOKHO RUNNERS.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sports Awards 2018-19/SRI VISHNU WOMENS ENGG COLLEGE - KHOKHO RUNNERS.jpg">
                                                    <img src="/assets/img/gallery/Sports Awards 2018-19/SRI VISHNU WOMENS ENGG COLLEGE - KHOKHO RUNNERS.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default SportsAwards201819
