import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import AboutLeftTab from '../About/AboutLeftTab';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import userImg from './testImages/user.png'

const Testimonials = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* <AboutLeftTab /> */}

                        <div className="col-lg-8 ps-4">
                            <div className="how-to-apply">
                                <h2> Student Testimonials
                                </h2>
                                <div class="impx-testimonial-item border-xlight shadow-sm mb-5">
                                    <div class="impx-testi-container">
                                        <div class="impx-testi-text">
                                            <blockquote>
                                                <p _msttexthash="6025461" _msthash="127">I'm currently doing Masters of IT security, from University of
                                                    Ontario Institute of Technology, Canada. </p>
                                                <p _msttexthash="191499893" _msthash="128">
                                                    Sridevi Women's Engineering College was a great learning experience for me not only in education but
                                                    also in values. I have learnt a lot from my fellow students, professors and from our Principal mam
                                                    who is always a constant support. My department gave us the liberty to come up with some thought
                                                    provoking ideas and projects. I would like to mention particularly that the final years of my
                                                    bachelors helped me become a professional and I'm always grateful to my college and I miss being
                                                    there. I couldn't have asked for better college :).
                                                    Only word which can convey my feeling is "THANK YOU!", thank you for the love, support and belief.
                                                </p>
                                            </blockquote>
                                        </div>
                                        <div class="impx-testi-name">
                                            <h6 className='text-red mb-1'>Ashokraj.Meena</h6>
                                            <p className='mb-0 text-primary'><strong>Roll No.14D21A1217</strong></p>
                                            <p className='text-secondary mb-0'>IT Department
                                            </p>
                                            <p className='mb-0'>2014-2018 Batch
                                            </p>
                                        </div>
                                    </div>
                                    <div class="impx-testi-image"><img src={userImg} alt="People 1" class="img-fluid rounded-circle"
                                        _mstalt="84812" _msthash="131" />
                                    </div>
                                </div>
                                <div class="impx-testimonial-item border-xlight shadow-sm mb-5">
                                    <div class="impx-testi-container">
                                        <div class="impx-testi-text">
                                            <blockquote>
                                                <p _msttexthash="191499893" _msthash="128">
                                                    When I reflect on my four years in the College of SWEC- Electronics and Instrumentation(EIE) Course, I can only conclude that the experience not only made me a better woman, but also prepared me in unpredictable ways for my career. Through Labs, workshops with incredibly supportive classmates, and professors who were nothing short of champions, I was challenged to discover a voice I didn’t know I had. The Constant Support From my staff is unbelievable. It was a wonderful journey.
                                                </p>
                                            </blockquote>
                                        </div>
                                        <div class="impx-testi-name">
                                            <h6 className='text-red mb-1'>Ashokraj.Meena</h6>
                                            <p className='mb-0 text-primary'><strong>Roll No.14D21A1004</strong></p>
                                            <p className='text-secondary mb-0'>EIE Department
                                            </p>
                                            <p className='mb-0'>2014-2018 Batch
                                            </p>
                                        </div>
                                    </div>
                                    <div class="impx-testi-image"><img src={userImg} alt="People 1" class="img-fluid rounded-circle"
                                        _mstalt="84812" _msthash="131" />
                                    </div>
                                </div>
                                <div class="impx-testimonial-item border-xlight shadow-sm mb-5">
                                    <div class="impx-testi-container">
                                        <div class="impx-testi-text">
                                            <blockquote>
                                                <p _msttexthash="191499893" _msthash="128">
                                                My experience in these four years has been good and exciting. Our college is improving rapidly with very good infrastructure and excellent placements. As a NCC cadet, I got great support from faculty and Principal mam. Faculty is very good, supportive and motivating. I am glad that our college encourages all round development of a student and promotes Sports, NSS and NCC. I had a great time in our college. Thank you for all the support.
                                                </p>
                                            </blockquote>
                                        </div>
                                        <div class="impx-testi-name">
                                            <h6 className='text-red mb-1'>K.Saisiri</h6>
                                       
                                            <p className='text-secondary mb-0'>ECE Department
                                            </p>
                                            <p className='mb-0'>2014-2018 Batch
                                            </p>
                                        </div>
                                    </div>
                                    <div class="impx-testi-image"><img src={userImg} alt="People 1" class="img-fluid rounded-circle"
                                        _mstalt="84812" _msthash="131" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Testimonials
