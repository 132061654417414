import React, { componentDidMount, useState } from 'react'
import { Link } from "react-router-dom";
import Logo from '../navbar/logo.png'

const Navbar = () => {

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <div className="top-header-area">
                <div className="">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div class=" d-flex align-items-center d-none d-lg-block">
                                <p class=" col-3 col-md-2"></p>
                                {/* news-heading */}
                                <marquee class="col-md-11" behavior="scroll" direction="left" scrollamount="5">

                                    {/* <a className='text-black'>

                                        For admission queries contact : +919441618800, +919666666005, Vice Chairman: Cell No. +91-9949117733
                                    </a> */}
                                </marquee>

                            </div>

                        </div>
                        <div className="col-lg-8 d-none d-xl-block">
                            <div className="header-right-content">
                                <div className="list">
                                    <ul>
                                        <li className="text-white align-items-center pt-1 text-black">
                                            <strong className='text-black'> Accredited by NBA & NAAC (A++) </strong>&nbsp;&nbsp;
                                        </li>
                                        <li className="nirf">
                                            <Link to="/NAACLogin"><strong>NAAC</strong></Link>
                                        </li>
                                        <li className="eamcetCode" style={{marginRight:"0"}}>
                                            <a href="#"> Eamcet Code :<strong>  SDEW </strong></a>
                                        </li>
                                        <li className="clubsCode">
                                            <a href="https://sites.google.com/view/swecclubs?pli=1" target="_blank"> SWEC Clubs</a>
                                        </li>
                                        {/* <li className="naacCode" >
                                            <a href="/NAACLogin"> NAAC</a>
                                        </li> */}
                                        
                                        <li className="login-icon text-black">
                                            <a className="h4 text-black"
                                                href="https://sso.secureserver.net/login?pl_id=494702&amp;app=email&amp;realm=pass"
                                                target="_blank"><i class="fa-regular fa-envelope"></i><strong>&nbsp; Webmail Login</strong></a>
                                        </li>

                                        <li className="admissionBtn">
                                            <a href="https://erp.swec.ac.in/" target='_blank'><strong><i class="fa-solid fa-right-to-bracket"></i> &nbsp;ERP Login</strong></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-area nav-bg-2 d-none d-lg-block">
                <div className="mobile-responsive-nav">
                    <div className="container">
                        <div className="mobile-responsive-menu">
                            <div className="logo">
                                <Link to="/home">
                                    <img src={Logo} className="main-logo" alt="Sridevi Engineeing college logo"  />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="desktop-nav">
                    <div className="px-lg-5">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <Link to="/home" className="navbar-brand" href="#">
                                <img src={Logo} className="main-logo" alt="logo" />

                            </Link>
                            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">

                                <ul className="navbar-nav mx-auto">
                                    <li className="nav-item">
                                        <Link to="/home" className="nav-link active">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link dropdown-toggle">
                                            About
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link to="/about/about-college" className="nav-link">About College</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/management" className="nav-link dropdown-item">Management</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/recognitions" className="nav-link dropdown-item">SWEC Recognitions</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/aicte" className="nav-link dropdown-item">AICTE Approval</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/governing-body" className="nav-link dropdown-item">Governing Body</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/principal" className="nav-link dropdown-item">Principal</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/dean-academics" className="nav-link dropdown-item">Dean Academics</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/dean-admissions" className="nav-link dropdown-item">Dean Admissions</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/board-of-studies" className="nav-link dropdown-item">Board of Studies (BoS)</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/committee" className="nav-link dropdown-item">Committees</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/admissions" className="nav-link">Admissions</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/mandatory-disclosure" className="nav-link dropdown-item">Mandatory Disclosure</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/service-rules" className="nav-link dropdown-item">Service Rules</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="/about/organogram" className="nav-link dropdown-item">Organogram</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/about/salient-features" className="nav-link dropdown-item">Salient Features</Link>
                                            </li>

                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link dropdown-toggle">
                                            Departments
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link to="/dept/cse" className="nav-link dropdown-item">Computer Science - Engineering <br /> (CSE)</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/dept/cse-ds/" className="nav-link dropdown-item" >CSD - Data Science (CSD)</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/dept/ai-ml/" className="nav-link dropdown-item">CSM - Artificial Intelligence and <br /> Machine Learning
                                                     (CSM 
                                                    )</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/dept/ece/" className="nav-link dropdown-item">Electronics and Communication <br /> Engineering (ECE)</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/dept/it/" className="nav-link dropdown-item">Information Technology (IT)</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/dept/eee/" className="nav-link dropdown-item">Electrical and Electronics <br/> Engineering (EEE)</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/dept/mba/" className="nav-link dropdown-item">Master of Business Administration <br/> (MBA)</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="/dept/s-and-h/" className="nav-link dropdown-item">S&H Department</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/dept/dpe/" className="nav-link dropdown-item">Department of Physical Education</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/dept/library/" className="nav-link dropdown-item">Library Department</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link dropdown-toggle">
                                            IQAC
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to="/iqac/quality-policy/" class="dropdown-item">Quality Policy</Link>
                                            </li>
                                            <li>
                                                <Link to="/iqac/quality-education/" class="dropdown-item">Quality Education</Link>
                                            </li>
                                            <li>
                                                <Link to="/iqac/" class="dropdown-item">IQAC</Link>
                                            </li>

                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link dropdown-toggle">
                                            R & D
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to="/r-and-d/innovation-incubation/" class="dropdown-item">Innovation &amp; Incubation</Link>
                                            </li>
                                            <li>
                                                <Link to="/r-and-d/grants-received/" class="dropdown-item">Grants received</Link>
                                            </li>
                                            <li>
                                                <Link to="/r-and-d/research-guide/" class="dropdown-item">Research guide</Link>
                                            </li>
                                            <li>
                                                <Link to="/r-and-d/industry-institute-interaction/" class="dropdown-item">Industry Institute
                                                    Interaction</Link>
                                            </li>

                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link dropdown-toggle">
                                            Examination Cell
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link class="dropdown-item" to="/academics-calendar/">JNTUH</Link>
                                            </li>
                                            <li>
                                                <Link class="dropdown-item" to="/academics-calendar/autonomous-syllabus">Autonomous</Link>
                                            </li>

                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/training-and-placements/" className="nav-link">
                                            Training & Placement
                                        </Link>

                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link dropdown-toggle" >
                                            News & Events
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link class="dropdown-item" to="/news-and-events/news">News</Link>
                                            </li>
                                            <li>
                                                <Link class="dropdown-item" to="/news-and-events/events">Events</Link>
                                            </li>
                                            <li>
                                                <Link class="dropdown-item" to="/news-and-events/gallery" onclick="scrollWin()">Student’s Gallery</Link>
                                            </li>
                                            {/* <li>
                                                <Link class="dropdown-item" to="/news-and-events/circulars">Circulars</Link>
                                            </li>
                                            <li>
                                                <Link class="dropdown-item" to="/news-and-events/swec-radio">SWEC Radio</Link>
                                            </li> */}
                                        </ul>
                                    </li>

                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Activities
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <Link class="dropdown-item" to="/activities/obe/">OBE</Link>
                                            </li>
                                            <li>
                                                <Link class="dropdown-item" to="/activities/ncc/">NCC</Link>
                                            </li>
                                            <li>
                                                <Link class="dropdown-item" to="/activities/nss/">NSS</Link>
                                            </li>
                                            <li>
                                                <Link class="dropdown-item" to="/activities/sswec/">SSWEC</Link>
                                            </li>
                                            <li>
                                                <Link class="dropdown-item" to="/activities/college-magazine/">College Magazine</Link>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                                <div className="others-options d-none">
                                    <div className="icon">
                                        <i className="ri-menu-3-fill" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="others-option-for-responsive">
                    <div className="container">
                        <div className="dot-menu">
                            <div className="inner">
                                <div className="icon">
                                    <i className="ri-menu-3-fill" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-block nav-bg-2 secondNavbar navbar-area justify-content-end px-lg-5">
                <div class="">
                    <div class="px-lg-5">
                        {/* <nav class="navbar navbar-expand-md navbar-light">
                            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul class="navbar-nav mx-auto">
                                    <li class="nav-item">
                                        <a href="#" class="nav-link dropdown-toggle">
                                            Infrastructure
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <a href="central-facilities.html">Central Facilities</a>
                                            </li>
                                            <li>
                                                <a href="transport.html">Transportation</a>
                                            </li>
                                            <li>
                                                <a href="computer-centre.html">Computer Centre</a>
                                            </li>
                                            <li>
                                                <a href="swec-campus-hostel.html">SWEC Campus Hostel</a>
                                            </li>
                                            <li>
                                                <a href="ict.html">ICT</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link dropdown-toggle">
                                            Activities
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <a href="obe.html">OBE</a>
                                            </li>
                                            <li>
                                                <a href="ncc.html">NCC</a>
                                            </li>
                                            <li>
                                                <a href="nss.html">NSS</a>
                                            </li>
                                            <li>
                                                <a href="sswec.html">SSWEC</a>
                                            </li>
                                            <li>
                                                <a href="college-magazine.html">College Magazine</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">EDC </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">Professional Socieies</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">Alumni </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">Grievance Cell </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">Testimonials </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">Feedback</a>
                                    </li>
                                </ul>
                                <div class="others-options d-none">
                                    <div class="icon">
                                        <i className="ri-menu-3-fill" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                                    </div>
                                </div>
                            </div>
                        </nav> */}
                        <nav class="navbar navbar-expand-lg py-0">
                            <div class="container-fluid">

                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav" style={{marginLeft:'auto'}}>
                                        <li class="nav-item afterarrow">
                                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Infrastructure
                                            </a>
                                            <ul class="dropdown-menu">

                                                <li>
                                                    <Link class="dropdown-item" to="/infrastructure/central-facilities/">Central Facilities</Link>
                                                </li>
                                                <li>
                                                    <Link class="dropdown-item" to="/infrastructure/transport/">Transportation</Link>
                                                </li>
                                                <li>
                                                    <Link class="dropdown-item" to="/infrastructure/computer-centre/">Computer Centre</Link>
                                                </li>
                                                <li>
                                                    <Link class="dropdown-item" to="/infrastructure/swec-campus-hostel/">SWEC Campus Hostel</Link>
                                                </li>
                                                <li className='border-0'>
                                                    <Link class="dropdown-item" to="/infrastructure/ict/">ICT</Link>
                                                </li>
                                            </ul>
                                            
                                        </li>

                                        <li class="nav-item">
                                            <Link to="/edc/" class="nav-link">EDC </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/professional-societies/" class="nav-link">Professional Societies</Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/alumni/" class="nav-link">Alumni </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/grievance-cell/" class="nav-link">Grievance Cell </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/testimonials/" class="nav-link">Testimonials </Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/feedback/" class="nav-link">Feedback</Link>
                                        </li>
                                        <li class="nav-item">
                                            <Link to="/nirf/" class="nav-link">NIRF</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/contact-us/" className="nav-link">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="others-option-for-responsive">
                    <div class="container">
                        <div class="dot-menu">
                            <div class="inner">
                                <div class="icon">
                                    <i class="ri-menu-3-fill" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile Navbar Start*/}
            <nav class="navbar navbar-expand-lg bg-body-tertiary d-block d-lg-none mobileNavba">
                <div class="container-fluid">
                    <Link to="/home">
                        <img src={Logo} className="main-logo" alt="logo" />
                    </Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fa-sharp fa-solid fa-bars"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <Link to="/home" className="nav-link active">
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to="/about/about-college" className="nav-link dropdown-item">About College</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/management" className="nav-link dropdown-item">Management</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/recognitions" className="nav-link dropdown-item">SWEC Recognitions</Link>
                                    </li>
                                    <li >
                                        <Link to="/about/aicte" className="nav-link dropdown-item">AICTE Approval</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/governing-body" className="nav-link dropdown-item">Governing Body</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/principal" className="nav-link dropdown-item">Principal</Link>
                                    </li>

                                    <li>
                                        <Link to="/about/Committee" className="nav-link dropdown-item">Committees</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/admissions" className="nav-link dropdown-item">Admissions</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/mandatory-disclosure" className="nav-link dropdown-item">Mandatory Disclosure</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/service-rules" className="nav-link dropdown-item">Service Rules </Link>
                                    </li>

                                    <li>
                                        <Link to="/about/organogram" className="nav-link dropdown-item">Organogram</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/salient-features" className="nav-link dropdown-item">Salient Features</Link>
                                    </li>

                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    Departments
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <Link to="/dept/cse" className="nav-link dropdown-item">Computer Science and Engineering(CSE)</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/dept/cse-ds/" className="nav-link dropdown-item">CSE &amp; Data Science (CSE- DS)</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/dept/ai-ml/" className="nav-link dropdown-item"> CSE &amp; Artificial Intelligence and Machine Learning
                                            (CSE &amp;
                                            AI&amp;ML)</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/dept/ece/" className="nav-link dropdown-item">Electronics and Communication Engineering(ECE)</Link>
                                    </li>
                                    <li className="nav-item" >
                                        <Link to="/dept/it/" className="nav-link dropdown-item">Information Technology(IT)</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/dept/eee/" className="nav-link dropdown-item">Electrical and Electronics Engineering(EEE)</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/dept/s-and-h/" className="nav-link dropdown-item">S&amp;H Department</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/dept/dpe/" className="nav-link dropdown-item" >Department of Physical Education</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/dept/library/" className="nav-link dropdown-item">Library Department</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/dept/mba/" className="nav-link dropdown-item">Master of Business Administration(MBA)</Link>
                                    </li>

                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    IQAC
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to="/iqac/quality-policy/" class="dropdown-item">Quality Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/iqac/quality-education/" class="dropdown-item">Quality Education</Link>
                                    </li>
                                    <li>
                                        <Link to="/iqac/" class="dropdown-item">IQAC</Link>
                                    </li>

                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    R & D
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to="/r-and-d/innovation-incubation/" class="dropdown-item">Innovation &amp; Incubation</Link>
                                    </li>
                                    <li>
                                        <Link to="/r-and-d/grants-received/" class="dropdown-item">Grants received</Link>
                                    </li>
                                    <li>
                                        <Link to="/r-and-d/research-guide/" class="dropdown-item">Research guide</Link>
                                    </li>
                                    <li>
                                        <Link to="/r-and-d/industry-institute-interaction/" class="dropdown-item">Industry Institute
                                            Interaction</Link>
                                    </li>

                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Examination Cell
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="academics.html">JNTUH</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="autonomous-syllabus.html">Autonomous</a>
                                    </li>

                                </ul>
                            </li>

                            <li className="nav-item">
                                <Link to="/training-and-placements/" className="nav-link">
                                    Training & Placement
                                </Link>

                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    News & Events
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link class="dropdown-item" to="/news-and-events/news">News</Link>
                                    </li>
                                    <li>
                                        <Link class="dropdown-item" to="/news-and-events/events" onclick="scrollWin()">Events</Link>
                                    </li>
                                    <li>
                                        <Link class="dropdown-item" to="/news-and-events/gallery">Student’s Gallery</Link>
                                    </li>
                                    {/* <li>
                                        <Link class="dropdown-item" to="/news-and-events/circulars">Circulars</Link>
                                    </li>
                                    <li>
                                        <Link class="dropdown-item" to="/news-and-events/swec-radio">SWEC Radio</Link>
                                    </li> */}
                                </ul>
                            </li>

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Activities
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <Link class="dropdown-item" to="/activities/obe/">OBE</Link>
                                    </li>
                                    <li>
                                        <Link class="dropdown-item" to="/activities/ncc/">NCC</Link>
                                    </li>
                                    <li>
                                        <Link class="dropdown-item" to="/activities/nss/">NSS</Link>
                                    </li>
                                    <li>
                                        <Link class="dropdown-item" to="/activities/sswec/">SSWEC</Link>
                                    </li>
                                    <li>
                                        <Link class="dropdown-item" to="/activities/college-magazine/">College Magazine</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact-us/" className="nav-link">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Mobile Navbar End*/}









            <div class="icon-float">
                <ul>

                    <li>
                        <a href="https://www.facebook.com/sweccollege/" target="_blank" class="fb1"><i class="fa-brands fa-facebook"></i></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/sweccollege/" target="_blank" class="insta"><i class="fa-brands fa-instagram"></i></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/srideviengg/" target="_blank" class="li1"><i class="fa-brands fa-linkedin"></i></a>
                    </li>
                    {/* <li>
                        <a href="mailto:admin@swec.ac.in" class="sh1"><i class="fa-regular fa-envelope"></i></a>
                    </li> */}
                </ul>
            </div>
            <div class="go-top active scrollToTop" onClick={handleClick}>
                <i class="fa fa-angle-up"></i>
                <i class="fa fa-angle-up"></i>
            </div>
           
        </div>
    )
}

export default Navbar
