import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const WorkshopsandSeminars = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Workshops & Seminars

                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <h6 class="py-2">
                                                Workshop and Seminar
                                            </h6>

                                            <div class="row">
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/5.jpg">
                                                        <img class="" src="/assets/img/gallery/others/5.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/6.jpg">
                                                        <img class="" src="/assets/img/gallery/others/6.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/7.jpg">
                                                        <img class="" src="/assets/img/gallery/others/7.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/8.jpg">
                                                        <img class="" src="/assets/img/gallery/others/8.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/9.jpg">
                                                        <img class="" src="/assets/img/gallery/others/9.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/10.jpg">
                                                        <img class="" src="/assets/img/gallery/others/10.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/11.jpg">
                                                        <img class="" src="/assets/img/gallery/others/11.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-12"></div>

                                                <div class="col-md-12 mbottom15">
                                                    <h6 className="py-3">Workshop on PCB design and IOT</h6>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/12.jpg">
                                                        <img class="" src="/assets/img/gallery/others/12.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/13.jpg">
                                                        <img class="" src="/assets/img/gallery/others/13.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/14.jpg">
                                                        <img class="" src="/assets/img/gallery/others/14.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/15.jpg">
                                                        <img class="" src="/assets/img/gallery/others/15.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/16.jpg">
                                                        <img class="" src="/assets/img/gallery/others/16.jpg" alt="gallery image" />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/17.jpg">
                                                        <img class="" src="/assets/img/gallery/others/17.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-12"></div>

                                                <div class="col-md-12 mbottom15">
                                                    <h6 className="py-3">Workshop in Python and C programming</h6>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/18.jpg">
                                                        <img class="" src="/assets/img/gallery/others/18.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/19.jpg">
                                                        <img class="" src="/assets/img/gallery/others/19.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-12"></div>
                                                <div class="col-md-12 mbottom15">
                                                    <h6 className="py-3">Workshop on Application of MATLAB and Simulink for electrical systems</h6>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/20.jpg">
                                                        <img class="" src="/assets/img/gallery/others/20.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/21.jpg">
                                                        <img class="" src="/assets/img/gallery/others/21.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/22.jpg">
                                                        <img class="" src="/assets/img/gallery/others/22.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/23.jpg">
                                                        <img class="" src="/assets/img/gallery/others/23.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-12"></div>

                                                <div class="col-md-12 mbottom15">
                                                    <h6 className="py-3">First year orientation by Mr D Raghu on Positive attitude</h6>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/26.jpg">
                                                        <img class="" src="/assets/img/gallery/others/26.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/27.jpg">
                                                        <img class="" src="/assets/img/gallery/others/27.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-12"></div>

                                                <div class="col-md-12 mbottom15">
                                                    <h6 className="py-3">First year orientation by Dr. Padmaja ( psychologist) on Goal setting</h6>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/25.jpg">
                                                        <img class="" src="/assets/img/gallery/others/25.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/24.jpg">
                                                        <img class="" src="/assets/img/gallery/others/24.jpg" alt="gallery image" />
                                                    </a>
                                                </div>



                                                <div class="col-md-12"></div>

                                                <div class="col-md-12 mbottom15">
                                                    <h6 className="py-3">MBA Dept work shop on "Jadu Ginni Ka- Financial literacy" held on 14th August, conducted by Ms Rohini,
                                                        under CSR activity of LLF and Vodafone all over India.</h6>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/28.jpg">
                                                        <img class="" src="/assets/img/gallery/others/28.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                                <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                    <a class="spotlight" href="/assets/img/gallery/others/29.jpg">
                                                        <img class="" src="/assets/img/gallery/others/29.jpg" alt="gallery image" />
                                                    </a>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default WorkshopsandSeminars
