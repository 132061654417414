import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import OrganogramImg from '../About/SWEC-Organogram.png'

const Organogram = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4">
                <div className="container">
                    <div className="how-to-apply text-center pb-lg-5 pb-4">
                        <h2>Organogram
                        </h2>
                    </div>
                    <img className='img-fluid pb-lg-5 pb-4' src={OrganogramImg} />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Organogram
    ;
