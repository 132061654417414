import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';


const EEEStudentAchievements = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Student publications and achievements
                                </h2>
                                <div className='apply-list'>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            Published national conference on Evolutionary Computing Applications to electrical engineering
                                            on 2nd -3rd May 19(NCECAEE-2019) on Smart Street Lighting system by k.Kavya, S.nikitha,
                                            S.mounika and A.Rashmitha.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            Published national conference on Evolutionary Computing Applications to electrical engineering
                                            on 2nd -3rd May 19(NCECAEE-2019) on Controlling Applications With Hand Gestures And Smart
                                            Assistive Device For Deaf And Dumb People Using Sixth Sense Technology by K.Meghana,
                                            P.Geetha ratnasree, I.Anusha and A.Manisha.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            Published national conference on Evolutionary Computing Applications to electrical engineering
                                            on 2nd -3rd May 19(NCECAEE-2019) on Bus Based Universal Motor Temperature Monitoring In
                                            Printing Industry by K.Sai Ramya Sri, M.Manasa, B.Swaroopa Lahari and A.Sabitha.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            Presented Paper in ICETEE2020 on Design and Development of BLDC Fan by N.Harathi,
                                            M.Sravanthi, G.Anjali and A.Srividya Reddy
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEEStudentAchievements
