import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';

const EceStudentPublications = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Student-publications
                                </h2>
                                <div className='pt-2'>
                                    <h6>Participation of Students Outside the State: 2020-2021
                                    </h6>
                                </div>
                                <div class="apply-list">

                                    <ul class="pt-2">
                                        <li><i class="ri-checkbox-circle-line"></i>Apart from Regular academics our students have actively participated in different events like poster presentation, project expo, paper presentation and quiz events.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>In this AY , an overwhelming strength of 291 students participated in different college’s and have showcased their skills in the above mentioned topics.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>78 student’s participants have shown their talent in poster presentation like Robotics, Artificial Intelligence, Data mining etc. Events were Organized by various colleges include Kallam Haranadhareddy Institute Of Technology, Vasireddy Venkatadri Institute Of Technology, BVC Engineering college, Sri Sivani College of Engineering.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>76 student’s participants have shown their talent in project expo event. DTMF Controlled Robot, Solar Battery Charger, Biometric Attendance system etc. Were few of the projects in which the students have won the prizes. Events were organized by three different colleges Sri Bharathi Engineeering College for Women, Dhanalakshmi Srinivasan College of Engineering, V.P Dr p.G Halakatti College of Engineering & Technology.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>68 students have participated and shown their inborn skills by presenting the paper’s in Signal Wireless Technology, Aircraft Tracking through GPS etc. Events were organized by three different colleges Sri Bharathi Engineeering College for Women, Dhanalakshmi Srinivasan College of Engineering, V.P Dr p.G Halakatti College of Engineering & Technology.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>69 students participated in quiz events and have won the applause by showing their knowledge in Embedded System, Wirless communication, Robotics etc. Events were organized by three different colleges Kallam Haranadhareddy Institute Of Technology, Vasireddy Venkatadri Institute of Technology, V.P Dr p.G Halakatti College of Engineering & Technology.
                                        </li>
                                    </ul>
                                </div>
                                <div className='pt-2'>
                                    <h6>Participation of Students Within the State: 2020-2021
                                    </h6>
                                </div>
                                <div class="apply-list">

                                    <ul class="pt-2">
                                        <li><i class="ri-checkbox-circle-line"></i>Apart from Regular academics our students have actively participated in different events like poster presentation, project expo, paper presentation and quiz events.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>In this AY, an overwhelming strength of 272 students participated in different colleges and has showcased their skills in the above mentioned topics.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>71 participants have shown their talent in poster presentation. Topics include: machine learning, artificial intelligence, data mining etc. Events organized by various colleges include Anurag College Of Engineering, G. Narayanamma inst. of tech. and science, Brilliant Institute of Engineering & Technology.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>70 participants in project expo. Student’s performed excellently well and few of the praise worthy projects were, password based door lock system, solar tracking systems etc. Events were organized by six different colleges G.Narayanamma inst. of tech. and science, Brilliant Institute Of Engineering & Technology , Anurag College Of Engineering, Spoorthy Engineering College, JB Institute of Engineering and technology & matrusri engineering college
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>66 participants have shown their talent in paper presentation like Channel tracking for multi antenna, smart card Security, nanotube etc. Events were organized by three different colleges G.Narayanmma Institute of Technology & Science, Brillant Institute of Engineering & Technology, Anurag College of Engineering.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>65 students participated in quiz events and have won the applause by showing their knowledge in Robotics, Machine Learning, Data Mining etc. Events were organized by three different colleges Anurag College Of Engineering, G Narayanmma Institute of Technology & Science, Brillant Institute Of Engineering & Technology.
                                        </li>
                                    </ul>
                                </div>
                                <div className='pt-2'>
                                    <h6>2018 - 19

                                    </h6>
                                </div>
                                <div className='pt-2'>
                                    <div class="table-responsive mtop20">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr >
                                                    <td width="45" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span >S.NO</span><span ></span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p class="MsoNormal" ><b><span >SECTION</span></b><span ></span></p>
                                                    </td>
                                                    <td width="114" valign="top" >
                                                        <p class="MsoNormal" ><b><span >DATE</span></b><span ></span></p>
                                                    </td>
                                                    <td width="208" valign="top" >
                                                        <p class="MsoNormal" ><b><span >EVENT NAME</span></b><span ></span></p>
                                                    </td>
                                                    <td width="255" valign="top" >
                                                        <p class="MsoNormal" ><b><span >RESOURCE PERSONS</span></b><span ></span></p>
                                                    </td>
                                                    <td width="151" valign="top">
                                                        <p class="MsoNormal" ><b><span >NO OF STUDENTS ATTENDED</span></b><span ></span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="45" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                &nbsp;
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p class="MsoNormal" >
                                                            <b>
                                                                <span >
                                                                    &nbsp;
                                                                </span>
                                                            </b><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="114" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <b>
                                                                <span >
                                                                    &nbsp;
                                                                </span>
                                                            </b><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="208" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <b>
                                                                <span >
                                                                    &nbsp;
                                                                </span>
                                                            </b><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="255" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <b>
                                                                <span >
                                                                    &nbsp;
                                                                </span>
                                                            </b><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="151" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <b>
                                                                <span >
                                                                    &nbsp;
                                                                </span>
                                                            </b><span ></span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="45" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span >1</span><span ></span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p class="MsoNormal" ><span >II-ECEA</span><span ></span></p>
                                                    </td>
                                                    <td width="114" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                23,
                                                                24 JULY 2018
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="208" valign="top" >
                                                        <p class="MsoNormal" ><span >PCB DESIGN AND FABRICATION PROCESS</span><span ></span></p>
                                                    </td>
                                                    <td width="255" valign="top" >
                                                        <p class="MsoNormal" ><span >MD.NABI SHAREEF</span><span ></span></p>
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                (PGB ELECTRONICS PVT LIMITED)&nbsp;
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="151" valign="top">
                                                        <p class="MsoNormal" align="center" ><span >57</span><span ></span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="45" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span >2</span><span ></span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p class="MsoNormal" ><span >II-<g class="gr_ gr_41 gr-alert gr_spell gr_inline_cards gr_run_anim ContextualSpelling ins-del multiReplace" id="41" data-gr-id="41">ECEB</g></span><span ></span></p>
                                                    </td>
                                                    <td width="114" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                23,
                                                                24 JULY 2018
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="208" valign="top" >
                                                        <p class="MsoNormal" ><span >PCB DESIGN AND FABRICATION PROCESS</span><span ></span></p>
                                                    </td>
                                                    <td width="255" valign="top" >
                                                        <p class="MsoNormal" ><span >MD.NABI SHAREEF</span><span ></span></p>
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                (PGB ELECTRONICS PVT LIMITED)&nbsp;
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="151" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span >57</span><span ></span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="45" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span >3</span><span ></span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p class="MsoNormal" ><span >II-ECEC</span><span ></span></p>
                                                    </td>
                                                    <td width="114" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                25,
                                                                26 JULY 2018
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="208" valign="top" >
                                                        <p class="MsoNormal" ><span >PCB DESIGN AND FABRICATION PROCESS</span><span ></span></p>
                                                    </td>
                                                    <td width="255" valign="top" >
                                                        <p class="MsoNormal" ><span >MD.NABI SHAREEF</span><span ></span></p>
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                ( PGB ELECTRONICS PVT LIMITED)&nbsp;
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="151" valign="top">
                                                        <p class="MsoNormal" align="center" ><span >61</span><span ></span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="45" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span >4</span><span ></span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p class="MsoNormal" ><span >II-ECED</span><span ></span></p>
                                                    </td>
                                                    <td width="114" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                25,
                                                                26 JULY 2018
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="208" valign="top" >
                                                        <p class="MsoNormal" ><span >PCB DESIGN AND FABRICATION PROCESS</span><span ></span></p>
                                                    </td>
                                                    <td width="255" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                MD.NABI SHAREEF&nbsp;
                                                            </span><span ></span>
                                                        </p>
                                                        <p class="MsoNormal" >
                                                            <span >
                                                                ( PGB ELECTRONICS PVT LIMITED)&nbsp;
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="151" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span >59</span><span ></span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-2'>
                                    <h6>2017-18
                                    </h6>
                                </div>
                                <div className='pt-2'>
                                    <div class="table-responsive mtop20">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >S.No</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Student Name</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship Institute Name</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Date</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Duration</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >1</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >B.SNEHA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship:BHEL (Bharat Heavy Electrical Limited)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >18 MAY TO 2 JUNE 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >B.Bhargavi</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship: CITD</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="3" >
                                                        <p class="gmail-zw-paragraph" ><span >3</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="3" >
                                                        <p class="gmail-zw-paragraph" ><span >G.SRIVANI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BHEL</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                28 may to 11&nbsp;
                                                            </span><span ><g class="gr_ gr_306 gr-alert gr_spell gr_inline_cards gr_run_anim ContextualSpelling ins-del multiReplace" id="306" data-gr-id="306">june</g></span><span > 2018</span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BHEL</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >28 MAY 2018 TO 11 JUNE 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                T&amp;
                                                                SCS VVMBB
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >24-Jan-18</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >4</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >G PAVANI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                Outreach&nbsp;
                                                            </span><span >Course</span><span >:CITD</span><span >.</span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >5</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >G DHATHRI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >MGIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                13,
                                                                14 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="5" >
                                                        <p class="gmail-zw-paragraph" ><span >6</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="5" >
                                                        <p class="gmail-zw-paragraph" ><span >L.MOUNIKA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >MGIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                13,
                                                                14 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >HITAM</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >17-Feb-18</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >AAI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >25JUN2018 TO 07JULY 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >TSCS</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >24-Jan-18</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >TSCS</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >24-Jan-18</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >7</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >MALAVIKA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >MGIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                13,
                                                                14 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="4" >
                                                        <p class="gmail-zw-paragraph" ><span >8</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="4" >
                                                        <p class="gmail-zw-paragraph" ><span >M PRIYANKA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >9</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >M MOUNIKA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Outreach Course: CITD.</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >10</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >M MOUNIKA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >MGIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                13,
                                                                14 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="2" >
                                                        <p class="gmail-zw-paragraph" ><span >11</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="2" >
                                                        <p class="gmail-zw-paragraph" ><span >P NAVYASREE</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >16-Mar-18</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >ONE DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >Internship:ODF</span><span >
                                                                (Ordnance Factory)&nbsp;
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >18 JUN 2018 TO 3 JULY 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >15DAYS</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="5" >
                                                        <p class="gmail-zw-paragraph" ><span >12</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="5" >
                                                        <p class="gmail-zw-paragraph" ><span >P.SRAVANI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >Internship:ODF</span><span >
                                                                (Ordnance Factory)&nbsp;
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >18 JUN 2018 TO 3 JULY 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >15DAYS</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >13</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >P.SANDHYA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship:BHEL (Bharat Heavy Electrical Limited)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                28 may to 11&nbsp;
                                                            </span><span ><g class="gr_ gr_307 gr-alert gr_spell gr_inline_cards gr_run_anim ContextualSpelling ins-del multiReplace" id="307" data-gr-id="307">june</g></span><span > 2018</span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >14</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >P.AKHILA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship:BHEL (Bharat Heavy Electrical Limited)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >18 JUNE TO 2 JULY 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="6" >
                                                        <p class="gmail-zw-paragraph" ><span >15</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="6" >
                                                        <p class="gmail-zw-paragraph" ><span >R.SUDHA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship: CITD</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >16-Mar-18</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >ONE DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >16</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >P.SIRISHA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship:BHEL (Bharat Heavy Electrical Limited)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                28 may to 11&nbsp;
                                                            </span><span >June</span><span > 2018</span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >S.SWETHA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship: CITD</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="3" >
                                                        <p class="gmail-zw-paragraph" ><span >18</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="3" >
                                                        <p class="gmail-zw-paragraph" ><span >S.JHANSI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship: CITD</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                6 &amp;
                                                                7 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="2" >
                                                        <p class="gmail-zw-paragraph" ><span >19</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="2" >
                                                        <p class="gmail-zw-paragraph" ><span >P NIHARIKA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship:BHEL (Bharat Heavy Electrical Limited)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                28 may to 11&nbsp;
                                                            </span><span >June</span><span > 2018</span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >GNITS</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                23,
                                                                24 FEB 2018
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="2" >
                                                        <p class="gmail-zw-paragraph" ><span >20</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="2" >
                                                        <p class="gmail-zw-paragraph" ><span >T.SAI KIRAN</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >TSCS</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >24-Jan-18</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship:BHEL (Bharat Heavy Electrical Limited)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                28 may to 11&nbsp;
                                                            </span><span >June</span><span > 2018</span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >21</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Divya</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >MGIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                13,
                                                                14 OCT 2017
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >22</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >A.Bhavya</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >Internship:ODF</span><span >
                                                                (Ordnance Factory)&nbsp;
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >26-Feb 2018 to 14-March 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >23</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >A.Nikitha</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship: CITD</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >24</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >A RAMYA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Outreach Course: CITD.</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >25</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >B.Navyasri</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                Outreach&nbsp;
                                                            </span><span >Course</span><span >:CITD</span><span >.</span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >26</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >CH.RAMYA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                Outreach&nbsp;
                                                            </span><span >Course</span><span >:CITD</span><span >.</span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="2" >
                                                        <p class="gmail-zw-paragraph" ><span >27</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="2" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                C.&nbsp;
                                                            </span><span >Shivani</span><span >
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internships:CITD</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Workshops:Akshaya</span><span > <g class="gr_ gr_308 gr-alert gr_spell gr_inline_cards gr_run_anim ContextualSpelling ins-del multiReplace" id="308" data-gr-id="308">Automations</g>(IOT)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >25 June 2018-27-June 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >28</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >D.V.S.Akhila</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Outreach Course: CITD.</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31-Jan 2018 to 12-Feb 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >29</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >D.V.S.Akhila</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Quiz in Tech Fest at MGIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >13-Oct-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >30</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >
                                                                D.&nbsp;
                                                            </span><span >Sujani</span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >Internship:ODF</span><span >
                                                                (Ordnance Factory)&nbsp;
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >26-Feb 2018 to 14-March 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >31</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >D.Pavani</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >Internship:ODF</span><span >(Ordnance Factory)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >19 Feb to 6 March 2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >32</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >D.Pravalika</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span >Internship:ODF</span><span >
                                                                (Ordnance Factory)&nbsp;
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >05-02-2018 to 20-02-2018</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span >12 Days</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-2'>
                                    <h6>2016-17
                                    </h6>
                                </div>
                                <div>
                                    <div class="table-responsive mtop20">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="35893" _msthash="437">S.No</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="175617" _msthash="438">Student Name</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="564824" _msthash="439">Internship Institute Name</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="42978" _msthash="440">Date</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="116051" _msthash="441">Duration</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="4459" _msthash="442">1</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="80795" _msthash="443">G DHATHRI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="250172" _msthash="444">Internship: BSNL</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="365716" _msthash="445">19-JUNE-2017 TO 30-JUN-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="71786" _msthash="446">2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="4550" _msthash="447">2</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82264" _msthash="448">L MOUNIKA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="46020" _msthash="449">JNTUH</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span _msttexthash="130624" _msthash="450">
                                                                30&amp;
                                                                31 JULY 2016
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55146" _msthash="451">2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="4641" _msthash="452">3</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="79300" _msthash="453">MALAVIKA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="454">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82719" _msthash="455">22-Jan-16</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="38610" _msthash="456">1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="4732" _msthash="457">4</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="122603" _msthash="458">A.Nikitha</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="245115" _msthash="459">Internship: BHEL</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="318565" _msthash="460">31-May 17 to 12-Jun-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="71786" _msthash="461">2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="4823" _msthash="462">5</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="54925" _msthash="463">A RAMYA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="464">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="103974" _msthash="465"> 21-Jan-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="38610" _msthash="466">1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="4914" _msthash="467">6</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="124085" _msthash="468">A.Preethi</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="2434952" _msthash="469">Participation certificate of Indian Engineering Olympiad (IEO)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="73710" _msthash="470">23-02-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="38610" _msthash="471">1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="5005" _msthash="472">7</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="149669" _msthash="473">B.Navyasri</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="258271" _msthash="474"> Internship: BHEL.</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="318565" _msthash="475">31-May 17 to 12-Jun-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="71786" _msthash="476">2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="5096" _msthash="477">8</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="101933" _msthash="478">Ch.Ramya</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="277277" _msthash="479">Internships: CITD</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="318565" _msthash="480">31-May 17 to 12-Jun-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="71786" _msthash="481">2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="5187" _msthash="482">9</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="124423" _msthash="483"><span _mstmutation="1" _istranslated="1"> C. </span> <span _mstmutation="1" _istranslated="1">Shivani</span></font><span >
                                                                &nbsp;
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="651092" _msthash="484"> Advanced Training Institute</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="320177" _msthash="485">12-Jun 17 to 16-Jun-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55328" _msthash="486">4 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="2">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9451" _msthash="487">10</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="2" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="165204" _msthash="488">D.V.S.Akhila</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="245115" _msthash="489">Internship: BHEL</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="257140" _msthash="490">31-May 17 - 12-Jun 2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="71786" _msthash="491">2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="234208" _msthash="492"><span _mstmutation="1">
                                                                Quiz in&nbsp;
                                                            </span><span _mstmutation="1"><g class="gr_ gr_224 gr-alert gr_spell gr_inline_cards gr_run_anim ContextualSpelling ins-del multiReplace" id="224" data-gr-id="224">Srifest</g></span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="51129" _msthash="493">Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="41938" _msthash="494">1 day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9555" _msthash="495">11</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="77688" _msthash="496">D.PAVANI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="234208" _msthash="497"><span _mstmutation="1">
                                                                Quiz in&nbsp;
                                                            </span><span _mstmutation="1"><g class="gr_ gr_225 gr-alert gr_spell gr_inline_cards gr_run_anim ContextualSpelling ins-del multiReplace" id="225" data-gr-id="225">Srifest</g></span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="51129" _msthash="498">Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="41938" _msthash="499">1 day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9659" _msthash="500">12</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="99918" _msthash="501">G.Akhila</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="245115" _msthash="502">Internship:BHEL</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="318565" _msthash="503">31-May 17 to 12-Jun-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="71786" _msthash="504">2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9763" _msthash="505">13</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="311467" _msthash="506">HARICHANDANA PEDDINTI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="507">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="103974" _msthash="508"> 21-Jan-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="38610" _msthash="509">1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9867" _msthash="510">14</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="123968" _msthash="511"><span _mstmutation="1" _istranslated="1"> K. </span> <span _mstmutation="1" _istranslated="1">Thanuja</span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="227344" _msthash="512">Quiz at Sri Fest</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="103974" _msthash="513"> 21-Jan-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="38610" _msthash="514">1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9971" _msthash="515">15</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="168740" _msthash="516"><span _mstmutation="1" _istranslated="1"> K. </span> <span _mstmutation="1" _istranslated="1">Rekhasree</span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="1518803" _msthash="517">Participation certificate for Workshop in BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="281775" _msthash="518">23-Dec 2016-24-Dec 2016</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55146" _msthash="519">2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="10075" _msthash="520">16</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="125255" _msthash="521"><span _mstmutation="1" _istranslated="1"> M. </span> <span _mstmutation="1" _istranslated="1">Mounika</span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="431652" _msthash="522">MATLAB workshop in BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="281775" _msthash="523">23-Dec 2016-24-Dec 2016</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55146" _msthash="524">2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="10179" _msthash="525">17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="121017" _msthash="526"><span _mstmutation="1" _istranslated="1"> N. </span> <span _mstmutation="1" _istranslated="1">Deepika</span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="600808" _msthash="527"><span _mstmutation="1">
                                                                1st prize in Quiz in&nbsp;
                                                            </span><span _mstmutation="1">Swec</span><span _mstmutation="1"> Fest</span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="103974" _msthash="528"> 21-Jan-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="38610" _msthash="529">1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="10283" _msthash="530">18</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="105898" _msthash="531">P.Swathi</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="431652" _msthash="532">MATLAB workshop in BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="281775" _msthash="533">23-Dec 2016-24-Dec 2016</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55146" _msthash="534">2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="10387" _msthash="535">19</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="146549" _msthash="536"><span _mstmutation="1" _istranslated="1"> S. </span> <span _mstmutation="1" _istranslated="1">Pushkala</span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" _msttexthash="841360" _msthash="537"><span >Internship:ODF</span><span >(Ordnance Factory)</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="361127" _msthash="538">1-Jun-2017 to 15-Jun-2017</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="71786" _msthash="539">2 Weeks</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9542" _msthash="540">20</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="146549" _msthash="541"><span _mstmutation="1" _istranslated="1"> S. </span> <span _mstmutation="1" _istranslated="1">Pushkala</span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="431652" _msthash="542">MATLAB workshop in BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="281775" _msthash="543">23-Dec 2016-24-Dec 2016</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55146" _msthash="544">2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9646" _msthash="545">21</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <font _mstmutation="1" _msttexthash="122122" _msthash="546"><span _mstmutation="1" _istranslated="1"> S. </span> <span _mstmutation="1" _istranslated="1">Mamatha</span></font>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="431652" _msthash="547">MATLAB workshop in BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="281775" _msthash="548">23-Dec 2016-24-Dec 2016</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55146" _msthash="549">2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9750" _msthash="550">22</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="90506" _msthash="551">A.MANISHA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="552">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="553">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="554">1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9854" _msthash="555">23</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="76167" _msthash="556">C.ANITHA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="557">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="558">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="559">1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9958" _msthash="560">24</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="107146" _msthash="561">D.PRASANNA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="562">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="563">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="564">1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="10062" _msthash="565">25</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="88829" _msthash="566">G.SAILAJA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="567">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="568">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="569">1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="10166" _msthash="570">26</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="69485" _msthash="571"> K SUSHMA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="572">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="573">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="38610" _msthash="574">1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" rowspan="3">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="10270" _msthash="575">27</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" rowspan="3" >
                                                        <p class="gmail-zw-paragraph" _msttexthash="333385" _msthash="576"><span >P.Likhitha</span><span > Krishna</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="45903" _msthash="577">GNITS</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="83746" _msthash="578">02-Mar-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="38610" _msthash="579">1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="46046" _msthash="580">BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="94861" _msthash="581">23 24 DEC2016</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55146" _msthash="582">2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="46020" _msthash="583">JNTUH</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" >
                                                            <span _msttexthash="130624" _msthash="584">
                                                                30&amp;
                                                                31 JULY 2016
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55146" _msthash="585">2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="10374" _msthash="586">28</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="106873" _msthash="587">R.NAGAVANI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="588">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="589">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="590">1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="10478" _msthash="591">29</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="94146" _msthash="592">N.MOUNIKA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="593">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="594">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="595">1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9633" _msthash="596">30</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="95667" _msthash="597">S M NANDINI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="598">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="599">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="38610" _msthash="600">1 Day</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9737" _msthash="601">31</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="92300" _msthash="602">S.HARITHA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="603">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="604">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="605">1 DAY</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td">
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9841" _msthash="606">32</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="113750" _msthash="607">T VAISHNAVI</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="46046" _msthash="608">BVRIT</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="94861" _msthash="609">23 24 DEC2016</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="55146" _msthash="610">2 Days</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="9945" _msthash="611">33</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="96564" _msthash="612">Y.SUKANYA</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="613">SWEC</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="82810" _msthash="614">21-Jan-17</span></p>
                                                    </td>
                                                    <td class="gmail-zw-td" >
                                                        <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="615">1 DAY</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-2'>
                                    <h6>2015-16
                                    </h6>
                                </div>
                                <div class="table-responsive mtop20">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="35893" _msthash="617">S.No</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="175617" _msthash="618">Student Name</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="564824" _msthash="619">Internship Institute Name</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="42978" _msthash="620">Date</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="116051" _msthash="621">Duration</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="4459" _msthash="622">1</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="82264" _msthash="623">L MOUNIKA</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="624">SWEC</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="82719" _msthash="625">22-Jan-16</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="626">1 DAY</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="4550" _msthash="627">2</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="95667" _msthash="628">S M NANDINI</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="629">SWEC</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="82719" _msthash="630">22-Jan-16</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="631">1 DAY</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" >
                                                        <span >
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" >
                                                        <span >
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="46020" _msthash="632">JNTUH</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="237341" _msthash="633">28 DEC 2015 TO 5 JAN 2016</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="41275" _msthash="634">1 WEEK</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="4641" _msthash="635">3</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="113750" _msthash="636">T VAISHNAVI</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="46020" _msthash="637">JNTUH</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="196248" _msthash="638">29 SEPT TO 1 COT 2015</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="42757" _msthash="639">3 DAYS</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="gmail-zw-td" rowspan="2" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="4732" _msthash="640">4</span></p>
                                                </td>
                                                <td class="gmail-zw-td" rowspan="2" >
                                                    <p class="gmail-zw-paragraph" _msttexthash="333385" _msthash="641"><span >P.Likhitha</span><span > Krishna</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="642">SWEC</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="82823" _msthash="643">23-Jan-16</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="30706" _msthash="644">1 DAY</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="33384" _msthash="645">SWEC</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="196248" _msthash="646">29 SEPT TO 1 COT 2015</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="42757" _msthash="647">3 DAYS</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="4823" _msthash="648">5</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="94328" _msthash="649">P.MOUNIKA</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" >
                                                        <font _mstmutation="1" _msttexthash="274794" _msthash="650"><span _mstmutation="1">INDUSTRIAL</span><span _mstmutation="1"> TRAINING</span></font><span >
                                                            &nbsp;
                                                            &nbsp;
                                                            &nbsp;
                                                            &nbsp;
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="141700" _msthash="651">(PIXEL QUEST)</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="165828" _msthash="652">9/5/2016-5/6/2016</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="54496" _msthash="653">4WEEKS</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="4914" _msthash="654">6</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="93054" _msthash="655">B.MOUNIKA</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" >
                                                        <font _mstmutation="1" _msttexthash="274794" _msthash="656"><span _mstmutation="1">
                                                            INDUSTRIAL&nbsp;
                                                        </span><span _mstmutation="1">TRAINING</span></font><span >
                                                            &nbsp;
                                                            &nbsp;
                                                            &nbsp;
                                                            &nbsp;
                                                            &nbsp;
                                                        </span>
                                                    </p>
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="141700" _msthash="657">(PIXEL QUEST)</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="165828" _msthash="658">9/5/2016-5/6/2016</span></p>
                                                </td>
                                                <td class="gmail-zw-td" >
                                                    <p class="gmail-zw-paragraph" ><span _msttexthash="54496" _msthash="659">4WEEKS</span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6>Student Achievements
                                </h6>
                                <div class="table-responsive mtop20">
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th><strong _msttexthash="31733" _msthash="661">S.NO</strong></th>
                                                <th class="text-capitalize"><strong _msttexthash="230581" _msthash="662">NAME OF THE STUDENT</strong></th>
                                                <th class="text-capitalize"><strong _msttexthash="44291" _msthash="663">TOPIC</strong></th>
                                                <th class="text-capitalize"><strong _msttexthash="45448" _msthash="664">EVENT</strong></th>
                                                <th class="text-capitalize"><strong _msttexthash="142233" _msthash="665">VENUE AND DATE</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td _msttexthash="4459" _msthash="666">1</td>
                                                <td _msttexthash="147745" _msthash="667">M.Gayathri</td>
                                                <td _msttexthash="186069" _msthash="668">Picturesque</td>
                                                <td _msttexthash="341549" _msthash="669"> Symposium PROMETHEAN </td>
                                                <td _msttexthash="1155310" _msthash="670"> B.V Raju institute of technology , 24 dec ,2016 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="4550" _msthash="671">2</td>
                                                <td _msttexthash="85137" _msthash="672">K.Kavya</td>
                                                <td _msttexthash="209144" _msthash="673">Enigmatology</td>
                                                <td _msttexthash="341549" _msthash="674"> Symposium PROMETHEAN </td>
                                                <td _msttexthash="1155310" _msthash="675"> B.V Raju institute of technology , 24 dec ,2016 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="4641" _msthash="676">3</td>
                                                <td _msttexthash="47697" _msthash="677">Sony</td>
                                                <td _msttexthash="248482" _msthash="678">Light Seeker Bot</td>
                                                <td _msttexthash="103415" _msthash="679">S.R.I -FEST</td>
                                                <td _msttexthash="1416311" _msthash="680"> Sridevi Womens engineering college, 21stJan 2107 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="4732" _msthash="681">4</td>
                                                <td _msttexthash="122915" _msthash="682">K.Nandini</td>
                                                <td _msttexthash="456716" _msthash="683">Fire Monitoring System</td>
                                                <td _msttexthash="103415" _msthash="684">S.R.I -FEST</td>
                                                <td _msttexthash="1416311" _msthash="685"> SrideviWomensengineering college, 21stJan 2107 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="4823" _msthash="686">5</td>
                                                <td _msttexthash="240305" _msthash="687">B.Hyma chandana</td>
                                                <td _msttexthash="209144" _msthash="688">Enigmatology</td>
                                                <td _msttexthash="288223" _msthash="689"> Symposium SHODHANA </td>
                                                <td _msttexthash="1333085" _msthash="690"> SrideviWomensengineering college, 14 MARCH 2015 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="4914" _msthash="691">6</td>
                                                <td _msttexthash="156806" _msthash="692">A Pratyusha</td>
                                                <td _msttexthash="209144" _msthash="693">Enigmatology</td>
                                                <td _msttexthash="288223" _msthash="694"> Symposium SHODHANA </td>
                                                <td _msttexthash="1333085" _msthash="695"> Sridevi Womens engineering college, 14 MARCH 2015 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="5005" _msthash="696">7</td>
                                                <td _msttexthash="75998" _msthash="697">Anusha</td>
                                                <td _msttexthash="248482" _msthash="698">Light Seeker Bot</td>
                                                <td _msttexthash="103415" _msthash="699">S.R.I -FEST</td>
                                                <td _msttexthash="1416311" _msthash="700"> Sridevi Womens engineering college, 21stJan 2107 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="5096" _msthash="701">8</td>
                                                <td _msttexthash="115687" _msthash="702">Susmitha</td>
                                                <td _msttexthash="257361" _msthash="703">GSM based system</td>
                                                <td _msttexthash="103415" _msthash="704">S.R.I -FEST</td>
                                                <td _msttexthash="1416311" _msthash="705"> SrideviWomensengineering college, 21stJan 2107 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="5187" _msthash="706">9</td>
                                                <td _msttexthash="120861" _msthash="707">K. Sai Siri</td>
                                                <td _msttexthash="114400" _msthash="708">'N' Kindle</td>
                                                <td _msttexthash="388609" _msthash="709">Poster Presentation</td>
                                                <td _msttexthash="1288326" _msthash="710"> Sridevi Womens engineering college, 22 dec 2015 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="9451" _msthash="711">10</td>
                                                <td _msttexthash="171509" _msthash="712">A.Sushmitha</td>
                                                <td _msttexthash="114400" _msthash="713">'N' Kindle</td>
                                                <td _msttexthash="388609" _msthash="714">Poster Presentation</td>
                                                <td _msttexthash="1288326" _msthash="715"> Sridevi Womens engineering college, 22 dec 2015 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="9555" _msthash="716">11</td>
                                                <td _msttexthash="122434" _msthash="717">P.Naveena</td>
                                                <td _msttexthash="114400" _msthash="718">'N' Kindle</td>
                                                <td _msttexthash="388609" _msthash="719">Poster Presentation</td>
                                                <td _msttexthash="1288326" _msthash="720"> Sridevi Womens engineering college, 22 dec 2015 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="9659" _msthash="721">12</td>
                                                <td _msttexthash="93210" _msthash="722">Likitha</td>
                                                <td _msttexthash="248482" _msthash="723">Light Seeker Bot</td>
                                                <td _msttexthash="103415" _msthash="724">S.R.I -FEST</td>
                                                <td _msttexthash="1416311" _msthash="725"> Sridevi Womens engineering college, 21stJan 2107 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="9763" _msthash="726">13</td>
                                                <td _msttexthash="115934" _msthash="727">Sowjanya</td>
                                                <td _msttexthash="248482" _msthash="728">Light Seeker Bot</td>
                                                <td _msttexthash="103415" _msthash="729">S.R.I -FEST</td>
                                                <td _msttexthash="1416311" _msthash="730"> Sridevi Womens engineering college, 21stJan 2107 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="9867" _msthash="731">14</td>
                                                <td _msttexthash="174993" _msthash="732">K.Pratyusha</td>
                                                <td _msttexthash="111371" _msthash="733">Fact Hunt</td>
                                                <td _msttexthash="259441" _msthash="734">FutureSastra2017</td>
                                                <td _msttexthash="1351896" _msthash="735"> Malla Reddy Engineering College 7th-8thjan 2107 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="9971" _msthash="736">15</td>
                                                <td _msttexthash="103337" _msthash="737">K.Vinila</td>
                                                <td _msttexthash="226031" _msthash="738">Technical Hunt</td>
                                                <td _msttexthash="341549" _msthash="739"> Symposium PROMETHEAN </td>
                                                <td _msttexthash="1250704" _msthash="740"> B.V Raju institute of technology , 23-24 dec ,2016 </td>
                                            </tr>
                                            <tr>
                                                <td _msttexthash="10075" _msthash="741">16</td>
                                                <td _msttexthash="103337" _msthash="742">K.Vinila</td>
                                                <td _msttexthash="209144" _msthash="743">Enigmatology</td>
                                                <td _msttexthash="341549" _msthash="744"> Symposium PROMETHEAN </td>
                                                <td _msttexthash="1250704" _msthash="745"> B.VRajuinstitute of technology , 23-24dec,2016 </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}



export default EceStudentPublications
