import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';


const CSEbooksPublished = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Faculty performance and appraisal development system
                                </h2>
                                <h6 className='pt-3'>Books Published
                                </h6>
                                <div className=''>
                                    <div class="table-responsive" >
                                        <table class="table table-bordered">
                                            <thead class="bg-gray">
                                                <tr>
                                                    <th><strong>S.No</strong></th>
                                                    <th><strong>Faculty Name </strong></th>
                                                    <th><strong>Type </strong></th>
                                                    <th><strong>Title  </strong></th>
                                                    <th><strong>Published Month &amp; Year  </strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Dr. T.K.S. Rathish Babu </td>
                                                    <td>Book Chapter </td>
                                                    <td>Energy optimized techniques in cloud and fog computing </td>
                                                    <td>17-Apr-21</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Dr. A. Gauthami Latha </td>
                                                    <td>Book </td>
                                                    <td>Introduction to Machine Learning, A Perspective Approach </td>
                                                    <td>Mar-21</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Dr. V. Anantha Krishna </td>
                                                    <td>Book</td>
                                                    <td>Introduction to Machine Learning, A Perspective Approach </td>
                                                    <td>Mar-21</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Dr. B. Narendra Kumar </td>
                                                    <td>Book </td>
                                                    <td>Introduction to Machine Learning, A Perspective Approach </td>
                                                    <td>Mar-21</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default CSEbooksPublished
