import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import FacilitiesLeftTab from './FacilitiesLeftTab';
import hostelImg from '../Facilities/bus/Hostel1.jpeg'
import RoomImg from '../Facilities/bus/Room.jpeg'


const Hostel = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <FacilitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>SWEC Campus Hostel
                                </h2>
                                <p>SWEC Campus Hostel offers a perfect blend of comfort and care that you can experience at your home. Our hostel is located within the college premises and is exclusively for our students. We have a very vigilant hostel security and caring warden for our students.
                                </p>
                                <p>The hostel is nested amidst nature in a peaceful and serene location that offers a perfect environment to study and unwind after a tiring day at the college. The hostel consists of multiple student rooms, a common room along with a common dining area. The common room has been provided with a television for entertainment and recreational purposes. Along with a caretaker to help the students when necessary, we also have a resident hostel supervisor/ warden to take care of the hostel and student needs.
                                </p>
                                <p>The hostel rooms are spacious and well – ventilated with ample space for every student to make their stay as comfortable as possible. The rooms are well maintained, clean and hygienic along with all the basic amenities that one could ask for. Most importantly, the hostel is one hundred percent ragging free and since the security of the students is our highest priority, we have an anti-ragging squad constantly monitoring the students at all times.
                                </p>
                                <h6 className='py-3'>Hostel Amenities and Highlights
                                </h6>
                                <div class="apply-list border-0">

                                    <ul class="">
                                        <li><i class="ri-checkbox-circle-line"></i>Clean, comfortable and spacious rooms
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>An attached bathroom in every shared room with 24X7 water supply
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Hot water provision during prescribed timings
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>A comfortable cot and a storage cupboard are provided, however, students must bring their own mattress (30”X 72”), pillow and bed sheets/blankets.
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Hot, nutritious, delicious and hygienically cooked breakfast, lunch and dinner will be provided.
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Safe and purified drinking water supply through RO plant
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Regular medical checkup facility
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Laundry available at additional cost
 </li>

                                        <li><i class="ri-checkbox-circle-line"></i>Medical Insurance for emergency cases
 </li>

                                        <li><i class="ri-checkbox-circle-line"></i>24X7 ambulance service available
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Wi-Fi and 24x7 generator back up facility
 </li>

                                        <li><i class="ri-checkbox-circle-line"></i>On campus mini store
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Eco friendly Incinerator to destroy female hygiene products
</li>
                                        <li><i class="ri-checkbox-circle-line"></i>24X7 CCTV camera surveillance
 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Regular Covid-19 sanitization of premises and rooms
 </li>
                                        
                                    </ul>
                                </div>


                                <img src={hostelImg} loading='lazy' alt='hostelImg' className='img-fluid pb-3' /> 
                                <img src={RoomImg} loading='lazy' alt='room' className='img-fluid' /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default Hostel
