// import React from 'react'
// import Navbar from '../../navbar/Navbar'
// import Footer from '../../footer/Footer';
// import DepartmentTab from '../mainTags/CSEDepartmentTab';
// import EceleftTab from './EceleftTab';

// const EceFaculty = () => {
//     return (
//         <div>
//             <Navbar />
//             <div className="graduate-admission pt-lg-5 pt-4 pb-70">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
//                             <EceleftTab />
//                         </div>
//                         <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
//                             <div className="how-to-apply">
//                                 <h2>Faculty
//                                 </h2>

//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     )
// }

// export default EceFaculty




import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';
import React, { Component, useState } from 'react';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import '../department.css'
import Loader from '../colorful_loader.gif'

export class EEEFaculty extends Component {
    // static displayName = CSEFaculty.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { csefaculty: [], loading: true };
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    componentDidMount() {
        this.populateCSEFaculty();
    }


    renderCSEFacultyTable(CSEFacultydata, refreshData) {
        const staticUrl = 'https://admin.swec.ac.in/';
        return (

            <div>

                <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                    data-id-field="name"
                    data-page-list="[5, 10, 25, 50, 100, ALL]"
                    data-page-size="10" className="border table-bordered">
                    <thead className="bg-light-gradient">
                        <tr>
                            <th className="fontSmall fontBold">Photo</th>
                            <th className="fontSmall fontBold">Faculty Name	</th>
                            <th className="fontSmall fontBold">Designation	</th>
                            <th className="fontSmall fontBold">	Highest Qualification</th>
                            <th className="fontSmall fontBold">Experience</th>
                            <th className="fontSmall fontBold">Specialized Subject</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CSEFacultydata.filter(item => item.Specialized_Subject != '').map((x, idx) =>
                            <tr key={x.id}>
                                {/* <td>{idx + 1}</td> */}
                                <td>
                                    <img className='faculty-S-Image' src={`${staticUrl}${x.Photo}`} />
                                </td>
                                <td className='text-bold'>
                                    {x.Faculty_Name}<br />  <span>JNTUH ID : </span>{x.Jntuh_Id}
                                </td>
                                <td>
                                    {x.Designation}
                                </td>
                                <td>
                                    {x.Highest_Qualification}
                                </td>
                                <td>
                                    {x.Exp_In_Year} {x.Exp_In_Month}
                                </td>
                                <td>
                                    {x.Specialized_Subject}
                                </td>

                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* Nonteaching */}


                <div className='py-4'>
                    <h5>Non Teaching</h5>
                </div>
                <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                    data-id-field="name"
                    data-page-list="[5, 10, 25, 50, 100, ALL]"
                    data-page-size="10" className="border table-bordered">
                    <thead className="bg-light-gradient">
                        <tr>
                            <th className="fontSmall fontBold">Photo</th>
                            <th className="fontSmall fontBold">Faculty Name	</th>
                            <th className="fontSmall fontBold">Designation	</th>
                            <th className="fontSmall fontBold">	Highest Qualification</th>
                            <th className="fontSmall fontBold">Experience</th>
                            <th className="fontSmall fontBold">Specialized Subject</th>
                        </tr>
                    </thead>
                    <tbody>

                        {CSEFacultydata.filter(item => item.Specialized_Subject == '').map((x, idx) =>
                            <tr key={x.id}>
                                {/* <td>{idx + 1}</td> */}
                                <td>
                                    <img src={`${staticUrl}${x.Photo}`} width="80" />
                                </td>
                                <td className='text-bold'>
                                    {x.Faculty_Name}<br />  <span>JNTUH ID : </span>{x.Jntuh_Id}
                                </td>
                                <td>
                                    {x.Designation}
                                </td>
                                <td>
                                    {x.Highest_Qualification}
                                </td>
                                <td>
                                    {x.Exp_In_Year} {x.Exp_In_Month}
                                </td>
                                <td>
                                    {x.Specialized_Subject}
                                </td>

                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>





        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateCSEFaculty();
    };
    render() {
        let contents = this.state.loading
            ? /*<p><em>Loading...</em></p>*/ $(".load").show()
            : this.renderCSEFacultyTable(this.state.csefaculty, this.refreshData);
        $(".load").hide()
        return (
            <div >
                <Navbar />
                <div className="graduate-admission pt-lg-5 pt-4 pb-70 ">
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                                <DepartmentTab />
                            </div>
                            <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                                <div className="how-to-apply">
                                    <h2>Teaching Faculty
                                    </h2>
                                    <div className="tabCustom mb-5 mt-2">
                                        <div className="container  px-0 tab-pane active">
                                            <div className={this.state.Message ? "alert alert-danger alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                                                <strong>
                                                    {this.state.Message}
                                                </strong>
                                            </div>
                                            <div className="card custom-card">
                                                <div className="card-body p-2">
                                                    {contents}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="load text-center mx-auto" style={{ 'display': 'block' }}>
                                        <img src={Loader} alt="loading icon" width="150" />
                                            <p className="text-center">Data loading please wait...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />


            </div>

        )
    }
    async populateCSEFaculty() {
        const response = await fetch('http://admin.swec.ac.in/api/Getfaculty?Branch=EEE&status=true', {
            method: 'get',
            // headers: {
            //     'Access-Control-Allow-Credentials':'true',
            //     'Content-Type': 'application/json',
            // }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            this.setState({ csefaculty: data, loading: false });
        }
    }
}



export default EEEFaculty;



