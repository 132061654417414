import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../footer/Footer';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import video1 from './swecTraining_Internship_and placement _ SWEC _ TV9Education_Summit_2022.mp4';
import video2 from './Haritha_Haram _ Sridevi Womens Engineering College Student _ Hybiz _ SWEC.mp4';
import video3 from './Minister Sabitha Indra Reddy Congratulations to Sridevi Womens Engineering College _ SWEC _ V6NEWS.mp4';

export default class extends React.Component {
    render() {
        return (
            <div>
                <div className=''>
                    <div className="academic-area pt-100 bg-f4f6f9">
                        <div className="section-title pb-2">
                            <h2> Photo and Video Gallery

                            </h2>
                        </div>
                        <div className="description">
                            <div className="container p-0 ">
                                <nav className='d-flex justify-content-center'>
                                    <div className="nav nav-tabs d-flex justify-content-center photoTabNavb" id="nav-tab" role="tablist">

                                        <button className="nav-link" id="nav-instructor-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-instructor" type="button" role="tab" aria-controls="nav-instructor"
                                            aria-selected="false" tabindex="-1"> Videos</button>
                                        <button className="nav-link active" id="nav-reviews-tab" data-bs-toggle="tab"
                                            data-bs-target="#nav-reviews" type="button" role="tab" aria-controls="nav-reviews"
                                            aria-selected="true">Photos</button>
                                    </div>
                                </nav>
                                <div className="tab-content pt-4" id="nav-tabContent">

                                    <div className="tab-pane fade" id="nav-instructor" role="tabpanel" aria-labelledby="nav-instructor-tab">
                                        <div className="reviews">
                                            <div className="row justify-content-center px-3">
                                                <div className="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up"
                                                    data-aos-duration="1200" data-aos-delay="200" data-aos-once="true">
                                                    <div className="single-stories-card style2">
                                                        <video width="100%" height="300" controls="controls" type="video/mp4" autoplay muted preload="none">
                                                            <source src={video1} type="video/mp4" />
                                                        </video>
                                                        {/* <iframe src={video1} className="lazy-youtube"></iframe> */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up"
                                                    data-aos-duration="1200" data-aos-delay="400" data-aos-once="true">
                                                    <div className="single-stories-card style2">
                                                        <video width="100%" height="300" controls="controls" type="video/mp4" autoplay muted preload="none">
                                                            <source src={video2} type="video/mp4" />
                                                        </video>
                                                        {/* <iframe src={video2}
                                                            className="lazy-youtube"
                                                        ></iframe> */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up"
                                                    data-aos-duration="1200" data-aos-delay="600" data-aos-once="true">
                                                    <div className="single-stories-card style2">
                                                        <video width="100%" height="300" controls="controls" type="video/mp4" autoplay muted preload="none">
                                                            <source src={video3} type="video/mp4" />
                                                        </video>
                                                        {/* <iframe src={video3}
                                                            className="lazy-youtube"
                                                        ></iframe> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="more-health-care text-center pt-3">
                                                <p>
                                                    <Link to="/news-and-events/news" className="read-more-btn active"> See all Videos<i className="flaticon-next"></i></Link></p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade active show" id="nav-reviews" role="tabpanel"
                                        aria-labelledby="nav-reviews-tab">
                                        <div className="reviews">

                                            <div class="row justify-content-center px-3">
                                                <div class="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" data-aos-once="true">
                                                    <div class="single-campus-card">
                                                        <div class="img">
                                                            <Link to="/news-and-events/gallery/vidh-youth-2018">
                                                                <img src="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/21.jpg?updatedAt=1702534202516" alt="Image" className='img-fluid' loading='lazy' width="100%" height="auto" />
                                                            </Link>
                                                        </div>
                                                        <div class="campus-content text-center">
                                                            <Link to="/news-and-events/gallery/vidh-youth-2018">
                                                                <h3>Vidh Youth 18</h3>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" data-aos-once="true">
                                                    <div class="single-campus-card">
                                                        <div class="img">
                                                            <Link to="/news-and-events/events/maithri-2018"><img src="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/g2.jpg?updatedAt=1702534348898" alt="Image" className='img-fluid' loading='lazy' width="100%" height="100%" /></Link>
                                                        </div>
                                                        <div class="campus-content text-center">
                                                            <Link to="/news-and-events/events/maithri-2018">
                                                                <h3>Maithri 2018</h3>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" data-aos-once="true">
                                                    <div class="single-campus-card">
                                                        <div class="img">
                                                            <Link to="/news-and-events/gallery/flashmob-2018"><img src="/assets/img/gallery/flashmob/ECE%20flash%20mob%202018-2.jpg" alt="Image" className='img-fluid' loading='lazy' /></Link>
                                                        </div>
                                                        <div class="campus-content text-center">
                                                            <Link to="/news-and-events/gallery/flashmob-2018">
                                                                <h3>Flash mob</h3>
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="more-health-care text-center pt-3">
                                                    <p className='text-center'>
                                                        <Link to="/news-and-events/gallery" className="read-more-btn active"> See all
                                                            Images<i className="flaticon-next"></i></Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className=" pt-100 pb-70">
                        <div className="section-title px-3">
                            <h2>Testimonials</h2>
                            <p>Listen to what our students and parents have to say
                            </p>
                        </div>

                        <div className="">

                            <div className="container row mx-auto">
                                <OwlCarousel className="hero-slider hero-slider2 owl-carousel owl-theme owl-loaded owl-drag" data-slider-id="1" items={1}
                                    loop
                                    nav
                                    dots
                                    margin={8}>
                                    <div className="slider-item mx-auto col-lg-7">
                                        <div className="container-fluid text-center">
                                            <div className="mt-4">
                                                <p className="text-black"> I can positively say Sridevi Women's Engeneering College has
                                                    made me a better person. It has helped me develop a positive attitude towards my
                                                    studies and discover more about myself. Teachers are very caring and interested
                                                    in students’ well-being. They make sure every className is fun, educational and
                                                    interactive.
                                                </p>
                                                <h6 className="text-red"> Swathi Konduru</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item mx-auto col-lg-7">
                                        <div className="container-fluid text-center">
                                            <div className="mt-4">
                                                <p className="text-black"> Sridevi Women's Engeneering College is a great institution.
                                                    The staff and instructors are always helpful and are looking out for you. The
                                                    atmosphere is great. I have loved my time in College Campus. I have made friends
                                                    that I will have forever.
                                                </p>
                                                <h6 className="text-red"> Sanjana Rao</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item mx-auto col-lg-7">
                                        <div className="container-fluid text-center">
                                            <div className="mt-4">
                                                <p className="text-black">
                                                    The campus is filled with positive energy and exuberance. My first visit left me
                                                    awestruck after looking at the infrastructure and maintenance of the college.
                                                    The campus is full of limitless opportunities if you want to discover yourself.
                                                    Besides that, this college gave me sweet memories, faithful friends and a sense
                                                    of confidence to face the challenges and turbulence in life.
                                                </p>
                                                <h6 className="text-red"> Akhila Reddy</h6>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                            {/* <div className="owl-nav"><button type="button"  className="owl-prev"><i
                        className="ri-arrow-left-line"></i></button><button type="button" 
                            className="owl-next"><i className="ri-arrow-right-line"></i></button></div>
                    <div className="owl-dots disabled"></div> */}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }

}

