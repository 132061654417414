import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import MbaLeftTab from './MbaLeftTab';
import pdf from './mbaImages/mba-conference.pdf'
import t1 from './mbaImages/t1.jpeg'
import t2 from './mbaImages/t2.jpg'
import t3 from './mbaImages/t3.png'
import t4 from './mbaImages/t4.png'
import t5 from './mbaImages/t5.jpg'



const MbaActivities = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2 mb-lg-5 mb-4">
                            <div className="how-to-apply submit">
                                <h2 className='mb-4'>Activities</h2>
                                <h6>National Conference on Emerging Trends and Challanges in Management 21st & 22nd December 2022
                                </h6>
                                <div class="collapsible-body cor-tim-tab pt-2">
                                    <iframe src={pdf} width="100%" height="780"></iframe>
                                </div>
                            </div>
                            <div className='submit'>
                                <h6>Training programme given by Dr. Padmavati at CED under Ministry of Agriculture - March 2021</h6>
                                <p>Training programme given for entrepreneurs in Agri business on " project Management- Financial statements- Analysis tools, at CED under Ministry of Agriculture</p>
                                <img src={t1} className='img-fluid py-2' loading='lazy' alt='Training programme ' />
                            </div>
                            <div className='submit'>
                                <h6>Department Achievements</h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li><a href="#" target='_blank'><i class="fa-solid fa-file-pdf"></i>Rural Entrepreneurship Development (RED) Action Plan  </a></li>
                                        </ul>

                                    </div>
                                </div>
                                <table class="table table-bordered mtop25">
                                    <tbody>
                                        <tr class="bg-gray">
                                            <td align="center" valign="top"><p><strong>Event</strong></p></td>
                                            <td align="center" valign="top"><p><strong>Description</strong></p></td>
                                            <td align="center" valign="top"><p><strong>Highlights</strong></p></td>
                                            <td align="center" valign="top" width="180"><p><strong>Date &amp;Venue</strong></p></td>
                                        </tr>
                                        <tr>
                                            <td align="center" valign="middle"><p>Workshop</p></td>
                                            <td valign="middle"><p>Ethics    and business and role of young managers</p></td>
                                            <td valign="middle"><p>Eminent    people like doctor Padmasri Kakarla Subbarao, Doctor A. Lakshmanrao Chief Justice(Rtd)    participated.</p></td>
                                            <td valign="middle">
                                                <p>
                                                    August    2013 <br />
                                                    Auditorium    SWEC
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center" valign="middle"><p>Workshop</p></td>
                                            <td valign="middle"><p>Financial    workshop for students</p></td>
                                            <td valign="middle"><p>Knowledge    of Indian financial Markets has enhanced the skills of students for Judicial    Investments &nbsp;</p></td>
                                            <td valign="middle">
                                                <p>
                                                    19th&amp;20th    December<br />
                                                    Auditorium    SWEC
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center" valign="middle"><p>Guest    lecture</p></td>
                                            <td valign="middle"><p>Awareness    programme on Stock Markets and Mutual funds</p></td>
                                            <td valign="middle"><p>Highly    interactive with students participation.</p></td>
                                            <td valign="middle">
                                                <p>
                                                    25th    October 2016<br />
                                                    Conference    Hall
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center" valign="middle"><p>Industrial    visit</p></td>
                                            <td valign="middle"><p>Visit    to BHEL Hyderabad</p></td>
                                            <td valign="middle"><p>The    students submitted a brief report about the company.</p></td>
                                            <td valign="middle"><p>26th    November 2016</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='submit'>
                                <h6>Faculty Development program</h6>
                                <p>Faculty Development program</p>
                                <p><strong>Faculty Development program</strong></p>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="bg-gray">
                                            <th>Name of event organized</th>
                                            <td><strong>Entrepreneurship Development</strong></td>
                                        </tr>
                                        <tr class="bg-gray">
                                            <th>Date of the event organized</th>
                                            <td><strong>24/06/2020 to 26/06/2020</strong></td>
                                        </tr>
                                        <tr class="bg-gray">
                                            <th>Name of the coordinator</th>
                                            <td><strong>Mrs. Sri Rangalakshmi</strong></td>
                                        </tr>
                                        <tr class="bg-gray">
                                            <th>No. of participants</th>
                                            <td><strong>80</strong></td>
                                        </tr>
                                    </thead>
                                </table>
                                <p>Entrepreneurship is not an inborn skill; it is a product of environment. It involves a complex of economic and social behavior. To be successful, an entrepreneur has to remain dynamic and responsible to the whole environment. Entrepreneurship can hardly survive under any given circumstances. It can flourish only under right environment. It is a part of total system. The social values, culture, government policies, political system, technology, economic conditions, laws, etc influence the growth of entrepreneurship.
                                </p>
                                <p>Entrepreneurship is not an inborn skill; it is a product of environment. It involves a complex of economic and social behavior. To be successful, an entrepreneur has to remain dynamic and responsible to the whole environment. Entrepreneurship can hardly survive under any given circumstances. It can flourish only under right environment. It is a part of total system. The social values, culture, government policies, political system, technology, economic conditions, laws, etc influence the growth of entrepreneurship.
                                </p>
                                <img src={t2} className='img-fluid py-2' loading='lazy' alt='Training programme ' />
                                <h6 className='pt-3 pb-3'>Student Development program</h6>
                                <p>Report on student development Program conducted by Geeta goti HMA EC member on “Campus to corporate”</p>
                                <p><strong>Academic Year (2019-2020)</strong></p>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="bg-gray">
                                            <th>Name of event organized</th>
                                            <td><strong>Campus to corporate </strong></td>
                                        </tr>
                                        <tr class="bg-gray">
                                            <th>Date of the event organized</th>
                                            <td><strong>07/02/2020</strong></td>
                                        </tr>
                                        <tr class="bg-gray">
                                            <th>Name of the coordinator</th>
                                            <td><strong>Mrs. Sandhya</strong></td>
                                        </tr>
                                        <tr class="bg-gray">
                                            <th>No. of participants</th>
                                            <td><strong>60</strong></td>
                                        </tr>
                                    </thead>
                                </table>
                                <p className='pt-2'>Campus life and corporate life are fundamentally different. If one continues to have same expectations from his employer as he/she had from the professors in the campus, one will be highly disappointed and damage the career success as the culture of education is so different from the organizational culture. So, we need to bridge the gap. The biggest challenge while transition from campus to corporate, you yourself have to develop the self confidence that you have the ability to perform the job assigned to you. You also will be expected to demonstrate that you have the ability to learn and perform more to the satisfaction of your superiors. The more you deliver, more the expectations is added from your end.

                                </p>
                                <p>Campus life and corporate life are fundamentally different. If one continues to have same expectations from his employer as he/she had from the professors in the campus, one will be highly disappointed and damage the career success as the culture of education is so different from the organizational culture. So, we need to bridge the gap. The biggest challenge while transition from campus to corporate, you yourself have to develop the self confidence that you have the ability to perform the job assigned to you. You also will be expected to demonstrate that you have the ability to learn and perform more to the satisfaction of your superiors. The more you deliver, more the expectations is added from your end.
                                </p>
                                <div className='row'>
                                    <div className='col-md-6 pb-3'>
                                        <img src={t3} className='img-fluid py-2' loading='lazy' alt='Training programme ' />

                                    </div>
                                    <div className='col-md-6 pb-3'>
                                        <img src={t4} className='img-fluid py-2' loading='lazy' alt='Training programme ' />

                                    </div>
                                    <div className='col-12 pb-3'>
                                        <img src={t5} className='img-fluid py-2' loading='lazy' alt='Training programme ' />

                                    </div>
                                </div>

                            </div>
                            <div className='submit'>
                                <h6 className='py-2'>MBA conference Brochure</h6>
                                <div class="collapsible-body cor-tim-tab pt-2">
                                    <iframe src={pdf} width="100%" height="780"></iframe>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default MbaActivities
