import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';
import hod from './ItImages/hod/hod.jpg'

const ItIndustryVisits = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Industry Visits
                                </h2>
                                {/* <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="#"><i class="fa-solid fa-file-pdf"></i>BSNL Virtual Industrial Visit  </a>
                                            </li>
                                            <li>
                                                <a href="#"><i class="fa-solid fa-file-pdf"></i>  Wipro Report 2015-16 </a>
                                            </li>
                                            <li>
                                                <a href="#"><i class="fa-solid fa-file-pdf"></i> Incois Report 2016-17  </a>
                                            </li>
                                            <li>
                                                <a href="#"><i class="fa-solid fa-file-pdf"></i>  Incois Report 2018-19 </a>
                                            </li>
                                            <li>
                                                <a href="#"><i class="fa-solid fa-file-pdf"></i> SAP Report 2017-18 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                                <div class="accordion mt-3" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                BSNL Virtual Industrial Visit
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <embed src="/assets/documents/BSNL Virtual Industrial visit_ 2020-2021.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" width="100%" height="400" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Wipro Report 2015-16
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <embed src="/assets/documents/Wipro report_2015-2016.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" width="100%" height="400" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Incois Report 2016-17
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <embed src="/assets/documents/INCOIS REPORT_2016-2017.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" width="100%" height="400" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFour">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                Incois Report 2018-19
                                            </button>
                                        </h2>
                                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                            <embed src="/assets/documents/INCOIS REPORT_ 2018-2019.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" width="100%" height="400" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFive">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            SAP Report 2017-18
                                            </button>
                                        </h2>
                                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                            <embed src="/assets/documents/SAP report_2017-2018.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" width="100%" height="400" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default ItIndustryVisits
