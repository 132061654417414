import React from 'react'

import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import IQACLeftTab from './IQACLeftTab';


const QualityPolicy = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <IQACLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Quality Policy

                                </h2>
                                <p> To INCULCATE the Global Standards of Excellence in Nurturing the Women Engineers in Building the Bench Mark of Women Empowerment with Acclaimed Technical Education, Research Embedded with Community Orientation Activities.

                                </p>

                                <div className="apply-list">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default QualityPolicy
