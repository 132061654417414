import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import PlacementsLeftTab from './PlacementsLeftTab';

const Task = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <PlacementsLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> TASK Training Programmes conducted for students in the accedemic year:2019-20
                                </h2>
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-info table-bordered table-faculty new-faculty">
                                            <thead>
                                                <tr class="bg-gray">
                                                    <th width="50"><strong>S.No.</strong></th>
                                                    <th><strong>Programme Name</strong></th>
                                                    <th><strong>Date</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td><strong>WE Hub</strong></td>
                                                    <td>JANUARY</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td><strong>BLOCK CHAIN TECHNOLOGY</strong></td>
                                                    <td>FEBRUARY</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td><strong>Presentation skills</strong></td>
                                                    <td>JUNE</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td><strong>Problem solving skills</strong></td>
                                                    <td>JUNE</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td><strong>REMEBERING KALAM</strong></td>
                                                    <td>JULY</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td><strong>SELF EMPOWERMENT</strong></td>
                                                    <td>JULY</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td><strong>INTERNSHIP AWARENESS PROGRAMME</strong></td>
                                                    <td>JULY</td>
                                                </tr><tr>
                                                    <td>8</td>
                                                    <td><strong>INTERVIEW SKILLS</strong></td>
                                                    <td>JULY</td>
                                                </tr><tr>
                                                    <td>9</td>
                                                    <td><strong>INTRODUCTION OF ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING</strong></td>
                                                    <td>AUGUST</td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td><strong>BIG DATA AND HADOOP WITH HIVE AND PIG TOOLS</strong></td>
                                                    <td>AUGUST</td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td><strong>TEAM WORK</strong></td>
                                                    <td>AUGUST</td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td><strong>INTERPERSONAL SKILLS BEGINNER AND PROBLEM SOLVING SKILLS BEGINNER</strong></td>
                                                    <td>AUGUST</td>
                                                </tr>
                                                <tr>
                                                    <td>13</td>
                                                    <td><strong>COMMUNICATION SKILLS</strong></td>
                                                    <td>AUGUST</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='pt-4'>
                                        <h6 className='pb-3'>Guidelines given by Dr. K.P.Srinivas Rao, Secretary SWEC to all students learning online
                                        </h6>

                                        <iframe className="lazy-youtube" width="100%" height="300" src="https://www.youtube.com/embed/t0liMmvuoIA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Task
