import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const NSSNews = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>NSS
                                    </h2>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-8 mb-3 mb-lg-4">
                                                <div class="detgails-right-content mt-4">
                                                    <div class="related-download bg-gray">
                                                        <ul>
                                                            <li>
                                                                <a href="/assets/img/latest-events/NSS.docx" target="_blank"><i class="fa-solid fa-file-pdf"></i> NSS </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default NSSNews
