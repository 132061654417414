import React,{useEffect} from 'react'
import { Link } from "react-router-dom";

const SLeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul className='text-start'>
                    <Link to="/dept/s-and-h" onclick="scrollWin()"><li className='text-black'><i class="fa-sharp fa-solid fa-building"></i> <span>About the Department</span> </li></Link>
                    <Link to="/dept/s-and-h/vision-mission-peos" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> Vision, Mission & PEO’s</span> </li></Link>
                    <Link to="/dept/s-and-h/hod-profile" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> HOD Profile</span> </li></Link>
                    <Link to="/dept/s-and-h/faculty-list" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span>Faculty  </span> </li></Link>
                    <a to="javascript:;" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Mentor List  </span> </li></a>
                    <a to="javascript:;" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Remedial Class  </span> </li></a>
                    
                    <Link to="/dept/s-and-h/activities" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span> Activities  </span> </li></Link>
                    <a to="javascript:;" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>   Innovative teaching methodologies  </span> </li></a>
                   
                
                
                
                
                
                
                
                
                </ul>
            </div>
        </div>
    )
}


export default SLeftTab
