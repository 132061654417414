import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';

const Itmagazines = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> New Letters

                                </h2>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/it/2020-21.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>2020-21</a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/2020-21.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2019-20  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/2018-19.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>   2018-19 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/2017-18.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2017-18 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/2016-17.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2016-17
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/2015-16.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>2015-16  </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Itmagazines
