import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import AboutLeftTab from '../About/AboutLeftTab';
import './news.css'
// import video from './videos/SWEC-RADIO.mp4'

const SwecRadio = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> SWEC Radio launched - May 2020
                                </h2>
                                <p>SWEC Radio in association with Younify Campus Radio was launched on 7th May,2020. The objective was to help students connect, learn and collaborate with each other. It is completely a Student run radio with Dr. B.Narendra as the Faculty coordinator. The SWEC Radio team has designed and conceptualized all the shows and all the shows are aired daily. We are extremely proud of our students who come up with beautiful themes for the shows and execute them flawlessly.</p>

                                {/* <video className='pt-4' width="auto" height="300" controls>
                                    <source src={video} type="video/mp4">
                                
                                    </source>
                                </video> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default SwecRadio
