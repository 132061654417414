import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import ActivitiesLeftTab from './ActivitiesLeftTab';

const ClgMagzine = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ActivitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>College Magazine

                                </h2>
                               
                            </div>


                            <div class="detgails-right-content mt-4">

                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="#" target='_blank'><i class="fa-solid fa-file-pdf"></i>  2018-2019 TECHNOSWEC MAGAZINE </a>
                                        </li>
                                        <li>
                                            <a href="#" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2017-2018 TECHNOSWEC MAGAZINE </a>
                                        </li>
                                        <li>
                                            <a href="#" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2016-2017 TECHNOSWEC MAGAZINE </a>
                                        </li>
                                        <li>
                                            <a href="#" target='_blank'><i class="fa-solid fa-file-pdf"></i>  2015-2016 TECHNOSWEC MAGAZINE </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default ClgMagzine
