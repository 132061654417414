import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';
import GAUTAMI from './cseD/GAUTAMI.jpg'

const IndustryVisits = () => {
  return (
    <div>
      <Navbar />
      <div className="graduate-admission pt-lg-5 pt-4 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
              <DepartmentTab />
            </div>
            <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
              <div className="how-to-apply">
                <h2>Industry Visits
                </h2>
                <div className=''>
                  <div class="detgails-right-content mt-1">
                    <div class="related-download bg-gray">
                      <ul>
                        <li>
                          <a href="/assets/documents/cse/Online Industrial Visit-05.01.2021.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Online Industrial Visit 05-01-2021. </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default IndustryVisits
