import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';

const ActivitiesLeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul>
                    <Link to="/activities/obe" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i>   OBE</li></Link>
                    <Link to="/activities/ncc" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> NCC</li></Link>
                    <Link to="/activities/nss" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> NSS </li></Link>
                    <Link to="/activities/sswec" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> SSSWES</li></Link>
                    <Link to="/activities/college-magazine" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> COLLEGE MAGAZINE</li></Link>
                </ul>
            </div>
        </div>
    )
}

export default ActivitiesLeftTab
