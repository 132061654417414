import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const YuvaSamarthya2021 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Yuva Samarthya - 2021
                                    </h2>
                                    <p className='pb-4'>CSE department is hosting the Yuva Samarthya - 2021 in association with CSI Student Chapter,  which will provide a great opportunity for displaying the talents of students from different departments on 30th and 31st March 2021.

                                    </p>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Yuva Samarthya/3.jpg">
                                                    <img src="/assets/img/gallery/Yuva Samarthya/3.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Yuva Samarthya/4.jpg">
                                                    <img src="/assets/img/gallery/Yuva Samarthya/4.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Yuva Samarthya/5.jpg">
                                                    <img src="/assets/img/gallery/Yuva Samarthya/5.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Yuva Samarthya/6.jpg">
                                                    <img src="/assets/img/gallery/Yuva Samarthya/6.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Yuva Samarthya/7.jpg">
                                                    <img src="/assets/img/gallery/Yuva Samarthya/7.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                         
                                            <div class="col-md-6 row">
                                                <a class="spotlight" href="/assets/img/gallery/Yuva Samarthya/2.jpeg">
                                                    <img src="/assets/img/gallery/Yuva Samarthya/2.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 row">
                                                <a class="spotlight" href="/assets/img/gallery/Yuva Samarthya/1.jpeg">
                                                    <img src="/assets/img/gallery/Yuva Samarthya/1.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>







                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default YuvaSamarthya2021
