import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const Events2019 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Events 2019
                                    </h2>
                                    <div className='py-3'>
                                        <h6>NSS Event - Sister’s for a Change – Gift a helmet</h6>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/1. NSS event - Sisters for Change 2019.jpg">
                                                    <img src="/assets/img/latest-events/1. NSS event - Sisters for Change 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/2. NSS event - Sisters for Change 2019.jpg">
                                                    <img src="/assets/img/latest-events/2. NSS event - Sisters for Change 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/3. NSS event - Sisters for Change 2019.jpg">
                                                    <img src="/assets/img/latest-events/3. NSS event - Sisters for Change 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/4. NSS event - Sisters for Change 2019.jpg">
                                                    <img src="/assets/img/latest-events/4. NSS event - Sisters for Change 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div className='py-3'>
                                                <h6>VidhYouth 2.0 Poster release 2k19</h6>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/VidhYouth Technical fest poster  release 2019.jpg">
                                                    <img src="/assets/img/latest-events/VidhYouth Technical fest poster  release 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/2.VidhYouth Technical fest poster release 2019.jpg">
                                                    <img src="/assets/img/latest-events/2.VidhYouth Technical fest poster release 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/3.VidhYouth Technical fest poster release 2019.jpg">
                                                    <img src="/assets/img/latest-events/3.VidhYouth Technical fest poster release 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/4.VidhYouth Technical fest poster release 2019.jpg">
                                                    <img src="/assets/img/latest-events/4.VidhYouth Technical fest poster release 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div className='py-3'>
                                                <h6>National Sports Day celebration 2K19</h6>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/1. National Sports Day celebrations 2019.jpg">
                                                    <img src="/assets/img/latest-events/1. National Sports Day celebrations 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div className='py-3'>
                                                <h6>Fresher’s Day</h6>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/Fresher's Day 2019.jpg">
                                                    <img src="/assets/img/latest-events/Fresher's Day 2019.jpg" alt="Image" class="img-responsive center-block" width="100%" height="100%" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/Fresher's Day 2019 - 1.jpg">
                                                    <img src="/assets/img/latest-events/Fresher's Day 2019 - 1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/Fresher's Day 2019 - 2.jpg">
                                                    <img src="/assets/img/latest-events/Fresher's Day 2019 - 2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/Fresher's Day 2019 - 3.jpg">
                                                    <img src="/assets/img/latest-events/Fresher's Day 2019 - 3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Events2019
