import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const SHEteam5thanniversary = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>SHE team 5th anniversary
                                    </h2>
                                    <p className='pb-2'>Prakriti Shah final year student who works as student ambassador for Cybersecurity Hyderabad was invited for the 5th Anniversary celebrations of SHE team and students of MBA dept attended the event too.

                                    </p>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/she_3.jpg">
                                                    <img src="/assets/img/latest-events/she_3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/she_2.jpg">
                                                    <img src="/assets/img/latest-events/she_2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/she_1.jpg">
                                                    <img src="/assets/img/latest-events/she_1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/she_4.jpg">
                                                    <img src="/assets/img/latest-events/she_4.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default SHEteam5thanniversary
