import React from 'react'
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tabs-style.css';
import Footer from './footer/Footer';
import Navbar from './navbar/Navbar';

const NAAC = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 ps-lg-4 order-1 order-lg-2">
                            <Tabs>
                                <div className='row'>
                                    <div className='col-xl-3'>
                                        <div className='header-text text-white p-2 mb-2 text-center'>
                                            <h6 className="text-white mb-0">CRITERIA</h6>
                                        </div>
                                        <TabList>
                                            <Tab>Criteria 1</Tab>
                                            <Tab>Criteria 2</Tab>
                                            <Tab>Criteria 3</Tab>
                                            <Tab>Criteria 4</Tab>
                                            <Tab>Criteria 5</Tab>
                                            <Tab>Criteria 6</Tab>
                                            <Tab>Criteria 7</Tab>
                                        </TabList>
                                    </div>
                                    <div className='col-xl-9'>
                                        <TabPanel>
                                            <h4>CRITERIA 1 - CURRICULAR ASPECTS</h4>
                                            <span>&nbsp;</span>
                                            <p><strong>1.1.1. Curricular Planning and Implementation</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">1.1.1</td>
                                                            <td>
                                                                The Institution ensures effective curriculum delivery through a wellplanned and documented process
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.1.2</td>
                                                            <td>
                                                                The institution adheres to the academic calendar including for the
                                                                conduct of CIE
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.1.3</td>
                                                            <td>
                                                                Teachers of the Institution participate in following activities related to
                                                                curriculum development and assessment of the affiliating University
                                                                and/are represented on the following academic bodies during the last
                                                                five years<p></p>
                                                                <span>1. Academic council/BoS of Affiliating university</span>
                                                                <span>2. Setting of question papers for UG/PG programs</span>
                                                                <span>
                                                                    3. Design and Development of Curriculum for Add on/ certificate/
                                                                    Diploma Courses
                                                                </span>
                                                                <span>4. Assessment /evaluation process of the affiliating University</span>
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><strong>1.2 Academic Flexibility</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">1.2.1</td>
                                                            <td>
                                                                Percentage of Programmes in which Choice Based Credit System
                                                                (CBCS)/ elective course system has been implemented
                                                                1.2.1.1. Number of Programmes in which CBCS/ Elective course
                                                                system implemented.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.2.2</td>
                                                            <td>
                                                                Number of Add on /Certificate programs offered during the last five
                                                                years1.2.2.1: How many Add on /Certificate programs are added within the
                                                                last 5 years (sample certificate copies)
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.2.3</td>
                                                            <td>
                                                                Average percentage of students enrolled in Certificate/ Add-on
                                                                programs as against the total number of students during the last five
                                                                years
                                                                Details of the students enrolled in Subjects related to certificate/Addon programs
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><strong>1.3 Curriculum Enrichment</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">1.3.1</td>
                                                            <td>
                                                                Institution integrates crosscutting issues relevant to Professional
                                                                Ethics, Gender, Human Values, Environment and Sustainability into
                                                                the Curriculum
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.3.2</td>
                                                            <td>
                                                                Average percentage of courses that include experiential learning
                                                                through project work/field work/internship during last five years
                                                                1.3.2.1: Number of courses that include experiential learning through
                                                                project work/field work/internship year wise during last five years

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.3.3 </td>
                                                            <td>
                                                                Percentage of students undertaking project work/field work/
                                                                internships (Data for the latest completed academic year)
                                                                1.3.3.1. Number of students undertaking project work/field work /
                                                                internships

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><strong>1.4 Feedback System</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">1.4.1</td>
                                                            <td>
                                                                Institution obtains feedback on the syllabus and its transaction at the
                                                                institution from the following stakeholders
                                                                1) Students 2) Teachers 3) Employers 4) Alumni
                                                                Report of analysis of feedback received from different stakeholders’
                                                                year wise
                                                                Action taken report of the Institution on feedback report as stated in
                                                                the minutes of the Governing Council, Syndicate, Board of
                                                                Management
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center">1.4.2 </td>
                                                            <td>
                                                                Feedback process of the Institution
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank" href="javascript:;">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <h4>Criteria 2- Teaching- Learning and Evaluation</h4>
                                            <p>&nbsp;</p>
                                            <p><strong>2.1 Student Enrolment and Profile  </strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>2.1.1</strong></td>
                                                            <td>
                                                                Average Enrolment percentage
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>2.1.2</strong></td>
                                                            <td>
                                                                Average percentage of seats filled against seats reserved for various categories
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><strong>2.2 Catering to Student Diversity  </strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>2.2.1</strong></td>
                                                            <td>
                                                                The institution assesses the learning levels of the students and organises special
                                                                Programmes for advanced learners and slow learners
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>2.2.2</strong></td>
                                                            <td>
                                                                Student- Full time teacher ratio
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><strong>2.3 Teaching- Learning Process</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>2.3.1</strong></td>
                                                            <td>
                                                                Student centric methods, such as experiential learning, participative learning and
                                                                problem solving methodologies are used for enhancing learning experiences
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center"><strong>2.3.2</strong></td>
                                                            <td>
                                                                Teachers use ICT enabled tools for effective teaching-learning process.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center"><strong>2.3.3</strong></td>
                                                            <td>
                                                                Ratio of mentor to students for academic and other related issues
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><strong>2.4 Teacher Profile and Quality</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>2.4.1</strong></td>
                                                            <td>
                                                                Average percentage of full time teachers against sanctioned posts during the last five
                                                                years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>2.4.2</strong></td>
                                                            <td>
                                                                Average percentage of full time teachers with Ph. D. / D.M. / M.Ch. / D.N.B
                                                                Superspeciality / D.Sc. / D.Litt. during the last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>2.4.3</strong></td>
                                                            <td>
                                                                Average teaching experience of full time teachers in the same institution
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><strong>2.5. Evaluation Process and Reforms</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>2.5.1</strong></td>
                                                            <td>
                                                                Mechanism of internal assessment is transparent and robust in terms of frequency and
                                                                mode
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>2.5.2</strong></td>
                                                            <td>
                                                                Mechanism to deal with internal examination related grievances is transparent, time-
                                                                bound and efficient
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><strong>2.6 Student Performance and Learning Outcome </strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>2.6.1</strong></td>
                                                            <td>
                                                                Teachers and students are aware of the stated Programme and course outcomes of the
                                                                Programmes offered by the institution.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>2.6.2</strong></td>
                                                            <td>
                                                                Attainment of Programme outcomes and course outcomes are evaluated by the
                                                                institution.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center"><strong>2.6.3</strong></td>
                                                            <td>
                                                                Average pass percentage of Students during last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <p><strong>2.7 Student Satisfaction Survey</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>2.7.1</strong></td>
                                                            <td>
                                                                Online student satisfaction survey regarding to teaching learning process.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </TabPanel>
                                        <TabPanel>
                                            <h4>Criteria 3- Research, Innovation and Extension</h4>
                                            <p>&nbsp;</p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>3.1.1</strong></td>
                                                            <td>
                                                                Grants for research projects sponsored by the government and non government
                                                                sources such as industry, corporate houses, international bodies, endowment, Chairs
                                                                in the institution during the last five years(INR in Lakhs)
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">Excel View Document</a></li>
                                                                    <li><a href="javascript:;" target="_blank"> e-copies of the grant award letters for sponsored research projects/ endowments</a></li>
                                                                    <li><a href="javascript:;" target="_blank">Any additional information</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.1.2</strong></td>
                                                            <td>
                                                                Percentage of teachers recognized as research guides at present
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">Upload copies of the letter of recognized as research guides</a></li>
                                                                    <li><a href="javascript:;" target="_blank">Any additional information</a></li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Institutional data in prescribed
                                                                            format
                                                                        </a>
                                                                    </li>

                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.1.3</strong></td>
                                                            <td>
                                                                Percentage of departments having Research projects funded by government and
                                                                non government agencies during the last five years

                                                            </td>
                                                            <td>
                                                                <ul className="list">

                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            List of research projects and
                                                                            funding details (Data Template)
                                                                        </a>
                                                                    </li>
                                                                    <li><a href="javascript:;" target="_blank">Additional Information</a></li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Supporting document from
                                                                            Funding Agency
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Paste Link for the funding
                                                                            agency website
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center"><strong>3.2.1</strong></td>
                                                            <td>
                                                                Institution has created an ecosystem for innovations including Incubation
                                                                centre and other initiatives for creation and transfer of knowledg
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">Additional Information</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.2.2</strong></td>
                                                            <td>
                                                                Number of Workshops/ seminars conducted on Intellectual Property Rights (IPR) and
                                                                Industry-Academia Innovative practices during the last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">View excel document</a></li>
                                                                    <li><a href="javascript:;" target="_blank">Report of the events</a></li>
                                                                    <li><a href="javascript:;" target="_blank">Additional Information</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center"><strong>3.3.1</strong></td>
                                                            <td>
                                                                Number of Ph.D’s awarded per teacher during the last five years
                                                                (Not applicable to UG college)
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Institutional data in prescribed
                                                                            format
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any additional information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.3.2</strong></td>
                                                            <td>
                                                                Number of research papers per teachers in the Journals notified on UGC website
                                                                during the last five years

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="#" target="_blank">
                                                                            List of research papers by title,
                                                                            author, department, name and
                                                                            year of publication
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.3.3</strong></td>
                                                            <td>
                                                                Number of books and chapters in edited volumes/books published and papers in
                                                                national/ international conference-proceedings per teacher during last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            List books and chapters edited
                                                                            volumes/ books published
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center"><strong>3.4.1</strong></td>
                                                            <td>
                                                                Extension activities in the neighborhood community in terms of impact and
                                                                sensitizing students to social issues, for their holistic development, and
                                                                impact thereof during the last five years

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            additional
                                                                            information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.4.2</strong></td>
                                                            <td>
                                                                Number of awards and recognitions received for extension activities from
                                                                government/ government recognised bodies during the last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">e-copy of the award letters</a></li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Number of awards for extension
                                                                            activities in last 5 years
                                                                        </a>
                                                                    </li>
                                                                    <li><a href="javascript:;" target="_blank">Any additional information</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.4.3</strong></td>
                                                            <td>
                                                                Number of extension and outreached Programmes conducted by the institution
                                                                through NSS/ NCC/ Red Cross/ YRC etc., during the last five years ( including
                                                                government initiated programs such as Swachh Bharat, Aids Awareness, Gender
                                                                Issue, etc. and those organized in collaboration with industry, community and
                                                                NGOs)

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">Reports of the event organized</a></li>
                                                                    <li><a href="javascript:;" target="_blank">Any additional information</a></li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Number of extension and
                                                                            outreach Programmes conducted
                                                                            with industry, community etc for
                                                                            the last five years
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.4.4</strong></td>
                                                            <td>
                                                                Average percentage of students participating in extension activities at 3.4.3 above
                                                                during last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">Reports of the event organized</a></li>
                                                                    <li><a href="javascript:;" target="_blank">Any additional information</a></li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Average percentage of students
                                                                            participating in extension
                                                                            activities with Govt. or NGO etc
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.5.1</strong></td>
                                                            <td>
                                                                Number of Collaborative activities for research, Faculty exchange, Student
                                                                exchange/Internship per year
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            e-copies of related Document
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of collaborative activities
                                                                            with institutions/industries for
                                                                            research, Faculty exchange,
                                                                            Student exchange/ internship
                                                                        </a>
                                                                    </li>
                                                                    <li><a href="javascript:;" target="_blank">Any additional information</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>3.5.2</strong></td>
                                                            <td>
                                                                Number of functional MoUs with institutions, other universities, industries,
                                                                corporate houses etc. during the last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            e-Copies of the MoUs with
                                                                            institution./ industry/ corporate
                                                                            house
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of functional MoUs
                                                                            with institutions of national,
                                                                            international importance,
                                                                            other universities etc during
                                                                            the last
                                                                            five years
                                                                        </a>
                                                                    </li>
                                                                    <li><a href="javascript:;" target="_blank">Additional information</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <h4>Criterion 4. Infrastructure and Learning Resources</h4>
                                            <p>&nbsp;</p>
                                            <p><strong>4.1 Physical Facilities</strong></p>
                                            <div class="table-responsive">
                                                <table cellspacing="0" class="table table-bordered">
                                                    <thead>
                                                        <tr class="bg-gray">
                                                            <th width="95" class="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.1.1</strong></td>
                                                            <td>
                                                                The Institution has adequate infrastructure and
                                                                physical facilities for teaching- learning. viz.,
                                                                classrooms, laboratories, computing equipment
                                                                etc.

                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li><a href="javascript:;" target="_blank">Floor wise Plan Proof</a></li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Adequate Facilities
                                                                            Excel sheet
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Photographs of College
                                                                            Infrastructure
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.1.2</strong></td>
                                                            <td>
                                                                The Institution has adequate facilities for
                                                                cultural activities, sports, games (indoor,
                                                                outdoor), gymnasium, yoga centre etc.
                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li><a href="javascript:;" target="_blank">Sports Area</a></li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Participants and
                                                                            Winners list of all level
                                                                            competitions for the
                                                                            last five years
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Photographs of Yoga,
                                                                            Sports and Cultural
                                                                            Activities
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.1.3</strong></td>
                                                            <td>
                                                                Percentage of classrooms and seminar halls with
                                                                ICT- enabled facilities such as smart class, LMS,
                                                                etc. (Data for the latest completed academic
                                                                year).
                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Number of classrooms
                                                                            and seminar halls with
                                                                            ICT enabled facilities
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Geotagged photographs
                                                                            of classrooms and
                                                                            seminar halls with ICT
                                                                            enabled facilities
                                                                        </a>
                                                                    </li>
                                                                    <li><a href="javascript:;" target="_blank">Master Timetable</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.1.4</strong></td>
                                                            <td>
                                                                Average percentage of expenditure, excluding
                                                                salary for infrastructure augmentation during
                                                                last five years (INR in Lakhs).
                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of budget
                                                                            allocation, excluding
                                                                            salary during the last five
                                                                            years
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Audited utilization
                                                                            statements
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Audit Reports
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>&nbsp;</p>
                                            <p><strong>4.2 Library as a Learning Resource</strong></p>
                                            <div class="table-responsive">
                                                <table cellspacing="0" class="table table-bordered">
                                                    <thead>
                                                        <tr class="bg-gray">
                                                            <th width="95" class="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.2.1</strong></td>
                                                            <td>
                                                                Library is automated using Integrated Library
                                                                Management System (ILMS)
                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            ILMS-NewGenLib
                                                                            Library Software
                                                                            Automation
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            ILMS Software Year
                                                                            Wise Invoices
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.2.2</strong></td>
                                                            <td>
                                                                The institution has subscription for the following
                                                                e-resources

                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of subscriptions
                                                                            like e-journals, eShodhSindhu,
                                                                            Shodhganga
                                                                            Membership, Remote
                                                                            access to library
                                                                            resources, Web interface
                                                                            etc
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            E-copy of letter of
                                                                            subscriptions
                                                                            Memberships
                                                                        </a>
                                                                    </li>
                                                                    <li><a href="javascript:;" target="_blank">Screenshots of ejournals login pages</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.2.3</strong></td>
                                                            <td>
                                                                Average annual expenditure for purchase of
                                                                books/e-books and subscription to journals/ejournals during the last five years (INR in
                                                                Lakhs).
                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of annual
                                                                            expenditure for purchase
                                                                            of books/e-books and
                                                                            journals/e- journals
                                                                            during the last five years
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Audited statements of
                                                                            accounts
                                                                        </a>
                                                                    </li>
                                                                    <li><a href="javascript:;" target="_blank">Audit Reports</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.2.4</strong></td>
                                                            <td>
                                                                Percentage per day usage of library by teachers
                                                                and students during the last completed
                                                                academic year.

                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of library usage
                                                                            by teachers and students
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>&nbsp;</p>
                                            <p><strong>4.3 IT Infrastructure</strong></p>
                                            <div class="table-responsive">
                                                <table cellspacing="0" class="table table-bordered">
                                                    <thead>
                                                        <tr class="bg-gray">
                                                            <th width="95" class="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.3.1</strong></td>
                                                            <td>
                                                                Institution frequently updates its IT facilities
                                                                including Wi-Fi

                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of major IT
                                                                            facilities updated for
                                                                            the last five years
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.3.2</strong></td>
                                                            <td>
                                                                Student - Computer ratio
                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li><a href="javascript:;" target="_blank">Distribution of computers</a></li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Bills of the purchase of
                                                                            computers and highlights
                                                                            the entries of computers
                                                                            in the stock registers.
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.3.3</strong></td>
                                                            <td>
                                                                Bandwidth of internet connection in the
                                                                Institution

                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of available
                                                                            bandwidth of internet
                                                                            connection in the
                                                                            Institution
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            E-copy of document of
                                                                            agreement with the
                                                                            service provider
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.4.1</strong></td>
                                                            <td>
                                                                Average percentage of expenditure incurred on
                                                                maintenance of infrastructure (physical and
                                                                academic support facilities) excluding salary
                                                                component during the last five years (INR in
                                                                Lakhs)

                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details about assigned
                                                                            budget and expenditure
                                                                            on physical facilities and
                                                                            academic support
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Audited statements of
                                                                            accounts
                                                                        </a>
                                                                    </li>
                                                                    <li><a href="javascript:;" target="_blank">Audit Reports</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="text-center" ><strong>4.4.2</strong></td>
                                                            <td>
                                                                There are established systems and procedures
                                                                for maintaining and utilizing physical, academic
                                                                and support facilities - laboratory, library, sports
                                                                complex, computers, classrooms etc.
                                                            </td>
                                                            <td>
                                                                <ul class="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Maintenance of
                                                                            facilities in campus
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </TabPanel>
                                        <TabPanel>
                                            <h4>Criterion 5- Student Support and Progression </h4>
                                            <p>&nbsp;</p>
                                            <p><strong>5.1 Student Support</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>5.1.1</strong></td>
                                                            <td>
                                                                Average percentage of students benefited by scholarships and  freeships.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>5.1.2</strong></td>
                                                            <td>
                                                                Average percentage of students benefitted by scholarships, freeships by the institution
                                                                / non- government agencies.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>5.1.3</strong></td>
                                                            <td>
                                                                Capacity building and skills enhancement initiatives taken by the  institution.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>5.1.4</strong></td>
                                                            <td>
                                                                Average percentage of students benefitted by guidance for competitive examinations
                                                                and career counselling offered by the Institution.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>5.1.5</strong></td>
                                                            <td>
                                                                Capacity building and skills enhancement initiatives taken by the institution.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>&nbsp;</p>
                                            <p><strong>5.2 Student Progression  </strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>5.2.1</strong></td>
                                                            <td>
                                                                Average percentage of placement of outgoing students.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>5.2.2</strong></td>
                                                            <td>
                                                                Average percentage of students progressing to higher education.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>5.2.3</strong></td>
                                                            <td>
                                                                Average percentage of students qualifying in state/national/ international level
                                                                examinations.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>&nbsp;</p>
                                            <p><strong>5.3 Student Participation and Activities </strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Download</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>5.3.1</strong></td>
                                                            <td>
                                                                Number of awards/medals for outstanding performance in sports/cultural activities at
                                                                university/state/national / international level.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>5.3.2</strong></td>
                                                            <td>
                                                                Institution facilitates students’ representation and engagement in various administrative,
                                                                co-curricular and extracurricular activities.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>5.3.3</strong></td>
                                                            <td>
                                                                Average number of sports and cultural events/competitions in which students of the
                                                                Institution participated.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>&nbsp;</p>
                                            <p><strong>5.4 Alumni Engagement</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>5.4.1</strong></td>
                                                            <td>
                                                                There is a registered Alumni Association that contributes significantly to the
                                                                development of the institution through financial and/or other support services.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>5.4.2</strong></td>
                                                            <td>
                                                                Alumni contribution.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <h4>Criteria 6 - Governance, Leadership and Management</h4>
                                            <p>&nbsp;</p>
                                            <p><strong>6.1 Institutional Vision and Leadership</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.1.1</strong></td>
                                                            <td>
                                                                The Governance of the Institution is reflective of and in tune with the vision and
                                                                mission of the institution
                                                                Governing Body Minutes of Meetings and Administrative Setup
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.1.2</strong></td>
                                                            <td>
                                                                The Effective Leadership is Visible in various institutional practices such as
                                                                decentralization and participative management
                                                                Delegation of Financial Powers with Some Proofs and Work Lodes with some
                                                                Proofs

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>&nbsp;</p>
                                            <p><strong>6.2. Strategy Development and Deployment</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.1</strong></td>
                                                            <td>
                                                                The institutional Strategic / Perspective plan is effectively deployed
                                                                Perspective/Strategic plan and deployment documents are available in the
                                                                institution
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.2</strong></td>
                                                            <td>
                                                                The functioning of the institutional bodies is effective and efficient as visible
                                                                from policies, administrative setup, appointment and service rules, procedures,
                                                                etc.

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.2</strong></td>
                                                            <td>
                                                                Grievance Redressal Committee circulars and Minutes of Meetings

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.2</strong></td>
                                                            <td>
                                                                Organizational Chart

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.2</strong></td>
                                                            <td>
                                                                Service Rules

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.3</strong></td>
                                                            <td>
                                                                Implementation of e-governance in areas of operation

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.3</strong></td>
                                                            <td>
                                                                Effectiveness of various bodies/cells/committees is evident through minutes of
                                                                meetings and implementation of their resolutions


                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.3</strong></td>
                                                            <td>
                                                                List of all Committees
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.3</strong></td>
                                                            <td>
                                                                All Committees Circulars and Minuets of Meetings 2016-2018
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.2.3</strong></td>
                                                            <td>
                                                                All Committees Circulars and Minuets of Meetings 2019-2021
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>&nbsp;</p>
                                            <p><strong>6.3. Faculty Empowerment Strategies</strong></p>
                                            <div className="table-responsive">
                                                0
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.1</strong></td>
                                                            <td>
                                                                Institution has effective welfare measures for teaching and non-teaching staff
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.1</strong></td>
                                                            <td>
                                                                Service Rules and Details of Staff utilized Welfare Measures along with proofs
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.2</strong></td>
                                                            <td>
                                                                Average percentage of teachers provided with financial support to attend
                                                                conferences/workshops and towards membership fee of professional bodies
                                                                year wise during the last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.2.1</strong></td>
                                                            <td>
                                                                Number of teachers provided with financial support to attend conferences/
                                                                workshops and towards membership fee of professional bodies year wise during
                                                                the last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.2.1</strong></td>
                                                            <td>
                                                                Proofs-Link-1
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.2.1</strong></td>
                                                            <td>
                                                                Proofs-Link-2
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.3</strong></td>
                                                            <td>
                                                                Average number of professional development/administrative training
                                                                programs organized by the institution for teaching and non-teaching staff dur
                                                                during the last five years.
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.3.1</strong></td>
                                                            <td>
                                                                Average number of professional development/administrative training programs
                                                                organized by the institution for teaching and non-teaching staff year-wise
                                                                during the last five years.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.3.1</strong></td>
                                                            <td>
                                                                Proofs-Link-1
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.3.1</strong></td>
                                                            <td>
                                                                Proofs-Link-2
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.4</strong></td>
                                                            <td>
                                                                Average percentage of teachers undergoing online/face-to-face professional
                                                                development Faculty Development Programmes(FDP) during the last five
                                                                years( Professional development programmes, Orientation / Induction Programmes, Refresher Course, Short Term Course)
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.4.1</strong></td>
                                                            <td>
                                                                Total number of teachers attending professional development Programmes viz.,
                                                                Orientation / Induction Programme, Refresher Course, Short Term Course
                                                                Year-wise during last five years
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.4.1</strong></td>
                                                            <td>
                                                                Proofs-Link-1
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr><tr>
                                                            <td className="text-center" ><strong>6.3.4.1</strong></td>
                                                            <td>
                                                                Proofs-Link-2
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.4.1</strong></td>
                                                            <td>
                                                                Proofs-Link-3
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.5</strong></td>
                                                            <td>
                                                                Institution has Performance Appraisal System for teaching and nonteaching staff:

                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.3.5</strong></td>
                                                            <td>
                                                                Sample Academic Performance Appraisal Forms
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>&nbsp;</p>
                                            <p><strong>6.4. Financial Management and Resource Mobilization</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.1</strong></td>
                                                            <td>
                                                                Institution conducts internal and external financial audits regularly
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.1</strong></td>
                                                            <td>
                                                                Sample Audit Report
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.2</strong></td>
                                                            <td>
                                                                Funds / Grants received from non-government bodies, individuals,
                                                                Philanthropists during the last five years (not covered in Criterion III)
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.2.1</strong></td>
                                                            <td>
                                                                Total Grants received from non-government bodies, individuals, Philanthropists
                                                                year-wise during the last five years ( INR in Lakhs)
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.2.1</strong></td>
                                                            <td>
                                                                Funds/Grants received from non-government bodies etc.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.2.1</strong></td>
                                                            <td>
                                                                Sample Cheques Received from Non-Governmental Organizations and MOUs
                                                                Signed.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.2.1</strong></td>
                                                            <td>
                                                                Some other MOUs signed
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.2.1</strong></td>
                                                            <td>
                                                                Sample Cheques Received from Non-Governmental Organizations and MOUs
                                                                Signed.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.3</strong></td>
                                                            <td>
                                                                Institutional strategies for mobilization of funds and the optimal utilization of
                                                                resources
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.4.3</strong></td>
                                                            <td>
                                                                Sample Financial Statement
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>&nbsp;</p>
                                            <p><strong>6.5. Internal Quality Assurance System</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.1</strong></td>
                                                            <td>
                                                                Internal Quality Assurance Cell (IQAC) has contributed significantly for
                                                                institutionalizing the quality assurance strategies and processes

                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.1</strong></td>
                                                            <td>
                                                                Mous Signed by Different Companies
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.1</strong></td>
                                                            <td>
                                                                Paper Publications, Books and Book Chapters, Patent Publications
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.1</strong></td>
                                                            <td>
                                                                Sample Question Papers
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.2</strong></td>
                                                            <td>
                                                                The institution reviews its teaching learning process, structures &amp;
                                                                methodologies of operations and learning outcomes at periodic intervals
                                                                through IQAC set up as per norms and recorded the incremental improvements
                                                                in various activities ( For first cycle – Incremental improvements made for the
                                                                preceding five years with regard to quality For second and subsequent cycles –
                                                                Incremental improvements made for the preceding five years with regard to
                                                                quality and post accreditation quality initiatives )

                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.2</strong></td>
                                                            <td>
                                                                Incremental Improvements
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.3</strong></td>
                                                            <td>
                                                                Quality assurance initiatives of the institution include:
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.3</strong></td>
                                                            <td>
                                                                List of Qualitive Initiatives
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.3</strong></td>
                                                            <td>
                                                                IQAC Circulars and Minutes of Meetings
                                                            </td>
                                                            <td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.3</strong></td>
                                                            <td>
                                                                Annual Quality Assurance Report (AQAR)
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.3</strong></td>
                                                            <td>
                                                                NBA Certificate
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center" ><strong>6.5.3</strong></td>
                                                            <td>
                                                                ISO Certificate
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a target="_blank">View Document</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <h4>Criteria 7- Institutional Values and Best</h4>
                                            <p>&nbsp;</p>

                                            <p>
                                                <strong>
                                                    7.1 Institutional Values and Social Responsibilities
                                                </strong>
                                            </p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.1</strong></td>
                                                            <td>
                                                                Measures initiated by the Institution for the promotion of gender equity during
                                                                the last five yearsDescribe gender equity &amp; sensitization in curricular and co-curricular activities,
                                                                facilities for women on campus etc., within 500 words
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">View Document</a></li>
                                                                    <li><a href="javascript:;" target="_blank">Facilities</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.2</strong></td>
                                                            <td>
                                                                The Institution has facilities for alternate sources of energy and energy
                                                                conservation measures.

                                                                <span>1. Solar energy  </span>
                                                                <span>2. Biogas plant  </span>
                                                                <span>3. Wheeling to the Grid  </span>
                                                                <span>4. Sensor-based energy conservation</span>
                                                                <span>5. Use of LED bulbs/ power efficient equipment   </span>
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            7.1.2 Geo
                                                                            Tagged
                                                                            Photographs
                                                                        </a>
                                                                    </li>
                                                                    <li><a href="javascript:;" target="_blank">Additional Information</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.3</strong></td>
                                                            <td>
                                                                Describe the facilities in the Institution for the management of
                                                                the following types of degradable and non-degradable waste
                                                                (within 500 words)

                                                                <span>1. Solid waste management   </span>
                                                                <span>2. Liquid waste management  </span>
                                                                <span>3. Biomedical waste management </span>
                                                                <span>4. E-waste  management  </span>
                                                                <span>5. Waste recycling system    </span>
                                                                <span>6. Hazardous chemicals and radioactive waste management     </span>
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Relevant
                                                                            documents like
                                                                            agreements/M
                                                                            oUs with
                                                                            Government
                                                                            and other
                                                                            approved
                                                                            agencies
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Geotagged
                                                                            photographs of the
                                                                            facilities
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any other
                                                                            relevant information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.4</strong></td>
                                                            <td>
                                                                Water conservation facilities available in the Institution:

                                                                <span>1. Rain water harvesting      </span>
                                                                <span>2.  Borewell /Open well recharge   </span>
                                                                <span>3. Construction of tanks and bunds  </span>
                                                                <span>4. Waste water recycling    </span>
                                                                <span>5. Maintenance of water bodies and distribution system in the campus  </span>
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">

                                                                            Geotagged
                                                                            photographs /
                                                                            videos of the
                                                                            facilities
                                                                        </a>
                                                                    </li>

                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any other
                                                                            relevant information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.5</strong></td>
                                                            <td>
                                                                Green campus initiatives include
                                                                The institutional initiatives for greening the campus are as follows:

                                                                <span> Restricted entry of automobiles   </span>
                                                                <span>Use of Bicycles/ Battery powered vehicles  </span>
                                                                <span>Pedestrian Friendly pathways  </span>
                                                                <span>Ban on use of Plastic  </span>
                                                                <span>Landscaping with trees and plants </span>
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">

                                                                            Geotagged
                                                                            photographs /
                                                                            videos of the
                                                                            facilities
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Various
                                                                            policy
                                                                            documents /
                                                                            decisions
                                                                            circulated for
                                                                            implementatio
                                                                            n
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any other
                                                                            relevant information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.6</strong></td>
                                                            <td>
                                                                Quality audits on environment and energy are regularly undertaken by the
                                                                The institutional environment and energy initiatives are confirmed through the
                                                                following

                                                                <span>  Green audit      </span>
                                                                <span>Energy audit     </span>
                                                                <span>Environment audit  </span>
                                                                <span>Clean and green campus recognitions/awards    </span>
                                                                <span> Beyond the campus environmental promotional activities    </span>
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Reports
                                                                            on
                                                                            environment
                                                                            and energy
                                                                            audits
                                                                            submitted by the auditing
                                                                            agency
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Certification by
                                                                            the auditing agency
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Certificates of
                                                                            the awards received
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any other
                                                                            relevant information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.7</strong></td>
                                                            <td>
                                                                The Institution has disabled-friendly, barrier free environment

                                                                <span>  Built environment with ramps/lifts for easy access to classrooms.      </span>
                                                                <span>Disabled-friendly washrooms      </span>
                                                                <span>Signage including tactile path, lights, display boards and signposts    </span>
                                                                <span>
                                                                    Assistive technology and facilities for persons with disabilities
                                                                    (Divyangjan) accessible website, screen-reading software, mechanized
                                                                    equipment
                                                                </span>
                                                                <span>
                                                                    Provision for enquiry and information: Human assistance, reader,
                                                                    scribe, soft copies of reading material, screen reading
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Certificates of
                                                                            the awards
                                                                            received
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Policy
                                                                            documents and
                                                                            information brochures
                                                                            on the support to be
                                                                            provided
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of the
                                                                            Software procured for
                                                                            providing the
                                                                            assistance
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any other
                                                                            relevant information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.8</strong></td>
                                                            <td>
                                                                Describe the Institutional efforts/initiatives in providing an inclusive
                                                                environment i.e., tolerance and harmony towards cultural, regional, linguistic,
                                                                communal socioeconomic and other diversities (within 500 words).
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Supporting
                                                                            documents on
                                                                            the information
                                                                            provided (as
                                                                            reflected in the
                                                                            administrative
                                                                            and academic
                                                                            activities of the
                                                                            Institution)
                                                                        </a>
                                                                    </li>

                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any other
                                                                            relevant information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.9</strong></td>
                                                            <td>
                                                                Sensitization of students and employees of the Institution to the constitutional
                                                                obligations: values, rights, duties and responsibilities of citizens.
                                                                Describe the various activities in the Institution for inculcating values for
                                                                being responsible citizens as reflected in the Constitution of India within 500
                                                                words.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details of
                                                                            activities that
                                                                            inculcate
                                                                            values;
                                                                            necessary to
                                                                            render students
                                                                            in to
                                                                            responsible
                                                                            citizens
                                                                        </a>
                                                                    </li>

                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any other
                                                                            relevant information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.10</strong></td>
                                                            <td>
                                                                The Institution has a prescribed code of conduct for students, teachers,
                                                                administrators and other staff and conducts periodic programmes in this
                                                                regard.

                                                                <span>1. The Code of Conduct is displayed on the website       </span>
                                                                <span>2. There is a committee to monitor adherence to the Code of Conduct  </span>
                                                                <span>
                                                                    3. Institution organizes professional ethics programmes for students, teachers,
                                                                    administrators and other staff
                                                                </span>
                                                                <span>
                                                                    4. Annual awareness programmes on Code of Conduct are organized
                                                                </span>

                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Code of
                                                                            ethics policy
                                                                            document
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Details
                                                                            of the
                                                                            monitoring
                                                                            committee
                                                                            composition
                                                                            and minutes of the committee
                                                                            meeting,
                                                                            number of
                                                                            programmes
                                                                            organized,
                                                                            reports on the
                                                                            various
                                                                            programs etc.,
                                                                            in support of
                                                                            the claims
                                                                        </a>
                                                                    </li>

                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any other
                                                                            relevant information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center"><strong>7.1.11</strong></td>
                                                            <td>
                                                                Institution celebrates / organizes national and international commemorative
                                                                days, events and festivals
                                                                Describe the efforts of the Institution in celebrating /organizing national and
                                                                international commemorative days, events and festivals during the last five
                                                                years within 500 words
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Annual
                                                                            report of the
                                                                            celebrations
                                                                            and
                                                                            commemorativ
                                                                            e events for the
                                                                            last five years
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Geotagged
                                                                            photographs of
                                                                            some of the
                                                                            events
                                                                        </a>
                                                                    </li>

                                                                    <li>
                                                                        <a href="javascript:;" target="_blank">
                                                                            Any other
                                                                            relevant information
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            
                                            <p>&nbsp;</p>
                                            <p><strong>7.2 - Best Practices</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>7.2</strong></td>
                                                            <td>
                                                                Describe two best practices successfully implemented by the Institution as per
                                                                NAAC format provided in the Manual.
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">Any Additional Information</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            
                                            <p>&nbsp;</p>
                                            <p><strong>7.3 - Institutional Distinctiveness</strong></p>
                                            <div className="table-responsive">
                                                <table cellspacing="0" className="table table-bordered">
                                                    <thead>
                                                        <tr className="bg-gray">
                                                            <th width="95" className="text-center"><strong>No.</strong></th>
                                                            <th width="450"><strong>Title</strong></th>
                                                            <th><strong>Document</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center"><strong>7.3</strong></td>
                                                            <td>
                                                                7.3.1 Portray the performance of the Institution in one area distinctive to its
                                                                priority and thrust within 1000 words
                                                            </td>
                                                            <td>
                                                                <ul className="list">
                                                                    <li><a href="javascript:;" target="_blank">Any Additional Information</a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                    </div>
                                </div>
                            </Tabs>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default NAAC
