import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const SriSamvidhan2019 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Sri Samvidhan 2019
                                    </h2>
                                    <p className='py-2'>
                                        The MBA department of SWEC organized a management fest on March 2nd, 2019 and invited some renowned management colleges. The event was well received and had about 110 participants from various institutes. The Inaugural function was presided by <strong>Dr. Sindhu</strong> from JNTU Hyderabad and <strong>Dr. G V Rao</strong> who is a well known motivational speaker and taxation expert. The Chief Guest for the Valedictory function was <strong>Mr. Sanjay Basarkar, Director Virtulearn</strong>.
                                    </p>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sri Samvidhan/MBA  Fest Sri Samvidhan - Valedictory Function.jpg">
                                                    <img src="/assets/img/gallery/Sri Samvidhan/MBA  Fest Sri Samvidhan - Valedictory Function.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sri Samvidhan/MBA FEST - Team behind the show.jpg">
                                                    <img src="/assets/img/gallery/Sri Samvidhan/MBA FEST - Team behind the show.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sri Samvidhan/Valedictory Ceremony - Felicitation of Mr. Sanjay Basarkar.jpg">
                                                    <img src="/assets/img/gallery/Sri Samvidhan/Valedictory Ceremony - Felicitation of Mr. Sanjay Basarkar.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sri Samvidhan/MBA FEST - Faculty.jpg">
                                                    <img src="/assets/img/gallery/Sri Samvidhan/MBA FEST - Faculty.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sri Samvidhan/Poster release of MBA FEST- Sri Samvidhan 2019.jpg">
                                                    <img src="/assets/img/gallery/Sri Samvidhan/Poster release of MBA FEST- Sri Samvidhan 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sri Samvidhan/Sri Samvidhan Banner 2019.jpg">
                                                    <img src="/assets/img/gallery/Sri Samvidhan/Sri Samvidhan Banner 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/Sri Samvidhan/Inauguaration by Dr G V Rao.jpg">
                                                    <img src="/assets/img/gallery/Sri Samvidhan/Inauguaration by Dr G V Rao.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default SriSamvidhan2019
