import React,{useEffect} from 'react'
import { Link } from "react-router-dom";

const EEELeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul className='text-start'>
                    <Link to="/dept/eee" onclick="scrollWin()"><li className='text-black'><i class="fa-sharp fa-solid fa-building"></i> <span>About the Department</span> </li></Link>
                    <Link to="/dept/eee/vision-mission-peos" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> Vision, Mission & PEO’s</span> </li></Link>
                    <Link to="/dept/eee/po-and-pso" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> PO and PSO’s</span> </li></Link>
                    <Link to="/dept/it/eee-faculty-list" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span>  Faculty List</span> </li></Link>
                    <Link to="#" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>   Department Academic Calendar</span> </li></Link>
                    <Link to="#" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span> Students projects </span> </li></Link>
                    <Link to="#" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Industry support laboratories </span> </li></Link>
                    <Link to="/dept/eee/industry-internship" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> Industry internship/summer trainings</span> </li></Link>
                    <Link to="/dept/eee/industry-visits" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span>  Industry visits</span> </li></Link>
                    <Link to="/dept/eee/student-development-programs" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span>  Student Development Program (SDP)</span> </li></Link>
                    <Link to="/dept/eee/syllabus" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span>  Syllabus</span> </li></Link>
                    <Link to="#" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span>  Result Analysis</span> </li></Link>
                    <Link to="/dept/eee/placements" onclick="scrollWin()"><li className='text-black'><i class="fa fa-graduation-cap fa-lg"></i> <span>  Placements & higher studies</span> </li></Link>
                    <Link to="/dept/eee/professional-bodies" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Professional bodies & activities</span> </li></Link>
                    <Link to="/dept/eee/magazines" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span> Magazines & Newsletters</span> </li></Link>
                    <Link to="/dept/eee/student-publications" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span> Student publications and achievements</span> </li></Link>
                    <Link to="/dept/eee/faculty-innovations" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Student publications and achievements</span> </li></Link>
                    <Link to="/dept/eee/workshops" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> FDP/STTPS/Workshops</span> </li></Link>
                    <Link to="/dept/eee/faculty-performance" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Faculty performance & appraisal development system</span> </li></Link>
                    <Link to="/dept/eee/laboratory-projects" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span> R & D laboratory, projects & activities</span> </li></Link>
                    <Link to="/dept/eee/academic-sponsered" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Academic/sponsored research & consultancy </span> </li></Link>
                    <Link to="#" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Bridge Course</span> </li></Link>
                    <Link to="/dept/eee/course-files" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Course Files</span> </li></Link>
                    <Link to="#" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Laboratories</span> </li></Link>
                    <Link to="/dept/eee/department-library" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Department Library</span> </li></Link>
                    <Link to="/dept/eee/department-mous" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>   Department MOU’s </span> </li></Link>
                    <Link to="/dept/eee/academic-plan" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>   Academic plan </span> </li></Link>


                </ul>
            </div>
        </div>
    )
}
export default EEELeftTab
