import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import RandDLeftTab from './RandDLeftTab'


const GrantsReceived = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <RandDLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2 ">
                            <div className="how-to-apply">
                                <h2>
                                    Grants Received
                                </h2>
                                <div class="detgails-right-content ">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li><a href="/assets/documents/Scheme Document for Online FDP 2020-21.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Scheme Document for Online FDP 2020-21 </a></li>
                                            <li><a href="/assets/documents/sridevi women.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> SPDC Sanction Letter </a></li>
                                            <li><a href="/assets/documents/S473 (1).pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> STTP Sanction Letter </a></li>
                                            <li><a href="/assets/img/eee_grants_recieved.jpg" target="_blank"><i class="fa-solid fa-image"></i> EEE Grant Received </a></li>

                                        </ul>
                                    </div>

                                </div>
                                <h2>
                                Funding Projects
                                </h2>
                                <div class="detgails-right-content ">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li><a href="/assets/documents/Funding Project.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Grants received from Government and non-governmental agencies </a></li>
                                           
                                        </ul>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default GrantsReceived
