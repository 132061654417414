import React from 'react'
import coursesImg from '../DiscoverMore/courses.png'
import eventsImg from '../DiscoverMore/events.png'
import Events from '../events/Events'
import { Link } from 'react-router-dom'

const Discover = () => {
    return (
        <div>
            <div className="academic-area pt-100 pb-70 bg-f4f6f9">
                <div className="">
                    <div className="section-title">
                        <h2>Discover More
                        </h2>
                        <p>About the premier Engineering College for women in Andhra Pradesh and Telangana states for more than
                            a decade.

                        </p>
                    </div>
                </div>
                <div className="container-fluid p-0">
                    <div className="row g-0">

                        <div className="col-lg-2 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="200" data-aos-once="true">
                            <Link to="/about/about-college">
                                <div className="single-education-card bg-1">
                                    <div className="edication-content">
                                        <div className="icon">
                                            <i className="flaticon-college"></i>
                                        </div>
                                        <Link to="/about/about-college">
                                            <h3 className='pt-2'>About College</h3>
                                        </Link>
                                        <Link to="/about/about-college" className="read-more-btn white-color">Graduate Education<i
                                            className="flaticon-next"></i></Link>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-lg-2 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="400" data-aos-once="true">
                            <Link to="/about/admissions">
                                <div className="single-education-card bg-2">
                                    <div className="edication-content">
                                        <div className="icon">
                                            <i className="flaticon-student-with-graduation-cap"></i>
                                        </div>
                                        <Link to="/about/admissions">
                                            <h3 className='pt-2'>Admissions</h3>
                                        </Link>
                                        <Link to="/about/admissions" className="read-more-btn white-color">Graduate Education<i
                                            className="flaticon-next"></i></Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="600" data-aos-once="true">
                            <Link to="/syllabus">
                                <div className="single-education-card bg-3">
                                    <div className="edication-content">
                                        <div className="icon">
                                            <i className="flaticon-college-graduation"></i>
                                        </div>
                                        <Link to="/syllabus">
                                            <h3 className='pt-2'>Syllabus</h3>
                                        </Link>
                                        <Link to="/syllabus" className="read-more-btn white-color">Graduate Education<i
                                            className="flaticon-next"></i></Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="800" data-aos-once="true">
                            <Link to="/training-and-placements/">
                                <div className="single-education-card bg-4">
                                    <div className="edication-content">
                                        <div className="icon">
                                            <i className="flaticon-athletics"></i>
                                        </div>
                                        <Link to="/training-and-placements/">
                                            <h3 className='pt-2'>Placements </h3>
                                        </Link>
                                        <Link to="/training-and-placements/" className="read-more-btn white-color">Graduate Education<i
                                            className="flaticon-next"></i></Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="600" data-aos-once="true">
                            <Link to="/news-and-events/news">
                                <div className="single-education-card bg-5">
                                    <div className="edication-content">
                                        <div className="icon">
                                            <img className="" width="50" src={coursesImg} alt="" />
                                        </div>
                                        <Link to="/news-and-events/news">
                                            <h3 className='pt-2'>News</h3>
                                        </Link>
                                        <Link to="/news-and-events/news" className="read-more-btn white-color">Graduate Education<i
                                            className="flaticon-next"></i></Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="800" data-aos-once="true">
                            <Link to="/news-and-events/events">
                                <div className="single-education-card bg-6">
                                    <div className="edication-content">
                                        <div className="icon">
                                            <img className="" width="50" src={eventsImg} alt="image" />
                                        </div>
                                        <Link to="/news-and-events/events">
                                            <h3 className='pt-2'>Events</h3>
                                        </Link>
                                        <Link to="/news-and-events/events" className="read-more-btn white-color">Graduate Education<i
                                            className="flaticon-next"></i></Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Events />
        </div >
    )
}

export default Discover
