import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import PlacementsLeftTab from './PlacementsLeftTab';

const DeptWise = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <PlacementsLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Dept wise Placements
                                </h2>
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-info table-bordered table-faculty new-faculty">
                                            <thead>
                                                <tr class="bg-gray">
                                                    <th width="50"><strong>Academic Year</strong></th>
                                                    <th><strong>CSE</strong></th>
                                                    <th><strong>ECE</strong></th>
                                                    <th><strong>EEE</strong></th>
                                                    <th><strong>IT</strong></th>
                                                    <th><strong>Total</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2016-17</td>
                                                    <td>183</td>
                                                    <td>164</td>
                                                    <td>25</td>
                                                    <td>32</td>  <td>404</td>
                                                </tr>
                                                <tr>
                                                    <td>2017-18</td>
                                                    <td>146</td>
                                                    <td>127</td>
                                                    <td>42</td>
                                                    <td>38</td>  <td>353</td>
                                                </tr>
                                                <tr>
                                                    <td>2018-19</td>
                                                    <td>163</td>
                                                    <td>135</td>
                                                    <td>42</td>
                                                    <td>53</td>  <td>393</td>
                                                </tr>
                                                <tr>
                                                    <td>2019-20</td>
                                                    <td>178</td>
                                                    <td>145</td>
                                                    <td>24</td>
                                                    <td>48</td>  <td>395</td>
                                                </tr>
                                                <tr>
                                                    <td>2020-21</td>
                                                    <td>89</td>
                                                    <td>75</td>
                                                    <td>9</td>
                                                    <td>25</td>  <td>198</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default DeptWise
