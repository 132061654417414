import React,{useEffect} from 'react'
import { Link } from "react-router-dom";

const FacilitiesLeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul>
                    <Link to="/infrastructure/central-facilities" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Central Facilities</li></Link>
                    <Link to="/infrastructure/transport" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> Transport facility</li></Link>
                    <Link to="/infrastructure/computer-centre" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Computer Centre </li></Link>
                    <Link to="/infrastructure/swec-campus-hostel" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> SWEC Campus Hostel</li></Link>
                    <Link to="/infrastructure/ict" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> ICT</li></Link>
                </ul>
            </div>
        </div>
    )
}

export default FacilitiesLeftTab
