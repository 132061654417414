import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';
import one from './workshop/1.jpeg'
import two from './workshop/2.jpeg'
import three from './workshop/3.jpeg'
import four from './workshop/4.jpeg'
import five from './workshop/5.jpeg'
import six from './workshop/6.jpeg'
import seven from './workshop/7.jpeg'
import eight from './workshop/8.jpeg'
import nine from './workshop/9.jpg'
import ten from './workshop/10.jpg'
import eleven from './workshop/11.jpg'

const ItWorkshops = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> FDP/STTPS/Workshops
                                </h2>
                                <h6 className='py-2'>IT FDP</h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/it/ATAL IoTFDP (2).pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Internet of things(IoT) 2020 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/IT FDP_JUNE2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IT FDP JUNE 2020-01 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/IT FDP_JUNE2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>IT FDP JUNE 2020-02 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/IT FDP_JULY2020_03.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IT FDP JULY 2020-03 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <h6 className='py-2'>IT Department Training Programme
                                </h6>
                                <div className='row'>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={one} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={two} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={three} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                </div>
                                <div class="detgails-right-content mt-2">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_02.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  IT STUDENT PROGRAM JULY 2020-02 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_03.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IT STUDENT PROGRAM JULY 2020-03  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_04.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IT STUDENT PROGRAM JULY 2020-04  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/IT STUDENT PROGRAM_JULY2020_05.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IT STUDENT PROGRAM JULY 2020-05  </a>
                                            </li><li>
                                                <a href="/assets/documents/it/TRAINING PROGRAM FOR FACULTY AND STUDENTS_JULY2020_01.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> TRAINING PROGRAM FOR FACULTY AND STUDENTS JULY 2020-01  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/TRAINING PROGRAM FOR FACULTY AND STUDENTS_AUG2020_02.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  TRAINING PROGRAM FOR FACULTY AND STUDENTS AUG 2020-02</a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/TRAINING PROGRAM FOR FACULTY AND STUDENTS_AUG2020_03.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  TRAINING PROGRAM FOR FACULTY AND STUDENTS AUG2020 03 </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <h6 className='py-2'>IT SDP
                                </h6>
                                <div className='row'>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={four} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={five} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={six} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={seven} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={eight} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                </div>
                                <h6 className='py-2'>AICTE ATAL FDP
                                </h6>
                                <div className='row'>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={nine} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={ten} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={eleven} className='img-fluid' alt='IT Department Training Programme' loading='lazy' />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default ItWorkshops
