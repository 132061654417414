import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const InternationalYogaDay2022 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Project Expo 2022
                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/yogaday-2022/1.jpg">
                                                    <img src="/assets/img/gallery/yogaday-2022/1.jpg" alt="Image" loading="lazy" class="img-responsive center-block" /
                                                    >
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 ">
                                                <a class="spotlight" href="/assets/img/gallery/yogaday-2022/2.jpg">
                                                    <img src="/assets/img/gallery/yogaday-2022/2.jpg" alt="Image" loading="lazy" class="img-responsive center-block" /
                                                    >
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 ">
                                                <a class="spotlight" href="/assets/img/gallery/yogaday-2022/3.jpg">
                                                    <img src="/assets/img/gallery/yogaday-2022/3.jpg" alt="Image" loading="lazy" class="img-responsive center-block" /
                                                    >
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 ">
                                                <a class="spotlight" href="/assets/img/gallery/yogaday-2022/4.jpg">
                                                    <img src="/assets/img/gallery/yogaday-2022/4.jpg" alt="Image" loading="lazy" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 ">
                                                <a class="spotlight" href="/assets/img/gallery/yogaday-2022/5.jpg">
                                                    <img src="/assets/img/gallery/yogaday-2022/5.jpg" alt="Image" loading="lazy" class="img-responsive center-block" /
                                                    >
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 ">
                                                <a class="spotlight" href="/assets/img/gallery/yogaday-2022/6.jpg">
                                                    <img src="/assets/img/gallery/yogaday-2022/6.jpg" alt="Image" loading="lazy" class="img-responsive center-block" /
                                                    >
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 ">
                                                <a class="spotlight" href="/assets/img/gallery/yogaday-2022/7.jpg">
                                                    <img src="/assets/img/gallery/yogaday-2022/7.jpg" alt="Image" loading="lazy" class="img-responsive center-block" /
                                                    >
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 ">
                                                <a class="spotlight" href="/assets/img/gallery/yogaday-2022/8.jpg">
                                                    <img src="/assets/img/gallery/yogaday-2022/8.jpg" alt="Image" loading="lazy" class="img-responsive center-block" /
                                                    >
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 ">
                                                <a class="spotlight" href="/assets/img/gallery/yogaday-2022/9.jpg">
                                                    <img src="/assets/img/gallery/yogaday-2022/9.jpg" alt="Image" loading="lazy" class="img-responsive center-block" /
                                                    >
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <div class="col-md-6 mb-3 mb-lg-4 ">
                                        <video class="pt-4" width="auto" height="300" controls="">
                                            <source src="/assets/img/gallery/yogaday-2022/10.mp4" type="video/mp4 " />
                                            <source src="/assets/img/gallery/yogaday-2022/10.mp4" type="video/ogg" />
                                        </video>

                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default InternationalYogaDay2022
