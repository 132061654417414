import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Principal from '../About/images/principal.jpg'
import narmada from '../About/images/narmada.png'
import Nagarjuna from '../About/images/Nagarjuna.jpeg'
import ARavikumar from '../About/images/A.Ravi-Kumar.jpg'
import RAVIKUMAR from '../About/images/RAVIKUMAR.jpg'
import Padmavathi from '../About/images/Padmavathi.png'
import Narendra from '../About/images/Narendra.jpg'
import Jagadeesh from '../About/images/S.Jagdeesh.jpg'




const Committie = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>List of Committees
                                </h2>

                                <div class="table-responsive">
                                    <table class="table table-info table-bordered table-faculty">
                                        <thead>
                                            <tr class="bg-gray">
                                                <th><strong>S.No</strong></th>
                                                <th width="300"><strong>Name of the Committee</strong></th>
                                                <th width="300"><strong>Committee Convener</strong></th>
                                                <th><strong>Mail id </strong></th>
                                                {/* <th><strong>Student members </strong></th>*/}
                                                {/* <th><strong>Phone No</strong></th>*/}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Internal Quality Assurance Committee</td>
                                                {/* <td>E.Krishnaveni Reddy</td>
                                            <td>9701798883</td>*/}
                                                <td>Dr. K.Shivnagi Reddy</td>
                                                <td>sivanagireddykalli@gmail.com </td>
                                                {/* <td>N Rohitha</td>*/}
                                                {/* <td>9951523234</td>*/}
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td className='text-blue-tag'><a class=" text-primary" href="/assets/documents/Grievance Redressal Committee 2021-22.pdf" target="_blank">Grievance Redressal Committee</a></td>
                                                <td>Mrs. E.Krishnaveni Reddy</td>
                                                <td>grievance@swec.ac.in</td>
                                                {/* <td>
                                            B Sruthi (CSE),Chatura (ECE),
                                            Pooja M( IT), K Janani (EEE),Nandini G (MBA)
                                            </td>*/}
                                                {/* <td>9701798883</td>*/}
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Internal Complaint Cell</td>
                                                <td>Mrs. M Pragati Reddy</td>
                                                <td>yeddula.pragathireddy@gmail.com</td>
                                                {/* <td>
                                            T Chandrika (CSE C)'H Sruti( ECE C)
                                            K Prashanti( EEE),Sneha Badam (IT),
                                            B Sai Manasa Reddy (MBA)
                                            </td>*/}
                                                {/* <td>9246979620</td>*/}
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Women Empowerment Committee</td>
                                                <td>Mrs. M.Kavita</td>
                                                <td>kavithareddy2414@gmail.com </td>
                                                {/* <td>Pranitha Kangulla (IT)
                                            R.Leekshitha (ECE) </td>*/}
                                                {/* <td>8790392639</td>*/}
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Entrepreneur Development Committee</td>
                                                {/* <td>K.Tulsi Ram</td>
                                            <td>9441329408</td>*/}
                                                <td>Dr Sunil Kumar</td>
                                                <td>psunilkumar.ece@gmail.com</td>
                                                {/* <td>
                                            R Akshaya (ECE),P Rishita (CSE),Shivani Thakur(IT),S Raveena (EEE),Neha Begam (MBA)

                                            </td>*/}
                                                {/* <td>9666075878</td>*/}
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Career Guidance Training And Placement Committee</td>
                                                <td>Mrs SandhyaRani Mantha</td>
                                                <td>training@swec.ac.in </td>
                                                {/* <td>M Spandana Reddy (ECE)</td>*/}
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Research And Development</td>
                                                <td>Dr. S. Jagadeesh</td>
                                                <td>jaga.ssjec@gmail.com</td>
                                                {/* <td>
                                            Sai Manvitha Nadella (ECE), K Anandini(EEE), R Pooja (IT), G Vinnu (CSE)
                                            </td>*/}
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Library Advisory Committee</td>
                                                <td>
                                                    Dr. B Narendra Kumar
                                                </td>
                                                <td>ithod@swec.ac.in</td>
                                                {/* <td>
                                            S Sandhya (ECE),S Vasavi Reddy (IT)
                                            </td>*/}
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>
                                                    Student Welfare Committee
                                                </td>
                                                <td>Mrs.Sarita P</td>
                                                <td>
                                                    eee.swec2@gmail.com
                                                </td>
                                                {/* <td>K Sushma (ECE)</td>*/}
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>
                                                    Minority OBC Committee
                                                </td>
                                                <td>Mrs Sujathakumari N</td>
                                                <td>nsujathakumari@gmail.com</td>
                                                {/* <td>Rayeea Taranum (CSE C)</td>*/}
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>SC/ST Committee </td>
                                                <td>Dr. S. Jagadeesh</td>
                                                <td>jaga.ssjec@gmail.com</td>
                                                {/* <td>B Anusha (EEE)</td>*/}
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td className='text-blue-tag'><a class="text-primary" href="/assets/documents/antiragging members-2023.pdf" target="_blank">Anti-Ragging Committee</a></td>
                                                {/* <td>K.Shivnagi Reddy</td>
                                            <td>9951523234</td>*/}
                                                <td>Mr. B Prashanth Kumar</td>
                                                <td>prashanth4328@gmail.com</td>
                                                {/* <td>
                                            I Lasya (ECE),Jahnavi Ch (CSE),
                                            B Sindhusree (EEE),Pooja Reddy (IT),
                                            Sandya Reddy MBA
                                            </td>*/}
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>NSS &amp; NCC Committee</td>
                                                <td>Mr. G.Dora Babu</td>
                                                <td>dorababumsc@gmail.com </td>
                                                {/* <td>
                                            Vadapalli RagaYuktha (ECE A)
                                            </td>*/}
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>Sports Committee</td>
                                                <td>Mr. Saibaba</td>
                                                <td>saibabavarda@gmail.com</td>
                                                {/* <td>
                                            S Akhila(CSE),Koneru Maniteja C(ECE),
                                            B Nandini (EEE),Jaswanthi I (IT)
                                            </td>*/}
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td>Hobbies Committee </td>
                                                <td>Dr.M Ramasubramanian</td>
                                                <td>Ramanmass01@gmail.com</td>
                                                {/* <td>
                                            P Archana Reddy CSE,Ruthika Reddy ECE,C Lohitha  EEE, D Shravya  IT
                                            </td>*/}
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td>Alumni Committee</td>
                                                <td>Mrs. K Anusha </td>
                                                <td> swecanu@gmail.com</td>
                                                {/* <td>
                                            M Sanjana (CSE)
                                            </td>*/}
                                            </tr>
                                            <tr>
                                                <td>17</td>
                                                <td>Canteen Committee</td>
                                                <td>Mr. B.Sudhakar Reddy</td>
                                                <td>susareddy@gmail.com </td>
                                                {/* <td>Erra Keerthi (IT) </td>*/}
                                            </tr>
                                            <tr>
                                                <td>18</td>
                                                <td>Social Responsibility Cell</td>
                                                <td>Dr.Inderjeet Singh</td>
                                                <td>drindar2020@gmail.com</td>
                                                {/* <td>Ch. Harshini (ECE)</td>*/}
                                            </tr>
                                            <tr className='border-0'>
                                                <td>19</td>
                                                <td>College Disciplinary committee</td>
                                                <td>Mr. A Nagarjuna Reddy</td>
                                                <td>anr304@gmail.com</td>
                                                {/* <td>Ch. Harshini (ECE)</td>*/}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className='py-3'>College Disciplinary Committee – 2022-23
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-info table-bordered table-faculty">
                                        <thead>
                                            <tr class="bg-gray">
                                                <th width="7%"><strong>S.No</strong></th>
                                                <th><strong>Photo</strong></th>
                                                <th><strong>Name</strong></th>
                                                <th><strong>Designation</strong></th>
                                                <th><strong>Designation in Committee</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td><img src={Principal} alt="Faculty image" width="90" loading='lazy' /></td>
                                                <td>Dr. A Narmada</td>
                                                <td>Principal</td>
                                                <td>Chairperson</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td><img src={Nagarjuna} alt="Faculty image" width="90" /></td>
                                                <td>Mr. A Nagarjuna Reddy</td>
                                                <td>IT DEPT</td>
                                                <td>Convenor</td>
                                            </tr>

                                            <tr>
                                                <td>3</td>
                                                <td><img src={ARavikumar} alt="Faculty image" width="90" /></td>
                                                <td>Dr. A Ravikumar</td>
                                                <td>HOD CSE</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td><img src={RAVIKUMAR} alt="Faculty image" width="90" /></td>
                                                <td>Dr K.C.Ravikumar</td>
                                                <td>HOD S&amp;H</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td><img src={Jagadeesh} alt="Faculty image" width="90" /></td>
                                                <td>Dr. S Jagadeesh</td>
                                                <td>HOD ECE</td>
                                                <td>Member</td>
                                            </tr>

                                            <tr>
                                                <td>6</td>
                                                <td><img src={Padmavathi} alt="Faculty image" width="90" /></td>
                                                <td>Dr. U Padmavathi</td>
                                                <td>HOD MBA</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td><img src={Narendra} alt="Faculty image" width="90" /></td>
                                                <td>Dr. D Narendra Kumar</td>
                                                <td>HOD IT</td>
                                                <td>Member</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className='py-4'>Women Protection Cell – 2022-23
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-info table-bordered table-faculty">
                                        <thead>
                                            <tr class="bg-gray">
                                                <th width="7%"><strong>S.No</strong></th>
                                                <th><strong>Name</strong></th>
                                                <th><strong>Designation</strong></th>
                                                <th><strong>Designation in Committee</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr. A Narmada</td>
                                                <td>Principal</td>
                                                <td>Chairperson</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>N Sujatha Kumari </td>
                                                <td>Assistant Professor</td>
                                                <td>Convenor</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Mrs. N S Anita</td>
                                                <td>Assistant Professor</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>C Sandhya Reddy</td>
                                                <td>Assistant Professor</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Mrs. Esthar Rani</td>
                                                <td>Assistant Professor</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Deepthi Reddy</td>
                                                <td>Assistant Professor</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Dr. D Madhavi</td>
                                                <td>Assistant Professor</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>G Priyanka</td>
                                                <td>EEE Student</td>
                                                <td>Student Member</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>K Swetha</td>
                                                <td>CSE Student</td>
                                                <td>Student Member</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>K Sruthi</td>
                                                <td>IT Student</td>
                                                <td>Student Member</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>Madhumitha J</td>
                                                <td>ECE Student</td>
                                                <td>Student Member</td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>Mahalakshmi B</td>
                                                <td>MBA Student</td>
                                                <td>Student Member</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Committie
