import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import MbaLeftTab from './MbaLeftTab';


const MbaWorkShop = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Workshop and Seminar
                                </h2>
                                <p>Number of workshops/seminars conducted on Intellectual Property Rights (IPR) and Industry-Academia Innovative practices during the last five years</p>
                                <div class="table-responsive mb-4 mt-2" >
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th width="65"><strong>S.No</strong></th>
                                                <th width="180"><strong>Name of the workshop/ seminar</strong></th>
                                                <th width="220"><strong>Date</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    Seminar on product design outsourcing
                                                </td>
                                                <td>05-03-2019</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>
                                                    Intellectual property rights and valuation
                                                </td>
                                                <td>18-02-2019 </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>
                                                    Seminar on exploring the link between academic science
                                                    and industrial innovation
                                                </td>
                                                <td>01-08-2018</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>
                                                    Workshop on investing in today’s economy
                                                </td>
                                                <td>22-09-2018 </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>
                                                    Seminar on innovation leadership and management
                                                </td>
                                                <td>12-03-2019 </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>
                                                    Automotive trends and global opportunities
                                                </td>
                                                <td>02-03-2018</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>
                                                    Seminar on innovation and relations between research
                                                    and industry
                                                </td>
                                                <td>27-02-2018</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>
                                                    Awareness on ethics
                                                </td>
                                                <td>08-02-2018</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>
                                                    Trademark and Copyright Introduction and Filing
                                                    Procedure
                                                </td>
                                                <td>08-11-2017</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>
                                                    Workshop on Intellectual Property Rights (IPR)
                                                    Awareness
                                                </td>
                                                <td>15-09-2017 to 16-09-2017</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>
                                                    Intellectual property in innovation and new product
                                                    development
                                                </td>
                                                <td>16-05-2017 </td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>
                                                    IP and cultural heritage in the digital world
                                                </td>
                                                <td>21-07-2017 </td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>
                                                    Seminar on recent advances and innovation for
                                                    sustainable business management
                                                </td>
                                                <td>12-07-2017</td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>
                                                    IPR Awareness on copy right
                                                </td>
                                                <td>12-06-2017</td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td>
                                                    seminar on advantages of IPR and its applications in
                                                    industry
                                                </td>
                                                <td>31-05-2017 </td>
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td>
                                                    Patents strategy filling a provisional patent application
                                                </td>
                                                <td>20-03-2017</td>
                                            </tr>
                                            <tr>
                                                <td>17</td>
                                                <td>
                                                    Seminar on Entrepreneurship
                                                </td>
                                                <td>11-10-2016</td>
                                            </tr>
                                            <tr>
                                                <td>18</td>
                                                <td>
                                                    workshop on application of trade marking businesses
                                                </td>
                                                <td>12-06-2016</td>
                                            </tr>
                                            <tr>
                                                <td>19</td>
                                                <td>
                                                    seminar on application patent law
                                                </td>
                                                <td>29-07-2016</td>
                                            </tr>
                                            <tr>
                                                <td>20</td>
                                                <td>
                                                    Guest Lecture on Water Resources Management
                                                </td>
                                                <td>19-05-2016</td>
                                            </tr>
                                            <tr>
                                                <td>21</td>
                                                <td>
                                                    workshop on IPR on students and faculty members
                                                </td>
                                                <td>08-02-2016 </td>
                                            </tr>
                                            <tr>
                                                <td>22</td>
                                                <td>
                                                    Talk on entrepreneurship
                                                </td>
                                                <td>12-10-2015</td>
                                            </tr>
                                            <tr>
                                                <td>23</td>
                                                <td>
                                                    Seminar On Project and Patent Filing
                                                </td>
                                                <td>22-09-2015</td>
                                            </tr>
                                            <tr>
                                                <td>24</td>
                                                <td>
                                                    WIPO Sub-Regional Symposium on Enforcement of
                                                    Intellectual Property Rights (IPRs)
                                                </td>
                                                <td>18-08-2015 </td>
                                            </tr>
                                            <tr>
                                                <td>25</td>
                                                <td>
                                                    Seminar on Intellectual Property Management in
                                                    Industries &amp; Academics
                                                </td>
                                                <td>27-05-2015</td>
                                            </tr>
                                            <tr>
                                                <td>26</td>
                                                <td>
                                                    Seminar on the occasion of induction program on trends
                                                    and opportunities in finance sector
                                                </td>
                                                <td>26-05-2015</td>
                                            </tr>
                                            <tr>
                                                <td>27</td>
                                                <td>
                                                    workshop on resume writing -innovative and unique
                                                    resume preparation ideas
                                                </td>
                                                <td>05-01-2015 </td>
                                            </tr>
                                            <tr>
                                                <td>28</td>
                                                <td>
                                                    Workshop On Innovation &amp; IPR
                                                </td>
                                                <td>18-11-2014 </td>
                                            </tr>
                                            <tr>
                                                <td>29</td>
                                                <td>
                                                    Guest Lecture on Engineering Seismology
                                                </td>
                                                <td>29-08-2014 </td>
                                            </tr>
                                            <tr>
                                                <td>30</td>
                                                <td>
                                                    Recent trends in manufacturing
                                                </td>
                                                <td>19-01-2015 </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}



export default MbaWorkShop
