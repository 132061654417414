import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const WomensDay2021 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2>Women's Day

                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                               <a class="spotlight" href="/assets/img/gallery/womens-day-2021/1.jpg">
                                                    <img src="/assets/img/gallery/womens-day-2021/1.jpg" alt="Image" class="img-responsive center-block"
                                                    />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                               <a class="spotlight" href="/assets/img/gallery/womens-day-2021/2.jpg">
                                                    <img src="/assets/img/gallery/womens-day-2021/2.jpg" alt="Image" class="img-responsive center-block"
                                                        loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                               <a class="spotlight" href="/assets/img/gallery/womens-day-2021/8.jpg">
                                                    <img src="/assets/img/gallery/womens-day-2021/8.jpg" alt="Image" class="img-responsive center-block"
                                                        loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                               <a class="spotlight" href="/assets/img/gallery/womens-day-2021/5.jpg">
                                                    <img src="/assets/img/gallery/womens-day-2021/5.jpg" alt="Image" class="img-responsive center-block"
                                                        loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                               <a class="spotlight" href="/assets/img/gallery/womens-day-2021/6.jpg">
                                                    <img src="/assets/img/gallery/womens-day-2021/6.jpg" alt="Image" class="img-responsive center-block"
                                                        loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                               <a class="spotlight" href="/assets/img/gallery/womens-day-2021/7.jpg">
                                                    <img src="/assets/img/gallery/womens-day-2021/7.jpg" alt="Image" class="img-responsive center-block"
                                                        loading="lazy" />
                                                </a>
                                            </div>



                                            <div class="col-md-6 mb-3 mb-lg-4">
                                               <a class="spotlight" href="/assets/img/gallery/womens-day-2021/9.jpg">
                                                    <img src="/assets/img/gallery/womens-day-2021/9.jpg" alt="Image" class="img-responsive center-block"
                                                        loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                               <a class="spotlight" href="/assets/img/gallery/womens-day-2021/3.jpg">
                                                    <img src="/assets/img/gallery/womens-day-2021/3.jpg" alt="Image" class="img-responsive center-block"
                                                        loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                               <a class="spotlight" href="/assets/img/gallery/womens-day-2021/4.jpg">
                                                    <img src="/assets/img/gallery/womens-day-2021/4.jpg" alt="Image" class="img-responsive center-block"
                                                        loading="lazy" />
                                                </a>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default WomensDay2021
