import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';


const CSEStudentDPrograms = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Student Development Program
                                </h2>
                                <div className=''>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop10 committee-table">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td><p class="text-center"><strong>S.No</strong></p></td>
                                                    <td><p><strong>Event Name</strong></p></td>
                                                    <td><p><strong>Starting Date</strong></p></td>
                                                    <td><p><strong>Duration</strong></p></td>
                                                </tr>
                                                <tr height="19" className='text-center' >
                                                    <td className='bg-light' colspan="4" height="19" width="525" >2020-21</td>
                                                </tr>
                                                <tr height="19" >
                                                    <td >1</td>
                                                    <td >Yuvasamarthya - 2021</td>
                                                    <td  >30-03-2021</td>
                                                    <td >2 Days</td>
                                                </tr>
                                                <tr height="19" >
                                                    <td >2</td>
                                                    <td >
                                                        Virtual Industrial
                                                        Visit at Tata Enterprise Business, TTSL
                                                    </td>
                                                    <td  >08-01-2021</td>
                                                    <td >1 Day</td>
                                                </tr>
                                                <tr height="19" >
                                                    <td >3</td>
                                                    <td >
                                                        FDP- Moodle Learning
                                                        Management System
                                                    </td>
                                                    <td  >10-08-2020</td>
                                                    <td >5 Days</td>
                                                </tr>
                                                <tr height="19" >
                                                    <td >4</td>
                                                    <td >
                                                        Webinar - Project
                                                        Development in Dot NET.
                                                    </td>
                                                    <td  >14-08-2020</td>
                                                    <td >1 Day</td>
                                                </tr>
                                                <tr height="19" >
                                                    <td >5</td>
                                                    <td >Amazon Web Services</td>
                                                    <td  >08-08-2020</td>
                                                    <td >12 Weeks</td>
                                                </tr>
                                                <tr height="19" >
                                                    <td >6</td>
                                                    <td >STP - JAVA</td>
                                                    <td  >20-07-2020</td>
                                                    <td >5 Days</td>
                                                </tr>
                                                <tr height="19" className='text-center' >
                                                    <td className='bg-light' colspan="4" height="19" >2019-20</td>
                                                </tr>
                                                <tr height="19" >
                                                    <td height="19" >1</td>
                                                    <td >Webinar - Patent Writing and Filling</td>
                                                    <td>17-06-2020</td>
                                                    <td >1 Day</td>
                                                </tr>
                                                <tr height="19" >
                                                    <td >2</td>
                                                    <td >Hackathon</td>
                                                    <td  >24-02-2020</td>
                                                    <td >3 Days</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default CSEStudentDPrograms
