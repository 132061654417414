import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import ActivitiesLeftTab from './ActivitiesLeftTab';
import sswec from '../Activities/sswes/sswec.png'
import analysisofsswec from '../Activities/sswes/analysisofsswec.png'


const SSWES = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ActivitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> SSWEC (Sridevi Society of Women Engineers Club)
                                </h2>
                                <p>Sridevi Society of Women Engineers Club (SSWEC) includes the processes of analyzing, monitoring and managing intended and unintended social consequences. Our organization is centered at the passion for our students success and continues to evolve with the challenges and opportunities reflected in today's society. Its primary purpose is to bring about a more sustainable and human environment. We take social responsibility and we influence, advice and develop each other and our environment through dialogue and dissemination of knowledge towards society.

                                </p>
                                <p>Empower women to reach their pinnacle in careers as engineers, leaders, so as to expand the image of the engineering and technology professions, to inculcate positive thinking in improving the quality of life, to demonstrate the value of diversity and inclusion towards society.

                                </p>
                                <p>SSWEC activities, helps the students development & appreciation to other person's point of view and also show consideration towards other living beings.
                                </p>
                                <img src={sswec} className='img-fluid' alt='sswec' loading='lazy' />
                            </div>
                            <div className='how-to-apply'>
                                <h6 className='pt-3 pb-2'>SSWEC CLUB
                                </h6>
                                <p>Education at SWEC College is more than just what you gain in the class room. In addition to the learning and growing, there are ample opportunities to develop mind, body, and spirit holistically as a person and as a leader. Keeping in tandem with the all-round development of students, SSWEC Sridevi Society Women’s Engineering Club has been started on 8th August 2013. The students of SSWEC actively organize and participate in various activities of this club. The main objective of the club is to empower women to reach their pinnacle in careers as engineers, leaders, athletes, so as to expand the images of the engineering and technology professions, and to inculcate positive thinking in improving the quality of life, to demonstrate the value of diversity and inclusion towards society.

                                </p>
                                <p>Social Awareness Programmes organized through this club aims in spreading awareness about several local and global issues. Club organizes social events through which students of SWEC get the chance to communicate with the people in the society and even try to educate them in various aspects.
                                </p>
                                <p>The Sridevi Society Women’s Engineering Club is dedicated to discussing and informing the public about important issues surrounding women and other minorities. SSWEC goal is to provide intersectional knowledge of the struggles, billions of women have faced and continue to face simply because of their gender, bringing them to the forefront in a generation of action and change.
                                </p>
                                <p>Such as creating technical awareness to the society (i.e) an opportunity for the non technical students or school going kids to showcase their talent for technical knowledge so as to solve their day to day problems using the latest technology available. So as to have basic knowledge of the daily needs and necessities in private and government sector.

                                </p>
                                <h6 className='pt-3 pb-2'>Integrity
                                </h6>
                                <p>We aspire to the highest level of ethical behavior as evidenced by honesty and dignity in our personal and professional relationships and responsibilities.
                                </p>
                                <h6 className='pt-3 pb-2'>Inclusive Environment
                                </h6>
                                <p>We embrace diversity in its broadest interpretation and commit to creating an inclusive environment for all our members and stakeholders. We value the contributions of a diverse membership, which enables SWE to achieve its full potential.
                                </p>
                                <h6 className='pt-3 pb-2'>Mutual Support
                                </h6>
                                <p>We provide an organization that fosters mentoring and the development of professional and personal networks.
                                </p>
                                <h6 className='pt-3 pb-2'>Professional Excellence
                                </h6>
                                <p>We conduct our activities in a professional manner, demonstrating and demanding the highest standards of business practices.
                                </p>
                                <h6 className='pt-3 pb-2'>Trust
                                </h6>
                                <p>We share a common definition of success with open, transparent access to information, building mutual respect and confidence in the competence of those with whom we lead, serve and partner with.
                                </p>
                                <h6 className='pt-3 pb-2'>Objectives of SSWEC
                                </h6>
                                <div class="apply-list border-0">

                                    {/* <ul class="pt-4">
                                          <li><i class="ri-checkbox-circle-line"></i><i class="ri-checkbox-circle-line"></i></li>
                                    </ul> */}
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            To recognize outstanding contribution by student volunteers and Programme
                                            Coordinators in community service.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            To encourage young student volunteers to develop their personality through community
                                            service.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            To encourage the Programme Coordinators of SSWEC for catering the needs of Social
                                            Service through the volunteers.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>To motivate Volunteers for continuing their selfless service towards community work. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Assessing students with mental health concerns. </li>
                                    </ul>
                                    <h6 className='pt-3 pb-2'>SSWEC BYLAWS I
                                    </h6>
                                    <p><strong>Article 1- Name of organization
                                    </strong></p>
                                    <p>The name of this organization shall be The Women’s Empowerment Club.
                                    </p>
                                    <p><strong>Article 2- Purpose of the Organization
                                    </strong></p>
                                    <p>The purpose of this organization shall be to establish a strong community of young women on campus by helping to build character, improve self-awareness, and gain confidence within one self.
                                    </p>
                                    <h6 className='pt-3 pb-2'>MISSION SSWEC
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            To inculcate spontaneous willingness to savor pleasant and positive thoughts in young
                                            minds.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>To enhance the meaning of life </li>
                                        <li><i class="ri-checkbox-circle-line"></i>To strive for righteous human purists. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>To address the intellectual, emotional and educational needs of the society. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>To enlighten the academic and non-academic endeavors to sensitize society.  </li>
                                        <li><i class="ri-checkbox-circle-line"></i>To widen the mental and intellectual of the younger generation nation building </li>
                                    </ul>
                                    <h6 className='pt-3 pb-2'>Rules and Regulations of SSWEC:
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The SSWEC club members shall maintain timely, accurate, and confidential records that
                                            document services.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The students must be able to demonstrate outcomes of the events which are organized by
                                            the club.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>To promote accountability to the local education agency and community.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To take part in all the activities of Social community clubs. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            To disseminate information gained through social community clubs among their fellow
                                            students as well as among the people living in their respective locality.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            To organize and actively participate in consumer protection programs being organized in
                                            their schools as well as in their locality, mobilizing their fellow students and general public.
                                        </li>
                                    </ul>
                                    <h6 className='pt-3 pb-2'>SSWEC ADVISORY MEMBERS:
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop20">
                                            <thead class="bg-gray">
                                                <tr>
                                                    <th><strong>S.No </strong></th>
                                                    <th><strong>Name of the member </strong></th>
                                                    <th><strong>Designation </strong></th>
                                                    <th><strong>Nature  </strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Dr B L Malleswari </td>
                                                    <td>Principal </td>
                                                    <td>Chairman </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Dr D Padmavthi </td>
                                                    <td>EEE HOD  </td>
                                                    <td>Convener  </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Dr A.Narmada </td>
                                                    <td>ECE HOD  </td>
                                                    <td>Member  </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Dr A.Goutami latha </td>
                                                    <td>CSE HOD  </td>
                                                    <td>Member  </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>Dr U Padmavathi </td>
                                                    <td>MBA HOD  </td>
                                                    <td>Member  </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Dr M.Ramasubramanyam </td>
                                                    <td>IT HOD  </td>
                                                    <td>Member </td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>Dr K.Mohanram </td>
                                                    <td>Professor, ECE  </td>
                                                    <td>Member &amp; Coordinator  </td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>Dr P.Avinash </td>
                                                    <td>Professor, IT  </td>
                                                    <td>Member  </td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>Dr Anantha Krishna </td>
                                                    <td>Professor, CSE  </td>
                                                    <td>Member  </td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td>Dr Suneel Kumar </td>
                                                    <td>Professor, ECE  </td>
                                                    <td>Member  </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='pt-4'>
                                        <p><strong>Sridevi women’s society includes the following club
                                        </strong></p>
                                        <p>1.Green energy club 2.Robotics club 3.IOT connect club 4.Profession connect club 5.Technology sharing club 6.FOSS club 7.Women in management club 8.Toast masters club 9.Science club 10.Business live and economy club 11.Arts and cultural club 12.Business and incubation club
                                        </p>
                                    </div>
                                    <h6 className='pt-3 pb-2'>Activities Comprising Sswec:
                                    </h6>
                                    <ul class="list">
                                        <li><i class="ri-checkbox-circle-line"></i>Importance of education for the girl students</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Lecture on women rights </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Social reforms empowering women </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Awareness on dengue and malaria </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Importance of vote </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Awareness program on literacy rate </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Awareness program on significance of education </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Awareness program on women education </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Child welfare service  </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Awareness program on cleanliness of living areas  </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Fundamental rights on government </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Awareness program on health &amp; hygiene </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Awareness program on significance of graduation </li>
                                    </ul>
                                    <p>While events like these have nothing to do with academics, they have at least something important to do with a student’s overall learning experience in school.
                                    </p>
                                    <h4 className='py-3'>Impact of SSWEC</h4>
                                    <h6 className='pt-3 pb-2'>1.To develop emotional intelligence
                                    </h6>
                                    <p>Social events develop social skills and empathy, the outward oriented dimensions of emotional intelligence. The interactions or conversations elicited by events helps the students to build relationships, understand different perspectives and engage other cultures. Social events provide an opportunity to expand one’s social circle.

                                    </p>
                                    <h6 className='pt-3 pb-2'>2.To take a break
                                    </h6>
                                    <p>Work with no breaks diminishes productivity. It’s good to take one’s mind off the troubles of exams or reports from time to time, something that participating in events can provide. Whether it’s by watching a movie or volunteering for a fundraiser, a “study break” lets students recuperate by focusing on activities that exercise other creative, mental muscles.

                                    </p>
                                    <h6 className='pt-3 pb-2'>3.To build relationships
                                    </h6>
                                    <p>Participating in on-campus social events enables students to meet people who share common interests and individuals who can help them academically, or even professionally. Relationship-building is a good habit that students need to learn because the world of work requires aptitude in negotiation, communication and, well, more networking.
                                    </p>
                                    <p>SSWEC is best understood as an umbrella framework that embodies the evaluation of all impacts on humans and on all the ways in which people and communities interact with their socio-cultural, economic surroundings. SSWEC thus has strong links with a wide range of specialist sub-fields involved in the assessment of areas such as:
                                    </p>

                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i> Community impacts</li>
                                        <li><i class="ri-checkbox-circle-line"></i> Social impacts  </li>
                                        <li><i class="ri-checkbox-circle-line"></i> Development impacts</li>
                                        <li><i class="ri-checkbox-circle-line"></i> Gender impacts </li>
                                        <li><i class="ri-checkbox-circle-line"></i> Health impacts  </li>
                                        <li><i class="ri-checkbox-circle-line"></i> Impacts on indigenous rights </li>
                                        <li><i class="ri-checkbox-circle-line"></i> Educational impacts </li>
                                        <li><i class="ri-checkbox-circle-line"></i> Impacts on social and human capital  </li>
                                        <li><i class="ri-checkbox-circle-line"></i> Impacts on societies </li>
                                    </ul>
                                    <p>As such, comprehensive SSWEC cannot normally be undertaken by a single person, but requires a team approach.
                                    </p>
                                    <p>Sridevi women’s engineering college conducted different activities under SSWEC like, “social impacts” refer to the consequences to human population of any public or private actions that alter the ways in which people live, work, play, relate to one another, organize to meet their needs and generally cope as members of the society. Club deals with training of students to build positive attitude and reciprocate the same to the society. Every year the increase of participants from all years in the social activities showing the Impact of students dealing towards society.
                                    </p>
                                </div>
                                <img src={analysisofsswec} className='img-fluid' alt='sswec' loading='lazy' />

                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default SSWES
