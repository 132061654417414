import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';

const ItPlacements = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Placements
                                </h2>
                                <div className='pt-2'>
                                    <h6 className='pb-2'>List of students placed in 2019-20
                                    </h6>
                                    <div>
                                        <div class="table-responsive">
                                            <table class="table table-bordered mtop10">
                                                <tbody>

                                                    <tr class="bg-gray">
                                                        <td><strong>Roll Number</strong></td>
                                                        <td><strong>Name</strong></td>
                                                        <td><strong>Branch</strong></td>
                                                        <td><strong>Company Name</strong></td>
                                                        <td><strong>&nbsp;Salary</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1224</td>
                                                        <td>N. SAMITHA</td>
                                                        <td>IT</td>
                                                        <td>
                                                            Pascalcase Software
                                                            Pvt Ltd
                                                        </td>
                                                        <td>3LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1213&nbsp;</td>
                                                        <td>K. LOHITHA</td>
                                                        <td>IT</td>
                                                        <td>
                                                            MICRON TECHNOLOGY,
                                                            INC.&nbsp;
                                                        </td>
                                                        <td>7.5LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1215</td>
                                                        <td>K. Samanvitha</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC Technologies Pvt
                                                            Ltd
                                                        </td>
                                                        <td>3.6LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1203</td>
                                                        <td>B.Akhila</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC Technologies Pvt
                                                            Ltd
                                                        </td>
                                                        <td>3.6 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1202</td>
                                                        <td>B.Divya Vasavi</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC Technologies Pvt
                                                            Ltd
                                                        </td>
                                                        <td>3.6 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1217</td>
                                                        <td>
                                                            Lakshmi Meghana
                                                            Aitha&nbsp;
                                                        </td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC Technologies Pvt
                                                            Ltd&nbsp;
                                                        </td>
                                                        <td>3.6 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1214</td>
                                                        <td>K.&nbsp; Anusha&nbsp;</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC Technologies Pvt
                                                            Ltd&nbsp;
                                                        </td>
                                                        <td>3.6 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1228</td>
                                                        <td>Neelam Usha rani</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC technologies Pvt
                                                            Ltd
                                                        </td>
                                                        <td>3.6 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1220</td>
                                                        <td>Samhitha.M</td>
                                                        <td>IT</td>
                                                        <td>CAPGEMINI</td>
                                                        <td>3.8LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1223</td>
                                                        <td><span zeum4c11="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">Nallapukeerthi</span></td>
                                                        <td>IT</td>
                                                        <td>CAPGEMINI</td>
                                                        <td>3.8LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1245</td>
                                                        <td>V Anusha</td>
                                                        <td>IT</td>
                                                        <td>
                                                            COGNIZANT
                                                            Technologies Pvt ltd
                                                        </td>
                                                        <td>4&nbsp; LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1239</td>
                                                        <td>Shaik Reshma Begum</td>
                                                        <td>IT</td>
                                                        <td>
                                                            T and D communication
                                                            Pvt Ltd
                                                        </td>
                                                        <td>6.5LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1246</td>
                                                        <td>V.Sujana</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC technology Pvt
                                                            Ltd
                                                        </td>
                                                        <td>3.6 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1241</td>
                                                        <td>Shiva Archana</td>
                                                        <td>IT</td>
                                                        <td>
                                                            COGNIZANT
                                                            Technologies Pvt ltd
                                                        </td>
                                                        <td>4 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1209</td>
                                                        <td>k.Ruchitha</td>
                                                        <td>IT</td>
                                                        <td>CAPGEMINI</td>
                                                        <td>3.8 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1206</td>
                                                        <td>E.Samatha</td>
                                                        <td>IT</td>
                                                        <td>TCS</td>
                                                        <td>3.3 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1232</td>
                                                        <td>P. Divya</td>
                                                        <td>IT</td>
                                                        <td>CAPGEMINI</td>
                                                        <td>3.8LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1242</td>
                                                        <td>T.Maulikaa</td>
                                                        <td>IT</td>
                                                        <td>CAPGEMINI&nbsp;</td>
                                                        <td>3.8LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1243</td>
                                                        <td>T Sravya</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC technology Pvt
                                                            Ltd
                                                        </td>
                                                        <td>3.6LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1235</td>
                                                        <td>S Lahari</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC technology Pvt
                                                            Ltd
                                                        </td>
                                                        <td>3.6LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1240</td>
                                                        <td>Shanya Verma</td>
                                                        <td>IT</td>
                                                        <td>CAPGEMINI</td>
                                                        <td>3.8LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1237</td>
                                                        <td>S. Shravani</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC Technology Pvt
                                                            Ltd
                                                        </td>
                                                        <td>3.6LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1218</td>
                                                        <td>L. ALEKYA</td>
                                                        <td>IT</td>
                                                        <td>TECH MAHINDRA&nbsp;</td>
                                                        <td>3.2 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1233</td>
                                                        <td>P.Poojitha</td>
                                                        <td>IT</td>
                                                        <td>
                                                            COGNIZANT
                                                            Technologies Pvt ltd
                                                        </td>
                                                        <td>4&nbsp; LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1234</td>
                                                        <td>P.Shivani</td>
                                                        <td>IT</td>
                                                        <td>CAPGEMINI</td>
                                                        <td>3.8 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1211</td>
                                                        <td>K.Sushma</td>
                                                        <td>IT</td>
                                                        <td>CAPGEMINI</td>
                                                        <td>3.8 LPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1236</td>
                                                        <td><span zeum4c11="PR_2_0" data-ddnwab="PR_2_0" aria-invalid="spelling" class="LI ng">S.Rohila</span></td>
                                                        <td>IT</td>
                                                        <td>LEGATO</td>
                                                        <td>3.25 LPA&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>16D21A1212</td>
                                                        <td>K. Divya</td>
                                                        <td>IT</td>
                                                        <td>
                                                            DXC Technology Pvt
                                                            Ltd
                                                        </td>
                                                        <td>3.6LPA</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h6 className='py-2'>List of students placed in 2017-2018
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td width="50"><strong>S.No</strong></td>
                                                    <td width="109"><strong>ROLL NO</strong></td>
                                                    <td width="231"><strong>NAME</strong></td>
                                                    <td width="70"><strong>BRANCH</strong></td>
                                                    <td width="153"><strong>COMPANY NAME</strong></td>
                                                    <td width="101"><strong>CTC</strong></td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>1</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1219</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>YALAMANCHILI MAHITHA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>aliens group</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>270400</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>2</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1252</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>GADDAMEDI VARSHA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>aliens group</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>270400</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>3</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1242</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>GUNDALA SHRAVANI</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Amazon</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>4</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1201</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>PANAGANTI AKHILA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Capgemini</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>5</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1202</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>NAGU ALEKHYA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Capgemini</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>6</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1218</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>DUGGIRALA LIKHITHA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Capgemini</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>7</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1220</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>SRIPATHI MANJULAVANI</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Capgemini</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>8</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1224</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>GANGA NAVYASRI</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Capgemini</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>9</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1228</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>PANAKANTI PALLAVI</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Capgemini</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>10</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1217</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>Lakshmi Sai Samanvitha Oruganti I</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>DXC</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>340200</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>11</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1241</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>Pocha Sharvani</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>DXC</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>340200</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>12</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1245</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>SOUMYA RAMPALLY</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>DXC</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>340200</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>13</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1226</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>AARE NIKHITHA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>14</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1231</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>GUNTAKA RAMYA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>15</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1234</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>DEVALARAJU SAI SMITHA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>16</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1235</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>GARIPELLI SAISUDHA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>17</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1211</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>PUNUGU CHANDANA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>18</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1212</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>RANGAPURAM DAYASAGAR GAYATHRI</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>19</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1219</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>YALAMANCHILI MAHITHA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>20</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1222</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>JONNADA MRUDULA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>21</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1236</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>SAITEJASWINI TALAVELMA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>22</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1252</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>VARSHA GADDAMEDI</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>23</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1203</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>KEDIKA AMITHA REDDY</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>24</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1206</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>RAYAPPAGARI ANURADHA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>25</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1207</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>KARNATI ANUSHA GOUD</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>26</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1208</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>G.ASHWINI</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>27</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1209</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>BANURI BHAVYA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>28</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1244</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>SHRUTI SHARMA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Qspiders Jspiders</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>29</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1205</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>GANTHA ANNAPURNA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Syntel Private Limited</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>310000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>30</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1216</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>M.KEERTHI PRIYA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Syntel Private Limited</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>310000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>31</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1222</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>JONNADA MRUDULA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>Syntel Private Limited</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>310000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>32</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1214</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>Kalwa janaki</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>33</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1215</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>Devasani.keerthi</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>34</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1232</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>Kasula ravali</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>35</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1233</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>Uppalapati&nbsp; sahithi</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>36</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1223</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>NAGA VENKATA LAXMI BANDARI</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>37</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1229</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>MANNE-PRASANNA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="53">
                                                        <p>38</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>14D21A1234</p>
                                                    </td>
                                                    <td width="312">
                                                        <p>DEVALARAJU SAI SMITHA</p>
                                                    </td>
                                                    <td width="73">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="179">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="107">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6 className='py-2'>List of students placed in 2016-2017
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td width="50"><strong>S.No</strong></td>
                                                    <td width="109"><strong>ROLL NO</strong></td>
                                                    <td width="231"><strong>NAME</strong></td>
                                                    <td width="70"><strong>BRANCH</strong></td>
                                                    <td width="153"><strong>COMPANY NAME</strong></td>
                                                    <td width="101"><strong>CTC</strong></td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>1</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1240</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>BAJJURI SUSHMITHA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>ALIENS GROUP</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>270400</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>2</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1241</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>RANGA TEJASWINI</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>ALIENS GROUP</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>270400</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>3</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1242</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>VAISHNAVI BANDI</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>ALIENS GROUP</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>270400</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>4</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1243</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>BALLA VASANTHA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>ALIENS GROUP</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>270400</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>5</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1245</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>S.YOGITHA GOUD</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>ALIENS GROUP</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>270400</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>6</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1201</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>VEMULA ANNAPURNA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>7</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1207</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>GURRAM JYOTHSNA REDDY</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>8</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1209</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>MADHURI LINGA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>9</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1212</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>CHUNDURU MONISHA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>10</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1221</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>RAVALI YAMAGARI</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>11</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1222</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>ROCHISHMATI EDA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>12</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1224</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>BADAM SAIGEETHA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>13</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1226</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>NEELI SARIKA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>14</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1227</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>SESHA SAI LEELA MADHURI&nbsp; HOTA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>15</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1229</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>SHRUTHI THAKUR</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>16</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1235</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>MEGHANA VEMURI</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>17</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1210</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>Varanasi Aparna</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>18</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1213</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>SPS Prathyusha</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>19</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1232</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>Sowmya Reddy Sirigangari</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>20</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1234</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>Sravya Aitha</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>21</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1236</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>Sri Lakshmi Sadanala</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>22</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1237</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>Srushitha Bachu</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>23</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1238</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>Bajjuri Sushmitha</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>24</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1204</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>CHAITANYA REDDY</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>25</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1206</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>MUDIKE&nbsp; JAGRITHI</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>26</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1208</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>L.MADHAVI REDDY</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>27</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1214</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>PADMA RAVALI</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>INTELENET</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>28</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1230</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>SOWJANYA VASAM</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>TECHM</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>325000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>29</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1215</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>PRATHYUSHA CHEKKABANDI</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>30</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1220</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>RANI ANUMULA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>31</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1228</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>SHIRISHA&nbsp; VEMALA</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="51">
                                                        <p>32</p>
                                                    </td>
                                                    <td width="115">
                                                        <p>13D21A1233</p>
                                                    </td>
                                                    <td width="263">
                                                        <p>SOWMYA REDDY SIRIGANGARI</p>
                                                    </td>
                                                    <td width="65">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="117">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="105">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6 className='py-2'>List of students placed in 2015-2016
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td width="50"><strong>S.No</strong></td>
                                                    <td width="109"><strong>ROLL NO</strong></td>
                                                    <td width="231"><strong>NAME</strong></td>
                                                    <td width="70"><strong>BRANCH</strong></td>
                                                    <td width="153"><strong>COMPANY NAME</strong></td>
                                                    <td width="101"><strong>CTC</strong></td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>1</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1210</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>HIMA BINDHU M</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>2</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1222</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>MOUNIKA GUNDALE</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>3</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1239</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>TADIKONDA SAHITHYA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>4</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1206</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>APARNA REKHA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>5</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1211</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>HIMA BINDU SAKKURI</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>6</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1219</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>P.MANISHA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>7</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1224</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>KALLURI MOUNIKA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>8</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1227</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SOMA PAVITHRA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>9</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1230</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>VENKATESAN&nbsp; POOJA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CAPGEMINI</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>10</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1210</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>HIMA BINDHU M</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CTS</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>380000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>11</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1222</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>MOUNIKA GUNDALE</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>CTS</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>380000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>12</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1236</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>BHASKARANI&nbsp; SAI LAXMI SPANDANA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>13</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1241</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SANSKRITHI THAKUR</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>14</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1247</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SHAIK NAZEEMUNNISA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>15</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1251</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SOWMYA MANCHALA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>16</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1255</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>TANNEERU SUPRIYA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>IBM</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>370000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>17</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1201</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>AISHWARYA GOPISETTY</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>serco</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>18</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1204</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>ANISHA POYYI</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>serco</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>19</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1209</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>HARIKA AMARTHALURI</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>serco</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>20</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1212</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>ISMAT FATIMA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>serco</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>21</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1213</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>BEDA KEERTHI</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>serco</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>22</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1214</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>LAKSHMI NAGA VENKATA DURGA DEVI&nbsp; GURUJU</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>serco</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>240000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>23</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1232</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>RAJESHWARI MARYALA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>Syntel Private Limited</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>315000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>24</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1241</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SANSKRITHI THAKUR</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>TECHM</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>325000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>25</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>&nbsp;</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>RUCHA PARASHAR BHATT</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>TECHM</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>325000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>26</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1215</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>PUVVADA LAXMI SUDHA VYSHNAVI</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>27</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1217</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>M.MANI KUMARI</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>28</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1221</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>MOUNIKA GOUD</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>29</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1225</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>MOUNIKA REDDY</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>30</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1226</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>DORAGARI NISHITHA NISHITHA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ULS</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>31</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1228</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>KURVA POOJA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>32</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1229</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>POOJA THAKUR</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>33</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1231</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>AITHABHATHULA POOJITHA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>34</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1235</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>RAMYA SAI</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>35</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1240</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>MEKA SAI PRAVALLIKA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>36</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1242</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SANTHOSHI JANUMPALLY</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>37</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1243</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SHRAVYA PULPA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>38</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1249</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SWARNAGANTTI SOUNDHARYA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>39</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1250</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>KOTTA SOWJANYA SOWJANYA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>40</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1252</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SREE SOWMYA ANNAMBHOTLA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>41</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1253</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>CHOWDARAPU SRILATHA</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="55">
                                                        <p>42</p>
                                                    </td>
                                                    <td width="111">
                                                        <p>12D21A1256</p>
                                                    </td>
                                                    <td width="364">
                                                        <p>SUSHMA KYATHAM</p>
                                                    </td>
                                                    <td width="57">
                                                        <p>IT</p>
                                                    </td>
                                                    <td width="165">
                                                        <p>ZON</p>
                                                    </td>
                                                    <td width="119">
                                                        <p>260000</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default ItPlacements
