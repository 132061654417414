import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';

const FacultyInnovation = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Innovative Teaching Methodologies
                                </h2>
                                {/* <div class="table-responsive" >
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th><strong>S.No</strong></th>
                                                <th><strong>Name</strong></th>
                                                <th><strong>Designation</strong></th>
                                                <th><strong>Subject</strong></th>
                                                <th><strong>Document</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>Dr.A.Gauthami Latha</p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>Software Engineering</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/1 SE Dr.A.Gauthami latha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <p>Dr.M. Ramasubramanian </p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/2 PPL ramasubramanian.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                                <td>
                                                    <p>Dr.V. Anantha Krishna</p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Cryptography &amp; Network Security</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/3 C&amp;NS DrV.Ananthkrishna.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>Dr.T.K.S. Rathish Babu</p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>Cloud Computing&nbsp;</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/4 CC Dr.T.K.S rathish babu.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                                <td>
                                                    <p>Dr.K.C. Ravi Kumar</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Data Analytics
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/5 DA Dr.KCRavikumar.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>6</p>
                                                </td>
                                                <td>
                                                    <p>Dr.B.Narendra Kumar</p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>Python Programming</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/6 python Dr.Narendra Kumar.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>7</p>
                                                </td>
                                                <td>
                                                    <p>Dr.A.Ravi kumar</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>Software Process <span zeum4c2="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">&amp;Project Management</span></p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/7 sppm A.Ravikumar.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>Dr.D. Madhavi</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Formal
                                                        Languages &amp; Automata
                                                        Theory
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/8 FLAT Dr.D.Madhavi.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.E. Krishnaveni Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Web
                                                        Technology

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/9 wt krishnaveni.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>10</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.M. Pragathi</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>Computer Networks</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/10 CN pragathi_.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>11</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.N. Sujata Kumari</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>Cloud Computing</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/11 CC N.SUjatha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>12</p>
                                                </td>
                                                <td>
                                                    <p>Mr.K. Rajasekhar Rao</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Data
                                                        Mining

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/12 DM Rajasekar_.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>13</p>
                                                </td>
                                                <td>
                                                    <p>Mr.P.Prashanth Kumar</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Compiler Design</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/13 CD Prashanth.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>14</p>
                                                </td>
                                                <td>
                                                    <p>Mr.K.Sreenivasa Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Formal
                                                        Languages &amp; Automata
                                                        Theory
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/14 FLAT Srinivasa Reddy.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>15</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.S. Radhika</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Data Mining</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/15 DM S.Radhika.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>16</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.K. Anusha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Artificial Intelligence</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/16 AI K.Anusha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>17</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.T. Kavitha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Software Engineering</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/17 SE T.Kavitha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>18</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.S. Anitha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Computer Networks</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/18 CN S.Anitha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>19</p>
                                                </td>
                                                <td>
                                                    <p>Mr.A. Saibabu</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Web
                                                        Technology

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/19 WT saibabu.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>20</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.S. Mounasri</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        &nbsp;Distributed&nbsp;
                                                        Databases
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/20 DDB Mounasri.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>21</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.B. Laxmi</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Computer Networks</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/21 CN B.Laxmi.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>22</p>
                                                </td>
                                                <td>
                                                    <p>Mr.V.Srikanth Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        &nbsp;Database
                                                        Management Systems
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/22 dbms-srikanth.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>23</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.R. Bhargavi</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/23 PPL Bhargavi.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>24</p>
                                                </td>
                                                <td>
                                                    <p>Mr.V.Bharath</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Organization &amp; Architecture</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/24 COA Bharath.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>25</p>
                                                </td>
                                                <td>
                                                    <p>Mr.L.Manikandan</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Object Oriented Programming Using&nbsp;C++</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/25-Cplusplus-Manikandan.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>26</p>
                                                </td>
                                                <td>
                                                    <p>Mr.S. Praveen</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Cloud Computing&nbsp;</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/26 CC Praveen.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>27</p>
                                                </td>
                                                <td>
                                                    <p>Mr.D.Mahesh</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/27 ppl mahesh.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>28</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.N.Sirisha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Database Management Systems</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/28 DBMS Sireesha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>29</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.N.Urmila</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Object Oriented Programming Using&nbsp;C++</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/29 Cplusplus Urmila.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>30</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.K.Laxmi Shravani</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/30 ppl Lakshmi Sravani.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>31</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.B.Divya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Organization &amp; Architecture</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/31 COA B.DIvya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>32</p>
                                                </td>
                                                <td>
                                                    <p>Mr.B.Suman</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Cryptography &amp; Network Security</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/32 C&amp;NS SUMAN.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>33</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.M.Chandrakala</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        &nbsp;Distributed&nbsp;
                                                        Databases
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/33 DDB Chandrakal.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>34</p>
                                                </td>
                                                <td>
                                                    <p>Ms.Y.Divya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Real Time Systems</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/34 RTS Y.Divya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>35</p>
                                                </td>
                                                <td>
                                                    <p>Ms.S.Sowmya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Database Management Systems</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/35 DBMS S.sowmya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>36</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.G.Nirali Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Real Time Systems</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/36 RTS Nirali.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>37</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.M.Akhila Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/37 PPL M.Akhila Reddy.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>38</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.R.Vedasri</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Software Engineering</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/38 SE vedasri.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>39</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.Ch.Sandhya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Software Process &amp;Project Management</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/39 SPPM Sandhya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>40</p>
                                                </td>
                                                <td>
                                                    <p>Mrs. R.Shashi Rekha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Organization &amp; Architecture</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/40 COA sashi Rekha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>41</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.V.Divya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Web
                                                        Technology

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/41 WT V.Divya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>42</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.C.Tejaswini</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Cloud Computing&nbsp;</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/42 CC Tejaswini.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>43</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.S.Divya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Software Process &amp; Project Management</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/43 SPPM S.Divya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>44</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.Ch.Veena</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Networks</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/44 CN Veena.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>45</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.N.Bharathi</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Formal
                                                        Languages &amp; Automata
                                                        Theory
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/45 FLAT Bharathi.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>46</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.K.Rajini</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Organization &amp; Architecture</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/46 COA Rajini.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>47</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.Alisha Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Cyber Security</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/47 Cyber Security Alisha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>48</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.Aruna</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Object Oriented Programming Using&nbsp;C++</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/48 Cplusplus Aruna.pptx.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>49</p>
                                                </td>
                                                <td>
                                                    <p>Mr.Jummelal</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Data Structures</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/49 DS Jummelal.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> */}
                                <div class="table-responsive" >
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th><strong>S.No</strong></th>
                                                <th><strong>Name</strong></th>
                                                <th><strong>Designation</strong></th>
                                                <th><strong>Subject</strong></th>
                                                <th><strong>Document</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>Dr.A.Gauthami Latha</p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>Software Engineering</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/1 SE Dr.A.Gauthami latha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <p>Dr.M. Ramasubramanian </p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/2 PPL ramasubramanian.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                                <td>
                                                    <p>Dr.V. Anantha Krishna</p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Cryptography &amp; Network Security</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/3 C&amp;NS DrV.Ananthkrishna.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>Dr.T.K.S. Rathish Babu</p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>Cloud Computing&nbsp;</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/4 CC Dr.T.K.S rathish babu.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                                <td>
                                                    <p>Dr.K.C. Ravi Kumar</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Data Analytics
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/5 DA Dr.KCRavikumar.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>6</p>
                                                </td>
                                                <td>
                                                    <p>Dr.B.Narendra Kumar</p>
                                                </td>
                                                <td>
                                                    <p>Professor</p>
                                                </td>
                                                <td>
                                                    <p>Python Programming</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/6 python Dr.Narendra Kumar.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>7</p>
                                                </td>
                                                <td>
                                                    <p>Dr.A.Ravi kumar</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>Software Process <span zeum4c2="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">&amp;Project Management</span></p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/7 sppm A.Ravikumar.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>Dr.D. Madhavi</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Formal
                                                        Languages &amp; Automata
                                                        Theory
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/8 FLAT Dr.D.Madhavi.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.E. Krishnaveni Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Web
                                                        Technology

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/9 wt krishnaveni.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>10</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.M. Pragathi</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>Computer Networks</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/10 CN pragathi_.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>11</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.N. Sujata Kumari</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>Cloud Computing</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/11 CC N.SUjatha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>12</p>
                                                </td>
                                                <td>
                                                    <p>Mr.K. Rajasekhar Rao</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Data
                                                        Mining

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/12 DM Rajasekar_.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>13</p>
                                                </td>
                                                <td>
                                                    <p>Mr.P.Prashanth Kumar</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Compiler Design</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/13 CD Prashanth.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>14</p>
                                                </td>
                                                <td>
                                                    <p>Mr.K.Sreenivasa Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Associate Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Formal
                                                        Languages &amp; Automata
                                                        Theory
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/14 FLAT Srinivasa Reddy.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>15</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.S. Radhika</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Data Mining</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/15 DM S.Radhika.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>16</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.K. Anusha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Artificial Intelligence</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/16 AI K.Anusha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>17</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.T. Kavitha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Software Engineering</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/17 SE T.Kavitha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>18</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.S. Anitha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Computer Networks</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/18 CN S.Anitha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>19</p>
                                                </td>
                                                <td>
                                                    <p>Mr.A. Saibabu</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Web
                                                        Technology

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/19 WT saibabu.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>20</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.S. Mounasri</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        &nbsp;Distributed&nbsp;
                                                        Databases
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/20 DDB Mounasri.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>21</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.B. Laxmi</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Computer Networks</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/21 CN B.Laxmi.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>22</p>
                                                </td>
                                                <td>
                                                    <p>Mr.V.Srikanth Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        &nbsp;Database
                                                        Management Systems
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/22 dbms-srikanth.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>23</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.R. Bhargavi</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/23 PPL Bhargavi.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>24</p>
                                                </td>
                                                <td>
                                                    <p>Mr.V.Bharath</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Organization &amp; Architecture</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/24 COA Bharath.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>25</p>
                                                </td>
                                                <td>
                                                    <p>Mr.L.Manikandan</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Object Oriented Programming Using&nbsp;C++</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/25-Cplusplus-Manikandan.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>26</p>
                                                </td>
                                                <td>
                                                    <p>Mr.S. Praveen</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Cloud Computing&nbsp;</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/26 CC Praveen.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>27</p>
                                                </td>
                                                <td>
                                                    <p>Mr.D.Mahesh</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/27 ppl mahesh.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>28</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.N.Sirisha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Database Management Systems</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/28 DBMS Sireesha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>29</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.N.Urmila</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Object Oriented Programming Using&nbsp;C++</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/29 Cplusplus Urmila.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>30</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.K.Laxmi Shravani</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/30 ppl Lakshmi Sravani.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>31</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.B.Divya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Organization &amp; Architecture</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/31 COA B.DIvya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>32</p>
                                                </td>
                                                <td>
                                                    <p>Mr.B.Suman</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Cryptography &amp; Network Security</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/32 C&amp;NS SUMAN.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>33</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.M.Chandrakala</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        &nbsp;Distributed&nbsp;
                                                        Databases
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/33 DDB Chandrakal.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>34</p>
                                                </td>
                                                <td>
                                                    <p>Ms.Y.Divya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Real Time Systems</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/34 RTS Y.Divya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>35</p>
                                                </td>
                                                <td>
                                                    <p>Ms.S.Sowmya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Database Management Systems</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/35 DBMS S.sowmya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>36</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.G.Nirali Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Real Time Systems</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/36 RTS Nirali.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>37</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.M.Akhila Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Principles
                                                        Of Programming Languages

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/37 PPL M.Akhila Reddy.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>38</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.R.Vedasri</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Software Engineering</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/38 SE vedasri.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>39</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.Ch.Sandhya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Software Process &amp;Project Management</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/39 SPPM Sandhya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>40</p>
                                                </td>
                                                <td>
                                                    <p>Mrs. R.Shashi Rekha</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Organization &amp; Architecture</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/40 COA sashi Rekha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>41</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.V.Divya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Web
                                                        Technology

                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/41 WT V.Divya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>42</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.C.Tejaswini</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Cloud Computing&nbsp;</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/42 CC Tejaswini.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>43</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.S.Divya</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Software Process &amp; Project Management</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/43 SPPM S.Divya.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>44</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.Ch.Veena</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Networks</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/44 CN Veena.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>45</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.N.Bharathi</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        Formal
                                                        Languages &amp; Automata
                                                        Theory
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/45 FLAT Bharathi.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>46</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.K.Rajini</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Computer Organization &amp; Architecture</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/46 COA Rajini.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>47</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.Alisha Reddy</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Cyber Security</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/47 Cyber Security Alisha.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>48</p>
                                                </td>
                                                <td>
                                                    <p>Mrs.Aruna</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>Object Oriented Programming Using&nbsp;C++</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/48 Cplusplus Aruna.pptx.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>49</p>
                                                </td>
                                                <td>
                                                    <p>Mr.Jummelal</p>
                                                </td>
                                                <td>
                                                    <p>Assistant Professor</p>
                                                </td>
                                                <td>
                                                    <p>&nbsp;Data Structures</p>
                                                </td>
                                                <td>
                                                    <p><a href="/assets/documents/cse/49 DS Jummelal.pdf" target="_blank" class="text-primary"><i class="fa fa-file-pdf fa-lg text-red"></i>&nbsp; View</a></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}



export default FacultyInnovation
