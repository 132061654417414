import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import MbaLeftTab from './MbaLeftTab';
import hod from './mbaImages/hod.png'


const MbaMentorList = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Mentor List
                                </h2>
                                <div class="table-responsive mb-4">
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th width="65"><strong>S.No</strong></th>
                                                <th width="180"><strong>Name of the Full-time teacher</strong></th>
                                                <th width="220"><strong>PAN</strong></th>
                                                <th width="120"><strong>Designation</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>B Sudhakar Reddy</td>
                                                <td>BBLPB1198M</td>
                                                <td>Assistant Professor</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>K Sri Ranga Lakshmi</td>
                                                <td>CEEPK8568P</td>
                                                <td>Assistant Professor</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>M Sandhya Rani</td>
                                                <td>AIFPM1892D</td>
                                                <td>Assistant Professor</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>U Eswar Krishna Nadh</td>
                                                <td>ACRPU1284H</td>
                                                <td>Assistant Professor</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>N Hima Bindu</td>
                                                <td>AEBPN8935Q</td>
                                                <td>Assistant Professor</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>G Aruna</td>
                                                <td>AOKPG7809K</td>
                                                <td>Assistant Professor</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default MbaMentorList
