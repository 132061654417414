import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import AboutLeftTab from '../About/AboutLeftTab';
import one from '../SeconNav/edc/evindence-1.jpg'
import two from '../SeconNav/edc/evindence-2.jpg'
import three from '../SeconNav/edc/evindence-3.jpg'

const EDC = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Entrepreneur Development Cell
                                </h2>
                                <p>Entrepreneurship is increasingly recognized as an important driver of economic growth of a country. Even Govt. of India has recognized the importance of entrepreneurship and has introduced programs like "Make in India" & "Start-up India".
                                </p>
                                <p>The aim of Entrepreneur Development Cell (EDC) at sridevi women’s engineering College, is to develop and strengthen entrepreneurial qualities in the budding professionals who are interested in starting their own ventures. The College provides infrastructure and technical support to the students having innovative ideas to transform into new products and services for the betterment of the society. The EDC also assists all the aspirants with mentoring, planning and execution of their start up idea into a real business.
                                </p>
                                <p>Hence an Entrepreneur Development Cell was constituted in the college with a dedicated team of actively working faculty who has an industry exposure along with some student representatives. Aspiring engineer entrepreneurs are groomed with the necessary inputs on how to be a successful entrepreneur through workshops and seminars by eminent people from the industry. At sridevi women’s engineering college, we encourage the students to consider self-employment as a career option, providing necessary training in Entrepreneurship skills through standardized courses. The cell also organizes different activities and events from time to time to train and motivate the students on entrepreneurship.
                                </p>
                            </div>
                            <div>
                                <h6 className='py-3'>EDC events 2019-2020
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Date</strong></td>
                                                <td align="center" valign="middle"><strong>Events</strong></td>
                                                <td align="center" valign="middle"><strong>Students Participated</strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="text-center py-3"><strong>Academic Year 2020-2021</strong></td>
                                            </tr>
                                            <tr>
                                                <td>24.06.2020</td>
                                                <td>Opportunities in Entrepreneurship Development with MSMEs</td>
                                                <td>56</td>
                                            </tr>
                                            <tr>
                                                <td>12.12.2020</td>
                                                <td>Entrepreneurship awareness program by Vinod Tantri SEBI</td>
                                                <td>42</td>
                                            </tr>
                                            <tr>
                                                <td>01.03.2021</td>
                                                <td>Expectations from Budding Entrepreneur’s</td>
                                                <td>52</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="text-center py-3"><strong>Academic Year 2019-2020</strong></td>
                                            </tr>
                                            <tr>
                                                <td>06-08-2019</td>
                                                <td>EDP at Rishi Engineering College</td>
                                                <td>20</td>
                                            </tr>
                                            <tr>
                                                <td>10-09-2019</td>
                                                <td>Awareness program for organizing stalls at vidyouth-2019</td>
                                                <td>120</td>
                                            </tr>
                                            <tr>
                                                <td>23-09-2019</td>
                                                <td>Initiative to execute a business stall on vidyouth 2019</td>
                                                <td>20</td>
                                            </tr>
                                            <tr>
                                                <td>23-11-2019</td>
                                                <td>Rural Entrepreneurship Opportunities for women</td>
                                                <td>125</td>
                                            </tr>
                                            <tr>
                                                <td>25-11-2019</td>
                                                <td>Workshop on Entrepreneurship</td>
                                                <td>30</td>
                                            </tr>
                                            <tr>
                                                <td>24-02-2020</td>
                                                <td>Seminar on Entrepreneurship awareness for II B.Tech Students</td>
                                                <td>74</td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="text-center py-3"><strong>Academic Year 2018-2019 </strong></td>
                                            </tr>
                                            <tr>
                                                <td>12-08-2018</td>
                                                <td>Seminar on Entrepreneurship awareness for III B.Tech Students</td>
                                                <td>116</td>
                                            </tr>
                                            <tr>
                                                <td>11-09-2018</td>
                                                <td>Guest lecture on need of entrepreneurship for engineering students</td>
                                                <td>78</td>
                                            </tr>
                                            <tr>
                                                <td>1&amp;2 Oct 2018</td>
                                                <td>Initiative to plan and execute business stall on vidyouth 2018</td>
                                                <td>43</td>
                                            </tr>
                                            <tr>
                                                <td>21&amp;22 Aug2018</td>
                                                <td>Students participated E-summit at Vardhaman college of engineering</td>
                                                <td>15</td>
                                            </tr>
                                            <tr className="border-0">
                                                <td>29&amp;30 Sep 2018</td>
                                                <td>Mobile site development at IIT Hyderabad</td>
                                                <td>37</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-responsive mt-4">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>A-Year</strong></td>
                                                <td align="center" valign="middle"><strong>Seminar</strong></td>
                                                <td align="center" valign="middle"><strong>Guest lecture</strong></td>
                                                <td align="center" valign="middle"><strong>Skill development</strong></td>
                                                <td align="center" valign="middle"><strong>Attended other events</strong></td>
                                            </tr>
                                            <tr>
                                                <td>2015-16</td>
                                                <td>03</td>
                                                <td>01</td>
                                                <td>02</td>
                                                <td>01</td>
                                            </tr>
                                            <tr>
                                                <td>2016-17</td>
                                                <td>02</td>
                                                <td>01</td>
                                                <td>01</td>
                                                <td>01</td>
                                            </tr>
                                            <tr>
                                                <td>2017-18</td>
                                                <td>02</td>
                                                <td>02</td>
                                                <td>01</td>
                                                <td>01</td>
                                            </tr>
                                            <tr>
                                                <td>2018-19</td>
                                                <td>01</td>
                                                <td>01</td>
                                                <td>01</td>
                                                <td>02</td>
                                            </tr>
                                            <tr className='border-0'>
                                                <td>2019-20</td>
                                                <td>03</td>
                                                <td>01</td>
                                                <td>02</td>
                                                <td>01</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className='py-3 mt-3'>
                                    Academic Year 2018-2019
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Date</strong></td>
                                                <td align="center" valign="middle"><strong>Events</strong></td>
                                            </tr>
                                            <tr>
                                                <td>12-08-2018</td>
                                                <td>Seminar on Entrepreneurship awareness for III B.Tech Students</td>
                                            </tr>
                                            <tr>
                                                <td>11-09-2018</td>
                                                <td>Guest lecture on need of entrepreneurship for engineering students</td>
                                            </tr>
                                            <tr>
                                                <td>1 &amp; 2 October 2018</td>
                                                <td>Initiative to plan and execute a business stall on Vidyouth 2018</td>
                                            </tr>
                                            <tr>
                                                <td>21 &amp; 22 August 2018</td>
                                                <td>Students participated E-Summit at Vardhman college of engineering</td>
                                            </tr>
                                            <tr className='border-0 pb-0'>
                                                <td>29 &amp; 30 Sept 2018</td>
                                                <td>Mobile site development at IIT Hyderabad</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className='py-3 mt-3'>
                                    Academic Year 2017-2018
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Date</strong></td>
                                                <td align="center" valign="middle"><strong>Events</strong></td>
                                            </tr>
                                            <tr>
                                                <td>22-07-2017</td>
                                                <td>Seminar on Entrepreneurship awareness for III B.Tech Students</td>
                                            </tr>
                                            <tr>
                                                <td>29-07-2017</td>
                                                <td>Seminar on Entrepreneurship awareness for IV B.Tech Students</td>
                                            </tr>
                                            <tr>
                                                <td>06-08-2017</td>
                                                <td>Guest Lecture on opportunities for women entrepreneurs</td>
                                            </tr>
                                            <tr>
                                                <td>18-12-2017</td>
                                                <td>Guest lecture on need of entrepreneurship for engineering students</td>
                                            </tr>
                                            <tr>
                                                <td>08-03-2018</td>
                                                <td>Students planned and organized food stalls and games stalls</td>
                                            </tr>
                                            <tr className='border-0'>
                                                <td>02-01-2018</td>
                                                <td>Students participated in business quiz at BVRIT</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className='py-3 mt-3'>
                                    Academic Year 2016-2017
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Date</strong></td>
                                                <td align="center" valign="middle"><strong>Events</strong></td>
                                            </tr>
                                            <tr>
                                                <td>21-06-2016</td>
                                                <td>Seminar on Entrepreneurship awareness for III B.Tech Students</td>
                                            </tr>
                                            <tr>
                                                <td>24-06-2016</td>
                                                <td>Seminar on Entrepreneurship awareness for IV B.Tech Students</td>
                                            </tr>
                                            <tr>
                                                <td>13-09-2016</td>
                                                <td>Guest lecture on need of entrepreneurship for engineering students</td>
                                            </tr>
                                            <tr>
                                                <td>08-03-2017</td>
                                                <td>Students planned and organized food stalls and games stalls</td>
                                            </tr>
                                            <tr className='border-0'>
                                                <td>01-04-2017</td>
                                                <td>Students attended EDC programme at Vasavi Engg. College</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className='py-3 mt-3'>
                                    Academic Year 2015-2016
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Date</strong></td>
                                                <td align="center" valign="middle"><strong>Events</strong></td>
                                            </tr>
                                            <tr>
                                                <td>06-08-2015</td>
                                                <td>Seminar on Entrepreneurship awareness for II B.Tech Students</td>
                                            </tr>
                                            <tr>
                                                <td>10-09-2015</td>
                                                <td>Seminar on Entrepreneurship awareness for III B.Tech Students</td>
                                            </tr>
                                            <tr>
                                                <td>18-09-2015</td>
                                                <td>Seminar on Entrepreneurship awareness for IV B.Tech Students</td>
                                            </tr>
                                            <tr>
                                                <td>02-02-2016</td>
                                                <td>Guest lecture on what is my story</td>
                                            </tr>
                                            <tr>
                                                <td>08-03-2016</td>
                                                <td>Students planned and organized food stalls</td>
                                            </tr>
                                            <tr>
                                                <td>14-03-2016</td>
                                                <td>Idea box brain storming session</td>
                                            </tr>
                                            <tr className='border-0'>
                                                <td>22-02-2016</td>
                                                <td>Young Entrepreneur seminar at Mahendra Engineering college</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className='py-3 mt-3'>
                                    Events conducted for Realtime exposure

                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>S.No</strong></td>
                                                <td align="center" valign="middle" ><strong>Event Conducted</strong></td>
                                                <td align="center" valign="middle"><strong>Evidence</strong></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>Explore Space with Dhruva Space! As a part of our outreach activity along with our NGO partner National Institute of Amateur Radio (NIAR).</td>
                                                <td><img src={one} alt="evidence image" class="img-responsive" /></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Industrial automation using PLC in collaboration with SKS institute</td>
                                                <td><img src={two} alt="evidence image" class="img-responsive" /></td>
                                            </tr>
                                            <tr className='border-0'>
                                                <td>3</td>
                                                <td>Power plant visit</td>
                                                <td><img src={three} alt="evidence image" class="img-responsive" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default EDC
