import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const KillCancerEssaywritingcompetition = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Kill Cancer Essay writing competition
                                    </h2>
                                    <div className='py-2'>
                                        <h6>Kill Cancer Essay writing competition participants receiving certificate from VC Sir Dr. K RadhaKishan Rao</h6>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/1.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/2.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/3.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/4.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/4.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/5.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/5.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/6.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/6.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/7.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/7.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/8.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/8.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>


                                             <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/9.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/9.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/10.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/10.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/11.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/11.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                             <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/12.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/12.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                             <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/13.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/13.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/14.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/14.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/imggallery/Kill Cancer/15.jpg">
                                                    <img src="/assets/img/gallery/Kill Cancer/15.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default KillCancerEssaywritingcompetition
