import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const OnlineDataScienceMachine = () => {
  return (
    <div>
      <Navbar />
      <div className="graduate-admission pt-lg-5 pt-4 pb-70">
        <div className="container">
          <div className="row">
            <AboutLeftTab />

            <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
              <div className="col-lg-12 ps-lg-4">
                <div className="how-to-apply">
                  <h2 className='pb-2'>Online Data Science, Machine Learning
                  </h2>
                  <div class="css-script-ads" >
                  </div>
                  <div class="spotlight-group">
                    <div class="row">
                      <div class="col-md-12 mb-3 mb-lg-4">
                        <iframe width="100%" height="300" className="lazy-youtube" src="https://www.youtube.com/embed/sFm0p_mgJEU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default OnlineDataScienceMachine
