import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'

const BOS = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-xl-2 order-lg-2 order-md-2 order-sm-1 order-1">
                            <div className="how-to-apply">
                                <h2>Board of Studies (BoS)</h2>
                                <div class="detgails-right-content "><div class="related-download bg-gray"><ul><li><a href="/assets/documents/Board of Studies/S AND H BOS.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Board of Studies Document </a></li></ul></div></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BOS