import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';
import GAUTAMI from './cseD/GAUTAMI.jpg'

const CSENewsLetter = () => {
  return (
    <div>
      <Navbar />
      <div className="graduate-admission pt-lg-5 pt-4 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
              <DepartmentTab />
            </div>
            <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
              <div className="how-to-apply">
                <h2>Newsletter
                </h2>
                <div className=''>
                  <div class="detgails-right-content mt-1">
                    <div class="related-download bg-gray">
                      <ul>
                        <li>
                          <a href="/assets/documents/cse/SWEC CSE Newsletter 2021-2022.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> CSE NEWS LETTER 2021-2022. </a>
                        </li>
                        <li>
                          <a href="/assets/documents/cse/New1-(SWEC)NewsLetter-20-21-Sem-I-Final.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>CSE NEWS LETTER 2020-21. </a>
                        </li>
                        <li>
                          <a href="/assets/documents/cse/New1-(SWEC)NewsLetter19-20-Sem-II-Final.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>CSE NEWS LETTER 2019-20 Sem 2. </a>
                        </li>
                        <li>
                          <a href="/assets/documents/cse/New1-(SWEC)NewsLetter19-20-Sem-I-Final.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> CSE NEWS LETTER 2019-20 Sem 1. </a>
                        </li>
                        <li>
                          <a href="/assets/documents/cse/CSE NEWS LETTER 2018-2019.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> CSE NEWS LETTER 2018-2019.</a>
                        </li>
                        <li>
                          <a href="/assets/documents/cse/CSE NEWS LETTER 2017-2018.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>CSE NEWS LETTER 2017-2018. </a>
                        </li>
                        <li>
                          <a href="/assets/documents/cse/CSE NEWS LETTER 2016-2017.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  CSE NEWS LETTER 2016-2017. </a>
                        </li>
                         <li>
                          <a href="/assets/documents/cse/CSE NEWS LETTER 2015-2016.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>CSE NEWS LETTER 2015-2016. </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


export default CSENewsLetter
