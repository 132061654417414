import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DpeLeftTab from './DpeLeftTab';



const DpeInfra = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DpeLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Sports Facilities available in the college
                                </h2>
                                <div className='pt-3 '>
                                    <h6 className='PB-2'>Outdoor Games</h6>

                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td valign="bottom"><strong>S.No</strong></td>
                                                    <td valign="bottom"><strong>Name of the Event</strong></td>
                                                    <td valign="bottom"><strong>Facility available</strong></td>
                                                    <td valign="bottom"><strong>Management</strong></td>
                                                    <td valign="bottom"><strong>Usage of </strong><strong>Students</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">1</td>
                                                    <td valign="bottom">Kabadi</td>
                                                    <td align="center" valign="bottom">15x14 mtrs</td>
                                                    <td rowspan="8" valign="middle" align="center">Sports Councilof SWEC</td>
                                                    <td align="center" valign="bottom">40</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">2</td>
                                                    <td valign="bottom">KHO  KHO</td>
                                                    <td align="center" valign="bottom">35X20 mtrs</td>
                                                    <td align="center" valign="bottom">80</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">3</td>
                                                    <td valign="bottom">Volley Ball</td>
                                                    <td align="center" valign="bottom">28x20 mtrs</td>
                                                    <td align="center" valign="bottom">100</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">5</td>
                                                    <td valign="bottom">Throw Ball</td>
                                                    <td align="center" valign="bottom">20 x 15 mtrs</td>
                                                    <td align="center" valign="bottom">120</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">6</td>
                                                    <td valign="bottom">Tennikoit</td>
                                                    <td align="center" valign="bottom">12 x 9.5 mtrs</td>
                                                    <td align="center" valign="bottom">60</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">7</td>
                                                    <td valign="bottom">Ball Badminton</td>
                                                    <td align="center" valign="bottom">24 x 12 mtrs</td>
                                                    <td align="center" valign="bottom">30</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">8</td>
                                                    <td valign="bottom">Athletic Track</td>
                                                    <td align="center" valign="bottom">200 mtrs</td>
                                                    <td align="center" valign="bottom">20</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">9</td>
                                                    <td valign="bottom">Cricket</td>
                                                    <td align="center" valign="bottom">Hard Pitch</td>
                                                    <td align="center" valign="bottom">100</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className='pb-4 pb-lg-5 pt-2'>
                                    <h6 className='pb-2'>infrastructure</h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop25">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center" valign="bottom"><strong>S.No</strong></td>
                                                    <td align="center" valign="bottom"><strong>Name of the Event</strong></td>
                                                    <td align="center" valign="bottom"><strong>Facility available</strong></td>
                                                    <td align="center" valign="bottom"><strong>Management</strong></td>
                                                    <td align="center" valign="bottom"><strong>Usage of </strong><strong>Students</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">1</td>
                                                    <td valign="bottom">TT</td>
                                                    <td align="center" valign="bottom">2 Tables</td>
                                                    <td rowspan="4" align="center" valign="middle">Sports Councilof SWEC</td>
                                                    <td align="center" valign="bottom">30</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">2</td>
                                                    <td valign="bottom">Shuttle Badminton</td>
                                                    <td align="center" valign="bottom">13.5 x 6 mtrs</td>
                                                    <td align="center" valign="bottom">50</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">3</td>
                                                    <td valign="bottom">Chess</td>
                                                    <td align="center" valign="bottom">8 Game Boards</td>
                                                    <td align="center" valign="bottom">40</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="bottom">4</td>
                                                    <td valign="bottom">Caroms</td>
                                                    <td align="center" valign="bottom">10 Game Boards</td>
                                                    <td align="center" valign="bottom">80</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DpeInfra
