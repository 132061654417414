import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const GraduationDay2019 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>Graduation Day 2019
                                    </h2>
                                    <div className='pb-2'>
                                        <p>Sridevi Women’s Engineering College (SWEC) celebrated Graduation Day of the 2015-2019 batch on Aug 10th 2019 to applaud the young enthusiastic engineering graduates for their commendable performance.</p>
                                        <p>The occasion was graced by the Vice Chairman Dr. K Radhakishan Rao, Management Member Dr. K Manjeera, Principal Dr. B.L.Malleswari and the HODs of all the departments.</p>
                                        <p>The celebration commenced with the lamp lighting ceremony and Saraswati Vandana to evoke the blessings of Goddess Saraswati which was followed by the oath of the engineering graduates and their felicitation. The gathering was then addressed by the Principal and Dr. Manjeera who congratulated all the students and wished them success in all their endeavors.
                                        </p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/1.jpg">
                                                    <img src="/assets/img/latest-events/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/2.jpg">
                                                    <img src="/assets/img/latest-events/2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/3.jpg">
                                                    <img src="/assets/img/latest-events/3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/4.jpg">
                                                    <img src="/assets/img/latest-events/4.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default GraduationDay2019
