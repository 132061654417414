import React,{useEffect} from 'react'
import { Link } from "react-router-dom";

const MbaLeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul className='text-start'>
                    <Link to="/dept/mba" onclick="scrollWin()"><li className='text-black'><i class="fa-sharp fa-solid fa-building"></i> <span>About the Department</span> </li></Link>
                    <Link to="/dept/mba/vision-mission-peos" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> Vision, Mission & PEO’s</span> </li></Link>
                    <Link to="/dept/mba/po-and-pso" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> PO and PSO’s</span> </li></Link>
                    <Link to="/dept/mba/hod-profile" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> HOD Profile</span> </li></Link>
                    <Link to="/dept/mba/mba-faculty-list" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span>Faculty  </span> </li></Link>
                    <Link to="/dept/mba/mentor-list" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Mentor List  </span> </li></Link>
                    <Link to="/dept/mba/infrastructure" onclick="scrollWin()"><li className='text-black'><i class="fa fa-industry fa-lg"></i> <span>Infrastructure  </span> </li></Link>
                    <Link to="/dept/mba/syllabus" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Syllabus  </span> </li></Link>
                    <Link to="/dept/mba/remedial-class" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Remedial Class</span> </li></Link>
                    <Link to="/dept/mba/bridge-course" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Bridge Course</span> </li></Link>
                    <Link to="/dept/mba/workshop" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Workshop and Seminar </span> </li></Link>
                    <Link to="/dept/mba/activities" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span> Activities </span> </li></Link>
                    <Link to="/dept/mba/innovative-teaching" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span> Innovative teaching methodologies </span> </li></Link>
                    <Link to="/dept/mba/placements" onclick="scrollWin()"><li className='text-black'><i class="fa fa-graduation-cap fa-lg"></i> <span> placements</span> </li></Link>

                </ul>
            </div>
        </div>
    )
}

export default MbaLeftTab
