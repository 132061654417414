import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import './news.css'
import { Link } from 'react-router-dom';

const Events = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="ps-lg-4">
                            <div className="how-to-apply ">
                                <h2 className='text-center'>Events
                                </h2>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; January, 2024</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/republic-day-celebrations-2024">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/republic-day-celebrations-2024/3.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/republic-day-celebrations-2024">
                                                    <h6 className='pt-3'>Republic Day Celebrations 2024</h6>
                                                </Link>

                                            </div>
                                         
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; March, 2023</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/womens-day-2023">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Internationalwomens2023.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/womens-day-2023">
                                                    <h6 className='pt-3'>International Women's Day Celebrations 2023</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/ideathon-2023">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/1.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/ideathon-2023">
                                                    <h6 className='pt-3'>IDEATHON 2k23
                                                    </h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/isro-industrial-2023">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/isro2023.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/isro-industrial-2023">
                                                    <h6 className='pt-3'>ISRO Industrial Visit (ECE II year)
                                                    </h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; December, 2022</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>

                                                <Link to="/news-and-events/events/project-expo-2022">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/project-exp-paper-clip.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/project-expo-2022">
                                                    <h6 className='pt-3'>Project Expo</h6>
                                                </Link>


                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; August, 2022</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/independence-day-celebration-2022">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/independentday2022.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/independence-day-celebration-2022">
                                                    <h6 className='pt-3'>Independence Day Celebration</h6>
                                                </Link>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; August, 2022</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/yoga-day-2022">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/yogaday2022.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/yoga-day-2022">
                                                    <h6 className='pt-3'>International Yoga Day</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; May, 2022</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/visit-isro-2022">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/isro2022.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/visit-isro-2022">
                                                    <h6 className='pt-3'>Industrial Visit to ISRO</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; April, 2022</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/alumni-21-22">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/alumni2022.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/alumni-21-22">
                                                    <h6 className='pt-3'>Alumni Photos 2021-22</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/workshop-on-android-and-mobile-development">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/isro2022.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/workshop-on-android-and-mobile-development">
                                                    <h6 className='pt-3'>Workshop on Android and mobile applications</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/hackathon-certificate-distribution">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/hekathon2022.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/hackathon-certificate-distribution">
                                                    <h6 className='pt-3'>Hackathon certificate Distribution</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; March, 2022</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/isro-industrial-visit">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/isro2022-2nd-time.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/isro-industrial-visit">
                                                    <h6 className='pt-3'>ISRO industrial visit</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/hackathon-valedictory-photography">
                                                    <img loading="lazy" alt="events" className="img-fluid" src='/assets/img/eventsImgs/Hackathon Valedictory Photography.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/hackathon-valedictory-photography">
                                                    <h6 className='pt-3'>Hackathon Valedictory Photography</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 '>
                                                <Link to="/news-and-events/events/anti-drug-abuse-committee">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/antidrugcommitee.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/anti-drug-abuse-committee">
                                                    <h6 className='pt-3'>Anti drug abuse committee</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 '>
                                                <Link to="/news-and-events/events/womens-day-celebration-2022">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/womens-day-selebrations-2022.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/womens-day-celebration-2022">
                                                    <h6 className='pt-3'>Women's Day Celebration March 8th 2022</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/hair-donation-drive-2022">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Hair Donation Drive-2022.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/hair-donation-drive-2022">
                                                    <h6 className='pt-3'>Hair Donation Drive </h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; January, 2022</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/obe-workshop-feb-2022">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/OBE Workship Feb 2022.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/obe-workshop-feb-2022">
                                                    <h6 className='pt-3'>OBE Workship Feb 2022</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; January, 2022</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/republic-day-2022">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Republic day celebrations 2022.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/republic-day-2022">
                                                    <h6 className='pt-3'>Republic day celebrations 2022</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; November, 2021</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/induction-program-2021-22">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Induction Program 2021-22.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/induction-program-2021-22">
                                                    <h6 className='pt-3'>Induction Program 2021-22</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; October, 2021</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/bathukamma-celebrations-2021">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Bathukamma celebrations 2021.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/bathukamma-celebrations-2021">
                                                    <h6 className='pt-3'>Bathukamma celebrations 2021</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; March, 2021</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/yuva-samarthya">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Yuva Samarthya - 2021.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/yuva-samarthya">
                                                    <h6 className='pt-3'>Yuva Samarthya - 2021</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/aicte-sttp-on-blockchain">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/AICTE Sponsored STTP on Blockchain Technology.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/aicte-sttp-on-blockchain">
                                                    <h6 className='pt-3'>AICTE Sponsored STTP on Blockchain Technology</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/womens-day-celebration-2021">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Womens Day Celebration 2021.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/womens-day-celebration-2021">
                                                    <h6 className='pt-3'>Women's Day Celebration 2021</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; January, 2021</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/republic-day-2021">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Republic Day Celebrations 2021.jpg' />

                                                </Link>
                                                <Link to="/news-and-events/events/republic-day-2021">
                                                    <h6 className='pt-3'>Republic Day Celebrations</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/dept/mba/">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Training programme given by Dr. Padmavati.jpeg' />
                                                </Link>
                                                <Link to="/dept/mba/">
                                                    <h6 className='pt-3'>Training programme given by Dr. Padmavati</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; December, 2020</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/mou-studyorbit">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/MoU with StudyOrbit.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/mou-studyorbit">
                                                    <h6 className='pt-3'>MoU with StudyOrbit</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/iso-certificate">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/ISO Certificate.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/iso-certificate">
                                                    <h6 className='pt-3'>ISO Certificate</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/alumni-meet-2020">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Alumni Meet 2020.jpg' />

                                                </Link>
                                                <Link to="/news-and-events/events/alumni-meet-2020">
                                                    <h6 className='pt-3'>Alumni Meet 2020</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/aicte-atal-fdp">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/AICTE ATAL FDP.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/aicte-atal-fdp">
                                                    <h6 className='pt-3'>AICTE ATAL FDP</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/first-year-orientation-day-2020">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/2. Freshers Orientation Day 2020- Anchors.jpg' />

                                                </Link>
                                                <Link to="/news-and-events/events/first-year-orientation-day-2020">
                                                    <h6 className='pt-3'>First Year Orientation Day 2020 </h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/mou-texas-review">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Texas Review MOU pic.jpg' />

                                                </Link>
                                                <Link to="/news-and-events/events/mou-texas-review">
                                                    <h6 className='pt-3'>MOU with Texas Review
                                                    </h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/sttp-on-blockchain-technology">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/AICTE STTP CSE Dept - Inaugural ceremony1.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/sttp-on-blockchain-technology">
                                                    <h6 className='pt-3'>STTP on Blockchain Technology and its Applications
                                                    </h6>
                                                </Link>
                                            </div>
                                            {/* <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <iframe height="220" src="https://www.youtube.com/embed/t0liMmvuoIA?autoplay=0&amp;showinfo=0&amp;controls=0"></iframe>
                                                <h6 className='pt-3'>MOU with Texas Review
                                                </h6>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; October, 2020</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/icdml">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/icdml-poster.png' />
                                                </Link>
                                                <Link to="/news-and-events/events/icdml">
                                                    <h6 className='pt-3'>ICDML2020</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; July, 2020</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/fdp-and-webinars">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/EEE WEBINAR_JULY2020_02.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/fdp-and-webinars">
                                                    <h6 className='pt-3'>FDP and WEBINARS</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; June, 2020</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/mba-fdp-and-sdp">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/FDP_MBA_June_2020.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/mba-fdp-and-sdp">
                                                    <h6 className='pt-3'> MBA FDP and SDP</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; January, 2020</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/self-defence-skills">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Self defence skills workshop on 2nd Jan 2020.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/self-defence-skills">
                                                    <h6 className='pt-3'> Self defence skills workshop on 2nd Jan 2020</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/say-no-to-plastic">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Say No to Plastic.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/say-no-to-plastic">
                                                    <h6 className='pt-3'> Say No to Plastic</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; December, 2019</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/cybersecurity-ambassador-prakriti-shah-seminar">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Cybersecurity Ambassador Seminar.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/cybersecurity-ambassador-prakriti-shah-seminar">
                                                    <h6 className='pt-3'> Cybersecurity Ambassador Seminar</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/mba-workshop">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/MBA workshop on Entrepreneurship2019.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/mba-workshop">
                                                    <h6 className='pt-3'> MBA workshop on Entrepreneurship</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; November, 2019</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/rowan-university-higher-education-seminar">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Rowan University Higher Education seminar.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/rowan-university-higher-education-seminar">
                                                    <h6 className='pt-3'> Rowan University Higher Education seminar</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/award-and-felicitation-by-education-matters">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Education Matters - SWEC Toppers Felicitation.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/award-and-felicitation-by-education-matters">
                                                    <h6 className='pt-3'> Award and Felicitation by Education Matters</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; October, 2019</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/she-teams">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/SHE team anniversary celebrations.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/she-teams">
                                                    <h6 className='pt-3'> SHE team anniversary celebrations</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/bathukamma_celebrations2019">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Bathukamma 2019.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/bathukamma_celebrations2019">
                                                    <h6 className='pt-3'> Bathukamma 2019</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; January, 2019</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4' >
                                                <Link to="/news-and-events/events/kill-cancer-essay-writing-competition">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Kill Cancer Essay writing competition.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/kill-cancer-essay-writing-competition">
                                                    <h6 className='pt-3'>Kill Cancer Essay writing competition</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/mou-education-matters">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Kill Cancer Essay writing competition2019.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/mou-education-matters">
                                                    <h6 className='pt-3'> MoU with Education Matters for Higher Education</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3 mb-lg-4'>
                                                <Link to="/news-and-events/events/jntuh-principal-meet">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/JNTUH Principal Meet 2019.jpg' />

                                                </Link>
                                                <Link to="/news-and-events/events/jntuh-principal-meet">
                                                    <h6 className='pt-3'> JNTUH Principal Meet 2019
                                                    </h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/iso-certification-swec">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/2015 Certification for SWEC2019.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/iso-certification-swec">
                                                    <h6 className='pt-3'> ISO 9001:2015 Certification for SWEC (2019)
                                                    </h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; September, 2019</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/mba-orentiation-day">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/mbaorient2019.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/mba-orentiation-day">
                                                    <h6 className='pt-3'> MBA Orientation Day</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/events2019">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Freshers Day 2019.jpeg' />

                                                </Link>
                                                <Link to="/news-and-events/events/events2019">
                                                    <h6 className='pt-3'> Events 2019</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; March, 2019</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/pratiyogita2019">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/KhoKho.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/pratiyogita2019">
                                                    <h6 className='pt-3'> Pratiyogita2K19</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/qubit-mgit-technical-fest">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Qubit MGIT Technical fest.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/qubit-mgit-technical-fest">
                                                    <h6 className='pt-3'>Qubit MGIT Technical fest</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/sri-samvidhan-2019">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/MBA FEST - Team behind the show.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/sri-samvidhan-2019">
                                                    <h6 className='pt-3'> Sri Samvidhan 2019</h6>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; February, 2019</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/kill-cancer-awareness">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Kill Cancer Awareness 5K Marathon.jpg' />

                                                </Link>
                                                <Link to="/news-and-events/events/kill-cancer-awareness">
                                                    <h6 className='pt-3'>Kill Cancer Awareness 5K Marathon</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/flashmob-2019">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Maithri 2019 Flash mob.jpeg' />
                                                </Link>
                                                <Link to="/news-and-events/events/flashmob-2019">
                                                    <h6 className='pt-3'> Maithri 2019 Flash mob</h6>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 submit'>
                                    <div className='eventsImages'>
                                        <h5 className=''><i class="fa-solid fa-calendar-days text-red"></i>&nbsp; January, 2018</h5>
                                        <div className='row mt-4'>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/sports-awards-2018-19">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/ANURAG KHOKHO RUNNERS.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/sports-awards-2018-19">
                                                    <h6 className='pt-3'> Sports Awards 2018-19</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/vidh-youth-2018">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/TechnoSWEC18 - Vidh Youth.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/vidh-youth-2018">
                                                    <h6 className='pt-3'> TechnoSWEC'18 - Vidh Youth</h6>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/sports-2018">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Sports day 2018.jpg' />
                                                </Link>
                                                <Link to="/news-and-events/events/sports-2018">
                                                    <h6 className='pt-3'>Sports day 2018</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/maithri-2018">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Maithri 2018.jpg' />

                                                </Link>
                                                <Link to="/news-and-events/events/maithri-2018">
                                                    <h6 className='pt-3'>Maithri 2018</h6>
                                                </Link>

                                            </div>
                                            <div className='col-md-6 col-lg-4 mb-3'>
                                                <Link to="/news-and-events/events/workshop-and-seminar">
                                                    <img loading="lazy" alt="events" classname="img-fluid" src='/assets/img/eventsImgs/Workshop and Seminar.jpg' />

                                                </Link>
                                                <Link to="/news-and-events/events/workshop-and-seminar">
                                                    <h6 className='pt-3'>Workshop and Seminar</h6>
                                                </Link>

                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}



export default Events
