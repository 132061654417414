import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const Maithri2K19 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>Maithri 2K19 ( The Cultural Fest)
                                    </h2>
                                    <div className='pb-2'>
                                        <p>MAITHRI is an Annual Cultural Fest celebrated in Sridevi Women's Engineering College (SWEC) on every Women's Day,March 8th. This year too it was celebrated with great pomp and show. Few glimpses of the fest..</p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/MAITHRI TEAM.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/MAITHRI TEAM.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/PAINTING 2.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/PAINTING 2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/PAINTING 3.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/PAINTING 3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/PAINTING 4.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/PAINTING 4.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/PAINTING 1.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/PAINTING 1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/MAITHRI 2019 - Award ceremony.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/MAITHRI 2019 - Award ceremony.jpg" alt="Image"
                                                        class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/MAITHRI 2019 BANNER.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/MAITHRI 2019 BANNER.jpg" alt="Image"
                                                        class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/Maithri news 2019.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/Maithri news 2019.jpg" alt="Image"
                                                        class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/SKETCH 1.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/SKETCH 1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/SKETCH 2.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/SKETCH 2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/SKETCH 3.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/SKETCH 3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/SKETCH 4.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/SKETCH 4.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/SKETCH 5.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/SKETCH 5.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/maithri-2019/CSE B Final year - Class Selfie Winners.jpg">
                                                    <img src="/assets/img/gallery/maithri-2019/CSE B Final year - Class Selfie Winners.jpg" alt="Image"
                                                        class="img-responsive center-block" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default Maithri2K19
