import React from 'react'

import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import IQACLeftTab from './IQACLeftTab';


const QualityEducation = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <IQACLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply submit">
                                <h2>Remedial Classes
                                </h2>
                                <p>
                                    The objective of remedial teaching at SWEC is to give additional help to students who, for one reason or another, have fallen behind the rest of the class in difficult engineering subjects Engg Drawing, BEE, Mathematics etc.
                                </p>
                                <p>
                                    Some of them may be unable to organize their perceptions or to comprehend engineering ideas and concepts. Some may have poor memory, poor level of motivation, short span of attention or associated behavioural problems. Above all, owing to encounters of failure they tend to have low aspirations and low expectations of themselves.
                                </p>
                                <p>
                                    Sometimes most of the learning difficulties of the students in remedial classes are not necessarily student related but are relative to the context where the learning is taking place, such as the family background of the child,parent support and the students’ peer groups.
                                </p>
                                <p>
                                    At SWEC, the remedial faculty understand thoroughly the strengths and weaknesses of these students so that appropriate teaching approaches can be adopted to meet their individual needs. With proper remedial help and the use of stimulating teaching strategies,closer supervision and more individual attention, the students’ interest in learning is aroused and help them make better progress.
                                </p>
                                <p>The ultimate aim of SWEC remedial teaching is to help students who have fallen behind to learn to the best of their ability and to bring them back into the mainstream classes as soon as possible.
                                </p>
                            </div>
                            <div className="how-to-apply submit">
                                <h2>Tutorial classes

                                </h2>
                                <p>SWEC gives tutorial classes for tough subjects and helps students understand difficult concepts and problems.
                                </p>
                                <p>The faculty discuss material from lectures and clarify the student queries on theory and problems.The students solve multiple questions to improve their grasp on the subject. Weak Students are given individual attention in tutorial classes that aid the student in understanding the subject bette.
                                </p>
                            </div>
                            <div className="how-to-apply">
                                <h2>Bridge courses
                                </h2>
                                <p>The objective of bridge course is to fill the gap in the curriculum studied by the student. It also includes courses on latest technology that are not part of the current curriculum.
                                </p>
                                <p>Bridge courses are regularly conducted in SWEC for the Diploma students and some other students to help them understand like mathematics or coding subjects that lateral entry students would have not come across such subjects and find them difficult. The faculty teach these subjects specially for such students and ensure they understand and excel in the exams.
                                </p>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/MENTOR  LIST.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> Mentoring </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default QualityEducation
