import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import FacilitiesLeftTab from './FacilitiesLeftTab';
import pdf from '../Facilities/bus/Bus routes_A4 (New).pdf'
import one from '../Facilities/bus/1.jpeg'
import two from '../Facilities/bus/22.jpg'
import three from '../Facilities/bus/3.jpeg'
import four from '../Facilities/bus/4.jpeg'
import five from '../Facilities/bus/5.jpg'
import six from '../Facilities/bus/6.jpg'

const Transport = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <FacilitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Transport Facility
                                </h2>
                                <p>SWEC is committed to the safety of its students and faculty. There is a fleet of well maintained buses with the latest communication devices that cover the areas around the college and far away locations thereby making it convenient for parents to send their children to reach college on time, safely and comfortably.
                                </p>
                                <p>There are 27 college buses and 8 exclusive hostel buses for the hostelites. The boarding points are spread across all corners of the city covered by 27 comprehensive routes.Every bus has a faculty incharge to ensure the safety of the students in transit.
                                </p>
                                <p>The transport in-charge is Mr. L Rameshwar Rao and can be contacted in case of any queries regarding transportation and routes.

                                </p>
                                <h6 className='pt-4 pb-3'>College Bus Routes
                                </h6>
                                <div class="table-responsive d-none">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-1
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  ROHIT
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9700051023
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:10
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">Panama Godowns Bus Stop, L.B.Nagar ,White House, Ashtalaxmi Temple, Kothapet Signals,
                                                    Chaitanyapuri(Vinayakaautomobiles, Dilsukhnagar (Saibaba Temple), Chandana Bros, Moosarambagh
                                                    Signals ,Malakpet Chermas, Nalgonda X Roads, Chaderghat, Koti (Putli Bowli Bus Stop), Abids (Ramakrishna
                                                    Theatre), Annapurna Hotel, Nampally,Mp Langerhouse, College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-2
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  VENKATESH

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 7993665353
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:35
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Srinagar Colony, Green Bawarchi, Annapurna Studio, Jubllihills Rad No 10, Madhapur Petrol Pump,
                                                    Gochibowli KFC, Wipro Cicrle, Goulidoddi,College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-3
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  RAJU

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 8897326332
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:15
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Saroornagar (Mandal Office), Saidabad Police Station, Chaderghat Police Station, Mojamjahi Market,
                                                    Gandhibhavan, Hyderabad Sweets, Bazarghat, Vijayanagar Colony (Vijaya Bank), N.M.D.C, Mehdipatnam,
                                                    Food World, Nanalnagar,Langerhouse,narsingi,kokapet College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-4
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  MALLESH

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9951144668
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:30
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Seetaphalmandi(Suresh Thetre), Warasiguda Bus Stop, Arts College Bus Stop, Jamai Osmania Bus Stop,
                                                    Adikmet Bus Stop, Ramnagargundu Bus Stop, Ramnagar (Near Hospital),   Vst  X Roa ds, Ashok   nagar  Signals,
                                                    Indira Park Signals, Indira Park Signals, Jntu MasabTank Bus Stop, Mehdipatnam , Nanalnagar Bus Stop,
                                                    Langer House, Narsingi, College,
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-5
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  SRISAILAM

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9133508940
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:30
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    E.S.I (Vikaspuri), A.G.Colony Gate,  Rajeevnagar ,  Rajeevnagar ,  Bharatnagar ,  Moosapet  Bus Stop, College

                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-6
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  MURTHY

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9652425830
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:15
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    E.S.I (Vikaspuri), A.G.Colony Gate,  Rajeevnagar ,  Rajeevnagar ,  Bharatnagar ,  Moosapet  Bus Stop, College

                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-7
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  LINGAM

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9052688172
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:40
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Esi ,  s.r.nagar  signals,  mytrivanam  signals,  ameerpet  signals,  r.s.brothers  signals,  srinagar  colony x roads,
                                                    punjagutta  signals, care hospital,  banjara  hills bus stop,  NMDC  M edipatnam ,  G alaxi  theatre,  shaikpet   nala ,
                                                    raidurga   ps , college
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-8
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  RAJU

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 6305778283
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:15
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Alwal ,  Lothukunta  Bus Stop,  Lal  Bazar Bus Stop,  Karkhana  ( Anjaneyaswamy  Temple),  Canara  Bank
                                                    ( Karkhana ),  Bowenpally  Signals,  Ferozguda  Bus Stop,  Balanagar  Bus Stop,  Narsapur  X Roads,  Jntu  Bus
                                                    Stop,  Nizam   Xroad ,  Gangaram , College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-9
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  SRIDHAR

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9949034498
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:00
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Nagole ,  Habsiguda  (Hanuman Temple),  Tarnaka  (Bus Stop),  Mettuguda ,  Sangeeth  (Rail  Nilayam ),
                                                    Y.M.C.Bridge , Police Line Signal,  Begumpet  Bus Stop,  Sesh   Mahal  ( Ameerpet ),  Myt h rivanam ,  Nalanda
                                                    Nagar( Vengalarao  Nagar),  Kalyan  Nagar X Road,  Krishnakanth  Park,  Jubilee  hills Check Post,  Kondapur ,
                                                    Gachibowli , College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-10
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  VENU

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9705834985
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:45
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Allwyn  Colony,  Jalkanya  Hotel,  Bashyan  School,  Vivekananda Nagar Reliance  Fresh, Vivekananda Nagar
                                                    Arch,  Jntu   ICICI   Bank,  Ceni  Police,  Malaysia Town Ship Ci r c le, Malaysia Town Ship, 6 th   Phase, Yellow
                                                    Bridge, Hi-Tech City, College.

                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-11
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  KHADEER

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9963195755
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:45
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Cry stal garden,   Pillar No143, Dairy Farm,  Budwel ,  Kismathpur,Bandlaguda,Suncity.
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-12
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  SRISAILAM

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9951585042
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:45
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Shiva  bagh ,  A mba  theatre,   Mehdipatnam,Rethibouli,Tolichowki , OU colony,  M a r ri chatu ,  Lanco  Hills, Wipro x
                                                    roads
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-13
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  YADAIAH

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9908345118
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:15
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Sagar  ring road, TKR  kamman ,   Meerpet  X Road,  Lalitha  Nagar X Road,  Manda   M allamma  garden,  Pishal
                                                    banda , DRDL Staff Quarters,  Katedan ,  Ara m g h ar  X Roads,  Rajendra  Nagar X Roads,  Attapur  X Roads,
                                                    Langar  House PS ,  Lan gar  House  S airam  Hospital,  Narsing i  , College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-14
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  SUDHARSHAN

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9347417440
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:30
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    ESI  ,  S R Nagar Signals,  Balkam  Pet,  Sanath   Nagar Bus Stand,  Erragadda  Signal,  Moosapet , Y Junction,
                                                    Kukatpally ,  Kalyan   Jewllers ,  Ushamullapudi ,  Kphb ,  Hydernagar , College

                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-15
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  NIRANJAN

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9177819958
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:40
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    IDPL ,  Shapur ,  Chintal , Ganesh Nagar - Ii Place,  Jagadgiri   Gutta ,  K ala   N ikethan ,  Hydernagar ,  Miyapur ,  Miyapur
                                                    ( Janapriya ),  Chandanagar ,  L ingampally  College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-16
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  AHMA D

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 8790013904
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:50
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    S R Nagar,  Yousuf   Guda  (Check Post), Krishna Nagar (Bus Stop),  Venkata   Giri ,  Peddamma  Temple (Main
                                                    Road), Madhapur (Petrol Bunk), Wipro, Satyam Computers,  Kondapur  Bus Stop,  Anji  Nagar,  Kothaguda  (G.I
                                                    School),  Gatchibowli  X Road,  Gulmohar   park,N al l agandla,College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-17
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  RAMESH

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9392713717
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:00
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    E.c.i.l . X roads,  K amalanagar  bus stop,  R adhika  bus stop,  S ainikpuri  bus stop,  N eredmet  x roads,  S afilguda
                                                    C heruvu ,  K rupa  complex,  A nand   bagh ,  M alkajgiri  bus stop,  M ettuguda  bus stop,  C hilakalaguda  x roads,
                                                    M usheerabad   singals ,  R aja deluxe,  RTC  x roads signals ,  Meh dipatnam ,  Langerhouse,college
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-18
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  ASHOK

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9912041887
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:10
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Saroor   nagar  ground,  K othapet ,  C haitanyapuri ,  C handana  bros( dilshuknagar ),  TV  tower,  M oosar ambagh
                                                    P etrolpump ,  A li café,  A mber pet, 6.no,  T ilak   nagar ,  F ever hospital,  B arkatpura ,  R eddy college,  YMCA ,  O ld
                                                    MLA  quarter,  B asheer   bagh  flyover,  A bids   GPO ,  D arga h ,  I ndranagar ,  M icrosoft,   College.    </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-19
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  MOHAN

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9603080651
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:45
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    ODF  Type-1,  Junio r Club,  Shop ping Com p lex,  L it Col l ege,  Shanker  Pally, B.D.L,  Khanapur , College.
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-20
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  CHANDU

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 8074567127
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:45
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Nizam  Pet X Road,  Vijetha  Super Market, Seventh Place,  Raghava  Reddy Gardens,  B hashyam  School,
                                                    Nizampet  Village,  Kesineni  Gar age,  Bachupally  Entrance, Coca Cola, Bus Body,  Miyapur  X Road,  Sairanga
                                                    Theatre,  Talki  Town,  C handanagar ,  L ingampally,College.
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-21
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name:  MD.ALI

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no: 9441204345
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time:7:30
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Sangareddy  New Bus   stand,  Natraj  Canteen,  Srd   Ib ,  Srd   Karuna  School,  Srd   Gokul  Hospital,  Srd   Iti ,  Srd  New
                                                    Cool   ctarte ,  Srd  Sunday Market,  Srd  X Road,  Kandi , Ganesh  Gadda ,  Rudraram ,  Isnapur ,  Isnapur  Church,
                                                    Muthangi ,  Pat ancheru ,  Rc   Puram , College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-22
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name :  ANIL

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no : 9542566992
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time : 9:00
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Metro,   Kukatpally   Bjp  Office,  Ku katpally   Kalanikethan ,  Kphb ,  Jntuh , Calvary Temple,  Miyapur ,  A l lwyn  Signals,
                                                    Hafeezpet  Bridge,   Kondapur  Signal, Botanical Garden,  Gachibowli , College

                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-23
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name :  RAMU

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no : 9392454666
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time : 8:45
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Pragathi   nagar  ,metro,  jntu ,  hyder   nagar ,  Madinaguda ,  Madinaguda  bus stop,  Gangaram  RS   brothers,
                                                    Chandanagar  bus stop,  B hel  circle,  N a l lagandla , college.
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-24
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name :   AKBAR

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no : 7569980653
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time : 7:25
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Kothur  , Thimmapur ,  Phalmakula ,  Shamshabad (Green  Bawarchi )  Shamshabad  Market,  Satham   Rai ,  Katedan
                                                    X Road,  Aramghar ,  Shivarampally ,  Bhavani  Colony ,   A.G.College ,  Rajendra  Nagar,  Budwel  Church,  Budwel
                                                    State Bank,  Bandlaguda , Kali   mandi r ,  Suncity ,  Tipkhan  Pool,  Vasavi  College Stop, College

                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-25
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name :    M D.SULTAN

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no : 7032085117
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time : 8:10
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Beeramguda ,  Saibaba  Temple, Ashok Nagar,  Jyothi  Nagar,  Lingampally , Rail  Vihar , College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-26
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name :    HUSSAIN

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no : 9618227240
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time : 7:10
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Sadashivapet  Bus Stand,  Malkapur  X Road,  Sangareddy  X Road,  Kandi ,  ODF,   Shankerp ally ,  Mokila ,
                                                    Khanapur , College
                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-27
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name :    YADAIH

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no : 9908345118
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time : 7:30
                                                </strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" class=" py-3">
                                                    Sanga reddy   X Road,  Isanapur ,  Muthangi ,  Patancheru . RC PURAM,   Ashok N agar ,  College

                                                </td>
                                            </tr>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Route no-28
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Driver name :      ANJANEYULU

                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Phone no : 8106553270
                                                </strong></td>
                                                <td align="center" valign="middle"><strong>Starting time : 7:30
                                                </strong></td>
                                            </tr>
                                            <tr className='border-0'>
                                                <td colspan="4" class=" py-3 border-0">
                                                    BDL,  Patancheru , RC  P uram ,  Be e rumguda , Ashok Nagar,
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className='pt-4'>
                                    <h6 className='text-danger text-center'><a className='text-danger' href={pdf} target='_blank'>Click here to show/download the route map document.</a></h6>
                                </div>
                                <div className='text-center pt-4'>
                                    <h5>Please Reach the Stop Before Five Minutes Bus Will Not Wait for You
                                    </h5>
                                    <p className='text-center pt-1'>For any transport related queries please contact</p>

                                    <p className='text-center'>Mr. Rameshwar Rao | Transport In-Charge Cell. No. <strong>8886885567
                                    </strong>
                                    </p>
                                </div>
                                <div className='pt-4 row'>
                                    <div className='col-md-6 pb-3'>
                                        <img src={one} loading='lazy' className='img-fluid' alt='clgbus' />
                                    </div>
                                    <div className='col-md-6 pb-3'>
                                        <img src={two} loading='lazy' className='img-fluid' alt='clgbus' />
                                    </div>
                                    <div className='col-md-6 pb-3'>
                                        <img src={three} loading='lazy' className='img-fluid' alt='clgbus' />
                                    </div>
                                    <div className='col-md-6 pb-3'>
                                        <img src={four} loading='lazy' className='img-fluid' alt='clgbus' />
                                    </div>

                                </div>
                                <div className='pt-4 '>
                                    <h5 className='pb-3 text-center'>We are providing Ambulance facility 24X7 available.
                                    </h5>
                                    <div className='row'>
                                        <div className='col-md-6 pb-3'>
                                            <img src={five} loading='lazy' className='img-fluid' alt='clgbus' />
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={six} loading='lazy' className='img-fluid' alt='clgbus' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Transport
