
import React,{useEffect} from 'react'
import { Link } from "react-router-dom";

const IQACLeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul>
                    <Link to="/iqac/quality-policy" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Quality Policy
                    </li></Link>
                    <Link to="/iqac/quality-education" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> Quality Education
                    </li></Link>
                    <Link to="/iqac" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> IQAC</li></Link>
                </ul>
            </div>
        </div>
    )
}

export default IQACLeftTab
