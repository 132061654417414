import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const Pratiyogita2K19 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Pratiyogita2K19
                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="clearfix ptop15">
                                            <p>SWEC Conducted a event <strong>Pratiyogita2K19</strong> held in March 2019</p>

                                            <div class=" mb-3 mb-lg-4">
                                                <h6 class="py-3"><strong>Pyramid formation by SWEC Sports Team</strong></h6>
                                                <img src="/assets/img/gallery/pratiyogita2K19/SWEC Sports Team.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                            </div>
                                            <div class=" mb-3 mb-lg-4">
                                                <h6 class="py-3"><strong>GNITS won the finals against SWEC in KhoKho in Pratiyogita 2K19</strong>
                                                </h6>
                                                <img src="/assets/img/gallery/pratiyogita2K19/KhoKho.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                            </div>
                                            <div class=" mb-3 mb-lg-4">
                                                <h6 class="py-3"><strong>SWEC Ladies Faculty Throwball Team - Winners</strong></h6>
                                                <img src="/assets/img/gallery/pratiyogita2K19/Throwball Team.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                            </div>

                                            <div class=" mb-3 mb-lg-4">
                                                <h4 class="mbottom15 mtop20"><strong>SWEC Throwball Runners Up in Pratiyogita 2k19</strong></h4>
                                                <img src="/assets/img/gallery/pratiyogita2K19/Volleyball Winners2.jpg" alt="Image"
                                                    class="img-responsive center-block" loading="lazy" />
                                            </div>

                                            <div class=" mb-3 mb-lg-4">
                                                <h6 class="py-3"><strong>SWEC Volleyball Winners</strong></h6>
                                                <img src="/assets/img/gallery/pratiyogita2K19/Volleyball Winners.jpg" alt="Image"
                                                    class="img-responsive center-block" loading="lazy" />
                                            </div>

                                            <div class=" mb-3 mb-lg-4">
                                                <h6 class="py-3"><strong>Pratiyogita 2K19</strong></h6>
                                            </div>
                                            <div className='row'>
                                                <div class="col-md-6 col-lg-4">

                                                    <img src="/assets/img/gallery/pratiyogita2K19/Throwball Team2.jpg" alt="Image" class="img-responsive" />
                                                </div>
                                                <div class="col-md-6 col-lg-4">
                                                    <img src="/assets/img/gallery/pratiyogita2K19/Volleyball Winners3.jpg" alt="Image"
                                                        class="img-responsive center-block" loading="lazy" />
                                                </div>
                                                <div class="col-md-6 col-lg-4">
                                                    <img src="/assets/img/gallery/pratiyogita2K19/Volleyball Winners4.jpg" alt="Image"
                                                        class="img-responsive center-block" loading="lazy" />
                                                </div>

                                                <div class=" mb-3 mb-lg-4">
                                                    <h6 class="py-3"><strong>Lighting the Torch - Inaugural ceremony</strong></h6>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Pratiyogita2K19
