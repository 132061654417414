import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'

const SalientFeatures = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Salient Features
                                </h2>
                                <div className="apply-list">
                                    <ul className='pt-4'>
                                        <li><i class="ri-checkbox-circle-line"></i>Completed two decades in the field of education
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Exclusive Women’s Engineering College</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Qualified and Experienced Faculty
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Healthy faculty and student ratio
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Sprawling 25 acres Campus
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Well-equipped labs
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Spacious and well stacked Library
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Training through TASK (Govt. Institution)
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>On Campus Personality Development Programs
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Learning through Industrial visits, workshops and seminars
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Excellent placements with 100% placement support
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Campus Hostel and College Bus facility
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>National Level players in Sports
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>JNTUH representation in Sports
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Awards and Certificates in various co-curricular activities
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SalientFeatures
