import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import PlacementsLeftTab from './PlacementsLeftTab';
import acc from '../TrainingAndPlacements/20-21/accenture.png'
import cts from '../TrainingAndPlacements/20-21/cts.png'
import dxc from '../TrainingAndPlacements/20-21/dxc.png'
import cap from '../TrainingAndPlacements/20-21/cg_logo.png'
import legato from '../TrainingAndPlacements/20-21/legato.png'
import hexaware from '../TrainingAndPlacements/20-21/hexaware.png'
import cgi from '../TrainingAndPlacements/20-21/cgi.png'
import TCS from '../TrainingAndPlacements/20-21/TCS.png'
import nttdata from '../TrainingAndPlacements/20-21/ntt-data.png'
import wipro from '../TrainingAndPlacements/20-21/wipro.png'
import ibm from '../TrainingAndPlacements/20-21/ibm.png'
import societe from '../TrainingAndPlacements/20-21/societe.png'
import SAP from '../TrainingAndPlacements/20-21/SAP-LABS.png'
import tech from '../TrainingAndPlacements/20-21/techm_logotechm_logotechm_logo.png'
import amazon from '../TrainingAndPlacements/20-21/amazon.png'
import INFOSYS from '../TrainingAndPlacements/20-21/INFOSYS.png'
import SYNTEL from '../TrainingAndPlacements/20-21/SYNTEL.png'
import hcl from '../TrainingAndPlacements/20-21/HCL.png'
import maq from '../TrainingAndPlacements/20-21/maq.png'
import mtx from '../TrainingAndPlacements/20-21/mtx.png'
import dell from '../TrainingAndPlacements/20-21/dell-tech.png'
import savantis from '../TrainingAndPlacements/20-21/savantis.png'
import invesco from '../TrainingAndPlacements/20-21/invesco.png'
import TELEPERFORMANCE from '../TrainingAndPlacements/20-21/TELEPERFORMANCE.png'
import Virtusa from '../TrainingAndPlacements/20-21/Virtusa.png'
import tsystems from '../TrainingAndPlacements/20-21/t-systems.png'
import subex from '../TrainingAndPlacements/20-21/subex.png'
import deloitte from '../TrainingAndPlacements/20-21/deloitte.png'
import enhisecure from '../TrainingAndPlacements/20-21/enhisecure.png'
import ZenQ from '../TrainingAndPlacements/20-21/ZenQ.png'
import atT from '../TrainingAndPlacements/20-21/atT.png'
import BOSCH from '../TrainingAndPlacements/20-21/BOSCH.png'
import ramGroup from '../TrainingAndPlacements/20-21/ramgroup.jpg'
import mindtree from '../TrainingAndPlacements/20-21/mindtree.png'
import GoogleMaps from '../TrainingAndPlacements/20-21/GoogleMaps.png'
import aliens from '../TrainingAndPlacements/20-21/aliens.png'
import ulearn from '../TrainingAndPlacements/20-21/ulearn.png'
import zon from '../TrainingAndPlacements/20-21/zon.png'
import cts2 from '../TrainingAndPlacements/20-21/cts2.png'
import cyient from '../TrainingAndPlacements/20-21/cyient.png'
import intelenet from '../TrainingAndPlacements/20-21/intelenet.png'
import genpact from '../TrainingAndPlacements/20-21/genpact.png'
import qspider from '../TrainingAndPlacements/20-21/qspider.png'
import c3i from '../TrainingAndPlacements/20-21/c3i.png'
import hitachi from '../TrainingAndPlacements/20-21/hitachi.png'
import VISTEX from '../TrainingAndPlacements/20-21/VISTEX.png'
import uxReactor from '../TrainingAndPlacements/20-21/uxReactor.png'
import MPHASIS from '../TrainingAndPlacements/20-21/MPHASIS.png'
import oracle from '../TrainingAndPlacements/20-21/oracle.png'
import DHlogo from '../TrainingAndPlacements/20-21/DHlogo.png'
import kony from '../TrainingAndPlacements/20-21/kony.png'
import dq from '../TrainingAndPlacements/20-21/dq.png'
import hgs from '../TrainingAndPlacements/20-21/hgs.png'
import incessant from '../TrainingAndPlacements/20-21/incessant.png'
import hp from '../TrainingAndPlacements/20-21/hp.png'
import muSigma from '../TrainingAndPlacements/20-21/muSigma.png'
import EXCERS from '../TrainingAndPlacements/20-21/EXCERS.png'
import INRYTHM from '../TrainingAndPlacements/20-21/INRYTHM.png'
import SERCO from '../TrainingAndPlacements/20-21/SERCO.png'
import rigalix from '../TrainingAndPlacements/20-21/rigalix.png'
import adp from '../TrainingAndPlacements/20-21/adp.png'
import Cadence from '../TrainingAndPlacements/20-21/adp.png'
import justDial from '../TrainingAndPlacements/20-21/justDial.png'
import google from '../TrainingAndPlacements/20-21/google.jfif'
import HSBC from '../TrainingAndPlacements/20-21/HSBC.png'
import landt from '../TrainingAndPlacements/20-21/landt.png'
import lg from '../TrainingAndPlacements/20-21/lg.png'
import MAQSoftware from '../TrainingAndPlacements/20-21/MAQSoftware.png'
import PORTWARE from '../TrainingAndPlacements/20-21/PORTWARE.png'
import NEKKANTISYSTEMS from '../TrainingAndPlacements/20-21/NEKKANTISYSTEMS.png'
import TOSHIBA from '../TrainingAndPlacements/20-21/TOSHIBA.png'
import ACROPETAL from '../TrainingAndPlacements/20-21/ACROPETAL.png'
import CSSCORP from '../TrainingAndPlacements/20-21/CSSCORP.png'
import FACE from '../TrainingAndPlacements/20-21/CSSCORP.png'
import ICICI from '../TrainingAndPlacements/20-21/ICICI.png'
import infotech from '../TrainingAndPlacements/20-21/infotech.png'
import OSMOSYS from '../TrainingAndPlacements/20-21/OSMOSYS.png'
import microsoft from '../TrainingAndPlacements/20-21/microsoft.png'
import SUTHERLAND from '../TrainingAndPlacements/20-21/SUTHERLAND.png'
import SPCapitalIQ from '../TrainingAndPlacements/20-21/SPCapitalIQ.png'
import Tripod from '../TrainingAndPlacements/20-21/Tripod.png'
import PENGROUP from '../TrainingAndPlacements/20-21/PENGROUP.png'
import SITEL from '../TrainingAndPlacements/20-21/SITEL.png'
import SATYAM from '../TrainingAndPlacements/20-21/SATYAM.png'
import XTREAMIT from '../TrainingAndPlacements/20-21/XTREAMIT.png'
import ITPLEXUS from '../TrainingAndPlacements/20-21/ITPLEXUS.png'
import ACTIVEBRAINS from '../TrainingAndPlacements/20-21/ACTIVEBRAINS.png'
import GCAConsulting from '../TrainingAndPlacements/20-21/GCAConsulting.png'
import SOCLogicIndia from '../TrainingAndPlacements/20-21/SOCLogicIndia.png'
import SRSCONSULTING from '../TrainingAndPlacements/20-21/SRSCONSULTING.png'
import TECRA from '../TrainingAndPlacements/20-21/TECRA.png'
import VODAFONE from '../TrainingAndPlacements/20-21/VODAFONE.png'
import StratagemSolutions from '../TrainingAndPlacements/20-21/StratagemSolutions.png'
import VALUEMOMENTUM from '../TrainingAndPlacements/20-21/VALUEMOMENTUM.png'
import InfobrainTechnologies from '../TrainingAndPlacements/20-21/InfobrainTechnologies.png'

const P1213 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <PlacementsLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>List of students selected for placements in the year 2012-2013
                                </h2>
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop25">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center" ><strong>S.No</strong></td>
                                                    <td align="center" ><strong>Name of the    Company</strong></td>
                                                    <td align="center" ><strong>&nbsp;Salary&nbsp;    L.P.A&nbsp;</strong></td>
                                                    <td align="center" ><strong>Total</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >1</td>
                                                    <td align="center" ><img src={microsoft} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >11.2</td>
                                                    <td align="center" >3</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >2</td>
                                                    <td align="center" ><img src={hcl} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2.4</td>
                                                    <td align="center" >21</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >3</td>
                                                    <td align="center" ><img src={tech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >3.15</td>
                                                    <td align="center" >18</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >4</td>
                                                    <td align="center" ><img src={dell} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >3</td>
                                                    <td align="center" >3</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >5</td>
                                                    <td align="center" ><img src={SPCapitalIQ} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >4.67</td>
                                                    <td align="center" >1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >6</td>
                                                    <td align="center" ><img src={adp} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >3.98</td>
                                                    <td align="center" >3</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >7</td>
                                                    <td align="center" ><img src={Tripod} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >1.8</td>
                                                    <td align="center" >4</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >8</td>
                                                    <td align="center" ><img src={Virtusa} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2.4</td>
                                                    <td align="center" >2</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >9</td>
                                                    <td align="center" ><img src={PENGROUP} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2.5</td>
                                                    <td align="center" >7</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >10</td>
                                                    <td align="center" ><img src={SITEL} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >1.8</td>
                                                    <td align="center" >1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >11</td>
                                                    <td align="center" ><img src={SATYAM} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2.2</td>
                                                    <td align="center" >6</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >12</td>
                                                    <td align="center" >&nbsp;<img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2.4</td>
                                                    <td align="center" >5</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >13</td>
                                                    <td align="center" ><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >3</td>
                                                    <td align="center" >1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >14</td>
                                                    <td align="center" ><img src={XTREAMIT} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >3.25</td>
                                                    <td align="center" >2</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >15</td>
                                                    <td align="center" ><img src={infotech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2.4</td>
                                                    <td align="center" >5</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >16</td>
                                                    <td align="center" ><img src={ITPLEXUS} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >1.8</td>
                                                    <td align="center" >1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >17</td>
                                                    <td align="center" ><img src={ACTIVEBRAINS} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >1.5</td>
                                                    <td align="center" >1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >18</td>
                                                    <td align="center" ><img src={GCAConsulting} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2</td>
                                                    <td align="center" >1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >19</td>
                                                    <td align="center" ><img src={SOCLogicIndia} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >1.8</td>
                                                    <td align="center" >2</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >20</td>
                                                    <td align="center" ><img src={SRSCONSULTING} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2.8</td>
                                                    <td align="center" >3</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >21</td>
                                                    <td align="center" ><img src={TECRA} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2</td>
                                                    <td align="center" >3</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >22</td>
                                                    <td align="center" ><img src={VALUEMOMENTUM} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >1.8</td>
                                                    <td align="center" >6</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >23</td>
                                                    <td align="center" ><img src={InfobrainTechnologies} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >1.8</td>
                                                    <td align="center" >7</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >24</td>
                                                    <td align="center" ><img src={SERCO} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2.4</td>
                                                    <td align="center" >30</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >25</td>
                                                    <td align="center" ><img src={VODAFONE} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >2.25</td>
                                                    <td align="center" >0</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >30</td>
                                                    <td align="center" ><img src={StratagemSolutions} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >3.5</td>
                                                    <td align="center" >15</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >31</td>
                                                    <td align="center" ><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    <td align="center" >3.1</td>
                                                    <td align="center" >2</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" >&nbsp;</td>
                                                    <td align="center" >&nbsp;</td>
                                                    <td align="center" ><strong>Total</strong></td>
                                                    <td align="center" ><strong>153</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}



export default P1213
