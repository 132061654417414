import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';

const EceIndustryIntern = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Industry Internship
                                </h2>
                                <p>Department of ECE, Sridevi Women’s Engineering College identified Curriculum Gaps and Learning Gaps among students. To fill these gaps, department collaborated with industry to enhance the student’s technical knowledge by doing internship projects. An internship is the phase of time for students when they are trained for their skill, they are good at and it gives them a chance to apply their knowledge practically in industries.
                                </p>
                                <h6>Internship Activities Offered in the Department:
                                </h6>
                                <p>SWEC, ECE department successfully concluded its internship process for the students. More than 20 companies made internship offers to the students, Major Companies include BHEL, BSNL, ECIL, ONGC, ODF, BDL etc. The internships are carried out by our students in the major areas of AI Technology, Cyber security, Mobile Technology, Signal Processing etc.
                                </p>
                                 <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/ece/2020-21.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> Click here for the Summery of Internship Activities during Academic Year 2020-2021 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2019-20.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> Click here for the Summery of Internship Activities during Academic Year 2019-2020 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2018-19.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  Click here for the Summery of Internship Activities during Academic Year 2018-2019  </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
 
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default EceIndustryIntern
