import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'


const SWECRecognitions = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>SWEC Recognitions

                                </h2>
                                
                                <div class="detgails-right-content">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/recognitions/UGC Autonomous Sanction Letter.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>UGC Autonomous Sanction Letter </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/recognitions/Institutional_Grade_Sheet.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>NAAC Grade sheet 2022 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/recognitions/Sridevi Women's Engineering College, Telangana23_6_2022_16_55_8.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>NBA 2022 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/ocuments/recognitions/AICTE Approval letter 2021-22.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>AICTE Approval letter 2021-22 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/cuments/recognitions/NBA Renewal letter -2021-22.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>NBA Renewal letter -2021-22 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/recognitions/SRIDEVI WOMEN_S 1ST SUR.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>ISO 2019-2022  ( Sept20 Audit) </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/recognitions/BPU Institution Partnership Certficate.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>Blue Prism Institution Partnership Certficate </a>
                                            </li>

                                            <li>
                                                <a href="/assets/documents/recognitions/NBA.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>NBA 2018-2021 </a>
                                            </li><li>
                                                <a href="/assets/documents/recognitions/ISO Certificate.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>ISO 2019-22 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/recognitions/AICTE 2019-20.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>AICTE 2019-20 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/recognitions/JNTUH 2019-20.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>JNTUH 2019-20 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/recognitions/section 2(f).pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>Section 2f </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="apply-list">
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SWECRecognitions
