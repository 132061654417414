import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'


const Aicte = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>AICTE Approval
                                </h2>
                            
                                <div class="detgails-right-content">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/AICTE  LETTERS 2011-2021.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> AICTE LETTERS 2011-2021 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/AICTE LETTERS 2001-2010.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> AICTE LETTERS 2001-2010 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="apply-list">
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Aicte;
