import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';

const ItLibrary = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Library Information
                                </h2>

                                <div class="table-responsive mt-3">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray" height="20">
                                                <td height="20">
                                                    S.NO</td>
                                                <td>
                                                    TITLES</td>
                                                <td>
                                                    AUTHOR</td>
                                                <td>
                                                    VOLUMES</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    1</td>
                                                <td>
                                                    MICROPROCESSORS
                                                    AND INTERFACING PROGRAMMING AND HARDWARE.
                                                </td>
                                                <td>
                                                    DOUGLAS V.HALL</td>
                                                <td>
                                                    17</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    2</td>
                                                <td>
                                                    MICROPROCESSORS
                                                    AND INTERFACING.
                                                </td>
                                                <td>
                                                    DOUGLAS V.HALL</td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    3</td>
                                                <td>
                                                    BUILDING
                                                    THE DATA WAREHOUSE
                                                </td>
                                                <td>
                                                    WILLIAM H.INMON</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="40">
                                                <td height="40"
                                                >
                                                    4</td>
                                                <td>
                                                    ADVANCED
                                                    MICROPROCESSORS AND <span>PHERIPHERALS</span>
                                                </td>
                                                <td>
                                                    Dr.
                                                    AJOY KUMAR ROY<br />
                                                    Dr. K M BHURCHANDI
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    5</td>
                                                <td>
                                                    DATA
                                                    MINING
                                                </td>
                                                <td>
                                                    JIAWEI HAN AND
                                                    MICHELINE KAMBER
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="57" >
                                                <td height="57">
                                                    6</td>
                                                <td>
                                                    THE
                                                    8051 MICROCONTROLLER AND EMBEDDED SYSTEMS
                                                </td>
                                                <td>
                                                    MUHAMMAD
                                                    ALI MAZIDI<br />
                                                    JANIC GILLISPIE MAZIDI<br />
                                                    ROLIN <span zeum4c5="PR_2_0" data-ddnwab="PR_2_0" aria-invalid="spelling"
                                                        class="LI ng">D.MCKINLAN</span>
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    7</td>
                                                <td>
                                                    PROCEDURAL
                                                    ELEMENTS FOR COMPUTER GRAPHICS
                                                </td>
                                                <td>
                                                    DAVID F.ROGERS</td>
                                                <td>
                                                    11</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    8</td>
                                                <td>
                                                    DATABASE
                                                    SYSTEM CONCEPTS
                                                </td>
                                                <td>
                                                    <span zeum4c5="PR_3_0" data-ddnwab="PR_3_0" aria-invalid="spelling"
                                                        class="LI ng">SILBERSCMATZ</span><br />
                                                    KORTH, SUDHARSHAN
                                                </td>
                                                <td>
                                                    4</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    9</td>
                                                <td>
                                                    DATABASE
                                                    MANAGEMENT SYSTEM
                                                </td>
                                                <td>
                                                    ALEXIS LEON, MATHEWS
                                                    LEON
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    10</td>
                                                <td>
                                                    DATA
                                                    MANAGEMENT AND FILE STRUCTURES
                                                </td>
                                                <td>
                                                    MARY E.S LOMIS</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    11</td>
                                                <td>
                                                    AN
                                                    INTRODUCTION TO DATABASE SYSTEM
                                                </td>
                                                <td>
                                                    BIPIN C.DESAI</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="57" >
                                                <td height="57">
                                                    12</td>
                                                <td>
                                                    DATABASE
                                                    SYSTEM CONCEPTS
                                                </td>
                                                <td>
                                                    ABRAHAM
                                                    SILBERSCHATZ, MENRY<br />
                                                    F. KORTH, <span zeum4c5="PR_4_0" data-ddnwab="PR_4_0" aria-invalid="spelling"
                                                        class="LI ng">S.SUDHARSHAN</span>
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    13</td>
                                                <td>
                                                    <span zeum4c5="PR_5_0" data-ddnwab="PR_5_0" aria-invalid="grammar" class="Lm ng">
                                                        MICRO
                                                        COMPUTER
                                                    </span> SYSTEMS,THE 8086/8088 <span zeum4c5="PR_6_0" data-ddnwab="PR_6_0" aria-invalid="spelling"
                                                        class="LI ng">FAMILYS</span>
                                                </td>
                                                <td>
                                                    YU-CHENG LIU, GLENN
                                                    A.GIBSON
                                                </td>
                                                <td>
                                                    4</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    14</td>
                                                <td>
                                                    MICROPROCESSORS</td>
                                                <td>
                                                    GILMORE</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    15</td>
                                                <td>
                                                    THE
                                                    INTEL MICROPROCESSORS
                                                </td>
                                                <td>
                                                    BARRY B.BREY</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    16</td>
                                                <td>
                                                    <span zeum4c5="PR_7_0" data-ddnwab="PR_7_0" aria-invalid="spelling" class="LI ng">CIRCUTES</span>
                                                    AND NETWORKS
                                                </td>
                                                <td>
                                                    A.
                                                    SUDHAKAR<br />
                                                    SHYAMMOHAN S PALLI
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    17</td>
                                                <td>
                                                    THE
                                                    8051 <span zeum4c5="PR_8_0" data-ddnwab="PR_8_0" aria-invalid="grammar" class="Lm ng">MICRO
                                                        CONTROLLERS</span>
                                                </td>
                                                <td>
                                                    AYALA</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    18</td>
                                                <td>
                                                    WIRELESS
                                                    COMMUNICATION AND NETWORKS
                                                </td>
                                                <td>
                                                    WILLIAM STALLINGS</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    19</td>
                                                <td>
                                                    NETWORK
                                                    THEORY
                                                </td>
                                                <td>
                                                    A.
                                                    SUDHAKAR<br />
                                                    SHYAMMOHAN S PALLI
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    20</td>
                                                <td>
                                                    COMPUTER
                                                    NETWORKS
                                                </td>
                                                <td>
                                                    ANDREW S.TANENBAUM</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    21</td>
                                                <td>
                                                    REAL
                                                    TIME COMPUTER CONTROL
                                                </td>
                                                <td>
                                                    STUART BENNETT</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    22</td>
                                                <td>
                                                    CRYPTOGRAPHY
                                                    AND <span zeum4c5="PR_9_0" data-ddnwab="PR_9_0" aria-invalid="spelling" class="LI ng">NETWOK</span>
                                                    SECURITY
                                                </td>
                                                <td>
                                                    WILLIAM STALLINGS</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="57" >
                                                <td height="57">
                                                    23</td>
                                                <td>
                                                    LAN</td>
                                                <td>
                                                    WHEELER'S<br />
                                                    SUBHASH MEHTA<br />
                                                    RAMESH BANGIA
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    24</td>
                                                <td>
                                                    CRYPTOGRAPHY
                                                    AND <span zeum4c5="PR_10_0" data-ddnwab="PR_10_0" aria-invalid="spelling"
                                                        class="LI ng">NETWOK</span> SECURITY
                                                </td>
                                                <td>
                                                    BEHROUZ
                                                    A.FOROUZAN<br />
                                                    DEBDEEP MUKHOPADHYAN
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    25</td>
                                                <td>
                                                    NEURAL
                                                    NETWORKS
                                                </td>
                                                <td>
                                                    SIMON HAYKIN</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    26</td>
                                                <td>
                                                    VISUAL
                                                    BASIC 6 PROGRAMMING
                                                </td>
                                                <td>
                                                    STEVEN HOLZNER</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    27</td>
                                                <td>
                                                    INTRODUCTION
                                                    TO COMPUTER GRAPHICS
                                                </td>
                                                <td>
                                                    ANIRBAN
                                                    MUKHOPADHYAY<br />
                                                    ARUP CHATTOPADHYAY
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    28</td>
                                                <td>
                                                    MANAGERIAL
                                                    ECONOMICS AND FINANCIAL ANALYSIS
                                                </td>
                                                <td>
                                                    A R ARYSRI</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    29</td>
                                                <td>
                                                    THE
                                                    UNIX PROGRAMMING ENVIRONMENT
                                                </td>
                                                <td>
                                                    <span zeum4c5="PR_11_0" data-ddnwab="PR_11_0" aria-invalid="grammar" class="Lm ng">BRAIN</span>
                                                    W.KERNIGHAN<br />
                                                    <span zeum4c5="PR_12_0" data-ddnwab="PR_12_0" aria-invalid="spelling" class="LI ng">ROBPIKE</span>
                                                </td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    30</td>
                                                <td>
                                                    DECISION
                                                    SUPPORT AND DATA WAREHOUSE SYSTEM
                                                </td>
                                                <td>
                                                    EFREM G.MAUAN</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    31</td>
                                                <td>
                                                    COMPUTER
                                                    GRAPHICS
                                                </td>
                                                <td>
                                                    STEVEN HARRINGTON</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    32</td>
                                                <td>
                                                    UNIX
                                                    AND SHELL PROGRAMMING
                                                </td>
                                                <td>
                                                    BEHROUZ
                                                    A.FOROUZAN<br />
                                                    RICHARD F. GIBERG
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    33</td>
                                                <td>
                                                    UNIX
                                                    SYSTEM PROGRAMMING USING C++
                                                </td>
                                                <td>
                                                    FERRENCE CHAN</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    34</td>
                                                <td>
                                                    ADVANCE
                                                    PROGRAMMING IN THE UNIX ENVIRONMENT
                                                </td>
                                                <td>
                                                    W. RICHARD STEVENS</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    35</td>
                                                <td>
                                                    UNIX
                                                    AND SHELL PROGRAMMING
                                                </td>
                                                <td>
                                                    SUMTABAH</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    36</td>
                                                <td>
                                                    PROCEDURAL
                                                    ELEMENTS FOR COMPUTER GRAPHICS
                                                </td>
                                                <td>
                                                    DAVID F.ROGERS</td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    37</td>
                                                <td>
                                                    COMPUTERS
                                                    AND COMMONSENSE
                                                </td>
                                                <td>
                                                    ROGER HUNT, JOHN
                                                    SHELLY
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    38</td>
                                                <td>
                                                    COMPUTER
                                                    FORENSICS AND INVESTIGATION
                                                </td>
                                                <td>
                                                    NELSON, PHILLIPS
                                                    ENFINGER, STEAUARD
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    39</td>
                                                <td>
                                                    MODERN
                                                    INFORMAL <span zeum4c5="PR_13_0" data-ddnwab="PR_13_0" aria-invalid="spelling"
                                                        class="LI ng">RETREIVAL</span>
                                                </td>
                                                <td>
                                                    RICHARD
                                                    BAEZA-YATER<br />
                                                    BERTHIER RIBIERO-NETO
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    40</td>
                                                <td>
                                                    COMPUTER
                                                    FORENSICS COMPUTER CRIME SCENE INVESTIGATION
                                                </td>
                                                <td>
                                                    JOHN R-VACCA</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    41</td>
                                                <td>
                                                    COMPUTER
                                                    ARCHITECTURE AND PARALLEL PROCESSING&nbsp;
                                                </td>
                                                <td>
                                                    KAI HWANG TAYE
                                                    A-BRIGGS
                                                </td>
                                                <td>
                                                    24</td>
                                            </tr>
                                            <tr height="95">
                                                <td height="95">
                                                    42</td>
                                                <td class="gmail-xl73">
                                                    OBJECT ORIENTED
                                                    MODELING AND DESIGNING
                                                </td>
                                                <td>
                                                    JAMES
                                                    RUMBAUGH, MICHAEL BLANA,<br />
                                                    <span zeum4c5="PR_14_0" data-ddnwab="PR_14_0" aria-invalid="spelling" class="LI ng">LILIUIAM</span>
                                                    PREMERLANI, FREDERICK <br />
                                                    EDDY, WILLIAM LORENSEN
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    43</td>
                                                <td>
                                                    CONCEPTS OF
                                                    PROGRAMMING LANGUAGES
                                                </td>
                                                <td>
                                                    ROBERT W.SEBESTA</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    44</td>
                                                <td>
                                                    CLIENT/SERVER
                                                    SURVIVAL GUIDE
                                                </td>
                                                <td>
                                                    ROBERT
                                                    ORJALI-DAN HARKEY<br />
                                                    JERI EDWARDS
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    45</td>
                                                <td>
                                                    SOFTWARE
                                                    TESTING TOOLS
                                                </td>
                                                <td>
                                                    K V K K PRASAD</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    46</td>
                                                <td>
                                                    ESSENTIAL
                                                    ACTION SCRIPT 2.0
                                                </td>
                                                <td>
                                                    COLIN UOOCKE</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    47</td>
                                                <td>
                                                    INTRANETS</td>
                                                <td>
                                                    JAMES D CIMINO</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    48</td>
                                                <td>
                                                    SPEECH
                                                    AND LANGUAGE PROCESSING
                                                </td>
                                                <td>
                                                    <span zeum4c5="PR_15_0" data-ddnwab="PR_15_0" aria-invalid="spelling" class="LI ng">DAKEIEL</span>
                                                    <span zeum4c5="PR_16_0" data-ddnwab="PR_16_0" aria-invalid="spelling" class="LI ng">JARAJSKY</span>
                                                </td>
                                                <td>
                                                    &nbsp;</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    49</td>
                                                <td>
                                                    INTERNET
                                                    AND WEB DESIGN
                                                </td>
                                                <td>
                                                    RAMESH BANGIA</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    50</td>
                                                <td>
                                                    OPERATING
                                                    SYSTEM
                                                </td>
                                                <td>
                                                    PABITRA <span zeum4c5="PR_17_0" data-ddnwab="PR_17_0" aria-invalid="spelling"
                                                        class="LI ng">OAL</span> CHOUAARY</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    51</td>
                                                <td>
                                                    OPERATING
                                                    SYSTEM CONCEPTS
                                                </td>
                                                <td>
                                                    ABRAHAM
                                                    SILBERSCHATZ<br />
                                                    PETER BAER GALVIN
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    52</td>
                                                <td>
                                                    SOFTWARE
                                                    TESTING TECHNIQUES
                                                </td>
                                                <td>
                                                    BORIS PEIZER</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    53</td>
                                                <td>
                                                    COBOL
                                                    PROGRAMMING
                                                </td>
                                                <td>
                                                    M K ROY, D GHOSH
                                                    DASTIDAR
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    55</td>
                                                <td>
                                                    DATA
                                                    WAREHOUSING IN THE REAL WORLD&nbsp;
                                                </td>
                                                <td>
                                                    SAM ANAHONY, DENNIS
                                                    MURRAY
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    56</td>
                                                <td>
                                                    FUNDAMENTALS
                                                    OF <span zeum4c5="PR_18_0" data-ddnwab="PR_18_0" aria-invalid="spelling"
                                                        class="LI ng">INFROMATION</span> TECHNOLOGY
                                                </td>
                                                <td>
                                                    ALEXIS MATHEWS</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    57</td>
                                                <td>
                                                    SIMULATION
                                                    MODELING AND ANALYSIS
                                                </td>
                                                <td>
                                                    AVERILL M LAW</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    58</td>
                                                <td>
                                                    INFORMATION
                                                    TECHNOLOGY
                                                </td>
                                                <td>
                                                    DENNIS P LUSTIN,
                                                    KIMJOLEY
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    59</td>
                                                <td>
                                                    VIRTUAL
                                                    REALITY <span zeum4c5="PR_19_0" data-ddnwab="PR_19_0" aria-invalid="spelling"
                                                        class="LI ng">TECHNOLGY</span>
                                                </td>
                                                <td>
                                                    GRIGOE C BORDIA,
                                                    PHILLIPE <span zeum4c5="PR_20_0" data-ddnwab="PR_20_0" aria-invalid="spelling"
                                                        class="LI ng">WIJJET</span>
                                                </td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    60</td>
                                                <td>
                                                    WORKING
                                                    IN MICROSOFT OFFICE
                                                </td>
                                                <td>
                                                    RON MANSFIELD</td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    61</td>
                                                <td>
                                                    INTRODUCTION
                                                    TO COMPUTER TO MS OFFICE 200
                                                </td>
                                                <td>
                                                    <span zeum4c5="PR_21_0" data-ddnwab="PR_21_0" aria-invalid="spelling"
                                                        class="LI ng">ALEXISLEON</span>
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    62</td>
                                                <td>
                                                    HUMAN
                                                    COMPUTER INTERACTION
                                                </td>
                                                <td>
                                                    ALANDIX JANNET FINLAY</td>
                                                <td>
                                                    4</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    63</td>
                                                <td>
                                                    WEB
                                                    PROGRAMMING
                                                </td>
                                                <td>
                                                    CHRIS BATES</td>
                                                <td>
                                                    4</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    64</td>
                                                <td>
                                                    PROGRAMMING
                                                    LANGUAGE
                                                </td>
                                                <td>
                                                    ROBERT W SEBESTA</td>
                                                <td>
                                                    4</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    65</td>
                                                <td>
                                                    JAVA
                                                    AND CORBA
                                                </td>
                                                <td>
                                                    ROBERT ORFALI</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    66</td>
                                                <td>
                                                    DEVELOPING
                                                    JAVA WEB SERVICES
                                                </td>
                                                <td>
                                                    RAMESH <span zeum4c5="PR_22_0" data-ddnwab="PR_22_0" aria-invalid="spelling"
                                                        class="LI ng">NAGAPPAH</span></td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    67</td>
                                                <td>
                                                    THE
                                                    COMPLETE REFERENCE JAVA
                                                </td>
                                                <td>
                                                    HERBERT SCHILILDD</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    68</td>
                                                <td>
                                                    PROGRAMMING
                                                    WITH JAVA
                                                </td>
                                                <td>
                                                    BALAGURU SWAMI</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    69</td>
                                                <td>
                                                    FUNDAMENTALS
                                                    OF COMPUTER ALGORITHMS
                                                </td>
                                                <td>
                                                    SARTAJ SAHNI</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    70</td>
                                                <td>
                                                    COMPUTER
                                                    AND COMPONENTS
                                                </td>
                                                <td>
                                                    <span zeum4c5="PR_23_0" data-ddnwab="PR_23_0" aria-invalid="spelling" class="LI ng">UARILYN</span>
                                                    WOLF&nbsp;
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    71</td>
                                                <td class="gmail-xl73">
                                                    INTRODUCTION TO
                                                    COMPUTER
                                                </td>
                                                <td>
                                                    PETER NORTON</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    72</td>
                                                <td>
                                                    VISUAL C++ 6</td>
                                                <td>
                                                    STEVEN HOLZNER</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    73</td>
                                                <td>
                                                    FUNDAMENTALS
                                                    OF PROGRAMMING LANGUAGES
                                                </td>
                                                <td>
                                                    GALGOTIA</td>
                                                <td>
                                                    5</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    74</td>
                                                <td>
                                                    NEURAL
                                                    NETWORKS
                                                </td>
                                                <td>
                                                    SIMON HAYKIN</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    75</td>
                                                <td>
                                                    SOFTWARE
                                                    PROJECT MANAGEMENT
                                                </td>
                                                <td>
                                                    <span zeum4c5="PR_24_0" data-ddnwab="PR_24_0" aria-invalid="spelling" class="LI ng">WLKER</span>
                                                    ROYCE
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    76</td>
                                                <td>
                                                    FUNDAMENTALS
                                                    OF MULTIMEDIA
                                                </td>
                                                <td>
                                                    MARK S.DREW</td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    77</td>
                                                <td>
                                                    HIGH
                                                    SPEED NETWORK AND INTERNET
                                                </td>
                                                <td>
                                                    WILLIAMS STALLINGS</td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    78</td>
                                                <td>
                                                    OBJECT
                                                    ORIENTED PROGRAMMING IN C++
                                                </td>
                                                <td>
                                                    NAGAJYOTHI BARKAKATI</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    79</td>
                                                <td>
                                                    DISTRIBUTED
                                                    DATABASE
                                                </td>
                                                <td>
                                                    STEFANO CERI</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    80</td>
                                                <td>
                                                    MULTIMEDIA
                                                    MAKING IT <span zeum4c5="PR_25_0" data-ddnwab="PR_25_0" aria-invalid="spelling"
                                                        class="LI ng">EORK</span>
                                                </td>
                                                <td>
                                                    TAY VAUGHAN</td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    81</td>
                                                <td>
                                                    COMPILERS</td>
                                                <td>
                                                    ALFRED V. AHO</td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    82</td>
                                                <td>
                                                    INTRODUCTION
                                                    TO AUTOMATA LANGUAGE &amp; COMPUTATIONS
                                                </td>
                                                <td>
                                                    JOHN E.HOPCROFT</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    82</td>
                                                <td>
                                                    OPERATIONS
                                                    RESEARCH
                                                </td>
                                                <td>
                                                    FREDERICK S. HILLIER</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    83</td>
                                                <td>
                                                    <span zeum4c5="PR_26_0" data-ddnwab="PR_26_0" aria-invalid="spelling" class="LI ng">DISCREATE</span>
                                                    MATHEMATICAL STRUCTURE WITH APPLICATION TO COMPUTER SCIENCE
                                                </td>
                                                <td>
                                                    R.MANOHAR</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    84</td>
                                                <td>
                                                    INTRODUCTION
                                                    TO AUTOMATA LANGUAGES&nbsp; COMPUTATIONS
                                                </td>
                                                <td>
                                                    JOHN E.HOPCROFT</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    85</td>
                                                <td>
                                                    <span zeum4c5="PR_27_0" data-ddnwab="PR_27_0" aria-invalid="spelling" class="LI ng">OPRATIONS</span>
                                                    <span zeum4c5="PR_28_0" data-ddnwab="PR_28_0" aria-invalid="spelling" class="LI ng">REASEARCH</span>
                                                </td>
                                                <td>
                                                    SD SHARMA</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    86</td>
                                                <td>
                                                    INTRODUCTION
                                                    TO OPERATION <span zeum4c5="PR_29_0" data-ddnwab="PR_29_0" aria-invalid="spelling"
                                                        class="LI ng">REASEARCH</span>
                                                </td>
                                                <td>
                                                    BILLY E.GILLETT</td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    87</td>
                                                <td>
                                                    ARTIFICIAL
                                                    INTELLIGENCE
                                                </td>
                                                <td>
                                                    KEVIN,KIGHT</td>
                                                <td>
                                                    3</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    88</td>
                                                <td>
                                                    THE
                                                    ESSENTIAL GUIDE TO USER INTERFACE DESIGN
                                                </td>
                                                <td>
                                                    <span zeum4c5="PR_30_0" data-ddnwab="PR_30_0" aria-invalid="spelling" class="LI ng">WILVERT</span>
                                                    O.GALITZ
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    89</td>
                                                <td>
                                                    ORACLE
                                                    DEVELOPER 2000
                                                </td>
                                                <td>
                                                    IVAN BAYROSS</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    90</td>
                                                <td>
                                                    DATA
                                                    MINING CONCEPTS AND TECHNIQUES
                                                </td>
                                                <td>
                                                    JIAWEI HAN AND
                                                    MICHELINE KAMBER
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    91</td>
                                                <td>
                                                    COMPUTER
                                                    ARCHITECTURE AND ORGANIZATION
                                                </td>
                                                <td>
                                                    JOHN P.HAYES</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    92</td>
                                                <td>
                                                    COMPUTER
                                                    ORGANIZATION
                                                </td>
                                                <td>
                                                    CARL
                                                    HAMACHER,ZVONKO VRANESIC,SAFWAT ZAKY
                                                </td>
                                                <td>
                                                    2</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    93</td>
                                                <td>
                                                    SOFTWARE
                                                    ENGINEERING
                                                </td>
                                                <td>
                                                    SOMMERVILLE</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    94</td>
                                                <td>
                                                    JAVA
                                                    (THE COMPLETE REFERENCE)
                                                </td>
                                                <td>
                                                    HERBERT SCHILDT</td>
                                                <td>
                                                    4</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    95</td>
                                                <td>
                                                    COMPUTER
                                                    NETWORKS
                                                </td>
                                                <td>
                                                    ANDREW S.TANENBAUM</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    96</td>
                                                <td>
                                                    CRYPTOGRAPHY
                                                    AND NETWORK SECURITY
                                                </td>
                                                <td>
                                                    WILLIAM STALLINGS</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    97</td>
                                                <td>
                                                    SOFTWARE
                                                    TESTING TECHNIQUES
                                                </td>
                                                <td>
                                                    BORIS BEIZER</td>
                                                <td>
                                                    7</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    98</td>
                                                <td>
                                                    SOFTWARE
                                                    ENGINEERING
                                                </td>
                                                <td>
                                                    ROGERS.PRESSMAN</td>
                                                <td>
                                                    16</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    99</td>
                                                <td>
                                                    EFFECTIVE
                                                    METHODS FOR SOFTWARE TESTING
                                                </td>
                                                <td>
                                                    WILLIAM E.PERRY</td>
                                                <td>
                                                    4</td>
                                            </tr>
                                            <tr height="20">
                                                <td colspan="4" height="20">
                                                    &nbsp;</td>
                                            </tr>
                                            <tr height="20">
                                                <td colspan="3" height="20">
                                                    TOTAL VOLUMES
                                                    AVAILABLE
                                                </td>
                                                <td>
                                                    243</td>
                                            </tr>
                                            <tr height="20">
                                                <td colspan="3" height="20">
                                                    TOTAL NUMBER OF TITLES AVAILABLE</td>
                                                <td>
                                                    99</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-responsive mt-3">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr height="24">
                                                <td height="24">
                                                    S.NO</td>
                                                <td>
                                                    TITLES</td>
                                                <td>
                                                    AUTHOR</td>
                                                <td>
                                                    VOLUMES</td>
                                            </tr>
                                            <tr height="42">
                                                <td height="42">
                                                    1</td>
                                                <td>
                                                    DATA
                                                    COMMUNICATION AND NETWORKING 5G
                                                </td>
                                                <td>
                                                    FOROUZAN</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="39">
                                                <td height="39">
                                                    2</td>
                                                <td>
                                                    OPERATING
                                                    SYSTEMS-INTERNALS AND DESIGN PRINCIPLES
                                                </td>
                                                <td>
                                                    WILLIAM STALLINGS</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    3</td>
                                                <td>
                                                    UNIX <span class="LI ng">INTERNLAS</span> -THE
                                                    NEW FRONTIERS
                                                </td>
                                                <td>
                                                    URESH VAHALIA</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="38">
                                                <td height="38">
                                                    4</td>
                                                <td>
                                                    MASTERING DATA
                                                    WAREHOUSE DESIGN
                                                </td>
                                                <td>
                                                    CLAUDIA
                                                    IMHOFF,NICHOLAS GALEMMO,JONATHAN G.GEIGER
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    5</td>
                                                <td>
                                                    AUTOMATA AND
                                                    COMPUTABILITY
                                                </td>
                                                <td>
                                                    DEXTER C.KOZEN</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="47" >
                                                <td height="47"
                                                >
                                                    6</td>
                                                <td>
                                                    DATABASE MANAGEMENT
                                                    SYSTEMS
                                                </td>
                                                <td>
                                                    RAGHU
                                                    RAMAKRISHNAN/JOHANNES GEHRKE
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="39">
                                                <td height="39">
                                                    7</td>
                                                <td>
                                                    NETWORK SECURITY</td>
                                                <td>
                                                    ROBERTA
                                                    BRAGG,MARK RHODES-OUSLEY,KEITH STRAABERG
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="39">
                                                <td height="39">
                                                    8</td>
                                                <td>
                                                    COMPUTER
                                                    SYSTEMS ORGANIZATION &amp; ARCHITECTURE
                                                </td>
                                                <td>
                                                    JOHN D.CARPINELLI</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="39">
                                                <td height="39">
                                                    9</td>
                                                <td>
                                                    PRACTICAL
                                                    OBJECT-ORIENTED DESIGN WITH UML
                                                </td>
                                                <td>
                                                    MARK PRIESTLEY</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    10</td>
                                                <td>
                                                    FUNDAMENTALS OF
                                                    EMBEDDED SOFTWARE
                                                </td>
                                                <td>
                                                    DANIEL W.LEWIS</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="39">
                                                <td height="39">
                                                    11</td>
                                                <td>
                                                    SOFTWARE TESTING
                                                    TECHNIQUES
                                                </td>
                                                <td>
                                                    GEOFFREY
                                                    MILLER,RICHARD PREWITT JR.MICHAEL SHANNON
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    12</td>
                                                <td>
                                                    SYSTEM SOFTWARE</td>
                                                <td>
                                                    T.SUJATHA</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    13</td>
                                                <td>
                                                    SOFTWARE PROJECT
                                                    MANAGEMENT
                                                </td>
                                                <td>
                                                    JOEL HENRY</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    14</td>
                                                <td>
                                                    PHP</td>
                                                <td>
                                                    STEVEN HOLZNER</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    15</td>
                                                <td>
                                                    OBJECT-ORIENTED
                                                    ANALYSIS 2 DESIGN
                                                </td>
                                                <td class="gmail-xl67">
                                                    GRADY BOOCH</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="39">
                                                <td height="39">
                                                    16</td>
                                                <td>
                                                    DISTRIBUTED SYSTEMS</td>
                                                <td>
                                                    GEORGE
                                                    COULOURIS,JEAN DOLLIMORE,TIM KINDBERG
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    17</td>
                                                <td>
                                                    FUNDAMENTALS OF
                                                    DATABASE SYSTEMS
                                                </td>
                                                <td>
                                                    RAMEZ
                                                    ELMASRI,SHAMKANT B.NAVATHE
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    18</td>
                                                <td>
                                                    SOFTWARE TESTING
                                                    TOOLS
                                                </td>
                                                <td>
                                                    Dr. K.V.K.K. PRASAD</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    19</td>
                                                <td class="gmail-xl67">
                                                    MODELING SOFTWARE SYSTEMS USING UML 2</td>
                                                <td>
                                                    PASCAL ROQUES</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    20</td>
                                                <td
                                                >
                                                    CRYPTOGRAPHY AND NETWORK SECURITY</td>
                                                <td>
                                                    WILLIAM STALLINGS</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="39">
                                                <td height="39">
                                                    21</td>
                                                <td>
                                                    NETWORK SECURITY</td>
                                                <td>
                                                    CHARLIE
                                                    KAUFMAN,RADIA PERLMAN,MIKE SPECINER
                                                </td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    22</td>
                                                <td>
                                                    CRYPTOGRAPHY AND
                                                    NETWORK SECURITY
                                                </td>
                                                <td>
                                                    ATUL KAHATE</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td height="20">
                                                    23</td>
                                                <td>
                                                    COMPUTER NETWORKS</td>
                                                <td>
                                                    ANDREW S.TANENBAUM</td>
                                                <td>
                                                    1</td>
                                            </tr>
                                            <tr height="20">
                                                <td colspan="4" height="20">
                                                    &nbsp;</td>
                                            </tr>
                                            <tr height="20">
                                                <td colspan="3" height="20">
                                                    TOTAL NO.OF VOLUMES AVAILABLE</td>
                                                <td>
                                                    23</td>
                                            </tr>
                                            <tr height="20">
                                                <td colspan="3" height="20">
                                                    NO.OF TITLES AVAILABLE</td>
                                                <td>
                                                    23</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ItLibrary
