import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import SLeftTab from './SLeftTab';
import hod from './sandhimages/hod.jpg'


const SHod = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <SLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>HOD Profile
                                </h2>
                                <div className='pt-3'>
                                    <div class="submit">
                                        <div>
                                            <img src={hod} width="100" className='img-fluid' alt='mba-hod' loading='lazy'/>
                                        </div>
                                        <p className='pt-3'><strong>Dr. Mr.K.C.Ravikumar</strong> is working as an assistant Professor in the CSE department and Head of the Department of Humanities and Sciences. He has teaching experience of 21 years in the society. He obtained M.Tech degree from Jawaharlal Nehru Technological University and pursuing PhD in Computer Science and Engineering from Jawaharlal Nehru Technological University, Hyderabad. He has published 18 research papers in various reputed national and international journals. He attended 4 International conferences . He is a Member of ISTE and CSI.</p>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SHod
