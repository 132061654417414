import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';


const CSELaboratories = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Laboratories
                                </h2>
                                <h6>Academic Year 2020-21
                                </h6>
                                <div class="table-responsive pt-2">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td><p class="text-center"><strong>S.No</strong></p></td>
                                                <td><p><strong>LAB No.</strong></p></td>
                                                <td><p><strong>LAB Name</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>LAB 1</td>
                                                <td>PYTHON/OS LAB</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>LAB 2</td>
                                                <td>PYTHON/OS /CONCURRENT PROGRAMMING LAB</td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>LAB 1</td>
                                                <td>PYTHON/OS LAB</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>LAB 3</td>
                                                <td>ITWS/MACHINE LEARNING LAB</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>LAB 4</td>
                                                <td>MAD/ MACHINE LEARNING LAB</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>LAB 5</td>
                                                <td>IoT LAB</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>LAB 6</td>
                                                <td>
                                                    WEBSCRIPTING LANGUAGES/SCRIPTING
                                                    LANGUAGES
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>LAB 7</td>
                                                <td>
                                                    CN/WT LAB
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>LAB 8</td>
                                                <td>
                                                    CN/WT/NETWORK PROGRAMMING LAB
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>LAB 9</td>
                                                <td>
                                                    DBMS/SE LAB
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>LAB 10</td>
                                                <td>
                                                    DBMS/SE/STM LAB
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>LAB 11</td>
                                                <td>
                                                    DM/CD LAB
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>LAB 12</td>
                                                <td>
                                                    DM/CD LAB
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>LAB 13</td>
                                                <td>
                                                    DS THROUGH C/JAVA LAB
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>LAB 14</td>
                                                <td>
                                                    C++/JAVA LAB
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className='pt-3'>
                                    <h6 className='pb-2'>Academic Year 2019-2020
                                    </h6>
                                    <div class="table-responsive">
                                            <table class="table table-bordered mtop25">
                                                <tbody>
                                                    <tr class="bg-gray">
                                                        <td><p class="text-center"><strong>S.No</strong></p></td>
                                                        <td><p><strong>LAB No.</strong></p></td>
                                                        <td><p><strong>LAB Name</strong></p></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>LAB 1</td>
                                                        <td>PYTHON/OS LAB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>LAB 2</td>
                                                        <td>PYTHON/OS LAB</td>
                                                    </tr>

                                                    <tr>
                                                        <td>3</td>
                                                        <td>LAB 3</td>
                                                        <td>ITWS/ANDROID LAB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>LAB 4</td>
                                                        <td>ITWS/ANDROID LAB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>LAB 5</td>
                                                        <td>IoT/C&amp;NS LAB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>LAB 6</td>
                                                        <td>
                                                            R-Prog/C&amp;NS LAB
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>LAB 7</td>
                                                        <td>
                                                            CN/WT LAB
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>LAB 8</td>
                                                        <td>
                                                            CN/WT LAB
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>LAB 9</td>
                                                        <td>
                                                            DBMS/SE LAB
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td>LAB 10</td>
                                                        <td>
                                                            DBMS/SE LAB
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>11</td>
                                                        <td>LAB 11</td>
                                                        <td>
                                                            DM/DAA LAB
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>LAB 12</td>
                                                        <td>
                                                            DM/DAA LAB
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>13</td>
                                                        <td>LAB 13</td>
                                                        <td>
                                                            DS/JAVA LAB
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>14</td>
                                                        <td>LAB 14</td>
                                                        <td>
                                                            C++/JAVA LAB
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CSELaboratories
