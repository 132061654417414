import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import DepartmentTab from './CSEDepartmentTab';

const Library = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row mx-auto justify-content-center">
                        {/* <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div> */}
                        <div className="col-lg-12">
                            <div className="how-to-apply">
                                <h2>Central Library
                                </h2>
                                <div class=" pt-3">
                                    <h5><i class="fa fa-eye text-red"></i> Vision</h5>
                                    <div class="pt-2">
                                        <p>To make available the existing & Developing Knowledge through Physical books, Journals & E-books, E-Journals a Global level in all fields to facilitate the students to rise to their highest capacity for the women empowerment.</p>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <div class="apply-list">
                                        <h5 class=""><i class="fa fa-bullseye text-red"></i> Mission</h5>
                                        <p className='pt-2'>To make available the existing & Developing Knowledge through Physical books, Journals & E-books, E-Journals a Global level in all fields to facilitate the students to rise to their highest capacity for the women empowerment.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-4'>
                                <h5>About Department</h5>
                                <p>Central Library occupies a place of pride in SWEC and is an essential component of the institute outstanding education mission. It is the most lively place on the campus providing a safe, comfortable and friendly environment that enables learning and advancement of knowledge and promotes discovery and scholarship. The mission of the Central Library is to facilitate the creation of new knowledge through acquisition, organization and dissemination of knowledge resources and providing for value-added services. All students, faculty members and employees of the Institute are permitted to make use of library facilities. The library has a huge collection of books on Engineering, Management, Science and Humanities offer library services through its various divisions. It is a most lively place - It is the most lively place. Central Library with a carpet area - The library has a carpet area of the library has a carpet area of 1500 Sq.m. with Digital facility is functioning from 9:20 AM to 4 PM on all working days.
                                </p>
                                <div className='pt-3'>
                                    <h6>Area of the Library</h6>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered text-start">
                                            <tbody>
                                                <tr>
                                                    <td align="center"><strong>Library Area</strong></td>
                                                    <td align="center"><strong>1500sqm</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>Seating Capacity</strong></td>
                                                    <td align="center"><strong>200</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>No of Volumes</strong></td>
                                                    <td align="center"><strong>43962</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>No of Titles</strong></td>
                                                    <td align="center"><strong>5894</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>SC/ST BOOKS:Volumes</strong></td>
                                                    <td align="center"><strong>2659</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>Titles</strong></td>
                                                    <td align="center"><strong>302</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>Printed Journals</strong></td>
                                                    <td align="center"><strong>72</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>National Journals</strong></td>
                                                    <td align="center"><strong>60</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>Online Journals</strong></td>
                                                    <td align="center"><strong>9950</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>Delnet</strong></td>
                                                    <td align="center"><strong>805</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>J-Gate</strong></td>
                                                    <td align="center"><strong>9145</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>Used Software</strong></td>
                                                    <td align="center"><strong>NewGenlib</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>Digital Library</strong></td>
                                                    <td align="center"><strong>40 Systems</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <h6>List of books in Library</h6>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center"><strong>S.NO</strong></td>
                                                    <td align="center"><strong>BRANCH</strong></td>
                                                    <td align="center"><strong>TITLES</strong></td>
                                                    <td align="center"><strong>VOLUMES</strong></td>
                                                    <td align="center"><strong>NATIONAL JOURNALS</strong></td>
                                                    <td align="center"><strong>INTERNATIONAL JOURNALS</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center">1</td>
                                                    <td align="center">ECE</td>
                                                    <td align="center">997</td>
                                                    <td align="center">8668</td>
                                                    <td align="center">18</td>
                                                    <td align="center">6</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">2</td>
                                                    <td align="center">CSE</td>
                                                    <td align="center">1601</td>
                                                    <td align="center">11730</td>
                                                    <td align="center">23</td>
                                                    <td align="center">4</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">3</td>
                                                    <td align="center">EEE</td>
                                                    <td align="center">657</td>
                                                    <td align="center">4923</td>
                                                    <td align="center">0</td>
                                                    <td align="center">0</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">4</td>
                                                    <td align="center">IT</td>
                                                    <td align="center">271</td>
                                                    <td align="center">2437</td>
                                                    <td align="center">5</td>
                                                    <td align="center">1</td>
                                                </tr>

                                                <tr>
                                                    <td align="center">6</td>
                                                    <td align="center">S &amp; H</td>
                                                    <td align="center">1201</td>
                                                    <td align="center">9378</td>
                                                    <td align="center">0</td>
                                                    <td align="center">0</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">7</td>
                                                    <td align="center">MBA</td>
                                                    <td align="center">1167</td>
                                                    <td align="center">6826</td>
                                                    <td align="center">11</td>
                                                    <td align="center">1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">&nbsp;</td>
                                                    <td align="center">Total</td>
                                                    <td align="center">5894</td>
                                                    <td align="center">43962</td>
                                                    <td align="center">61</td>
                                                    <td align="center">11</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Library Details</h6>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td align="center">1</td>
                                                    <td align="center">Librarian Name</td>
                                                    <td align="center">L.RAMESHWAR RAO </td>
                                                </tr>
                                                <tr>
                                                    <td align="center">2</td>
                                                    <td align="center">Qualification</td>
                                                    <td align="center">B.A  MLISc</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">3</td>
                                                    <td align="center">Reading Capacity</td>
                                                    <td align="center">200</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">4</td>
                                                    <td align="center">No of Computers</td>
                                                    <td align="center">40</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">5</td>
                                                    <td align="center">No of volumes</td>
                                                    <td align="center">43962</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">6</td>
                                                    <td align="center">No of titles</td>
                                                    <td align="center">5894</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">7</td>
                                                    <td align="center">E-Journals DELNET</td>
                                                    <td align="center">805</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">8</td>
                                                    <td align="center">J-GATE</td>
                                                    <td align="center">9145</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">9</td>
                                                    <td align="center">Printed Journals</td>
                                                    <td align="center">72</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">10</td>
                                                    <td align="center">Study Tables</td>
                                                    <td align="center">20</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">11</td>
                                                    <td align="center">software</td>
                                                    <td align="center">NEWGENLIB</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <h6>Online Journals</h6>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center"><strong>JOURNALS</strong></td>
                                                    <td align="center"><strong>QUANTITY</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center">DELNET</td>
                                                    <td align="center">805</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">J-GATE Engiineering &amp; Technology</td>
                                                    <td align="center">6206</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">Full Text Journals</td>
                                                    <td align="center">2939</td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>Total</strong></td>
                                                    <td align="center"><strong>9950</strong></td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-4 submit'>
                                    <h6>List of periodicals subscribed journals</h6>
                                    <div class="table-responsive pt-2 ">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center"><strong>BRANCH</strong></td>
                                                    <td align="center"><strong>NATIONAL JOURNALS</strong></td>
                                                    <td align="center"><strong>INTERNATIONAL JOURNALS</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center">ECE</td>
                                                    <td align="center">31</td>
                                                    <td align="center">38</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">CSE</td>
                                                    <td align="center">28</td>
                                                    <td align="center">30</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">EEE</td>
                                                    <td align="center">19</td>
                                                    <td align="center">12</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">IT</td>
                                                    <td align="center">13</td>
                                                    <td align="center">9</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">S &amp; H</td>
                                                    <td align="center">26</td>
                                                    <td align="center">13</td>
                                                </tr>
                                                <tr>
                                                    <td align="center">MBA</td>
                                                    <td align="center">47</td>
                                                    <td align="center">13</td>
                                                </tr>
                                                <tr>
                                                    <td align="center"><strong>Total</strong></td>
                                                    <td align="center"><strong>164</strong></td>
                                                    <td align="center"><strong>108</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className='submit'>
                                <h5>Digital Library</h5>
                                <p>E-journals have been subscribed through DELNET -AICTE consortium. Access to these Journals is made available to all the staff and students. Many transactions, journals, magazines, newsletters, proceedings can be accessed though this Digital Library.
                                </p>
                                <h6>
                                    E Library Online Journals
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <div className='row'>
                                                <div className='col-lg-4'>
                                                    <li>
                                                        <a href="https://jgateplus.com/search/login/" target='_blank'><i class="fa-solid fa-link"></i> J-GATE </a>
                                                    </li>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <li>
                                                        <a href="http://164.100.247.30/" target='_blank'><i class="fa-solid fa-link"></i> Delnet </a>
                                                    </li>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <li>
                                                        <a href="https://ndl.iitkgp.ac.in/" target='_blank'><i class="fa-solid fa-link"></i> NDA India </a>
                                                    </li>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='submit'>
                                <h5>Internet Lab</h5>
                                <p>
                                    Twenty systems connected to LAN with Internet facility are provided for the Students. They can use the facility for their project work, preparation for seminars and paper presentations, online access to journals, e-books and study material. Intranet facility is provided to access the previous question papers, teaching schedules, internal marks, attendance, daily circulars etc.</p>
                            </div>
                            <div className='submit'>
                                <h5>Audio - Visual Centre & Sonet Lecture Dvd's </h5>
                                <p>VD's comprising the NPTEL (National Programme on Technology Enhanced Learning) /SONET lectures which were procured from all IIT's are kept open for students and staff for subject reference. Staff and students can refer these dvd's in the seminar halls.</p>
                            </div>
                            <div className='submit'>
                                <h5>Departmental Libraries</h5>
                                <p>In addition to the Central Library, every PG department has department library. All important text/reference books are maintained in the departmental libraries for the use of faculty and students.</p>
                            </div>
                            <div className='how-to-apply'>
                                <h5>Salient Features of the Library </h5>
                                <div class="apply-list">
                                    <ul class="pt-2">
                                        <li><i class="ri-checkbox-circle-line"></i>The Total Seating Capacity is 200 </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Digital Library with 20 Systems </li>
                                        <li><i class="ri-checkbox-circle-line"></i>E- POWER and DELNET journals online </li>
                                        <li><i class="ri-checkbox-circle-line"></i> Reference section </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Periodical section </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Standard text books and books by well known authors </li>
                                        <li><i class="ri-checkbox-circle-line"></i>SC/ST Book Bank Scheme </li>
                                        <li><i class="ri-checkbox-circle-line"></i>250 Readers Seats </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Reference and Referral Service </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Digital Library 20 Systems are available </li>
                                        <li><i class="ri-checkbox-circle-line"></i>On-Line Searching </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Document Copying and CD Writing </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='how-to-apply pt-3'>
                                <h5>Functioning of Digital Library</h5>
                                <div class="apply-list">
                                    <ul class="pt-2">
                                        <li><i class="ri-checkbox-circle-line"></i>Access the Online Journals &amp; Databases </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Access E-Books, E-Lectures &amp; Multimedia Content </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Telnet Developing Library Networking E-Journals  </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Library
