import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import PlacementsLeftTab from './PlacementsLeftTab';

const P2122 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <PlacementsLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> List of students selected for placements in the year 2021-2022

                                </h2>
                                <div>
                                <div class="table-responsive">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>S.No</strong></td>
                                                <td align="center" valign="middle"><strong>Name of the Company</strong></td>                                       
                                                {/* <td align="center" valign="middle"><strong>Branch</strong></td> */}
                                                <td align="center" valign="middle"><strong>&nbsp;Salary&nbsp; L.P.A&nbsp;</strong></td>
                                                <td align="center" valign="middle"><strong>Total</strong></td>
                                            </tr>
											<tr>
                                                <td align="center" valign="middle">1</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/dxc.png" alt="logo" class="img-responsive img-fluid" /></td>                                                
                                                
                                                <td align="center" valign="middle">4.25</td>
                                                <td align="center" valign="middle">195</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">2</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/accenture.png" alt="logo" class="img-responsive img-fluid" /></td>                                                                                            
                                                <td align="center" valign="middle">4.25 &amp; 6.5</td>
                                                <td align="center" valign="middle">173</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">3</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/MPHASIS.png" alt="logo" class="img-responsive img-fluid" /></td>                                                
                                               
                                                <td align="center" valign="middle">4.0</td>
                                                <td align="center" valign="middle">140</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">4</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/cg_logo.png" alt="logo" class="img-responsive img-fluid" /></td>                                             
                                              
                                                <td align="center" valign="middle">	4.0 &amp; 7.5 </td>
                                                <td align="center" valign="middle">72</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">5</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/wipro.png" alt="logo" class="img-responsive img-fluid" /></td>                                                
                                                
                                                <td align="center" valign="middle">	4.0 &amp; 6.0 </td>
                                                <td align="center" valign="middle">62</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">6</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/cts.png" alt="logo" class="img-responsive img-fluid" /></td>                                                
                                               
                                                <td align="center" valign="middle">	4.0 &amp; 7.0 </td>
                                                <td align="center" valign="middle">23</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">7</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/legato.png" alt="logo" class="img-responsive img-fluid" /></td>                                                
                                                
                                                <td align="center" valign="middle">	5.5 </td>
                                                <td align="center" valign="middle">33</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">8</td>
                                                <td align="center" valign="middle"><img src="" alt="logo" class="img-responsive img-fluid" /></td>                                                
                                             
                                                <td align="center" valign="middle">	5.0 </td>
                                                <td align="center" valign="middle">	12</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">9</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/autos.png" alt="logo" class="img-responsive img-fluid" /></td>                                                
                                                
                                                <td align="center" valign="middle">	5</td>
                                                <td align="center" valign="middle">29</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">10</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/itc-infotech.png" alt="logo" class="img-responsive img-fluid" /></td>                                     
                                               
                                                <td align="center" valign="middle">5</td>
                                                <td align="center" valign="middle">30</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">11</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/optum.png" alt="logo" class="img-responsive img-fluid" width="70" />
                                                <img src="/assets/img/s-logos/uhg.png" alt="logo" width="70" class="img-responsive d-inline" /></td>                                       
                                                
                                                <td align="center" valign="middle">7.5</td>
                                                <td align="center" valign="middle">3</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">12</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/deloitte.png" alt="logo" class="img-responsive img-fluid" /></td>                                            
                                              
                                                <td align="center" valign="middle">6</td>
                                                <td align="center" valign="middle">2</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">13</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/medtronic.png" alt="logo" class="img-responsive img-fluid" /></td>                                          
                                                
                                                <td align="center" valign="middle">7</td>
                                                <td align="center" valign="middle">8</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">14</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/amazon.png" alt="logo" class="img-responsive img-fluid" /></td>                                   
                                               
                                                <td align="center" valign="middle">4.5</td>
                                                <td align="center" valign="middle">4</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">15</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/SG.png" alt="logo" class="img-responsive img-fluid" /></td>                                         
                                               
                                                <td align="center" valign="middle">5.5</td>
                                                <td align="center" valign="middle">1</td>
                                            </tr>
											 <tr>
                                                <td align="center" valign="middle">16</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/ps.png" alt="logo" class="img-responsive img-fluid" /></td>                                         
                                               
                                                <td align="center" valign="middle">	6.5</td>
                                                <td align="center" valign="middle">4</td>
                                            </tr>
											 <tr>
                                                <td align="center" valign="middle">17</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/OPEN-TEXT.png" alt="logo" class="img-responsive img-fluid" /></td>                                         
                                               
                                                <td align="center" valign="middle">	6</td>
                                                <td align="center" valign="middle">5</td>
                                            </tr>
											<tr>
                                                <td align="center" valign="middle">18</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/hexaware.png" alt="logo" class="img-responsive img-fluid" /></td>                                         
                                               
                                                <td align="center" valign="middle">	4</td>
                                                <td align="center" valign="middle">4</td>
                                            </tr>
											<tr>
                                                <td align="center" valign="middle">19</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/dell.png" alt="logo" class="img-responsive img-fluid" /></td>                                         
                                               
                                                <td align="center" valign="middle">6</td>
                                                <td align="center" valign="middle">1</td>
                                            </tr>
											<tr>
                                                <td align="center" valign="middle">20</td>
                                                <td align="center" valign="middle"><img src="/assets/img/s-logos/sonata-software.png" alt="logo" class="img-responsive img-fluid" /></td>                                         
                                               
                                                <td align="center" valign="middle">4</td>
                                                <td align="center" valign="middle">14</td>
                                            </tr>
											
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default P2122
