import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DpeLeftTab from './DpeLeftTab';



const DpeSports = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DpeLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Sports Documents
                                </h2>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li><a href="/assets/documents/sports/Sports Commitee 2018-19.pdf" target='_blank'><i class="fa-solid fa-download"></i> Sports Commitee 2018-19 </a></li>
                                            <li><a href="/assets/documents/sports/Sports Day.pdf" target='_blank'><i class="fa-solid fa-download"></i>  Sports Day</a></li>
                                            <li><a href="/assets/documents/sports/Sports Fest.pdf" target='_blank'><i class="fa-solid fa-download"></i> Sports Fest </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default DpeSports
