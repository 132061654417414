import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import AboutLeftTab from '../About/AboutLeftTab';
import { Link } from 'react-router-dom';

const SwecsGallery = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-4 order-2 order-lg-1'>
                            <div className="additional-information">
                                <div className="list">
                                    <ul>
                                        <Link to="/news-and-events/news" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> News</li></Link>
                                        <Link to="/news-and-events/events" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> Events</li></Link>
                                        <Link to="/news-and-events/gallery" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i>Photo Gallery </li></Link>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Our Gallery
                                    </h2>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/mba-orentiation-day">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/4Oct_1__1_.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673421787198"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/mba-orentiation-day">
                                                    <h5 class="py-3">MBA Orientation Day</h5>
                                                </Link>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/education-matters-counseling">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/education-matters-counseling.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673249090179"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/education-matters-counseling">
                                                    <h5 class="py-3">Education Matters counseling</h5>
                                                </Link>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/gallery-view">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/g1sec.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673421784245"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/gallery-view">
                                                    <h5 class="py-3">Infrastructure Management</h5>
                                                </Link>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/sports-2018">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/55.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673421784596"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/sports-2018">
                                                    <h5 class="py-3">Sports Day 2018</h5>
                                                </Link>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/maithri-2018">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/g2.jpg?updatedAt=1702534348898"
                                                        alt="gallery image" width="100%" height="100%" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/maithri-2018">
                                                    <h5 class="py-3">Maithri 2018</h5>
                                                </Link>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/flashmob-2018">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/ECE_flash_mob_2018-2.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673249089756"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/flashmob-2018">
                                                    <h5 class="py-3">Flash Mob 2018</h5>
                                                </Link>

                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/workshop-and-seminar">
                                                    <img loading="lazy" class=""
                                                        src="/assets/img/gallery/others/5.jpg"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/workshop-and-seminar">
                                                    <h5 class="py-3">Workshop and Seminar</h5>
                                                </Link>

                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/farewell-2018">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/CSE_Farewell_2018.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673249090371"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/farewell-2018">
                                                    <h5 class="py-3">Farewell 2018</h5>
                                                </Link>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/isb-debate">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/Great_Britain_Debate_2017.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673249092014"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/isb-debate">
                                                    <h5 class="py-3">ISB debate 2016</h5>
                                                </Link>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/orientation-program-2016">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/Orientation_program_2016.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673249091707"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/orientation-program-2016">
                                                    <h5 class="py-3">Orientation Programme 2016</h5>
                                                </Link>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/nss-program">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/8.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673249090358"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/nss-program">
                                                    <h5 class="py-3">NSS Programme</h5>
                                                </Link>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/jntuh-principal-meet-2019">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/principal-meet.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673249092061"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/jntuh-principal-meet-2019">
                                                    <h5 class="py-3">JNTUH Principal Meet 2019</h5>
                                                </Link>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <Link to="/news-and-events/gallery/vidh-youth-2018">
                                                    <img loading="lazy" class=""
                                                        src="https://ik.imagekit.io/we26mxcya/2.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673249089539"
                                                        alt="gallery image" />
                                                </Link>
                                                <Link to="/news-and-events/gallery/vidh-youth-2018">
                                                    <h5 class="py-3">VIDH YOUTH'18</h5>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default SwecsGallery
