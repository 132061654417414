import React,{ useEffect } from 'react'
import { Link } from "react-router-dom";

const FooterTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className='academicsText text-center py-1'> <h3 className='pt-3'>EXAM CELL</h3></div>
            <div className="list">
                <ul>
                    
                    {/* <Link to="#" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Courses Offered
                    </li></Link> */}
                    <Link to="#" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Academic Calendar for B.E
                    </li></Link>
                    <Link to="/academic-calendar-mba" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Academic Calendar for MBA </li></Link>
                    <Link to="/syllabus/" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Syllabus </li></Link>
                    <Link to="/time-tables" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Time Tables </li></Link>
                    <Link to="/notifications" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Notifications </li></Link>
                    <Link to="/feedback-forms" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Feedback Forms </li></Link>
                    <Link to="/holiday-list" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Holidays List </li></Link>
                </ul>
                {/* <div className='academicsText text-center py-1'> <h3 className='pt-3'>Autonomous</h3></div>
                <ul>
                    <Link to="/autonomous-academic-calendar/" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Academic Calendar </li></Link>
                    <Link to="/autonomous-syllabus/" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Syllabus </li></Link>
                   
                    <Link to="#"><li className='text-black'><i class="fa fa-university"></i> Notifications</li></Link>
                    <Link to="#"><li className='text-black'><i class="fa fa-university"></i> Results </li></Link>
                </ul> */}
            </div>
        </div>
    )
}

export default FooterTab
