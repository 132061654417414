import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Video1 from './Sridevi Womens Engineering College (SWEC) Aerial View _ Top Womens College in Hyderabad.mp4';
import Video2 from './Sridevi Womens Engineering College Infrastructure and Facilities _ SWEC Infrastructure.mp4';

const AboutClg = () => {
  return (
    <div>
      <Navbar />
      <div className="graduate-admission pt-lg-5 pt-4 pb-70">
        <div className="container">
          <div className="row">
            <AboutLeftTab />
            <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
              <div className="how-to-apply">
                <h2>Our College
                </h2>
                <p>
                  Sridevi Women's Engineering College (SWEC) established in 2001 is an Autonomous Institution, with approval from both AICTE, New Delhi, and the Government of Telangana. Affiliated to J.N.T.U. Hyderabad, SWEC holds accreditation from NBA and proudly bears the NAAC A++ institute status. Sponsored by the Sridevi Education Society, the college places a significant emphasis on contributing to the educational needs of the state and nation, with a particular focus on advancing girl education and women empowerment. Boasting a total strength of 2900 students, SWEC identifies with the EAMCET code SDEW and the college code D2. Committed to fostering an excellent academic environment, SWEC provides students with cutting-edge learning resources to effectively pursue their career goals.
                </p>
                <div className="submit mt-5">
                  <h4><i class="fa fa-eye  text-red"></i> Vision</h4>
                  <div className="pt-2">
                    <p>To attract the finest talent by creating an atmosphere conducive to learning and to train and empower female professionals with global skills thereby assigning their legitimate place of honour in the society.

                    </p>

                  </div>

                </div>
                <div class="apply-list">
                  <h4 class=""><i class="fa fa-bullseye  text-red"></i> Mission</h4>
                  <ul className='pt-2'>
                    <li><i class="fa fa-check text-success"></i>To create a center for excellence in engineering by imparting knowledge and skills.</li>
                    <li><i class="fa fa-check text-success"></i>To facilitate women empowerment by enhancing their technical competency and intellectual capabilities.
                    </li>
                    <li><i class="fa fa-check text-success"></i>To create a conducive work environment with an equally competent and experienced team that derives strength from each other.
                    </li>
                  </ul>
                </div>
              
                <div className="submit mt-4">
                  <h4 className='pb-2'>SWEC College and Infrastructure video
                  </h4>
                  <div className="row pt-4">
                    <div className="col-lg-6 col-md-6">
                      <h6 className='pb-3'>College Aerial View video</h6>
                      {/* <iframe width="100%" className="lazy-youtube" height="300" src={Video1} ></iframe> */}
                      <video width="100%" height="300" controls="controls" type="video/mp4" autoplay muted preload="none">
                        <source src={Video1} type="video/mp4" />
                      </video>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h6 className='pb-3'>College Infrastructure video</h6>
                      {/* <iframe width="100%" height="300" className="lazy-youtube" src={Video2} ></iframe> */}
                      <video width="100%" height="300" controls="controls" type="video/mp4" autoplay muted preload="none">
                        <source src={Video2} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AboutClg
