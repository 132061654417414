import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import AboutLeftTab from '../About/AboutLeftTab';
import './news.css'

const Circulars = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> JNTUH Circulars
                                </h2>
                                <div class="cor-p4">
                                    <p><a href="/assets/documents/JNTUH Circulars/CamScanner01_17_202216.07.461.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Extended Holidays</a></p>
                                    <p><a href="/assets/documents/JNTUH Circulars/Conduct_online_classes_for_all_UG_PG_Courses_for_17_22_January_2022.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Conduct online classes for all UG PG Courses 22 January 2022</a></p>
                                    <p><a href="/assets/documents/JNTUH Circulars/ContinuationofOnlineClassesforUGPGcoursestillJanuary30th2022.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Continuation of Online Classes for UGPG courses till January 30th 2022</a></p>
                                    <p><a href="/assets/documents/JNTUH Circulars/OnlineclassesforUGIandIIyearsandOnCampusClassesfrom01.02.2022forallremainingUG,PGcourses.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Online classes for UGI &amp; IIyears &amp; On Campus Classes from 01.02.2022 for all remaining UG,PG courses</a></p>
                                    <p><a href="/assets/documents/JNTUH Circulars/PrincipalsofAffiliatedandAutonomousCollegesofJNTUHtoobservetheholidaysfrom08.01.2022to16.01.2022.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Principals of Affiliated and Autonomous Colleges of JNTUH to observe the holidays from 08.01.2022 - 16.01.2022</a></p>
                                    <p><a href="/assets/documents/JNTUH Circulars/ReschedulingofUG_PG_Pre_PhDExamsFeb_2022.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Rescheduling of UG PG Pre PhD Exams Feb 2022</a></p>
                                    <p><a href="/assets/documents/JNTUH Circulars/ToconductoncampusclassesforUGIIIyearscoursesfrom02.02.2022.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; To conduct on campus classes for UGI II years courses from 02.02.2022</a></p>


                                    <p><a href="/assets/documents/JNTUH Circulars/Commencement of I year II Sem. classes and Revised Academic Calendars of  UG and PG courses for the Academic year 2020-21..pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Commencement of I year II Semister classes and Revised Academic Calendar</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/JNTUH circular 24th March 2021.jpeg" class="text-primary" target="_blank"><i class="fa fa-image fa-lg text-red"></i>&nbsp; JNTUH circular 24th March 2021</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/JNTUH circular 23rd March 2021.jpeg" class="text-primary" target="_blank"><i class="fa fa-image fa-lg text-red"></i>&nbsp; JNTUH circular 23rd March 2021</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/Commencement ofon-campus Class work -20March 2021.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Commencement ofon-campus Class work -20March 2021</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/Attn_All_the_Principals_of_JNTUH_Commencement_of_oncampus_Classwork_from_01022021.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Attendance all the Principals of JNTUH Commencement of oncampus Classwork</a></p>
                                    <p><a href="/assets/documents/JNTUH Circulars/NEP_CIRCULAR.jpeg" class="text-primary" target="_blank"><i class="fa fa-image fa-lg text-red"></i>&nbsp; NEP CIRCULAR</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/409Corona Virus COVID-19.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Corona Virus COVID-19</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/410Permanent Affiliation Guidelines.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Permanent Affiliation Guidelines</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/411A120 Extension-1.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; A120 Extension-1</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/413Circulars 23.03.2020 .pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Circulars 23.03.2020 </a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/416Courserau2019s online Courses status.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Courserau 2019's online Courses status</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/428Cicular_Syllabi Covered_Attendance_27.04.2020.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Cicular Syllabi Covered Attendance 27.04.2020</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/432JNTUH Ciruclar on Eextension of Lockdown till 29th May 2020_07.05.2020.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; JNTUH Ciruclar on Eextension of Lockdown till 29th May 2020-07.05.2020</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/434AICTE PCI EOA details for A.Y. 2020-21.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; AICTE PCI EOA details for A.Y. 2020-21</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/438JNTUH-Estt.- No Classwork.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; JNTUH-Estt.- No Classwork</a></p>

                                    <p><a href="/assets/documents/JNTUH Circulars/Academic Calender 2020-21-21-08-2020.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; UG Academic Calender 2020-21-21-08-2020</a></p>

                                    <p><a href="/assets/documents/457Letter to Registrar, JNTU with ESDP details.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Letter to Registrar, JNTU with ESDP details</a></p>
                                    <p><a href="/assets/documents/455Ombudsman.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Ombudsman</a></p>
                                    <p><a href="/assets/documents/456GOB Nomination.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; GOB Nomination</a></p>
                                    <p><a href="/assets/documents/459Website functional.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Website functional</a></p>
                                    <p><a href="/assets/documents/de-200915-191226-15-9-2020 Letter to Principals.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Letter to Principals</a></p>
                                    <span>&nbsp;</span>
                                    <h4 className='pb-2'>AICTE Circulars</h4>
                                    <p><a href="/assets/documents/AICTE Circulars/guidelines fo VC.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Guidelines for VC</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/NoC for IC.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; NoC for IC</a></p>


                                    <p><a href="/assets/documents/AICTE Circulars/AICTE Advise due to Covid-19 Pandemic.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; AICTE Advise due to Covid-19 Pandemic</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/Atmanirbhar Bharat.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Atmanirbhar Bharat</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/Circular to All PGDM  MBA Institutions.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Circular to All PGDM  MBA Institutions</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/Notification for Ombudsperson.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Notification for Ombudsperson</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/Participartion in AICTE-NPTEL.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Participartion in AICTE-NPTEL</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/Revised Academic calendar-1-2.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Revised Academic calendar-1-2</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/Circular to All Technical Institutes, Deemed University, University Conducting Technical Courses approval by AICTE (Except PGDM &amp; PGCM).jfif" class="text-primary" target="_blank"><i class="fa fa-image fa-lg text-red"></i>&nbsp; Circular to All Technical Institutes, Deemed University, University Conducting Technical Courses approval by AICTE (Except PGDM &amp; PGCM)</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/grant-for-organising-conference-GOC FLYER.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Grant for Organising Conference Flyer</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/GOC SCHEME DOCUMENT.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Grant for Organising Conference Schema</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/SPICES FLYER.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Schema for promoting interests, Creativity and Ethics among Students(Spices)</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/RAISE-Banner.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Raise Banner</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/2020-08-20-Enterprise_EDU--GLOBAL.AllIndiaCouncilforTechnicalEducation.680541.CustomerSignedAgreement.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Institution Collaboration Agreement</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/Enviroment_Policy.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Enviroment Policy</a></p>

                                    <p><a href="/assets/documents/AICTE Circulars/Gender Equality guidelines.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Gender Equality Guidelines</a></p>

                                    <span>&nbsp;</span>

                                    <h3 className='pb-2'>UGC Circulars</h3>
                                    <p><a href="/assets/documents/UGC Circulars/3256227_Revision-in-SWAYAM.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Revision in SWAYAM</a></p>

                                    <p><a href="/assets/documents/UGC Circulars/3894760_Invite-application-Scholarships2020.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Invite application Scholarships 2020</a></p>

                                    <p><a href="/assets/documents/UGC Circulars/9037511_UGC-letter-Saksham.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; UGC letter Saksham</a></p>

                                    <span>&nbsp;</span>
                                    <h3 className='pb-2'>SWEC circulars</h3>
                                    <p><a href="/assets/documents/SWEC Circulars/Scholarship &amp; Fee Reimbursement Circular 2020-21.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Scholarship &amp; Free Reimbursement Circular 2020-21</a></p>
                                    <p>
                                        <i class="fa fa-angle-double-right fa-lg text-red"></i>&nbsp; Commemoration of 75th Year of Independence under Ek Bharat Shrestha Bharat programme:<br />&nbsp;&nbsp;Link for your reference:&nbsp;<i class="fa fa-link fa-lg text-red"></i> <a href="https://ekbharat.gov.in/pages/SentenceList" class="text-primary" target="_blank">https://ekbharat.gov.in/pages/SentenceList</a><br />
                                        &nbsp;&nbsp;Link to the website:
                                        &nbsp;<i class="fa fa-link fa-lg text-red"></i> <a href="https://ekbharat.gov.in/" class="text-primary" target="_blank">https://ekbharat.gov.in/</a>
                                    </p>
                                    <p><a href="/assets/documents/SWEC Circulars/circular-1.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Notice 1</a></p>
                                    <p><a href="/assets/documents/SWEC Circulars/circular-2.pdf" class="text-primary" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; Notice 2</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Circulars
