import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';
import GAUTAMI from './cseD/Ravi-kumar.jpg'

const CSEHod = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Computer Science and Engineering (CSE)
                                </h2>
                                <div className='pt-3'>
                                    <div class="">
                                        <h5> HOD Profile</h5>
                                        <div class="pt-3">
                                            <img src={GAUTAMI} width={100} className='img-fluid' alt='cse-hod' loading='lazy' />
                                            <p className='pt-3'><h6>Dr. A. Ravi Kumar (CSE & CSM HoD) </h6> has 19 Years of teaching experience. His areas of interest are Software Engineering, Big Data and Cloud Computing. He has published more than 40 Papers in National & International Journals. He published two books and has 3 patents. He has guided more than 40 M.Tech students. He attended various workshops & conferences and has been a convener for many workshops and National Level Technical Fests. His specialization subjects are Software Engineering, Cloud Computing, Software Process and Project Management, Data Base Management, Artificial Intelligence, Operating Systems, Distributed systems, C Programming & C++, Python & Software Requirements Engineering.
                                            </p>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div class="apply-list">
                                            <h6>Educational Qualifications</h6>
                                            <ul class="pt-2">
                                                <li><i class="fas fa-check-circle"></i> B.Tech in CSE from Karnataka University in 2001
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> M.Tech in Software Engineering from Bharath University in 2005
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> Phd: Sri Satya Sai University of Technology and Medical Sciences in 2021
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='pt-3'>
                                        <div class="apply-list">
                                            <h6>Achievements
                                            </h6>
                                            <ul class="pt-2">
                                                <li><i class="fas fa-check-circle"></i> Published more than 40 papers In National & International Journals.
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> Patents Filled: 03
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> Number of books published: 03
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> Number of book chapters published: 02
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> Attended Various Workshops & Conferences
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> Convener for Many Workshops and National Level Technical Fests.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='pt-3'>
                                        <div class="apply-list">
                                            <h6>Various roles performed throughout the career are
                                            </h6>
                                            <ul class="pt-2">
                                                <li><i class="fas fa-check-circle"></i> I/c Principal
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> HOD -CSE
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> Officer In-charge Exam branch
                                                </li>
                                                <li><i class="fas fa-check-circle"></i> M.Tech Co-Ordinator
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='pt-3'>
                                        <div class="apply-list">
                                            <h6>Hobbies</h6>
                                            <ul class="pt-2">
                                                <li><i class="fas fa-check-circle"></i>Listening to Music, Dance & Playing shuttle
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='pt-3'>
                                        <div class="apply-list">
                                            <h6>Subjects handled
                                            </h6>
                                            <ul class="pt-2">
                                                <li><i class="fas fa-check-circle"></i>1.	Software Engineering</li>
                                                <li><i class="fas fa-check-circle"></i>Cloud Computing</li>
                                                <li><i class="fas fa-check-circle"></i>	Software Process and Project Management</li>
                                                <li><i class="fas fa-check-circle"></i>	Data Base Management systems</li>
                                                <li><i class="fas fa-check-circle"></i>	Artificial Intelligence</li>
                                                <li><i class="fas fa-check-circle"></i>	Operating Systems</li>
                                                <li><i class="fas fa-check-circle"></i>	Distributed systems</li>
                                                <li><i class="fas fa-check-circle"></i>	C Programming </li>
                                                <li><i class="fas fa-check-circle"></i>	C++</li>
                                                <li><i class="fas fa-check-circle"></i>	 Python </li>
                                                <li><i class="fas fa-check-circle"></i>	Software Requirements Engineering.</li>
                                                <li><i class="fas fa-check-circle"></i>	Software Quality Assurance & Testing</li>
                                                <li><i class="fas fa-check-circle"></i>	Human Computer Interaction</li>
                                                <li><i class="fas fa-check-circle"></i>	Management Information Systems

                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default CSEHod
