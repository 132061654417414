import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'


const ServiceRules = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Service Rules

                                </h2>
                                <div class="detgails-right-content ">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/SWEC Service Rules.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> SWEC Service Rules </a>

                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ServiceRules
    ;
