import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import MbaLeftTab from './MbaLeftTab';


const MbaPoandPso = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Master of Business Administration (MBA)
                                </h2>
                                <div className='pt-3'>

                                    <div className=''>
                                        <h5>Programme Outcomes (POS)</h5>
                                        <div class="apply-list ">
                                            <ul class="pt-2">
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-I : </strong>
                                                    Apply knowledge of management theories and practices to solve business problems.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-II :  </strong>
                                                    Foster Analytical and critical thinking abilities for data-based decision making.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-III : </strong>
                                                    Ability to develop Value based Leadership ability.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-IV : </strong>
                                                    Ability to understand, analyze and communicate global, economic, legal, and ethical aspects of business.

                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-V : </strong>

                                                    Ability to lead themselves and others in the achievement of organizational goals, contributing effectively to a team environment.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default MbaPoandPso
