import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';


const EEESyllabus = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Syllabus
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr class="bg-gray">
                                                <th><strong>S.No</strong></th>
                                                <th><strong>YEAR/BRANCH</strong></th>
                                                <th><strong>LINK</strong></th>

                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>IV EEE R16</td>
                                                <td><a href="https://drive.google.com/file/d/17tt_RR2MAHu5aQSpIqHIS9Xhl00dB6vq/view?usp=sharing" class="text-black" target="_blank"> <i class="fa fa-file-pdf text-red"></i>&nbsp;View Syllabus </a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>IV EEE R16-SWAYAM COURSES</td>
                                                <td><a href="https://drive.google.com/file/d/1vfEDs7FoRkJ39ykU5tYYnEvS4z8p6Tjb/view?usp=sharing" class="text-black" target="_blank"> <i class="fa fa-file-pdf text-red"></i>&nbsp;View Syllabus </a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>R18 B.Tech EEE </td>
                                                <td><a href="/assets/documents/R18B.Tech.EEESyllabus.pdf" class="text-black" target="_blank"> <i class="fa fa-file-pdf text-red"></i>&nbsp;View Syllabus </a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEESyllabus
