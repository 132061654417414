import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AytonomousTab from './AytonomousTab';


const AutonomousSyllubus = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-5'>
                            <AytonomousTab />
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="how-to-apply">
                                <h2>Syllabus
                                </h2>
                            </div>
                            <div className='pt-2 pt-lg-3'>

                                <div class="detgails-right-content ">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/SwayamCourseslistforA.Y.2021_22forB.Tech.IIIIV_IISem.pdf"  target="_blank"><i class="fa-solid fa-file-pdf"></i>  Swayam Moocs 2021-22   </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/autonomous_syllubus/CSD I Sem Syllabus - SD-R22.pdf"  target="_blank"><i class="fa-solid fa-file-pdf"></i>   CSD I Sem Syllabus  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/autonomous_syllubus/CSE I Sem Syllabus - SD-R22.pdf"  target="_blank"><i class="fa-solid fa-file-pdf"></i>   CSE I Sem Syllabus </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/autonomous_syllubus/CSM I Sem Syllabus - SD-R22.pdf"  target="_blank"><i class="fa-solid fa-file-pdf"></i>   CSM I Sem Syllabus  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/autonomous_syllubus/ECE I Sem Syllabus - SD-R22.pdf"  target="_blank"><i class="fa-solid fa-file-pdf"></i> ECE I Sem Syllabus   </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/autonomous_syllubus/IT I Sem Syllabus - SD-R22.pdf"  target="_blank"><i class="fa-solid fa-file-pdf"></i>   IT I Sem Syllabus   </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default AutonomousSyllubus
