import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const WalkinInteriview = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>Walk-in Interview
                                    </h2>
                                    <div class="css-script-ads" >
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mb-3 mb-lg-4">
                                            <a class="spotlight" href="/assets/img/interview-news.png">
                                                <img src="/assets/img/interview-news.png" alt="Image" class="img-responsive center-block" />
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default WalkinInteriview
