import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import ActivitiesLeftTab from './ActivitiesLeftTab';

const NSS = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ActivitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply contact-and-address">
                                <h2> NSS (NATIONAL SERVICE SCHEME)
                                </h2>
                                <p>NSS is a voluntary association of young people in Colleges, Universities. The cardinal principle of the NSS program is that it is organized and teachers through their combined participation in community service get a sense of involvement in the tasks of nation building.
                                </p>
                                <p>All activities undertaken in NCC and NSS are under the able leadership of Mr. G Dorababu from S&H department.
                                </p>
                                <div>
                                    <div class="social-media ">
                                        <h3 className='pt-3'>Share on</h3>

                                        <ul className='pt-0'>
                                            <li>
                                                {/* <a href="https://www.facebook.com/sharer/sharer.php?u=http://www.srideviengg.com/&t=TITLE" target="_blank"><i class="flaticon-facebook"></i></a> */}
                                                <a href="https://www.facebook.com/sharer/sharer.php?u=URLENCODED_URL&t=TITLE"
                                                    onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                                                    target="_blank" title="Share on Facebook">
                                                    <i class="flaticon-facebook"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/share?url=URLENCODED_URL&via=TWITTER_HANDLE&text=TEXT"
                                                    onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
                                                    target="_blank" title="Share on Twitter">

                                                    <i class="flaticon-twitter"></i></a>
                                                {/* <a href="https://twitter.com/share?text=sdew&url=http://www.srideviengg.com/&hashtags=#sdew,#sale,#referral" target="_blank"><i class="flaticon-twitter"></i></a> */}
                                            </li>
                                            {/* <li>
                                                <a href="https://plus.google.com/share?url=http://www.srideviengg.com/" target="_blank"><i class="fa-brands fa-square-google-plus"></i> </a>
                                            </li> */}
                                            <li>
                                                <a href="javascript:;" target="_blank"><i class="flaticon-linkedin"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h6 className='pt-4 pb-3'>
                                            NSS Committee 2020-21
                                        </h6>
                                        <div class="table-responsive">
                                            <table class="table table-bordered mtop20">
                                                <thead class="bg-gray">
                                                    <tr>
                                                        <th><strong>S.No.</strong></th>
                                                        <th><strong>Name of the Convener/member</strong></th>
                                                        <th><strong>Designations</strong></th>
                                                        <th><strong>Nature</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Dr. Indrajeet Singh</td>
                                                        <td>Prof-ECE</td>
                                                        <td>Chiarman</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Mr. G.Dorababu</td>
                                                        <td>Asst.Prof-S&amp;H</td>
                                                        <td>Convener</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Mr. K.Sampath Kumar</td>
                                                        <td>Asst.Prof- ECE</td>
                                                        <td>Member</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Ms.V.Anusha</td>
                                                        <td>Asst.Prof- CSE</td>
                                                        <td>Member</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Mrs. G.V.Swathi</td>
                                                        <td>Asst.Prof- EEE</td>
                                                        <td>Member</td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Mrs. Nmratha</td>
                                                        <td>Asst.Prof- IT</td>
                                                        <td>Member</td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td>D.Swapna</td>
                                                        <td>Asst.Prof- S&amp;H</td>
                                                        <td>Member</td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>V.Raga Yukta</td>
                                                        <td>Student ECE</td>
                                                        <td>Student Member</td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>A.Mounica</td>
                                                        <td>Student CSE</td>
                                                        <td>Student Member</td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td>N Snehalatha</td>
                                                        <td>Student CSE</td>
                                                        <td>Student Member</td>
                                                    </tr>
                                                    <tr>
                                                        <td>11</td>
                                                        <td>K.Akshaya</td>
                                                        <td>Student IT</td>
                                                        <td>Student Member</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>V.Prathyusha</td>
                                                        <td>Student IT</td>
                                                        <td>Student Member</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="detgails-right-content mt-4">
                                            <div class="related-download bg-gray">
                                                <ul>
                                                    <li>
                                                        <a href="#" target='_blank'><i class="fa-solid fa-file-pdf"></i> List of NSS Events:
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <h6 className='pt-4 pb-3'>A.C.Y 2020-21
                                            </h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered mtop20">
                                                    <thead class="bg-gray">
                                                        <tr>
                                                            <th><strong>Name of the activity</strong></th>
                                                            <th><strong>Organizing unit/ agency/ collaborating agency</strong></th>
                                                            <th><strong>Year of the activity</strong></th>
                                                            <th><strong>No of teachers participated in such activities</strong></th>
                                                            <th><strong>No of students participated in such activities</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>AWARENESS ON KILL CANCER</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE, Hyderabad</td>
                                                            <td>2021</td>
                                                            <td>4</td>
                                                            <td>41</td>
                                                        </tr>
                                                        <tr>
                                                            <td>AWARENESS ON NOVEL CORONA VIRUS</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE, Hyderabad</td>
                                                            <td>2021</td>
                                                            <td>4</td>
                                                            <td>43</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div>
                                            <h6 className='pt-4 pb-3'>A.C.Y 2019-20
                                            </h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered mtop20">
                                                    <thead class="bg-gray">
                                                        <tr>
                                                            <th><strong>S.No.</strong></th>
                                                            <th><strong>Name of the activity</strong></th>
                                                            <th><strong>Date</strong></th>
                                                            <th><strong>No of students participated</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>SISTERS FOR A CHANGE – GIFT A HELMET</td>
                                                            <td>08-08-2019</td>
                                                            <td>540</td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>AWARENESS ON KILL CANCER</td>
                                                            <td>18-11-2019</td>
                                                            <td>100</td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>SAY ‘NO’ TO PLASTICS</td>
                                                            <td>02-01-2020</td>
                                                            <td>1080</td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>SELF DEFENCE SKILLS</td>
                                                            <td>02-01-2020</td>
                                                            <td>1080</td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>5K RUN AGAINST WORLD CANCER DAY</td>
                                                            <td>04-02-2020</td>
                                                            <td>40</td>
                                                        </tr>
                                                        <tr className='border-0'>
                                                            <td>5</td>
                                                            <td>
                                                                WATER AND SANITATION (GREEN INSTITUTIONAL MENTOR AWARD GIVEN BY MHRD)
                                                            </td>
                                                            <td>08-03-2020</td>
                                                            <td>-</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div>
                                            <h6 className='pt-4 pb-3'>A.C.Y 2018-19

                                            </h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered mtop20">
                                                    <thead class="bg-gray">
                                                        <tr>
                                                            <th><strong>Name of the activity</strong></th>
                                                            <th><strong>Organizing unit/ agency/ collaborating agency</strong></th>
                                                            <th><strong>Year of the activity</strong></th>
                                                            <th><strong>No  of teachers participated in such activities</strong></th>
                                                            <th><strong>No of students participated in such activities</strong></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>KASTURIBA GANDHI NATIONAL MEMORIAL TRUST</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE, Hyderabad</td>
                                                            <td>2018</td>
                                                            <td>6</td>
                                                            <td>60</td>
                                                        </tr>
                                                        <tr>
                                                            <td>AWARENESS ON DENGUE AND MALARI</td>
                                                            <td>GHMC.HYDERABAD</td>
                                                            <td>2018</td>
                                                            <td>3</td>
                                                            <td>ALL COLLEGE STUDENTS</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IMPORTANCE OF VOTE</td>
                                                            <td>TELANGAN ELECTION COMMISSION, HYDERABAD</td>
                                                            <td>2018</td>
                                                            <td>4</td>
                                                            <td>150</td>
                                                        </tr>
                                                        <tr>
                                                            <td>WORK SHOP ON WACHH PAKHWADA</td>
                                                            <td>JNTUH, HYDERABAD</td>
                                                            <td>2018</td>
                                                            <td>2</td>
                                                            <td>6</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div>
                                            <h6 className='pt-4 pb-3'>A.C.Y 2017-18
                                            </h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered mtop20">
                                                    <thead class="bg-gray">
                                                        <tr>
                                                            <th><strong>Name of the activity</strong></th>
                                                            <th><strong>Organizing unit/ agency/ collaborating agency</strong></th>
                                                            <th><strong>Year of the activity</strong></th>
                                                            <th><strong>No of teachers participated in such activities</strong></th>
                                                            <th><strong>No of students participated in such activities</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>BLOOD DONATION CAMP</td>
                                                            <td>THALASSEMIA &amp;SICKLE CELL SOCIETY, Hyderabad</td>
                                                            <td>2018</td>
                                                            <td>10</td>
                                                            <td>106</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SWACHH BHARAT</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE, Hyderabad</td>
                                                            <td>2017</td>
                                                            <td>12</td>
                                                            <td>113</td>
                                                        </tr>
                                                        <tr>
                                                            <td>KILL CANCER</td>
                                                            <td>INTERNATIONAL SOCIETY FOR KRISHNA CONSCIOUSNESS (ISKCON), Hyderabad</td>
                                                            <td>2018</td>
                                                            <td>6</td>
                                                            <td>68</td>
                                                        </tr>
                                                        <tr>
                                                            <td>AWARENESS ON THALASSEMIA DISEASE</td>
                                                            <td>THALASSEMIA &amp;SICKLE CELL SOCIETY, Hyderabad</td>
                                                            <td>2018</td>
                                                            <td>8</td>
                                                            <td>40</td>
                                                        </tr>
                                                        <tr>
                                                            <td>NATIONAL YOUTH WEEK</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE, Hyderabad</td>
                                                            <td>2018</td>
                                                            <td>6</td>
                                                            <td>556</td>
                                                        </tr>
                                                        <tr>
                                                            <td>BE GOOD DO GOOD</td>
                                                            <td>SAMSKRUTI FOUNDATION, Hyderabad</td>
                                                            <td>2018</td>
                                                            <td>2</td>
                                                            <td>14</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPECIAL CAMP</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE, Hyderabad</td>
                                                            <td>2018</td>
                                                            <td>2</td>
                                                            <td>36</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div>
                                            <h6 className='pt-4 pb-3'>A.C.Y 2016-17
                                            </h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered mtop20">
                                                    <thead class="bg-gray">
                                                        <tr>
                                                            <th><strong>Name of the activity</strong></th>
                                                            <th><strong>Organizing unit/ agency/ collaborating agency</strong></th>
                                                            <th><strong>Year of the activity</strong></th>
                                                            <th><strong>No  of teachers participated in such activities</strong></th>
                                                            <th><strong>No of students participated in such activities</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>HEALTH AWARENESS PROGRAMME</td>
                                                            <td>KEERTHANA FOUNDATION, Madhapur</td>
                                                            <td>2017</td>
                                                            <td>2</td>
                                                            <td>50</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SOCIAL SERVICE CHILD WELFARE</td>
                                                            <td>AMMAVADI, Kondapur</td>
                                                            <td>2017</td>
                                                            <td>2</td>
                                                            <td>50</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ENVIRONMENT ENRICHMENT</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE, Hyderabad</td>
                                                            <td>2017</td>
                                                            <td>3</td>
                                                            <td>50</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SOCIAL SERVICE</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE, Hyderabad</td>
                                                            <td>2017</td>
                                                            <td>3</td>
                                                            <td>50</td>
                                                        </tr>
                                                        <tr>
                                                            <td>RECREATION ACTIVITY </td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE, Hyderabad</td>
                                                            <td>2017</td>
                                                            <td>3</td>
                                                            <td>50</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        <div>
                                            <h6 className='pt-4 pb-3'>A.C.Y 2015-16
                                            </h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered mtop20">
                                                    <thead class="bg-gray">
                                                        <tr>
                                                            <th><strong>Name of the activity</strong></th>
                                                            <th><strong>Organizing unit/ agency/ collaborating agency</strong></th>
                                                            <th><strong>Year of the activity</strong></th>
                                                            <th><strong>No  of teachers participated in such activities</strong></th>
                                                            <th><strong>No of students participated in such activities</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>SOCIAL SERVICE -CHILD WELFARE</td>
                                                            <td>KASTURIBA GANDHI NATIONAL MEMORIAL TRUST</td>
                                                            <td>2015</td>
                                                            <td>2</td>
                                                            <td>100</td>
                                                        </tr>
                                                        <tr>
                                                            <td>WOMEN’S EMPOWERMENT</td>
                                                            <td>BRAHMA KUMARA TRUST</td>
                                                            <td>2015</td>
                                                            <td>2</td>
                                                            <td>100</td>
                                                        </tr>
                                                        <tr>
                                                            <td>HARITHAHARAM</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE</td>
                                                            <td>2015</td>
                                                            <td>2</td>
                                                            <td>30</td>
                                                        </tr>
                                                        <tr>
                                                            <td>WORLD AID’S DAY</td>
                                                            <td>STATE GOVERNMENT OF TELANGANA</td>
                                                            <td>2015</td>
                                                            <td>2</td>
                                                            <td>30</td>
                                                        </tr>
                                                        <tr>
                                                            <td>THOUGHT TRANSFORMATION</td>
                                                            <td>DIVINE INDIA YOUTH ASSOCIATION</td>
                                                            <td>2015</td>
                                                            <td>2</td>
                                                            <td>18</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IMPORTANCE OF VOTE</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE</td>
                                                            <td>2016</td>
                                                            <td>4</td>
                                                            <td>200</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SPECIAL CAMP</td>
                                                            <td>SRIDEVI WOMEN’S ENGINEERING COLLEGE</td>
                                                            <td>2016</td>
                                                            <td>3</td>
                                                            <td>100</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default NSS
