import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';
import one from './library/1.jpg'
import two from './library/2.jpg'
import three from './library/3.jpg'





const EceDepartmentLibrary = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Library
                                </h2>
                                <p>Department library of Electronics and Communication Engineering has a wide collection of text books, journals and other resources to help the staff and students in teaching, learning and innovative practices. Over 430 text books of national and international authors in the major areas of Analog and Digital Electronics, Signal Processing, Communication Engineering, VLSI and Embedded systems, Power Electronics, Microprocessor and Microcontrollers, Applied Electronics, Electrical Technology, Electronic Instrumentation etc are available in our library. The department collection is enriched by the Manuals and CD’s apart from regular books. The library has seminar reports and project reports for reference purpose. The library is situated in the first floor of main block and is accessible to staff and students from during college working hours.
                                </p>
                                <div >
                                    <div className='pt-3 row'>
                                        <div className='col-md-6 pb-3'>
                                            <img src={three} alt='library' loading='lazy' className='img-fluid'/>
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={two} alt='library' loading='lazy' className='img-fluid'/>
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={one} alt='library' loading='lazy' className='img-fluid'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EceDepartmentLibrary
