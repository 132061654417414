import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import OneImg from '../images/1.png'
import TwoImg from '../images/2.jpg'
import ThreeImg from '../images/3.jpg'
import FourImg from '../images/4.jpg'
import FiveImg from '../images/5.jpg'
import SixImg from '../images/6.jpg'
import DpeLeftTab from '../DPE/DpeLeftTab';


const DPE = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DpeLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>
                                    Department of Physical Education
                                </h2>
                                <p>
                                    The Director of Physical Education, <strong>Mr. Saibaba</strong> is the chief organizer with more than 20 years of experience in various organizations. The Director of Physical Education of the sports event conducts and supervises various events throughout the year with the support of department faculty sports coordinators.
                                </p>
                                <p>Under his guidance and training, we have two teams each for Kabaddi, Throwball, Volley ball, Kho-Kho, Tennicoit, Chess, Carrom, Table tennis, Badminton and Basketball. Our college sports team participate in all events and have won several trophies in the inter college competitions. We also encourage our faculty to participate in various sports and inter college faculty competitions.
                                </p>
                                <p><strong>SRIDEVI WOMEN'S ENGINEERING COLLEGE</strong> has constituted a Sports Council, a body to facilitate games and sports activities among students. The Sports Council of SRIDEVI WOMEN'S ENGINEERING COLLEGE consists of the Vice Chairman; the Principal, Vice Principal, HODs and the Director of Physical Education as its members and it lays down the policies governing all the extra-curricular activities. The Director of Physical Education as the chief organizer with 15years of experience in various organizations and being a sports selection committee member for women in JNTUH. The director of Physical Education of the sports event conducts and supervises them throughout the year with the support of department faculty sports coordinators.
                                </p>

                                <h5 className='py-3'>Recent Sports competitions
                                </h5>

                                <h6 className='py-2'>Vigynan College
                                </h6>
                                <table className='mt-3'>
                                    <thead>
                                        <tr>
                                            <th>Sport</th>
                                            <th>Position</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Kho Kho</td>
                                            <td>Winners
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Throwball	</td>
                                            <td>Winners
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Kabaddi</td>
                                            <td>Runners Up
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className='py-2 mt-4'>Thirumala College
                                </h6>
                                <table className='mt-3'>
                                    <thead>
                                        <tr>
                                            <th>Sport</th>
                                            <th>Position</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Kho Kho</td>
                                            <td>Winners
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Throwball	</td>
                                            <td>Winners
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h6 className='py-2 mt-4'>Narayanamma  College
                                </h6>
                                <table className='mt-3'>
                                    <thead>
                                        <tr>
                                            <th>Sport</th>
                                            <th>Position</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Kho Kho</td>
                                            <td>Winners
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Throwball	</td>
                                            <td>Runners Up
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className='py-4 mt-2'>In addition to the Sports activities she also coordinates and guides the NCC and NSS students. Cadet Kaktam Saisiri won the ALL INDIA BEST CADET & BEST FIRER AP & Telangana and Cadet Ragini Sharma received the BEST COXSWAIN of AP and T at the special National Integration camp.
                                </p>

                                <div className='py-4'>
                                    <div className='row'>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img className='img-fluid' src={OneImg} alt='players' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img className='img-fluid' src={TwoImg} alt='players' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img className='img-fluid' src={ThreeImg} alt='players' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img className='img-fluid' src={FourImg} alt='players' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img className='img-fluid' src={FiveImg} alt='players' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img className='img-fluid' src={SixImg} alt='players' />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )

}

export default DPE
