import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';
import one from './sdp/1.jpeg'
import two from './sdp/2.png'
import three from './sdp/3.jpg'
import four from './sdp/4.jpg'
import five from './sdp/5.png'

const EEESDP = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Student Development Program (SDP)
                                </h2>
                                <div className='apply-list'>
                                    <ul class="list">
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The department of EEE in association with MEPAC3 Engineering solutions Pvt. Ltd conducted
                                            "One day work shop on PLC" on 2nd march 2020 for II B. Tech. II-semester students.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The department of EEE in association with Pantech Proed Private Ltd conducted “Three day
                                            workshop on power electronics application in Renewable energy sources by using MATLAB and
                                            IoT from 12th March 2020 to 14th March 2020 for III B.Tech. II-semester students.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The department of EEE Conducted A National level series of Webinars from 1st July 2020 to 7th
                                            July 2020.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The department of EEE in association with Pantech E Learning conducted  webinar on Latest
                                            Trends in green energy technologies on 2nd July 2020.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The department of EEE Conducted A National level Webinar on Integral Transforms for Electrical
                                            and Electronics Engineering on 19th July 2020.
                                        </li>
                                    </ul>
                                </div>

                                <h6 className='pt-3 pb-2'>Webinar
                                </h6>
                                <div className='row'>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={one} alt='Webinar-img' loading='lazy' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={two} alt='Webinar-img' loading='lazy' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={three} alt='Webinar-img' loading='lazy' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={four} alt='Webinar-img' loading='lazy' className='img-fluid' />
                                    </div>
                                    <div className='col-md-6 col-lg-4 pb-3'>
                                        <img src={five} alt='Webinar-img' loading='lazy' className='img-fluid' />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEESDP
