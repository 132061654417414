import React from 'react'
import { Link } from "react-router-dom";


const AytonomousTab = () => {
    return (
        <div className="additional-information">

            <div className='academicsText text-center py-1'> <h3 className='pt-3'>Autonomous</h3></div>
            <div className="list">

                <ul>
                    <Link to="/autonomous-academic-calendar/"><li className='text-black'><i class="fa fa-university"></i> Academic Calendar </li></Link>
                    <Link to="/academics-calendar/autonomous-syllabus/"><li className='text-black'><i class="fa fa-university"></i> Syllabus </li></Link>
                    <Link to="#"><li className='text-black'><i class="fa fa-university"></i> Notifications</li></Link>
                    <a href="https://erp.swec.ac.in/" target='_blank'><li className='text-black'><i class="fa fa-university"></i> Results </li></a>
                </ul>
                <div className='academicsText text-center py-1'> <h3 className='pt-3'>JNTUH</h3></div>
                <ul>
                    <Link to="/academics-calendar/"><li className='text-black'><i class="fa fa-university"></i> Academic Calendar for B-Tech
                    </li></Link>

                    <Link to="/academic-calendar-mba/"><li className='text-black'><i class="fa fa-university"></i> Academic Calendar for MBA </li></Link>
                    <Link to="/syllabus/"><li className='text-black'><i class="fa fa-university"></i> Syllabus </li></Link>
                    <Link to="/time-tables/"><li className='text-black'><i class="fa fa-university"></i> Time Tables </li></Link>
                    <Link to="/notifications/"><li className='text-black'><i class="fa fa-university"></i> Notifications </li></Link>
                    <Link to="/feedback-forms/"><li className='text-black'><i class="fa fa-university"></i> Feedback Forms </li></Link>
                    <Link to="/holiday-list/"><li className='text-black'><i class="fa fa-university"></i> Holidays List </li></Link>
                </ul>

            </div>
        </div>
    )
}


export default AytonomousTab
