import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import JntuhLefttab from './JntuhLefttab';

const Academics = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-5'>
                            <JntuhLefttab />
                        </div>
                        <div className="col-lg-8">
                            <div>
                                <h2>Courses Offered
                                </h2>
                            </div>
                            <div className="pt-3">
                                <p>The students seeking admission to SWEC have to qualify in the EAMCET ( Engineering,
                                    Agricultural and Medical Common Entrance Test ) examination conducted by Govt. of
                                    Andhra Pradesh. To be eligible for appearing in the EAMCET examination, the student
                                    should have passed the 10 + 2 with Maths, Physics and Chemistry as their mainstream.
                                </p>
                            </div>
                            <div className="pt-3">
                                <h5>Academic of SWEC</h5>
                                <p>The students seeking admission to SWEC have to qualify in the EAMCET ( Engineering,
                                    Agricultural and Medical Common Entrance Test ) examination conducted by Govt. of
                                    Andhra Pradesh. To be eligible for appearing in the EAMCET examination, the student
                                    should have passed the 10 + 2 with Maths, Physics and Chemistry as their mainstream.
                                </p>
                            </div>
                            <div className="pt-3">
                                <h5>Total Intake</h5>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-info">
                                        <thead>
                                            <tr className="bg-gray">
                                                <th><strong>Department</strong></th>
                                                <th><strong>Intake Number</strong></th>
                                                <th><strong>No.of Sections</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>CSE</td>
                                                <td>240</td>
                                                <td>4</td>
                                            </tr>
                                            <tr>
                                                <td>ECE</td>
                                                <td>240</td>
                                                <td>4</td>
                                            </tr>
                                            <tr>
                                                <td>EEE</td>
                                                <td>60</td>
                                                <td>1</td>
                                            </tr>
                                            <tr>
                                                <td>IT</td>
                                                <td>120</td>
                                                <td>1</td>
                                            </tr>
                                            <tr>
                                                <td>MBA</td>
                                                <td>60</td>
                                                <td>1</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Academics
