import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import ActivitiesLeftTab from './ActivitiesLeftTab';

const NCC = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ActivitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> NCC (National Cadet Corps)
                                </h2>
                                <p>The National Cadet Corps is established in 2013 and cadets actively participate in different community service activities. The students are encouraged to join in NCC from first year onwards. The selection process is done by Hyderabad NCC unit. Rigorous training has been provided throughout the year and successful students are selected for national level programs such Republic day parade</p>

                            </div>


                            <div class="detgails-right-content mt-4">
                                
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="#" target='_blank'><i class="fa-solid fa-file-pdf"></i> NCC </a>
                                        </li>
                                        <li>
                                            <a href="#" target='_blank'><i class="fa-solid fa-file-pdf"></i> NSS </a>
                                        </li>
                                        <li>
                                            <a href="#" target='_blank'><i class="fa-solid fa-file-pdf"></i> SPORTS (EVENTS) </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default NCC
