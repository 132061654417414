import React from 'react'

import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import IQACLeftTab from './IQACLeftTab';


const IQAC = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <IQACLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <h2>IQAC</h2>
                            <div class="detgails-right-content mt-4">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="/assets/documents/IQAC/19-20 circular IQAC.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> 2019-2020 I Sem IQAC circular  </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/IQAC/IQAC annual report.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IQAC Circular  </a>
                                        </li>
                                        <li>
                                            <a href="documents/IQAC/IQAC circular.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IQAC MOM  </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/IQAC/IQAC Final MOM.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> IQAC Annual Report  </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/IQAC/IIQA.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  IIQA </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/IQAC/NAAC_SSR 2021.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> NAAC SSR 2021  </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/IQAC/NAAC SSR DVV 2021.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> NAAC SSR DVV 2021 </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <h2 className='pt-lg-4'>Student Satisfaction Survey
                            </h2>
                            <div class="detgails-right-content mt-4">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSebbIotuUxAumc4KZS9zWk_rV4H6Nmzewz_D9x5ec9DI9KGJA/viewform?vc=0&c=0&w=1&flr=" target="_blank"><i class="fa-solid fa-file-pdf"></i> 2020-21 </a>
                                        </li>
                                        <li>
                                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd2XVv7ekYGg_KJp5jvmZdjL0lmIIp0MR-E3akKmwnVJWyJsQ/viewform?vc=0&c=0&w=1&flr=0" target="_blank"><i class="fa-solid fa-file-pdf"></i> 2019-20  </a>
                                        </li>
                                        <li>
                                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfseCnhWSGRxsZF861iDtIa2eAhqnvHWii5DCSRTELzN7fyhg/viewform?vc=0&c=0&w=1&flr=0" target="_blank"><i class="fa-solid fa-file-pdf"></i> 2018-19  </a>
                                        </li>
                                      
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default IQAC



