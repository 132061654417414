import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const ICDML2020 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> ICDML2020
                                    </h2>
                                    <p className='py-2'>
                                        SWEC is organizing a two day AICTE sponsored International Conference on Data Science, Machine Learning and its Applications on October 9th and 10th,2020.
                                    </p>
                                    <div class="detgails-right-content my-3">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li>
                                                    <a href="/assets/documents/ICDML Brochure.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> ICDML Brochure </a>
                                                </li>
                                                <li>
                                                    <a href="/assets/documents/ICDML Poster.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> ICDML Brochure </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">

                                        <div class="row ">
                                            <div className='col-12 mt-3 mb-3'>
                                                <h6>Inaugural</h6>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/inaugural/ICDML Inauguration 9th October.jpg">
                                                    <img src="/assets/img/icdml2020/inaugural/ICDML Inauguration 9th October.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div className='col-12 mt-3 mb-3'>
                                                <h6>Principal Address</h6>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Principal Address/ICDML Inauguration Principal Address.jpg">
                                                    <img src="/assets/img/icdml2020/Principal Address/ICDML Inauguration Principal Address.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Principal Address/ICDML2020 Chief Guest Dr.Kim Address.jpg">
                                                    <img src="/assets/img/icdml2020/Principal Address/ICDML2020 Chief Guest Dr.Kim Address.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div className='col-12 mt-3 mb-3'>
                                                <h6>Book release</h6>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Book release/ICDML2020 Proceedings Book Release.jpg">
                                                    <img src="/assets/img/icdml2020/Book release/ICDML2020 Proceedings Book Release.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Book release/ICDML2020 Proceedings Online Book Release.jpg">
                                                    <img src="/assets/img/icdml2020/Book release/ICDML2020 Proceedings Online Book Release.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div className='col-12 mt-3 mb-3'>
                                                <h6>Vote of thanks</h6>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Vote of thanks/ICDML2020 Co-coordinator Vote of Thanks 9th October.jpg">
                                                    <img src="/assets/img/icdml2020/Vote of thanks/ICDML2020 Co-coordinator Vote of Thanks 9th October.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div className='col-12 mt-3 mb-3'>
                                                <h6>Anchors</h6>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Anchors/Anchors Day1 - AN session.jpg">
                                                    <img src="/assets/img/icdml2020/Anchors/Anchors Day1 - AN session.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Anchors/Anchors Day1 - FN session.jpg">
                                                    <img src="/assets/img/icdml2020/Anchors/Anchors Day1 - FN session.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Anchors/Anchors Day2 - AN session.jpg">
                                                    <img src="/assets/img/icdml2020/Anchors/Anchors Day2 - AN session.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Anchors/Anchors Day2 - FN session.jpg">
                                                    <img src="/assets/img/icdml2020/Anchors/Anchors Day2 - FN session.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div className='col-12 mt-3 mb-3'>
                                                <h6>Team photo</h6>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/icdml2020/Team photo/ICDML2020 Team Photo.jpg">
                                                    <img src="/assets/img/icdml2020/Team photo/ICDML2020 Team Photo.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ICDML2020
