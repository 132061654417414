import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const MOUwithTexasReview = () => {
  return (
    <div>
      <Navbar />
      <div className="graduate-admission pt-lg-5 pt-4 pb-70">
        <div className="container">
          <div className="row">
            <AboutLeftTab />

            <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
              <div className="col-lg-12 ps-lg-4">
                <div className="how-to-apply">
                  <h2> MOU with Texas Review
                  </h2>
                  <div className='py-2'>
                    <p>SWEC has signed a MOU with Texas Review that is known for providing good counselling and training for students aspiring to pursue their higher education after graduation.</p>
                  </div>
                  <div class="css-script-ads" >
                  </div>
                  <div class="spotlight-group">
                    <div class="row">
                      <div class="col-md-6 mb-3 mb-lg-4">
                        <a class="spotlight" href="/assets/img/Texas Review MOU pic.jpg">
                          <img src="/assets/img/Texas Review MOU pic.jpg" alt="Image" class="img-responsive center-block" />
                        </a>
                      </div>
                      <div class="detgails-right-content mt-4">
                        <div class="related-download bg-gray">
                          <ul>
                            <li>
                              <a href="/assets/documents/TexasREview MOU2020-2023.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Texas Review MOU 2020-2023 </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default MOUwithTexasReview
