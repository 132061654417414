import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import DepartmentTab from './CSEDepartmentTab';


const CSE = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                         <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Computer Science and Engineering (CSE)
                                </h2>
                                <div className='pt-3 submit'>
                                    <p>The Department of Computer Science and Engineering was established in 2001 and is growing in strength and facilities year after year. The Department offers a four-year course of Bachelor’s Degree in Computer Science and Engineering with a sanctioned intake of 240.</p>
                                    <p>The Department of CSE is headed by Dr. A. Ravi Kumar. The department has highly qualified, well-experienced & dedicated faculty and non-teaching staff. The Department is known for its faculty having expertise in diverse fields. The Department has good infrastructure with spacious classrooms, well equipped fourteen computer labs along with one industry supported lab with 600 computers, All the students and staff are provided with a high-speed Internet facility of 10Mbps, seminar hall, staff rooms with individual staff cabins, HOD chamber, department library, centralized internet Centre and R & D Centre.</p>
                                    <p>The Department provides world-class training and research opportunities. The Department provides state of the art computing facilities to the students. It also promotes active industry-institute collaboration by identifying areas of interest and taking part in sponsored research projects and consultancy services. The Department regularly organizes a series of lectures by academicians and professionals of the highest repute, which lay stress on the latest innovative technologies in the field of Computer Science and Engineering.

                                    </p>
                                    <p>The Department prides itself on providing good career opportunities for students. Their sincere efforts and diversity of knowledge in fast pace of technology has spawned a plethora of exciting jobs. Students of CSE Department are placed in Top MNCs like IBM, Tech Mahindra, Infosys, Wipro, Microsoft, Oracle, HSBC, HCL, Syntel , Capgemini, DxC, and so on. On account of improved infrastructure & facilities in the Department and praiseworthy performance of our passed out students in the industrial organizations, many companies of repute show their interest to visit our Institute for campus recruitment.

                                    </p>
                                    <p>Department encourages students and staff to present and publish the papers, research articles in reputed international / national conferences and journals. Students are encouraged to participate in Co-Curricular Activities like Paper Presentations, Poster Presentations, Quiz Competitions, Project Expos, National Level Symposiums, Internship Programs along with Extra Curricular Activities like Kabbadi, Kho-Kho, Volley Ball, Through Ball etc and other cultural activities in inter-state and intra-state engineering colleges. Students are also encouraged to participate in social activities like NCC (National Cadet Corp) and NSS ( National Service Scheme) like Village Campaigns, Blood Donation Camps and Environmental Awareness Programs. Staff also work towards publishing Patents, Book, Book Chapters etc.

                                    </p>


                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CSE;
