import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';

const SportsNews = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>Fest - SriSamvidhan
                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <h6 className='py-2'>Faculty Throwball Runners Up at Narayanamma college 2018</h6>
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4 row">
                                                <img src="/assets/img/Faculty Throwball runners up at Narayanamma college 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <img src="/assets/img/Throwball Runners Up at Narayanamma college1 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>

                                            <h6 class="py-3">Sports News</h6>
                                            <div class="col-md-6 mb-3 mb-lg-4 row">
                                                <img src="/assets/img/Sports News - Kho-Kho Winners at Vignan Women's Engg College 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 row">
                                                <img src="/assets/img/Sports News - Runners Up at Vignan Women's Engg College 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>

                                            <h6 class="py-3">Sports at Vigynan College</h6>
                                            <div class="col-md-6 mb-3 mb-lg-4 row">
                                                <img src="/assets/img/Kabbadi Runners Up at Vignan Women's Engg college 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 row">
                                                <img src="/assets/img/Throwball winners at Vignan Women's Engg college 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 row mtop20">
                                                <img src="/assets/img/Kho-kho team.jpg" width="" alt="image" class="img-responsive center-block" />
                                            </div>

                                            <h6 class="py-3">Sports at Thirumala College</h6>
                                            <div class="col-md-6 mb-3 mb-lg-4 row">
                                                <img src="/assets/img/Kho-Kho Winners at Thirumala Engg College 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 row">
                                                <img src="/assets/img/Throwball Winners at Thirumala Engg College 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>

                                            <h6 class="py-3">Kho-Kho team runners up at Narayanamma College</h6>
                                            <div class="col-md-6 mb-3 mb-lg-4 row">
                                                <img src="/assets/img/Kho Kho runners up at Narayanamma College 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 row">
                                                <img src="/assets/img/Kho-Kho team runners up at Narayanamma college 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4 row mtop20">
                                                <img src="/assets/img/Throwball Runners Up at Narayanamma college 2018.jpg" width="" alt="image"
                                                    class="img-responsive center-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default SportsNews
