import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const STTPonBlockchain = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> STTP on Blockchain Technology and its Applications
                                    </h2>
                                    <div className='py-2'>
                                        <p>CSE department inaugurated the AICTE sponsored online "Short Term Training Program on Blockchain Technology and its Applications" on 3rd Dec 2020 at 10:30am. The STTP convenor and HOD CSE department Dr. Gauthami Latha and Professor & Coordinator Dr. Anantha Krishna welcomed the Chief Guest of the event Dr B Vishnu Vardhan, JNTUH, Manthani and Guest of Honour Dr. M Subrahmanya Sarma , Senior Manager, Security IBM . Dr. B.L.Malleswari, Principal, SWEC also addressed the online participants and welcomed them to the STTP.</p>
                                        <p>The 5 day event will witness eminent speakers who are experts in blockchain technology and would share their knowledge and experience with the faculty.</p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/cse/workshop/19.png">
                                                    <img src="/assets/img/cse/workshop/19.png" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/cse/workshop/20.png">
                                                    <img src="/assets/img/cse/workshop/20.png" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                           
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/cse/workshop/STTP Poster CSE Dept 2020.jpg">
                                                    <img src="/assets/img/cse/workshop/STTP Poster CSE Dept 2020.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/cse/workshop/sakshi-news.jpg">
                                                    <img src="/assets/img/cse/workshop/sakshi-news.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/cse/workshop/21.png">
                                                    <img src="/assets/img/cse/workshop/21.png" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default STTPonBlockchain
