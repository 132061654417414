import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'


const Disclosure= () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Mandatory Disclosure
                                </h2>
                                <div className='pt-3'>
                                   
                                    {/* <p><a href="/assets/documents/Mandatory disclosures.pdf" class="text-black disabled" target="_blank"><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; <strong>  Mandatory Disclosure Document</strong></a></p> */}

                                    <p><a href="javascript:;" class="text-black disabled" aria-disabled><i class="fa-solid fa-file-pdf text-red"></i>&nbsp; <strong>  Mandatory Disclosure Document</strong></a></p>

                                </div>
                                <div className="apply-list">
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Disclosure

