import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';
import one from './MAGAZINES/13.jpg'
import two from './MAGAZINES/14.jpg'
import three from './MAGAZINES/15.jpg'
import four from './MAGAZINES/16.jpg'
import five from './MAGAZINES/9.jpg'
import six from './MAGAZINES/10.jpg'
import seven from './MAGAZINES/11.jpg'
import eight from './MAGAZINES/12.jpg'
import nine from './MAGAZINES/5.jpg'
import ten from './MAGAZINES/6.jpg'
import eleven from './MAGAZINES/7.jpg'
import twelve from './MAGAZINES/8.jpg'
import thirteen from './MAGAZINES/1.jpg'
import fourteen from './MAGAZINES/2.jpg'
import fivteen from './MAGAZINES/3.jpg'
import sixteen from './MAGAZINES/4.jpg'


const EceMagazines = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Magazines & Newsletters
                                </h2>
                                <div class="faq-left-content pl-20">

                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    NewsLetter 2018-2019
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div className='row'>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={one} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={two} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={three} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={four} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    NewsLetter 2017-2018
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div className='row'>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={five} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={six} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={seven} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={eight} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    NewsLetter 2016 - 2017
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div className='row'>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={nine} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={ten} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={eleven} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={twelve} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    NewsLetter 2015 - 2016
                                                </button>
                                            </h2>
                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div className='row'>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={thirteen} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={fourteen} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={fivteen} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                        <div className='col-md-6 pb-3'>
                                                            <img src={sixteen} className='img-fluid' alt='magazines' loading='lazy' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='pt-3 submit'>
                                    <div class="detgails-right-content mt-4">
                                        <h6>Magzines</h6>
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="/assets/documents/ece/2020-2021 ECE magzine.pdf"  target='_blank'><i class="fa-solid fa-file-pdf"></i>  2020-2021 ECE magzine  </a></li>
                                                <li><a href="/assets/documents/ece/2019-2020 ECE magzine.pdf"  target='_blank'><i class="fa-solid fa-file-pdf"></i>   2020-2021 ECE magzine </a></li>
                                                <li><a href="/assets/documents/ece/2018-2019 ECE magzine.pdf"  target='_blank'><i class="fa-solid fa-file-pdf"></i>   2020-2021 ECE magzine </a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className=''>
                                    <div class="detgails-right-content mt-4">
                                        <h6>News letter</h6>
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="/assets/documents/ece/News letter 2020-2021 m.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>   News letter 2020-2021  </a></li>
                                                <li><a href="/assets/documents/ece/News letter 2019-2020 m.pdf"  target='_blank'><i class="fa-solid fa-file-pdf"></i>   News letter 2019-2020 </a></li>
                                                <li><a href="/assets/documents/ece/News letter 2018-2019 m.pdf"  target='_blank'><i class="fa-solid fa-file-pdf"></i>     News letter 2018-2019  </a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}



export default EceMagazines
