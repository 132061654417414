import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import JntuhLefttab from './JntuhLefttab';

const AcaMba = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-5'>
                            <JntuhLefttab />
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="how-to-apply">
                                <h2> Academic Calendar for MBA
                                </h2>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for MBA 2023-24
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="https://ik.imagekit.io/ckjdgz4vmo/MBA%20I%20Academic%20Calendar%202023-24.pdf?updatedAt=1697606819313" target='_blank'><i class="fa-solid fa-file-pdf"></i> Academic Calendar for MBA  I year</a>
                                            </li>
                                            <li>
                                                <a href="https://ik.imagekit.io/ckjdgz4vmo/mba2.pdf?updatedAt=1696932483317" target='_blank'><i class="fa-solid fa-file-pdf"></i> Academic Calendar for MBA  II year</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for MBA 2021-22
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/ACADEMIC_CALENDAR_2021-22_FOR_MBA-MCA_I_YEAR_I_II_SEMESTERS.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> Academic Calendar for MBA I and II years</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for MBA 2019-20
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/Revised_Academic_Calendar_2019-20forMBAIIIYears.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>   Academic Calendar for MBA I and II years</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for MBA 2017-18
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="http://jntuh.ac.in/uploads/calendars/2017-18_Academic_Calendar_for_MBA_I_and_II_years.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  Academic Calendar for MBA I and II years</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default AcaMba
