import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const IDEATHON2k23 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> IDEATHON 2k23
                                    </h2>
                                    <p className='pb-3'>The IT department is coordinating the IDEATHON 2k23 event at SWEC that received 450+ registrations from first to final year students who are gearing up for the event scheduled on March 23 and 24th</p>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/ideathon-2023/1.jpg">
                                                    <img src="/assets/img/ideathon-2023/1.jpg" alt="Image" loading="lazy" class="img-responsive center-block"  />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/ideathon-2023/2.jpg">
                                                    <img src="/assets/img/ideathon-2023/2.jpg" alt="Image" loading="lazy" class="img-responsive center-block"  />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/ideathon-2023/3.jpg">
                                                    <img src="/assets/img/ideathon-2023/3.jpg" alt="Image" loading="lazy" class="img-responsive center-block"  />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/ideathon-2023/4.jpg">
                                                    <img src="/assets/img/ideathon-2023/4.jpg" alt="Image" loading="lazy" class="img-responsive center-block"  />
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default IDEATHON2k23
