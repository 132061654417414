import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";


const AboutLeftTab = () => {
    const [activeLink, setActiveLink] = useState('home');

    const handleLinkClick = (link) => {
        setActiveLink(link);
    }
    useEffect(() => {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="col-lg-4 pe-lg-4  order-xl-1 order-lg-1 order-md-1 order-sm-2 order-2">
            <div className="additional-information">
                <div className="list">
                    <ul>
                        <li onClick={() => handleLinkClick('about-college')} className={activeLink === 'about-college' ? 'active' : 'text-dark'}><Link to="/about/about-college"><i class="fa fa-university"></i> About Us</Link></li>
                        <li onClick={() => handleLinkClick('management')} className={activeLink === 'management' ? 'active' : 'text-black'}> <Link to="/about/management"><i class="fa fa-users"></i> Management</Link></li>
                        <li onClick={() => handleLinkClick('recognitions')} className={activeLink === 'recognitions' ? 'active' : 'text-black'}><Link to="/about/recognitions"><i class="fa fa-university"></i> SWEC Recognitions</Link></li>
                        <li onClick={() => handleLinkClick('governing-body')} className={activeLink === 'governing-body' ? 'active' : 'text-black'}> <Link to="/about/governing-body"><i class="fa fa-university"></i> Governing Body</Link></li>
                        <Link to="/about/principal" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> Principal</li></Link>
                        <li onClick={() => handleLinkClick('dean-academics')} className={activeLink === 'dean-academics' ? 'active' : 'text-black'}> <Link to="/about/dean-academics"><i class="fa fa-university"></i> Dean Academics</Link></li>
                        <li onClick={() => handleLinkClick('dean-academics')} className={activeLink === 'dean-admissions' ? 'active' : 'text-black'}> <Link to="/about/dean-admissions"><i class="fa fa-university"></i> Dean Admissions</Link></li>
                        <li onClick={() => handleLinkClick('board-of-studies')} className={activeLink === 'board-of-studies' ? 'active' : 'text-black'}><Link to="/about/committee"><i class="fa fa-users"></i> College Committees</Link></li>
                        <li onClick={() => handleLinkClick('committee')} className={activeLink === 'committees' ? 'active' : 'text-black'}><Link to="/about/board-of-studies"><i class="fa fa-users"></i> Board of Studies (BOS)</Link></li>
                        <li onClick={() => handleLinkClick('service-rules')} className={activeLink === 'service-rules' ? 'active' : 'text-black'}><Link to="/about/service-rules"><i class="fa fa-users"></i> Service Rules</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AboutLeftTab
