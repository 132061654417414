import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import Principal from '../About/images/Dr. K Sivanagi Reddy - Dean Academics.jpeg'

const DeanAcademics = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-xl-2 order-lg-2 order-md-2 order-sm-1 order-1">
                            <div className="how-to-apply">
                                <h2>Dean Academics</h2>
                                <p><img className='pb-3 me-4 float-start' src={Principal} alt='principal' width="150" loading='lazy' />
                                <strong>Dr. SivaNagiReddy Kalli</strong> is a distinguished academician and researcher with a rich educational background and extensive experience in the field of Electronics and Communication Engineering. He completed his Bachelor of Engineering (B.E) degree from Bharathidasan University, Trichy, India, demonstrating his commitment to excellence from the onset of his academic journey. Subsequently, he completed his Master of Technology (M.Tech) degree from JNTUK Kakinada, India, further honing his expertise in the domain. His academic pursuit culminated in the attainment of a Ph.D. from Jawaharlal Nehru Technological University, Hyderabad, India, showcasing his dedication to advancing knowledge and research in his field.</p>
                                <p>Currently, Dr. Kalli holds the esteemed position of Dean Academics and Professor in the Department of Electronics and Communication Engineering at our Esteemed Organisation. With a career experience spanning of 20 years, he has exhibited exemplary contributions in education, research, and administration at various hierarchical levels, thereby leaving a lasting impact on the academic landscape.
                                </p>
                                <p>Dr. Kalli's scholarly achievements extend beyond the confines of academia, with notable accomplishments in patenting and publication. He has secured 7 Patent grants including published patents, reflecting his innovative spirit and commitment to practical applications of his research. Moreover, his scholarly contributions include over 50 articles published in esteemed journals such as IEEE, SCI, Scopus, and WoS, as well as 14 presentations at prestigious International conferences. His role as a reviewer for various leading journals underscores his commitment to maintaining academic rigor and standards in the peer-review process.</p>
                                <p>In addition to his research contributions, Dr. Kalli is also an accomplished author, having authored four books on Contemporary Models of Wireless Communications and Networks, Wireless Communications and Networks, Digital Design Through Verilog, and Verilog: Digital VLSI. These publications serve as valuable resources for students, researchers, and professionals seeking insights into the latest developments in the field of  Artificial intelligence, Machine Learning and Electronics & Communication.</p>
                                <p>Recognized for his exceptional contributions, Dr. Kalli was honored with the Faculty Excellency Award in 2021 and 2022 testament to his outstanding achievements and dedication to his craft. Beyond his academic pursuits, he remains actively engaged in professional societies, holding life memberships in esteemed organizations such as IEEE, Asian Council of Science Editors, IAOE, and IAENG. His involvement in these societies reflects his commitment to fostering collaboration, knowledge exchange, and professional development within the global scientific community.</p>
                                <p>Finally, Dr. SivaNagiReddy Kalli embodies the quintessential scholar, whose unwavering commitment to academic excellence, research innovation, and professional engagement has left an indelible mark on the field of Electronics and Communication Engineering. His multifaceted contributions serve as a beacon of inspiration for aspiring researchers and educators alike, shaping the future trajectory of the discipline.</p>
                               
                              
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DeanAcademics