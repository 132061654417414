import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';



import acc from '../../TrainingAndPlacements/20-21/accenture.png'
import cts from '../../TrainingAndPlacements/20-21/cts.png'
import dxc from '../../TrainingAndPlacements/20-21/dxc.png'
import cap from '../../TrainingAndPlacements/20-21/cg_logo.png'
import legato from '../../TrainingAndPlacements/20-21/legato.png'
import hexaware from '../../TrainingAndPlacements/20-21/hexaware.png'
import cgi from '../../TrainingAndPlacements/20-21/cgi.png'
import TCS from '../../TrainingAndPlacements/20-21/TCS.png'
import nttdata from '../../TrainingAndPlacements/20-21/ntt-data.png'
import wipro from '../../TrainingAndPlacements/20-21/wipro.png'
import ibm from '../../TrainingAndPlacements/20-21/ibm.png'
import societe from '../../TrainingAndPlacements/20-21/societe.png'
import SAP from '../../TrainingAndPlacements/20-21/SAP-LABS.png'
import tech from '../../TrainingAndPlacements/20-21/techm_logotechm_logotechm_logo.png'
import amazon from '../../TrainingAndPlacements/20-21/amazon.png'
import INFOSYS from '../../TrainingAndPlacements/20-21/INFOSYS.png'
import SYNTEL from '../../TrainingAndPlacements/20-21/SYNTEL.png'
import hcl from '../../TrainingAndPlacements/20-21/HCL.png'
import maq from '../../TrainingAndPlacements/20-21/maq.png'
import mtx from '../../TrainingAndPlacements/20-21/mtx.png'
import dell from '../../TrainingAndPlacements/20-21/dell-tech.png'
import savantis from '../../TrainingAndPlacements/20-21/savantis.png'
import invesco from '../../TrainingAndPlacements/20-21/invesco.png'
import TELEPERFORMANCE from '../../TrainingAndPlacements/20-21/TELEPERFORMANCE.png'
import Virtusa from '../../TrainingAndPlacements/20-21/Virtusa.png'
import tsystems from '../../TrainingAndPlacements/20-21/t-systems.png'
import subex from '../../TrainingAndPlacements/20-21/subex.png'
import deloitte from '../../TrainingAndPlacements/20-21/deloitte.png'
import enhisecure from '../../TrainingAndPlacements/20-21/enhisecure.png'
import ZenQ from '../../TrainingAndPlacements/20-21/ZenQ.png'
import atT from '../../TrainingAndPlacements/20-21/atT.png'
import BOSCH from '../../TrainingAndPlacements/20-21/BOSCH.png'
import ramGroup from '../../TrainingAndPlacements/20-21/ramgroup.jpg'
import mindtree from '../../TrainingAndPlacements/20-21/mindtree.png'
import GoogleMaps from '../../TrainingAndPlacements/20-21/GoogleMaps.png'
import aliens from '../../TrainingAndPlacements/20-21/aliens.png'
import ulearn from '../../TrainingAndPlacements/20-21/ulearn.png'
import zon from '../../TrainingAndPlacements/20-21/zon.png'
import cts2 from '../../TrainingAndPlacements/20-21/cts2.png'
import cyient from '../../TrainingAndPlacements/20-21/cyient.png'
import intelenet from '../../TrainingAndPlacements/20-21/intelenet.png'
import genpact from '../../TrainingAndPlacements/20-21/genpact.png'
import qspider from '../../TrainingAndPlacements/20-21/qspider.png'
import c3i from '../../TrainingAndPlacements/20-21/c3i.png'
import hitachi from '../../TrainingAndPlacements/20-21/hitachi.png'
import VISTEX from '../../TrainingAndPlacements/20-21/VISTEX.png'
import uxReactor from '../../TrainingAndPlacements/20-21/uxReactor.png'
import MPHASIS from '../../TrainingAndPlacements/20-21/MPHASIS.png'
import oracle from '../../TrainingAndPlacements/20-21/oracle.png'
import DHlogo from '../../TrainingAndPlacements/20-21/DHlogo.png'
import kony from '../../TrainingAndPlacements/20-21/kony.png'
import dq from '../../TrainingAndPlacements/20-21/dq.png'
import hgs from '../../TrainingAndPlacements/20-21/hgs.png'
import incessant from '../../TrainingAndPlacements/20-21/incessant.png'
import hp from '../../TrainingAndPlacements/20-21/hp.png'
import muSigma from '../../TrainingAndPlacements/20-21/muSigma.png'
import EXCERS from '../../TrainingAndPlacements/20-21/EXCERS.png'
import INRYTHM from '../../TrainingAndPlacements/20-21/INRYTHM.png'
import SERCO from '../../TrainingAndPlacements/20-21/SERCO.png'
import rigalix from '../../TrainingAndPlacements/20-21/rigalix.png'
import adp from '../../TrainingAndPlacements/20-21/adp.png'
import Cadence from '../../TrainingAndPlacements/20-21/adp.png'
import justDial from '../../TrainingAndPlacements/20-21/justDial.png'
import google from '../../TrainingAndPlacements/20-21/google.jfif'
import HSBC from '../../TrainingAndPlacements/20-21/HSBC.png'
import landt from '../../TrainingAndPlacements/20-21/landt.png'
import lg from '../../TrainingAndPlacements/20-21/lg.png'
import MAQSoftware from '../../TrainingAndPlacements/20-21/MAQSoftware.png'
import PORTWARE from '../../TrainingAndPlacements/20-21/PORTWARE.png'
import NEKKANTISYSTEMS from '../../TrainingAndPlacements/20-21/NEKKANTISYSTEMS.png'
import TOSHIBA from '../../TrainingAndPlacements/20-21/TOSHIBA.png'
import ACROPETAL from '../../TrainingAndPlacements/20-21/ACROPETAL.png'
import CSSCORP from '../../TrainingAndPlacements/20-21/CSSCORP.png'
import FACE from '../../TrainingAndPlacements/20-21/CSSCORP.png'
import ICICI from '../../TrainingAndPlacements/20-21/ICICI.png'
import infotech from '../../TrainingAndPlacements/20-21/infotech.png'
import OSMOSYS from '../../TrainingAndPlacements/20-21/OSMOSYS.png'
import microsoft from '../../TrainingAndPlacements/20-21/microsoft.png'
import SUTHERLAND from '../../TrainingAndPlacements/20-21/SUTHERLAND.png'
import SPCapitalIQ from '../../TrainingAndPlacements/20-21/SPCapitalIQ.png'
import Tripod from '../../TrainingAndPlacements/20-21/Tripod.png'
import PENGROUP from '../../TrainingAndPlacements/20-21/PENGROUP.png'
import SITEL from '../../TrainingAndPlacements/20-21/SITEL.png'
import SATYAM from '../../TrainingAndPlacements/20-21/SATYAM.png'
import XTREAMIT from '../../TrainingAndPlacements/20-21/XTREAMIT.png'
import ITPLEXUS from '../../TrainingAndPlacements/20-21/ITPLEXUS.png'
import ACTIVEBRAINS from '../../TrainingAndPlacements/20-21/ACTIVEBRAINS.png'
import GCAConsulting from '../../TrainingAndPlacements/20-21/GCAConsulting.png'
import SOCLogicIndia from '../../TrainingAndPlacements/20-21/SOCLogicIndia.png'
import SRSCONSULTING from '../../TrainingAndPlacements/20-21/SRSCONSULTING.png'
import TECRA from '../../TrainingAndPlacements/20-21/TECRA.png'
import VODAFONE from '../../TrainingAndPlacements/20-21/VODAFONE.png'
import StratagemSolutions from '../../TrainingAndPlacements/20-21/StratagemSolutions.png'
import VALUEMOMENTUM from '../../TrainingAndPlacements/20-21/VALUEMOMENTUM.png'
import InfobrainTechnologies from '../../TrainingAndPlacements/20-21/InfobrainTechnologies.png'
import cgn from './eceimages/industry/sdc/cgn.png'
import rapiidrobotics from './eceimages/industry/sdc/rapiid-robotics.png'


const EcePlacements = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Placement, Higher Studies and Entrepreneurship
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td>
                                                    <p class="text-center"><strong>Item</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>CAYm1 2017-18</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>CAYm2 2016-17</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>CAYm3 2015-16</strong></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Total No. of Final Year Students (N)</td>
                                                <td>231</td>
                                                <td>214</td>
                                                <td>166</td>
                                            </tr>
                                            <tr>
                                                <td>No. of Students Placed in Companies and Government Sector (X)</td>
                                                <td>129</td>
                                                <td>156</td>
                                                <td>98</td>
                                            </tr>
                                            <tr>
                                                <td>No. of Students admitted to higher studies with valid qualifying scores (GATE or equipment State or National Level Tests, GRE, GMAT etc.) (Y)</td>
                                                <td>11</td>
                                                <td>1</td>
                                                <td>4</td>
                                            </tr>
                                            <tr>
                                                <td>No. of students turned entrepreneur in engineer / technology (Z)</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>X + Y + Z = </td>
                                                <td>140</td>
                                                <td>157</td>
                                                <td>102</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='pt-2'>
                                    <h6 className='py-2'>Placement Details For Academic Year 2017-18
                                    </h6>
                                    <div class="table-responsive">

                                        <table class="table table-bordered mtop25">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td>
                                                        <p><strong>S.no</strong></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Name of the student  placed</strong></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Enrollment No</strong></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Working status</strong></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Name of the Employer</strong></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td><strong>Nemallapudi Sai Lakshmi Pranathi</strong></td>
                                                    <td>14D21A0492</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td><strong>Amara Pratyusha</strong></td>
                                                    <td>14D21A0405</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td><strong>Challa Santhosha Priya</strong></td>
                                                    <td>14D21A04C0</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src="../../images/s-logos/reliable-techno.png" alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td><strong>Sushmitha Reddy</strong></td>
                                                    <td>14D21A04F2</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td><strong>Adulapuram Sowmya </strong></td>
                                                    <td>14D21A0404</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td><strong>Kureti Geeta Sowmya</strong> </td>
                                                    <td>14D21A04I9</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={intelenet} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td><strong>Janumpally mahitha reddy</strong> </td>
                                                    <td>14D21A0423</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td><strong>Chennuri Meghana</strong> </td>
                                                    <td>14D21A0414</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td><strong>Upadhya Gautami</strong> </td>
                                                    <td>14D21A04A7</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td><strong>R.Vandana Reddy</strong> </td>
                                                    <td>14D21A04K5</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src="../../images/s-logos/intellinet.png" alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td><strong>Suddavanda pravallika</strong> </td>
                                                    <td>14D21A0498</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cgn} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td><strong>Jasti Baby Bhavana </strong> </td>
                                                    <td>14D21A0424</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={amazon} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>13</td>
                                                    <td><strong>Barlapally Prasanna Reddy</strong> </td>
                                                    <td>14D21A04G9</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>14</td>
                                                    <td><strong>Kancharla Manideepika Chowdary</strong> </td>
                                                    <td>14D21A04J2</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={intelenet} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>15</td>
                                                    <td><strong>Bhavani Kamatala</strong> </td>
                                                    <td>14D21A04D1</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>16</td>
                                                    <td><strong>Yanagunde Sowjanya Reddy </strong> </td>
                                                    <td>14D21A04M0</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>17</td>
                                                    <td><strong>Gayathri Miriyala </strong> </td>
                                                    <td>14D21A0473</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cgn} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>18</td>
                                                    <td><strong>P Sri Pravallika </strong> </td>
                                                    <td>14D21A0440</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>19</td>
                                                    <td><strong>Totapalli Kavya</strong> </td>
                                                    <td>14D21A0450</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>20</td>
                                                    <td><strong>Rishika Singh</strong> </td>
                                                    <td>14D21A04K7</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={amazon} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>21</td>
                                                    <td><strong>R. Priyanka Reddy </strong> </td>
                                                    <td>14D21A04K4</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src="../../images/s-logos/wipro.png" alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>22</td>
                                                    <td><strong>Baki Hyma Chandana</strong> </td>
                                                    <td>14D21A0412</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>23</td>
                                                    <td><strong>Arradi Anusha</strong> </td>
                                                    <td>14D21A04B4</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>24</td>
                                                    <td><strong>Komati Yagnasree </strong> </td>
                                                    <td>14D21A04I6</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>25</td>
                                                    <td><strong>Adapakala Sravanthi</strong> </td>
                                                    <td>14D21A0403</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>26</td>
                                                    <td><strong>G. Sai Naveena</strong> </td>
                                                    <td>14D21A04H7</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>27</td>
                                                    <td><strong>Papolu Sri Naga Chandi Priya </strong> </td>
                                                    <td>14D21A0438</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>28</td>
                                                    <td><strong>S.Vijaya Lakshmi</strong> </td>
                                                    <td>14D21A04L1</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>29</td>
                                                    <td><strong>Anuradha Morapakala</strong> </td>
                                                    <td>14D21A04J6</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={rapiidrobotics} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                                <tr>
                                                    <td>30</td>
                                                    <td><strong>Priyanka Singh</strong> </td>
                                                    <td>14D21A0442</td>
                                                    <td class="text-success"><strong>Working</strong></td>
                                                    <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='pt-2'>
                                        <h6 className='py-2'>Placement Details For Academic year 2016-17</h6>
                                        <div class="table-responsive">
                                            <table class="table table-bordered mtop25">
                                                <tbody>
                                                    <tr class="bg-gray">
                                                        <td>
                                                            <p><strong>S.no</strong></p>
                                                        </td>
                                                        <td>
                                                            <p><strong>Name of the student  placed</strong></p>
                                                        </td>
                                                        <td>
                                                            <p><strong>Enrollment No</strong></p>
                                                        </td>
                                                        <td>
                                                            <p><strong>Working status</strong></p>
                                                        </td>
                                                        <td>
                                                            <p><strong>Name of the Employer</strong></p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td><strong>Jojode Himabindu</strong></td>
                                                        <td>12D21A0470</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td><strong>Kummarakuntla Sushmitha</strong></td>
                                                        <td>13D21A0447</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td><strong>Devanaboina Tulasi Ratnam</strong></td>
                                                        <td>12D21A04A0</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td><strong>Tamma Tejaswini Mamimala</strong></td>
                                                        <td>12D21A04B7</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={tech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td><strong>Aisha Tasleem </strong></td>
                                                        <td>13D21A0401</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td><strong>Aishwarya Rapaka</strong> </td>
                                                        <td>13D21A0402</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={intelenet} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                        <td><strong>Sarikonda Divya</strong> </td>
                                                        <td>13D21A0406</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td><strong>Polaboina Geetha</strong> </td>
                                                        <td>13D21A0407</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={tech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td><strong>Gopireddy Jaya Shree Reddy</strong> </td>
                                                        <td>13D21A0409</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td><strong>Anthati Joshna</strong> </td>
                                                        <td>13D21A0410</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={tech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>11</td>
                                                        <td><strong>Rashmi Kiran</strong> </td>
                                                        <td>13D21A0413</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td><strong>Kusumanjali Rayibilli</strong> </td>
                                                        <td>13D21A0414</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>13</td>
                                                        <td><strong>Aitipamula Madhuri</strong> </td>
                                                        <td>13D21A0416</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>14</td>
                                                        <td><strong>Madhuri Koyilakonda</strong> </td>
                                                        <td>13D21A0417</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>15</td>
                                                        <td><strong>Vinjamuri  Neharika</strong> </td>
                                                        <td>13D21A0466</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={tech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>16</td>
                                                        <td><strong>Bhavirisetty Mounika</strong> </td>
                                                        <td>13D21A0419</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>17</td>
                                                        <td><strong>Banoori Navya Reddy</strong> </td>
                                                        <td>13D21A0421</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={tech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>18</td>
                                                        <td><strong>Chadhuvula  Navya Sree</strong> </td>
                                                        <td>13D21A0422</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>19</td>
                                                        <td><strong>Priya Nandini Mummaleti</strong> </td>
                                                        <td>13D21A0468</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>20</td>
                                                        <td><strong>Onmani Niveditha</strong> </td>
                                                        <td>13D21A0424</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>21</td>
                                                        <td><strong>Nandyada sandhya</strong> </td>
                                                        <td>13D21A0477</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cgn} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>22</td>
                                                        <td><strong>A.Pooja Yadav</strong> </td>
                                                        <td>13D21A0426</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>23</td>
                                                        <td><strong>Rashmini Akkapally</strong> </td>
                                                        <td>13D21A0429</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>24</td>
                                                        <td><strong>Pilligundla Sahithya</strong> </td>
                                                        <td>13D21A0431</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>25</td>
                                                        <td><strong>G Sai Harika </strong> </td>
                                                        <td>13D21A0432</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>26</td>
                                                        <td><strong>Sai Prasanna</strong> </td>
                                                        <td>13D21A0434</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>27</td>
                                                        <td><strong>Tata  Sharanya</strong> </td>
                                                        <td>13D21A0437</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>28</td>
                                                        <td><strong>Shivaranjini G</strong> </td>
                                                        <td>13D21A0481</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>29</td>
                                                        <td><strong>Gouni Sindhuja </strong> </td>
                                                        <td>13D21A0440</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={rapiidrobotics} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                    <tr>
                                                        <td>30</td>
                                                        <td><strong>Sree Lasya P</strong> </td>
                                                        <td>13D21A0441</td>
                                                        <td class="text-success"><strong>Working</strong></td>
                                                        <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='pt-2'>
                                            <h6 className='py-2'>Placement Details For Academic year 2015-16</h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered mtop25">
                                                    <tbody>
                                                        <tr class="bg-gray">
                                                            <td>
                                                                <p><strong>S.no</strong></p>
                                                            </td>
                                                            <td>
                                                                <p><strong>Name of the student  placed</strong></p>
                                                            </td>
                                                            <td>
                                                                <p><strong>Enrollment No</strong></p>
                                                            </td>
                                                            <td>
                                                                <p><strong>Working status</strong></p>
                                                            </td>
                                                            <td>
                                                                <p><strong>Name of the Employer</strong></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>1</td>
                                                            <td><strong>Cherala Alekhya</strong></td>
                                                            <td>12D21A0401</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={SERCO} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td><strong>Anusha Reddy</strong></td>
                                                            <td>12D21A0403</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cgn} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td><strong>Enje Asha Jyothi</strong></td>
                                                            <td>12D21A0404</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" />Jochebed Tech Solutions</td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td><strong>V Bhavani</strong></td>
                                                            <td>12D21A0405</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={SERCO} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td><strong>Paramkusham Bhavika</strong></td>
                                                            <td>12D21A0406</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={SERCO} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>6</td>
                                                            <td><strong>Kotipalli Bindu Sowjanya</strong> </td>
                                                            <td>12D21A0408</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={INFOSYS} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>7</td>
                                                            <td><strong>Laharika Keshayyagari</strong> </td>
                                                            <td>12D21A0415</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={dxc} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>8</td>
                                                            <td><strong>Malkapuram Mounika</strong> </td>
                                                            <td>12D21A0420</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={amazon} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>9</td>
                                                            <td><strong>Yelamarthi Mounika</strong> </td>
                                                            <td>12D21A0421</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={tech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>10</td>
                                                            <td><strong>Naseema Begum</strong> </td>
                                                            <td>12D21A0423</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={ICICI} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>11</td>
                                                            <td><strong>P. Neelima</strong> </td>
                                                            <td>12D21A0425</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cyient} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>12</td>
                                                            <td><strong>Kothakapu Niharika</strong> </td>
                                                            <td>12D21A0426</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={hgs} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>13</td>
                                                            <td><strong>Gunda Nikhitha</strong> </td>
                                                            <td>12d21a0427</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" />NXC Semiconductors</td>
                                                        </tr>
                                                        <tr>
                                                            <td>14</td>
                                                            <td><strong>Mirapakayala Nikitha</strong> </td>
                                                            <td>12D21A0428</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={SERCO} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>15</td>
                                                            <td><strong>Noorie Alisaheb</strong> </td>
                                                            <td>12D21A0429</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cgn} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>16</td>
                                                            <td><strong>Preethi Reddy Nandanoori</strong> </td>
                                                            <td>12D21A0432</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" />Nurture Software Solutions</td>
                                                        </tr>
                                                        <tr>
                                                            <td>17</td>
                                                            <td><strong>Thotakuri Premalatha </strong> </td>
                                                            <td>12D21A0433</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cgn} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>18</td>
                                                            <td><strong>Ramya Sri B</strong> </td>
                                                            <td>12D21A0437</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" />SIA Publishers And Distributors</td>
                                                        </tr>
                                                        <tr>
                                                            <td>19</td>
                                                            <td><strong>C. Roopa</strong> </td>
                                                            <td>12D21A0439</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cgn} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>20</td>
                                                            <td><strong>Behatha Sai Bharani</strong> </td>
                                                            <td>12D21A0441</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cyient} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>21</td>
                                                            <td><strong>M Sailaja </strong> </td>
                                                            <td>12D21A0442</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={tech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>22</td>
                                                            <td><strong>Sajja Nandana</strong> </td>
                                                            <td>12D21A0443</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={intelenet} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>23</td>
                                                            <td><strong>Samyukta Korrapati</strong> </td>
                                                            <td>12D21A0445</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>24</td>
                                                            <td><strong>Sana Tabassum</strong> </td>
                                                            <td>12D21A0446</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={ibm} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>25</td>
                                                            <td><strong>Pabboju Tejasree</strong> </td>
                                                            <td>12D21A0457</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={genpact} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>26</td>
                                                            <td><strong>Venu Madhavi Kandula</strong> </td>
                                                            <td>12D21A0460</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={amazon} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>27</td>
                                                            <td><strong>Aishwarya Gunti</strong> </td>
                                                            <td>12D21A0461</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={cap} alt="logo" class="img-responsive img-fluid" loading="lazy" />HPE</td>
                                                        </tr>
                                                        <tr>
                                                            <td>28</td>
                                                            <td><strong>Peddala Asha Sree</strong> </td>
                                                            <td>12D21A0462</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={amazon} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>29</td>
                                                            <td><strong>Divya Sri Vishnubotla</strong> </td>
                                                            <td>12D21A0468</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={amazon} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>30</td>
                                                            <td><strong>Muskara Keerthi</strong> </td>
                                                            <td>12D21A0473</td>
                                                            <td class="text-success"><strong>Working</strong></td>
                                                            <td><img src={INFOSYS} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='pt-3'>
                                                <h6>Performance appraisals (result analysis)
                                                </h6>
                                                <p>List of students cleared in 4 years during 3 assessment years 2014-18
                                                </p>
                                                <div class="table-responsive">
                                                    <table class="table table-bordered mtop10 table-verticle">
                                                        <tbody>
                                                            <tr class="bg-gray">
                                                                <td>
                                                                    <p><strong>S.no</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Dept</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>No of Students</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Pass %</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Distinction</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>First Class</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Second Class</strong></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>1</strong></td>
                                                                <td><strong>ECE</strong></td>
                                                                <td>
                                                                    <table class="table table-bordered">
                                                                        <tbody>
                                                                            <tr class="bg-gray">
                                                                                <td>
                                                                                    <p><strong>Appeared</strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p><strong>Passed</strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p><strong>Failed</strong></p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>231</strong></td>
                                                                                <td><strong>200</strong></td>
                                                                                <td><strong>32</strong></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td><strong>86.20</strong></td>
                                                                <td><strong>49</strong></td>
                                                                <td><strong>141</strong></td>
                                                                <td><strong>9</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className='pt-3'>
                                                <p>List of students cleared in 4 years during 3 assessment years 2013-17
                                                </p>
                                                <div class="table-responsive">
                                                    <table class="table table-bordered mtop10 table-verticle">
                                                        <tbody>
                                                            <tr class="bg-gray">
                                                                <td>
                                                                    <p><strong>S.no</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Dept</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>No of Students</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Pass %</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Distinction</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>First Class</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Second Class</strong></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>1</strong></td>
                                                                <td><strong>ECE</strong></td>
                                                                <td>
                                                                    <table class="table table-bordered">
                                                                        <tbody>
                                                                            <tr class="bg-gray">
                                                                                <td>
                                                                                    <p><strong>Appeared</strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p><strong>Passed</strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p><strong>Failed</strong></p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>214</strong></td>
                                                                                <td><strong>202</strong></td>
                                                                                <td><strong>12</strong></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td><strong>94.39</strong></td>
                                                                <td><strong>105</strong></td>
                                                                <td><strong>93</strong></td>
                                                                <td><strong>4</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='pt-3'>
                                                <p>List of students cleared in 4 years during 3 assessment years 2012-16
                                                </p>
                                                <div class="table-responsive">
                                                    <table class="table table-bordered mtop10 table-verticle">
                                                        <tbody>
                                                            <tr class="bg-gray">
                                                                <td>
                                                                    <p><strong>S.no</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Dept</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>No of Students</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Pass %</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Distinction</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>First Class</strong></p>
                                                                </td>
                                                                <td>
                                                                    <p><strong>Second Class</strong></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>1</strong></td>
                                                                <td><strong>ECE</strong></td>
                                                                <td>
                                                                    <table class="table table-bordered">
                                                                        <tbody>
                                                                            <tr class="bg-gray">
                                                                                <td>
                                                                                    <p><strong>Appeared</strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p><strong>Passed</strong></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p><strong>Failed</strong></p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>166</strong></td>
                                                                                <td><strong>159</strong></td>
                                                                                <td><strong>7</strong></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td><strong>95.78</strong></td>
                                                                <td><strong>109</strong></td>
                                                                <td><strong>49</strong></td>
                                                                <td><strong>1</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EcePlacements
