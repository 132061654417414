import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import RandDLeftTab from './RandDLeftTab'


const IndustryInstituteInteraction = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <RandDLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2 ">
                            <div className="how-to-apply">
                                <h2>
                                    Industry Institute Interaction
                                </h2>
                                <div className="academics-area mt-lg-5 pb-70">
                                    <div className="container">
                                        <div className="academics-left-content">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="single-academics-card3">
                                                        <div className="icon">
                                                            <i className="flaticon-pc"></i>
                                                        </div>
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/CSE.docx"   target="_blank"><h3>CSE</h3></a>
                                                        
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/CSE.docx"   target="_blank" className="read-more-btn">Download PDF<i className="flaticon-next"></i></a>
                                                    </div>
                                                </div>
                                               
                                               
                                                
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="single-academics-card3">
                                                        <div className="icon">
                                                            <i className="flaticon-help"></i>
                                                        </div>
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/IT.docx"   target="_blank"><h3>IT</h3></a>
                                                        
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/IT.docx"   target="_blank" className="read-more-btn">Download PDF<i className="flaticon-next"></i></a>
                                                    </div>
                                                </div>     
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="single-academics-card3">
                                                        <div className="icon">
                                                            <i className="flaticon-connection"></i>
                                                        </div>
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/ECE.docx"   target="_blank"><h3>ECE</h3></a>
                                                        
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/ECE.docx"   target="_blank" className="read-more-btn">Download PDF<i className="flaticon-next"></i></a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="single-academics-card3">
                                                        <div className="icon">
                                                            <i className="flaticon-help"></i>
                                                        </div>
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/EEE.docx"   target="_blank"><h3>EEE</h3></a>
                                                        
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/EEE.docx"   target="_blank" className="read-more-btn">Download PDF<i className="flaticon-next"></i></a>
                                                    </div>
                                                </div>  
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="single-academics-card3">
                                                        <div className="icon">
                                                            <i className="flaticon-profile"></i>
                                                        </div>
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/MBA.docx"   target="_blank"><h3>MBA</h3></a>
                                                        
                                                        <a href="/assets/documents/INDUSTRIAL VISITS/MBA.docx"   target="_blank" className="read-more-btn">Download PDF<i className="flaticon-next"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>

                            </div>
                            <div className="how-to-apply">
                                <h2>
                                Internships
                                </h2>
                                <div className="academics-area mt-lg-5 pb-70">
                                    <div className="container">
                                        <div className="academics-left-content">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="single-academics-card3">
                                                        <div className="icon">
                                                            <i className="flaticon-pc"></i>
                                                        </div>
                                                        <a href="/assets/documents/INTERNSHIPS/CSE.pdf"   target="_blank"><h3>CSE</h3></a>
                                                        
                                                        <a href="/assets/documents/INTERNSHIPS/CSE.pdf"   target="_blank" className="read-more-btn">Download PDF<i className="flaticon-next"></i></a>
                                                    </div>
                                                </div>
                                               
                                               
                                                
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="single-academics-card3">
                                                        <div className="icon">
                                                            <i className="flaticon-help"></i>
                                                        </div>
                                                        <a href="/assets/documents/INTERNSHIPS/IT.pdf"   target="_blank"><h3>IT</h3></a>
                                                        
                                                        <a href="/assets/documents/INTERNSHIPS/IT.pdf"   target="_blank" className="read-more-btn">Download PDF<i className="flaticon-next"></i></a>
                                                    </div>
                                                </div>     
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="single-academics-card3">
                                                        <div className="icon">
                                                            <i className="flaticon-connection"></i>
                                                        </div>
                                                        <a href="/assets/documents/INTERNSHIPS/ECE.pdf"   target="_blank"><h3>ECE</h3></a>
                                                        
                                                        <a href="/assets/documents/INTERNSHIPS/ECE.pdf"   target="_blank" className="read-more-btn">Download PDF<i className="flaticon-next"></i></a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-6">
                                                    <div className="single-academics-card3">
                                                        <div className="icon">
                                                            <i className="flaticon-help"></i>
                                                        </div>
                                                        <a href="/assets/documents/INTERNSHIPS/EEE.pdf"   target="_blank"><h3>EEE</h3></a>
                                                        
                                                        <a href="/assets/documents/INTERNSHIPS/EEE.pdf"   target="_blank" className="read-more-btn">Download PDF<i className="flaticon-next"></i></a>
                                                    </div>
                                                </div>  
                                               
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default IndustryInstituteInteraction

