import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import FooterTab from './FooterTab'



const CopyrightPolicy = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row mx-auto">
                        {/* <div className='col-lg-4 pe-lg-5'>
                            <FooterTab />
                        </div> */}
                        <div className="col-lg-12 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Copyright Policy
                                </h2>
                                <hh5>
                                Updating Soon...

                                </hh5>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default CopyrightPolicy
