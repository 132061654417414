import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';


const EEEPoAnsPSO = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Information Technology (IT)
                                </h2>
                                <h6 className='pt-2'>Programme Outcomes (PO'S)</h6>

                                <p><strong>Engineering Graduates will be able to</strong></p>
                                <div className='apply-list'>
                                    <ul >
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Engineering knowledge:</strong> Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Problem analysis:</strong> Identify, formulate, review research literature, and analyze complex engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Design/development of solutions:</strong> Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and environmental considerations.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Conduct investigations of complex problems:</strong> Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Modern tool usage:</strong> Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>The engineer and society:</strong>  Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Environment and&nbsp;sustainability:</strong> Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Ethics:</strong> Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Individual and team work:</strong> Function effectively as an individual, and as a member or leader in diverse teams, and in multidisciplinary settings.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Communication:</strong> Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give and receive clear instructions.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Project management and finance:</strong> Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member and leader in a team, to manage projects and in multidisciplinary environments.</li>
                                        <li><strong><i class="ri-checkbox-circle-line"></i>Life-long learning:</strong> Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.</li>
                                    </ul>
                                </div>

                               <div className='submit'>
                               <h6 className='py-2'>Program Specific Program Outcome</h6>
                                <p>Ability to apply technical skills and modern engineering tools, to develop new products useful to the industry and the society.</p>
                                <p>Ability to apply the knowledge in the domain of Electrical and Electronics Engineering, to demonstrate their proficiency through experimentation, modeling and realization.
                                </p>
                               </div>
                                <h6 className='py-2'>Program Specific Outcomes (PSOS)</h6>
                                <div className='apply-list'>
                                    <ul>
                                        <li><strong>PSO- I :</strong><i class="ri-checkbox-circle-line"></i>Able to&nbsp;design and analyze&nbsp;energy efficient and ecofriendly power and energy systems by employing control strategies related to power electronics, power systems applications for societal and industrial requirements.</li>
                                        <li><strong>PSO- II :</strong><i class="ri-checkbox-circle-line"></i>Able to apply cutting edge engineering hardware and software tools to solve complex Electrical and Electronics Engineering problems.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEEPoAnsPSO
