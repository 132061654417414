import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';
import one from './eceimages/industry/sdc/1.jpg'
import two from './eceimages/industry/sdc/2.jpg'
import three from './eceimages/industry/sdc/3.jpg'

const EceSDP = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Student Development Programmes
                                </h2>
                                <p>Student Development Programs are initiated by the department to fill the Curriculum and Learning Gaps. To full fill these gaps department collaborated with different industries and companies and conducted seminars and workshops on different topics to relate the syllabus with industry applications. The following are the workshops and seminars conducted in the last three academic years.
                                </p>
                                <div>
                                    <h5 className='pt-2'>Academic Year: 2020-21
                                    </h5>
                                    <div class="apply-list">

                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>In association with IIT Bombay, Spoken Tutorial, Department conducted a program on “Python Programming” on 27/07/2020 to 31/07/2020 for III & IV year I semester students.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i> Department in associated with Indian Institute of Remote Sensing, Indian Space Research Organization (ISRO), Department of Space, GOI who is offering ONLINE courses on Satellite related Technologies for developing trained professionals in the field of Remote Sensing, Geo-informatics, and Global Navigation Satellite System (GNSS) technology etc
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div>
                                    <h6 className='pt-2'>Courses conducted are:

                                    </h6>
                                    <div class="apply-list">

                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>63rd IIRS Outreach Programme on “Applications in Agricultural Water Management” (August 03rd to August 07th, 2020)
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>64th IIRS Outreach Programme on “Basics of Remote Sensing Geographical Information System and Global Navigation Satellite System” (August 17th to August 20th ,2020)
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>66th IIRS Outreach Programme on “Global Navigation Satellite System” (September 14th to September 25th, 2020)
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>67th IIRS Outreach Programme on “Understanding of Coastal ocean processes using Remote Sensing and Numerical Modelling” (September 21st to September 25th, 2020)
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>In associated with Triad Techno Services and conducted a seminar on “Training on IOT and ML” On 20/01/2021& 21/01/2021 by Mr S V Chandran for III-I & IV-I Students.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A two day hands on session was conducted in collaboration with “PGP Electronics” on “PCB Design” on 20/10/2020 & 21/10/2021 for III year I semester students
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Seminar was conducted on “Microprocessors Microcontroller and Applications” by Vision Krest Embedded Technologies Pvt.Ltd on 10/09/2020 &11/09/2020 for III year I Semester Students
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Seminar was conducted on “Digital System Design” by PGP Electronics, Hyderabad on 13/08/2020 & 14/08/2020 for II year I Semester Students
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                                <div>
                                    <h5 className='pt-2'>Academic Year: 2019-20
                                    </h5>
                                    <div class="apply-list">
                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>One day Program was conducted on “RF Amplifier Design and Communication” in collaboration with PGP Electronics On 12/02/2020 for II-II Semester ECE Students
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Seminar was conducted on “Image Processing using Matlab” by Pantech Solution on 27/01/2020 & 28/01/2020 for III Year II Semester Students
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Seminar was conducted on “Android multiscreen Application” by SPNS Technologies on 17/12/2019 & 18/12/2019 for III Year I semester Students
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Two-day Seminar was conducted on “Information Theory and channel Coding” in association with Triad Techno Services On 22/10/2019 & 23/10/2019 for II Year I Semester Students
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A One-day seminar was conducted on “Microprocessor and Microcontroller Interfacing” by “Vision Krest Technologies” on 19/10/2019 for III Year I Semester Students
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Seminar was conducted on “Image Processing and Machine Learning” by Pantech Solutions on 9/09/2019 & 10/09/2019 for IV year I Semester Students
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Two-day Seminar and Hands on Session was conducted on “PCB Design” by PGP Electronics on 5/09/2019 & 6/09/2019 for II Year I Semester Students
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Two-day Workshop was conducted on “5G Mobile Communication System for Smart Factories” in association with Triad Techno Services On 13/08/2019 & 14/08/2019 for IV Year I Semester Students
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <h5 className='pt-2'>Academic Year: 2018-19

                                    </h5>
                                    <div class="apply-list border-0">

                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>A Three-day workshop was conducted on “Touch Free Timer Switch using LMC555” in collaboration with “Silicon Research Laboratories” on 20/03/2019 & 22/03/2019 for II Year II Semester Students.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Seminar was conducted on “FPGA Implementation in VLSI Industries” By Silicon Research Labs Pvt. Ltd On 14/03/2019 & 15/03/2019 for III Year II Semester Students.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Three-Day Workshop was conducted on “Mobile Computing” by Vision Krest Embedded Technologies Pvt Ltd, From 13/02/2019 to 15/02/2019 for III Year II Semester Students.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>A Three-day workshop was conducted on “Network Protocols for Secure Communications” by Triad Techno Services From 17/01/2019 to 19/01/2019 for IV Year I Semester Students.
                                            </li>

                                        </ul>
                                    </div>

                                </div>
                                <div>
                                    <h5 className='pt-2 pb-3'>A Hands-on Session was conducted on “PCB Design” By PGP Electronics on:
                                    </h5>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <h6 className='pb-2'>Android multiscreen Application

                                            </h6>
                                            <img src={one} className='img-fluid pb-3' alt='cds' loading='lazy' />
                                        </div>
                                        <div className='col-md-6'>
                                            <h6 className='pb-2'>Mobile computing

                                            </h6>
                                            <img src={two} className='img-fluid pb-3' alt='cds' loading='lazy' />
                                        </div>
                                        <div className='col-md-6'>
                                            <h6 className='pb-2'>RF Amplifier Design & Communication

                                            </h6>
                                            <img src={three} className='img-fluid pb-3' alt='cds' loading='lazy' />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default EceSDP
