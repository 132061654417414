import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const ObeWorkShop2022 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> OBE Workshop
                                    </h2>
                                    <p className='pb-4'>
                                        OBE workshop on Outcome formulation and attainment conducted on Feb 26th 2022 by Dr. S Viswanadha Raju,Professor,Computer Science & Engineering JNTUH University College of Engineering Jagtial for senior faculty of SWEC
                                    </p>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/1.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/1.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/2.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/2.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/3.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/3.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/4.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/4.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/5.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/5.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/6.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/6.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/7.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/7.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/8.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/8.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/9.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/9.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/obe-workshop/10.jpg">
                                                    <img src="/assets/img/gallery/obe-workshop/10.jpg" alt="Image" class="img-responsive center-block" loading="lazy" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}



export default ObeWorkShop2022
