import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const Selfdefenceskills = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Self defence skills
                                    </h2>
                                    <div className='py-2'>
                                        <p>SWEC conducted a self defence workshop for their girl students in coordination with Vanita TV under the NSS banner. The workshop was well received by the students and they learnt some quick techniques to defend themselves.
                                        </p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/self_defence_skills/1.jpg">
                                                    <img src="/assets/img/gallery/self_defence_skills/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/self_defence_skills/2.jpg">
                                                    <img src="/assets/img/gallery/self_defence_skills/2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/self_defence_skills/3.jpg">
                                                    <img src="/assets/img/gallery/self_defence_skills/3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/self_defence_skills/4.jpg">
                                                    <img src="/assets/img/gallery/self_defence_skills/4.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/self_defence_skills/5.jpg">
                                                    <img src="/assets/img/gallery/self_defence_skills/5.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/self_defence_skills/6.jpg">
                                                    <img src="/assets/img/gallery/self_defence_skills/6.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/self_defence_skills/7.jpg">
                                                    <img src="/assets/img/gallery/self_defence_skills/7.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Selfdefenceskills
