import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';
import one from './eceimages/industry/1.png'
import two from './eceimages/industry/2.png'
import three from './eceimages/industry/3.png'
import four from './eceimages/industry/4.png'
import five from './eceimages/industry/5.png'
import six from './eceimages/industry/6.png'

const EceIndustryVisits = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Industrial Visits
                                </h2>
                                <p>ECE Department of Sridevi Women’s Engineering College always strives to provide an Exposure to their Students on latest developments in the field of Science & Technology With the continuous encouragement from the Management and time to time Guidance from the Principal of institution.
                                </p>
                                <div className='pt-3 row'>
                                    <h6 className='pb-2'>Virtual industrial visit to Tata tele business services on 8/1/2021
                                    </h6>
                                    <div className='col-md-6'>
                                        <img src={one} className='img-fluid pb-3' alt='Industrial Visits' loading='lazy' />
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={two} className='img-fluid pb-3' alt='Industrial Visits' loading='lazy' />
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <h6 className='pb-2'>Industrial visit to Central Institute of Tool Design (CITD) on 09/09/2019 & 10/09/2019
                                    </h6>

                                    <img src={three} className='img-fluid pb-3' alt='Industrial Visits' loading='lazy' />
                                </div>
                                <div className='pt-4'>
                                    <h6 className='pb-2'>Industrial visit to Central Institute of Tool Design (CITD) on 21/8/2019 & 22/8/2019
                                    </h6>
                                    <img src={four} className='img-fluid pb-3' alt='Industrial Visits' loading='lazy' />
                                </div>
                                <div className='pt-4'>
                                    <h6 className='pb-2'>
                                        Industrial visit to Regional telecom training Centre (BSNL) on 26/07/2018 &27/07/2018
                                    </h6>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <img src={five} className='img-fluid pb-3' alt='Industrial Visits' loading='lazy' />
                                        </div>
                                        <div className='col-md-6'>
                                            <img src={six} className='img-fluid pb-3' alt='Industrial Visits' loading='lazy' />
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <h6 className='pb-2'>Academic year: 2020-2021</h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td><strong>S.NO</strong> </td>
                                                    <td><strong>Activity</strong> </td>
                                                    <td><strong>Industry </strong></td>
                                                    <td><strong>class </strong></td>
                                                    <td><strong>No of students visited </strong></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>24/2/2020</td>
                                                    <td>Regional telecom training Centre (BSNL)</td>
                                                    <td>II-II</td>
                                                    <td>205</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>8/1/2021</td>
                                                    <td>Tata tele business services(Online)</td>
                                                    <td>III &amp; IV</td>
                                                    <td>300</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className='pt-4'>
                                    <h6 className='pb-2'>Academic year: 2019-2020
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td><strong>S.NO</strong> </td>
                                                    <td><strong>Activity</strong> </td>
                                                    <td><strong>Industry </strong></td>
                                                    <td><strong>class </strong></td>
                                                    <td><strong>No of students visited </strong></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>09/09/2019 &amp; 10/09/2019</td>
                                                    <td>Central Institute of Tool Design (CITD)</td>
                                                    <td>III-1st sem</td>
                                                    <td>240</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>21/8/2019 &amp; 22/8/2019</td>
                                                    <td>Central Institute of Tool Design (CITD)</td>
                                                    <td>IV-1st sem</td>
                                                    <td>175</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>30/1/2020 &amp; 31/1/2020</td>
                                                    <td>Regional telecom training Centre (BSNL)</td>
                                                    <td>III-2nd sem</td>
                                                    <td>240</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>5/2/2020 &amp; 6/2/2020</td>
                                                    <td>Regional telecom training Centre (BSNL)</td>
                                                    <td>IV-2nd sem</td>
                                                    <td>186</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <h6 className='pb-2'>Academic year: 2018-2019
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td><strong>S.NO</strong> </td>
                                                    <td><strong>Activity</strong> </td>
                                                    <td><strong>Industry </strong></td>
                                                    <td><strong>class </strong></td>
                                                    <td><strong>No of students visited </strong></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>26/07/2018 &amp;27/07/2018</td>
                                                    <td>Regional telecom training Centre (BSNL)</td>
                                                    <td>III-1st sem</td>
                                                    <td>187</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>02/8/2018 &amp; 03/8/2018</td>
                                                    <td>Regional telecom training Centre (BSNL) </td>
                                                    <td>IV-1st sem</td>
                                                    <td>156</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>28/12/2018 &amp; 29/12/2018</td>
                                                    <td>Electronics corporation of India limited(ECIL)</td>
                                                    <td>III-2nd sem</td>
                                                    <td>180</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>20/01/2019 &amp; 21/01/2019</td>
                                                    <td>Electronics corporation of India limited(ECIL)</td>
                                                    <td>IV-2nd sem</td>
                                                    <td>145</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EceIndustryVisits
