import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const Sports2018 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2>Sports
                                    </h2>
                                    <h6 className='pt-2'>
                                        Sports Day 2018

                                    </h6>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li>
                                                    <a target='_blank' href="/assets/img/gallery/sports/SPORTS (EVENTS).docx"><i class="fa-solid fa-file-pdf"></i> Mentoring </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h6 className='py-3'>
                                        Sports Day 2018

                                    </h6>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/sports/1.jpg">
                                                    <img src="/assets/img/gallery/sports/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/sports/2.jpg">
                                                    <img src="/assets/img/gallery/sports/2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/sports/3.jpg">
                                                    <img src="/assets/img/gallery/sports/3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/sports/4.jpg">
                                                    <img src="/assets/img/gallery/sports/4.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/sports/5.jpg">
                                                    <img src="/assets/img/gallery/sports/5.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>


                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/sports/6.jpg">
                                                    <img src="/assets/img/gallery/sports/6.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/sports/7.jpg">
                                                    <img src="/assets/img/gallery/sports/7.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default Sports2018
