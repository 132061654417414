import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import SLeftTab from './SLeftTab';


const Hactivities = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <SLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Activities
                                </h2>
                                <div className='pt-3'>
                                    <div class="">
                                        <div class="">
                                            <div class="apply-list ">
                                                <p>The department focuses on the overall personality development of the student through various activities in the First year. The faculty ensure smooth transition of the student from a junior college to a graduation college and offer guidance and support throughout the four years. Some of the personality development activities conducted during the year are:

                                                </p>
                                                <ul class="pt-2">
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        English training through ELCS lab

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        British English Certification course (optional)

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Essay writing and JAM competitions

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Essay writing and JAM competitions

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Guest lectures

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Drama
                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Elocution and debates

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Elocution and debates
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Hactivities
