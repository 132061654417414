import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';
import hod from './ItImages/hod/hod.jpg'

const ITSprojects = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Students Projects
                                </h2>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/it/Bestprojects_2020_2021.pdf" target='blank'><i class="fa-solid fa-file-pdf"></i> 2020-21  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/Bestprojects_2018-2019.pdf" target='blank'><i class="fa-solid fa-file-pdf"></i> 2018-2019  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/Bestprojects_2017-2018.pdf" target='blank'><i class="fa-solid fa-file-pdf"></i> 2017-18  </a>
                                            </li>

                                            <li>
                                                <a href="/assets/documents/it/Bestprojects_2016-2017.pdf" target='blank'><i class="fa-solid fa-file-pdf"></i> 2016-17  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/Bestprojects_2015-2016.pdf" target='blank'><i class="fa-solid fa-file-pdf"></i> 2015-16 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/it/Bestprojects_2014-2015.pdf" target='blank'><i class="fa-solid fa-file-pdf"></i> 2014-15 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Best Projects 2020-21
                                    </h6>
                                    <div class="table-responsive" >
                                        <table class="table table-bordered">
                                            <thead class="bg-gray">
                                                <tr>
                                                    <th><strong>B.No</strong></th>
                                                    <th><strong>Hall Ticket</strong></th>
                                                    <th><strong>Name </strong></th>
                                                    <th><strong>Title of the Project </strong></th>
                                                    <th><strong>Gudie Name </strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td  width="833" colspan="5" >
                                                        <p className='text-center' align="center"><b><span>Best Projects</span></b></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="60" rowspan="3" valign="top" >
                                                        <p align="center"><b><span >&nbsp;</span></b></p>
                                                        <p align="center"><b><span >1.</span></b></p>
                                                    </td>
                                                    <td width="148" >
                                                        <p align="center"><span>16D21A1212</span></p>
                                                    </td>
                                                    <td width="151" >
                                                        <p align="center"><span>K.Divya</span></p>
                                                    </td>
                                                    <td width="283" rowspan="3" >
                                                        <p align="center">
                                                            <span>
                                                                Dizziness
                                                                Detection and Alert System
                                                            </span><span ></span>
                                                        </p>
                                                    </td>
                                                    <td width="191" rowspan="3" >
                                                        <p align="center"><span>Dr.P.Avinash</span><span></span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="148" >
                                                        <p align="center"><span>16D21A1205</span></p>
                                                    </td>
                                                    <td width="151" >
                                                        <p align="center"><span>D.Taruni</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="148" >
                                                        <p align="center"><span>16D21A1225</span></p>
                                                    </td>
                                                    <td width="151" >
                                                        <p align="center"><span>N.Niharika</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="60" rowspan="3" valign="top" >
                                                        <p align="center"><b><span >&nbsp;</span></b></p>
                                                        <p >
                                                            <b>
                                                                <span>
                                                                    &nbsp;&nbsp;
                                                                    2.
                                                                </span>
                                                            </b>
                                                        </p>
                                                    </td>
                                                    <td width="148" >
                                                        <p align="center"><span>16D21A1228</span></p>
                                                    </td>
                                                    <td width="151" >
                                                        <p align="center"><span>Usha rani N</span></p>
                                                    </td>
                                                    <td width="283" rowspan="3" >
                                                        <p align="center">
                                                            <span>
                                                                Attendance
                                                                Management System through Face Recognition
                                                            </span><span></span>
                                                        </p>
                                                    </td>
                                                    <td width="191" rowspan="3" >
                                                        <p align="center"><span>C. Swapna</span><span></span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="148" >
                                                        <p align="center"><span>16D21A1250</span></p>
                                                    </td>
                                                    <td width="151" >
                                                        <p align="center"><span><span zeum4c19="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">DharmavathiM</span></span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="148" >
                                                        <p align="center"><span>16D21A1239</span></p>
                                                    </td>
                                                    <td width="151" >
                                                        <p align="center"><span>Reshma Begum</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="60" rowspan="3" valign="top" >
                                                        <p align="center"><b><span >&nbsp;</span></b></p>
                                                        <p >
                                                            <b>
                                                                <span>
                                                                    &nbsp;&nbsp;
                                                                    3.
                                                                </span>
                                                            </b>
                                                        </p>
                                                    </td>
                                                    <td width="148" >
                                                        <p align="center"><span>16D21A1222</span></p>
                                                    </td>
                                                    <td width="151" >
                                                        <p align="center"><span>M.Sanjana</span></p>
                                                    </td>
                                                    <td width="283" rowspan="3" >
                                                        <p align="center">
                                                            <span>
                                                                Women Safety
                                                                using IOT
                                                            </span><span></span>
                                                        </p>
                                                    </td>
                                                    <td width="191" rowspan="3" >
                                                        <p align="center"><span>Shamal Telkar</span><span></span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="148" >
                                                        <p align="center"><span>16D21A1217</span></p>
                                                    </td>
                                                    <td width="151" >
                                                        <p align="center"><span>AL. Meghana</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="148" >
                                                        <p align="center"><span>16D21A1206</span></p>
                                                    </td>
                                                    <td width="151" >
                                                        <p align="center"><span>E. Samatha</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ITSprojects
