import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import { Link } from "react-router-dom";

const Overall = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <div className="additional-information">
                                <div className="list">
                                    <h5 className='pb-3'>NIRF 2023</h5>
                                    <ul>
                                        <Link to="/nirf"><li className='text-black'><i class="fa fa-university"></i> Engineering
                                        </li></Link>
                                        <Link to="/overall"><li className='text-black'><i class="fa fa-users"></i> Overall
                                        </li></Link>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Overall

                                </h2>
                                <div class="detgails-right-content">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="/assets/documents/NIRF2/Sridevi Womens Engineering College20230120- submit overall 20.01.2023.pdf"><i class="fa-solid fa-file-pdf"></i> Sridevi Women's Engineering College - NIRF - 2023</a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Overall
