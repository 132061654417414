import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';


const EEEVision = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Information Technology (IT)
                                </h2>
                                <div className='pt-3'>
                                    <div class="submit">
                                        <h5><i class="fa fa-eye text-red"></i> Vision</h5>
                                        <div class="pt-2">
                                            <p>
                                                Empowerment of women in the field of Electrical and Electronics Engineering by imparting core value based quality technical education to meet global needs.

                                            </p>

                                        </div>
                                    </div>

                                    <div class="">
                                        <h5><i class="fa fa-bullseye  text-red"></i> Mission</h5>
                                        <div class="pt-2">
                                            <div class="apply-list ">

                                                <ul class="pt-2">
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        To pursue excellence in electrical and electronics engineering education by providing positive and professional quality technical education.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        To enhance learning through practice of cutting edge technologies in Electrical and Electronics engineering with multidisciplinary approach to meet industry requirements.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        To conduct individual and team activities that enhances the managerial and professional skills.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-4'>
                                        <h5>Programme Educational Objectives (Peo's)</h5>
                                        <div class="apply-list ">
                                            <ul class="pt-2">
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-I : </strong>
                                                    To provide a good foundation in mathematics, sciences and engineering fundamentals to make them globally competent to excel in Electrical & Electronics Engineering field.

                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-II :  </strong>
                                                    To encourage students towards importance of power sector, green and energy efficient sources and to train them in critical issues of Electrical and Electronics Engineering.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-III : </strong>
                                                    To inculcate ethical values and aptitude for lifelong learning that enables them to stay abreast with latest technology needed for successful professional career.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-IV : </strong>
                                                    To equip graduates with the communication skills and team work with multi- disciplinary approach and zeal to provide solutions for complex engineering problems.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEEVision
