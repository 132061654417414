import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import ITLeftTab from '../ITlinks/ITLeftTab';

const IT = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>
                                    Department of Information Technology (IT)
                                </h2>
                                <p>
                                    The Department of Information Technology was established in the year 2001 with an intake of 60 and currently it has increased to 180. The IT Department has industry-oriented curriculum of both hardware and software. The objective is to provide skill-based education in the field of Information Technology. To fulfill this objective, the software, and hardware curriculum is updated regularly in the Board of Studies meetings.
                                </p>
                                <p>
                                    The Department has been accredited by NBA twice during the renewal cycles. The Department has well qualified and experienced faculty members with state of the art laboratories and provides enhanced learning facilities to engage in continuous learning and research.
                                </p>
                                <p>
                                    The Department has been accredited by NBA twice during the renewal cycles. The Department has well qualified and experienced faculty members with state of the art laboratories and provides enhanced learning facilities to engage in continuous learning and research.
                                </p>
                                <h5 className='pb-2 pt-3'>Best Practices of IT department</h5>
                                <div class="apply-list">
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The department regularly organizes Faculty Development Programs (FDPs), Short Term Training Programmes (STTPs),
                                            research seminars, Guest lectures on the curriculum needs and the recent trends in IT industries sponsored by
                                            IIT-BOMBAY, AICTE-ISTE, CSIR, CSI, Infosys, Oracle, Salesforce and many more.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            Students are motivated to participate in Code Challenge, Ideathon, Hackathon, TCS Codevita and All India MHRD projects
                                            to update their knowledge on par with the industry standards.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            Faculty members and students are motivated to attend the online courses like NPTEL/Swayam, Course era and Virtual Labs
                                            sponsored by IIT Kharagpur.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            Industry oriented Elective courses are being inculcated in curriculum in order to make the students industry ready
                                            professionals.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The Department has established innovation and research centers in learner friendly environment where the students are
                                            encouraged to participate in technical events, project expos and Ideathon organized in the college level as well as
                                            outside.
                                        </li>

                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The Department has 14 academic laboratories like programming for problem solving using C / Python, data structures,
                                            database management system, JAVA programming, Operating Systems, Digital Electronics, IoT, Software engineering,
                                            Compiler Construction, Computer Networks, Network Security web technologies, cloud application development, data
                                            warehousing and data mining, Machine Learning and object-oriented modelling, etc. According to the National Education
                                            Policy 2020, the new lab syllabus was framed for from JNTUH in these, <br />
                                            Skill Development Course like - Node JS/ React JS/ Django and Real-time Research Project/ Societal Related Project are
                                            included in the curriculum.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The infrastructure and lab facilities are upgraded from time to time to provide good practical learning and innovative
                                            environment for the students and researchers. The department has centres of excellence (COE) in the niche areas of
                                            technology through collaborations with leading industries like Cisco and Oracle.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                            The Department is associated with Students chapters like IEEE & CSI, Under this, we conduct workshops, seminars &
                                            bootcamps in emerging technologies like AI, ML, Cyber Security & IOT,
                                        </li>


                                    </ul>
                                </div>
                                <h5 className='pb-2 pt-3'>Highlights of the Department</h5>
                                <div class="apply-list">
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                        Three Students from our department achieved Academic gold medals from JNTUH University.
                                        </li>

                                        <li><i class="ri-checkbox-circle-line"></i>
                                        The Department has consistent placement records of 85 % and this year, one of our students is placed in the top MNC with a package of 12.47 LPA and other students with an average package of 6.5 LPA.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>
                                        Every year 80 % of the students undergo Internships in reputed companies.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default IT
