import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AytonomousTab from './AytonomousTab';


const AcademicAutonomous = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-5'>
                          <AytonomousTab />
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="how-to-apply">
                                <h2>Academic Calendar for B-Tech
                                </h2>
                            </div>
                            <div class="pt-2 pt-lg-3"><h6>Academic Calendar for B-Tech 2023-24</h6><div class="detgails-right-content mt-4"><div class="related-download bg-gray"><ul><li><a href="https://ik.imagekit.io/ckjdgz4vmo/btech1.pdf?updatedAt=1696932483182" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for B-Tech I semester 2023-24 </a></li><li><a href="https://ik.imagekit.io/ckjdgz4vmo/btech2.pdf?updatedAt=1696932482320" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for B-Tech II semester 2023-24 </a></li></ul></div></div></div>
                            <div class="pt-2 pt-lg-3"><h6>Academic Calendar for B-Tech/B.Pharm 2023-24</h6><div class="detgails-right-content mt-4"><div class="related-download bg-gray"><ul><li><a href="https://ik.imagekit.io/ckjdgz4vmo/BTechBPharmIIIYEARIIISEMESTERS_231007_223559.pdf?updatedAt=1697606818977" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for B-Tech/B.Pharm III YearI &amp; II Semisters 2023-24 </a></li><li><a href="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/IV%20Year%20B.Tech%20%20Autonomous%20Academic%20Calendar.pdf?updatedAt=1700113880635" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for B-Tech/B.Pharm IV YearI &amp; II semesters 2023-24 </a></li></ul></div></div></div>
                            <div class="how-to-apply"><h2> Academic Calendar for MBA</h2></div>
                            <div class="pt-2 pt-lg-3"><h6>Academic Calendar for MBA 2023-24</h6><div class="detgails-right-content mt-4"><div class="related-download bg-gray"><ul><li><a href="https://ik.imagekit.io/ckjdgz4vmo/MBA%20I%20Academic%20Calendar%202023-24.pdf?updatedAt=1697606819313" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for MBA  I year</a></li><li><a href="https://ik.imagekit.io/ckjdgz4vmo/mba2.pdf?updatedAt=1696932483317" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for MBA  II year</a></li></ul></div></div></div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default AcademicAutonomous
