import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';

const ItFaxultyInnovations = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Innovative Teaching Methodologies
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th width="65"><strong>S.No</strong></th>
                                                <th width="180"><strong>Name of the Full-time teacher</strong></th>
                                                <th width="220"><strong>Designation</strong></th>
                                                <th width="120"><strong>Subject</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr.Bokka Radhakrishna</td>
                                                <td>Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/1.WNMC bokka radhakrishna.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;WWMC</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Dr. Avinash</td>
                                                <td>Assoc. Professor &amp; Head</td>
                                                <td><a class="text-black" href="/assets/documents/it/2.COMPILER DESIGN BY AVINASH.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Compiler Design</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>B Narendra Kumar </td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/3.DCCN Narendra.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Data Communication &amp; Computer Network</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>M.Kavitha</td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/4.ecommerce KAVITHA.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;E-Commerce</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Shamal Telkar</td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/5.DAA-Shamal Telkar (1).pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Design &amp; Analysis Of Algorithms</a></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>C Swapna</td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/6.OS PPT Swapna.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Operating System</a></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>D.Sreelatha</td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/7.cloud computing sreelatha.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Cloud Computing</a></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>K.Samatha</td>
                                                <td>Assistant Professor</td>
                                                <td>
                                                    <a class="text-black" href="/assets/documents/it/8 .K.samatha.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Data Mining &amp; Data Warehousing</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>B.Lakshmi Narayana</td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/9.java by Lakshmi Narayana.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;OOP through JAVA</a></td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>N.Nitheesha </td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/10.N.NITHEESHA PPL.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Principles Of Programming Languages</a></td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>N. Namratha</td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/11.dbms by namartha.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Database Management Systems</a></td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>Ch.Anupama</td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/12.MFCS Anupama.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;MFCS</a></td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>Aaruni Giriraj</td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/13 dbms Aaruni Giriraj.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Database Management Systems</a></td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>K.Murlidhar</td>
                                                <td>Assistant Professor</td>
                                                <td><a class="text-black" href="/assets/documents/it/14. Daa K.Muralidhar.pdf" target="_blank"><i className='fa fa-file-pdf text-red'></i>&nbsp;Design &amp; Analysis Of Algorithms</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ItFaxultyInnovations
