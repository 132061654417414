import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import { Link } from "react-router-dom";
import AboutLeftTab from '../About/AboutLeftTab';

const Feedback = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* <AboutLeftTab /> */}
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Feedback

                                </h2>
                                <h6 className='pb-4'>AICTE Feedback Portal for Students and Faculty : <a target='_blank' href='https://www.aicte-india.org/feedback/' className='text-danger indiaoOrgText'>https://www.aicte-india.org/feedback</a>
                                </h6>
                                <div class="detgails-right-content">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a target="_blank"  href="/assets/documents/feedback/2020-21 FEED BACK ANALYSIS REPORT.pdf"><i class="fa-solid fa-file-pdf"></i>Students Report of Analysis </a>
                                            </li>
                                            <li>
                                                <a target="_blank"  href="/assets/documents/feedback/2020-21_ACTION TAKEN REPORT BY INSTITUTE.pdf"><i class="fa-solid fa-file-pdf"></i>Action Taken Report by Institute	
 </a>
                                            </li>
                                            <li>
                                                <a target="_blank"  href="/assets/documents/feedback/DEPARTMENT WISE ACTION TAKEN REPORT FOR 2020-21.pdf"><i class="fa-solid fa-file-pdf"></i>Department wise Action Taken Report </a>
                                            </li>
                                            <li>
                                                <a target="_blank"  href="/assets/documents/feedback/SAMPLE FILLED IN FEED BACK FORM FOR 2020-21.pdf"><i class="fa-solid fa-file-pdf"></i>Sample filled in feedback form	
 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Feedback
