import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';

const CseVision = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Computer Science and Engineering (CSE)
                                </h2>
                                <div className='pt-3'>
                                    <div class="submit">
                                        <h5><i class="fa fa-eye text-red"></i> Vision</h5>
                                        <div class="pt-2">
                                            <p>Attaining global recognition in computer science and engineering education for women to meet the needs of industry and society.
                                            </p>

                                        </div>
                                    </div>

                                    <div class="">
                                        <h5><i class="fa fa-bullseye  text-red"></i> Mission</h5>
                                        <div class="pt-2">
                                            <div class="apply-list ">

                                                <ul class="pt-2">
                                                    <li><i class="ri-checkbox-circle-line"></i>Continuous association with industry to adopt advanced technologies to enhance student’s technical competency.
                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>To create conducive environment that helps the students in acquiring knowledge and skills in the emerging areas of computer science and engineering </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>To inculcate ethical and social values, leadership qualities and professional behavior for improving the living standards.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-4'>
                                        <h5>Programme Educational Objectives (Peo's)</h5>
                                        <p>A graduate of Computer Science and Engineering program will be able to:
                                        </p>
                                        <div class="apply-list ">
                                            <ul class="pt-2">
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                <strong className='text-000'>PEO1 : </strong> Graduates are prepared with concrete foundation in Engineering fundamentals to solve computing related problems.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                <strong className='text-000'>PEO2 : </strong> Graduates are prepared to acquire knowledge in core areas of Computer Science and Engineering to meet the needs of industry.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                <strong className='text-000'>PEO3 : </strong>  Graduates are prepared to provide the advanced technologies, professional skills, communication skills and teamwork needed for successful professional.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                <strong className='text-000'>PEO4 : </strong> Graduates are prepared to exhibit leadership qualities, social consciousness and ethical values for lifelong learning.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>




                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default CseVision
