


import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';

const StudentProjects = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Student projects
                                </h2>
                                <div className='pt-2'>
                                    <div class="">

                                        <div class="">
                                            <p>Best projects of academic year 2019-2020
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="table-responsive">
                                            <table class="table table-bordered mtop10 committee-table">
                                                <tbody>
                                                    <tr class="bg-gray">
                                                        <td><strong>S.No</strong></td>
                                                        <td><strong>NAME OF THE STUDENTS</strong></td>
                                                        <td><strong>TITLE </strong></td>
                                                        <td><strong>ACADEMIC YEAR</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>K.Nagaramya sree </td>
                                                        <td rowspan="3">Autonomous robot for refinery inception </td>
                                                        <td rowspan="3">2019-2020 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>S.Bhargavi </td>

                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>V.Mounika </td>

                                                    </tr>

                                                    <tr>
                                                        <td>4</td>
                                                        <td>Soma Navya </td>
                                                        <td rowspan="3">Prediction of employee appraisal using AI </td>
                                                        <td rowspan="3">2019-2020 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Dumala Prathyusha  </td>

                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>Chetana.K  </td>

                                                    </tr>

                                                    <tr>
                                                        <td>7</td>
                                                        <td>Madugula Meghana </td>
                                                        <td rowspan="3">Statistical evaluation And prediction of Fuel utilization in vehicles </td>
                                                        <td rowspan="3">2019-2020 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>K Divya   </td>

                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>T Taranya Vandana Reddy </td>

                                                    </tr>

                                                    <tr>
                                                        <td>10</td>
                                                        <td>Prakriti Shah </td>
                                                        <td rowspan="3">Brain controlled Home automation</td>
                                                        <td rowspan="3">2019-2020 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>11</td>
                                                        <td>N.Mounika Reddy   </td>

                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>M.Bhavani </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}



export default StudentProjects
