import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';


const EEELibrary = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Infrastructure
                                </h2>
                                <p>EEE department has well equipped laboratories to conduct experiments as per JNTUH curriculum and also has facilitiesto conduct experiments over and above the syllabus.</p>

                                <div class="table-responsive mt-2">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="top"><p><strong>S.No</strong></p></td>
                                                <td align="center" valign="top"><p><strong>Lab Name</strong></p></td>
                                                <td align="center" valign="top"><p><strong>Area(Sq.m)</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td align="center"><p>1</p></td>
                                                <td align="center"><p>Networks </p></td>
                                                <td align="center"><p>80</p></td>
                                            </tr>
                                            <tr>
                                                <td align="center"><p>2</p></td>
                                                <td align="center"><p>Electrical Machines –I &amp; II</p></td>
                                                <td align="center"><p>240</p></td>
                                            </tr>
                                            <tr>
                                                <td align="center"><p>3</p></td>
                                                <td align="center"><p>Control Systems </p></td>
                                                <td align="center"><p>80</p></td>
                                            </tr>
                                            <tr>
                                                <td align="center"><p>4</p></td>
                                                <td align="center"><p>Power Electronics/Project Lab</p></td>
                                                <td align="center"><p>80</p></td>
                                            </tr>
                                            <tr>
                                                <td align="center"><p>5</p></td>
                                                <td align="center"><p>Electrical Measurements</p></td>
                                                <td align="center"><p>80</p></td>
                                            </tr>
                                            <tr>
                                                <td align="center"><p>6</p></td>
                                                <td align="center"><p>Power Systems</p></td>
                                                <td align="center"><p>160</p></td>
                                            </tr>
                                            <tr>
                                                <td align="center"><p>7</p></td>
                                                <td align="center"><p>Basic Electrical Simulation</p></td>
                                                <td align="center"><p>80</p></td>
                                            </tr>
                                            <tr>
                                                <td align="center"><p>8</p></td>
                                                <td align="center"><p>FM&amp;HM </p></td>
                                                <td align="center"><p>180</p></td>
                                            </tr>
                                            <tr>
                                                <td align="center"><p>9</p></td>
                                                <td align="center"><p>Electrical workshop</p></td>
                                                <td align="center"><p>160</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>




                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default EEELibrary
