import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const NSSProgramme = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'> NSS Programme

                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/1.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/1.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/2.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/2.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>



                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/4.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/4.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/5.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/5.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/6.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/6.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/7.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/7.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/8.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/8.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/9.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/9.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-12"></div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/10.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/10.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/11.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/11.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/3.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/3.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                           
                                        
                                            <div class="col-md-12 mbottom15 py-3">
                                                <h6>A visit to the Kasturba Gandhi National Memorial Trust at Hydershakote, Langer house as part of NSS led by
                                                    Mr.Dorababu</h6>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/12.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/12.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/others/13.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/13.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/nss/14.jpg">
                                                    <img class="" src="/assets/img/gallery/nss/14.jpg" alt="gallery image" loading="lazy" />
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default NSSProgramme
