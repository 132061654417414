import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';


const EEEMagazines = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Newsletter
                                </h2>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/eee/ARDUOUS VOL6 ISSUE 1 2020-21.docx" target='_blank'><i class="fa fa-download "></i> ARDUOUS VOL6 ISSUE1 2020-21  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/eee/ARDUOUS VOL5 ISSUE 2 2019-20.docx" target='_blank'><i class="fa fa-download  "></i>   ARDUOUS VOL5 ISSUE2 2019-20  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/eee/ARDUOUS VOL5 ISSUE 1 2019-20.docx" target='_blank'><i class="fa fa-download  "></i>   ARDUOUS VOL5 ISSUE1 2019-20  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/eee/ARDUOUS VOL4 ISSUE1 2018-19.docx" target='_blank'><i class="fa fa-download  "></i>  ARDUOUS VOL4 ISSUE1 2018-19   </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/eee/NOVUS VOL3 ISSUE1 2017-18.docx" target='_blank'><i class="fa fa-download  "></i>  NOVUS VOL3 ISSUE1 2017-18   </a>
                                            </li>

                                            <li>
                                                <a href="/assets/documents/eee/INNOVISION 2016 VOL2 ISSUE1 2016-17.docx" target='_blank'><i class="fa fa-download  "></i>   INNOVISION 2016 VOL2 ISSUE1 2016-17  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/eee/INNOVISION 2016 VOL2 ISSUE2 2016-17.docx" target='_blank'><i class="fa fa-download  "></i> INNOVISION 2016 VOL2 ISSUE2 2016-17  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/eee/INGENUITY 2K15 VOL1 ISSUE1 2015-16.docx" target='_blank'><i class="fa fa-download  "></i>   INGENUITY 2K15 VOL1 ISSUE1 2015-16  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/eee/INGENUITY 2K15 VOL1 ISSUE2 2015-16.docx" target='_blank'><i class="fa fa-download  "></i>  INGENUITY 2K15 VOL1 ISSUE2 2015-16   </a>
                                            </li>


                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default EEEMagazines
