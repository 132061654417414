import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';
import infosys from './placements/INFOSYS.png'
import aliens from './placements/aliens.png'
import amazon from './placements/amazon.png'
import cap from './placements/cap.png'
import intellinet from './placements/intellinet.png'
import ulearn from './placements/ulearn.png'
import valueLabs from './placements/valueLabs.png'
import genpact from './placements/genpact.png'
import icici from './placements/ICICI.png'

const EEEPlacements = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Placements & higher studies
                                </h2>
                                <div className='pt-3'>
                                    <h6 className='pb-2'>List of students - higher education </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td class="text-center"><strong>S.No.</strong></td>
                                                    <td><p><strong>Roll Number</strong></p></td>
                                                    <td><p><strong>Name </strong></p></td>
                                                    <td><p><strong>Higher Education </strong></p></td>
                                                    <td><p><strong>College </strong></p></td>
                                                    <td><p><strong>Present Roll Number</strong></p></td>
                                                    <td><p><strong>Year of pass out  </strong></p></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>17D25A0209 </td>
                                                    <td>U. Soniya </td>
                                                    <td>
                                                        M.Tech (Embedded
                                                        Systems)
                                                    </td>
                                                    <td>
                                                        Siddhartha Institute of
                                                        Technology and Science
                                                    </td>
                                                    <td>20TQ1D5503 </td>
                                                    <td>2019-20 </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>17D25A0203 </td>
                                                    <td>D.Mounika </td>
                                                    <td>
                                                        M.Tech (Electrical
                                                        Power Systems)
                                                    </td>
                                                    <td>VJIT  </td>
                                                    <td>20911D0704</td>
                                                    <td>2019-20 </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>16D25A010 </td>
                                                    <td>S. Sowmya</td>
                                                    <td>
                                                        M.Tech (Electrical
                                                        Power Systems)
                                                    </td>
                                                    <td>VJIT </td>
                                                    <td>-</td>
                                                    <td>2018-19 </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>14D21A0208 </td>
                                                    <td>T.Kaveri </td>
                                                    <td>M.Tech (DSCE)</td>
                                                    <td>
                                                        MLR Institute of
                                                        Technilogy
                                                    </td>
                                                    <td>19R21D0607 </td>
                                                    <td>2017-18 </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>14D21A0210 </td>
                                                    <td>
                                                        M.Lakshmi
                                                        Durga sreee
                                                    </td>
                                                    <td>
                                                        M.Tech (Power
                                                        Systems)
                                                    </td>
                                                    <td>VNR VJIET </td>
                                                    <td>19071D8308 </td>
                                                    <td>2017-18</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>14D21A0227 </td>
                                                    <td>
                                                        G. Sai
                                                        Meghana
                                                    </td>
                                                    <td>
                                                        M.Tech (Power
                                                        Systems)
                                                    </td>
                                                    <td>VNR VJIET </td>
                                                    <td>18071D4301 </td>
                                                    <td>2017-18 </td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>14D21A0232 </td>
                                                    <td>
                                                        Sara
                                                        Sheshikala
                                                    </td>
                                                    <td>MBA </td>
                                                    <td>
                                                        Sarojini Naidu Vanitha
                                                        Maha Vidyala
                                                    </td>
                                                    <td>117518672034 </td>
                                                    <td>2017-18 </td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>14D21A0236 </td>
                                                    <td>
                                                        Katkuri
                                                        Soujanya
                                                    </td>
                                                    <td>
                                                        M.tech (power
                                                        electronics and
                                                        electrical drive)
                                                    </td>
                                                    <td>
                                                        Siddhartha institute of
                                                        technology and sciences
                                                    </td>
                                                    <td>18TQ1D5408 </td>
                                                    <td>2017-18 </td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>14D21A0237 </td>
                                                    <td>
                                                        Soundarya
                                                        Vadlamani
                                                    </td>
                                                    <td>
                                                        MSc in Data
                                                        Science.
                                                    </td>
                                                    <td>
                                                        University of
                                                        Hertfordshire, England
                                                    </td>
                                                    <td>19055534 </td>
                                                    <td>2017-18 </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6 className='py-3'>List of students placed in 2015 to 2018
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop25">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td class="text-center"><p><strong>Item</strong></p></td>
                                                    <td><p><strong>CAYm1 (2017-18)</strong></p></td>
                                                    <td><p><strong>CAYm2 (2016-17)</strong></p></td>
                                                    <td><p><strong>CAYm3 (2015-16)</strong></p></td>
                                                </tr>
                                                <tr>
                                                    <td>Total Number of Final year students</td>
                                                    <td>55</td>
                                                    <td>47</td>
                                                    <td>52</td>
                                                </tr>
                                                <tr>
                                                    <td>No.of students placed in companies or government sector</td>
                                                    <td>34</td>
                                                    <td>25</td>
                                                    <td>27</td>
                                                </tr>
                                                <tr>
                                                    <td>No. of students admitted to higher studies with valid qualifying scores</td>
                                                    <td>2</td>
                                                    <td>1</td>
                                                    <td>2</td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6 className='py-3'>List of students placements
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop25">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td class="text-center"><p><strong>S.No.</strong></p></td>
                                                    <td><p><strong>Name</strong></p></td>
                                                    <td><p><strong>Roll Number</strong></p></td>
                                                    <td><p><strong>Working Company Name</strong></p></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td><strong>Ashrita Dasari</strong></td>
                                                    <td>14D21A0202</td>
                                                    <td><img src={infosys} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td><strong>Bharatha Bhargavi</strong></td>
                                                    <td>14D21A0204</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td><strong>Atti Dhana Lakshmi</strong></td>
                                                    <td>14D21A0206</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td><strong>M. Divya</strong></td>
                                                    <td>14D21A0207</td>
                                                    <td><img src={cap} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td><strong>Kovuri Lakshmiprasanna</strong></td>
                                                    <td>14D21A0211</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td><strong>Bachalakuri Mounika</strong></td>
                                                    <td>14D21A0215</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td><strong>M N Shruthi Priya</strong></td>
                                                    <td>14D21A0217</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td><strong>Vonnala Niharika</strong></td>
                                                    <td>14D21A0219</td>
                                                    <td><img src={amazon} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td><strong>Niharika.Vonnala</strong></td>
                                                    <td>14D21A0219</td>
                                                    <td><img src={intellinet} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td><strong>Rapolu Nithya Santhoshi</strong></td>
                                                    <td>14D21A0220</td>
                                                    <td><img src={valueLabs} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td><strong>Sabbu.Ravali</strong></td>
                                                    <td>14D21A0225</td>
                                                    <td><img src={cap} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td><strong>G Sai Meghana</strong></td>
                                                    <td>14D21A0227</td>
                                                    <td><img src="../../images/s-logos/.png" alt="Higher Education" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>13</td>
                                                    <td><strong>M Saipooja</strong></td>
                                                    <td>14D21A0228</td>
                                                    <td><img src={genpact} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>14</td>
                                                    <td><strong>M Saipooja</strong></td>
                                                    <td>14D21A0228  </td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>15</td>
                                                    <td><strong>Sagi Satya Avanija</strong></td>
                                                    <td>14D21A0230</td>
                                                    <td><img src={cap} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>16</td>
                                                    <td><strong>Sara.Sheshikala</strong></td>
                                                    <td>14D21A0232</td>
                                                    <td><img src="../../images/s-logos/.png" alt="Higher Education" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>17</td>
                                                    <td><strong>Shravya Nagamalla</strong></td>
                                                    <td>14D21A0233</td>
                                                    <td><img src={icici} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>18</td>
                                                    <td><strong>Vadlamani Soundarya</strong></td>
                                                    <td>14D21A0237</td>
                                                    <td><img src={intellinet} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>19</td>
                                                    <td><strong>Vadlamani Soundarya</strong></td>
                                                    <td>14D21A0206</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>20</td>
                                                    <td><strong>Billa Spandhana</strong></td>
                                                    <td>14D21A0238</td>
                                                    <td><img src={intellinet} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>21</td>
                                                    <td><strong>Manikya Sushma</strong></td>
                                                    <td>14D21A0241</td>
                                                    <td><img src={cap} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>22</td>
                                                    <td><strong>K Swathi</strong></td>
                                                    <td>14D21A0243</td>
                                                    <td><img src="../../images/s-logos/qspider.png" alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>23</td>
                                                    <td><strong>B.Bhargavi</strong></td>
                                                    <td>14D21A0203</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>24</td>
                                                    <td><strong>Bhavani Chandu</strong></td>
                                                    <td>14D21A0205</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>25</td>
                                                    <td><strong>Thammireddy Kaveri</strong></td>
                                                    <td>14D21A0208</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>26</td>
                                                    <td><strong>Baddam Krishnaveni</strong></td>
                                                    <td>14D21A0209</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>27</td>
                                                    <td><strong>M.Lakshmi Durga Sree</strong></td>
                                                    <td>14D21A0210</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>28</td>
                                                    <td><strong>Garrepalli Lavanya</strong></td>
                                                    <td>14D21A0212</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>29</td>
                                                    <td><strong>Nuthalapati Manasa</strong></td>
                                                    <td>14D21A0213</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>30</td>
                                                    <td><strong>Ponugoti Mounika</strong></td>
                                                    <td>14D21A0216</td>
                                                    <td><img src={aliens} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>31</td>
                                                    <td><strong>Nagakeerthi Vemulapalli</strong></td>
                                                    <td>14D21A0218</td>
                                                    <td><img src={amazon} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>32</td>
                                                    <td><strong>L Pranitha</strong></td>
                                                    <td>14D21A0221</td>
                                                    <td><img src={ulearn} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>33</td>
                                                    <td><strong>Kondikoppulapravallika</strong></td>
                                                    <td>14D21A0222</td>
                                                    <td><img src={ulearn} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>34</td>
                                                    <td><strong>Baddam Radhika</strong></td>
                                                    <td>Galipell Sai shruthi</td>
                                                    <td><img src={ulearn} alt="company logo" class="" /></td>
                                                </tr>
                                                <tr>
                                                    <td>35</td>
                                                    <td><strong>Galipell Sai shruthi</strong></td>
                                                    <td>14D21A0229</td>
                                                 <td><img src={ulearn} alt="company logo" class="" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default EEEPlacements
