import React from 'react'
import { Link } from "react-router-dom";

const CSEDStab = () => {
    return (
        <div className="additional-information">
            <div className="list">
                <ul className='text-start'>
                    <Link to="/dept/cse-ds"><li className='text-black'><i class="fa-sharp fa-solid fa-building"></i> <span>About the Department</span> </li></Link>
                    {/* <Link to="/dept/cse/vision-mission"><li className='text-black'><i class="fa fa-user fa-lg"></i> <span>Vision, Mission & PEO’s </span></li></Link>
                    <Link to="/dept/cse/po-and-pso"><li className='text-black'><i class="fa fa-user fa-lg"></i> <span>PO and PSO’s </span> </li></Link>
                    <Link to="/dept/cse/hod-profile"><li className='text-black'><i class="fa fa-user fa-lg"></i> <span>HOD Profile </span> </li></Link>
                    <Link to=""><li className='text-black'><i class="fa fa-users fa-lg"></i> <span>Faculty List </span> </li></Link>
                    <Link to="/dept/cse/student-projects"><li className='text-black'><i class="fa fa-users fa-lg"></i> Students projects</li></Link>
                    <Link to="/dept/cse/industry-visits"><li className='text-black'><i class="fa fa-users fa-lg"></i>  Industry visits</li></Link>
                    <Link to="/dept/cse/student-development-programs"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Industry internship/summer trainings </span> </li></Link>
                    <Link to="/dept/cse/syllabus"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Syllabus</span> </li></Link>
                    <Link to="/dept/cse/placements"><li className='text-black'><i class="fa fa-graduation-cap fa-lg"></i> <span>Placements & higher studies</span> </li></Link>
                    <Link to="/dept/cse/professional-bodies"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Professional bodies & activities</span> </li></Link>
                    <Link to="/dept/cse/magazines"><li className='text-black'><i class="fa fa-envelope fa-lg"></i> <span>Magazines & Newsletters</span> </li></Link>
                    <Link to="/dept/cse/faculty-publications"><li className='text-black'><i class="fa fa-users fa-lg"></i> <span>Faculty publications and achievements</span> </li></Link>
                    <Link to="/dept/cse/faculty-innovations"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Faculty innovations in teaching  & learning  </span> </li></Link>
                    <Link to="/dept/cse/workshops"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>FDP/STTPS/Workshops</span> </li></Link>
                    <Link to="/dept/cse/books-published"><li className='text-black'><i class="fa fa-book fa-lg"></i>  <span>Books Published</span></li></Link>
                    <Link to="/dept/cse/patent-publications"><li className='text-black'><i class="fa fa-book fa-lg"></i>  <span>Patent Publications </span></li></Link>
                    <Link to="/dept/cse/laboratory-projects"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span>R & D laboratory, projects & activities </span> </li></Link>
                    <Link to="/dept/cse/academic-sponsered"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Academic/sponsored research & consultancy</span> </li></Link>
                    <Link to="/dept/cse/bridge-course"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Bridge Course</span> </li></Link>
                    <Link to="/dept/cse/course-files"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Course Files </span> </li></Link>
                    <Link to="/dept/cse/laboratories"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Laboratories </span> </li></Link>
                    <Link to="/dept/cse/department-library"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Department library</span> </li></Link>
                    <Link to="/dept/cse/department-mous"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Department MOU’s</span> </li></Link>
                 */}
                 </ul>
            </div>
        </div>
    )
}


export default CSEDStab
