import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import MbaLeftTab from './MbaLeftTab';
import hod from './mbaImages/hod.png'


const MbaHod = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>HOD Profile
                                </h2>
                                <div className='pt-3 pb-4 pb-lg-5'> 
                                    <img src="/assets/img/mba-hod.jpeg" alt='mba-hod' loading='lazy' className='img-fluid pb-3' width="150" />
                                    <p><strong>Dr Uppugunduri Padmavathi, Professor M.COM ,MBA, NET, ACMA, Ph.D</strong> on “Corporate Governance in the Defense, Public Sector Enterprises of India, from the Department of Business Management, Osmania University Hyderabad. She is a graduate, Rastra Bhasha Praveena, from Dakshin Bharat Hindi Prachar Sabha, Chennai. She is an Associate member of Cost Accountants of India.</p>
                                    <p>She started her career as a lecturer in Sri Padmavathi Mahila Kalasala, Hyderabad, in 1987. She worked in multiple colleges across Hyderabad in various capacities as lecturer, professor, HOD, Controller of Examinations etc. She visited the University of Texas, Austin, USA, in connection with academic relations and curriculum & pedagogy for Business Management students. She has vast experience in the functional area of Finance and is the paper setter for several autonomous colleges.

                                    </p>
                                    <p>
                                        She has reviewed several chapters in the book “Security Analysis and Portfolio Management”, Published by Tata MC Graw Hill. She has presented papers at several National and International conferences.
                                    </p>
                                    <p>
                                        She conducted several training programs for National and International participants representing more than ten countries from Electricity Dept, at CIRE, Hyderabad. She was a guest faculty for management students of CDE programs at B.R.Ambedkar Open University. She has organized National Level workshops and Seminars on emerging areas of Finance, HR and Marketing. She also coordinated tours and cultural activities for students of Business Schools and Management Colleges, at various institutions. She focuses on the development of research instinct and skills among the staff and students and supports their all-round development both personally and professionally.


                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default MbaHod
