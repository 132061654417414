import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';
import GAUTAMI from './cseD/GAUTAMI.jpg'

const CSEFacultyPublications = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Faculty Achievements

                                </h2>
                                <div className=''>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl95" width="930">Faculty Publications - Summary Sheet</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl76">S.No</td>
                                                    <td class="gmail-xl76" >Name of the Faculty</td>
                                                    <td class="gmail-xl77" width="195">
                                                        Journal
                                                        Name
                                                    </td>
                                                    <td class="gmail-xl77" width="81" >
                                                        Journal
                                                        Type
                                                    </td>
                                                    <td class="gmail-xl77" width="243" >
                                                        Paper
                                                        Title
                                                    </td>
                                                    <td class="gmail-xl77" width="152">
                                                        ISSN
                                                        &amp; ISDN No
                                                    </td>
                                                    <td class="gmail-xl77" width="58" >
                                                        Volume
                                                        &amp; Issue
                                                    </td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl96 bg-light">2020-21</td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl99 bg-light" >SCI / ESCI</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">1</td>
                                                    <td class="gmail-xl70" >Dr. V.Anantha Krishna</td>
                                                    <td class="gmail-xl71" width="195">IJFGCN</td>
                                                    <td class="gmail-xl71" width="81" >ESCI</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Identification
                                                        of Fake Profiles in Social Media Using Advanced Machine Learning Techniques
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2233-7857</td>
                                                    <td class="gmail-xl71" width="58" >14,1</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">2</td>
                                                    <td class="gmail-xl70" >A. Saibabu</td>
                                                    <td class="gmail-xl71" width="195">IJFGCN</td>
                                                    <td class="gmail-xl71" width="81" >ESCI</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Identification
                                                        of Fake Profiles in Social Media Using Advanced Machine Learning Techniques
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2233-7857</td>
                                                    <td class="gmail-xl71" width="58" >14,1</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">3</td>
                                                    <td class="gmail-xl70" >B.Laxmi</td>
                                                    <td class="gmail-xl71" width="195">IJFGCN</td>
                                                    <td class="gmail-xl71" width="81" >ESCI</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Identification
                                                        of Fake Profiles in Social Media Using Advanced Machine Learning Techniques
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2233-7857</td>
                                                    <td class="gmail-xl71" width="58" >14,1</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69" >4</td>
                                                    <td class="gmail-xl70" >
                                                        Dr. T.K.S Rathish
                                                        Babu
                                                    </td>
                                                    <td class="gmail-xl71" width="195">JAIHC</td>
                                                    <td class="gmail-xl71" width="81" >SCI</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        a
                                                        novel efficient algorithm for duplicate video comparision in surveillance
                                                        video storage systems
                                                    </td>
                                                    <td class="gmail-xl71" width="152">10.1007/s12652-021-03119-7</td>
                                                    <td class="gmail-xl88" width="58" >-</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">5</td>
                                                    <td class="gmail-xl70" >Dr. B. Narendra Kumar</td>
                                                    <td class="gmail-xl71" width="195">IJGDC</td>
                                                    <td class="gmail-xl71" width="81" >SCI</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        A
                                                        Review on Intrusion Detection System using Machine Learning Techniques
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        1148
                                                        – 1163
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >13,2</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">6</td>
                                                    <td class="gmail-xl70" >K.Rajasekhar Rao</td>
                                                    <td class="gmail-xl71" width="195">IJFGCN</td>
                                                    <td class="gmail-xl71" width="81" >SCIE</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        empirical
                                                        data analysis of crime rate using enhansed ensemble and genetic algorithms.
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2233-7857</td>
                                                    <td class="gmail-xl71" width="58" >13,4</td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl93 bg-light" >SCOPUS</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">1</td>
                                                    <td class="gmail-xl70" >Dr. A.Gauthami Latha</td>
                                                    <td class="gmail-xl71" width="195">IJCSE</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        an
                                                        efficient skin cancer prognosis strategy using deep learning techniques.
                                                    </td>
                                                    <td class="gmail-xl71" width="152">0976-5166</td>
                                                    <td class="gmail-xl71" width="58" >12,1</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">2</td>
                                                    <td class="gmail-xl70" >
                                                        Dr. T.K.S Rathish
                                                        Babu
                                                    </td>
                                                    <td class="gmail-xl71" width="195">JCTN</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        A
                                                        Novel Approach to Detect the Level of Congestion in a Wireless Network
                                                    </td>
                                                    <td class="gmail-xl71" width="152">1688–1691</td>
                                                    <td class="gmail-xl71" width="58" >17,4</td>
                                                </tr>
                                                <tr height="53" >
                                                    <td height="53" class="gmail-xl69" >3</td>
                                                    <td class="gmail-xl70" >Dr. V.Anantha Krishna</td>
                                                    <td class="gmail-xl71" width="195">JCTN</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        A
                                                        Novel Approach to Detect the Level of Congestion in a Wireless Network
                                                    </td>
                                                    <td class="gmail-xl71" width="152">1688–1691</td>
                                                    <td class="gmail-xl71" width="58" >17,4</td>
                                                </tr>
                                                <tr height="60" >
                                                    <td height="60" class="gmail-xl69" >4</td>
                                                    <td class="gmail-xl70" >Dr. B. Narendra Kumar</td>
                                                    <td class="gmail-xl71" width="195">
                                                        GIS
                                                        SCIENCE JOURNAL
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl79" width="243" >
                                                        Neural
                                                        Network Rule Measures (NNRM) based Intrusion Detection System
                                                    </td>
                                                    <td class="gmail-xl71" width="152">1869-9391</td>
                                                    <td class="gmail-xl71" width="58" >7,12</td>
                                                </tr>
                                                <tr height="40" >
                                                    <td height="40" class="gmail-xl69" >5</td>
                                                    <td class="gmail-xl70" >Dr. D.Madhavi</td>
                                                    <td class="gmail-xl71" width="195">IJEAT</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl79" width="243" >
                                                        ‘Coherent
                                                        Light Weight Planning for Secure Cloud Computing’
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2249-8958</td>
                                                    <td class="gmail-xl71" width="58" >9,4</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">6</td>
                                                    <td class="gmail-xl70" >A.Ravikumar</td>
                                                    <td class="gmail-xl71" width="195">JGE</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        A
                                                        Power domain NOMA multiplexing for spectral efficiency improvemente randomly
                                                        available users
                                                    </td>
                                                    <td class="gmail-xl71" width="152">1483-1499</td>
                                                    <td class="gmail-xl71" width="58" >11,2</td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl99 bg-light" >OTHERS</td>
                                                </tr>
                                                <tr height="60" >
                                                    <td height="60" class="gmail-xl69" >1</td>
                                                    <td class="gmail-xl70" >Dr. D.Madhavi</td>
                                                    <td class="gmail-xl71" width="195">IJCRT</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl79" width="243" >
                                                        Detection
                                                        of frauds using local outlier factor and isolation algorithm for transaction
                                                        information
                                                    </td>
                                                    <td class="gmail-xl82" width="152" >2320-2882</td>
                                                    <td class="gmail-xl71" width="58" >8,7</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl69" >2</td>
                                                    <td class="gmail-xl70" >E.Krishnaveni Reddy</td>
                                                    <td class="gmail-xl71" width="195">IJFGCN</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        A
                                                        Survey - concepts of machine learning and its applications.
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2233-7857</td>
                                                    <td class="gmail-xl71" width="58" >13,4</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl69" >3</td>
                                                    <td class="gmail-xl70" >K.Anusha</td>
                                                    <td class="gmail-xl71" width="195">IJFGCN</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        A
                                                        Survey - concepts of machine learning and its applications.
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2233-7857</td>
                                                    <td class="gmail-xl71" width="58" >13,4</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">4</td>
                                                    <td class="gmail-xl70" >A.Ravikumar</td>
                                                    <td class="gmail-xl71" width="195">
                                                        JUNI
                                                        KHYAT
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        an
                                                        advanced wireless communication model for future application with big data
                                                        techniques.
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2278-4632</td>
                                                    <td class="gmail-xl71" width="58" >11,1</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">5</td>
                                                    <td class="gmail-xl70" >B.Suman</td>
                                                    <td class="gmail-xl71" width="195">
                                                        JUNI
                                                        KHYAT
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        an
                                                        advanced wireless communication model for future application with big data
                                                        techniques.
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2278-4632</td>
                                                    <td class="gmail-xl71" width="58" >11,1</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl69" >6</td>
                                                    <td class="gmail-xl70" >K.Sreenivasa Reddy</td>
                                                    <td class="gmail-xl71" width="195">
                                                        Dogo
                                                        Rangsang Research
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        a
                                                        study on the integration of machine learning in wireless communication.
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2347-7180</td>
                                                    <td class="gmail-xl71" width="58" >11,1</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl69" >7</td>
                                                    <td class="gmail-xl70" >P.Prashanth Kumar</td>
                                                    <td class="gmail-xl71" width="195">
                                                        Dogo
                                                        Rangsang Research
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        a
                                                        study on the integration of machine learning in wireless communication.
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2347-7180</td>
                                                    <td class="gmail-xl71" width="58" >11,1</td>
                                                </tr>
                                                <tr height="56">
                                                    <td height="56" class="gmail-xl69" >8</td>
                                                    <td class="gmail-xl70" >L.Manikandan</td>
                                                    <td class="gmail-xl71" width="195">
                                                        Journal
                                                        of Huazhong University of Science &amp; Technology
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        To
                                                        secure the generalized data using BR21 technique.
                                                    </td>
                                                    <td class="gmail-xl71" width="152">1671-4512</td>
                                                    <td class="gmail-xl71" width="58" >50,2</td>
                                                </tr>
                                                {/* <tr height="21" >
                                                    <td height="21" class="gmail-xl89" ></td>
                                                    <td class="gmail-xl90" ></td>
                                                    <td class="gmail-xl91" width="195" ></td>
                                                    <td class="gmail-xl91" width="81" ></td>
                                                    <td class="gmail-xl92" width="243"></td>
                                                    <td class="gmail-xl91" width="152"></td>
                                                    <td class="gmail-xl91" ></td>
                                                </tr>
                                                <tr height="21" >
                                                    <td height="21" class="gmail-xl89" ></td>
                                                    <td class="gmail-xl90" ></td>
                                                    <td class="gmail-xl91" width="195" ></td>
                                                    <td class="gmail-xl91" width="81" ></td>
                                                    <td class="gmail-xl92" width="243"></td>
                                                    <td class="gmail-xl91" width="152"></td>
                                                    <td class="gmail-xl91" ></td>
                                                </tr> */}
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl97 bg-light"  >2019-20</td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl99 bg-light" >SCI / ESCI</td>
                                                </tr>
                                                <tr height="56" >
                                                    <td height="56" class="gmail-xl69" >1</td>
                                                    <td class="gmail-xl70" >Dr. A. Gauthami Latha</td>
                                                    <td class="gmail-xl71" width="195">PONTE</td>
                                                    <td class="gmail-xl71" width="81" >SCI</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Smart
                                                        Switch Intelligence Using Classification and Clustering Algorithms
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >
                                                        0032
                                                        – 423X
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >76,3</td>
                                                </tr>
                                                <tr height="60" >
                                                    <td height="60" class="gmail-xl69">2</td>
                                                    <td class="gmail-xl70" >Dr. M Ramasubramanian</td>
                                                    <td class="gmail-xl71" width="195">PONTE</td>
                                                    <td class="gmail-xl71" width="81" >SCI</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Smart
                                                        Switch Intelligence Using Classification and Clustering Algorithms
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >
                                                        0032
                                                        – 423X
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >76,3</td>
                                                </tr>
                                                <tr height="56" >
                                                    <td height="56" class="gmail-xl69" >3</td>
                                                    <td class="gmail-xl70" >Dr.K.C Ravi Kumar</td>
                                                    <td class="gmail-xl71" width="195">IJKWI</td>
                                                    <td class="gmail-xl71" width="81" >SCI</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Determining
                                                        the semantic orientation of opinion words using typed dependencies for
                                                        opinion word senses and sentiwordnet scores from online product reviews.
                                                    </td>
                                                    <td class="gmail-xl87" width="152">10.1504/IJKWI.2019.103617</td>
                                                    <td class="gmail-xl71" width="58" >6,2</td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl99 bg-light" >SCOPUS</td>
                                                </tr>
                                                <tr height="48" >
                                                    <td height="48" class="gmail-xl69" >1</td>
                                                    <td class="gmail-xl70" >Dr. A. Gauthami Latha</td>
                                                    <td class="gmail-xl71" width="195">IJEAT</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Framework
                                                        for Image Retrieval Using Protege
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >
                                                        2249
                                                        – 8958
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,5</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl69" >2</td>
                                                    <td class="gmail-xl70" >Dr. A. Gauthami Latha</td>
                                                    <td class="gmail-xl71" width="195">IJITEE</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Semantic
                                                        Image Annotation Using Ontology And SPARQL
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >
                                                        2278
                                                        - 3075
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >9,3</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl69" >3</td>
                                                    <td class="gmail-xl70" >Dr.M.Ramasubramanian</td>
                                                    <td class="gmail-xl71" width="195">
                                                        Journal
                                                        of Physics Conference Series
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Mining
                                                        Specific Content Search Using Clustering Algorithm
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >10.10810.1088/1742-6596/1228/1/0120578</td>
                                                    <td class="gmail-xl71" width="58" >-</td>
                                                </tr>
                                                <tr height="44" >
                                                    <td height="44" class="gmail-xl69" >8</td>
                                                    <td class="gmail-xl70" >Dr.V.Anantha Krishna</td>
                                                    <td class="gmail-xl71" width="195">
                                                        Journal
                                                        of Physics Conference Series
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Mining
                                                        Specific Content Search Using Clustering Algorithm
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >10.10810.1088/1742-6596/1228/1/0120578</td>
                                                    <td class="gmail-xl71" width="58" >-</td>
                                                </tr>
                                                <tr height="38" >
                                                    <td height="38" class="gmail-xl69" >10</td>
                                                    <td class="gmail-xl70" >Dr. TKS Rathish Babau</td>
                                                    <td class="gmail-xl71" width="195">
                                                        Journal
                                                        of Physics Conference Series
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Mining
                                                        Specific Content Search Using Clustering Algorithm
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >10.10810.1088/1742-6596/1228/1/0120578</td>
                                                    <td class="gmail-xl71" width="58" >-</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl69" >4</td>
                                                    <td class="gmail-xl70" >Dr.V.Anantha Krishna</td>
                                                    <td class="gmail-xl71" width="195">IJRTE</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Software
                                                        Engineering Technique For Block chain Technology
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        2278
                                                        – 3878
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >9,2</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl69" >9</td>
                                                    <td class="gmail-xl70" >Dr. TKS Rathish Babu</td>
                                                    <td class="gmail-xl71" width="195">IJRTE</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Software
                                                        Engineering Technique For Block chain Technology
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        2277
                                                        – 3878
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,2</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">5</td>
                                                    <td class="gmail-xl70" >Dr.V.Anantha Krishna</td>
                                                    <td class="gmail-xl71" width="195">IJRTE</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        An
                                                        Internet of Things Based Software Defined Security Service Provisioning
                                                        Framework
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        2277
                                                        – 3878
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,6</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">6</td>
                                                    <td class="gmail-xl70" >A. Sai Babu</td>
                                                    <td class="gmail-xl71" width="195">IJRTE</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        An
                                                        Internet of Things Based Software Defined Security Service Provisioning
                                                        Framework
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        2277
                                                        – 3878
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,6</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">7</td>
                                                    <td class="gmail-xl70" >V.Prabhavathi</td>
                                                    <td class="gmail-xl71" width="195">IJRTE</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        An
                                                        Internet of Things Based Software Defined Security Service Provisioning
                                                        Framework
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        2277
                                                        – 3878
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,6</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">11</td>
                                                    <td class="gmail-xl70" >N. Sirisha</td>
                                                    <td class="gmail-xl71" width="195">JARDCS</td>
                                                    <td class="gmail-xl71" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl86" width="243">
                                                        Analysis
                                                        of Tumor Detection Technique by Using Data Mining Approach in Oncology Field
                                                    </td>
                                                    <td class="gmail-xl71" width="152">1943-023X</td>
                                                    <td class="gmail-xl71" width="58" >11</td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl99 bg-light" >OTHERS</td>
                                                </tr>
                                                <tr height="64" >
                                                    <td height="64" class="gmail-xl69" >1</td>
                                                    <td class="gmail-xl70" >Dr. M Ramasubramanian</td>
                                                    <td class="gmail-xl71" width="195">
                                                        international
                                                        journal of advanced research and innovation
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        An
                                                        Efficient Security System in Content Sharing Image Sites
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        2139
                                                        – 9253
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,3</td>
                                                </tr>
                                                <tr height="44" >
                                                    <td height="44" class="gmail-xl69" >2</td>
                                                    <td class="gmail-xl70" >Dr.V.Anantha Krishna</td>
                                                    <td class="gmail-xl71" width="195">JASC</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Fire
                                                        Fighting Robot With Artificial Intelligence
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        1076
                                                        - 5131
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,2</td>
                                                </tr>
                                                <tr height="47" >
                                                    <td height="47" class="gmail-xl69" >3</td>
                                                    <td class="gmail-xl70" >Dr. TKS Rathish Babau</td>
                                                    <td class="gmail-xl71" width="195">JASC</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Fire
                                                        Fighting Robot With Artificial Intelligence
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        1076
                                                        - 5131
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,2</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">4</td>
                                                    <td class="gmail-xl70" >N. Sujatha Kumari</td>
                                                    <td class="gmail-xl71" width="195">
                                                        The
                                                        International Journal of Analytical and Experimental Modal Analysis
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Smart
                                                        Plantation With IOT
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        0886
                                                        – 9367
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >6,9</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">5</td>
                                                    <td class="gmail-xl70" >M.Pragathi</td>
                                                    <td class="gmail-xl71" width="195">
                                                        The
                                                        International Journal of Analytical and Experimental Modal Analysis
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Smart
                                                        Plantation With IOT
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        0886
                                                        – 9367
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >6,9</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">6</td>
                                                    <td class="gmail-xl70" >K. Rajashekhar Rao</td>
                                                    <td class="gmail-xl71" width="195">
                                                        International
                                                        Journal For Recent Developments In Science And Technology
                                                    </td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Protected
                                                        and Flexible Multi-keyword Score search Model Over Encoded Cloud Data
                                                    </td>
                                                    <td class="gmail-xl71" width="152">
                                                        2581
                                                        – 4575
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >3,8</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl69">7</td>
                                                    <td class="gmail-xl70" >S. Mounasri</td>
                                                    <td class="gmail-xl71" width="195">IJSER</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Mining
                                                        Bigdata in Context with Random Forest using Machine Learning
                                                    </td>
                                                    <td class="gmail-xl71" width="152">2229-5518</td>
                                                    <td class="gmail-xl71" width="58" >10,7</td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl98 bg-light" >2018-19</td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl93 bg-light" >SCOPUS</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl84" >1</td>
                                                    <td class="gmail-xl70" >Dr. A. Gauthami Latha</td>
                                                    <td class="gmail-xl82" width="195" >
                                                        Springer
                                                        Nature
                                                    </td>
                                                    <td class="gmail-xl82" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl78" width="243">
                                                        An
                                                        Efficient Content-Based Image Retrieval (CBIR) Using GLCM for Feature
                                                        Extraction
                                                    </td>
                                                    <td class="gmail-xl80" width="152" >10.1007/978-981-10-3779-5_4</td>
                                                    <td class="gmail-xl82" width="58" >555</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl84" >2</td>
                                                    <td class="gmail-xl84" >Dr. B.Narendra Kumar</td>
                                                    <td class="gmail-xl82" width="195" >JARDCS</td>
                                                    <td class="gmail-xl82" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl81" width="243" >
                                                        Selective
                                                        Feature Mechanism for Two-Phase Intrusion Detection System
                                                    </td>
                                                    <td class="gmail-xl82" width="152" >
                                                        10.02
                                                        SI/jardcs.pp1573-1587
                                                    </td>
                                                    <td class="gmail-xl82" width="58" >10,2</td>
                                                </tr>
                                                <tr height="84" >
                                                    <td height="84" class="gmail-xl84" >3</td>
                                                    <td class="gmail-xl84" >Dr. B.Narendra Kumar</td>
                                                    <td class="gmail-xl82" width="195" >INASS</td>
                                                    <td class="gmail-xl82" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl78" width="243">
                                                        Enhancing
                                                        the Performance of an Intrusion Detection System Through Multi-Linear
                                                        Dimensionality Reduction and Multi-Class SVM
                                                    </td>
                                                    <td class="gmail-xl80" width="152" >10.22266/ijies2018.0228.19</td>
                                                    <td class="gmail-xl82" width="58" >11,1</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl84" >4</td>
                                                    <td class="gmail-xl84" >Dr. B.Narendra Kumar</td>
                                                    <td class="gmail-xl82" width="195" >IJEECS</td>
                                                    <td class="gmail-xl82" width="81" >SCOPUS</td>
                                                    <td class="gmail-xl78" width="243">
                                                        A
                                                        novel approach for selective feature mechanism for two-phase intrusion
                                                        detection system
                                                    </td>
                                                    <td class="gmail-xl80" width="152" >2502-4752</td>
                                                    <td class="gmail-xl82" width="58" >14,1</td>
                                                </tr>
                                                <tr height="21" >
                                                    <td colspan="7" align="center" height="21" class="gmail-xl94 bg-light" >OTHERS</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl84" >1</td>
                                                    <td class="gmail-xl69">Dr.M.Ramasubramanian</td>
                                                    <td class="gmail-xl71" width="195">IJIEMR</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        An
                                                        Approach for Advanced Multi Authority CP – ABE Access Control Scheme for
                                                        Public Cloud Storage
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >
                                                        2456
                                                        – 5083
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,1</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl84" >2</td>
                                                    <td class="gmail-xl74" >
                                                        Dr. V. Anantha
                                                        Krishna
                                                    </td>
                                                    <td class="gmail-xl71" width="195">IJIEMR</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        An
                                                        Approach for Advanced Multi Authority CP – ABE Access Control Scheme for
                                                        Public Cloud Storage
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >
                                                        2456
                                                        – 5083
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,1</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl84" >3</td>
                                                    <td class="gmail-xl74" >Dr.T.K.S Rathish Babu</td>
                                                    <td class="gmail-xl71" width="195">IJIEMR</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        An
                                                        Approach for Advanced Multi Authority CP – ABE Access Control Scheme for
                                                        Public Cloud Storage
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >
                                                        2456
                                                        – 5083
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >8,1</td>
                                                </tr>
                                                <tr height="84" >
                                                    <td height="84" class="gmail-xl84" >4</td>
                                                    <td class="gmail-xl85" >Dr. B.Narendra Kumar</td>
                                                    <td class="gmail-xl82" width="195" >IOSRJEN</td>
                                                    <td class="gmail-xl82" width="81" >UGC</td>
                                                    <td class="gmail-xl81" width="243" >
                                                        A
                                                        Comparative Survey on the Influence of Machine Learning Techniques on
                                                        Intrusion Detection System (IDS)
                                                    </td>
                                                    <td class="gmail-xl80" width="152" >2278-8719</td>
                                                    <td class="gmail-xl82" width="58" >8,8</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl84" >5</td>
                                                    <td class="gmail-xl70" >K. Rajashekhar Rao</td>
                                                    <td class="gmail-xl71" width="195">IJRDST</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Productivity
                                                        Intrusion Detection System Using Genetic Algorithm
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >
                                                        2581
                                                        – 4575
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >3,4</td>
                                                </tr>
                                                <tr height="42" >
                                                    <td height="42" class="gmail-xl84" >6</td>
                                                    <td class="gmail-xl70" >K. Rajashekhar Rao</td>
                                                    <td class="gmail-xl71" width="195">IJRDST</td>
                                                    <td class="gmail-xl71" width="81" >UGC</td>
                                                    <td class="gmail-xl72" width="243" >
                                                        Enhanced
                                                        Home Security With E-Mail and SMS Alert System
                                                    </td>
                                                    <td class="gmail-xl73" width="152" >
                                                        2581
                                                        – 4575
                                                    </td>
                                                    <td class="gmail-xl71" width="58" >3,1</td>
                                                </tr>
                                                <tr height="63" >
                                                    <td height="63" class="gmail-xl84" >7</td>
                                                    <td class="gmail-xl70" >K. Rajashekhar Rao</td>
                                                    <td class="gmail-xl82" width="195" >IJARST</td>
                                                    <td class="gmail-xl82" width="81" >UGC</td>
                                                    <td class="gmail-xl81" width="243" >
                                                        MINING
                                                        SPECIFIC CONTENT SEARCH USINGCLUSTERING ALGORITHM
                                                    </td>
                                                    <td class="gmail-xl80" width="152" >
                                                        2457–
                                                        0362
                                                    </td>
                                                    <td class="gmail-xl82" width="58" >8,6</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='my-3'>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead class="bg-gray">
                                                    <tr>
                                                        <th width="65"><strong>S.No</strong></th>
                                                        <th width="180"><strong>Academic Year</strong></th>
                                                        <th width="220"><strong>List of student’s paper  publications</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>2020-21  </td>
                                                        <td>03-05-2019 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>2019-20 </td>
                                                        <td>3 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>2018-19  </td>
                                                        <td>1 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>2017-18  </td>
                                                        <td>1 </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="table-responsive" >
                                        <table class="table table-bordered">
                                            <thead class="bg-gray">
                                                <tr>
                                                    <th><strong>S.No</strong></th>
                                                    <th><strong>Academic Year</strong></th>
                                                    <th><strong>Name of  the student</strong></th>
                                                    <th><strong>Roll No </strong></th>
                                                    <th><strong>Paper Title </strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>2020-21  </td>
                                                    <td>Krithika  </td>
                                                    <td>17D21A0554 </td>
                                                    <td>
                                                        Disseminate Use of Artificial
                                                        Intelligence in the World of
                                                        Technology
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>2019-20</td>
                                                    <td colspan="3">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td width="160">V.Yamini   </td>
                                                                    <td class="text-center">15D21A05M1 </td>
                                                                    <td >
                                                                        Smart plantation with IOT
                                                                    </td>
                                                                </tr>
                                                                <tr><td>&nbsp;</td></tr>
                                                                <tr>
                                                                    <td width="160">K.Jyothirmai </td>
                                                                    <td class="text-center">16D21A05E4   </td>
                                                                    <td >
                                                                        DrawAI: Conversion of
                                                                        Sketch to Code using Faster
                                                                        R-CNN
                                                                    </td>
                                                                </tr>
                                                                <tr><td>&nbsp;</td></tr>
                                                                <tr>
                                                                    <td width="160">K.Bhavana </td>
                                                                    <td class="text-center">16D21A05D8   </td>
                                                                    <td >
                                                                        Object Discernment using
                                                                        Scale Invariant Feature
                                                                        Transform with Python
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>2018-19 </td>
                                                    <td>Krithika   </td>
                                                    <td>17D21A0554 </td>
                                                    <td>
                                                        The Most Promising Job
                                                        Skills in Demand for the Next
                                                        10 Years
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>2017-18 	</td>
                                                    <td>Krithika   </td>
                                                    <td>17D21A0554 </td>
                                                    <td>
                                                        LISS: Simplest Way To Shed
                                                        Extra Fat
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="detgails-right-content mt-4">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li><a href="/assets/documents/cse/intenship cse data(2).pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> Internship </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CSEFacultyPublications
