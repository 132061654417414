





import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import MbaLeftTab from './MbaLeftTab';


const MbaInnovativeTeachig = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Innovative Teaching Methodologies
                                </h2>
                
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th width="65"><strong>S.No</strong></th>
                                                <th width="180"><strong>Name of the Full-time teacher</strong></th>
                                                <th width="120"><strong>Designation</strong></th>
                                                <th width="220"><strong>Subject</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr.U.Padmavathi</td>
                                                <td>Professor</td>

                                                <td>
                                                    <a className="text-black"  href="/assets/documents/mba/Dr U Padmavathi (cost and managemnet accounting (cma).pdf"
                                                        target="_blank"><i class="fa fa-file pdf text-red"></i>&nbsp;
                                                        (CMA) Cost and management
                                                        accounting
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>B.Sudhakar Reddy</td>
                                                <td>Assistant Professor</td>
                                                <td><a className="text-black"  href="/assets/documents/mba/2.B Sudhakar reddy  (Business ecomomics).pdf" target="_blank"><i class="fa fa-file pdf text-red"></i>&nbsp;Business
                                                    Economics(BE)</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>K.Sri Ranga Lakshmi </td>
                                                <td>Assistant Professor</td>

                                                <td>
                                                    <a className="text-black"  href="/assets/documents/mba/3.K.Sri Ranga Lakshmi  QABD.pdf" target="_blank"><i class="fa fa-file pdf text-red"></i>&nbsp;
                                                        QABD(Quantitative Analysis for
                                                        Business Decisions)
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>4</td>
                                                <td>N.Hima Bindu</td>
                                                <td>Assistant Professor</td>

                                                <td>
                                                    <a className="text-black"  href="/assets/documents/mba/4. N Himabindu   MIR.pdf" target="_blank"><i class="fa fa-file pdf text-red"></i>&nbsp;
                                                        MIR(Management of industrial
                                                        relations
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>M. Sandhya </td>
                                                <td>Assistant Professor</td>

                                                <td><a className="text-black"  href="/assets/documents/mba/5. M Sandhya  BC.pdf" target="_blank"><i class="fa fa-file pdf text-red"></i>&nbsp;BCS(Business communication lab )
                                                </a></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>G.Aruna</td>
                                                <td>Assistant Professor</td>

                                                <td>
                                                    <a className="text-black"  href="/assets/documents/mba/6. G Aruna  HRM (2).pdf" target="_blank"><i class="fa fa-file pdf text-red"></i>&nbsp;
                                                        HRM(Human Resource
                                                        Management)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Mrs .Ch.Deepthi Reddy</td>
                                                <td>Assistant Professor</td>

                                                <td>
                                                    <a className="text-black"  href="/assets/documents/mba/7. Ch.Deepthi mam IFM-1.pdf" target="_blank"><i class="fa fa-file pdf text-red"></i>&nbsp;
                                                        IFM(international financial
                                                        management)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>
                                                    Mr.s Vittal Reddy
                                                </td>
                                                <td>Assistant Professor</td>

                                                <td>
                                                    <a className="text-black"  href="/assets/documents/mba/8. Vittal reddy POM.pdf" target="_blank"><i class="fa fa-file pdf text-red"></i>&nbsp;
                                                        POM(Production Operations
                                                        Management)
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>G.Sneha </td>
                                                <td>Assistant Professor</td>
                                                <td><a className="text-black"  href="/assets/documents/mba/9.G.Sneha IPR.pdf" target="_blank"><i class="fa fa-file pdf text-red"></i>&nbsp; IPR(intellectual property rights
                                                    )</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default MbaInnovativeTeachig
