import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';

const CSEpo = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Computer Science and Engineering (CSE)
                                </h2>
                                <div className='pt-3'>
                                    <div class="">
                                        <h5> Programme Outcomes (Po's)</h5>
                                        <div class="pt-2">
                                            <p>Engineering Graduates will be able to:
                                            </p>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div class="apply-list border-0">
                                            <ul class="pt-2">
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO1 : Engineering knowledge : </strong>Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO2 : Problem analysis : </strong> Identify, formulate, review research literature, and analyze complex engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO3 Design/development of solutions : </strong>  Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and environmental considerations.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO4 : Conduct investigations of complex problems : </strong>  Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO5 : Modern tool usage : </strong>  Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO6 : The engineer and society : </strong> Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO7 : Environment and sustainability : </strong> Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO8: Ethics : </strong> Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO9 : Individual and team work : </strong>  Function effectively as an individual, and as a member or leader in diverse teams, and in multidisciplinary settings.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO10 : Communication : </strong> Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give and receive clear instructions.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO11 : Project management and finance : </strong> Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member and leader in a team, to manage projects and in multidisciplinary environments.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong className='text-000'>PO12 : Life-long learning: </strong> Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="apply-list border-0">
                                            <h5>Program Specific Outcomes (Psos)</h5>
                                            <ul class="pt-2">

                                                <li><i class="ri-checkbox-circle-line"></i> To possess competent skills and knowledge for innovations in computer science and engineering. </li>
                                                <li><i class="ri-checkbox-circle-line"></i> An ability to involve the students in multi-disciplinary environment for understanding the functionality of computer system effectively.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default CSEpo
