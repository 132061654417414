import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const AlumniMeet2020 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Alumni Meet 2020
                                    </h2>
                                    <div className='py-2'>
                                        <p>
                                            The SWEC Alumni meet is conducted on the first Saturday of December every year and this year it was conducted online on Dec 5th 2020, . We invited all our students from 2013-2020 batches and around 70 students attended the alumni meet. The inaugural ceremony started at 11am with lighting of the lamp and after the Principal's address, it was open to all alumni students to express their thoughts and experiences. Students from various departments shared their thoughts and were delighted to connect with their faculty and colleagues online.
                                        </p>
                                        <p>
                                            The SWEC Alumni committee has Faculty who are our own alumni and we are extremely proud of them. The meet ended successfully with all Alumni hoping to meet in person once the pandemic is over.
                                        </p>
                                    </div>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/eventsImgs/Alumni Meet 2020.jpg">
                                                    <img src="/assets/img/eventsImgs/Alumni Meet 2020.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default AlumniMeet2020
