import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';

const EceAnalysis = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Result Analysis

                                </h2>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/ece/2019-20 4-2.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  2019-20 4-2   </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2019-20 4-1.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  2019-20 4-1  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2019-20 3-2.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2019-20 3-2  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2019-20 3-1.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  2019-20 3-1  </a>
                                            </li> <li>
                                                <a href="/assets/documents/ece/2019-20 2-2.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2019-20 2-2  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2019-20  2-1.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2019-20 2-1  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2018-19 4-2.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2018-19 4-2   </a>
                                            </li> 
                                            <li>
                                                <a href="/assets/documents/ece/2018-19 4-1.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>2018-19 4-1   </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2018-19 3-2.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  2018-19 3-2 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2018-19 3-2.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2018-19 3-1   </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2018-19 2-2.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>2018-19 2-2   </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/2018-19 2-1.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> 2018-19 2-1  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/ece/Result Analysis summary.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  Result Analysis summary   </a>
                                            </li>
                                            
                                           
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EceAnalysis
