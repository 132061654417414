import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const WomensDayCelebrations2023 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-3'> International Women's Day Celebrations 2023
                                    </h2>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div className='row'>

                                            <div class="popup-gallery clearfix ptop15 row">
                                                <div class="col-md-6 ">
                                                    <a class="spotlight" href="/assets/img/womens-day-2023/1.jpg">
                                                        <img src="/assets/img/womens-day-2023/1.jpg" alt="Image" loading="lazy" class="img-responsive center-block"  />
                                                    </a>
                                                </div>
                                                <div class="col-md-6 ">
                                                    <a class="spotlight" href="/assets/img/womens-day-2023/3.jpg">
                                                        <img src="/assets/img/womens-day-2023/3.jpg" alt="Image" loading="lazy" class="img-responsive center-block"  />
                                                    </a>
                                                </div>
                                                <div class="col-md-12">&nbsp;</div>
                                                <div class="col-md-6 mb-3">
                                                    <a class="spotlight" href="/assets/img/womens-day-2023/2.jpg">
                                                        <img src="/assets/img/womens-day-2023/2.jpg" alt="Image" loading="lazy" class="img-responsive center-block"  />
                                                    </a>
                                                </div>

                                                <div class="col-md-6 mb-3">
                                                    <a class="spotlight" href="/assets/img/womens-day-2023/4.jpg">
                                                        <img src="/assets/img/womens-day-2023/4.jpg" alt="Image" loading="lazy" class="img-responsive center-block"  />
                                                    </a>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}



export default WomensDayCelebrations2023
