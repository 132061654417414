import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'


const Admissions = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply ">
                                <h2>Admissions
                                </h2>
                                <div className='pt-2 apply-list border-0'>
                                    <h5>UG Courses: B.Tech
                                    </h5>
                                    <p>The students seeking admission to SWEC have to qualify in the EAMCET ( Engineering, Agricultural and Medical Common Entrance Test ) examination conducted by Govt. of Telangana. To be eligible for appearing in the EAMCET examination, the student should have passed the 10 + 2 with Maths, Physics and Chemistry as their mainstream.
                                    </p>
                                    <p>The candidates should attend the counseling for admission into the Engineering colleges conducted by the Convenor. Based on the rank obtained in EAMCET, candidates can opt for SWEC as per the availability of the college and branch at the time of counselling.
                                    </p>
                                    <p>The candidates - The students seeking admission to SWEC.
                                    </p>
                                    <p className='d-flex'><strong> Fees</strong>&nbsp;  For Convener seat it is <strong>&nbsp;   Rs.1.10 lakh/-</strong></p>
                                    <p><strong>For more details please contact </strong></p>
                                    <p>For Admissions call: <span className='fw-bold text-dark'>+91 99594 25729</span></p>
                                    <h6 className='pt-4b-3'>Under Lateral entry scheme
                                    </h6>
                                    <p>In addition, diploma holders are admitted into second year B.Tech to the extent of 10% intake based on the merit in ECET, under lateral entry scheme.

                                    </p>
                                    <h6 className='pt-4 pb-3'>Criteria for Admission into B.Tech
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>Admissions to the Institute are made along with the other Engineering colleges in the state through a common entrance test (EAMCET) conducted by the Govt. of Telangana.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>The minimum qualification for admission to the first year of the B.Tech course is a pass in the Intermediate (10 + 2) conducted by the board of Intermediate Education, Govt. of Telangana or any other examination recognized as equivalent thereto with Mathematics, Physics and Chemistry as optional subjects.</li>
                                    </ul>
                                    <h5 className='pt-4 pb-2'>PG Courses: MBA
                                    </h5>
                                    <h6 className='pb-2'>Criteria for Admission into MBA
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>The minimum qualification for admission to the first year of the MBA is a pass in an undergraduate course (10 + 2 + 3).</li>
                                        <li><i class="ri-checkbox-circle-line"></i>The seats are allotted based on the merit, through counseling in TSICET conducted by Govt of Telangana.</li>
                                        <li><i class="ri-checkbox-circle-line"></i><p>Fee for convener seat - <strong>Rs 55,000</strong></p></li>
                                    </ul>
                                </div>
                                <h6>2022-23 Intake Details</h6>
                                <div class="table-responsive pt-2">
                                    <table class="table table-bordered mtop10">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Courses</strong></td>
                                                <td align="center" valign="middle"><strong>2022-23 Intake</strong></td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">CSE</td>
                                                <td align="center" valign="middle">240</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">CSE ( Data Science)</td>
                                                <td align="center" valign="middle">90</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">CSE ( AI/ ML)</td>
                                                <td align="center" valign="middle">180</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">IT</td>
                                                <td align="center" valign="middle">180</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">ECE</td>
                                                <td align="center" valign="middle">120</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6 className='pt-3'>Previous Intake Details
                                </h6>
                                <div class="table-responsive pt-2">
                                    <table class="table table-bordered mtop10">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>Courses</strong></td>
                                                <td align="center" valign="middle"><strong>2021-22 Intake</strong></td>
                                                <td align="center" valign="middle"><strong>2020-21 Intake</strong></td>
                                                <td align="center" valign="middle"><strong>2019-20 Intake</strong></td>
                                                <td align="center" valign="middle"><strong>2018-19 Intake</strong></td>
                                                <td align="center" valign="middle"><strong>2017-18 Intake</strong></td>
                                                <td align="center" valign="middle"><strong>2016-17 Intake</strong></td>

                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">CSE</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>

                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">ECE</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>
                                                <td align="center" valign="middle">240</td>

                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">IT</td>
                                                <td align="center" valign="middle">120</td>
                                                <td align="center" valign="middle">120</td>
                                                <td align="center" valign="middle">120</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>

                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">EEE</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>

                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">MBA</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>
                                                <td align="center" valign="middle">60</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>For Admissions call: <span className='fw-bold text-dark'>+91 99594 25729</span></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default Admissions
