import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import one from '../Activities/activities/1.jpg'
import two from '../Activities/activities/2.jpg'
import three from '../Activities/activities/3.jpg'
import four from '../Activities/activities/4.jpg'
import five from '../Activities/activities/5.jpg'
import six from '../Activities/activities/6.jpg'
import seven from '../Activities/activities/7.jpg'

import ActivitiesLeftTab from './ActivitiesLeftTab';

const OBE = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ActivitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> OBE (OUTCOME BASED EDUCATION)
                                </h2>
                                <p><strong>We are glad to have received insights about Outcome Based Education by knowledgeable and experienced professors from NIT Warangal and IIIT Kanchipuram.
                                </strong></p>

                                <p>Faculty Development Program on Outcome Based Education was organized by the College at regular intervals. All the Faculty Members participated in the FDP including senior and junior faculty from all the departments.

                                </p>
                                <p>Dr. Baburao M.Tech. PhD, Retd. Professor of CSE department of NIT Warangal explained in depth- process for development of Vision, Mission and PEO’s in OBE. In another session, Prof. Somayajulu emphasized on OBE Philosophy, Bloom’s taxonomy levels and CO’s development for Technical Institutions.

                                </p>
                                <p>It was a pleasure to listen to Prof. Banshidhar Majhi Director IIIT Kanchipuram, Chennai elaborate on the significance of Faculty information and contributions in OBE. Prof. NVS Narasimha Sarma ECE department of NIT Warangal explained how curriculum is mapped to PO’s and PSO’s and how it must be correlated to OBE practices.

                                </p>
                                <p>Prof. DVSS Siva Sarma of EEE Department and Dean of NIT Warangal explained the importance of mapping between PO’s, PSO’s and CO’s. Prof. G Yesu Ratnam EEE department, College of Engineering from Osmania University spoke on OBE evaluation of attainment of CO’s and PO’s and how each one is correlated. All the sessions lead by these experienced facilitators were descriptive, interactive and learning based in line with OBE process.
                                </p>

                                <div className='pt-4'>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop20">
                                            <thead class="bg-gray">
                                                <tr>
                                                    <th width="150"><strong>Date</strong></th>
                                                    <th><strong>OBE Expert</strong></th>
                                                    <th><strong>Title</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>17th April 2018</td>
                                                    <td>Dr Baburao M.Tech, PhD Retd. Prof NIT Warangal</td>
                                                    <td>Process for development of Vision, Mission and PEO’s in OBE</td>
                                                </tr>
                                                <tr>
                                                    <td>10th June 2017</td>
                                                    <td>Prof. DVLN Somayajulu M.Tech, PhD NIT Warangal</td>
                                                    <td>Bloom’s taxonomy levels and CO’s development</td>
                                                </tr>
                                                <tr>
                                                    <td>12th June 2018</td>
                                                    <td>Prof. Banshidhar Majhi M.Tech, PhD Director, IITDM Kanchipuram,Chennai</td>
                                                    <td>Significance of Faculty information and contributions in OBE</td>
                                                </tr>
                                                <tr>
                                                    <td>15th Sept 2018</td>
                                                    <td>Prof. NVS Narasimha Sarma M.Tech, PhD ECE NIT Warangal</td>
                                                    <td>Mapping curriculum to PO’s and PSO’s</td>
                                                </tr>
                                                <tr>
                                                    <td>15th Sept 2018</td>
                                                    <td>Prof. DVSS Siva Sarma M.Tech, PhD EEE NIT Warangal</td>
                                                    <td>Mapping between PO’s , PSO’s and CO’s</td>
                                                </tr>
                                                <tr class='border-0'>
                                                    <td>5th Oct 2018</td>
                                                    <td>Prof. G Yesu Ratnam HOD EEE, M.Tech, PhD Osmania University</td>
                                                    <td>Evaluation of attainment of CO’s and PO’s</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='pt-4'>
                                        <div className='row mx-auto'>
                                            <div className='col-md-6 pb-3'>
                                                <img src={one} className='img-fluid' alt='activities' loading='lazy' />
                                                <div className='pt-3'>
                                                    <h6 className='text-center'>Dr Baburao Retd NIT Warangal - 1
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className='col-md-6 pb-3'>
                                                <img src={two} className='img-fluid' alt='activities' loading='lazy' />
                                                <div className='pt-3'>
                                                    <h6 className='text-center'>Dr Baburao Retd NIT Warangal - 2
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className='col-md-6 pb-3'>
                                                <img src={three} className='img-fluid' alt='activities' loading='lazy' />
                                                <div className='pt-3'>
                                                    <h6 className='text-center'>Prof. DVSS Siva Sarma , EEE NIT Warangal
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className='col-md-6 pb-3'>
                                                <img src={four} className='img-fluid' alt='activities' loading='lazy' />
                                                <div className='pt-3'>
                                                    <h6 className='text-center'>Prof. Banshidhar Majhi Director IIIT Kanchipuram - 1
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className='col-md-6 pb-3'>
                                                <img src={five} className='img-fluid' alt='activities' loading='lazy' />
                                                <div className='pt-3'>
                                                    <h6 className='text-center'>Prof. DVLN Somayajulu , NIT Warangal

                                                    </h6>
                                                </div>
                                            </div>
                                            <div className='col-md-6 pb-3'>
                                                <img src={six} className='img-fluid' alt='activities' loading='lazy' />
                                                <div className='pt-3'>
                                                    <h6 className='text-center'>Prof. Banshidhar Majhi Director IIIT Kanchipuram - 2


                                                    </h6>
                                                </div>
                                            </div>
                                            <div className='col-md-6 pb-3'>
                                                <img src={seven} className='img-fluid' alt='activities' loading='lazy' />
                                                <div className='pt-3'>
                                                    <h6 className='text-center'>Prof. NVS Narasimha Sarma, ECE NIT Warangal

                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default OBE
