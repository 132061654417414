import React,{useEffect} from 'react'
import { Link } from "react-router-dom";

const ITLeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul className='text-start'>
                    <Link to="/dept/it" onclick="scrollWin()"><li className='text-black'><i class="fa-sharp fa-solid fa-building"></i> <span>About the Department</span> </li></Link>
                    <Link to="/dept/it/vision-mission-peos" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> Vision, Mission & PEO’s</span> </li></Link>
                    <Link to="/dept/it/po-and-pso" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> PO and PSO’s</span> </li></Link>
                    <Link to="/dept/it/hod-profile" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span>  HOD Profile</span> </li></Link>
                    
                    <Link to="/dept/it/it-faculty-list" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span> Faculty List</span> </li></Link>
                    
                    <a href='javascript:;' onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> Department Academic Calendar</span> </li></a>
                    <Link to="/dept/it/student-projects" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span>   Students projects</span> </li></Link>
                    <Link to="/dept/it/industry-internship" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span>  Industry internship/summer trainings</span> </li></Link>
                    <Link to="/dept/it/industry-visits" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span>  Industry Visits</span> </li></Link>
                    <Link to="/dept/it/syllabus" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Syllabus</span> </li></Link>
                    <Link to="/dept/it/placements" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Placements</span> </li></Link>
                    <Link to="/dept/it/magazines" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span>Magazines & Newsletters</span> </li></Link>
                    <Link to="/dept/it/faculty-publications" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span>Faculty publications and achievements</span> </li></Link>
                    <Link to="/dept/it/faculty-innovations" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Faculty innovations in teaching & learning</span> </li></Link>
                    <Link to="/dept/it/workshops" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  FDP/STTPS/Workshops</span> </li></Link>
                    <a href='javascript:;' onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Faculty performance & appraisal development system</span> </li></a>
                    <a href='javascript:;' onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>   R & D laboratory, projects & activities</span> </li></a>
                    <a href='javascript:;' onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Academic/sponsored research & consultancy</span> </li></a>
                    <Link to="/dept/it/bridge-course" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Bridge Course </span> </li></Link>
                    <a href='javascript:;' onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Course Files</span> </li></a>
                    <a href='javascript:;' onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Laboratories</span> </li></a>
                    <Link to="/dept/it/department-library" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Department Library</span> </li></Link>
                    <a href='javascript:;' onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Department MOU’s</span> </li></a>






                </ul>
            </div>
        </div>
    )
}
export default ITLeftTab
