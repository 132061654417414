import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import AboutLeftTab from '../About/AboutLeftTab';


const ProfessionalSocieties = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Professional Society

                                </h2>

                                <table class="mt-3">
                                    <thead>
                                        <tr>
                                            <th>Name of the Society	</th>
                                            <th>Faculty Coordinator
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>CSI</td>
                                            <td><strong>Dr. A. Ravi Kumar, Professor & HOD
                                            </strong></td>
                                        </tr>
                                        <tr>
                                            <td>IEEE</td>
                                            <td><strong>Dr. P Suneel Kumar, Professor
                                            </strong></td>
                                        </tr>
                                        <tr>
                                            <td>IETE</td>
                                            <td><strong>Mr. K Siva Nagi Reddy, Associate Professor
                                            </strong></td>
                                        </tr>
                                        <tr>
                                            <td>ISTE	</td>
                                            <td><strong>Mr. K C Ravi Kumar, Associate Professor
                                            </strong></td>
                                        </tr>
                                        <tr>
                                            <td>HMA</td>
                                            <td><strong>Mr. B Sudhakar Reddy, Assistant Professor </strong></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default ProfessionalSocieties
