import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';
import tech from '../../TrainingAndPlacements/20-21/techm_logotechm_logotechm_logo.png'
import zon from '../../TrainingAndPlacements/20-21/zon.png'
import cyient from '../../TrainingAndPlacements/20-21/cyient.png'
import oracle from '../../TrainingAndPlacements/20-21/oracle.png'
import task from './cseD/task.png'
import cisco from './cseD/cisco.png'
import inentirety from './cseD/inentirety.png'
import pyramid2 from './cseD/pyramid2.png'
import braino from './cseD/braino.png'
import cloud from './cseD/cloud.png'
import prometric from './cseD/prometric.png'
import csc from './cseD/csc.png'
import talentio from './cseD/talentio.png'
import valmiki from './cseD/valmiki.png'
import coign from './cseD/coign.png'
import trueprojects from './cseD/true-projects.png'
import pantech from './cseD/pantech.png'
import scube from './cseD/scube.png'

const CSEMous = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department MOU’s
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td><p class="text-center"><strong>S.No</strong></p></td>
                                                <td><p><strong>MOUs  signed organization</strong></p></td>
                                                <td><p><strong>Date</strong></p></td>
                                                <td><p><strong>MOU Duration</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td><img src={task} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>10-06-2014</td>
                                                <td>Faculty and Student Training and Placement</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td><img src={cisco} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>25-11-2020</td>
                                                <td>Faculty and Student Training and Placement</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td><img src={oracle} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>16-05-2018</td>
                                                <td>Faculty and Student Training and Placement</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td><img src={inentirety} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>12-04-2018</td>
                                                <td>Faculty and Student Training</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td><img src={cyient} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>04-12-2017</td>
                                                <td>Training Students through Internships</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td><img src={zon} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>16-10-2017</td>
                                                <td>Faculty and Student Training</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td><img src={pyramid2} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>24-04-2017</td>
                                                <td>Campus Recruitment Training</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td><img src={braino} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>09-09-2020</td>
                                                <td>Campus Recruitment Training</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td><img src={cloud} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>09-09-2020</td>
                                                <td>Campus Recruitment Training</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td><img src={prometric} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>20-02-2017</td>
                                                <td>Resource Utilization</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td><img src={csc} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>15-07-2016</td>
                                                <td>Student Training and Recruitment</td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td><img src={talentio} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>17-03-2016</td>
                                                <td>Campus Recruitment Training</td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td><img src={tech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>15-04-2015</td>
                                                <td>Train the Trainer  Programs (FDP)</td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td><img src={valmiki} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>03-04-2015</td>
                                                <td>Higher Education and Career  Guidance</td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td><img src={coign} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>15-02-2015</td>
                                                <td>Campus Recruitment Training</td>
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td><img src={trueprojects} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>09-09-2020</td>
                                                <td>Campus Recruitment Training</td>
                                            </tr>
                                            <tr>
                                                <td>17</td>
                                                <td><img src={pantech} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>09-09-2020</td>
                                                <td>Campus Recruitment Training</td>
                                            </tr>
                                            <tr>
                                                <td>18</td>
                                                <td><img src={scube} alt="logo" class="img-responsive img-fluid" loading="lazy" /></td>
                                                <td>09-09-2020</td>
                                                <td>Campus Recruitment Training</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CSEMous
