import React from 'react'
import Discover from '../DiscoverMore/Discover'
import { Link } from 'react-router-dom'


const Courses = () => {
    return (
        <div>
            <div className="campus-life-area ptb-100">
                <div className="container"> 
                    <div className="section-title">
                        <h2>Courses Offered</h2>
                        <p>Checkout out for courses offered, powered by excellent technical faculty and state of the art
                            infrastructure.

                        </p>
                    </div>
                    <div className="popula mt-lg-5">
                        <div id="course-section2" className="course-section2 container-fluid d-block">
                            <div id="collapse1" className="ct-tab-content2 crg-td-list2 f_none">
                                <div className="row flex-row justify-content-center align-items-center mx-auto">
                                    <div className="col-12  col-md-6 col-lg-4">
                                        <div className="crg-td-list f_none">
                                            <ul>
                                                <li>
                                                    <Link className="crg-link" to="/dept/cse-ds">
                                                        <div className="crg-img">
                                                            <img src="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/cse-01.png?updatedAt=1702451967209"
                                                                className="crg-img" alt='CSE-DS image' width="100%" height="200"  />
                                                        </div>
                                                        <div className="crg-info">
                                                            <h6 className="crg-title">CSE Data Science (CSE-DS)</h6>

                                                            <p >Technology / Computer Science</p>

                                                            <div className="crg-view">
                                                                <ul>
                                                                    <li>
                                                                        <Link className="crg-link" to="/dept/cse-ds">Read More</Link>
                                                                        <div className="crg-submenu">
                                                                            <div>

                                                                                <ul>
                                                                                    <p >
                                                                                        Data Science can be defined as a blend of mathematics, business acumen, tools, algorithms and machine learning techniques...
                                                                                    </p>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12  col-md-6 col-lg-4">
                                        <div className="crg-td-list f_none">
                                            <ul>
                                                <li>
                                                    <Link className="crg-link" to="/dept/ai-ml/">
                                                        <div className="crg-img">
                                                            <img src="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/ai-img.jpg?updatedAt=1689770988311"
                                                                className="crg-img" alt='CSE – AI & ML image' width="100%" height="200" />
                                                        </div>
                                                        <div className="crg-info">
                                                            <h6 className="crg-title">Artificial Intelligence & Machine Learning (CSE – AI & ML)</h6>

                                                            <p >Technology / Computer Science / Artificial Intelligence
                                                            </p>

                                                            <div className="crg-view">
                                                                <ul>
                                                                    <li>
                                                                        <Link className="crg-link" to="/dept/ai-ml/">Read More</Link>
                                                                        <div className="crg-submenu">
                                                                            <div>

                                                                                <ul>
                                                                                    <p >
                                                                                    Artificial Intelligence & Machine Learning is designed to make the students technically sound in emerging areas of...
                                                                                    </p>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12  col-md-6 col-lg-4">
                                        <div className="crg-td-list f_none">
                                            <ul>
                                                <li>
                                                    <Link className="crg-link" to="/dept/cse">
                                                        <div className="crg-img">
                                                            <img src="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/cse-o2.png?updatedAt=1702451967226"
                                                                className="crg-img" alt='CSE image' width="100%" height="200" />
                                                        </div>
                                                        <div className="crg-info">
                                                            <h6 className="crg-title">Computer Science and Engineering (CSE)</h6>

                                                            <p >Technology / Computer Science</p>

                                                            <div className="crg-view">
                                                                <ul>
                                                                    <li>
                                                                        <Link className="crg-link" to="/dept/cse">Read More</Link>
                                                                        <div className="crg-submenu">
                                                                            <div>

                                                                                <ul>
                                                                                    <p >
                                                                                        The Department prides itself on providing
                                                                                        good career opportunities
                                                                                        for students. Their sincere efforts and
                                                                                        diversity of knowledge in
                                                                                        fast pace of technology...
                                                                                    </p>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12  col-md-6 col-lg-4">
                                        <div className="crg-td-list f_none">
                                            <ul>
                                                <li>
                                                    <Link className="crg-link" to="/dept/ece">
                                                        <div className="crg-img">
                                                            <img src="https://ik.imagekit.io/we26mxcya/courses/ECE.png?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1675677654876"
                                                                className="crg-img" alt='ECE image' width="100%" height="200" />
                                                        </div>
                                                        <div className="crg-info">
                                                            <h6 className="crg-title">Electronics and Communication Engineering (ECE)
                                                            </h6>

                                                            <p>Electronics / Electrical</p>

                                                            <div className="crg-view">
                                                                <ul>
                                                                    <li>
                                                                        <Link className="crg-link" to="/dept/ece">Read More</Link>
                                                                        <div className="crg-submenu">
                                                                            <div>

                                                                                <ul>
                                                                                    <p >
                                                                                        The Mission of ECE department is to impart
                                                                                        high quality of technical
                                                                                        education and research with an excellent
                                                                                        infrastructure and
                                                                                        well-qualified faculty...
                                                                                    </p>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12  col-md-6 col-lg-4">
                                        <div className="crg-td-list f_none">
                                            <ul>
                                                <li>
                                                    <Link className="crg-link" to="/dept/it">
                                                        <div className="crg-img">
                                                            <img src="https://ik.imagekit.io/we26mxcya/courses/IT.png?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1675677654255"
                                                                className="crg-img" alt='IT image' width="100%" height="200" />
                                                        </div>
                                                        <div className="crg-info">
                                                            <h6 className="crg-title">Information Technology (IT)</h6>

                                                            <p >
                                                                Technology

                                                            </p>

                                                            <div className="crg-view">
                                                                <ul>
                                                                    <li>
                                                                        <Link className="crg-link" to="/dept/it">Read More</Link>
                                                                        <div className="crg-submenu">
                                                                            <div>

                                                                                <ul>
                                                                                    <p >
                                                                                        The college encourages students and staff to
                                                                                        present and publish
                                                                                        papers, research articles in reputed
                                                                                        International / National
                                                                                        conferences and Journals. Apart from this...
                                                                                    </p>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12  col-md-6 col-lg-4">
                                        <div className="crg-td-list f_none">
                                            <ul>
                                                <li>
                                                    <Link className="crg-link" to="/dept/eee">
                                                        <div className="crg-img">
                                                            <img src="https://ik.imagekit.io/we26mxcya/courses/EEE.png?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1675677654248"
                                                                className="crg-img" alt='EEE image' width="100%" height="200" />
                                                        </div>
                                                        <div className="crg-info">
                                                            <h6 className="crg-title">Electrical and Electronics Engineering (EEE)</h6>

                                                            <p>
                                                                Technology / Electronics / Electrical

                                                            </p>

                                                            <div className="crg-view">
                                                                <ul>
                                                                    <li>
                                                                        <Link className="crg-link" to="/dept/eee">Read More</Link>
                                                                        <div className="crg-submenu">
                                                                            <div>

                                                                                <ul>
                                                                                    <p >
                                                                                        To educate our students and encourage them
                                                                                        to contribute in the
                                                                                        field of Electrical and Electronics
                                                                                        Engineering through integration,
                                                                                        application and transfer of knowledge...
                                                                                    </p>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12  col-md-6 col-lg-4">
                                        <div className="crg-td-list f_none">
                                            <ul>
                                                <li>
                                                    <Link className="crg-link" to="/dept/mba">
                                                        <div className="crg-img">
                                                            <img src="https://ik.imagekit.io/we26mxcya/courses/MBA-2.png?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1675677653782"
                                                                className="crg-img" alt='MBA image' width="100%" height="200" />
                                                        </div>
                                                        <div className="crg-info">
                                                            <h6 className="crg-title">Business Administration (MBA)</h6>
                                                            <p >
                                                                Technology / Administration

                                                            </p>
                                                            <div className="crg-view">
                                                                <ul>
                                                                    <li>
                                                                        <Link className="crg-link" to="/dept/mba">Read More</Link>
                                                                        <div className="crg-submenu">
                                                                            <div>

                                                                                <ul>
                                                                                    <p>
                                                                                        To provide all-round development of young
                                                                                        managers by imparting
                                                                                        managerial skills and knowledge, along with
                                                                                        exposure to the real
                                                                                        business world through interaction...
                                                                                    </p>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Discover />
        </div>
    )
}

export default Courses
