import React, { useEffect } from 'react'
import { Link } from "react-router-dom";

const EceleftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul className='text-start'>
                    <Link to="/dept/ece" onclick="scrollWin()"><li className='text-black'><i class="fa-sharp fa-solid fa-building"></i> <span>About the Department</span> </li></Link>
                    <Link to="/dept/ece-ds/vision-mission-peos" onclick="scrollWin()"><li className='text-black'><i class="fa-sharp fa-solid fa-building"></i> <span>Vision, Mission & PEO’s</span> </li></Link>
                    <Link to="/dept/ece-ds/po-and-pso" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> PO and PSO’s</span> </li></Link>
                    {/* <Link to="/dept/ece-ds/hod" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> HOD Profile</span> </li></Link> */}
                   
                    <Link to="/dept/ece-ds/ece-faculty" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span> Faculty List</span> </li></Link>
                    <Link to="/dept/ece-ds/department-academic-calendar" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span> Department Academic Calendar</span> </li></Link>
                    <Link to="/dept/ece-ds/student-projects" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span> Student Projects</span> </li></Link>
                    <Link to="/dept/ece-ds/industry-internship" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span>  Industry internship/summer trainings</span> </li></Link>
                    <Link to="/dept/ece-ds/industry-visits" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span> Industrial Visits</span> </li></Link>
                    <Link to="/dept/ece-ds/student-development-programs" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> <span> Student development programs(SDP)</span> </li></Link>
                    <Link to="/dept/ece-ds/syllabus" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> syllabus</span> </li></Link>
                    <Link to="/dept/ece-ds/result-analysis" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Result Analysis</span> </li></Link>
                    <Link to="/dept/ece-ds/placements" onclick="scrollWin()"><li className='text-black'><i class="fa fa-graduation-cap fa-lg"></i> <span>  Placements & higher studies</span> </li></Link>
                    <Link to="/dept/ece-ds/professional-bodies" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Professional bodies & activities</span> </li></Link>
                    <Link to="/dept/ece-ds/magazines" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span>Magazines & Newsletters</span> </li></Link>
                    <Link to="/dept/ece-ds/student-publications" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span>Student publications and achievements</span> </li></Link>
                    <Link to="/dept/ece-ds/faculty-innovations" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Faculty innovations in teaching & learning</span> </li></Link>
                    <Link to="/dept/ece-ds/workshops" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> FDP/STTPS/Workshops </span> </li></Link>
                    <Link to="/dept/ece-ds/faculty-performance" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Faculty performance & appraisal development system</span> </li></Link>
                    <Link to="/dept/ece-ds/laboratory-projects" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span>R & D laboratory, projects & activities </span> </li></Link>
                    <Link to="/dept/ece-ds/academic-sponsered" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Academic/sponsored research & consultancy </span> </li></Link>
                    <a href="javascript:;" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Bridge Files </span> </li></a>
                    <Link to="/dept/ece-ds/course-files" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Course Files </span> </li></Link>
                    <a href="javascript:;" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>   Laboratories </span> </li></a>
                    <Link to="/dept/ece-ds/department-library" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>  Department Library</span> </li></Link>
                    <Link to="/dept/ece-ds/department-mous" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Department MOU’s</span> </li></Link>

                </ul>
            </div>
        </div>
    )
}


export default EceleftTab
