import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import $ from 'jquery'

const Contact = () => {

    const contactSubmitBtn = () => {
        
        alert()
    }
    return (

        <div>
            <Navbar />
            <div className="contact-us-area pt-100 pb-70">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6">
                            <div className="contact-and-address">
                                <h2>Let's Contact Us</h2>
                                <div className="contact-and-address-content border-0">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 submit">
                                            {/* <div className="contact-card">
                                             
                                                <h4>For Admissions</h4>
                                                <p>Mobile: <a href="#">+919959425729 </a></p>
                                                <p>E-mail: <a href="#">srideviengg@rediffmail.com <br /></a></p>
                                                <p> <a href="#">admin@swec.ac.in </a></p>
                                            </div> */}
                                            <div className="contact-card">
                                                <div className="icon">
                                                    <i class="fa-solid fa-location-dot"></i>
                                                </div>
                                                <h4>Address</h4>
                                                <p>V.N.Pally, Near Gandipet, R.R. Dist. 500075
                                                </p>
                                                {/* <p>Mobile: <a href="#">08413 234333, 234056 </a></p> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 submit" >
                                            <div className="contact-card">
                                                <div className="icon">
                                                    <i><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                                                        <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                                    </svg></i>
                                                </div>
                                                <h5>Student Verification

                                                </h5>
                                                <div>
                                                    <p className='mb-2 pt-2'><strong>Mr.K.Tulsiram
                                                    </strong>  - Controller of Examination and Associate Professor </p>
                                                    <p className='mb-2'><strong>Cell No
                                                    </strong>- 9912282057</p>
                                                    <p className='mb-2'><strong>Email
                                                    </strong> <a className='text-black' href="mailto:exambranch@swec.ac.in">exambranch@swec.ac.in</a></p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-card">
                                                <h5>Distance From College
                                                </h5>
                                                <div>
                                                    <p className='mb-2 pt-2'><strong>Bank
                                                    </strong> – 0.5 km </p>
                                                    <p className='mb-2'><strong>Post office
                                                    </strong>- 2 km </p>
                                                    <p className='mb-2'><strong>Railway station
                                                    </strong> - 9 km</p>
                                                    <p className='mb-2'><strong>Bus station
                                                    </strong> - 0.5 km </p>
                                                    <p className='mb-2'><strong>Airport

                                                    </strong> - 30 km</p>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="contact-card">
                                                <h4>For Admissions</h4>
                                                <p>Mobile: <a href="#">+919959425729 </a></p>
                                                <p>E-mail: <a href="#">srideviengg@rediffmail.com <br /></a></p>
                                                <p> <a href="#">admin@swec.ac.in </a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contacts-form">
                                <h3>Get in Touch
                                </h3>

                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <label>Your name</label>
                                            <input type="text" name="name" id="name" className="form-control" required="" data-error="Please enter your name" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <label>Your email</label>
                                            <input type="email" name="email" id="email" className="form-control" required="" data-error="Please enter your email" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <label>Your phone</label>
                                            <input type="text" name="phone_number" id="phone_number" required="" data-error="Please enter your number" className="form-control" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <label>Location</label>
                                            <input type="text" name="msg_subject" id="msg_subject" className="form-control" required="" data-error="Please enter your subject" />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Your message</label>
                                            <textarea name="message" className="form-control" id="message" cols="30" rows="8" required="" data-error="Write your message"></textarea>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                            <div className="form-check">
                                                <input name="gridCheck" value="I agree to the terms and privacy policy." className="form-check-input" type="checkbox" id="gridCheck" required="" />
                                                <label className="form-check-label" for="gridCheck">
                                                    I agree to the <a href="">terms</a> and <a href="">privacy policy</a>
                                                </label>
                                                <div className="help-block with-errors gridCheck-error"></div>
                                            </div>
                                        </div> */}
                                    <div className="col-lg-12 col-md-12">
                                        <button className="default-btn" onClick={contactSubmitBtn} >
                                            <span>Submit</span>
                                        </button>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <hr />

                </div>
                <div className='container'>
                    <div className='row pt-lg-4'>
                        <div className=' col-md-6 col-lg-3 mb-4'>
                            <h6>Vice Chairman
                            </h6>
                            <p><strong>Dr.K.Radha Kishan Rao, M.D.
                            </strong></p>
                            <p>Cell No. +91-9949117733
                            </p>
                        </div>
                        <div className=' col-md-6 col-lg-3 mb-4'>
                            <h6>Principal
                            </h6>
                            {/* <p><strong>Dr.BL.Malleswari., Ph.D.
                            </strong></p> */}
                            <p>Cell No. +91-8885661507
                            </p>
                        </div>
                        {/* <div className=' col-md-6 col-lg-3 mb-4'>
                            <h6>Student Verification
                            </h6>
                            <p>
                                Mr.K.Tulsiram - Controller of Examination

                                <br /> Cell No. 9912282057
                                <br />Email:  <a href="mailto:exambranch@swec.ac.in">exambranch@swec.ac.in</a>
                            </p>
                        </div> */}
                        {/* <div className=' col-md-6 col-lg-3 mb-4'>
                            <h6>Distance from College
                            </h6>
                            <p>
                                <strong>Bank</strong> – 0.5 km
                                <br /><strong> Post office</strong> - 2 km
                                <br /><strong>Railway station</strong> - 9 km
                                <br /><strong>Bus station</strong> - 0.5 km
                                <br /><strong>Airport </strong> - 30 km
                            </p>
                        </div> */}
                        <div className=' col-md-6 col-lg-3 mb-4'>
                            <h6>Admin 1
                            </h6>
                            <p><strong>Mr D.Venkat Rao 
                            </strong></p>
                            <p>Cell No: +91-9666094086
                            </p>
                        </div>
                        <div className=' col-md-6 col-lg-3 mb-4'>
                            <h6>Admin 2
                            </h6>
                            <p><strong>Mr. Kiran Kumar
                            </strong></p>
                            <p>Cell No: +91-9441618800
                            </p>
                        </div>
                        {/* <div className='col-lg-4'>
                            <div className=''>
                                <div class="detgails-right-content mb-0">
                                    <div class="related-download bg-gray mb-3">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/contact/KEY SWEC CONTACT LIST.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Key SWEC Contact List </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <p>(Please call only during college hours 9:20 am - 4:00 pm)</p>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.7555767433723!2d78.29177817463523!3d17.423513783469822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93492398b995%3A0x319e82e0be1391!2sSridevi%20Women%E2%80%99s%20Engineering%20College!5e0!3m2!1sen!2sin!4v1683897946476!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            <Footer />
        </div>

    )
}

export default Contact
