import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import FacilitiesLeftTab from './FacilitiesLeftTab';
import lab from '../Facilities/bus/it-lab.jpg'


const ComputerCenter = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <FacilitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Computer Centre
                                </h2>
                                <p>The computer centre is equipped with state-of-art computing facilities to compliment classroom pedagogy. The centre has about 100 workstations running on Windows 2003, Windows 2008, XP and Linux. All computers have 24*7 Internet connectivity with a 100 Mbps leased line.
                                </p>
                                <img src={lab} loading='lazy' alt='lab' className='img-fluid' />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default ComputerCenter
