import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const MBAOrientationDay2019 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> MBA Orientation Day 2019
                                    </h2>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group pt-2">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="https://ik.imagekit.io/we26mxcya/4Oct_1.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673253078679">
                                                    <img src="https://ik.imagekit.io/we26mxcya/4Oct_1.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673253078679" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="https://ik.imagekit.io/we26mxcya/MBAOrientation/4Oct_2.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673253011153">
                                                    <img src="https://ik.imagekit.io/we26mxcya/MBAOrientation/4Oct_2.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673253011153" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="https://ik.imagekit.io/we26mxcya/MBAOrientation/4Oct_3.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673253010786">
                                                    <img src="https://ik.imagekit.io/we26mxcya/MBAOrientation/4Oct_3.jpg?ik-sdk-version=javascript-1.4.3&amp;updatedAt=1673253010786" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default MBAOrientationDay2019
