import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';

const EceProffessionalBodies = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Professional body society members
                                </h2>
                                <div className='pt-2'>
                                    <h6>IEEE Activities
                                    </h6>
                                    <div class="apply-list">

                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>The Students Branch of Sridevi Women’s Engineering College (SWESB) has celebrated “Talent Hunt” among the students on 10th March 2020.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>The Student Branch of Sridevi Women’s Engineering College (SWESB) has celebrated IEEE day and conducted an “Oratorical Competition” among the students on 6th December 2019.
                                            </li>

                                            <li><i class="ri-checkbox-circle-line"></i>IEEE association of Sridevi Women’s Engineering College has conducted a two days’ workshop on ‘X-MAX Technology’ on 04/10/2018 and 05/10/2018.
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="apply-list">
                                        <h6 className='pt-3'>IETE Activities
                                        </h6>
                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>IETE association of Sridevi Women’s Engineering College conducted guest lecturer on “DSP” by Dr. A. Santhosh, ECE HOD, Sri Chaitanya Institute of technology and science, Karimnagar on 13/03/2020.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>IETE association of Sridevi Women’s Engineering College conducted Quiz among the students of various branch students on 12th July, 2019.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>Paper presentation contest conducted by IETE-ISF forum for all ECE students on date 21/02/2019.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i> IETE association of Sridevi Women’s Engineering College has conducted a two days Workshop on “Robotics” on 18/12/2018 and 19/12/2018.
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="apply-list">
                                        <h6 className='pt-3'>ISTE Activities
                                        </h6>
                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>ISTE association of Sridevi Women’s Engineering College has conducted a workshop on Python by IIT Mumbai on 05/02/2020
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>Young Engineer Award Program conducted on the occasion of Engineer’s Day on 15th September 2019.
                                            </li>

                                            <li><i class="ri-checkbox-circle-line"></i>Young Engineer Award Program conducted on the occasion of Engineer’s Day on 15th September 2018.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='pt-3'>
                                        <div class="table-responsive" >
                                            <table class="table table-bordered">
                                                <thead class="bg-gray">
                                                    <tr>
                                                        <th width="100"><strong>Name of Society </strong></th>
                                                        <th><strong>Faculty Coordinator </strong></th>
                                                        <th width="220"><strong>Email ID </strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>IEEE </td>
                                                        <td>Mr. K.Tulasi Ram, Associate Professor </td>
                                                        <td>ramukorrapati@gmail.com </td>
                                                    </tr>
                                                    <tr>
                                                        <td>IETE  </td>
                                                        <td>Dr. Sardar Inderjeeth Singh, Professor </td>
                                                        <td>drindar2020@gmail.com </td>
                                                    </tr>
                                                    <tr>
                                                        <td>ISTE  </td>
                                                        <td>Dr. K. Mohan ram, Professor </td>
                                                        <td>umsmohan@gmail.com </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}



export default EceProffessionalBodies
