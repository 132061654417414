import React from 'react'
import AboutLeftTab from './AboutLeftTab'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import ChairPerson from '../About/images/chairperson.png'
import Secretary from '../About/images/secretary.jpg'
import Treasurer from '../About/images/Treasurer.jpg'
import Srinivas from '../About/images/Srinivas_Rao.jpg'
import Manjeera from '../About/images/Manjeera.jpg'
import Deepa from '../About/images/Deepa.jpg'
import Krishna from '../About/images/Krishna.jpg'
import Anji from '../About/images/Anji.jpg'
import Subbarangaiah from '../About/images/Subbarangaiah.jpg'
import Bruhaspathy from '../About/images/Bruhaspathy.jpg'
import Padmavathi from '../About/images/Padmavathi.jpg'
import Kavitha from '../About/images/kavitha.jfif'
import Principal from '../About/images/principal.jpg'

const GoverningBody = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply border-0">
                                <h2>Governing Body
                                </h2>
                                <p>Sridevi Women’s Engineering College follows guidelines AICTE guidelines with regard to professional code of prescribed / suggested by statutory bodies / regulatory authorities for different professions.
                                </p>
                                <p>The elite decision making body of the college is the Governing Body. Governing body of the college meets once in a year to discuss the various issues and aspects related to the development of the college. It includes considering and approving the Institution's strategic plan which sets the academic aims and objectives of the Institution and identifies the Financial, Physical and Staffing strategies. It chalks out a roadmap to achieve the goals of the Institution.
                                </p>
                                <p>Sridevi women's Engineering college has a Governing body with members drawn from Academic, Industrial and service sectors. The Governing body is the kingpin of the college administration and lays down all important policies for the effective management of the institution and for planning its future development.It is constituted as per guidelines prescribed by AICTE, UGC following the due procedure. The College Governing Body is headed by eminent academician providing representation to all other stakeholders.It prepares a strategic plan of action for the institutional growth, sets the aims and agenda of the institution, identifies the financial, physical and staffing strategies. The members of our Governing body are renowned personalities, such as reputed educationalist, self-less philanthropists and professional industrialists, dedicated to the noble cause of education and empowering the students.
                                </p>

                            </div>
                            <div className="submit">
                                <div className='pt-3'>
                                    <div className='row mx-auto'>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img height="150" src={ChairPerson} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Srmt. K.Jyothi Devi
                                                </h6>
                                                <p>Chair Person</p>
                                            </div>

                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img width="65" src='/assets/images/governing-images/secretary.jpeg' alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr.K.Radha Kishan Rao, M.D.
                                                </h6>
                                                <p>Secretary</p>
                                            </div>

                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img height="150" src={Treasurer} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr.Madiraju Sridevi

                                                </h6>
                                                <p>Member</p>
                                            </div>

                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img height="150" src={Srinivas} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr.K.P.Srinivasa Rao. M. S.
                                                </h6>
                                                <p>Member</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img width="180" src='/assets/images/governing-images/Dr. K Manjeera - Member.jpeg' alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr.K.Manjeera

                                                </h6>
                                                <p>Member</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img height="150" src={Deepa} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr.K.Deepa


                                                </h6>
                                                <p>Member</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img height="150" src={Krishna} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr.Krishna Prasad M.D
                                                </h6>
                                                <p>Member</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img height="150" src='/assets/images/governing-images/Dr. G Vijaya Kumari -JNTUH Nominee.jpeg' alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr. Mrs. G Vijaya Kumari, Professor & Director, J-Hub, JNTUH
                                                </h6>
                                                <p>JNTUH Nominee</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img height="150" src={Subbarangaiah} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr. Subbarangaiah, M.D. , Veda IIT , Hyd
                                                </h6>
                                                <p>Eminent Professional
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img height="150" src={Bruhaspathy} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className='managementText'>
                                                <h6>Mr. K Bruhaspathy Rao, President, Pioneer Gas Power Limited
                                                </h6>
                                                <p>Eminent Professional
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img width="150" src='/assets/images/governing-images/KC RAVIKUMAR.jpg' alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr. K.C Ravikumar Ph.D Professor in CSE, S&H HoD, SWEC

                                                </h6>
                                                <p>Faculty Nominee
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-3'>
                                            <img height="150" src={Kavitha} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Mrs. M.Kavitha. Asst Prof. IT, SWEC
                                                </h6>
                                                <p>Faculty Nominee
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-lg-4 text-center pb-1'>
                                            <img height="150" src={Principal} alt='ChairpersonImg' className='my-3 Img-fluid' loading='lazy' />
                                            <div className=' managementText'>
                                                <h6>Dr. A Narmada 
                                                </h6>
                                                <p>Principal
                                                </p>
                                            </div>
                                        </div>
                                        <p>The governing body drafts the policies of the college and reviews it periodically. The frequency of review is either once in three months or as necessary. The agenda is prepared by the Principal and is discussed by the body. They review the college academic performance every semester and formulate policies for the betterment of performance.The annual budget for every financial year is prepared after reviewing the income and expenditure of the college and the decisions are duly recorded for future reference and review.</p>
                                    </div>
                                </div>
                            </div>
                            <h5>Code of Conduct
                            </h5>
                            <div class="detgails-right-content mt-4 submit">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="/assets/documents/Code of Conduct - Hand book.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Code of Conduct - Hand book </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <h5>
                                GBM minutes 2023-24
                            </h5>
                            <div class="detgails-right-content mt-4 submit">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="/assets/documents/23-24-GBM.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>GBM minutes 2023-24 </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>


                            <h5>
                                GBM minutes 2022-23
                            </h5>
                            <div class="detgails-right-content mt-4 submit">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="/assets/documents/GB MoM 27-12-2022.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>GBM minutes 27.12.2022 </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <h5>
                                GBM minutes 2021-22
                            </h5>
                            <div class="detgails-right-content mt-4 submit">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="/assets/documents/GBM Minutes - 20.02.2022.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>GBM Minutes 20.02.2022 </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/GBM Minutes 26.08.2021.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>GBM Minutes 26.08.2022 </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <h5>
                                GBM minutes 2020-2021
                            </h5>
                            <div class="detgails-right-content mt-4 submit">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="/assets/documents/MoM GBM 2020-21 8th Oct.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>MoM GBM 2020-21 8th October </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <h5>
                            GBM minutes 2019-2020

                            </h5>
                            <div class="detgails-right-content mt-4 submit">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="/assets/documents/2020_11_07 12_31 PM Office Lens.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>GBM minutes </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            {/* <h5>
                                Minutes Of Meeting
                            </h5>
                            <div class="detgails-right-content mt-4 submit">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="/assets/" target="_blank"><i class="fa-solid fa-file-pdf"></i>  MOM 2018 - 2019 </a>
                                        </li>
                                        <li>
                                            <a href="/assets/" target="_blank"><i class="fa-solid fa-file-pdf"></i>  MOM 2017 - 2018 </a>
                                        </li>
                                        <li>
                                            <a href="/assets/" target="_blank"><i class="fa-solid fa-file-pdf"></i>   MOM 2016 - 2017 </a>
                                        </li>
                                        <li>
                                            <a href="/assets/" target="_blank"><i class="fa-solid fa-file-pdf"></i>  MOM 2015 - 2016 </a>
                                        </li>
                                        <li>
                                            <a href="/assets/" target="_blank"><i class="fa-solid fa-file-pdf"></i> MOM 2014 - 2015</a>
                                        </li>

                                    </ul>
                                </div>
                            </div> */}
                            {/* <div class="detgails-right-content mt-4 how-to-apply">

                                <div class="apply-list border-0">
                                    <h4 class=""><i class="fa fa-bullseye text-red"></i> Mission</h4>

                                    <ul class="py-4">
                                        <li><i class="ri-checkbox-circle-line"></i>To administer and monitor the teaching in the college and to determine the teaching requirement of the Institute.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To appoint the Principal / HOD, teaching and non-teaching staff on the recommendations of the Selection Committees constituted under the regulations of the University.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To recommend either the reduction or increase of student intake/programs.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To monitor faculty deployment and development, placement and industry-institute interaction activities in the college and suggest remedial measures wherever necessary.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To perform other duties and exercises as may be entrusted by the Management and the University.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To ensure the establishment and monitoring of proper, effective and efficient systems of control and accountability.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To monitor institutional performance and quality assurance arrangements.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To promote of transparency and openness at every level.</li>
                                    </ul>
                                </div>



                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default GoverningBody
