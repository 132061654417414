import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const JNTUHCovid19Circular = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>JNTUH Covid 19 circular
                                    </h2>
                                    <div class="spotlight-group">
                                        <embed src="/assets/documents/Instruction_Col_Inst_15.04.2020.pdf#toolbar=0&amp;navpanes=0&amp;scrollbar=0" width="100%" height="600" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default JNTUHCovid19Circular
