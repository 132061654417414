import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import PlacementsLeftTab from './PlacementsLeftTab';

const P2223 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <PlacementsLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> List of students selected for placements in the year 2022-2023
                                </h2>
                                <div>
                                <div class="table-responsive">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="middle"><strong>S.No</strong></td>
                                                <td align="center" valign="middle"><strong>Name of the Company</strong></td>
                                                 {/* <td align="center" valign="middle"><strong>Branch</strong></td>  */}
                                                <td align="center" valign="middle"><strong>&nbsp;Salary&nbsp; L.P.A&nbsp;</strong></td>
                                                <td align="center" valign="middle"><strong>Total</strong></td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">1</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/cg_logo.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">4.25 &amp; 7.5</td>
                                                <td align="center" valign="middle">205</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">2</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/dxc.png" alt="logo" class="img-responsive" /></td>
                                                <td align="right" valign="middle">4.25</td>
                                                <td align="center" valign="middle">137</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">3</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/accenture.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">4.6 &amp; 6.5</td>
                                                <td align="center" valign="middle">176</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">4</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/legato.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">	5 </td>
                                                <td align="center" valign="middle">19</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">5</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/hexaware.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">	4.0 </td>
                                                <td align="center" valign="middle">62</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">6</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/ericsson.png" alt="logo" class="img-responsive" width="90" /></td>

                                                <td align="right" valign="middle">	3.6 </td>
                                                <td align="center" valign="middle">29</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">7</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/TCS.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">	4 </td>
                                                <td align="center" valign="middle">21</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">8</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/ntt-data.png" alt="logo" class="img-responsive" /></td>
                                                <td align="right" valign="middle">	3.5 </td>
                                                <td align="center" valign="middle">	3</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">9</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/ecolabs.png" alt="logo" class="img-responsive" width="80" /></td>

                                                <td align="right" valign="middle">	9.84</td>
                                                <td align="center" valign="middle">3</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">10</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/wipro.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">4</td>
                                                <td align="center" valign="middle">19</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">11</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/ibm.png" alt="logo" class="img-responsive" width="70" /></td>

                                                <td align="right" valign="middle">7.5</td>
                                                <td align="center" valign="middle">6</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">12</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/HCL.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">4</td>
                                                <td align="center" valign="middle">4</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">13</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/techm_logo.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">4 &amp; 5.5</td>
                                                <td align="center" valign="middle">32</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">14</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/savantis.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">3.6</td>
                                                <td align="center" valign="middle">50</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">15</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/l-and-t.png" alt="logo" class="img-responsive" /></td>

                                                <td align="right" valign="middle">4</td>
                                                <td align="center" valign="middle">22</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">16</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/pwc.png" alt="logo" class="img-responsive" width="80" /></td>

                                                <td align="right" valign="middle">	6</td>
                                                <td align="center" valign="middle">2</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">17</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/ttech.png" alt="logo" class="img-responsive" width="80" /></td>

                                                <td align="right" valign="middle">	6</td>
                                                <td align="center" valign="middle">7</td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="middle">18</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/TCS.png" alt="logo" class="img-responsive" width="80" /></td>

                                                <td align="right" valign="middle">	3.6</td>
                                                <td align="center" valign="middle">32</td>
                                            </tr>

                                            <tr>
                                                <td align="center" valign="middle">19</td>
                                                <td align="left" valign="middle"><img src="/assets/img/s-logos/INFOSYS.png" alt="logo" class="img-responsive" width="80" /></td>

                                                <td align="right" valign="middle">	3.6</td>
                                                <td align="center" valign="middle">18</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default P2223
