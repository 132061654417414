import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const NationalSportsDaycelebration = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>National Sports Day celebration
                                    </h2>
                                    <p className='pb-2'>August 29th 2019, National Sports day was celebrated by all the Sports Captains of the Basketball, Throw ball, Kho-Kho, Kabbadi and Volleyball. The Student captains and the Faculty captains emphasized the importance of the sports and encouraged all students and faculty to play a sport and stay fit.

                                    </p>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/1. National Sports Day celebrations 2019.jpg">
                                                    <img src="/assets/img/latest-events/1. National Sports Day celebrations 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/National Sports Day celebrations 2019.jpg">
                                                    <img src="/assets/img/latest-events/National Sports Day celebrations 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default NationalSportsDaycelebration
