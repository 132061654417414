import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';
import hod from './eceimages/narmada.jpg'

const EceHod = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>HOD Profile
                                </h2>
                                <div className='pt-3'>
                                    <img src={hod} width="150" alt='ece-hod' loading='lazy' className='img-fluid' />
                                    <p className='pt-2'>The department is headed by <strong>Dr.Narmada Alaparthi, "member IEEE"</strong> an action-oriented and result-focused professional offering <strong>21</strong> years of progressively responsible experience in teaching and research projects. Good knowledge in Wireless Sensor Networks and Micro controller based embedded system design. Apart from teaching, she has the distinction of contributing to the research and development activity in VNRVJIET and Vignan group of institutions. She has <strong> completed 3 DST sponsored Research projects and 1- consultancy project,. </strong>published 27 papers in national, international journals and IEEE Conferences indexed in ISI, EBSCO, Copernicus etc.She worked as Associate Professor, ECE in VNRVJIET for 8.5 years, later she worked as Professor &amp; HOD,ECE, Vignan Group of Institutions for 11 years. <strong>She is currently working as Professor &amp; HOD ECE, Sridevi Women’s Engineering College.</strong> </p>
                                </div>
                                <div className='pt-2'>
                                    <h6>Areas of Specialization:
                                    </h6>
                                    <div class="apply-list border-0 pt-2">

                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>Wireless Sensor Networks
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>IEEE 802.15.4 ZigBee Protocol </li>
                                            <li><i class="ri-checkbox-circle-line"></i> Embedded Systems
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div className='pt-2'>
                                    <h6>Achievements in Brief
                                    </h6>
                                    <div class="apply-list border-0 pt-2">

                                        <ul class="">
                                            <ul>
                                                <li><i class="ri-checkbox-circle-line"></i>Completed <strong>3-DST sponsored projects</strong></li>
                                                <li><i class="ri-checkbox-circle-line"></i>Completed <strong>3- DST-NIMAT</strong> sponsored EAC Projects</li>
                                                <li><i class="ri-checkbox-circle-line"></i>Worked as <strong>NBA coordinator</strong>, got highest marks for the department</li>
                                                <li><i class="ri-checkbox-circle-line"></i> <strong>Best Teacher award</strong> by VITS, DESHMUKHI</li>
                                                <li><i class="ri-checkbox-circle-line"></i>Planned and Established <strong>Embedded Systems</strong> Laboratory for M.Tech Course in VNRVJIET</li>
                                                <li><i class="ri-checkbox-circle-line"></i>State <strong>16<sup>th</sup> Rank</strong> in Girls category in S.S.C. during 1992</li>
                                                <li><i class="ri-checkbox-circle-line"></i>National Merit Scholarship 1992 – 1998</li>
                                                <li><i class="ri-checkbox-circle-line"></i>Telugu Sahitya Academy Scholarship 1992 – 1998</li>
                                            </ul>
                                        </ul>
                                    </div>

                                </div>
                                <div className='pt-3'>
                                    <h6>Research and consultancy Projects
                                    </h6>
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr>
                                                    <td colspan="6" class="text-center"><strong>DST- Sponsored Completed Projects</strong></td>
                                                </tr>
                                                <tr class="bg-gray">
                                                    <td align="center" valign="middle" width="60"><strong>S. No</strong></td>
                                                    <td align="center" valign="middle" width="250"><strong>Title of the Project </strong></td>
                                                    <td align="center" valign="middle"><strong>Agency, File No. </strong></td>
                                                    <td align="center" valign="middle"><strong>Principal Investigator(s) (PI) &amp; (Co –PI)</strong></td>
                                                    <td align="center" valign="middle"><strong>Grant in lakhs</strong></td>
                                                    <td align="center" valign="middle" width="90"><strong>Duration </strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">1</td>
                                                    <td valign="middle">Enhancing Efficiency of Wireless Sensor Network with MIMO</td>
                                                    <td align="center" valign="middle">DST, SR/ET-41/2017 </td>
                                                    <td valign="middle">Dr.A.Narmada-PI</td>
                                                    <td align="center" valign="middle">31.33 </td>
                                                    <td align="center" valign="middle">2017-2019</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">2</td>
                                                    <td valign="middle">Realization of IP based wireless sensor network with emphasis on image transfer and on demand routing </td>
                                                    <td align="center" valign="middle">DST, DST/TSG/NTS/2009/28 </td>
                                                    <td valign="middle">Dr.P.Sudhakara Rao-PI. Mrs.A.Narmada-CO- PI </td>
                                                    <td align="center" valign="middle">10.50 </td>
                                                    <td align="center" valign="middle">2009-2015 </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">3</td>
                                                    <td valign="middle">Development of a non-intrusive system for real time monitoring and prediction of driver fatigue and drowsiness </td>
                                                    <td align="center" valign="middle">DST, DST/TSG/ICT/2007/80 </td>
                                                    <td valign="middle">Dr.P.Sudhakara Rao-PI A.Narmada-Member</td>
                                                    <td align="center" valign="middle">20.32 </td>
                                                    <td align="center" valign="middle">2010-2016 </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="6" class="text-center"><strong>Consultancy Projects completed</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">4</td>
                                                    <td valign="middle">Automated Commando Training System</td>
                                                    <td align="center" valign="middle">Grey Hounds, AP Police </td>
                                                    <td valign="middle">A.Narmada-PI</td>
                                                    <td align="center" valign="middle">3.0 </td>
                                                    <td align="center" valign="middle">2007-10</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>DST-NIMAT (National Implementing and Monitoring Agency for Training) Projects
                                    </h6>
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center" valign="middle" width="60"><strong>S. No</strong></td>
                                                    <td align="center" valign="middle" width="250"><strong>Program</strong></td>
                                                    <td align="center" valign="middle"><strong>File No</strong></td>
                                                    <td align="center" valign="middle"><strong>Coordinator</strong></td>
                                                    <td align="center" valign="middle"><strong>Grant in lakhs</strong></td>
                                                    <td align="center" valign="middle" width="90"><strong>Duration </strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">1</td>
                                                    <td valign="middle">Entrepreneurship Awareness Camp</td>
                                                    <td align="center" valign="middle">EDII/NIMAT/17-18/349</td>
                                                    <td valign="middle">Dr.A.Narmada </td>
                                                    <td align="center" valign="middle">1.00 </td>
                                                    <td align="center" valign="middle">2018-19</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">2</td>
                                                    <td valign="middle">Entrepreneurship Awareness Camp</td>
                                                    <td align="center" valign="middle">EDII/DST-NIMAT/18-19/469 </td>
                                                    <td valign="middle">Dr.A.Narmada </td>
                                                    <td align="center" valign="middle">1.00 </td>
                                                    <td align="center" valign="middle">2017-18</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">3</td>
                                                    <td valign="middle">Entrepreneurship Awareness Camp</td>
                                                    <td align="center" valign="middle">EDII/DST-NIMAT/19-120/532</td>
                                                    <td valign="middle">Dr.A.Narmada </td>
                                                    <td align="center" valign="middle">1.00 </td>
                                                    <td align="center" valign="middle">2019-20</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Key Positions Held</h6>
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center" valign="middle" width="60"><strong>S. No</strong></td>
                                                    <td align="center" valign="middle" width="250"><strong>University / Institution/Industry</strong></td>
                                                    <td align="center" valign="middle"><strong>Designation</strong></td>
                                                    <td align="center" valign="middle"><strong>Duration</strong></td>
                                                    <td align="center" valign="middle"><strong>Experience in Years &amp; Months </strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">1</td>
                                                    <td valign="middle">Sridevi Women’s Engineering College</td>
                                                    <td align="center" valign="middle">Professor &amp; HOD, ECE</td>
                                                    <td valign="middle">Aug 2019 – Till date</td>
                                                    <td align="center" valign="middle">1 month</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">2</td>
                                                    <td valign="middle">Vignan Group of Institutions , Hyderabad</td>
                                                    <td align="center" valign="middle">Professor &amp; HOD, ECE</td>
                                                    <td valign="middle">Sept 2008 – Aug 2019</td>
                                                    <td align="center" valign="middle">11 Years</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">3</td>
                                                    <td valign="middle">VNR Vignana Jyothi Institute of Engineering and Technology, Hyderabad (VNRVJIET)</td>
                                                    <td align="center" valign="middle">Associate Professor</td>
                                                    <td valign="middle">March 2000 - Sept 2008</td>
                                                    <td align="center" valign="middle">8years 6 month</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">4</td>
                                                    <td valign="middle">Bharath Dynamics Ltd (BDL, Hyd)</td>
                                                    <td align="center" valign="middle">Trainee</td>
                                                    <td valign="middle">Jan 1999- Jan 2000</td>
                                                    <td align="center" valign="middle">1year</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">5</td>
                                                    <td valign="middle">Pentagon Info Web Software solutions</td>
                                                    <td align="center" valign="middle">Trainee</td>
                                                    <td valign="middle">June 1998-Dec 1999</td>
                                                    <td align="center" valign="middle">6months</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" class="text-right"><strong>Total Experience</strong></td>
                                                    <td colspan="2"><strong>21 years 1 month</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>Education</h6>
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center" valign="middle" width="60"><strong>S. No</strong></td>
                                                    <td align="center" valign="middle" width="250"><strong>Qualification</strong></td>
                                                    <td align="center" valign="middle"><strong>Institute/ University</strong></td>
                                                    <td align="center" valign="middle"><strong>Year of Passing</strong></td>
                                                    <td align="center" valign="middle"><strong>% of Marks</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">1</td>
                                                    <td valign="middle">Ph.D – Wireless Communications</td>
                                                    <td align="center" valign="middle">JNTUH, Hyderabad</td>
                                                    <td valign="middle">2015</td>
                                                    <td align="center" valign="middle">--</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">2</td>
                                                    <td valign="middle">M.E-DIGITAL SYSTEMS</td>
                                                    <td align="center" valign="middle">University College of Engg, OSMANIA UNIVERSITY</td>
                                                    <td valign="middle">2005</td>
                                                    <td align="center" valign="middle">86</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">3</td>
                                                    <td valign="middle">B.TECH-ECE</td>
                                                    <td align="center" valign="middle">NAGARJUNA UNIVERSITY</td>
                                                    <td valign="middle">1998</td>
                                                    <td align="center" valign="middle">78</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">4</td>
                                                    <td valign="middle">Intermediate</td>
                                                    <td align="center" valign="middle">AP Intermediate Board of education, Loyola Junior college</td>
                                                    <td valign="middle">1994</td>
                                                    <td align="center" valign="middle">86</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">5</td>
                                                    <td valign="middle">SSC</td>
                                                    <td align="center" valign="middle">Loyola School, AP, Hyderabad</td>
                                                    <td valign="middle">1992</td>
                                                    <td align="center" valign="middle">90</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>List of Journal Publications
                                    </h6>
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center" valign="middle" width="60"><strong>S. No</strong></td>
                                                    <td align="center" valign="middle" width="250"><strong>Title with page no's</strong></td>
                                                    <td align="center" valign="middle"><strong>Journal</strong></td>
                                                    <td align="center" valign="middle"><strong>ISSN/ISBN</strong></td>
                                                    <td align="center" valign="middle"><strong>DOI</strong></td>
                                                    <td align="center" valign="middle"><strong>Month, Year</strong></td>
                                                    <td align="center" valign="middle"><strong>PP</strong></td>
                                                    <td align="center" valign="middle" width="250"><strong>Indexing details</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">1</td>
                                                    <td valign="middle">Performance Comparison of RIPv2 and DSR Protocols in Customized Zigbee Stack with 5 Nodes and 1HOP</td>
                                                    <td align="center" valign="middle">IJARITAC</td>
                                                    <td valign="middle">0975-8070</td>
                                                    <td align="center" valign="middle">10.5958/0975-8089.2018.00023.4</td>
                                                    <td align="center" valign="middle">Jan 2019</td>
                                                    <td align="center" valign="middle">238-244</td>
                                                    <td align="center" valign="middle">NAAS Rating 2017 - 3.85, ISI Impact factor - 1.88, Indian Citation Index – Research Impact Indicator: 0.061, InfoBase Index(IB Factor - 2.8, Index Copernicus(2016, ICV - 76.32), Google Scholar, CNKI Scholar, EBSCO Discovery, Summon(ProQuest), J-gate, Primo and Primo Central, I2OR, ESJI, Indian Science, Cite Factor, DRJI, ISRA-JIF, IIJIF, Indian Science Abstract, NA-Digest, Ulrich-PD</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">2</td>
                                                    <td valign="middle">Choosing Cluster Head in Artificially Intelligent Wireless Sensor Network</td>
                                                    <td align="center" valign="middle">RJET</td>
                                                    <td valign="middle">0976-2973</td>
                                                    <td align="center" valign="middle">10.5958/2321-581X.2018.00046.6</td>
                                                    <td align="center" valign="middle">Oct-Dec 2018</td>
                                                    <td align="center" valign="middle">335-342</td>
                                                    <td align="center" valign="middle"> ISA: Indian Science Abstracts CAS: Chemical Abstracts Service (CAS) (Applied) Google Scholar Index Copernicus (Applied) Pro Quest Central </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">3</td>
                                                    <td valign="middle">RFID Integration with Wireless Sensor Networks</td>
                                                    <td align="center" valign="middle">RJET</td>
                                                    <td valign="middle">0976-2973</td>
                                                    <td align="center" valign="middle">0.5958/2321-581X.2018.00017.X</td>
                                                    <td align="center" valign="middle">April-June 2018</td>
                                                    <td align="center" valign="middle">120-126</td>
                                                    <td align="center" valign="middle"> ISA: Indian Science Abstracts CAS: Chemical Abstracts Service (CAS) (Applied) Google Scholar Index Copernicus (Applied) Pro Quest Central </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">4</td>
                                                    <td valign="middle">Performance of IP-Based Wireless Sensor Network with 1-M Granularity Cartesian Terrain</td>
                                                    <td align="center" valign="middle">IJARITAC</td>
                                                    <td valign="middle">0975-8070</td>
                                                    <td align="center" valign="middle">10.5958/0975-8089.2018.00016.7</td>
                                                    <td align="center" valign="middle">May-Aug 2018</td>
                                                    <td align="center" valign="middle">171-176</td>
                                                    <td align="center" valign="middle">NAAS Rating 2017 - 3.85, ISI Impact factor - 1.88, Indian Citation Index – Research Impact Indicator: 0.061, InfoBase Index(IB Factor - 2.8, Index Copernicus(2016, ICV - 76.32), Google Scholar, CNKI Scholar, EBSCO Discovery, Summon(ProQuest), J-gate, Primo and Primo Central, I2OR, ESJI, Indian Science, Cite Factor, DRJI, ISRA-JIF, IIJIF, Indian Science Abstract, NA-Digest, Ulrich-PD</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">5</td>
                                                    <td valign="middle">Performance comparison of IP- based Wireless Sensor Network with 802.11</td>
                                                    <td align="center" valign="middle">IJARITAC</td>
                                                    <td valign="middle">0975-15678</td>
                                                    <td align="center" valign="middle">10.5958/0975-8089.2017.00032.X</td>
                                                    <td align="center" valign="middle">Sept-Dec 2017</td>
                                                    <td align="center" valign="middle">354-362</td>
                                                    <td align="center" valign="middle">NAAS Rating 2017 - 3.85, ISI Impact factor - 1.88, Indian Citation Index – Research Impact Indicator: 0.061, InfoBase Index(IB Factor - 2.8, Index Copernicus(2016, ICV - 76.32), Google Scholar, CNKI Scholar, EBSCO Discovery, Summon(ProQuest), J-gate, Primo and Primo Central, I2OR, ESJI, Indian Science, Cite Factor, DRJI, ISRA-JIF, IIJIF, Indian Science Abstract, NA-Digest, Ulrich-PD</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">6</td>
                                                    <td valign="middle">Performance of IP- Based Wireless Sensor Network with Cartesian Terrain</td>
                                                    <td align="center" valign="middle">RJET</td>
                                                    <td valign="middle"> 0976-2973 (Print) 2321-581X (online) </td>
                                                    <td align="center" valign="middle">10.5958/2321-581X.2017.00035.6</td>
                                                    <td align="center" valign="middle">July-Sept-2017</td>
                                                    <td align="center" valign="middle">1-6</td>
                                                    <td align="center" valign="middle"> ISA: Indian Science Abstracts CAS: Chemical Abstracts Service (CAS) (Applied) Google Scholar Index Copernicus (Applied) Pro Quest Central </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">7</td>
                                                    <td valign="middle">Mathematical Modelling of end-to-end delay of customised ZigBee stack towards optimization of performance metrics</td>
                                                    <td align="center" valign="middle">RJET</td>
                                                    <td valign="middle">0976-2973</td>
                                                    <td align="center" valign="middle">10.5958/2321-581X.2016.00026.X</td>
                                                    <td align="center" valign="middle">Oct-Dec 2016</td>
                                                    <td align="center" valign="middle">131-143</td>
                                                    <td align="center" valign="middle"> ISA: Indian Science Abstracts CAS: Chemical Abstracts Service (CAS) (Applied) Google Scholar Index Copernicus (Applied) Pro Quest Central </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">8</td>
                                                    <td valign="middle">Mathematical modeling of end-to-end-delay of Zigbee stack towards optimization of performance metrics</td>
                                                    <td align="center" valign="middle">RTSRT</td>
                                                    <td valign="middle">2393-8765</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">Jan 2017</td>
                                                    <td align="center" valign="middle">2-9</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">9</td>
                                                    <td valign="middle">Selection of cluster head in Wireless Sensor Network towards extending network life time</td>
                                                    <td align="center" valign="middle">RTSRT</td>
                                                    <td valign="middle">2393-8765</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">Jan 2017</td>
                                                    <td align="center" valign="middle">2-9</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">8</td>
                                                    <td valign="middle">Mathematical modeling of end-to-end-delay of Zigbee stack towards optimization of performance metrics</td>
                                                    <td align="center" valign="middle">JoMEA</td>
                                                    <td valign="middle">2455-197X</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">Dec 2016</td>
                                                    <td align="center" valign="middle">1-10</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">10</td>
                                                    <td valign="middle">Customized Zigbee stack-Mathematical modelling</td>
                                                    <td align="center" valign="middle">RTECS</td>
                                                    <td valign="middle">2393-8757</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">Dec 2016</td>
                                                    <td align="center" valign="middle">7-14</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">11</td>
                                                    <td valign="middle">Selection of cluster head in artificially intelligent WSN towards extending network life time and reducing energy consumption</td>
                                                    <td align="center" valign="middle">IJWCNMET</td>
                                                    <td valign="middle">0974-6760</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">Nov 2016</td>
                                                    <td align="center" valign="middle">1-19</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">12</td>
                                                    <td valign="middle">RFID Integration with Wireless sensor network</td>
                                                    <td align="center" valign="middle">IJ-RSAE</td>
                                                    <td valign="middle">0974-6589</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">Nov 2016</td>
                                                    <td align="center" valign="middle">1-16</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">13</td>
                                                    <td valign="middle">PERFORMANCE COMPARISON OF CSMA, MACA, Generic MAC and Sensor MAC CHANNEL ACCESS PROTOCOLS FOR ZIGBEE WSN</td>
                                                    <td align="center" valign="middle">ISOI</td>
                                                    <td valign="middle">0970-9983</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">Sept 2012</td>
                                                    <td align="center" valign="middle">168-171</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">14</td>
                                                    <td valign="middle">Performance analysis of DSR Protocol for Zigbee WPAN</td>
                                                    <td align="center" valign="middle">ISOI</td>
                                                    <td valign="middle">0970-9983</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">June 2012</td>
                                                    <td align="center" valign="middle">78-81</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">15</td>
                                                    <td valign="middle">Performance Comparison Of Routing Protocols For Zigbee Wpan</td>
                                                    <td align="center" valign="middle">IJCSI</td>
                                                    <td valign="middle">1694-0814</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">Nov 2011</td>
                                                    <td align="center" valign="middle">394-302</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">16</td>
                                                    <td valign="middle">PERFORMANCE COMPARISON OF RANDOM CHANNEL ACCESS PROTOCOLS FOR ZIGBEE WPAN</td>
                                                    <td align="center" valign="middle">IJ-ETA-ETS</td>
                                                    <td valign="middle">0974-3588</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">July-Dec 2011</td>
                                                    <td align="center" valign="middle">246-253</td>
                                                    <td align="center" valign="middle"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>List of Conference Publications</h6>
                                    <div class="table-responsive mt-3">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center" valign="middle" width="60"><strong>S. No</strong></td>
                                                    <td align="center" valign="middle" width="250"><strong>Title</strong></td>
                                                    <td align="center" valign="middle"><strong>IEEE Title</strong></td>
                                                    <td align="center" valign="middle"><strong>ISSN</strong></td>
                                                    <td align="center" valign="middle"><strong>DOI</strong></td>
                                                    <td align="center" valign="middle"><strong>Month &amp; Year of publication</strong></td>
                                                    <td align="center" valign="middle"><strong>PP</strong></td>
                                                    <td align="center" valign="middle" width="200"><strong>Indexing details</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">1</td>
                                                    <td valign="middle">Dynamic Source Routing Protocol –A Comparative Analysis with AODV and DYMO in ZigBee based Wireless Personal Area Network</td>
                                                    <td align="center" valign="middle">SPIN-2019</td>
                                                    <td valign="middle">978-1-1728-13280-7</td>
                                                    <td align="center" valign="middle">10.1109/SPIN.2019.8711790</td>
                                                    <td align="center" valign="middle">March 2019</td>
                                                    <td align="center" valign="middle">703-708</td>
                                                    <td align="center" valign="middle">EBSCO, Google scholar, ISI</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">2</td>
                                                    <td valign="middle">Performance comparison of CSMA, MACA, Generic MAC and Sensor MAC channel access protocols for ZigBee WSN with RIPv2 as Routing protocol</td>
                                                    <td align="center" valign="middle">i-PACT 2019</td>
                                                    <td valign="middle">978-1-3817-23241-6</td>
                                                    <td align="center" valign="middle">10.1109/iPACT.2019.8711790</td>
                                                    <td align="center" valign="middle">March 2019</td>
                                                    <td align="center" valign="middle">836-841</td>
                                                    <td align="center" valign="middle">EBSCO, Google scholar, ISI</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">3</td>
                                                    <td valign="middle">Average end-to-end delay of customized ZigBee stack</td>
                                                    <td align="center" valign="middle">PARCOMPTECH-2017</td>
                                                    <td valign="middle">978-1-5090-2080-5/16</td>
                                                    <td align="center" valign="middle">10.1109/PARCOMPTECH.2017.8068333</td>
                                                    <td align="center" valign="middle">23-24 Feb. 2017</td>
                                                    <td align="center" valign="middle">719-723</td>
                                                    <td align="center" valign="middle">EBSCO, Google scholar, ISI</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">4</td>
                                                    <td valign="middle">Adaptation Layer towards integration of ZigBee and IP stacks</td>
                                                    <td align="center" valign="middle">IEEE-ICACDOT-2016</td>
                                                    <td valign="middle">978-1-7717-6416-1/13</td>
                                                    <td align="center" valign="middle">10.1109/ICACDOT.2016.7877581</td>
                                                    <td align="center" valign="middle">9-10 Sept. 2016</td>
                                                    <td align="center" valign="middle">1-4</td>
                                                    <td align="center" valign="middle">EBSCO, Google scholar, ISI</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">5</td>
                                                    <td valign="middle">TCP/IP and ZigBee Middleware</td>
                                                    <td align="center" valign="middle">IEEE-ICONSTEM-2016</td>
                                                    <td valign="middle">978-1-4244-6875-1/10</td>
                                                    <td align="center" valign="middle">10.1109/ICONSTEM.2016.7560978</td>
                                                    <td align="center" valign="middle">30-31 March 2016</td>
                                                    <td align="center" valign="middle">18-26</td>
                                                    <td align="center" valign="middle">EBSCO, Google scholar, ISI</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">6</td>
                                                    <td valign="middle">ZigBee Based Multi-Level Parking Vacancy Monitoring System</td>
                                                    <td align="center" valign="middle">IEEE-EIT-2010</td>
                                                    <td valign="middle">978-1-4244-2030-8/08</td>
                                                    <td align="center" valign="middle">10.1109/EIT.2010.5612133</td>
                                                    <td align="center" valign="middle">20-22 May 2010</td>
                                                    <td align="center" valign="middle">1-4</td>
                                                    <td align="center" valign="middle">EBSCO, Google scholar, ISI</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">7</td>
                                                    <td valign="middle">Fully automated commando training system</td>
                                                    <td align="center" valign="middle">IEEE-EIT-2008</td>
                                                    <td valign="middle">2166-8531/12</td>
                                                    <td align="center" valign="middle">10.1109/EIT.2008.4554267</td>
                                                    <td align="center" valign="middle">18-20 May 2008</td>
                                                    <td align="center" valign="middle">54-59</td>
                                                    <td align="center" valign="middle">EBSCO, Google scholar, ISI</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">8</td>
                                                    <td valign="middle">Zigbee based WSN with IP connectivity</td>
                                                    <td align="center" valign="middle">IEEE-CIMSIM-2012</td>
                                                    <td valign="middle">978-1-4577-0167-2/12</td>
                                                    <td align="center" valign="middle">10.1109/CIMSim.2012.39</td>
                                                    <td align="center" valign="middle">25-27 Sept. 2012</td>
                                                    <td align="center" valign="middle">178-181</td>
                                                    <td align="center" valign="middle">--</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">9</td>
                                                    <td valign="middle">WSN and IP based parking management system</td>
                                                    <td align="center" valign="middle">IEEE-ICST-2012</td>
                                                    <td valign="middle">978-1-6717-3416-5/16</td>
                                                    <td align="center" valign="middle">10.1109/ICSensT.2012.6461716</td>
                                                    <td align="center" valign="middle">18-21 Dec. 2012</td>
                                                    <td align="center" valign="middle">434-438</td>
                                                    <td align="center" valign="middle">EBSCO, Google scholar, ISI</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">10</td>
                                                    <td valign="middle">Towards Realization of IP based Wireless Sensor network with emphasis on on-demand routing</td>
                                                    <td align="center" valign="middle">IEEE-ICCSP-2013</td>
                                                    <td valign="middle">0967-1345</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">April 2013</td>
                                                    <td align="center" valign="middle">1-6</td>
                                                    <td align="center" valign="middle">EBSCO, Google scholar, ISI</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">11</td>
                                                    <td valign="middle">Remote data acquisition and control system, 693-697</td>
                                                    <td align="center" valign="middle">TIES-2007</td>
                                                    <td valign="middle">--</td>
                                                    <td align="center" valign="middle">--</td>
                                                    <td align="center" valign="middle">Sept 2007</td>
                                                    <td align="center" valign="middle">693-697</td>
                                                    <td align="center" valign="middle">--</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6>List of Books Published</h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop10">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td align="center" valign="middle" width="60"><strong>S. No</strong></td>
                                                    <td align="center" valign="middle"><strong>Title of Book published</strong></td>
                                                    <td align="center" valign="middle"><strong>ISBN</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">1</td>
                                                    <td valign="middle">Smart Traffic Light Control System</td>
                                                    <td align="center" valign="middle">978-1-365-59600-1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">2</td>
                                                    <td valign="middle">Efficient Hardware Architecture for Multimedia Encryption and Authentication</td>
                                                    <td align="center" valign="middle">978-1-365-59875-3</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">3</td>
                                                    <td valign="middle">Zigbee based Remote motor Control</td>
                                                    <td align="center" valign="middle">978-1-365-60827-8</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">4</td>
                                                    <td valign="middle">Wireless Electronic Notice board</td>
                                                    <td align="center" valign="middle">978-1-365-62136-9</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">5</td>
                                                    <td valign="middle">Zigbee Based Multilevel Parking vacancy Monitoring System</td>
                                                    <td align="center" valign="middle">978-1-365-69757-9</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">6</td>
                                                    <td valign="middle">Fully Automated Commando Training System</td>
                                                    <td align="center" valign="middle">978-1-365-69759-3</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">7</td>
                                                    <td valign="middle">Selection of Cluster Head for Wireless Sensor Network</td>
                                                    <td align="center" valign="middle">978-1-365-69765-4</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">8</td>
                                                    <td valign="middle">Energy Efficient Wireless Sensor Network</td>
                                                    <td align="center" valign="middle">978-1-365-69769-2</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">9</td>
                                                    <td valign="middle">Wireless electronic display notice board</td>
                                                    <td align="center" valign="middle">972-1-387-06444-1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">10</td>
                                                    <td valign="middle">Digital alpha numeric scrolling on led screen</td>
                                                    <td align="center" valign="middle">978-1-387-35254-8</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">11</td>
                                                    <td valign="middle">Design of Ultra Low cost Cell Phone Based Embedded System for Irrigation</td>
                                                    <td align="center" valign="middle">978-1-387-35580-8</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default EceHod
