import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';
import hod from './ItImages/hod/hod.jpg'

const ITindustryIntern = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Internships
                                </h2>
                                <h6 className='py-2'>Students Internship 2019-20</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td valign="top">
                                                    <p><strong>SNO</strong></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><strong>NAME OF&nbsp; STUDENT</strong></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><strong>NAME OF ORGANIZATION</strong></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><strong>TITLE </strong></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><strong>DURATION </strong></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>1</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>K POOJA</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            ELECTRONICS CORPORATION OF
                                                            INDIA LIMITED
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            AADHAR BASED ONLINE VOTING
                                                            SYSTEM
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>8-8-2019 TO 7-9-2019</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>2</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>S MANASA </span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            ELECTRONICS CORPORATION OF
                                                            INDIA LIMITED
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            AADHAR BASED ONLINE VOTING
                                                            SYSTEM
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>8-8-2019 TO 7-9-2019</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>3</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>LIKITHA G</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>SMART BRIDGE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            ARTIFICIAL INTELLIGENCE WITH
                                                            PYTHON AND IBM WATSON
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>3-6-2019 TO 22-06-2019</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>4</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>N SAI NANDINI</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>BRAIN O VISION</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>MACHINE LEARNING ENGINEER</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>17-4-2020 TO 16-5-2020</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>5</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>K SUCHITRA</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>ZEBO.AI</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            ARTIFICIAL INTELLIGENCE WITH
                                                            PYTHON
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>28-7-2020 TO 27-8-2020</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>6</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>DUVVARI TRIVENI</span></p>
                                                    <p><span>AVULA KAVYASREE</span></p>
                                                    <p><span>GENTELA KRISHNAVENI</span></p>
                                                    <p><span>GOPAGONI NIHARIKA</span></p>
                                                    <p><span>&nbsp;</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>PANTECH SOLUTIONS</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            CAR <span zeum4c2="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">SPPED</span> CONTROL USING HAND
                                                            GESTURE
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>5-1-2021&nbsp; TO 24-1-2021</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>7</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>G.KAVYA SRI</span></p>
                                                    <p><span>T.POOJA</span></p>
                                                    <p><span>P.MANASA</span></p>
                                                    <p><span>S.SPOORTHI</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>PANTECH SOLUTIONS</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            AN INTELLIGENT TRAFFIC LIGHT
                                                            MANAGEMENT SYSTEM
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>18-1-2021 TO 2-2-2021</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>8</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>JONNALA NITHYA</span></p>
                                                    <p><span>YADAGIRI SAMYUKTHA</span></p>
                                                    <p><span>MEELA RANJITHA</span></p>
                                                    <p><span>KOMMULA VASAVI</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>PANTECH SOLUTIONS</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            VEHICLE DETECTION,TRACKING
                                                            AND SPEED ESTIMATION
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>18-1-2021 TO 2-2-2021</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>9</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>KOPPERA SUSHMA REDDY</span></p>
                                                    <p><span>KIRTI CHANDAK</span></p>
                                                    <p><span>NERELLA SAI NANDINI</span></p>
                                                    <p><span>TIWARI SINDHU</span></p>
                                                    <p><span>&nbsp;</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>PANTECH SOLUTIONS</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            DESIGNING A CHATBOT USING PYTHON
                                                            TO PREDICT AUTOMATED ANSWER
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>4-1-2021 TO 24-1-2021</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>10</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>KADAVERGU PRANATHI</span></p>
                                                    <p><span>JAMPULA DEEKSHA</span></p>
                                                    <p><span>BACHINA DIVYA</span></p>
                                                    <p><span>ADEM RUCHITHA</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>PANTECH SOLUTIONS</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            REAL TIME OBJECT DETECTION
                                                            AND TRACKING USING DEEP LEARNING
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>19-1-2021 TO 2-2-2021</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p><span>11</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>G.LIKITHA</span></p>
                                                    <p><span>C.SWATHI</span></p>
                                                    <p><span>B.VAISHNAVI</span></p>
                                                    <p><span>AYESHA</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>PANTECH SOLUTIONS</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p>
                                                        <span>
                                                            ESTIMATION OF POWER
                                                            CONSUMPTION FOR HOUSEHOLD ELECTRIC APPLIANCES USING DEEP LEARNING
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p><span>19-1-2021 TO 2-2-2021</span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className='pt-3'>
                                    <h6 className='py-2'>Internships Academic Year 2020-21
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">

                                            <thead>
                                                <tr>
                                                    <th><strong>S.No</strong></th>
                                                    <th><strong>Roll No. </strong></th>
                                                    <th><strong>Name of the Student</strong></th>
                                                    <th><strong>Name of the Organization </strong></th>
                                                    <th><strong>Area </strong></th>
                                                    <th><strong>Date</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>1. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1204</p>
                                                    </td>
                                                    <td>
                                                        <p>Adem Ruchitha</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Real time object detection and tracking using deep learning</p>
                                                    </td>
                                                    <td>
                                                        <p>19-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>2. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1205</p>
                                                    </td>
                                                    <td>
                                                        <p>Avula KavyaSree</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Car speed control using hand gesture</p>
                                                    </td>
                                                    <td>
                                                        <p>5-1-2021 to 24-1-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>3. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1206</p>
                                                    </td>
                                                    <td>
                                                        <p>Ayesha</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Estimation of power consumption for household electric appliances using deep learning</p>
                                                    </td>
                                                    <td>
                                                        <p>19-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>4. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1207</p>
                                                    </td>
                                                    <td>
                                                        <p>Bachina Divya</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Real time object detection and tracking using deep learning</p>
                                                    </td>
                                                    <td>
                                                        <p>19-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>5. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1208</p>
                                                    </td>
                                                    <td>
                                                        <p>B. Vaishnavi</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Estimation of power consumption for household electric appliances using deep learning</p>
                                                    </td>
                                                    <td>
                                                        <p>19-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>6. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1211</p>
                                                    </td>
                                                    <td>
                                                        <p>C.Swathi</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Estimation of power consumption for household electric appliances using deep learning</p>
                                                    </td>
                                                    <td>
                                                        <p>19-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>7. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1213</p>
                                                    </td>
                                                    <td>
                                                        <p>Duvvari Triveni</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Car speed control using hand gesture</p>
                                                    </td>
                                                    <td>
                                                        <p>5-1-2021 to 24-1-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>8. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1215</p>
                                                    </td>
                                                    <td>
                                                        <p> Gentela Krishnaveni </p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Car speed control using hand gesture</p>
                                                    </td>
                                                    <td>
                                                        <p>5-1-2021 to 24-1-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>9. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1216</p>
                                                    </td>
                                                    <td>
                                                        <p> Gopagoni Niharika </p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Car speed control using hand gesture</p>
                                                    </td>
                                                    <td>
                                                        <p>5-1-2021 to 24-1-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>10. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1218</p>
                                                    </td>
                                                    <td>
                                                        <p>G.Kavya Sri</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>An intelligent traffic light management system</p>
                                                    </td>
                                                    <td>
                                                        <p>18-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>11. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1219</p>
                                                    </td>
                                                    <td>
                                                        <p>G.Likitha</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Estimation of power consumption for household electric appliances using deep learning</p>
                                                    </td>
                                                    <td>
                                                        <p>19-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>12. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1220</p>
                                                    </td>
                                                    <td>
                                                        <p>Jampula Deeksha</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Real time object detection and tracking using deep learning</p>
                                                    </td>
                                                    <td>
                                                        <p>19-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>13. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1221</p>
                                                    </td>
                                                    <td>
                                                        <p>Jonnala Nithya</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Vehicle detection tracking and speed estimation</p>
                                                    </td>
                                                    <td>
                                                        <p>18-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>14. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1224</p>
                                                    </td>
                                                    <td>
                                                        <p> Kadavergu Pranathi </p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Real time object detection and tracking using deep learning</p>
                                                    </td>
                                                    <td>
                                                        <p>19-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>15. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1228</p>
                                                    </td>
                                                    <td>
                                                        <p>Kirti Chandak</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Designing a chatbot using python to predict automated answer</p>
                                                    </td>
                                                    <td>
                                                        <p>4-1-2021 to 24-1-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>16. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1229</p>
                                                    </td>
                                                    <td>
                                                        <p>T.Pooja</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>An intelligent traffic light management system</p>
                                                    </td>
                                                    <td>
                                                        <p>18-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>17. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1230</p>
                                                    </td>
                                                    <td>
                                                        <p>Kommula Vasavi</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Vehicle detection tracking and speed estimation</p>
                                                    </td>
                                                    <td>
                                                        <p>18-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>18. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1231</p>
                                                    </td>
                                                    <td>
                                                        <p> Koppera Sushma Reddy </p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Designing a chatbot using python to predict automated answer</p>
                                                    </td>
                                                    <td>
                                                        <p>4-1-2021 to 24-1-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>19. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1234</p>
                                                    </td>
                                                    <td>
                                                        <p>Meera Ranjitha</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Vehicle detection tracking and speed estimation</p>
                                                    </td>
                                                    <td>
                                                        <p>18-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>20. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1237</p>
                                                    </td>
                                                    <td>
                                                        <p> Nerella Sai Nandini </p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Designing a chatbot using python to predict automated answer</p>
                                                    </td>
                                                    <td>
                                                        <p>4-1-2021 to 24-1-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>21. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1239</p>
                                                    </td>
                                                    <td>
                                                        <p>P. Manasa</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>An intelligent traffic light management system</p>
                                                    </td>
                                                    <td>
                                                        <p>18-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>22. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1246</p>
                                                    </td>
                                                    <td>
                                                        <p>S. Spoorthy</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>An intelligent traffic light management system</p>
                                                    </td>
                                                    <td>
                                                        <p>18-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>23. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1253</p>
                                                    </td>
                                                    <td>
                                                        <p>Tiwari Sindhu</p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Designing a chatbot using python to predict automated answer</p>
                                                    </td>
                                                    <td>
                                                        <p>4-1-2021 to 24-1-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>24. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>17D21A1254</p>
                                                    </td>
                                                    <td>
                                                        <p> Yadagiri Samyuktha </p>
                                                    </td>
                                                    <td>
                                                        <p>Pantech Solutions</p>
                                                    </td>
                                                    <td>
                                                        <p>Vehicle detection tracking and speed estimation</p>
                                                    </td>
                                                    <td>
                                                        <p>18-1-2021 to 2-2-2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>25. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1205</p>
                                                    </td>
                                                    <td>
                                                        <p> ARRA HIMA SAI VARSHA </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>26. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1206</p>
                                                    </td>
                                                    <td>
                                                        <p>BADAM SNEHA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>27. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1207</p>
                                                    </td>
                                                    <td>
                                                        <p> BOMBAY SHREELATHA </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>28. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1214</p>
                                                    </td>
                                                    <td>
                                                        <p>EARRA KEERTHI</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>29. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1216</p>
                                                    </td>
                                                    <td>
                                                        <p>GORAK TANUSHA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>30. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1217</p>
                                                    </td>
                                                    <td>
                                                        <p>I.JASWANTHI</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>31. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1218</p>
                                                    </td>
                                                    <td>
                                                        <p> JONNADULA MOUNIKA </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>32. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1219</p>
                                                    </td>
                                                    <td>
                                                        <p>K.M.L.AKANKSHA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>33. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1221</p>
                                                    </td>
                                                    <td>
                                                        <p>K.SUPRIYA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>34. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1222</p>
                                                    </td>
                                                    <td>
                                                        <p>K.TABU SRI</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>35. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1223</p>
                                                    </td>
                                                    <td>
                                                        <p>KEERTHI DONAPATI</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>36. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1224</p>
                                                    </td>
                                                    <td>
                                                        <p> KOTTE SUMANA SREE </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>37. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1225</p>
                                                    </td>
                                                    <td>
                                                        <p>KUCHIPUDI SUCHITRA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>38. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1228</p>
                                                    </td>
                                                    <td>
                                                        <p>MADIREDDY AKHILA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>39. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1230</p>
                                                    </td>
                                                    <td>
                                                        <p>MEKA ANUSHA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>40. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1231</p>
                                                    </td>
                                                    <td>
                                                        <p> MUTHIREDDY ASHWITHA </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>41. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1232</p>
                                                    </td>
                                                    <td>
                                                        <p>NAGINENI ROHITHA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>42. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1233</p>
                                                    </td>
                                                    <td>
                                                        <p>NAREDLA RUCHITHA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>43. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1234</p>
                                                    </td>
                                                    <td>
                                                        <p>NEELAM ANITHA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>44. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1235</p>
                                                    </td>
                                                    <td>
                                                        <p> NEELAM CHAITANYASRI </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>45. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1236</p>
                                                    </td>
                                                    <td>
                                                        <p>P.SINDHU PRANATHI</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>46. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1237</p>
                                                    </td>
                                                    <td>
                                                        <p>PISKE ANITHA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>47. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1238</p>
                                                    </td>
                                                    <td>
                                                        <p>R.POOJA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>48. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1239</p>
                                                    </td>
                                                    <td>
                                                        <p>R.SAHITHYA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>49. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1240</p>
                                                    </td>
                                                    <td>
                                                        <p> RAMAGIRI BINDU PRIYA </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>50. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1241</p>
                                                    </td>
                                                    <td>
                                                        <p> REDDY VALLAPU TEJASWI </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>51. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1242</p>
                                                    </td>
                                                    <td>
                                                        <p>S INDU</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>52. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1243</p>
                                                    </td>
                                                    <td>
                                                        <p>S.P.S.PRANEETHA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>53. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1248</p>
                                                    </td>
                                                    <td>
                                                        <p>SINGU TEJASWINI</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>54. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1249</p>
                                                    </td>
                                                    <td>
                                                        <p> SK.SREE HARSHITHA </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>55. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1254</p>
                                                    </td>
                                                    <td>
                                                        <p> UPADHYAYULA SAI SHARMILA </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>56. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1257</p>
                                                    </td>
                                                    <td>
                                                        <p> VENKATAKUCHI DEEPTHI </p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>57. &nbsp;</p>
                                                    </td>
                                                    <td>
                                                        <p>18D21A1258</p>
                                                    </td>
                                                    <td>
                                                        <p>YEDDULA SOWMYA</p>
                                                    </td>
                                                    <td>
                                                        <p>TRIAD &ndash;Techno Services</p>
                                                    </td>
                                                    <td>
                                                        <p>Web Development</p>
                                                    </td>
                                                    <td>
                                                        <p>22<sup>nd</sup> Feb to 06<sup>th</sup> Mar 2021</p>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6 className='py-2'>Internships Academic Year 2019-20
                                    </h6>
                                    <div class="table-responsive" >
                                        <table class="table table-bordered">
                                            <thead class="bg-gray">
                                                <tr>
                                                    <th><strong>S.No</strong></th>
                                                    <th><strong>Roll No. </strong></th>
                                                    <th><strong>Name of the Student</strong></th>
                                                    <th><strong>Name of the Organization	</strong></th>
                                                    <th><strong>Area </strong></th>
                                                    <th><strong>Date</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td valign="top">
                                                        <p ><span >1</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >17D21A1229</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >K POOJA</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span >
                                                                ELECTRONICS CORPORATION OF
                                                                INDIA LIMITED
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span >
                                                                AADHAR BASED ONLINE VOTING
                                                                SYSTEM
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >8-8-2019 TO 7-9-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">
                                                        <p ><span >2</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >17D21A1247</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >S MANASA </span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span >
                                                                ELECTRONICS CORPORATION OF
                                                                INDIA LIMITED
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span >
                                                                AADHAR BASED ONLINE VOTING
                                                                SYSTEM
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >8-8-2019 TO 7-9-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><span >3</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >17D21A1219</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >LIKITHA G</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >SMART BRIDGE</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span >
                                                                ARTIFICIAL INTELLIGENCE WITH
                                                                PYTHON AND IBM WATSON
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >3-6-2019 TO 22-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><span >4</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >17D21A1237</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >N SAI NANDINI</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >BRAIN O VISION</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >MACHINE LEARNING ENGINEER</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >17-4-2020 TO 16-5-2020</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p ><span >5</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >18D21A1225</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >K SUCHITRA</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >ZEBO.AI</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p >
                                                            <span >
                                                                ARTIFICIAL INTELLIGENCE WITH
                                                                PYTHON
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td valign="top">
                                                        <p ><span >28-7-2020 TO 27-8-2020</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='pt-3'>
                                    <h6 className='py-2'>Internships Academic Year 2018-19
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td>
                                                        <strong>S.No</strong>
                                                    </td>
                                                    <td>
                                                        <strong>ROLL NUMBER</strong>
                                                    </td>
                                                    <td>
                                                        <strong>NAME</strong>
                                                    </td>
                                                    <td>
                                                        <strong>Company</strong>
                                                    </td>
                                                    <td>
                                                        <strong>DURATION</strong>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top">
                                                        <p align="center" >
                                                            <span>
                                                                1.<span >
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1209</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span >K Ruchitha</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                2.<span >
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1206</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>E Samatha </span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                3.<span >
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1202</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>B Divya</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                4.<span >
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1234</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>P Shivani</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                5.<span >
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1214</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>Anusha</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                6.<span>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1222</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>M Sanjana</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                7.<span >
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1216</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>K Manisha</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                8.<span >
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1203</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>B Akhila</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                9.<span >
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1217</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>Lakshmi Meghana</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>Techimax</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>12-05-2019 to 12-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                10.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>17D21A1219</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>G Likhitha</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                11.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1245</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>V Anusha</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                12.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1215</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>K Samanvitha </span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                13.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1230</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>Sravya</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                14.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>17D21A1251</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>Pooja</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                15.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>17D21A1218</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>G Kavya</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>SmartBridge</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>27-05-2019 to 27-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                16.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>17D21A1242</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>Rubeena</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>&nbsp;Internshala</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>4-12-2018 to 4-2-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                17.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>17D21A1242</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>Rubeena</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p ><span>Internshala</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>19-08-2018 to 19-09-2018</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                18.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1228</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>N Usharani</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>Techimax</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>12-05-2019 to 12-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                19.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1230</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>K Lohitha</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>Techimax</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>12-05-2019 to 12-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                20.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1205</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>D Taruni</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>Techimax</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>12-05-2019 to 12-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                21.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1246</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>V Sujana</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>Techimax</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>12-05-2019 to 12-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                22.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1207</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>G Soumya</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>360digrii</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>29-05-2019 to 29-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                23.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1239</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>Reshma <span zeum4c5="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling">Begaum</span></span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>360digrii</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>29-05-2019 to 29-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                24.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1208</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>I Veeshali</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>360digrii</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>29-05-2019 to 29-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                25.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1231</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>O Sowjanya</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>360digrii</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>29-05-2019 to 29-06-2019</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td valign="top" >
                                                        <p align="center" >
                                                            <span>
                                                                26.<span >
                                                                    &nbsp;
                                                                </span>
                                                            </span><span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>16D21A1242</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p><span>T. Maulikaa</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>360digrii</span></p>
                                                    </td>
                                                    <td valign="top" >
                                                        <p align="center" ><span>29-05-2019 to 29-06-2019</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ITindustryIntern
