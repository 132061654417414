import React from "react";
import './App.css';

import { Route, Switch, Router } from "react-router-dom"
import AboutClg from "./About/AboutClg";
import DeanAcademics from "./About/dean-academics";
import DeanAdmissions from "./About/dean-admissions";
import BOS from "./About/board-of-studies";
import MainSlider from './HomeSlider/MainSlider'
import Management from "./About/Management";
import SWECRecognitions from "./About/SWECRecognitions";
import Aicte from "./About/Aicte";
import GoverningBody from "./About/GoverningBody";
import Prncipal from "./About/Principal";

import Disclosure from "./About/Disclosure";
import ServiceRules from "./About/ServiceRules";
import Organogram from "./About/Organogram";
import SalientFeatures from "./About/SalientFeatures";
import CSE from "./Departments/mainTags/CSE";
import CseDs from "./Departments/mainTags/CseDs";
import CseAIMl from "./Departments/mainTags/CseAIMl";
import DECE from "./Departments/mainTags/D-ECE";
import IT from "./Departments/mainTags/IT";
import EEE from "./Departments/mainTags/EEE";
import MBA from "./Departments/mainTags/MBA";
import SandH from "./Departments/mainTags/SandH";
import DPE from "./Departments/mainTags/DPE";
import Contact from "./contact/Contact";
import InnovationIncubation from "./RandD/InnovationIncubation";
import GrantsReceived from "./RandD/GrantsReceived";
import ResearchGuide from "./RandD/ResearchGuide";
import IndustryInstituteInteraction from "./RandD/IndustryInstituteInteraction";
import QualityPolicy from "./Iqac/QualityPolicy";
import QualityEducation from "./Iqac/QualityEducation";
import IQAC from "./Iqac/IQAC";
import NIRF from "./Nirf/NIRF";
import Overall from "./Nirf/Overall";
import Feedback from "./feedback/Feedback";
import Testimonials from "./testimonials/Testimonials";
import GiveranceCell from "./SeconNav/GiveranceCell";
import Alumni from "./SeconNav/Alumni";
import ProfessionalSocieties from "./SeconNav/ProfessionalSocieties";
import EDC from "./SeconNav/EDC";
import CentralFacilities from "./Facilities/CentralFacilities";
import Transport from "./Facilities/Transport";
import ComputerCenter from "./Facilities/ComputerCenter";
import Hostel from "./Facilities/Hostel";
import ICT from "./Facilities/ICT";
import OBE from "./Activities/OBE";
import NCC from "./Activities/NCC";
import NSS from "./Activities/NSS";
import SSWES from "./Activities/SSWES";
import ClgMagzine from "./Activities/ClgMagzine";
import Admissions from "./About/Admissions";
import TPD from "./TrainingAndPlacements/TPD";
import P2021 from './TrainingAndPlacements/P2021'
import Library from "./Departments/mainTags/Library";
import Jntuh from "./ExaminationCell/Jntuh";
import AcaMba from "./ExaminationCell/AcaMba";
import JntuSyllubus from "./ExaminationCell/JntuSyllubus";
import JntuTimeTable from "./ExaminationCell/JntuTimeTable";
import Notifications from "./ExaminationCell/Notifications";
import Academics from "./ExaminationCell/Academics";
import FeedbackForms from "./ExaminationCell/FeedbackForms";
import HolidaysList from "./ExaminationCell/HolidaysList";
import AcademicAutonomous from "./ExaminationCell/Autonomous/AcademicAutonomous";
import AutonomousSyllubus from "./ExaminationCell/Autonomous/AutonomousSyllubus";
import P1920 from "./TrainingAndPlacements/P1920";
import P1819 from "./TrainingAndPlacements/P1819";
import P1718 from "./TrainingAndPlacements/P1718";
import P1617 from "./TrainingAndPlacements/P1617";
import P1516 from "./TrainingAndPlacements/P1516";
import P1415 from "./TrainingAndPlacements/P1415";
import P1314 from "./TrainingAndPlacements/P1314";
import P1213 from "./TrainingAndPlacements/P1213";
import TechnicalTraining from "./TrainingAndPlacements/TechnicalTraining";
import Task from "./TrainingAndPlacements/Task";
import DeptWise from "./TrainingAndPlacements/DeptWise";
import CseVision from "./Departments/cseDepartmentLinks/CseVision";
import CSEpo from "./Departments/cseDepartmentLinks/CSEpo";
import CSEHod from "./Departments/cseDepartmentLinks/CSEHod";
import CSEStudentProjects from './Departments/cseDepartmentLinks/CSEStudentProjects'
import IndustryVisits from './Departments/cseDepartmentLinks/CSEIndustryVisits'
import CSEStudentDPrograms from "./Departments/cseDepartmentLinks/CSEStudentDPrograms";
import CSESyllubus from "./Departments/cseDepartmentLinks/CSESyllubus";
import CSEPlacements from "./Departments/cseDepartmentLinks/CSEPlacements";
import CSEProffessional from "./Departments/cseDepartmentLinks/CSEProffessional";
import CSENewsLetter from "./Departments/cseDepartmentLinks/CSENewsLetter";
import CSEFacultyPublications from "./Departments/cseDepartmentLinks/CSEFacultyPublications";
import FacultyInnovation from "./Departments/cseDepartmentLinks/FacultyInnovation";
import CSEWorkShop from "./Departments/cseDepartmentLinks/CSEWorkShop";
import CSEbooksPublished from "./Departments/cseDepartmentLinks/CSEbooksPublished";
import PatentPublications from "./Departments/cseDepartmentLinks/PatentPublications";
import CSELaboratoryProjects from "./Departments/cseDepartmentLinks/CSELaboratoryProjects";
import CSEAcademicSponser from "./Departments/cseDepartmentLinks/CSEAcademicSponser";
import CSEBridgeCourse from "./Departments/cseDepartmentLinks/CSEBridgeCourse";
import CSECourseFiles from "./Departments/cseDepartmentLinks/CSECourseFiles";
import CSELaboratories from "./Departments/cseDepartmentLinks/CSELaboratories";
import CSELibrary from "./Departments/cseDepartmentLinks/CSELibrary";
import CSEMous from "./Departments/cseDepartmentLinks/CSEMous";
import CSEDS from "./Departments/cseDepartmentLinks/CSEDS";
import EceVision from "./Departments/ECELinks/EceVision";
import EcePo from "./Departments/ECELinks/EcePo";
import EceHod from "./Departments/ECELinks/EceHod";
import EceFaculty from "./Departments/ECELinks/EceFaculty";
import EceCalender from "./Departments/cseDepartmentLinks/EceCalender";
import EceStudentProjects from "./Departments/ECELinks/EceStudentProjects";
import EceIndustryIntern from "./Departments/ECELinks/EceIndustryIntern";
import EceIndustryVisits from "./Departments/ECELinks/EceIndustryVisits";
import EceSDP from "./Departments/ECELinks/EceSDP";
import EceSyllabus from "./Departments/ECELinks/EceSyllabus";
import EceAnalysis from "./Departments/ECELinks/EceAnalysis";
import EcePlacements from "./Departments/ECELinks/EcePlacements";
import EceProffessionalBodies from "./Departments/ECELinks/EceProffessionalBodies";
import EceMagazines from "./Departments/ECELinks/EceMagazines";
import EceStudentPublications from "./Departments/ECELinks/EceStudentPublications";
import ECEFacultyInnovations from "./Departments/ECELinks/ECEFacultyInnovations";
import ECEworkshops from "./Departments/ECELinks/ECEworkshops";
import EceFacultyPerprmance from "./Departments/ECELinks/EceFacultyPerprmance";
import EceLaboratoryProjects from "./Departments/ECELinks/EceLaboratoryProjects";
import EceAcademicSponsered from "./Departments/ECELinks/EceAcademicSponsered";
import EceCourseFiles from "./Departments/ECELinks/EceCourseFiles";
import EceDepartmentLibrary from "./Departments/ECELinks/EceDepartmentLibrary";
import EceMous from "./Departments/ECELinks/EceMous";
import ItVision from "./Departments/ITlinks/ItVision";
import ItPO from "./Departments/ITlinks/ItPO";
import ITHOD from "./Departments/ITlinks/ITHOD";
import ITSprojects from "./Departments/ITlinks/ITSprojects";
import ITindustryIntern from "./Departments/ITlinks/ITindustryIntern";
import ItIndustryVisits from "./Departments/ITlinks/ItIndustryVisits";
import ItSyllabus from "./Departments/ITlinks/ItSyllabus";
import ItPlacements from "./Departments/ITlinks/ItPlacements";
import Itmagazines from "./Departments/ITlinks/Itmagazines";
import ItFacultyAchievements from "./Departments/ITlinks/ItFacultyAchievements";
import ItFaxultyInnovations from "./Departments/ITlinks/ItFaxultyInnovations";
import ItWorkshops from "./Departments/ITlinks/ItWorkshops";
import ItBridgeCourse from "./Departments/ITlinks/ItBridgeCourse";
import ItLibrary from "./Departments/ITlinks/ItLibrary";
import EEEVision from "./Departments/EEELinks/EEEVision";
import EEEPoAnsPSO from "./Departments/EEELinks/EEEPoAnsPSO";
import EEEIndustryInterm from "./Departments/EEELinks/EEEIndustryInterm";
import EEEIndustryVisits from "./Departments/EEELinks/EEEIndustryVisits";
import EEESDP from "./Departments/EEELinks/EEESDP";
import EEESyllabus from "./Departments/EEELinks/EEESyllabus";
import EEEPlacements from "./Departments/EEELinks/EEEPlacements";
import EEEProfessionalBodies from "./Departments/EEELinks/EEEProfessionalBodies";
import EEEMagazines from "./Departments/EEELinks/EEEMagazines";
import EEEStudentAchievements from "./Departments/EEELinks/EEEStudentAchievements";
import EEEFacultyInnovation from "./Departments/EEELinks/EEEFacultyInnovation";
import EEEworkshops from "./Departments/EEELinks/EEEworkshops";
import EEEFacultyPerformance from "./Departments/EEELinks/EEEFacultyPerformance";
import EEEEandD from "./Departments/EEELinks/EEEEandD";
import EEEAcademicSponser from "./Departments/EEELinks/EEEAcademicSponser";
import EEECourseFiles from "./Departments/EEELinks/EEECourseFiles";
import EEELibrary from "./Departments/EEELinks/EEELibrary";
import EEEMous from "./Departments/EEELinks/EEEMous";
import EEEAcademicPlan from "./Departments/EEELinks/EEEAcademicPlan";
import MbaVision from "./Departments/mbaLinks/MbaVision";
import MbaPoandPso from "./Departments/mbaLinks/MbaPoandPso";
import MbaHod from "./Departments/mbaLinks/MbaHod";
import MbaFaculty from "./Departments/mbaLinks/MbaFaculty";
import MbaMentorList from "./Departments/mbaLinks/MbaMentorList";
import MbaInfrastructure from "./Departments/mbaLinks/MbaInfrastructure";
import MbaSyllabus from "./Departments/mbaLinks/MbaSyllabus";
import MbaRemedialClass from "./Departments/mbaLinks/MbaRemedialClass";
import MbaBridgeCourse from "./Departments/mbaLinks/MbaBridgeCourse";
import MbaWorkShop from "./Departments/mbaLinks/MbaWorkShop";
import MbaActivities from "./Departments/mbaLinks/MbaActivities";
import MbaInnovativeTeachig from "./Departments/mbaLinks/MbaInnovativeTeachig";
import MbaPlacements from "./Departments/mbaLinks/MbaPlacements";
import Svision from "./Departments/SandH/Svision";
import SHod from "./Departments/SandH/SHod";
import SandHFaculty from "./Departments/SandH/SandHFaculty";
import Hactivities from "./Departments/SandH/Hactivities";
import DpeHod from "./Departments/DPE/DpeHod";
import DpeInfra from "./Departments/DPE/DpeInfra";
import DpeAchievements from "./Departments/DPE/DpeAchievements";
import DpeSports from "./Departments/DPE/DpeSports";
import News from "./NewsAndEvents/News";
import NationalYouthFest  from "./NewsAndEvents/newsSection/national-youth-festival-2024";
import Circulars from "./NewsAndEvents/Circulars";
import SwecRadio from "./NewsAndEvents/SwecRadio";
import Events from "./NewsAndEvents/Events";
import WomensDayCelebrations2023 from "./NewsAndEvents/eventsSaection/WomensDayCelebrations2023";
import RepublicDayCelebrations2024 from "./NewsAndEvents/eventsSaection/RepublicDayCelebrations2024";
import IDEATHON2k23 from "./NewsAndEvents/eventsSaection/IDEATHON2k23";
import ISROIndustrialVisit2Year from "./NewsAndEvents/eventsSaection/ISROIndustrialVisit2Year";
import ProjectExpo2022 from "./NewsAndEvents/eventsSaection/ProjectExpo2022";
import IndependentDay2022 from "./NewsAndEvents/eventsSaection/IndependentDay2022";
import InternationalYogaDay2022 from "./NewsAndEvents/eventsSaection/InternationalYogaDay2022.js";
import VisitVisro2022 from "./NewsAndEvents/eventsSaection/VisitVisro2022";
import Alumni2122 from "./NewsAndEvents/eventsSaection/Alumni2122";
import WorkshoponAndroidandmobileAps from "./NewsAndEvents/eventsSaection/WorkshoponAndroidandmobileAps";
import HackathonCertificateDistribution from "./NewsAndEvents/eventsSaection/HackathonCertificateDistribution";
import ISROIndustrialVisit2021 from "./NewsAndEvents/eventsSaection/ISROIndustrialVisit2021";
import HackathonValedictoryPhotography from "./NewsAndEvents/eventsSaection/HackathonValedictoryPhotography.js";
import AntidrugAbuseCommittee from "./NewsAndEvents/eventsSaection/AntidrugAbuseCommittee";
import WomensDay2022 from "./NewsAndEvents/eventsSaection/WomensDay2022";
import HairDonationDrive from "./NewsAndEvents/eventsSaection/HairDonationDrive";
import ObeWorkShop2022 from "./NewsAndEvents/eventsSaection/ObeWorkShop2022";
import Republic2022 from "./NewsAndEvents/eventsSaection/Republic2022";
import InductionProgram2122 from "./NewsAndEvents/eventsSaection/InductionProgram2122";
import Bathukamma2021 from "./NewsAndEvents/eventsSaection/Bathukamma2021";
import YuvaSamarthya2021 from "./NewsAndEvents/eventsSaection/YuvaSamarthya2021";
import AICTESponsoredSTTP from "./NewsAndEvents/eventsSaection/AICTESponsoredSTTP";
import WomensDay2021 from "./NewsAndEvents/eventsSaection/WomensDay2021";
import RepublicDayCelebrations2021 from "./NewsAndEvents/eventsSaection/RepublicDayCelebrations2021";
import MouWithStudyOrbit from "./NewsAndEvents/eventsSaection/MouWithStudyOrbit";
import IsoCertificate from "./NewsAndEvents/eventsSaection/IsoCertificate";
import AlumniMeet2020 from "./NewsAndEvents/eventsSaection/AlumniMeet2020";
import AICTEATALFDP from "./NewsAndEvents/eventsSaection/AICTEATALFDP";
import FirstYearOrientationDay2020 from "./NewsAndEvents/eventsSaection/FirstYearOrientationDay2020";
import MOUwithTexasReview from "./NewsAndEvents/eventsSaection/MOUwithTexasReview.js";
import STTPonBlockchain from "./NewsAndEvents/eventsSaection/STTPonBlockchain";
import ICDML2020 from "./NewsAndEvents/eventsSaection/ICDML2020";
import FDPandWEBINARS from "./NewsAndEvents/eventsSaection/FDPandWEBINARS";
import FDPandSDP from "./NewsAndEvents/eventsSaection/FDPandSDP";
import Selfdefenceskills from "./NewsAndEvents/eventsSaection/Selfdefenceskills";
import SayNoPlastic from "./NewsAndEvents/eventsSaection/SayNoPlastic";
import CybersecurityAmbassadorSeminar from "./NewsAndEvents/eventsSaection/CybersecurityAmbassadorSeminar";
import MBAworkshop from "./NewsAndEvents/eventsSaection/MBAworkshop";
import HigherEducationseminar from "./NewsAndEvents/eventsSaection/HigherEducationseminar";
import EngineeringEducatorsAwards from "./NewsAndEvents/eventsSaection/EngineeringEducatorsAwards";
import SheTeamsProgramme from "./NewsAndEvents/eventsSaection/SheTeamsProgramme";
import BathukammaCelebrations2019 from "./NewsAndEvents/eventsSaection/BathukammaCelebrations2019";
import KillCancerEssaywritingcompetition from "./NewsAndEvents/eventsSaection/KillCancerEssaywritingcompetition";
import MOU2020 from "./NewsAndEvents/eventsSaection/MOU2020";
import JntuhPrincipalMeet from "./NewsAndEvents/eventsSaection/JntuhPrincipalMeet";
import IsoCertificate2015 from "./NewsAndEvents/eventsSaection/IsoCertificate2015 ";
import MBAOrientationDay2019 from "./NewsAndEvents/eventsSaection/MBAOrientationDay2019";
import Events2019 from "./NewsAndEvents/eventsSaection/Events2019";
import VIDHYOUTH18 from "./NewsAndEvents/eventsSaection/VIDHYOUTH18";
import Sports2018 from "./NewsAndEvents/eventsSaection/Sports2018";
import Maithri2018 from "./NewsAndEvents/eventsSaection/Maithri2018";
import WorkshopsandSeminars from "./NewsAndEvents/eventsSaection/WorkshopsandSeminars";
import SportsAwards201819 from "./NewsAndEvents/eventsSaection/SportsAwards201819";
import Pratiyogita2K19 from "./NewsAndEvents/eventsSaection/Pratiyogita2K19";
import QubitMGITTechnicalfest from "./NewsAndEvents/eventsSaection/QubitMGITTechnicalfest";
import SriSamvidhan2019 from "./NewsAndEvents/eventsSaection/SriSamvidhan2019";
import KillCancerAwareness5KMarathon from "./NewsAndEvents/eventsSaection/KillCancerAwareness5KMarathon.js";
import Maithri2019FlashmobandSong from "./NewsAndEvents/eventsSaection/Maithri2019FlashmobandSong";
import SwecsGallery from "./NewsAndEvents/SwecsGallery";
import EducationMatterscounseling from "./NewsAndEvents/gallerySection/EducationMatterscounseling";
import InfrastructureManagement from "./NewsAndEvents/gallerySection/InfrastructureManagement";
import FlashMob2018 from "./NewsAndEvents/gallerySection/FlashMob2018";
import Farewell2018 from "./NewsAndEvents/gallerySection/Farewell2018";
import OrientationProgram2016 from "./NewsAndEvents/gallerySection/OrientationProgram2016";
import ISBdebate2016 from "./NewsAndEvents/gallerySection/ISBdebate2016";
import NSSProgramme from "./NewsAndEvents/gallerySection/NSSProgramme";
import IdeathonMediaCoverage from "./NewsAndEvents/newsSection/IdeathonMediaCoverage.js";
import FestSriSamvidhan from "./NewsAndEvents/newsSection/FestSriSamvidhan";
import NAACGrade from "./NewsAndEvents/newsSection/NAACGrade";
import WalkinInteriview from "./NewsAndEvents/newsSection/WalkinInteriview.js";
import OnlineDataScienceMachine from "./NewsAndEvents/newsSection/OnlineDataScienceMachine";
import IIRSCertifcate from "./NewsAndEvents/newsSection/IIRSCertifcate";
import JNTUHCovid19Circular from "./NewsAndEvents/newsSection/JNTUHCovid19Circular";
import SHEteam5thanniversary from "./NewsAndEvents/newsSection/SHEteam5thanniversary";
import NSSNews from "./NewsAndEvents/newsSection/NSSNews";
import FreshersDay from "./NewsAndEvents/newsSection/FreshersDay";
import NationalSportsDaycelebration from "./NewsAndEvents/newsSection/NationalSportsDaycelebration.js";
import VidhYouth2 from "./NewsAndEvents/newsSection/VidhYouth2";
import NSSEventSistersforaChange from "./NewsAndEvents/newsSection/NSSEventSistersforaChange";
import GraduationDay2019 from "./NewsAndEvents/newsSection/GraduationDay2019";
import FirstYearOrientationDay2019 from "./NewsAndEvents/newsSection/FirstYearOrientationDay2019";
import NBACertified from "./NewsAndEvents/newsSection/NBACertified";
import Pratiyogita2K19News from "./NewsAndEvents/newsSection/Pratiyogita2K19News";
import TheIQPrincess2019 from "./NewsAndEvents/newsSection/TheIQPrincess2019";
import Maithri2K19 from "./NewsAndEvents/newsSection/Maithri2K19";
import Maithri2019News from "./NewsAndEvents/newsSection/Maithri2019News";
import JBRECTechnicalFest from "./NewsAndEvents/newsSection/JBRECTechnicalFest";
import EngineeringEducatorsAwardsNews from "./NewsAndEvents/newsSection/EngineeringEducatorsAwardsNews";
import NausainikCamp from "./NewsAndEvents/newsSection/NausainikCamp";
import VIDHYOUTH18news from "./NewsAndEvents/newsSection/VIDHYOUTH18news";
import CollegeDayNews from "./NewsAndEvents/newsSection/CollegeDayNews";
import SportsNews from "./NewsAndEvents/newsSection/SportsNews";
import Graduationday2023 from "./NewsAndEvents/newsSection/graduation-day-2023";
import Prakalp from "./NewsAndEvents/newsSection/it-project-expo-prakalp";
import ECEProject2023 from "./NewsAndEvents/newsSection/ece-project-expo-2023";
import CSEFaculty from "./Departments/cseDepartmentLinks/CSEFaculty";
// import Maithri2019 from "./NewsAndEvents/newsSection/Maithri2019";
import ItFaculty from './Departments/ITlinks/ItFaculty'
import EEEFaculty from './Departments/EEELinks/EEEFaculty'
import P2122 from "./TrainingAndPlacements/P2122";
import P2223 from "./TrainingAndPlacements/P2223";
import SkillEnhancement from "./TrainingAndPlacements/SkillEnhancement";
import IntellectualPropertyRights from './footer/IntellectualPropertyRights'
import TermsConditions from "./footer/TermsConditions";
import ExtensionActivities from "./footer/ExtensionActivities";
import WebsitePolicies from "./footer/WebsitePolicies";
import CopyrightPolicy from "./footer/CopyrightPolicy";
import ReachUs from "./footer/ReachUs";
import Location from "./footer/Location";
import EventsVideos from "./events/EventsVideos";
import Committie from "./About/Committie.js";
import NAAC from "./NAAC.js";
import NAAC2021 from "./NAAC-2021.js";
import NAACLogin from "./NAACLogin.js";




function App() {
  return (

    <React.Fragment>
      <section>
        <Switch>
          <Route path="/" exact>
            <MainSlider />
          </Route>
          <Route path="/home" exact>
            <MainSlider />
          </Route>
          <Route path="/NAAC" exact>
            <NAAC />
          </Route>
          <Route path="/NAAC-2021" exact>
            <NAAC2021 />
          </Route>
          <Route path="/NAACLogin" exact>
            <NAACLogin />
          </Route>
          <Route path="/about/about-college" exact>
            <AboutClg />
          </Route>
          <Route path="/about/dean-academics" exact>
            <DeanAcademics />
          </Route>
          <Route path="/about/dean-admissions" exact>
            <DeanAdmissions />
          </Route>
          <Route path="/about/board-of-studies" exact>
            <BOS />
          </Route>
          <Route path="/about/management" exact>
            <Management />
          </Route>
          <Route path="/about/recognitions" exact>
            <SWECRecognitions />
          </Route>
          <Route path="/about/aicte" exact>
            <Aicte />
          </Route>
          <Route path="/about/governing-body" exact>
            <GoverningBody />
          </Route>
          <Route path="/about/principal" exact>
            <Prncipal />
          </Route>
          <Route path="/about/committee" exact>
          <Committie />
          </Route>
          <Route path="/about/mandatory-disclosure" exact>
            <Disclosure />
          </Route>
          <Route path="/about/service-rules" exact>
            <ServiceRules />
          </Route>
          <Route path="/about/organogram" exact>
            <Organogram />
          </Route>
          <Route path="/about/salient-features" exact>
            <SalientFeatures />
          </Route>
          <Route path="/dept/cse" exact>
            <CSE />
          </Route>
          <Route path="/dept/cse-ds/" exact>
            <CseDs />
          </Route>
          <Route path="/dept/ai-ml/" exact>
            <CseAIMl />
          </Route>
          <Route path="/dept/ece/" exact>
            <DECE />
          </Route>
          <Route path="/dept/it/" exact>
            <IT />
          </Route>
          <Route path="/dept/eee/" exact>
            <EEE />
          </Route>
          <Route path="/dept/mba/" exact>
            <MBA />
          </Route>
          <Route path="/dept/s-and-h/" exact>
            <SandH />
          </Route>
          <Route path="/dept/dpe/" exact>
            <DPE />
          </Route>
          <Route path="/contact-us/" exact>
            <Contact />
          </Route>
          <Route path="/r-and-d/innovation-incubation/" exact>
            <InnovationIncubation />
          </Route>
          <Route path="/r-and-d/grants-received/" exact>
            <GrantsReceived />
          </Route>
          <Route path="/r-and-d/research-guide/" exact>
            <ResearchGuide />
          </Route>
          <Route path="/r-and-d/industry-institute-interaction/" exact>
            <IndustryInstituteInteraction />
          </Route>
          <Route path="/iqac/quality-policy/" exact>
            <QualityPolicy />
          </Route>
          <Route path="/iqac/quality-education/" exact>
            <QualityEducation />
          </Route>
          <Route path="/iqac/" exact>
            <IQAC />
          </Route>
          <Route path="/nirf/" exact>
            <NIRF />
          </Route>
          <Route path="/overall/" exact>
            <Overall />
          </Route>
          <Route path="/feedback/" exact>
            <Feedback />
          </Route>
          <Route path="/testimonials/" exact>
            <Testimonials />
          </Route>
          <Route path="/grievance-cell/" exact>
            <GiveranceCell />
          </Route>
          <Route path="/alumni/" exact>
            <Alumni />
          </Route>
          <Route path="/professional-societies/" exact>
            <ProfessionalSocieties />
          </Route>
          <Route path="/edc/" exact>
            <EDC />
          </Route>
          <Route path="/infrastructure/central-facilities/" exact>
            <CentralFacilities />
          </Route>
          <Route path="/infrastructure/transport/" exact>
            <Transport />
          </Route>
          <Route path="/infrastructure/computer-centre/" exact>
            <ComputerCenter />
          </Route>
          <Route path="/infrastructure/swec-campus-hostel/" exact>
            <Hostel />
          </Route>
          <Route path="/infrastructure/ict/" exact>
            <ICT />
          </Route>
          <Route path="/activities/obe/" exact>
            <OBE />
          </Route>
          <Route path="/activities/ncc/" exact>
            <NCC />
          </Route>
          <Route path="/activities/nss/" exact>
            <NSS />
          </Route>
          <Route path="/activities/sswec/" exact>
            <SSWES />
          </Route>
          <Route path="/activities/college-magazine/" exact>
            <ClgMagzine />
          </Route>
          <Route path="/about/admissions" exact>
            <Admissions />
          </Route>
          <Route path="/training-and-placements/" exact>
            <TPD />
          </Route>
          <Route path="/training-and-placements/2020-21" exact>
            <P2021 />
          </Route>
          <Route path="/dept/library" exact>
            <Library />
          </Route>
          <Route path="/academics-calendar/" exact>
            <Jntuh />
          </Route>
          <Route path="/academic-calendar-mba/" exact>
            <AcaMba />
          </Route>
          <Route path="/syllabus/" exact>
            <JntuSyllubus />
          </Route>
          <Route path="/time-tables/" exact>
            <JntuTimeTable />
          </Route>
          <Route path="/notifications/" exact>
            <Notifications />
          </Route>
          <Route path="/feedback-forms/" exact>
            <FeedbackForms />
          </Route>
          <Route path="/holiday-list/" exact>
            <HolidaysList />
          </Route>
          <Route path="/autonomous-academic-calendar/" exact>
            <AcademicAutonomous />
          </Route>
          <Route path="/academics/" exact>
            <Academics />
          </Route>
          <Route path="/academics-calendar/autonomous-syllabus" exact>
            <AutonomousSyllubus />
          </Route>
          <Route path="/training-and-placements/2019-20" exact>
            <P1920 />
          </Route>
          <Route path="/training-and-placements/2018-19" exact>
            <P1819 />
          </Route>
          <Route path="/training-and-placements/2017-18" exact>
            <P1718 />
          </Route>
          <Route path="/training-and-placements/2016-17" exact>
            <P1617 />
          </Route>
          <Route path="/training-and-placements/2015-16" exact>
            <P1516 />
          </Route>
          <Route path="/training-and-placements/2014-15" exact>
            <P1415 />
          </Route>
          <Route path="/training-and-placements/2013-14" exact>
            <P1314 />
          </Route>
          <Route path="/training-and-placements/2012-13" exact>
            <P1213 />
          </Route>
          <Route path="/training-and-placements/technical-training" exact>
            <TechnicalTraining />
          </Route>
          <Route path="/training-and-placements/task" exact>
            <Task />
          </Route>
          <Route path="/training-and-placements/dept-wise-placements" exact>
            <DeptWise />
          </Route>
          <Route path="/dept/cse/vision-mission" exact>
            <CseVision />
          </Route>
          <Route path="/dept/cse/po-and-pso" exact>
            <CSEpo />
          </Route>
          <Route path="/dept/cse/hod-profile" exact>
            <CSEHod />
          </Route>
          <Route path="/dept/cse/student-projects" exact>
            <CSEStudentProjects />
          </Route>
          <Route path="/dept/cse/industry-visits" exact>
            <IndustryVisits />
          </Route>
          <Route path="/dept/cse/student-development-programs" exact>
            <CSEStudentDPrograms />
          </Route>
          <Route path="/dept/cse/syllabus" exact>
            <CSESyllubus />
          </Route>
          <Route path="/dept/cse/placements" exact>
            <CSEPlacements />
          </Route>
          <Route path="/dept/cse/professional-bodies" exact>
            <CSEProffessional />
          </Route>
          <Route path="/dept/cse/magazines" exact>
            <CSENewsLetter />
          </Route>
          <Route path="/dept/cse/faculty-publications" exact>
            <CSEFacultyPublications />
          </Route>
          <Route path="/dept/cse/faculty-innovations" exact>
            <FacultyInnovation />
          </Route>
          <Route path="/dept/cse/workshops" exact>
            <CSEWorkShop />
          </Route>
          <Route path="/dept/cse/books-published" exact>
            <CSEbooksPublished />
          </Route>
          <Route path="/dept/cse/patent-publications" exact>
            <PatentPublications />
          </Route>
          <Route path="/dept/cse/laboratory-projects" exact>
            <CSELaboratoryProjects />
          </Route>
          <Route path="/dept/cse/academic-sponsered" exact>
            <CSEAcademicSponser />
          </Route>
          <Route path="/dept/cse/bridge-course" exact>
            <CSEBridgeCourse />
          </Route>
          <Route path="/dept/cse/course-files" exact>
            <CSECourseFiles />
          </Route>
          <Route path="/dept/cse/laboratories" exact>
            <CSELaboratories />
          </Route>
          <Route path="/dept/cse/department-library" exact>
            <CSELibrary />
          </Route>
          <Route path="/dept/cse/department-mous" exact>
            <CSEMous />
          </Route>
          <Route path="/dept/cse-ds" exact>
            <CSEDS />
          </Route>
          <Route path="/dept/ece-ds/vision-mission-peos" exact>
            <EceVision />
          </Route>
          <Route path="/dept/ece-ds/po-and-pso" exact>
            <EcePo />
          </Route>
          <Route path="/dept/ece-ds/hod" exact>
            <EceHod />
          </Route>
          <Route path="/dept/ece-ds/ece-faculty" exact>
            <EceFaculty />
          </Route>
          <Route path="/dept/ece-ds/department-academic-calendar" exact>
            <EceCalender />
          </Route>
          <Route path="/dept/ece-ds/student-projects" exact>
            <EceStudentProjects />
          </Route>
          <Route path="/dept/ece-ds/industry-internship" exact>
            <EceIndustryIntern />
          </Route>
          <Route path="/dept/ece-ds/industry-visits" exact>
            <EceIndustryVisits />
          </Route>
          <Route path="/dept/ece-ds/student-development-programs" exact>
            <EceSDP />
          </Route>
          <Route path="/dept/ece-ds/syllabus" exact>
            <EceSyllabus />
          </Route>
          <Route path="/dept/ece-ds/result-analysis" exact>
            <EceAnalysis />
          </Route>
          <Route path="/dept/ece-ds/placements" exact>
            <EcePlacements />
          </Route>
          <Route path="/dept/ece-ds/professional-bodies" exact>
            <EceProffessionalBodies />
          </Route>
          <Route path="/dept/ece-ds/magazines" exact>
            <EceMagazines />
          </Route>
          <Route path="/dept/ece-ds/student-publications" exact>
            <EceStudentPublications />
          </Route>
          <Route path="/dept/ece-ds/faculty-innovations" exact>
            <ECEFacultyInnovations />
          </Route>
          <Route path="/dept/ece-ds/workshops" exact>
            <ECEworkshops />
          </Route>
          <Route path="/dept/ece-ds/faculty-performance" exact>
            <EceFacultyPerprmance />
          </Route>
          <Route path="/dept/ece-ds/laboratory-projects" exact>
            <EceLaboratoryProjects />
          </Route>
          <Route path="/dept/ece-ds/academic-sponsered" exact>
            <EceAcademicSponsered />
          </Route>
          <Route path="/dept/ece-ds/course-files" exact>
            <EceCourseFiles />
          </Route>
          <Route path="/dept/ece-ds/department-library" exact>
            <EceDepartmentLibrary />
          </Route>
          <Route path="/dept/ece-ds/department-mous" exact>
            <EceMous />
          </Route>
          <Route path="/dept/it/vision-mission-peos" exact>
            <ItVision />
          </Route>
          <Route path="/dept/it/po-and-pso" exact>
            <ItPO />
          </Route>
          <Route path="/dept/it/hod-profile" exact>
            <ITHOD />
          </Route>
          <Route path="/dept/it/student-projects" exact>
            <ITSprojects />
          </Route>
          <Route path="/dept/it/industry-internship" exact>
            <ITindustryIntern />
          </Route>
          <Route path="/dept/it/industry-visits" exact>
            <ItIndustryVisits />
          </Route>
          <Route path="/dept/it/syllabus" exact>
            <ItSyllabus />
          </Route>
          <Route path="/dept/it/placements" exact>
            <ItPlacements />
          </Route>
          <Route path="/dept/it/magazines" exact>
            <Itmagazines />
          </Route>
          <Route path="/dept/it/faculty-publications" exact>
            <ItFacultyAchievements />
          </Route>

          <Route path="/dept/it/faculty-innovations" exact>
            <ItFaxultyInnovations />
          </Route>
          <Route path="/dept/it/workshops" exact>
            <ItWorkshops />
          </Route>
          <Route path="/dept/it/bridge-course" exact>
            <ItBridgeCourse />
          </Route>
          <Route path="/dept/it/department-library" exact>
            <ItLibrary />
          </Route>
          <Route path="/dept/eee/vision-mission-peos" exact>
            <EEEVision />
          </Route>
          <Route path="/dept/eee/po-and-pso" exact>
            <EEEPoAnsPSO />
          </Route>
          <Route path="/dept/eee/industry-internship" exact>
            <EEEIndustryInterm />
          </Route>
          <Route path="/dept/eee/industry-visits" exact>
            <EEEIndustryVisits />
          </Route>
          <Route path="/dept/eee/student-development-programs" exact>
            <EEESDP />
          </Route>
          <Route path="/dept/eee/syllabus" exact>
            <EEESyllabus />
          </Route>
          <Route path="/dept/eee/placements" exact>
            <EEEPlacements />
          </Route>
          <Route path="/dept/eee/professional-bodies" exact>
            <EEEProfessionalBodies />
          </Route>
          <Route path="/dept/eee/magazines" exact>
            <EEEMagazines />
          </Route>
          <Route path="/dept/eee/student-publications" exact>
            <EEEStudentAchievements />
          </Route>
          <Route path="/dept/eee/faculty-innovations" exact>
            <EEEFacultyInnovation />
          </Route>
          <Route path="/dept/eee/workshops" exact>
            <EEEworkshops />
          </Route>
          <Route path="/dept/eee/faculty-performance" exact>
            <EEEFacultyPerformance />
          </Route>
          <Route path="/dept/eee/faculty-performance" exact>
            <EEEFacultyPerformance />
          </Route>
          <Route path="/dept/eee/laboratory-projects" exact>
            <EEEEandD />
          </Route>
          <Route path="/dept/eee/academic-sponsered" exact>
            <EEEAcademicSponser />
          </Route>
          <Route path="/dept/eee/course-files" exact>
            <EEECourseFiles />
          </Route>
          <Route path="/dept/eee/department-library" exact>
            <EEELibrary />
          </Route>
          <Route path="/dept/eee/department-mous" exact>
            <EEEMous />
          </Route>
          <Route path="/dept/eee/academic-plan" exact>
            <EEEAcademicPlan />
          </Route>
          <Route path="/dept/mba/vision-mission-peos" exact>
            <MbaVision />
          </Route>
          <Route path="/dept/mba/po-and-pso" exact>
            <MbaPoandPso />
          </Route>
          <Route path="/dept/mba/hod-profile" exact>
            <MbaHod />
          </Route>
          <Route path="/dept/mba/mba-faculty-list" exact>
            <MbaFaculty />
          </Route>
          <Route path="/dept/mba/mentor-list" exact>
            <MbaMentorList />
          </Route>
          <Route path="/dept/mba/infrastructure" exact>
            <MbaInfrastructure />
          </Route>
          <Route path="/dept/mba/syllabus" exact>
            <MbaSyllabus />
          </Route>
          <Route path="/dept/mba/remedial-class" exact>
            <MbaRemedialClass />
          </Route>
          <Route path="/dept/mba/bridge-course" exact>
            <MbaBridgeCourse />
          </Route>
          <Route path="/dept/mba/workshop" exact>
            <MbaWorkShop />
          </Route>
          <Route path="/dept/mba/activities" exact>
            <MbaActivities />
          </Route>
          <Route path="/dept/mba/innovative-teaching" exact>
            <MbaInnovativeTeachig />
          </Route>
          <Route path="/dept/mba/placements" exact>
            <MbaPlacements />
          </Route>
          <Route path="/dept/s-and-h/vision-mission-peos" exact>
            <Svision />
          </Route>
          <Route path="/dept/s-and-h/hod-profile" exact>
            <SHod />
          </Route>
          <Route path="/dept/s-and-h/faculty-list" exact>
            <SandHFaculty />
          </Route>
          <Route path="/dept/s-and-h/activities" exact>
            <Hactivities />
          </Route>
          <Route path="/dept/dpe/hod-profile" exact>
            <DpeHod />
          </Route>
          <Route path="/dept/dpe/infrastructure" exact>
            <DpeInfra />
          </Route>
          <Route path="/dept/dpe/achievements" exact>
            <DpeAchievements />
          </Route>
          <Route path="/dept/dpe/sports" exact>
            <DpeSports />
          </Route>
          <Route path="/news-and-events/news" exact>
            <News />
          </Route>
          <Route path="/news-and-events/circulars" exact>
            <Circulars />
          </Route>
          <Route path="/news-and-events/swec-radio" exact>
            <SwecRadio />
          </Route>
          <Route path="/news-and-events/events" exact>
            <Events />
          </Route>
          <Route path="/news-and-events/events/womens-day-2023" exact>
            <WomensDayCelebrations2023 />
          </Route>
          <Route path="/news-and-events/events/republic-day-celebrations-2024" exact>
            <RepublicDayCelebrations2024 />
          </Route>
          <Route path="/news-and-events/events/ideathon-2023" exact>
            <IDEATHON2k23 />
          </Route>
          <Route path="/news-and-events/events/isro-industrial-2023" exact>
            <ISROIndustrialVisit2Year />
          </Route>
          <Route path="/news-and-events/events/project-expo-2022" exact>
            <ProjectExpo2022 />
          </Route>
          <Route path="/news-and-events/events/independence-day-celebration-2022" exact>
            <IndependentDay2022 />
          </Route>
          <Route path="/news-and-events/events/yoga-day-2022" exact>
            <InternationalYogaDay2022 />
          </Route>
          <Route path="/news-and-events/events/visit-isro-2022" exact>
            <VisitVisro2022 />
          </Route>
          <Route path="/news-and-events/events/alumni-21-22" exact>
            <Alumni2122 />
          </Route>
          <Route path="/news-and-events/events/workshop-on-android-and-mobile-development" exact>
            <WorkshoponAndroidandmobileAps />
          </Route>
          <Route path="/news-and-events/events/hackathon-certificate-distribution" exact>
            <HackathonCertificateDistribution />
          </Route>
          <Route path="/news-and-events/events/isro-industrial-visit" exact>
            <ISROIndustrialVisit2021 />
          </Route>
          <Route path="/news-and-events/events/hackathon-valedictory-photography" exact>
            <HackathonValedictoryPhotography />
          </Route>
          <Route path="/news-and-events/events/anti-drug-abuse-committee" exact>
            <AntidrugAbuseCommittee />
          </Route>
          <Route path="/news-and-events/events/womens-day-celebration-2022" exact>
            <WomensDay2022 />
          </Route>
          <Route path="/news-and-events/events/hair-donation-drive-2022" exact>
            <HairDonationDrive />
          </Route>
          <Route path="/news-and-events/events/obe-workshop-feb-2022" exact>
            <ObeWorkShop2022 />
          </Route>
          <Route path="/news-and-events/events/republic-day-2022" exact>
            <Republic2022 />
          </Route>
          <Route path="/news-and-events/events/induction-program-2021-22" exact>
            <InductionProgram2122 />
          </Route>
          <Route path="/news-and-events/events/bathukamma-celebrations-2021" exact>
            <Bathukamma2021 />
          </Route>
          <Route path="/news-and-events/events/yuva-samarthya" exact>
            <YuvaSamarthya2021 />
          </Route>
          <Route path="/news-and-events/events/aicte-sttp-on-blockchain" exact>
            <AICTESponsoredSTTP />
          </Route>
          <Route path="/news-and-events/events/womens-day-celebration-2021" exact>
            <WomensDay2021 />
          </Route>
          <Route path="/news-and-events/events/republic-day-2021" exact>
            <RepublicDayCelebrations2021 />
          </Route>
          <Route path="/news-and-events/events/mou-studyorbit" exact>
            <MouWithStudyOrbit />
          </Route>
          <Route path="/news-and-events/events/iso-certificate" exact>
            <IsoCertificate />
          </Route>
          <Route path="/news-and-events/events/alumni-meet-2020" exact>
            <AlumniMeet2020 />
          </Route>
          <Route path="/news-and-events/events/aicte-atal-fdp" exact>
            <AICTEATALFDP />
          </Route>
          <Route path="/news-and-events/events/first-year-orientation-day-2020" exact>
            <FirstYearOrientationDay2020 />
          </Route>
          <Route path="/news-and-events/events/mou-texas-review" exact>
            <MOUwithTexasReview />
          </Route>
          <Route path="/news-and-events/events/sttp-on-blockchain-technology" exact>
            <STTPonBlockchain />
          </Route>
          <Route path="/news-and-events/events/icdml" exact>
            <ICDML2020 />
          </Route>
          <Route path="/news-and-events/events/fdp-and-webinars" exact>
            <FDPandWEBINARS />
          </Route>
          <Route path="/news-and-events/events/mba-fdp-and-sdp" exact>
            <FDPandSDP />
          </Route>
          <Route path="/news-and-events/events/self-defence-skills" exact>
            <Selfdefenceskills />
          </Route>
          <Route path="/news-and-events/events/say-no-to-plastic" exact>
            <SayNoPlastic />
          </Route>
          <Route path="/news-and-events/events/mba-workshop" exact>
            <MBAworkshop />
          </Route>
          <Route path="/news-and-events/events/rowan-university-higher-education-seminar" exact>
            <HigherEducationseminar />
          </Route>
          <Route path="/news-and-events/events/award-and-felicitation-by-education-matters" exact>
            <EngineeringEducatorsAwards />
          </Route>
          <Route path="/news-and-events/events/she-teams" exact>
            <SheTeamsProgramme />
          </Route>
          <Route path="/news-and-events/events/bathukamma_celebrations2019" exact>
            <BathukammaCelebrations2019 />
          </Route>
          <Route path="/news-and-events/events/bathukamma_celebrations2019" exact>
            <BathukammaCelebrations2019 />
          </Route>
          <Route path="/news-and-events/events/kill-cancer-essay-writing-competition" exact>
            <KillCancerEssaywritingcompetition />
          </Route>
          <Route path="/news-and-events/events/mou-education-matters" exact>
            <MOU2020 />
          </Route>
          <Route path="/news-and-events/events/jntuh-principal-meet" exact>
            <JntuhPrincipalMeet />
          </Route>
          <Route path="/news-and-events/events/iso-certification-swec" exact>
            <IsoCertificate2015 />
          </Route>
          <Route path="/news-and-events/events/mba-orentiation-day" exact>
            <MBAOrientationDay2019 />
          </Route>
          <Route path="/news-and-events/events/events2019" exact>
            <Events2019 />
          </Route>
          <Route path="/news-and-events/events/pratiyogita2019" exact>
            <Pratiyogita2K19 />
          </Route>
          <Route path="/news-and-events/events/qubit-mgit-technical-fest" exact>
            <QubitMGITTechnicalfest />
          </Route>
          <Route path="/news-and-events/events/sri-samvidhan-2019" exact>
            <SriSamvidhan2019 />
          </Route>
          <Route path="/news-and-events/events/kill-cancer-awareness" exact>
            <KillCancerAwareness5KMarathon />
          </Route>
          <Route path="/news-and-events/events/flashmob-2019" exact>
            <Maithri2019FlashmobandSong />
          </Route>
          <Route path="/news-and-events/events/sports-awards-2018-19" exact>
            <SportsAwards201819 />
          </Route>
          <Route path="/news-and-events/events/vidh-youth-2018" exact>
            <VIDHYOUTH18 />
          </Route>
          <Route path="/news-and-events/events/sports-2018" exact>
            <Sports2018 />
          </Route>
          <Route path="/news-and-events/events/maithri-2018" exact>
            <Maithri2018 />
          </Route>
          <Route path="/news-and-events/events/workshop-and-seminar" exact>
            <WorkshopsandSeminars />
          </Route>
          <Route path="/news-and-events/gallery" exact>
            <SwecsGallery />
          </Route>
          <Route path="/news-and-events/gallery/mba-orentiation-day" exact>
            <MBAOrientationDay2019 />
          </Route>
          <Route path="/news-and-events/gallery/education-matters-counseling" exact>
            <EducationMatterscounseling />
          </Route>
          <Route path="/news-and-events/gallery/gallery-view" exact>
            <InfrastructureManagement />
          </Route>
          <Route path="/news-and-events/gallery/sports-2018" exact>
            <Sports2018 />
          </Route>
          <Route path="/news-and-events/gallery/maithri-2018" exact>
            <Maithri2018 />
          </Route>
          <Route path="/news-and-events/gallery/flashmob-2018" exact>
            <FlashMob2018 />
          </Route>
          <Route path="/news-and-events/gallery/workshop-and-seminar" exact>
            <WorkshopsandSeminars />
          </Route>
          <Route path="/news-and-events/gallery/farewell-2018" exact>
            <Farewell2018 />
          </Route>
          <Route path="/news-and-events/gallery/orientation-program-2016" exact>
            <OrientationProgram2016 />
          </Route>
          <Route path="/news-and-events/gallery/isb-debate" exact>
            <ISBdebate2016 />
          </Route>
          <Route path="/news-and-events/gallery/nss-program" exact>
            <NSSProgramme />
          </Route>
          <Route path="/news-and-events/gallery/jntuh-principal-meet-2019" exact>
            <JntuhPrincipalMeet />
          </Route>
          <Route path="/news-and-events/gallery/vidh-youth-2018" exact>
            <VIDHYOUTH18 />
          </Route>
          <Route path="/news-and-events/news/ideathon-media-coverage" exact>
            <IdeathonMediaCoverage />
          </Route>
          <Route path="/news-and-events/news/fest-srisamvidhan" exact>
            <FestSriSamvidhan />
          </Route>
          <Route path="/news-and-events/news/naac-grade" exact>
            <NAACGrade />
          </Route>
          <Route path="/news-and-events/news/walk-in" exact>
            <WalkinInteriview />
          </Route>
          <Route path="/news-and-events/news/womens-day-celebration-2021" exact>
            <WomensDay2021 />
          </Route>
          <Route path="/news-and-events/news/mou-texas-review" exact>
            <MOUwithTexasReview />
          </Route>
          <Route path="/news-and-events/news/news-coverage-v6" exact>
            <OnlineDataScienceMachine />
          </Route>
          <Route path="/news-and-events/news/iirs-certifcate" exact>
            <IIRSCertifcate />
          </Route>
          <Route path="/news-and-events/news/covid-19-circular" exact>
            <JNTUHCovid19Circular />
          </Route>
          <Route path="/news-and-events/news/she_team-anniversary" exact>
            <SHEteam5thanniversary />
          </Route>
          <Route path="/news-and-events/news/nss-news" exact>
            <NSSNews />
          </Route>
          <Route path="/news-and-events/news/freshers-day" exact>
            <FreshersDay />
          </Route>
          <Route path="/news-and-events/news/national-sports-day-celebratio" exact>
            <NationalSportsDaycelebration />
          </Route>
          <Route path="/news-and-events/news/vidh-youth-2.0" exact>
            <VidhYouth2 />
          </Route>
          <Route path="/news-and-events/news/nss-event" exact>
            <NSSEventSistersforaChange />
          </Route>
          <Route path="/news-and-events/news/graduation-day-2" exact>
            <GraduationDay2019 />
          </Route>
          <Route path="/news-and-events/news/first-year-orientation-day" exact>
            <FirstYearOrientationDay2019 />
          </Route>
          <Route path="/news-and-events/news/nba-certified-news" exact>
            <NBACertified />
          </Route>
          <Route path="/news-and-events/news/pratiyogita2019-news" exact>
            <Pratiyogita2K19News />
          </Route>
          <Route path="/news-and-events/news/qubit-mgit-technical-fest-news" exact>
            <QubitMGITTechnicalfest />
          </Route>
          <Route path="/news-and-events/news/sports-awards-2018-19" exact>
            <SportsAwards201819 />
          </Route>
          <Route path="/news-and-events/news/the-iq-princess-2019-news" exact>
            <TheIQPrincess2019 />
          </Route>
          <Route path="/news-and-events/news/maithri-the-cultural-fest-news" exact>
            <Maithri2K19 />
          </Route>
          <Route path="/news-and-events/news/maithri-2019-news" exact>
            <Maithri2019News />
          </Route>
          <Route path="/news-and-events/news/sri-samvidhan-2019-news" exact>
            <SriSamvidhan2019 />
          </Route>
          <Route path="/news-and-events/news/jbrec-technical-fest-news" exact>
            <JBRECTechnicalFest />
          </Route>
          <Route path="/news-and-events/news/engineering-educators-awards-news" exact>
            <EngineeringEducatorsAwardsNews />
          </Route>
          <Route path="/news-and-events/news/vidh-youth-news" exact>
            <VIDHYOUTH18news />
          </Route>
          <Route path="/news-and-events/news/college-day-news" exact>
            <CollegeDayNews />
          </Route>
          <Route path="/news-and-events/news/national-youth-festival-2024" exact>
            <NationalYouthFest />
          </Route>
          <Route path="/news-and-events/news/nausainik-camp" exact>
            <NausainikCamp />
          </Route>
          <Route path="/news-and-events/news/nausainik-camp" exact>
            <NausainikCamp />
          </Route>
          <Route path="/news-and-events/news/sports-news" exact>
            <SportsNews />
          </Route>
          <Route path="/news-and-events/news/graduation-day-2023" exact>
            <Graduationday2023 />
          </Route>
          <Route path="/news-and-events/news/it-project-expo-prakalp" exact>
            <Prakalp />
          </Route>
          <Route path="/news-and-events/news/ece-project-expo-2023" exact>
            <ECEProject2023 />
          </Route>
          
          <Route path="/dept/cse/cse-faculty-list" exact>
            <CSEFaculty />
          </Route>
          <Route path="/dept/it/it-faculty-list" exact>
            <ItFaculty />
          </Route>
          <Route path="/dept/it/eee-faculty-list" exact>
            <EEEFaculty />
          </Route>
          <Route path="/training-and-placements/2021-22" exact>
            <P2122 />
          </Route>
          <Route path="/training-and-placements/2022-23" exact>
            <P2223 />
          </Route>
          <Route path="/training-and-placements/skill-enhancement" exact>
            <SkillEnhancement />
          </Route>
          <Route path="/intellectual-property-rights" exact>
            <IntellectualPropertyRights />
          </Route>
          <Route path="/terms-conditions" exact>
            <TermsConditions />
          </Route>
          <Route path="/extension-activities" exact>
            <ExtensionActivities />
          </Route>
          <Route path="/website-policies" exact>
            <WebsitePolicies />
          </Route>
          <Route path="/copyright-policy" exact>
            <CopyrightPolicy />
          </Route>
          <Route path="/reach-us" exact>
            <ReachUs />
          </Route>
          <Route path="/locations" exact>
            <Location />
          </Route>
          <Route path="/academics" exact>
            <Academics />
          </Route>
          {/* <Route path="/videos" exact>
          <EventsVideos />
        </Route> */}
        </Switch>
      </section>
    </React.Fragment>




  );
}

export default App;
