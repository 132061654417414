import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import MbaLeftTab from './MbaLeftTab';


const MbaVision = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Master of Business Administration (MBA)
                                </h2>
                                <div className='pt-3'>
                                    <div class="submit">
                                        <h5><i class="fa fa-eye text-red"></i> Vision</h5>
                                        <div class="pt-2">
                                            <p>
                                                Empowerment of women with entrepreneurial skills to become future business leaders and contribute to its growth at International Standards.

                                            </p>

                                        </div>
                                    </div>

                                    <div class="">
                                        <h5><i class="fa fa-bullseye  text-red"></i> Mission</h5>
                                        <div class="pt-2">
                                            <div class="apply-list ">

                                                <ul class="pt-2">
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        To provide all round development of Young Managers by imparting professional skills universally.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        To dispense exposure to the real business world through interaction with Industrial experts.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        To emphasize on Corporate Social Responsibility.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-4'>
                                        <h5>Programme Educational Objectives (Peo's)</h5>
                                        <div class="apply-list ">
                                            <ul class="pt-2">
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-I : </strong>
                                                    To give an exposure on the business environment and organisations in all respects to become good business managers and leaders.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-II :  </strong>
                                                    To Enhance analytical and problem solving skills to facilitate decision making.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-III : </strong>
                                                    To develop entrepreneurial skills and encourage self-employment.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-IV : </strong>
                                                    To improve interpersonal skills at work and prepare students for multicultural environment in the modern globalised scenario.
                                                </li>
                                                <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PEO-V : </strong>
                                                    To improve interpersonal skills at work and prepare students for multicultural environment in the modern globalised scenario.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MbaVision
