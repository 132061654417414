import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';


const EEEMous = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department MOU'S
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="top">
                                                    <p>
                                                        <strong>S.No</strong>
                                                    </p>
                                                </td>
                                                <td align="center" valign="top"><p><strong>Name of the Company</strong></p></td>
                                                <td align="center" valign="top"><p><strong>Start Date </strong></p></td>
                                                <td align="center" valign="top"><p><strong>Duration</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    Skillshark Edutech Pvt Ltd
                                                    Hyderabad
                                                </td>
                                                <td>13/07/2020 </td>
                                                <td>3 Years </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>
                                                    Eifer Megacorp Private
                                                    Limited
                                                </td>
                                                <td>08/07/2019 </td>
                                                <td>3 Years </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>
                                                    Electromation Technologies,
                                                    Hyderabad.
                                                </td>
                                                <td>16/04/2019 </td>
                                                <td>3 Years </td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Techniq Design Group </td>
                                                <td>
                                                    05/03/2018
                                                </td>
                                                <td>5 Years </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>
                                                    inEntirety Innovations Pvt
                                                    Ltd, Hyderabad
                                                </td>
                                                <td>
                                                    14/03/2017
                                                </td>
                                                <td>5 Years </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>
                                                    SKS Institute of Industrial
                                                    Automation, Hyderabad
                                                </td>
                                                <td>
                                                    15/3/2016
                                                </td>
                                                <td>5 Years </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEEMous
