import React,{useEffect} from 'react'
import { Link } from "react-router-dom";

const DpeLeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul className='text-start'>
                    <Link to="/dept/dpe" onclick="scrollWin()"><li className='text-black active'><i class="fa-sharp fa-solid fa-building"></i> <span>About the Department</span> </li></Link>
                    <Link to="/dept/dpe/hod-profile" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user"></i> <span> HOD Profile</span> </li></Link>
                    <Link to="/dept/dpe/infrastructure" onclick="scrollWin()"><li className='text-black'><i class="fa fa-industry fa-lg"></i> <span> Infrastructure </span> </li></Link>
                    <Link to="/dept/dpe/achievements" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span>  Achievements </span> </li></Link>
                    <Link to="/dept/dpe/sports" onclick="scrollWin()"><li className='text-black'><i class="fa fa-trophy fa-lg"></i> <span>  Sports </span> </li></Link>
                </ul>
            </div>
        </div>
    )
}


export default DpeLeftTab
