import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';

const EceAcademicSponsered = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>EceAcademicSponsered
                                </h2>
                                <div className='pt-3'>
                                    <h6 className='pb-2'>Sponsored Research - Dst Projects
                                    </h6>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td>
                                                    <p><strong>S.no</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>Title of the Project</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>Agency , File No.</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>Principal Investigator (s)(PI) &amp;(CO-PI)</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>Grant in Lakhs</strong></p>
                                                </td>
                                                <td>
                                                    <p><strong>Duration</strong></p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td valign="center">
                                                    <p align="center" ><span >1.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Autonomous quad copter for painting high rise
                                                            buildings
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >DST,TPN/57967</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >Dr.A.Narmada</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >27.76</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            3years(Applied)
                                                            2020-2021
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="center">
                                                    <p align="center" ><span >2.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Smart Garbage collection management system
                                                            using IOT
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >DST, TPN/59117</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >Dr.A.Narmada</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >14.22</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            3years(Applied)
                                                            2020-2021
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td valign="center">
                                                    <p align="center" ><span >3.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Development
                                                            of an inexpensive shopping assistance system for visually impaired with
                                                            indoor navigation and product recognition
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >DST, SEED/TIDE/2019/302</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >Dr.A.Narmada</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >49.22</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            3
                                                            years(Applied)
                                                        </span><span >2019-2020<span ></span></span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td valign="center">
                                                    <p align="center" ><span >4.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Data
                                                            analytics for the study of Thalassemia
                                                        </span>
                                                    </p>
                                                    <p align="center" ><span >&nbsp;</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >DST, SEED/TIDE/2019/112</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >Dr.B.L.Malleswari</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >33.10</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            3
                                                            years(Applied)
                                                        </span><span >2019-2020<span ></span></span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="center">
                                                    <p align="center" ><span >5.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Location
                                                            based camera using Nano scale technology for the security of disabled women
                                                            and senior citizen
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >DST, /TIDE/2019/330</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >Dr.K.Mohanram</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >39.60</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            3
                                                            years(Applied)
                                                        </span><span >2019-2020<span ></span></span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="center">
                                                    <p align="center" ><span >6.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Technology based Entrepreneurship Development
                                                            Program(TEDPs: 02)
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >SEED DST, TPN/54849</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Dr.A.Narmada
                                                            &amp; Mr.K.Tulasiram
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >3.20</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            1 year
                                                            (Applied) 2019-2020
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="center">
                                                    <p align="center" ><span >7.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Women
                                                            Entrepreneurship Development Program(WEDPs: 02)
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >DST, TPN/54849</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Dr.A.Narmada
                                                            &amp; Mr.K.Tulasiram
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >2.00</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            1 year
                                                            (Applied) 2019-2020
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="center">
                                                    <p align="center" ><span >8.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Faculty
                                                            Development Program(FDPs:04)
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >DST, TPN/54849</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Dr.A.Narmada
                                                            &amp;
                                                        </span>
                                                    </p>
                                                    <p align="center" ><span >Mr.K.Tulasiram</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >2.00</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            1 week
                                                            (Applied) 2019-2020
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="center">
                                                    <p align="center" ><span >9.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Real time implementation of advanced control algorithms on a
                                                            laboratory scale plant
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >DST, JNTUH/TEQIP-III/CRS/2019/ECE/08</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >Mr.K.Tulasiram</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >2.85</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            1
                                                            year(Applied) 2019-2020
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="center">
                                                    <p align="center" ><span >10.</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            Application of Geospatial graph <span zeum4c29="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="grammar" class="Lm ng">data base</span> technologies to power
                                                            distribution
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >DST,</span></p>
                                                    <p align="center" ><span >NRDMS/01/89/2015-A</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >Dr B.L Malleswari</span></p>
                                                    <p align="center" ><span >&nbsp;</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" ><span >8.80</span></p>
                                                </td>
                                                <td valign="center" >
                                                    <p align="center" >
                                                        <span >
                                                            3 years
                                                            (ongoing)&nbsp; 2015-2018
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='pt-3'>
                                    <h6 className='pb-2'>Sponsored Research - Aicte Projects
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td>
                                                        <p><strong>S.no</strong></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Title of the Project</strong></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Agency , File No.</strong></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Principal Investigator (s)(PI) &amp;(CO-PI)</strong></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Grant in Lakhs</strong></p>
                                                    </td>
                                                    <td>
                                                        <p><strong>Duration</strong></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>1.</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                SPDC-Skill and Personality Development Programmed Centre
                                                                for SC/ST Students
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                AICTE-
                                                                1-6334491464
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>Dr.K.Mohanram,</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>4.61</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>3years
                                                            (Sanctioned)</span></p>
                                                        <p>
                                                            <span>2020-2021</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>2.</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                International conference on Data Science and Machine
                                                                Learning<br />
                                                                    (ICDML)
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>AICTE-1-7123954904</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>Dr.B.L.Malleswari</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>0.50</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>2 days
                                                            (Sanctioned)</span></p>
                                                        <p>
                                                            <span>2020-2021</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>3.</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>MODROBS</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>AICTE-
                                                            1-9331307245</span></p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>Dr.A.Narmada &amp;</span>
                                                        </p>
                                                        <p>
                                                            <span>Mr.K.Tulasiram</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>18.29</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>2&nbsp; years (Applied )</span>
                                                        </p>
                                                        <p>
                                                            <span>2019-2020</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>4.</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>ATAL-FDP</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>AICTE-<span >161366774</span></span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>Dr.B.L.Malleswari</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>2.00</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>1 week
                                                            (Applied)</span></p>
                                                        <p>
                                                            <span>2019-2020</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>5.</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>ISTE-AICTE Refresher Programme</span>
                                                        </p>
                                                        <p>
                                                            <span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>AICTE
                                                            -1-7123954904</span></p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>Dr.B.L.Malleswari</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>0.50</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>1 week
                                                            (Applied)</span></p>
                                                        <p>
                                                            <span>2019-2020</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>6.</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                STTP –
                                                                Short Term Training Program
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>AICTE-1-7119332871</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>Dr. P.
                                                            Suneel Kumar</span></p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>2.56</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>3
                                                            weeks (Applied)</span></p>
                                                        <p>
                                                            <span>2019-2020</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>7.</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                MODROB
                                                                - Aspiring Institutions
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>AICTE-1-7095017821</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                Dr.
                                                                B.L.Malleswari
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>12.55</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>1
                                                            year&nbsp; (Applied)</span></p>
                                                        <p>
                                                            <span>2019-2020</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>8.</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                MODROB
                                                                - Aspiring Institutions
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>AICTE-1-6334491464</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                Dr.
                                                                K.Mohanram
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>2.64</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>1 year
                                                            (Applied)</span></p>
                                                        <p>
                                                            <span>2019-2020</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>9.</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>FDP-Program</span>
                                                        </p>
                                                        <p >
                                                            <span>&nbsp;</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                AICTE-
                                                                1-7069217901
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p ><span>Dr. B.L. Malleswari</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>2.25</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p><span>1 week
                                                            (Applied)</span></p>
                                                        <p>
                                                            <span>2019-2020</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default EceAcademicSponsered
