import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import JntuhLefttab from './JntuhLefttab';

const JntuSyllubus = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-5'>
                            <JntuhLefttab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Academic Regulations and Syllabus
                                </h2>
                            </div>
                            <div className='pt-3'>
                                <h5>Moocs courses list
                                </h5>
                                <div class="detgails-right-content mt-">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/SwayamCourseslistforA.Y.2021_22forB.Tech.IIIIV_IISem.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>   Swayam Moocs 2021-22 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/SWAYAMMOOCsJuly_December2020.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>    Swayam Moocs December 2020</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className='pt-3'>
                                <h5>Academic Regulations and Syllabus
                                </h5>
                                <div class="table-responsive pt-2">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="120"><strong>R13</strong></th>
                                                <th>
                                                    <div class="pb-0 mb-0">
                                                        <div class="related-download p-0">
                                                            <ul>
                                                                <li>
                                                                    <a href="/assets/documents/New/R13 B.Tech Academic Regulations.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  Academic Regulations R13 </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th width="120"><strong>R15</strong></th>
                                                <th>
                                                    <div class="pb-0 mb-0">
                                                        <div class="related-download p-0">
                                                            <ul>
                                                                <li>
                                                                    <a href="/assets/documents/New/R15 B.Tech Academic Regulations.pdf" target='_blank' ><i class="fa-solid fa-file-pdf"></i>   Academic Regulations R15 </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th width="120"><strong>R16</strong></th>
                                                <th>
                                                    <div class="pb-0 mb-0">
                                                        <div class="related-download p-0">
                                                            <ul>
                                                                <li>
                                                                    <a href="/assets/documents/New/R16 B.Tech.Academic Regulations.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>   Academic Regulations R16 </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th width="120"><strong>R18</strong></th>
                                                <th>
                                                    <div class="pb-0 mb-0">
                                                        <div class="related-download p-0">
                                                            <ul>
                                                                <li>
                                                                    <a href="/assets/documents/New/R18 B.Tech Academic Regulations.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>   Academic Regulations R18 </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                {/* <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/"><i class="fa-solid fa-file-pdf"></i>   Swayam Moocs 2021-22 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/"><i class="fa-solid fa-file-pdf"></i>    Swayam Moocs December 2020</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}

                            </div>
                            <div className='pt-4'>
                                <h5>M.Tech Syllabus and Regulations
                                </h5>
                                <div class="table-responsive pt-2">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th width="120"><strong>R13</strong></th>
                                                <th>
                                                    <div class="pb-0 mb-0">
                                                        <div class="related-download p-0">
                                                            <ul>
                                                                <li>
                                                                    <a href="/assets/documents/New/R13 M.tech syllabus.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> M.Tech Syllabus</a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th width="120"><strong>R15</strong></th>
                                                <th>
                                                    <div class="pb-0 mb-0">
                                                        <div class="related-download p-0">
                                                            <ul>
                                                                <li>
                                                                    <a href="/assets/documents/New/R15 Mtech Syllabus.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> M.Tech Syllabus</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                            <div className='pt-4'>
                                <h5>Syllabus
                                </h5>
                                <div>
                                    <h6 className='pt-3 text-red'>CSE</h6>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R16</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/cse/I-I & I-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> I-I & I-II </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/cse/II-I & II-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  II-I & II-II  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/cse/III-I & III-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>III-I & III-II  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/cse/IV-I & IV-II.pdf" target='_blank' ><i class="fa-solid fa-file-pdf"></i> IV-I & IV-II </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                    {/* <li>
                                                                        <a href="/assets/" target='_blank'><i class="fa-solid fa-file-pdf"></i>  II-I & II-II  </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="/assets/" target='_blank'><i class="fa-solid fa-file-pdf"></i>III-I & III-II  </a>
                                                                    </li>
                                                                    <li className='mb-2'>
                                                                        <a href="/assets/" target='_blank' ><i class="fa-solid fa-file-pdf"></i> IV-I & IV-II </a>
                                                                    </li> */}

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R18</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/cse/R18 B.TECH CSE II-I & II-II Syllabus.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  II-I & II-II Syllabus </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/cse/R18 B.Tech I Year Syllabus of EEE,CSE,IT.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> I Year Syllabus of EEE,CSE,IT  </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6 className='pt-3 text-red'>ECE
                                    </h6>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R16</strong></th>
                                                    <th>
                                                        CSE
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/ece/I-I & I-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> I-I & I-II </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/ece/II-I & II-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  II-I & II-II  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/ece/III-I & III-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>III-I & III-II  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/ece/IV-I & IV-II.pdf" target='_blank' ><i class="fa-solid fa-file-pdf"></i> IV-I & IV-II </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R18</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/ece/R18 B.TECH ECE II-I & II-II Syllabus.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  II-I & II-II Syllabus </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/ece/R18 B.Tech I Year Syllabus of ECE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> I Year Syllabus of ECE </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6 className='pt-3 text-red'>EEE
                                    </h6>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R16</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/eee/I-I & I-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> I-I & I-II </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/eee/II-I & II-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  II-I & II-II  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>

                                                                            <li>
                                                                                <a href="/assets/documents/New/eee/III-I & III-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>III-I & III-II  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/eee/IV-I & IV-II.pdf" target='_blank' ><i class="fa-solid fa-file-pdf"></i> IV-I & IV-II </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R18</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/eee/R18 B.TECH EEE II-I &II-II Syllabus.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  II-I & II-II Syllabus </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/eee/R18 B.Tech I Year Syllabus of EEE,CSE,IT.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> I Year Syllabus of EEE,CSE,IT </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6 className='pt-3 text-red'>IT
                                    </h6>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R16</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className='d-flex'>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/it/I-I & I-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> I-I & I-II </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/it/II-I & II-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  II-I & II-II  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/it/III-I & III-II.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>III-I & III-II  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/it/IV-I & IV-II.pdf" target='_blank' ><i class="fa-solid fa-file-pdf"></i> IV-I & IV-II </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R18</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/it/R18 B.TECH IT II-I & II-II Syllabus.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  II-I & II-II Syllabus </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/it/R18 B.Tech I Year Syllabus of EEE,CSE,IT.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  I Year Syllabus of EEE,CSE,IT </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6 className='pt-3 text-red'>MBA
                                    </h6>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R19</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className='d-flex'>
                                                                    <li>
                                                                        <a href="/assets/documents/New/mba/R19MBASyllabus.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> MBA </a>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R15</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className='d-flex'>
                                                                    <li>
                                                                        <a href="/assets/documents/New/mba/R15 MBA.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  MBA </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R17</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className='d-flex'>
                                                                    <li>
                                                                        <a href="/assets/documents/New/mba/R17 MBA.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  MBA </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <div class="table-responsive pt-2">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R09</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/R09 CSE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> CSE </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/R09 ECE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  ECE  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/R09 EEE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>EEE  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/R09 IT.pdf" target='_blank' ><i class="fa-solid fa-file-pdf"></i> IT </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R13</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/R13 CSE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> CSE </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/R13 ECE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  ECE  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/R13 EEE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>EEE  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/R13 IT.pdf" target='_blank' ><i class="fa-solid fa-file-pdf"></i> IT </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th width="120"><strong>R15</strong></th>
                                                    <th>
                                                        <div class="pb-0 mb-0">
                                                            <div class="related-download p-0">
                                                                <ul className=''>
                                                                    <div className='row'>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/R15 CSE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> CSE </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/R15 ECE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  ECE  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li>
                                                                                <a href="/assets/documents/New/R15 EEE.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>EEE  </a>
                                                                            </li>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-3 mb-2'>
                                                                            <li className='mb-2'>
                                                                                <a href="/assets/documents/New/R15 IT.pdf" target='_blank' ><i class="fa-solid fa-file-pdf"></i> IT </a>
                                                                            </li>
                                                                        </div>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default JntuSyllubus
