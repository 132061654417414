
import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';

const ItFacultyAchievements = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Faculty Achievements
                                </h2>
                                <div className='py-2'>
                                    <h6 className='py-2'>List of Faculty Publications
                                    </h6>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead class="bg-gray">
                                                <tr>
                                                    <th><strong>S.No</strong></th>
                                                    <th><strong>Name of the faculty</strong></th>
                                                    <th><strong>Title of paper </strong></th>
                                                    <th><strong>Date </strong></th>
                                                    <th><strong>Organization </strong></th>
                                                    <th><strong>DOI / ISSN/ URL  </strong></th>
                                                    <th><strong>Journal  </strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td width="38" rowspan="18" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >1<b></b></span></p>
                                                    </td>
                                                    <td width="116" rowspan="18" >
                                                        <p class="MsoNormal" ><b><span lang="EN-US">Dr. Avinash</span></b><b><span lang="EN-US" ></span></b></p>
                                                    </td>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Experimental Analysis in Cloud Computing for Wireless Sensor
                                                                Networks
                                                            </span><span lang="EN-US" ></span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                2
                                                                Dec 2019
                                                            </span><span lang="EN-US" ></span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJEAT</span><span lang="EN-US" ></span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2249-8958</span><span lang="EN-US"></span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">Scopus</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Enhanced Home Security with e-mail and sms alerting system</span></p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                1
                                                                Jan 2019
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US"><span zeum4c8="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">IJRDST</span></span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2581-4575</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                UGC-Care
                                                                List
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Productivity intrusion system using genetic algorithm</span></p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                4
                                                                April 2019
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US"><span zeum4c8="PR_2_0" data-ddnwab="PR_2_0" aria-invalid="spelling" class="LI ng">IJRDST</span></span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2581-4575</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Protected and flexible multi keyword score search model over
                                                                encoded cloud data
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                8
                                                                Aug 2019
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US"><span zeum4c8="PR_3_0" data-ddnwab="PR_3_0" aria-invalid="spelling" class="LI ng">IJRDST</span></span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2581-4575</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Analysis of various protocols in wireless body area networks(WBAN)</span></p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                2
                                                                Feb 2018
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJARST</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2457-0362
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">Scopus</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Advanced driver assistance system shared steering between human
                                                                and automation
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                Feb
                                                                2018
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJR</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">ISSN:2236-6124</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">Scopus</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Mining specific content search using clustering algorithm</span></p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                June
                                                                2018
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJARST</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2457-0362
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                A Valuable
                                                                Role of Digital Payments in Building Smart Cities Using IoT Technology
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">05-09-2018</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US"><span zeum4c8="PR_4_0" data-ddnwab="PR_4_0" aria-invalid="spelling" class="LI ng">JARD&amp;CS</span></span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">05-09-2018</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;A Survey Report on Object Recognizing and
                                                                Tracking For <span zeum4c8="PR_5_0" data-ddnwab="PR_5_0" aria-invalid="grammar" class="Lm ng">Ice Bergs</span> in Polar Areas Using Joint Registration and Active
                                                                Contour Segmentation
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">July 2018</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJARST</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2457-0362
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;Design and Optimization of Microstrip Patch
                                                                Antenna For Satellite Applications
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">June 2018</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJR</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2236-6124
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Advanced
                                                                Driver Assistance System- Shared Steering Between Human and Automation
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">February 2018</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJR</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2236-6124
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;Healthcare Management System And Domain
                                                                Search of Nearest Medical Services
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">December 2017</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJARST</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2457-0362
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;Visual Statistics Based Image Reconstruction
                                                                By Using Different Distance Metric On Color Image
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">September 2017,</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJR</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2236-6124
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Reliable data
                                                                transmission with random redundancy in structural health monitoring
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">7 Aug 2017</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJIEMR</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2456-5083
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Improved cloud
                                                                storage using two factor for data security protection mechanism
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">8 Aug 2017</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJARST</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2457-0362
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;Towards The Implementation of IoT For
                                                                Environmental Condition Monitoring Homes
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">June 2017</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJARST</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2457-0362
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                A Review on
                                                                caching mechanisms for data availability and performance in MANETS
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">10 June 2017</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJAASE</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2320-6136
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Healthcare
                                                                Management System and domain search of nearest medical services
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">6 Dec 2017</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJARST</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">ISSN:2457-0362</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">UGC-Care List</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="38" rowspan="5" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >2</span></p>
                                                    </td>
                                                    <td width="116" rowspan="5" >
                                                        <p class="MsoNormal" ><b><span lang="EN-US">&nbsp;</span></b></p>
                                                        <p class="MsoNormal" ><b><span lang="EN-US">&nbsp;</span></b></p>
                                                        <p class="MsoNormal" ><b><span lang="EN-US">&nbsp;</span></b></p>
                                                        <p class="MsoNormal" ><b><span lang="EN-US">Ms M. Kavitha</span></b></p>
                                                    </td>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Semi supervised machine learning approach
                                                                for ddos detection
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" ><span lang="EN-US">2021</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJISRT</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN: 2456-2165</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">UGC</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                A <span zeum4c8="PR_6_0" data-ddnwab="PR_6_0" aria-invalid="grammar" class="Lm ng">survey concepts</span> of machine learning and
                                                                its applications.
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Oct
                                                                2020
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJFGCN</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN: 2233-7857</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                Web
                                                                of Science
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Experimental analysis in cloud computing
                                                                for wireless sensor networks
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" ><span lang="EN-US">2 dec 2019</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJEAT</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">2249-8958</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">Scopus</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;Improving of automatic wrappers by data
                                                                extraction system
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                June
                                                                2014
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJRRECS</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">2321-5461</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">others</span></p>
                                                    </td>
                                                </tr>
                                                <tr >
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;Analysis of web information gathering based
                                                                on sketch image <span zeum4c8="PR_7_0" data-ddnwab="PR_7_0" aria-invalid="spelling" class="LI ng">retrival</span> system
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" ><span lang="EN-US">2012</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US"><span zeum4c8="PR_8_0" data-ddnwab="PR_8_0" aria-invalid="spelling" class="LI ng">IJETT&amp;CS</span></span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">2278-6856</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">others</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="38" rowspan="6" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >3</span></p>
                                                    </td>
                                                    <td width="116" rowspan="6" >
                                                        <p class="MsoNormal" ><b><span lang="DE">Ms. Shamal Telkar</span></b></p>
                                                    </td>
                                                    <td width="215" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Women’s Safety using IoT</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2021</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJISRT</span></p>
                                                    </td>
                                                    <td width="103" valign="top" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2456-2165
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">UGC</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Data Driven design for fog computing aided process monitoring
                                                                system for large scale industrial processes.
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2021</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJISRT</span></p>
                                                    </td>
                                                    <td width="103" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN: 2456-2165</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">UGC</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Real Time Stream Data Analysis framework for forecasting the
                                                                marine congestion and crash <span zeum4c8="PR_9_0" data-ddnwab="PR_9_0" aria-invalid="spelling" class="LI ng">preventi</span> of ships.
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2020</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJFGCN</span></p>
                                                    </td>
                                                    <td width="103" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2233-7857
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                Web
                                                                of Science
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Novel Decentralized Systems for Data Publishing by Trusty URI
                                                                Links
                                                            </span>
                                                        </p>
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2016</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJCS</span></p>
                                                    </td>
                                                    <td width="103" valign="top" >
                                                        <p class="gmail-TableParagraph" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="gmail-TableParagraph" ><span lang="EN-US">ISSN:</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2348-6600</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="gmail-TableParagraph" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">others</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;A Two Level Knowledge Discovery Using
                                                                Content and Query Values<span ></span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">Sept </span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2016</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJSETR</span></p>
                                                    </td>
                                                    <td width="103" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                ISSN:
                                                                2319-8885
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">others</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;A Real Time Intelligent Alarm system On
                                                                Driver <span zeum4c8="PR_10_0" data-ddnwab="PR_10_0" aria-invalid="spelling" class="LI ng">Fatique</span> Based On Video Sequence<span ></span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center" >
                                                            <span lang="EN-US">
                                                                10<sup><span>th</span></sup><span>&nbsp;</span>Aug
                                                                2014
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">ICCVR</span></p>
                                                    </td>
                                                    <td width="103" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US"><span zeum4c8="PR_11_0" data-ddnwab="PR_11_0" aria-invalid="spelling" class="LI ng">ICCVR-2014</span></span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">Springer</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="38" rowspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >4</span></p>
                                                    </td>
                                                    <td width="116" rowspan="2" >
                                                        <p class="gmail-CM1" ><b><span lang="EN-US" >Ms.C.Swapna</span></b></p>
                                                    </td>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Sentiment Analysis on hashtags using
                                                                machine learning
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center"><span lang="EN-US">Dec-2020</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJISRT</span></p>
                                                    </td>
                                                    <td width="103" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN: 2456-2165</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">UGC</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                A Smart Web Crawler: An Efficient
                                                                Harvesting Deep-web Interfaces Using Site Ranker And Adoptive Learning
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" align="center"><span lang="EN-US">September 2016</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IJSETR</span></p>
                                                    </td>
                                                    <td width="103" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="gmail-TableParagraph" ><span lang="EN-US">ISSN:</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">2319-8885</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">others</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="38" rowspan="3" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >5</span></p>
                                                    </td>
                                                    <td width="116" rowspan="3" >
                                                        <p class="MsoNormal" >
                                                            <b>
                                                                <span lang="EN-US">
                                                                    Ms
                                                                    <span zeum4c8="PR_12_0" data-ddnwab="PR_12_0" aria-invalid="spelling" class="LI ng">Samatha</span> Konda
                                                                </span>
                                                            </b>
                                                        </p>
                                                    </td>
                                                    <td width="215" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Classifying Emotions of Users Towards
                                                                Software Products Using Sentiment Analysis<span ></span>
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">November 2017</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJR</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN: 2348-795X</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">others</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Contemplate of Feature Reduction using
                                                                Non parametric Statistics Using Data Mining Algorithms
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">November 2017</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJSART</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN: 2395- 1052(Online)</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                others
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;An
                                                                Experimental Study On Evaluation Market Performance And Estimation Of Total
                                                                Overhead Mechanism In Cloud Computing Environment
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">July’13-Dec’13</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJ-ETA-ETS</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN: 0974-3588</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                others
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="38" rowspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >6</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >&nbsp;</span></p>
                                                    </td>
                                                    <td width="116" rowspan="2" >
                                                        <p class="MsoNormal" ><b><span lang="EN-US" >R Sreedhar</span></b></p>
                                                    </td>
                                                    <td width="215" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Multi-Modeling Cloud Computing in
                                                                Information Retrieval
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Sep-2014</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJATIR</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN:2348-2370</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                others
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Your Safety&nbsp; Our Concern</span></p>
                                                    </td>
                                                    <td width="81" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Jan-2018</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJETSR</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN:2394-3386</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                others
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="38" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >7</span></p>
                                                    </td>
                                                    <td width="116" >
                                                        <p class="MsoNormal" ><b><span lang="EN-US" >Ms N Namratha</span></b></p>
                                                    </td>
                                                    <td width="215" valign="top" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Billboard Perception and text recognition
                                                                using python tesseract
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Dec-2020</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">IJISRT</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">ISSN: 2456-2165</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">UGC</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="38" rowspan="2" >
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >8</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US" >&nbsp;</span></p>
                                                    </td>
                                                    <td width="116" rowspan="2" >
                                                        <p class="MsoNormal" ><b><span lang="EN-US">Ms Ammannamma</span></b></p>
                                                    </td>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Security enhancement of digital
                                                                signatures for blockchain using EdDSA Algorithm
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" ><span lang="EN-US">2021</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US"><span zeum4c8="PR_13_0" data-ddnwab="PR_13_0" aria-invalid="spelling" class="LI ng">ICICV</span></span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">978-0-7381-1182-7</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">IEEE-xplore</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="215" >
                                                        <p class="MsoNormal" >
                                                            <span lang="EN-US">
                                                                Analysis of tumor detection technique by
                                                                using data mining approach in oncology field
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td width="81" >
                                                        <p class="MsoNormal" ><span lang="EN-US">Nov</span></p>
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;2019</span></p>
                                                    </td>
                                                    <td width="71" >
                                                        <p class="MsoNormal" ><span lang="EN-US">JARDCS</span></p>
                                                    </td>
                                                    <td width="103" >
                                                        <p class="MsoNormal" ><span lang="EN-US">1943-023x</span></p>
                                                    </td>
                                                    <td width="113" valign="top" >
                                                        <p class="MsoNormal" ><span lang="EN-US">&nbsp;</span></p>
                                                        <p class="MsoNormal" align="center" ><span lang="EN-US">Scopus</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ItFacultyAchievements
