
import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import JntuhLefttab from './JntuhLefttab';

const JntuTimeTable = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-5'>
                            <JntuhLefttab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Academic Calendar for B-Tech
                                </h2>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Time Tables for 2020-21</h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech_I_YEAR _I Sem R18_I_Mid ExamTimeTable jan-2021 add new branch csbs.pdf"target="_blank"><i class="fa-solid fa-download"></i> B.Tech_I_YEAR _I Sem R18_I_Mid ExamTimeTable jan-2021 add new branch csbs</a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech_2-1 _R18 End semister exam Time table.pdf"target="_blank"><i class="fa-solid fa-download"></i>B.Tech_2-1 _R18 End semester exam Time table </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 3-1 _R18 End semister exam Time table.pdf"target="_blank"><i class="fa-solid fa-download"></i>B.Tech 3-1 _R18 End semester exam Time table </a>
                                            </li>
                                            <li>

                                                <a href="/assets/documents/exam/B.Tech_3-1_Substitute End semister exam Time table.pdf"target="_blank"><i class="fa-solid fa-download"></i> B.Tech_3-1_Substitute End semester exam Time table </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech  IV_I R16 End semister exam Time table.pdf"target="_blank"><i class="fa-solid fa-download"></i>B.Tech IV_I R16 End semester exam Time table </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/External lab exams & mid exams of  B.Tech B.Pharm and mini project feb2021.pdf"target="_blank"><i class="fa-solid fa-download"></i> External lab exams & mid exams of B.Tech B.Pharm and mini project feb2021 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/REVISED TIME TABLE B.Tech_3-1 R18 SUBSTITUTE March-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i>REVISED TIME TABLE B.Tech_3-1 R18 SUBSTITUTE March-2021 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Timetables for B.Tech I year I sem II midterm exams march-2021
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech_I_Year _I Sem R18_II_Mid ExamTimeTable march-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i>  B.Tech_I_Year _I Sem R18_II_Mid Exam Time Table March-2021</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Timetables for B.Tech I year I sem II midterm exams may-2021

                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech_I_Year _I Sem R18_II_Mid ExamTimeTablemay-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i>B.Tech_I_Year _I Sem R18_II_Mid Exam Time Table May-2021</a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/Timetablefor  B.Pharm I Year_I sem  II _mid term  exams_may-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i>Timetablefor B.Pharm I Year_I sem II _mid term exams_may-2021 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Timetables for B.Tech IV-II I- Mid term Exams may-2021
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech_R-16 VI _II  Mid-I  exams Timetable MAY-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i>Timetables for B.Tech IV-II I- Mid term Exams may-2021</a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Timetables and letter for B.Tech II-I, III-I & IV-I Sem II-midterm exams feb march-2021
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/B.Pharmacy 2-1,3-1,4-1 sem  II- Mid Term Exams  Timetable feb-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i>B.Pharmacy 2-1,3-1,4-1 sem II- Mid Term Exams Timetable feb-2021 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech  IV_I R16  II Midterm exams timetables Feb-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i> B.Tech IV_I R16 II Midterm exams timetables Feb-2021 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech II_I R18 II Midterm exams timetables March-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i>B.Tech II_I R18 II Midterm exams timetables March-2021 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech III_I  R18 II Midterm exam timetable Feb-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i>B.Tech III_I R18 II Midterm exam timetable Feb-2021 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/letter to the principals regading mid-II exams IV_I B.Pharm Rescheduling feb-2021.pdf"target="_blank"><i class="fa-solid fa-download"></i> letter to the principals regading mid-II exams IV_I B.Pharm Rescheduling feb-2021 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Provisional Timetables for B.Tech II-I, III-I & IV-I Sem I-midterm exams Dec-2020
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech  IV_I R16  I Midterm exams timetables DEC-2020.pdf"target="_blank"><i class="fa-solid fa-download"></i> B.Tech IV_I R16 I Midterm exams timetables DEC-2020 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech II_I R18 I Midterm exams timetables Dec-2020.pdf"target="_blank"><i class="fa-solid fa-download"></i> B.Tech II_I R18 I Midterm exams timetables Dec-2020</a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech III_I  R18 Midterm exam timetable Dec-2020.pdf"target="_blank"><i class="fa-solid fa-download"></i> B.Tech III_I R18 Midterm exam timetable Dec-2020</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>MBA II & IV Sem Supply Exams March-April 2021
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/Time Table of MBA  II & IV Sem Supply March exam 2020.pdf"target="_blank"><i class="fa-solid fa-download"></i>  Time Table of MBA II & IV Sem Supply March exam 2020 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h5 className='pt-3'>Time Tables for 2019-20
                            </h5>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Time Table of MBA MCA III Sem Regular & supply exam Feb_2021
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/Time Table of MBA  III Sem Regular & supply exam Feb_2021.pdf"target="_blank"><i class="fa-solid fa-download"></i>   Time Table of MBA III Sem Regular & supply exam Feb_2021</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>B-Tech II-II Regulation-supplementary Examinations May-2017 Time Tables
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 2-2 R07 timetable.doc" target="_blank"><i class="fa-solid fa-download"></i> II Year B.Tech-II semester-R07 Regulation-supplementary Examinations May-2017 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 2-2 R09 timetable.doc" target="_blank"><i class="fa-solid fa-download"></i> II Year B.Tech-II semester-R09 Regulation-supplementary Examinations May-2017 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 2-2 R13 timetable.doc" target="_blank"><i class="fa-solid fa-download"></i> II Year B.Tech-II semester-R13 Regulation-regular Examinations May- 2017  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 2-2 R15 timetable.doc"target="_blank"><i class="fa-solid fa-download"></i> II Year B.Tech - II semester–R15 Regulation-regular Examinations May-2017  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 2-2 substitute.doc"target="_blank"><i class="fa-solid fa-download"></i> II Year B.Tech.-II semester–R15,R13,R09, Regulation-regular/supplementary Examinations May- 2017 Time Table for Substitute Subjects for Readmitted Students </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>B-Tech III-II Regulation-supplementary Examinations May-2017 Time Tables
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 3-2 R07 timetable.doc" target="_blank"><i class="fa-solid fa-download"></i> III Year B.Tech-II semester-R07 Regulation-supplementary Examinations May-2017 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 3-2 R09 timetable.doc" target="_blank"><i class="fa-solid fa-download"></i> III Year B.Tech-II semester-R09 Regulation-supplementary Examinations May-2017 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 3-2 R13 Timetable.docx" target="_blank"><i class="fa-solid fa-download"></i> III Year B.Tech-II semester-R13 Regulation-regular Examinations May- 2017  </a>
                                            </li>
                                           
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 3-2 substitute.docx" target="_blank"><i class="fa-solid fa-download"></i> III Year B.Tech.-II semester–R13,R09, Regulation-regular/supplementary Examinations May- 2017 Time Table for Substitute Subjects for Readmitted Students </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>B-Tech IV-II Regulation-supplementary Examinations May-2017 Time Tables

                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 4-2 R07 timetable.doc" target="_blank"><i class="fa-solid fa-download"></i> IV Year B.Tech-II semester-R07 Regulation-supplementary Examinations May-2017 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 4-2 R09 timetable.doc" target="_blank"><i class="fa-solid fa-download"></i> IV Year B.Tech-II semester-R09 Regulation-supplementary Examinations May-2017 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 4-2 R-13 TIME TABLE.docx" target="_blank"><i class="fa-solid fa-download"></i> IV Year B.Tech-II semester-R13 Regulation-regular Examinations May- 2017  </a>
                                            </li>
                                           
                                            <li>
                                                <a href="/assets/documents/exam/B.Tech 4-2 substitute.doc" target="_blank"><i class="fa-solid fa-download"></i> IV Year B.Tech.-II semester –R13,R09, Regulation-regular/supplementary Examinations May- 2017 Time Table for Substitute Subjects for Readmitted Students </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default JntuTimeTable
