import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const AICTEATALFDP = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> AICTE ATAL FDP
                                    </h2>
                                    <div className='py-2'>
                                        <p>The AICTE sponsored 5 day online ATAL FDP was inaugurated on Dec 7th,2020 at 10am with Dr. A Kannan, Former Professor and HOD, Information Science and Technology, College of Engineering Guindy, Anna University, Chennai as the Chief Guest. The inaugural ceremony began with lighting the lamp followed by invocation of Lord Ganesha with a prayer song. Our principal Dr. B.L. Malleswari ma'am addressed and welcomed the Chief Guest and participants of the FDP. Dr. Kannan gave his keynote address and emphasized on the importance of IOT and how technology evolved over the years.
                                        </p>
                                        <p>The ATAL FDP will be a 5 day extensive training program from Dec 7th -11th, 2020, with eminent resource person's lined up as speakers for the morning session and hands on training by experts on the pertinent topic in the afternoon session.
                                        </p>
                                    </div>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/AICTE-ATAL-FDP/1.jpg">
                                                    <img src="/assets/img/gallery/AICTE-ATAL-FDP/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>

                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/AICTE-ATAL-FDP/2.jpg">
                                                    <img src="/assets/img/gallery/AICTE-ATAL-FDP/2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>

                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/AICTE-ATAL-FDP/3.jpg">
                                                    <img src="/assets/img/gallery/AICTE-ATAL-FDP/3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>

                                            </div>
                                            <div className='col-12 py-3'>
                                                <h6>AICTE ATAL FDP on IoT - Valedictory Ceremony
                                                </h6>
                                                <p>The valedictory ceremony was conducted on Dec 11th at 3:15pm. The Chief guest was Dr Anita Sheela ma'am HOD of ECE dept JNTUH. The program started with lighting the lamp, keynote address by Dr. Anita Sheela ma'am and concluding remarks by Principal Dr. B.L. Malleswari ma'am. The participants gave us feedback about the FDP and we concluded the program with vote of thanks by covenor Mrs. M. Kavita ma'am
                                                </p>
                                                <p>The AICTE sponsored ATAL FDP on IoT valedictory session was successfully conducted with Dr. Anita Sheela, ECE HoD, JNTUH presiding the function as the Chief Guest. After lighting the lamp, Dr. Anita Sheela gave her address and concluding remarks were given by our Principal Dr. B.L. Malleswari. The participants gave their feedback about the FDP and we closed the session with vote of thanks by the Convenor, Mrs.M.Kavita.</p>
                                    
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/AICTE-ATAL-FDP/4.jpg">
                                                    <img src="/assets/img/gallery/AICTE-ATAL-FDP/4.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/AICTE-ATAL-FDP/5.jpg">
                                                    <img src="/assets/img/gallery/AICTE-ATAL-FDP/5.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/AICTE-ATAL-FDP/6.jpg">
                                                    <img src="/assets/img/gallery/AICTE-ATAL-FDP/6.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/AICTE-ATAL-FDP/7.jpg">
                                                    <img src="/assets/img/gallery/AICTE-ATAL-FDP/7.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/AICTE-ATAL-FDP/8.jpg">
                                                    <img src="/assets/img/gallery/AICTE-ATAL-FDP/8.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/AICTE-ATAL-FDP/9.jpg">
                                                    <img src="/assets/img/gallery/AICTE-ATAL-FDP/9.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default AICTEATALFDP
