import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const MOU2020 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2>MoU with Manya Princeton Review
                                    </h2>
                                    <div className='py-2'>
                                        <p>SWEC signed MOU with Manya Princeton Review for Higher Education counseling and training services.
                                        </p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img//MoU-1.jpg">
                                                    <img src="/assets/img//MoU-1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div className='pt-3 pb-2'>
                                                <h6 className=''>MoU with Education Matters for Higher Education
                                                </h6>
                                                <p className='pb-2'>SWEC signed an MoU with Education Matters for Higher Education Career counseling in SWEC premises. The aim is to provide information about opportunities in various countries and encourage students to study abroad.
                                                    <a class="spotlight" href="/assets/img//MoU-1.jpg">
                                                        <img src="/assets/img//MoU.jpg " alt="Image" class="mt-3 img-responsive center-block" />
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MOU2020
