import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DpeLeftTab from './DpeLeftTab';
import one from '../SandH/sandhimages/1.jpeg'
import two from '../SandH/sandhimages/2.jpg'
import three from '../SandH/sandhimages/3.jpg'
import four from '../SandH/sandhimages/4.jpg'
import five from '../SandH/sandhimages/5.jpg'
import six from '../SandH/sandhimages/6.jpg'
import seven from '../SandH/sandhimages/7.jpg'
import eight from '../SandH/sandhimages/8.jpg'
import nine from '../SandH/sandhimages/9.jpg'
import ten from '../SandH/sandhimages/10.jpg'
import eleven from '../SandH/sandhimages/11.jpg'
import twelve from '../SandH/sandhimages/12.jpg'
import thirteen from '../SandH/sandhimages/13.jpg'
import fourteen from '../SandH/sandhimages/14.jpg'
import fivteen from '../SandH/sandhimages/15.jpg'
import sixteen from '../SandH/sandhimages/16.jpg'








const DpeAchievements = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DpeLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2 mb-lg-5 mb-4">
                            <div className="how-to-apply">
                                <h2>Achievements
                                </h2>
                                <div className='submit'>
                                    <p>In the All India Nausainik camp held in 2017, only two girls were selected across AP and Telangana and we are proud to say that they are from our college.
                                    </p>
                                    <div className='col-12 pb-3'>
                                        <img width={300} className='img-fluid' src={one} loading='lazy' alt='activities' />
                                    </div>
                                </div>
                                <div className='submit'>
                                    <h6 className='pb-2'>Faculty Throwball Runners Up at Narayanamma college 2018
                                    </h6>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={two} loading='lazy' alt='activities' />
                                        </div>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={three} loading='lazy' alt='activities' />
                                        </div>

                                    </div>
                                </div>

                                <div className='submit'>
                                    <h6 className='pb-2'>Sports News
                                    </h6>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={four} loading='lazy' alt='activities' />
                                        </div>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={five} loading='lazy' alt='activities' />
                                        </div>

                                    </div>
                                </div>
                                <div className='submit'>
                                    <h6 className='pb-2'>Sports at Vigynan College
                                    </h6>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={six} loading='lazy' alt='activities' />
                                        </div>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={seven} loading='lazy' alt='activities' />
                                        </div>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={eight} loading='lazy' alt='activities' />
                                        </div>
                                    </div>
                                </div>
                                <div className='submit'>
                                    <h6 className='pb-2'>Sports at Thirumala College
                                    </h6>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={nine} loading='lazy' alt='activities' />
                                        </div>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={ten} loading='lazy' alt='activities' />
                                        </div>

                                    </div>
                                </div>
                                <div className='submit'>
                                    <h6 className='pb-2'>Kho-Kho team runners up at Narayanamma College
                                    </h6>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={eleven} loading='lazy' alt='activities' />
                                        </div>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={twelve} loading='lazy' alt='activities' />
                                        </div>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={thirteen} loading='lazy' alt='activities' />
                                        </div>
                                    </div>
                                </div>
                                <div className='submit'>
                                    <p className='pb-2'>
                                        <strong>A visit to the Kasturba Gandhi National Memorial Trust at Hydershakote ,Langer house as part of NSS led by Mr.Dorababu. 100 Students and faculty visited the trust and donated rice, clothes,books and few other essential items on 14th August 2018 and spent some time with the elderly people staying there.
                                        </strong>
                                    </p>
                                    <div className='row'>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={fourteen} loading='lazy' alt='activities' />
                                        </div>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={fivteen} loading='lazy' alt='activities' />
                                        </div>
                                        <div className='col-12 col-md-6 pb-3'>
                                            <img className='img-fluid' src={sixteen} loading='lazy' alt='activities' />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default DpeAchievements
