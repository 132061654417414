import React from 'react';
import Courses from '../coursesOffered/Courses';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const About = () => {
    return (
        <div className='aboutClg-Section'>
            <div className="aboutClgSection pt-xl-5 pt-lg-5 pt-md-5 mt-5">
                    <OwlCarousel className="hero-slider hero-slider2 style2 owl-carousel owl-theme owl-loaded owl-drag" data-slider-id="1" items={1}
                        loop
                        nav
                        margin={8}>
                        <div className="slider-item">
                            <div className=" banner-bg-7">
                                <div className="container">
                                    <div className="tyle2">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="campus-content pr-20">
                                                    <div className="campus-title">
                                                        <h2>About College (SWEC)</h2>
                                                        <h6></h6>
                                                        <p>
                                                            Sridevi Women’s Engineering was established in the year 2001 with the approval from AICTE,
                                                            New Delhi and Government of A.P. The College is affiliated to
                                                            J.N.T.U. Hyderabad and is sponsored by Sridevi Education
                                                            Society. The core objective of the institution is to contribute
                                                            to the Educational needs of State and Nation, with an emphasis
                                                            on promoting girl education and women empowerment.
                                                            Our total strength sums up to 2600.
                                                            The EAMCET code is SDEW and college code is D2.
                                                        </p>
                                                        <p>Sridevi Women’s Engineering College provides an excellent
                                                            academic environment and latest learning resources to help
                                                            students make the most out of their career goals.</p>
                                                        {/* <p><strong>VISION:</strong> To attract the finest talent by creating
                                                            an atmosphere conducive to learning and to train and empower
                                                            female professionals with global skills thereby assigning their
                                                            legitimate place of honour in the society.</p> */}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="campus-image pl-20">
                                                    <img src="./assets/img/slider-clg.png" alt="Image" width="100%" height="auto" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-item">
                            <div className=" banner-bg-7">
                                <div className="container">
                                    <div className="tyle2">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="campus-content pr-20">
                                                    <div className="campus-title">
                                                        <h2>College Objective</h2>
                                                        <p className="pb-lg-4" align="justify">Our goal must be to create
                                                            distinctive programs and deliver them with distinction.</p>
                                                        <h5 className="pb-lg-1">
                                                            <i className="fa fa-eye  text-red"></i> Vision
                                                        </h5>
                                                        <p className="pb-lg-4 ps-lg-2" align="justify">
                                                            To attract the finest talent by creating an atmosphere conducive
                                                            to learning and to train and empower female professionals with
                                                            global skills thereby assigning their legitimate place of honour
                                                            in the society.
                                                        </p>
                                                        <div>
                                                            <h5 className="pb-1">
                                                                <i className="fa fa-bullseye  text-red"></i> Mission
                                                            </h5>
                                                            <p className="ptop10" align="justify">To
                                                                create a centre for excellence in engineering and facilitate
                                                                women empowerment by imparting knowledge and skills to
                                                                students enhancing their technical competency and
                                                                intellectual capabilities; with an equally competent and
                                                                experienced team that derives strength from each other and
                                                                creates a conducive work environment.</p>


                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="campus-image pl-20">
                                                    <img src="./assets/img/slider-objective.png" alt="Image" width="100%" height="auto" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </OwlCarousel>
                
                {/* <div className="owl-nav"><button type="button"  className="owl-prev"><i
                    className="ri-arrow-left-line"></i></button><button type="button" 
                        className="owl-next"><i className="ri-arrow-right-line"></i></button></div>
                <div className="owl-dots disabled"></div>  */}
            </div>
            <Courses />
        </div>
    )
}

export default About
