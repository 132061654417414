
import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';
import one from './cseD/19.png'
import two from './cseD/20.png'
import three from './cseD/21.png'
import four from './cseD/sttp.jpg'
import five from './cseD/1.png'
import six from './cseD/2.png'
import seven from './cseD/3.png'
import eight from './cseD/4.png'
import nine from './cseD/10.png'
import ten from './cseD/11.png'
import eleven from './cseD/12.png'
import twelve from './cseD/5.png'
import thirteen from './cseD/6.png'
import fourteen from './cseD/7.png'
import fiveteen from './cseD/8.png'
import sixteen from './cseD/9.png'

const CSELaboratoryProjects = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Project Grant
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th><strong>Name of the Faculty</strong></th>
                                                <th><strong>Project Title</strong></th>
                                                <th><strong>Project Type Consultancy</strong></th>
                                                <th><strong>Funding Agency</strong></th>
                                                <th><strong>Amount</strong></th>
                                                <th><strong>Status</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Dr.V.ANANTHA KRISHNA--(PI) </td>
                                                <td>ELECTRONIC MEDICAL GUAGE 	SYSTEM FORPREVENTION ,MONITORING AND PROPER CAREOF WOUNDS </td>
                                                <td>DST-RESEARCH</td>
                                                <td>SEED-TECHNOLOGY INTERVENTIONSFOR DISABLED AND ELDERLY (TIDE) </td>
                                                <td>100000.00</td>
                                                <td>APPLIED</td>
                                            </tr>
                                            <tr>
                                                <td>Dr.TKS RATHISH BABU--(PI) </td>
                                                <td>A HEALTH SECURITY WEARABLE DEVICE </td>
                                                <td>DST-RESEARCH</td>
                                                <td>DEVICE DEVELOPMENT PROGRAM </td>
                                                <td>985000.00</td>
                                                <td>APPLIED</td>
                                            </tr>
                                            <tr>
                                                <td>Dr..A.GAUTAMI LATHA-(PI) </td>
                                                <td>SMART GARBAGE COLLECTION WASTE MANAGEMENT SYSTEM USING IOT </td>
                                                <td>DST-RESEARCH</td>
                                                <td>DEVICE DEVELOPMENT PROGRAM </td>
                                                <td>14,22,000 </td>
                                                <td>APPLIED</td>
                                            </tr>
                                            <tr>
                                                <td>Dr.A.GAUTHAMI LATHA-(CO-PI) </td>
                                                <td>AUTONOMOUS QUADCOPTER FOR MULTIPURPOSE APPLICATION LIKE PAINTING HIGH RISE STRUCTURES  WINDOW CLEANING AND SOLAR PANEL CLEANING </td>
                                                <td>DST-RESEARCH</td>
                                                <td>DEVICE DEVELOPMENT PROGRAM </td>
                                                <td>2775000 </td>
                                                <td>APPLIED</td>
                                            </tr>
                                            <tr>
                                                <td>Dr B NARENDRA KUNAR -(CO-PI) </td>
                                                <td>SMART GARBAGE COLLECTION WASTE MANAGEMENT SYSTEM USING IOT </td>
                                                <td>DST-RESEARCH</td>
                                                <td>DEVICE DEVELOPMENT PROGRAM </td>
                                                <td>14,22,000 </td>
                                                <td>APPLIED</td>
                                            </tr>
                                            <tr>
                                                <td>Dr B NARENDRA KUNAR-(CO-PI) </td>
                                                <td>AUTONOMOUS QUADCOPTER FOR MULTIPURPOSE  APPLICATION LIKE PAINTING HIGH RISE STRUCTURES WINDOW CLEANING AND SOLAR PANEL CLEANING </td>
                                                <td>DST-RESEARCH</td>
                                                <td>DEVICE DEVELOPMENT PROGRAM </td>
                                                <td>2775000 </td>
                                                <td>APPLIED</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div className='pt-3'>
                                    <h6>Workshops
                                    </h6>
                                    <div className='pt-2'>
                                        <p>CSE department inaugurated the AICTE sponsored online "Short Term Training Program on Blockchain Technology and its Applications" on 3rd Dec 2020 at 10:30am. The STTP convenor and HOD CSE department Dr. Gauthami Latha and Professor & Coordinator Dr. Anantha Krishna welcomed the Chief Guest of the event Dr B Vishnu Vardhan, JNTUH, Manthani and Guest of Honour Dr. M Subrahmanya Sarma , Senior Manager, Security IBM . Dr. B.L.Malleswari, Principal, SWEC also addressed the online participants and welcomed them to the STTP.
                                        </p>
                                        <p>The 5 day event will witness eminent speakers who are experts in blockchain technology and would share their knowledge and experience with the faculty.
                                        </p>

                                        <div class="detgails-right-content mt-4">
                                            <div class="related-download bg-gray">
                                                <ul>
                                                    <li>
                                                        <a href="/assets/documents/cse/STTP Brochure CSE Dept 2020.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i> STTP Brochure CSE 2020 </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='pt-3 row'>
                                            <div className='col-md-6 col-lg-4 pb-3'>
                                                <img src={one} alt='laboratory' className='img-fluid' loading='lazy' />
                                            </div>
                                            <div className='col-md-6 col-lg-4 pb-3'>
                                                <img src={two} alt='laboratory' className='img-fluid' loading='lazy' />
                                            </div>
                                            <div className='col-md-6 col-lg-4 pb-3'>
                                                <img src={three} alt='laboratory' className='img-fluid' loading='lazy' />
                                            </div>
                                            <div className='col-md-6 col-lg-4 pb-3'>
                                                <img src={four} alt='laboratory' className='img-fluid' loading='lazy' />
                                            </div>
                                        </div>
                                        <div className='pt-3'>
                                            <h6>IPR & IA Workshops
                                            </h6>
                                            <div class="detgails-right-content mt-4">
                                                <div class="related-download bg-gray">
                                                    <ul>
                                                        <li>
                                                            <a href="/assets/documents/cse/IPR & IA WORKSHOPS.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  IPR & IA Workshops </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h6>SWEC, CSE Hosts Faculty Development Programme
                                            </h6>
                                            <p>Sridevi womens engineering college (SWEC), organized the faculty development programme (FDP) from 29th May to 05th June, 2017, at the premises in Vattinagula Pally.
                                            </p>
                                            <div className='pt-3 row'>
                                                <div className='col-md-6 col-lg-4 pb-3'>
                                                    <img src={five} alt='laboratory' className='img-fluid' loading='lazy' />
                                                </div>
                                                <div className='col-md-6 col-lg-4 pb-3'>
                                                    <img src={six} alt='laboratory' className='img-fluid' loading='lazy' />
                                                </div>
                                                <div className='col-md-6 col-lg-4 pb-3'>
                                                    <img src={seven} alt='laboratory' className='img-fluid' loading='lazy' />
                                                </div>
                                                <div className='col-md-6 col-lg-4 pb-3'>
                                                    <img src={eight} alt='laboratory' className='img-fluid' loading='lazy' />
                                                </div>
                                            </div>

                                            <div className='pt-3'>
                                                <h6>SWEC, CSE Conducts Hands on Training on “PYTHON AND C PROGRAMMING “ ON 26th to 28th JULY 2018
                                                </h6>
                                                <p>Department of CSE, Sridevi womens engineering college (SWEC), organized the guest lecture on “stress management on 07th August 2017 by Prof. SAI KUMAR. Dr. M.Ramasubramanian, Head, CSE, inaugurated the guest lecture by Lighting the lamp ceremony.
                                                </p>
                                                <div className='pt-3 row'>
                                                    <div className='col-md-6 col-lg-4 pb-3'>
                                                        <img src={nine} alt='laboratory' className='img-fluid' loading='lazy' />
                                                    </div>
                                                    <div className='col-md-6 col-lg-4 pb-3'>
                                                        <img src={ten} alt='laboratory' className='img-fluid' loading='lazy' />
                                                    </div>
                                                    <div className='col-md-6 col-lg-4 pb-3'>
                                                        <img src={eleven} alt='laboratory' className='img-fluid' loading='lazy' />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='pt-3'>
                                                <h6>SWEC, CSE Hosts Motivational Guest Lecture on July 21 and 22 2017
                                                </h6>
                                                <p>Department of CSE, Sridevi womens engineering college (SWEC), organized the motivational guest lecture on July 21 and 22 2017 by Prof. SAI KUMAR. Dr. M.Ramasubramanian, Head, CSE, inaugurated the motivational SDP and gave the Welcome address, after Lighting the lamp ceremony.
                                                </p>
                                                <div className='pt-3 row'>
                                                    <div className='col-md-6 col-lg-4 pb-3'>
                                                        <img src={twelve} alt='laboratory' className='img-fluid' loading='lazy' />
                                                    </div>
                                                    <div className='col-md-6 col-lg-4 pb-3'>
                                                        <img src={thirteen} alt='laboratory' className='img-fluid' loading='lazy' />
                                                    </div>
                                                    <div className='col-md-6 col-lg-4 pb-3'>
                                                        <img src={fourteen} alt='laboratory' className='img-fluid' loading='lazy' />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='pt-3'>
                                                <h6>SWEC, CSE Hosts Workshop on Debian Linux
                                                </h6>
                                                <p>Department of CSE, Sridevi womens engineering college (SWEC), organized the workshop on Debian Linux, organized by SWECHA people, ‎on 31st July ‎2017.
                                                </p>
                                                <p>Dr. M.Ramasubramanian, Head, CSE, inaugurated the workshop by lighting the lamp , gave the Welcome address and introduce the guest. Dr. Anantha Krishna, Professor gave the importance of Debian workshop.
                                                </p>
                                                <div className='pt-3 row'>
                                                    <div className='col-md-6 col-lg-4 pb-3'>
                                                        <img src={fiveteen} alt='laboratory' className='img-fluid' loading='lazy' />
                                                    </div>
                                                    <div className='col-md-6 col-lg-4 pb-3'>
                                                        <img src={sixteen} alt='laboratory' className='img-fluid' loading='lazy' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='pt-3'>
                                                <h6>Seminar in the year 2018 - 19
                                                </h6>
                                                <div class="apply-list">

                                                    <ul class="pt-2">
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the seminar on “Industry requirements” on 05th March 2018, By Mr. Sekhar Nani, resource person from COIGN consultants Pvt Ltd, at the college premises.
                                                        </li>
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the seminar on “SELF LEARNING” on 29th January 2018 by Ms. Sai Latha, resource person from COIGN consultants Pvt Ltd, at the college premises.
                                                        </li>
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the guest lecture on “Communication and Personality Development – Focus Insight Innovation ” on 03rd January 2018 by Ms.Fouzia, resource person from COIGN consultants Pvt Ltd, at the premises in Vattinagula Pally.
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className='pt-3'>
                                                <h6>Seminar in the year 2017 - 18

                                                </h6>
                                                <div class="apply-list">

                                                    <ul class="pt-2">
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the seminar on “Industry requirements” on 22rd September 2017. By Mr. Sekhar Nani, resource person from COIGN consultants Pvt Ltd, at the premises in Vattinagula Pally.
                                                        </li>
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the guest lecture on “Communication and Personality Development – Focus Insight Innovation ” on 31st July 2017 by Ms.Fouzia, resource person from COIGN consultants Pvt Ltd, at the premises in Vattinagula Pally.
                                                        </li>

                                                    </ul>
                                                </div>

                                            </div>
                                            <div className='pt-3'>
                                                <h6>Seminar in the year 2016 - 17

                                                </h6>
                                                <div class="apply-list">

                                                    <ul class="pt-2">
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the seminar on “Industry requirements” on 23rd February 2017. By Mr. Sekhar Nani, resource person from COIGN consultants Pvt Ltd, at the premises in Vattinagula Pally.
                                                        </li>
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the guest lecture on “ARITHMETIC REASONING AND LOGICAL SKILLS” on 20th January 20117 by Ms. Ramya, resource person from COIGN consultants Pvt Ltd, at the premises in Vattinagula Pally.
                                                        </li>
                                                        <li><i class="ri-checkbox-circle-line"></i> Department of CSE, Sridevi Womens Engineering College (SWEC), organized the guest lecture on “Communication and Personality Development – Focus Insight Innovation ” on 25th July 2016 by Ms.Fouzia, resource person from COIGN consultants Pvt Ltd, at the premises in Vattinagula Pally.
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className='pt-3'>
                                                <h6>Seminar in the year 2015 - 16

                                                </h6>
                                                <div class="apply-list">

                                                    <ul class="pt-2">
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the seminar on “Industry requirements” on 29th February 2016. By Mr. Sekhar Nani, resource person from COIGN consultants Pvt Ltd, at the premises in Vattinagula Pally.
                                                        </li>
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the seminar on “Industry Perspective Learning” on 7th August 2015 by Mr. Ravindar Reddy, resource person from COIGN consultants Pvt Ltd, at the premises in Vattinagula Pally.
                                                        </li>
                                                        <li><i class="ri-checkbox-circle-line"></i>Department of CSE, Sridevi Womens Engineering College (SWEC), organized the guest lecture on “Communication and Personality Development” on 28th January 2016 by Ms.Fouzia, resource person from COIGN consultants Pvt Ltd, at the premises in Vattinagula Pally.
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default CSELaboratoryProjects
