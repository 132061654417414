import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from '../ECELinks/EceleftTab';

const EceCalender = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department Academic Calendar
                                </h2>
                                <div className='pt-3'>
                                    <p>CAY (2020-2021)
                                    </p>
                                    <h6>I SEMESTER
                                    </h6>
                                    <div className='mt-3'>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr class="bg-gray">
                                                        <td><strong>S.NO</strong> </td>
                                                        <td><strong>Activity</strong> </td>
                                                        <td><strong>Date</strong></td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>1</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>FDP-</span>
                                                                            <span>Cyber&nbsp;security</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>4-May-2020</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>2</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Faculty&nbsp;Webinar</span>
                                                                            <span>-</span>
                                                                            <span>IOT(IEEE)</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>4-June-2020</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>3</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Faculty&nbsp;Webinar</span>
                                                                            <span>
                                                                                -Robotic&nbsp;Process&nbsp;Automation
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>9-June-2020</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>4</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Faculty&nbsp;workshop</span>
                                                                            <span>-Angular&nbsp;JS</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>26-June-2020</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>5</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                FDP-61&nbsp;st&nbsp;&nbsp;<span zeum4c2="PR_1_0" data-ddnwab="PR_1_0">Out&nbsp;Reach</span>&nbsp;Programme(IIRS-ISRO)
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                29-June-2020&nbsp;to&nbsp;3-July-2020
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>6</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>FDP-60&nbsp;</span>
                                                                            <span>
                                                                                <span>th</span>
                                                                            </span>
                                                                            <span>
                                                                                &nbsp;&nbsp;<span>Out&nbsp;Reach</span>&nbsp;Programme(IIRS-ISRO
                                                                            </span>
                                                                            <span>)</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                13-July-2020&nbsp;to&nbsp;24-July-2020
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>7</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                FDP-62&nbsp;nd&nbsp;&nbsp;<span zeum4c2="PR_3_0" data-ddnwab="PR_3_0">Out&nbsp;Reach</span>&nbsp;Programme(IIRS-ISRO
                                                                            </span>
                                                                            <span>)</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                27-July-2020&nbsp;to&nbsp;31-July-2020
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>8</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>FDP-6</span>
                                                                            <span>5</span>
                                                                            <span>&nbsp;</span>
                                                                            <span>
                                                                                <span>th</span>
                                                                            </span>
                                                                            <span>
                                                                                &nbsp;&nbsp;<span zeum4c2="PR_4_0" data-ddnwab="PR_4_0">Out&nbsp;Reach</span>&nbsp;Programme(IIRS-ISRO
                                                                            </span>
                                                                            <span>)</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                17-Aug-2020&nbsp;to&nbsp;11-Sep-2020
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>10</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>FDP-</span>
                                                                            <span>Arduino</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>4-Aug-2020&nbsp;to10-Aug-2020</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>11</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>FDP-</span>
                                                                            <span>
                                                                                Recent&nbsp;trends&nbsp;in&nbsp;E&amp;C&nbsp;for&nbsp;Research
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>12-Aug-2020&nbsp;to18-Aug-2020</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>12</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                Commencement&nbsp;of&nbsp;Class&nbsp;work&nbsp;for&nbsp;II
                                                                            </span>
                                                                            <span>,</span>
                                                                            <span>
                                                                                III&nbsp;&amp;&nbsp;IV&nbsp;year
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>1-Sep-2020</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>13</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                Industry&nbsp;Interaction&nbsp;-student&nbsp;workshop
                                                                            </span>
                                                                            <span>&nbsp;on&nbsp;</span>
                                                                            <span>Python</span>
                                                                            <span>(IIT</span>
                                                                            <span>
                                                                                &nbsp;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div >
                                                                            <span>Bombay)</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                27-July-20&nbsp;to&nbsp;31-July-20
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>14</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                Industry&nbsp;Interaction&nbsp;-student&nbsp;workshop
                                                                            </span>
                                                                            <span>
                                                                                &nbsp;on&nbsp;Micro&nbsp;controllers
                                                                            </span>
                                                                            <span>
                                                                                &nbsp;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div >
                                                                            <span>Applications</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                10-&nbsp;Sep&nbsp;-20&nbsp;to11-&nbsp;Sep&nbsp;-20
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>15</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                Industry&nbsp;Interaction&nbsp;-student&nbsp;workshop
                                                                            </span>
                                                                            <span>
                                                                                &nbsp;on&nbsp;Digital&nbsp;Design
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                13-Aug-20&nbsp;to&nbsp;14-Aug-20
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>16</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                Industry&nbsp;Interaction&nbsp;-student&nbsp;workshop
                                                                            </span>
                                                                            <span>&nbsp;on&nbsp;PCB&nbsp;Design</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                20-Oct-20&nbsp;to&nbsp;21-Oct-20
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>17</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                <span zeum4c2="PR_5_0" data-ddnwab="PR_5_0" aria-invalid="spelling" class="LI ng">Dusshera</span>&nbsp;holidays
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>19-Oct-2020to&nbsp;24-Oct-2020</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>18</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>I</span>
                                                                            <span>st&nbsp;</span>
                                                                            <span>&nbsp;Mid-Sem&nbsp;Examination</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                21-Dec-2020&nbsp;to&nbsp;28-Dec-2020
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>19</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>Submission&nbsp;</span>
                                                                            <span>o</span>
                                                                            <span>
                                                                                f&nbsp;I&nbsp;Mid&nbsp;marks&nbsp;on&nbsp;or&nbsp;before&nbsp;to&nbsp;University
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>4-Jan-2021</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>20</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                Industry&nbsp;Interaction&nbsp;-student&nbsp;workshop
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                20-&nbsp;Jan&nbsp;-21&nbsp;to&nbsp;21-&nbsp;Jan&nbsp;-21
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>21</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>Practical&nbsp;classes</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>22-&nbsp;Feb-</span>
                                                                            <span>2021&nbsp;27</span>
                                                                            <span>-Feb-</span>
                                                                            <span>202</span>
                                                                            <span>1</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>22</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                Preparation&nbsp;Holidays&nbsp;and&nbsp;Practical&nbsp;Examinations
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>01</span>
                                                                            <span>-Mar-</span>
                                                                            <span>2021&nbsp;06</span>
                                                                            <span>-&nbsp;Mar-</span>
                                                                            <span>2021</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>23</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                II&nbsp;Mid&nbsp;Term&nbsp;Examination
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>
                                                                                15-&nbsp;Feb&nbsp;-2020&nbsp;to20-&nbsp;Feb&nbsp;-2020
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>24</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>Submission&nbsp;</span>
                                                                            <span>o</span>
                                                                            <span>
                                                                                f&nbsp;II&nbsp;Mid&nbsp;marks&nbsp;on&nbsp;or&nbsp;before&nbsp;to&nbsp;University
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>6-&nbsp;Mar&nbsp;-2021</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>25</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <span>
                                                                                End&nbsp;Semester&nbsp;&amp;&nbsp;Supplementary&nbsp;Examination
                                                                            </span>
                                                                            <span>&nbsp;</span>
                                                                            <span>(II&nbsp;sem&nbsp;of&nbsp;II</span>
                                                                            <span>
                                                                                &nbsp;
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div >
                                                                            <span>&amp;&nbsp;III&nbsp;years)</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <div>
                                                                    <div >
                                                                        <div>
                                                                            <span>08-&nbsp;Mar-</span>
                                                                            <span>2021</span>
                                                                            <span>&nbsp;to&nbsp;20-&nbsp;Mar-</span>
                                                                            <span>2021</span>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='pt-3'>
                                            <p> <strong>CAY (2020-2021)
                                            </strong></p>
                                            <h6>II SEMESTER

                                            </h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr class="bg-gray">
                                                            <td><strong>S.NO</strong> </td>
                                                            <td><strong>Activity</strong> </td>
                                                            <td><strong>Date</strong></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>1</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>
                                                                                    Commencement&nbsp;of&nbsp;Class&nbsp;work&nbsp;for&nbsp;II
                                                                                </span>
                                                                                <span>,</span>
                                                                                <span>
                                                                                    III&nbsp;&amp;&nbsp;IV&nbsp;year
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>22</span>
                                                                                <span>-Mar-</span>
                                                                                <span>2021</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>2</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>Faculty&nbsp;Meeting-1</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>19-Mar-21</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>3</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>
                                                                                    1st&nbsp;Spell&nbsp;of&nbsp;Instructions
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>22</span>
                                                                                <span>-Mar-</span>
                                                                                <span>2021</span>
                                                                                <span>&nbsp;to</span>
                                                                                <span>&nbsp;15</span>
                                                                                <span>-May-</span>
                                                                                <span>2021</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>4</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>Summer&nbsp;Vacation</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>17</span>
                                                                                <span>-May-</span>
                                                                                <span>2021&nbsp;29</span>
                                                                                <span>-May-</span>
                                                                                <span>2021</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>5</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>
                                                                                    First&nbsp;Mid&nbsp;Term&nbsp;Examinations
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>31</span>
                                                                                <span>-May-</span>
                                                                                <span>2021&nbsp;05</span>
                                                                                <span>-June-</span>
                                                                                <span>2021</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>6</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>
                                                                                    Submission&nbsp;of&nbsp;First&nbsp;Mid&nbsp;Term&nbsp;Exam&nbsp;Marks&nbsp;to&nbsp;the
                                                                                </span>
                                                                                <span>
                                                                                    &nbsp;
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div>
                                                                                <span>
                                                                                    University&nbsp;on&nbsp;or&nbsp;before
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>11</span>
                                                                                <span>-June-</span>
                                                                                <span>2021</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>7</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div class="gmail-zw-line-div" >
                                                                            <div>
                                                                                <span>
                                                                                    2nd&nbsp;Spell&nbsp;of&nbsp;Instructions
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div class="gmail-zw-line-div" >
                                                                            <div>
                                                                                <span>07</span>
                                                                                <span>-June-</span>
                                                                                <span>2021&nbsp;31</span>
                                                                                <span>-July-</span>
                                                                                <span>2021</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>8</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div class="gmail-zw-line-div" >
                                                                            <div>
                                                                                <span>
                                                                                    Second&nbsp;Mid&nbsp;Term&nbsp;Examinations
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>02</span>
                                                                                <span>-Aug-</span>
                                                                                <span>2021&nbsp;07</span>
                                                                                <span>-Aug-</span>
                                                                                <span>2021</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>9</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div class="gmail-zw-line-div">
                                                                            <div>
                                                                                <span>
                                                                                    Preparation&nbsp;Holidays&nbsp;and&nbsp;Practical&nbsp;Examinations
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div class="gmail-zw-line-div" >
                                                                            <div>
                                                                                <span>
                                                                                    &nbsp;&nbsp;9-Aug-2021&nbsp;to14-Aug-2021
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div class="gmail-zw-line-div">
                                                                            <div>
                                                                                <span>10</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div class="gmail-zw-line-div">
                                                                            <div>
                                                                                <span>
                                                                                    Submission&nbsp;of&nbsp;Second&nbsp;Mid&nbsp;Term&nbsp;Exam&nbsp;Marks&nbsp;to&nbsp;the
                                                                                </span>
                                                                                <span>
                                                                                    &nbsp;
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div>
                                                                                <span>
                                                                                    University&nbsp;on&nbsp;or&nbsp;before
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>14</span>
                                                                                <span>-Aug-</span>
                                                                                <span>2021</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="gmail-zw-line-div">
                                                                            <div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div class="gmail-zw-line-div">
                                                                            <div>
                                                                                <span>11</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>
                                                                                    End&nbsp;Semester&nbsp;Examinations
                                                                                </span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        <div>
                                                                            <div>
                                                                                <span>16-Aug-2021&nbsp;28-Aug-2021</span>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='pt-3'>
                                            <p><strong>CAY-m1 (2019-2020)
                                            </strong></p>
                                            <h6>II SEMESTER
                                            </h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr class="bg-gray">
                                                            <td><strong>S.NO</strong> </td>
                                                            <td><strong>Activity</strong> </td>
                                                            <td><strong>Date</strong></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>1</p>
                                                            </td>
                                                            <td>
                                                                <p><span>Commencement of Class work for II,III &amp; IV year</span></p>
                                                            </td>
                                                            <td>
                                                                <p>16-Dec-2019</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>2</p>
                                                            </td>
                                                            <td>
                                                                <p><span>Faculty Meeting-1</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>11-Dec-2019</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>3</span></p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>
                                                                        Industry Interaction -Student workshop on Android Multi Screen
                                                                        Applications
                                                                    </span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p><span>17-Dec-2019 to 18-Dec-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>5</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>Republic Day</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>26-Jan-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>6</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>Industry Interaction -Student workshop on Image Processing</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>27-Jan-2020 to 28-Jan-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>7</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>I st Mid-Sem Examination</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>10-Feb-2020 to 12-Feb-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>8</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>Industry Interaction -Student workshop on RF Amplifier Design</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>12-Feb-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>9</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>Submission of I Mid marks on or before to University</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>19-Feb-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>10</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>Parent-Teacher Meeting</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>14-March-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>11</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>Last date of Instruction</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>7-April-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>12</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>II Mid Term Examination</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>6-Oct-2020 to 31-Oct-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>13</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>Submission of II Mid marks on or before to University</span></p>
                                                            </td>
                                                            <td>
                                                                <p><span>2-Nov-2020 to 5-Nov-2020</span></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p><span>14</span></p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>
                                                                        End Semester &amp; Supplementary Examination (II sem of II &amp;
                                                                        III years)
                                                                    </span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p><span>12-Nov-2020 to 2-Dec-2020</span></p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='pt-3'>
                                            <p><strong>CAY-m2 (2018-2019)
                                            </strong></p>
                                            <h6>I SEMESTER</h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr class="bg-gray">
                                                            <td><strong>S.NO</strong> </td>
                                                            <td><strong>Activity</strong> </td>
                                                            <td><strong>Date</strong></td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>1</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Faculty Meeting-1</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>3-July-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>2</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Commencement of Class work for II,III &amp; IV year</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>9-July-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>3</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Independence Day</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>15-Aug-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>4</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>I Mid-Sem Examination</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>4-Sep-2018 to 6-Sep-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>5</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Submission Of I Mid marks on or before to University</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>15-Sep-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>6</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Parent-Teacher Meeting</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>13-Oct-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>7</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span><span zeum4c14="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">Dusshera</span> Recess</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>15-Oct-2018 to20-Oct-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>8</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Last date of Instruction</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>10-Nov-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>9</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>II Mid Term Examination</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>12-Nov-2018 to 14-Nov-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>10</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Preparation Holiday and Practical Examination</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>15-Nov-2018 to 24-Nov-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>11</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Submission Of II Mid marks on or before to University</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>24-Nov-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>12</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>End Semester &amp; Supplementary Examination(II sem of II,III &amp; IV years)</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>26-Nov-2018 to 8-Dec-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>13</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Summer Vacation</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>10-Dec-2018 to 15-Dec-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='pt-3'>
                                            <p><strong>CAY-m2 (2018-2019)
                                            </strong></p>
                                            <h6>II SEMESTER
                                            </h6>
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr class="bg-gray">
                                                            <td><strong>S.NO</strong> </td>
                                                            <td><strong>Activity</strong> </td>
                                                            <td><strong>Date</strong></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>1</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Faculty Meeting-1</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>17-Dec-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>2</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Commencement of Class work for II,III &amp; IV year</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>31-Dec-2018</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>3</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Faculty Development Programme-OBE</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>7-Jan-2019 to 12-Jan-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>4</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Industry interaction -Student workshop on Secure Communication</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>17-Jan-2019 to 19-Jan-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>5</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Industry interaction -Student workshop on PCB Design</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>10-Jan-2019 to 11-Jan-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>6</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Republic Day</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>26-Jan-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>7</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Industry interaction -Student workshop on Mobile Computing</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>13-Feb-19 to 15-Feb-19</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>8</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Faculty Meeting-2</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>16-Feb-19</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>9</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Guest Lecture</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>19-Feb-19</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>10</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>I Mid-Sem Examination</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>15-Feb-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>11</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Closing of attendance slot</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>20-Feb-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>12</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Submission Of I Mid marks on or before to University</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>9-Mar-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>13</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Industry interaction -student workshop on FPGA Implementation</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>14-Mar-2019 to15- Mar -2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>14</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Parent-Teacher Meeting</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>16-Mar-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>15</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Industry interaction -student <span zeum4c16="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="grammar" class="Lm ng">work shop</span> on LM55 timer</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>20- Mar -2019 to22- Mar -2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>16</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Closing of attendance slot</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>31-Mar-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>17</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Syllabus coverage and Closing of attendance slot</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>13-April-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>18</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Last date of Instruction</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>15-Apr-2019 to 17-Apr-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>19</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>II Mid Term Examination</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>18-Apr-2019 to 27-Apr-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>20</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Preparation Holiday and Practical Examination</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>25-Apr-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>21</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Submission Of II Mid marks on or before to University</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>30-Apr-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>22</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>End Semester &amp; Supplementary Examination(II sem of I,II &amp; III years)</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>29-Apr-2019 to 11-May-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    <span>23</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>Summer Vacation</span>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p>
                                                                    <span>13-May-2019 to 6-July-2019</span>
                                                                </p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default EceCalender
