import React from 'react'
import './alumni.css'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import AboutLeftTab from '../About/AboutLeftTab';
import Sayanna from './AlumniReunion/Sayanna.png'
import pdf from './AlumniReunion/458OmbudsmanDetails.pdf'

const Alumni = () => {
    // return (
    //     <div>



    //     </div>
    // )
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <div class="detgails-right-content mt-4">
                                <div class="related-download bg-gray">
                                    <ul>
                                        <li>
                                            <a href="/assets/documents/allumni/Alumini Reunion 2022-23.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Alumini Reunion 2022-23 </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/allumni/alumni meet 21-22 invitation (1).pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Alumni Meet 2021-22 Invitation </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/allumni/ALLUMNI REGISTRATION CERTIFICATE.pptx" target="_blank"><i class="fa-solid fa-file-pdf"></i>  Alumni Registration Certificate </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/allumni/ALLUMNI REPORT.docx" target="_blank"><i class="fa-solid fa-file-pdf"></i> Alumni Report </a>
                                        </li>
                                        <li>
                                            <a href="/assets/documents/allumni/DISTINGUISH ALLUMNI.docx" target="_blank"><i class="fa-solid fa-file-pdf"></i> Distinguish Allumni </a>
                                        </li>

                                        <li>
                                            <a href="https://docs.google.com/forms/d/e/1FAIpQLScIF6j2yOb5tooYdShvWdMAXWaeKDTCObkdubHgsMxk6zJWzw/viewform?vc=0&c=0&w=1" target="_blank"><i class="fa fa-edit"></i>  Click Here for Registration </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2 ">
                            <div className="how-to-apply">
                                <h2> Alumni Reunion
                                </h2>
                                <div class="css-script-ads" >
                                </div>
                                <div class="spotlight-group">
                                    <div className='row'>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A0770.jpg">
                                                <img src="/assets/img/allumni/4F8A0770.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A9741.jpg">
                                                <img src="/assets/img/allumni/4F8A9741.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A0903.jpg">
                                                <img src="/assets/img/allumni/4F8A0903.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A0873.jpg">
                                                <img src="/assets/img/allumni/4F8A0873.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A0864.jpg">
                                                <img src="/assets/img/allumni/4F8A0864.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A0852.jpg">
                                                <img src="/assets/img/allumni/4F8A0852.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A0844.jpg">
                                                <img src="/assets/img/allumni/4F8A0844.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A0836.jpg">
                                                <img src="/assets/img/allumni/4F8A0836.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A0824.jpg">
                                                <img src="/assets/img/allumni/4F8A0824.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3 ">
                                            <a class="spotlight" href="/assets/img/allumni/4F8A0780.jpg">
                                                <img src="/assets/img/allumni/4F8A0780.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>
                                        <h2 className='pt-4'> Alumni Inauguration
                                        </h2>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/inaguration-1.jpg">
                                                <img src="/assets/img/allumni/inaguration-1.jpg" alt="Image" class="img-responsive center-block img-fluid" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/inaguration-2.jpg">
                                                <img src="/assets/img/allumni/inaguration-2.jpg" alt="Image" class="img-responsive center-block img-fluid" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/inaguration-3.jpg">
                                                <img src="/assets/img/allumni/inaguration-3.jpg" alt="Image" class="img-responsive center-block img-fluid" />
                                            </a>
                                        </div>

                                        <div class="col-lg-12">
                                            <h2 className='pt-4'>Gallery</h2>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3 row">
                                            <a class="spotlight" href="/assets/img/allumni/1.jpg">
                                                <img src="/assets/img/allumni/1.jpg" alt="Image" class="img-responsive center-block img-fluid" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/2.jpg">
                                                <img src="/assets/img/allumni/2.jpg" alt="Image" class="img-responsive center-block img-fluid" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/3.jpg">
                                                <img src="/assets/img/allumni/3.jpg" alt="Image" class="img-responsive center-block img-fluid" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3 row">
                                            <a class="spotlight" href="/assets/img/allumni/4.jpg">
                                                <img src="/assets/img/allumni/4.jpg" alt="Image" class="img-responsive center-block img-fluid" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/5.jpg">
                                                <img src="/assets/img/allumni/5.jpg" alt="Image" class="img-responsive center-block img-fluid" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/6.jpg">
                                                <img src="/assets/img/allumni/6.jpg" alt="Image" class="img-responsive center-block img-fluid" />
                                            </a>
                                        </div>

                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/Alumni Meet 2021-22 - Student pic.jpg">
                                                <img src="/assets/img/allumni/Alumni Meet 2021-22 - Student pic.jpg" alt="Image" class="icenter-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/Alumni Meet 2021-22 - SWEC Faculty who are Alumni Students.jpg">
                                                <img src="/assets/img/allumni/Alumni Meet 2021-22 - SWEC Faculty who are Alumni Students.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/Alumni Meet 2021-22 -1.jpg">
                                                <img src="/assets/img/allumni/Alumni Meet 2021-22 -1.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/Alumni Meet 2021-22 -Few Alumni Group pic.jpg">
                                                <img src="/assets/img/allumni/Alumni Meet 2021-22 -Few Alumni Group pic.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/Alumni Meet 2021-22 -Principal Address.jpg">
                                                <img src="/assets/img/allumni/Alumni Meet 2021-22 -Principal Address.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3">
                                            <a class="spotlight" href="/assets/img/allumni/Alumni Meet 2021-22 -Welcome address.jpg">
                                                <img src="/assets/img/allumni/Alumni Meet 2021-22 -Welcome address.jpg" alt="Image" class="center-block" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Alumni
