import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import CSEDStab from '../cseDepartmentLinks/CSEDStab';


const CseDs = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <CSEDStab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Computer Science and Engineering in Data Science (CSE-DS)
                                </h2>
                                <p>The Department of Computer Science and Engineering in Data Science (CSE-DS) was established in the year 2022 with an intake of 60.
                                </p>
                                <p>The Department of CSE is headed by  Dr. K. C Ravikumar. Department offers a four year Bachelors (B.Tech) programme which aims at training students in the rapidly growing field of Data Science.

                                </p>
                                <p>Data Science is the study of data to extract meaningful insights for business. It is a multidisciplinary approach that combines principles and practices from the fields of Mathematics, Statistics, Artificial Intelligence and Computer Engineering to analyze large amounts of data.

                                </p>

                                <div class="apply-list border-0">
                                    The department facilitated with:
                                    <ul className='pt-4'>
                                        <li><i class="ri-checkbox-circle-line"></i>Academic/Student Centric Infrastructure
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Conference and Cultural Infrastructure
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Physical and Virtual IT Infrastructure
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Research and Tutorial Support
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Course-Specific Facilities and Resources
                                        </li>
                                    </ul>
                                </div>
                                <p>Data Science can be defined as a blend of mathematics, business acumen, tools, algorithms and machine learning techniques, all of which help us in finding out the hidden insights or patterns from raw data which can be of major use in the formation of big business decisions.

                                </p>
                                <p>Data science has been helping in the sectors like, Finance and Banking, Government, Healthcare, Utilities, Entertainment, Telecommunications etc. It is the best way to find solutions to circumstances with varied and dispersed data. Data Science has varied applications where business and commercial areas predominate. A major focus of this program is to train the students with intelligent data analysis and equipping them with the knowledge of advanced innovations in the field of Data Science.

                                </p>
                                <p>Data science has been helping in the sectors like, Finance and Banking, Government, Healthcare, Utilities, Entertainment, Telecommunications etc. It is the best way to find solutions to circumstances with varied and dispersed data. Data Science has varied applications where business and commercial areas predominate. A major focus of this program is to train the students with intelligent data analysis and equipping them with the knowledge of advanced innovations in the field of Data Science.

                                </p>
                                <p>There are different job profiles available like Data Scientist, Data Analyst, Data Engineer, Data Mining Engineer, Data Architect, Data Statistician and many for aspirants to join the exciting, growing, and demanding field of data science. This stream is useful in finding meaningful information from huge pools of data like sensors, images, streaming video, satellite, medical imagery and the cloud.

                                </p>
                               
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CseDs;
