import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';


const EEEFacultyInnovation = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Innovative Teaching Methodologies
                                </h2>
                                <div class="table-responsive" >
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th width="65"><strong>S.No</strong></th>
                                                <th width="180"><strong>Name of the Full-time teacher</strong></th>
                                                <th width="220"><strong>Designation</strong></th>
                                                <th width="120"><strong>Subject</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr.D.Padmavathi</td>
                                                <td>Professor &amp; Head</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>Computer Aided Power System Analysis</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>A.Ganga Dinesh Kumar</td>
                                                <td>Associate Professor</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>Control Engineering</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>B.Swetha</td>
                                                <td>Assistant Professor</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>Control Engineering</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>P.Swathi</td>
                                                <td>Assistant Professor</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>Control Engineering</a></td>
                                            </tr>

                                            <tr>
                                                <td>5</td>
                                                <td>B.Laksmi Prasanna </td>
                                                <td>Assistant Professor</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>Advanced Power Electronics and control</a></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>P.Saritha</td>
                                                <td>Assistant Professor</td>
                                                <td>
                                                    <a href="" target="_blank">
                                                        Advances in UHV transmission anddistribution
                                                    </a>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>7</td>
                                                <td>K.Harini </td>
                                                <td>Assistant Professor</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>EM-II</a></td>
                                            </tr>

                                            <tr>
                                                <td>8</td>
                                                <td>P.Swetha</td>
                                                <td>Assistant Professor</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>High Voltage Engineering</a></td>
                                            </tr>

                                            <tr>
                                                <td>9</td>
                                                <td>K.Lavanya</td>
                                                <td>Assistant Professor</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>HVDC Transmission </a></td>
                                            </tr>

                                            <tr>
                                                <td>10</td>
                                                <td>O.Ashwini</td>
                                                <td>Assistant Professor</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>Fundamentals of Electrical Engineering</a></td>
                                            </tr>

                                            <tr>
                                                <td>11</td>
                                                <td>K.Sudeshna </td>
                                                <td>Assistant Professor</td>
                                                <td><a href="" target="_blank"><i class="fa fa-file-pdf text-red"></i>Electric Circuit Analysis</a></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEEFacultyInnovation
