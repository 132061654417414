import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const Maithri2018 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> Maithri
                                    </h2>
                                    
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                       
<div class="row">
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/1.jpg">
            <img class="" src="/assets/img/gallery/maithri/1.jpg" alt="image" loading="lazy" />
        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/2.jpg">
            <img class="" src="/assets/img/gallery/maithri/2.jpg" alt="image" loading="lazy" />
        </a>
    </div>

    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/3.jpg">
            <img class="" src="/assets/img/gallery/maithri/3.jpg" alt="image" loading="lazy" />

        </a>
    </div>

    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/4.jpg">
            <img class="" src="/assets/img/gallery/maithri/4.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/5.jpg">
            <img class="" src="/assets/img/gallery/maithri/5.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/6.jpg">
            <img class="" src="/assets/img/gallery/maithri/6.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/7.jpg">
            <img class="" src="/assets/img/gallery/maithri/7.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/8.jpg">
            <img class="" src="/assets/img/gallery/maithri/8.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/9.jpg">
            <img class="" src="/assets/img/gallery/maithri/9.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/10.jpg">
            <img class="" src="/assets/img/gallery/maithri/10.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/11.jpg">
            <img class="" src="/assets/img/gallery/maithri/11.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/12.jpg">
            <img class="" src="/assets/img/gallery/maithri/12.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/13.jpg">
            <img class="" src="/assets/img/gallery/maithri/13.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/14.jpg">
            <img class="" src="/assets/img/gallery/maithri/14.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/15.jpg">
            <img class="" src="/assets/img/gallery/maithri/15.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/16.jpg">
            <img class="" src="/assets/img/gallery/maithri/16.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/17.jpg">
            <img class="" src="/assets/img/gallery/maithri/17.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/18.jpg">
            <img class="" src="/assets/img/gallery/maithri/18.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/19.jpg">
            <img class="" src="/assets/img/gallery/maithri/19.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/20.jpg">
            <img class="" src="/assets/img/gallery/maithri/20.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/21.jpg">
            <img class="" src="/assets/img/gallery/maithri/21.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/22.jpg">
            <img class="" src="/assets/img/gallery/maithri/22.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/23.jpg">
            <img class="" src="/assets/img/gallery/maithri/23.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/24.jpg">
            <img class="" src="/assets/img/gallery/maithri/24.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/25.jpg">
            <img class="" src="/assets/img/gallery/maithri/25.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/26.jpg">
            <img class="" src="/assets/img/gallery/maithri/26.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/27.jpg">
            <img class="" src="/assets/img/gallery/maithri/27.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/28.jpg">
            <img class="" src="/assets/img/gallery/maithri/28.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/29.jpg">
            <img class="" src="/assets/img/gallery/maithri/29.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/30.jpg">
            <img class="" src="/assets/img/gallery/maithri/30.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/31.jpg">
            <img class="" src="/assets/img/gallery/maithri/31.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/32.jpg">
            <img class="" src="/assets/img/gallery/maithri/32.jpg" alt="image" loading="lazy" />

        </a>
    </div>

    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/34.jpg">
            <img class="" src="/assets/img/gallery/maithri/34.jpg" alt="image" loading="lazy" />

        </a>
    </div>

    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/36.jpg">
            <img class="" src="/assets/img/gallery/maithri/36.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/37.jpg">
            <img class="" src="/assets/img/gallery/maithri/37.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/38.jpg">
            <img class="" src="/assets/img/gallery/maithri/38.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/39.jpg">
            <img class="" src="/assets/img/gallery/maithri/39.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/40.jpg">
            <img class="" src="/assets/img/gallery/maithri/40.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/41.jpg">
            <img class="" src="/assets/img/gallery/maithri/41.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/42.jpg">
            <img class="" src="/assets/img/gallery/maithri/42.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/43.jpg">
            <img class="" src="/assets/img/gallery/maithri/43.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/44.jpg">
            <img class="" src="/assets/img/gallery/maithri/44.jpg" alt="image" loading="lazy" />

        </a>
    </div>

    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/46.jpg">
            <img class="" src="/assets/img/gallery/maithri/46.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/47.jpg">
            <img class="" src="/assets/img/gallery/maithri/47.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/48.jpg">
            <img class="" src="/assets/img/gallery/maithri/48.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/49.jpg">
            <img class="" src="/assets/img/gallery/maithri/49.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/50.jpg">
            <img class="" src="/assets/img/gallery/maithri/50.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/51.jpg">
            <img class="" src="/assets/img/gallery/maithri/51.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/52.jpg">
            <img class="" src="/assets/img/gallery/maithri/52.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/53.jpg">
            <img class="" src="/assets/img/gallery/maithri/53.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/54.jpg">
            <img class="" src="/assets/img/gallery/maithri/54.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/55.jpg">
            <img class="" src="/assets/img/gallery/maithri/55.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-12"></div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/56.jpg">
            <img class="" src="/assets/img/gallery/maithri/56.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/57.jpg">
            <img class="" src="/assets/img/gallery/maithri/57.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/58.jpg">
            <img class="" src="/assets/img/gallery/maithri/58.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/59.jpg">
            <img class="" src="/assets/img/gallery/maithri/59.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/60.jpg">
            <img class="" src="/assets/img/gallery/maithri/60.jpg" alt="image" loading="lazy" />

        </a>
    </div>
    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 ">
        <a class="spotlight" href="/assets/img/gallery/maithri/61.jpg">
            <img class="" src="/assets/img/gallery/maithri/61.jpg" alt="image" loading="lazy" />

        </a>
    </div>
</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Maithri2018
