import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
// import Subramanyam from '../TrainingAndPlacements/tpd/SUBRAMANYAM.jpg'
import Subramanyam from '../TrainingAndPlacements/tpd/SUBRAMANYAM.jpg'
import nationalaward from '../TrainingAndPlacements/tpd/mr.subramanyam-national-award.jpg'
import mba from '../TrainingAndPlacements/tpd/MBA.png'
import one from '../TrainingAndPlacements/tpdPlacements/1.png'
import two from '../TrainingAndPlacements/tpdPlacements/2.png'
import three from '../TrainingAndPlacements/tpdPlacements/3.png'
import four from '../TrainingAndPlacements/tpdPlacements/4.png'
import five from '../TrainingAndPlacements/tpdPlacements/5.png'
import six from '../TrainingAndPlacements/tpdPlacements/6.png'
import seven from '../TrainingAndPlacements/tpdPlacements/7.png'
import eight from '../TrainingAndPlacements/tpdPlacements/8.png'
import nine from '../TrainingAndPlacements/tpdPlacements/9.png'
import ten from '../TrainingAndPlacements/tpdPlacements/10.png'
import eleven from '../TrainingAndPlacements/tpdPlacements/11.png'
import twelve from '../TrainingAndPlacements/tpdPlacements/12.png'
import thirteen from '../TrainingAndPlacements/tpdPlacements/13.png'
import fourteen from '../TrainingAndPlacements/tpdPlacements/14.png'
import fiveteen from '../TrainingAndPlacements/tpdPlacements/15.png'
import sixteen from '../TrainingAndPlacements/tpdPlacements/16.png'


import PlacementsLeftTab from './PlacementsLeftTab';

const TPD = () => {
  return (
    <div>
      <Navbar />
      <div className="graduate-admission pt-lg-5 pt-4 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
              <PlacementsLeftTab />
            </div>
            <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
              <div className="how-to-apply">
                <h2> About TPD
                </h2>
                <p>The training and placement department at SWEC strives to bridge the gap between the Institute and the Industry by providing an all-round development of the personality of the students with knowledge, skills and competence essential to succeed in their career.
                </p>
                <p>The department provides personal and career-oriented support to students by conducting several career oriented workshops, soft skill training and campus recruitment training. The effectiveness of these training is evident from the consistent placement results. Several companies have been visiting the college every year to tap and recruit the young talented students. There are many students who pursue higher studies and get admission into prestigious institutions in India and overseas.
                </p>
                <p>The placement cell is well equipped with a conference hall, 4 interview rooms, 4 computer labs and internet connectivity to conduct recruitment drives.
                </p>
                <p>The placement cell is well equipped with a conference hall, 4 interview rooms, 4 computer labs and internet connectivity to conduct recruitment drives.
                </p>
                <p>The objective of the training department is to train students in soft skills, develop their personality and prepare them for the corporate world and help them succeed in their respective field. Training builds the confidence of the students and improves their ability to perform their job by demonstrating their ability to comprehend and deliver results. It helps them blend into the new environment with ease, cope with peer pressure and be a productive resource.
                </p>
                <div className='pt-2'>
                  <h5>For any specific assistance, please contact
                  </h5>
                  <div className='d-flex pt-3 submit'>
                    <img src={Subramanyam} alt='Subramanyam' loading='lazy' className='img-fluid' />
                    <div className='ms-3 SubramanyamText'>
                      <h6 className='text-red'>Mr D Subramanyam</h6>
                      <p className='pt-0'><strong>Head of Placements
                      </strong></p>
                      <p className='pt-0'><strong>Mobile number - +91 9700009977, 9505977356
                      </strong></p>
                      <p className='pt-0 text-secondary'><strong><a href='' target='_blank'>Email id - swectpd@gmail.com
                      </a></strong></p>
                    </div>
                  </div>
                  <div className='apply-list submit'>
                    <p className=''>The Placement department is headed by Mr D Subramanyam and he has been instrumental in providing a stepping stone to the student’s career with excellent placements in reputed companies. He has signed MOU with companies like CSC, Oracle, SAP and Tech Mahindra to enhance the technical skills of the students. He has 12 years of experience in IT and is also the secretary of the placement association in Hyderabad.</p>
                    <ul>
                      <li>
                        <strong><i class="fa fa-star text-red"></i>  Mr D Subramanyam, TPO has been awarded the National award for impact creators in Engineering education.</strong>
                      </li>
                    </ul>
                    {/* <img height="500" width="400" src={nationalaward} alt='nation-award' className='img-fluid pt-2 pb-3' loading='lazy' /> */}
                    <div class="spotlight-group">
                      <div class="row">
                        <div class="col-md-6 mb-3 mb-lg-4">
                          <a class="spotlight" href={nationalaward}>
                            <img src={nationalaward} alt="Image" class="img-responsive center-block" /></a>
                        </div>

                      </div>
                    </div>

                  </div>
                  <div className=' pt-3 '>
                    <img src={mba} loading='lazy' alt='mba' className='img-fluid' />
                  </div>
                  <p className='pt-2'>
                    The training department is headed by Mrs. M. Sandhya who is a Soft
                    Skills Trainer and Coach. She has completed her MBA from the
                    University of Pune and has a total of 22 years of experience with 13
                    years of industrial experience in Sales and Marketing. She is
                    passionate about training and her aim is to help every individual
                    overcome the communication barriers that hinder growth and personal
                    effectiveness in this competitive and professional world. She is
                    passionate about her work and is totally committed to the learning and
                    development of her students.
                  </p>

                </div>
                <div className='row align-items-center pt-2 submit'>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={one} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={two} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={three} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={four} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={five} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={six} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={seven} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={eight} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={nine} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={ten} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={eleven} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={twelve} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={thirteen} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={fourteen} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={fiveteen} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                  <div className='col-6 col-lg-4 col-xl-3 pbottom15 mb-2'>
                    <div className='b-all'>
                      <img src={sixteen} loading='lazy' alt='placements' className='img-fluid center-block' />
                    </div>
                  </div>
                </div>
                <h5>
                  Higher Education
                </h5>
                <p className='pb-2'>The training department encourages every student to acquire practical skills in their chosen discipline along with interpersonal skills. The communication skills are enhanced through facilities like Phonetics and Communication skill labs. Apart from these resources, external trainers train and enhance the soft skills of the students.
                </p>
                <h5>
                  MOU's
                </h5>
                <div class="detgails-right-content mt-3">
                  <div class="related-download bg-gray">
                    <ul>
                      <li>
                        <a href="/assets/documents/Study orbit MoU-2021-2024.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  Study orbit MoU 2021-2024

                        </a>
                      </li>
                      <li>
                        <a href="/assets/documents/Manya Princeton review MoU 2021-2024.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>Manya Princeton review MoU 2021-2024  </a>
                      </li>

                      <li>
                        <a href="/assets/documents/Pyramid Education & Training -June 2019-21-CRT.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>Pyramid Education & Training - June 2019-21 CRT  </a>
                      </li>

                      <li>
                        <a href="/assets/documents/Pyramid Education & Training - Feb 2021 Open Ended-CRT.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Pyramid Education & Training - Feb 2021 Open Ended-CRT </a>
                      </li>
                      <li>
                        <a href="/assets/documents/TexasREview MOU2020-2023.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Texas Review MOU 2020-2023 </a>
                      </li>
                      <li>
                        <a href="/assets/documents/Mskools MoU for Career counseling 2019 -1.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Mskools MoU for Career counseling 2019 </a>
                      </li>
                      <li>
                        <a href="/assets/documents/Education Matters  MoU 1.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  Education Matters </a>
                      </li>
                      <li>
                        <a href="/assets/documents/Impact Education - MBA I MoU Pg1 (1).pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  Impact Education - MBA </a>
                      </li>
                      <li>
                        <a href="/assets/documents/Manya Princeton Review MOU -1.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Manya Princeton Review </a>
                      </li>
                      <li>
                        <a href="/assets/documents/COIGN.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> COIGN </a>
                      </li>
                      <li>
                        <a href="/assets/documents/CSC.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> CSC </a>
                      </li>
                      <li>
                        <a href="/assets/documents/CYIENT.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> CYIENT </a>
                      </li>
                      <li>
                        <a href="/assets/documents/MoU -SWEC- Tech Mahindra_15-04-2015.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Tech Mahindra </a>
                      </li>
                      {/* <li>
                        <a href="/assets/"  target="_blank"><i class="fa-solid fa-file-pdf"></i>   PROMETRIC </a>
                      </li> */}
                      <li>
                        <a href="/assets/documents/PYRAMID.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  PYRAMID </a>
                      </li>
                      <li>
                        <a href="/assets/documents/TALENTIO.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> TALENTIO </a>
                      </li>
                      <li>
                        <a href="/assets/documents/VALMIKI.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> VALMIKI </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <h5>
                  College Placement Umbrella (CPU) at University level
                </h5>
                <div class="detgails-right-content mt-3">
                  <div class="related-download bg-gray">
                    <ul>
                      <li>
                        <a href="/assets/documents/College Placement Umbrella (CPU) at University level.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  CPU

                        </a>
                      </li>


                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default TPD
