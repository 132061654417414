import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const FirstYearOrientationDay2019 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>First Year Orientation Day 2019
                                    </h2>
                                    <p className='pb-2'>SWEC organized the Orientation Day for the first year students and parents on 2nd August 2019. The program began with the lamp lighting ceremony followed by the Principal’s address. The address primarily included introducing the college, the Departments, the HOD’s and the yearly schedule. The college highlights and achievements were also mentioned. The HOD’s also gave the parents and students an overview about the department and their activities. The Orientation day marked the beginning of a week-long training session for the first year students.
                                    </p>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/4Oct_1.jpg">
                                                    <img src="/assets/img/latest-events/4Oct_1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/4Oct_2.jpg">
                                                    <img src="/assets/img/latest-events/4Oct_2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/4Oct_3.jpg">
                                                    <img src="/assets/img/latest-events/4Oct_3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/First year Orientation Day -2 2019.jpg">
                                                    <img src="/assets/img/latest-events/First year Orientation Day -2 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/First year Orientation Day 2019.jpg">
                                                    <img src="/assets/img/latest-events/First year Orientation Day 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/latest-events/First year Orientation Day-1 2019.jpg">
                                                    <img src="/assets/img/latest-events/First year Orientation Day-1 2019.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default FirstYearOrientationDay2019
