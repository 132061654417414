import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';

const ItPO = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department of Information Technology (IT)
                                </h2>
                                <div className=''>
                                    <div class="">
                                        <h6 className='pt-3'>Program Outcomes (Pos)</h6>
                                        <div class="">
                                            <p>The program gives the students the ability to

                                            </p>
                                            <div class="apply-list ">

                                                <ul class="pt-2">
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Apply knowledge of mathematics, science, and engineering.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Design and conduct experiments, as well as analyze and interpret data.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Design a system component or process to meet desired needs.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Function on multi-disciplinary teams.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Identify, formulate, and solve engineering problems.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Understand the impact of engineering solutions in a global, economic, environmental, and societal context.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Speak of contemporary issues.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Apply mathematical foundations, algorithmic principles, computer science theory in the modelling design of computer-based systems.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                        Apply design and development principles in the construction of software systems of varying complexity.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="">
                                            <h6 className='pt-2'>Program Specific Outcomes (Pso)

                                            </h6>
                                            <div class="apply-list ">

                                                <ul class="pt-2">
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PSO-I :</strong> Analyse and develop software systems related to algorithms, software engineering, web applications, mobile computing, data base management and networking to solve real world problems.

                                                    </li>
                                                    <li><i class="ri-checkbox-circle-line"></i>
                                                    <strong>PSO-II : </strong> Detailed knowledge with the coexisting issues, strong skills in learning new programming environment and thereby innovate new ideas and solutions to existing problems.

                                                    </li>


                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ItPO
