 import React from 'react'
 import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
 import EceleftTab from './EceleftTab';

import acc from '../../TrainingAndPlacements/20-21/accenture.png'
import cts from '../../TrainingAndPlacements/20-21/cts.png'
import dxc from '../../TrainingAndPlacements/20-21/dxc.png'
import cap from '../../TrainingAndPlacements/20-21/cg_logo.png'
import legato from '../../TrainingAndPlacements/20-21/legato.png'
import hexaware from '../../TrainingAndPlacements/20-21/hexaware.png'
import cgi from '../../TrainingAndPlacements/20-21/cgi.png'
import TCS from '../../TrainingAndPlacements/20-21/TCS.png'
import nttdata from '../../TrainingAndPlacements/20-21/ntt-data.png'
import wipro from '../../TrainingAndPlacements/20-21/wipro.png'
import ibm from '../../TrainingAndPlacements/20-21/ibm.png'
import societe from '../../TrainingAndPlacements/20-21/societe.png'
import SAP from '../../TrainingAndPlacements/20-21/SAP-LABS.png'
import tech from '../../TrainingAndPlacements/20-21/techm_logotechm_logotechm_logo.png'
import amazon from '../../TrainingAndPlacements/20-21/amazon.png'
import INFOSYS from '../../TrainingAndPlacements/20-21/INFOSYS.png'
import SYNTEL from '../../TrainingAndPlacements/20-21/SYNTEL.png'
import hcl from '../../TrainingAndPlacements/20-21/HCL.png'
import maq from '../../TrainingAndPlacements/20-21/maq.png'
import mtx from '../../TrainingAndPlacements/20-21/mtx.png'
import dell from '../../TrainingAndPlacements/20-21/dell-tech.png'
import savantis from '../../TrainingAndPlacements/20-21/savantis.png'
import invesco from '../../TrainingAndPlacements/20-21/invesco.png'
import TELEPERFORMANCE from '../../TrainingAndPlacements/20-21/TELEPERFORMANCE.png'
import Virtusa from '../../TrainingAndPlacements/20-21/Virtusa.png'
import tsystems from '../../TrainingAndPlacements/20-21/t-systems.png'
import subex from '../../TrainingAndPlacements/20-21/subex.png'
import deloitte from '../../TrainingAndPlacements/20-21/deloitte.png'
import enhisecure from '../../TrainingAndPlacements/20-21/enhisecure.png'
import ZenQ from '../../TrainingAndPlacements/20-21/ZenQ.png'
import atT from '../../TrainingAndPlacements/20-21/atT.png'
import BOSCH from '../../TrainingAndPlacements/20-21/BOSCH.png'
import ramGroup from '../../TrainingAndPlacements/20-21/ramgroup.jpg'
import mindtree from '../../TrainingAndPlacements/20-21/mindtree.png'
import GoogleMaps from '../../TrainingAndPlacements/20-21/GoogleMaps.png'
import aliens from '../../TrainingAndPlacements/20-21/aliens.png'
import ulearn from '../../TrainingAndPlacements/20-21/ulearn.png'
import zon from '../../TrainingAndPlacements/20-21/zon.png'
import cts2 from '../../TrainingAndPlacements/20-21/cts2.png'
import cyient from '../../TrainingAndPlacements/20-21/cyient.png'
import intelenet from '../../TrainingAndPlacements/20-21/intelenet.png'
import genpact from '../../TrainingAndPlacements/20-21/genpact.png'
import qspider from '../../TrainingAndPlacements/20-21/qspider.png'
import c3i from '../../TrainingAndPlacements/20-21/c3i.png'
import hitachi from '../../TrainingAndPlacements/20-21/hitachi.png'
import VISTEX from '../../TrainingAndPlacements/20-21/VISTEX.png'
import uxReactor from '../../TrainingAndPlacements/20-21/uxReactor.png'
import MPHASIS from '../../TrainingAndPlacements/20-21/MPHASIS.png'
import oracle from '../../TrainingAndPlacements/20-21/oracle.png'
import DHlogo from '../../TrainingAndPlacements/20-21/DHlogo.png'
import kony from '../../TrainingAndPlacements/20-21/kony.png'
import dq from '../../TrainingAndPlacements/20-21/dq.png'
import hgs from '../../TrainingAndPlacements/20-21/hgs.png'
import incessant from '../../TrainingAndPlacements/20-21/incessant.png'
import hp from '../../TrainingAndPlacements/20-21/hp.png'
import muSigma from '../../TrainingAndPlacements/20-21/muSigma.png'
import EXCERS from '../../TrainingAndPlacements/20-21/EXCERS.png'
import INRYTHM from '../../TrainingAndPlacements/20-21/INRYTHM.png'
import SERCO from '../../TrainingAndPlacements/20-21/SERCO.png'
import rigalix from '../../TrainingAndPlacements/20-21/rigalix.png'
import adp from '../../TrainingAndPlacements/20-21/adp.png'
import Cadence from '../../TrainingAndPlacements/20-21/adp.png'
import justDial from '../../TrainingAndPlacements/20-21/justDial.png'
import google from '../../TrainingAndPlacements/20-21/google.jfif'
import HSBC from '../../TrainingAndPlacements/20-21/HSBC.png'
import landt from '../../TrainingAndPlacements/20-21/landt.png'
import lg from '../../TrainingAndPlacements/20-21/lg.png'
import MAQSoftware from '../../TrainingAndPlacements/20-21/MAQSoftware.png'
import PORTWARE from '../../TrainingAndPlacements/20-21/PORTWARE.png'
import NEKKANTISYSTEMS from '../../TrainingAndPlacements/20-21/NEKKANTISYSTEMS.png'
import TOSHIBA from '../../TrainingAndPlacements/20-21/TOSHIBA.png'
import ACROPETAL from '../../TrainingAndPlacements/20-21/ACROPETAL.png'
import CSSCORP from '../../TrainingAndPlacements/20-21/CSSCORP.png'
import FACE from '../../TrainingAndPlacements/20-21/CSSCORP.png'
import ICICI from '../../TrainingAndPlacements/20-21/ICICI.png'
import infotech from '../../TrainingAndPlacements/20-21/infotech.png'
import OSMOSYS from '../../TrainingAndPlacements/20-21/OSMOSYS.png'
import microsoft from '../../TrainingAndPlacements/20-21/microsoft.png'
import SUTHERLAND from '../../TrainingAndPlacements/20-21/SUTHERLAND.png'
import SPCapitalIQ from '../../TrainingAndPlacements/20-21/SPCapitalIQ.png'
import Tripod from '../../TrainingAndPlacements/20-21/Tripod.png'
import PENGROUP from '../../TrainingAndPlacements/20-21/PENGROUP.png'
import SITEL from '../../TrainingAndPlacements/20-21/SITEL.png'
import SATYAM from '../../TrainingAndPlacements/20-21/SATYAM.png'
import XTREAMIT from '../../TrainingAndPlacements/20-21/XTREAMIT.png'
import ITPLEXUS from '../../TrainingAndPlacements/20-21/ITPLEXUS.png'
import ACTIVEBRAINS from '../../TrainingAndPlacements/20-21/ACTIVEBRAINS.png'
import GCAConsulting from '../../TrainingAndPlacements/20-21/GCAConsulting.png'
import SOCLogicIndia from '../../TrainingAndPlacements/20-21/SOCLogicIndia.png'
import SRSCONSULTING from '../../TrainingAndPlacements/20-21/SRSCONSULTING.png'
import TECRA from '../../TrainingAndPlacements/20-21/TECRA.png'
import VODAFONE from '../../TrainingAndPlacements/20-21/VODAFONE.png'
import StratagemSolutions from '../../TrainingAndPlacements/20-21/StratagemSolutions.png'
import VALUEMOMENTUM from '../../TrainingAndPlacements/20-21/VALUEMOMENTUM.png'
import InfobrainTechnologies from '../../TrainingAndPlacements/20-21/InfobrainTechnologies.png'
import cisco from './library/cisco.png'
import bsnl from './library/bsnl.png'
import inentirety from './library/inentirety.png'
import task from './library/task.png'
import prometric from './library/prometric.png'
import csc from './library/prometric.png'
import talentio from './library/talentio.png'
import valmiki from './library/valmiki.png'
import coign from './library/coign.png'

const EceMous = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department MOU'S
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td><strong>S.No</strong> </td>
                                                <td><strong>Name of the Industry</strong> </td>
                                                <td><strong>MOU Date </strong></td>
                                                <td><strong>Benefits </strong></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>1</span></p>
                                                </td>
                                                <td>
                                                    <p><span>CISCO Networking Academy </span>
                                                    
                                                        
                                                        <span><img src={cisco} alt="company logo" /></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>18/11/2020</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        
                                                            <span>
                                                                Establishment of IoT lab in the
                                                                College Premises
                                                            </span>
                                                        
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><b><span>2</span></b></p>
                                                </td>
                                                <td>
                                                    <p><span>Indian Space Research Organization </span><span><img width="38" height="37"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>01/10/2020</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Online Courses on Satellite Related
                                                            Technologies
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>3</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            IIT
                                                            Bombay, Spoken Tutorial
                                                        </span><span></span>
                                                    </p>
                                                    <p><span><img width="101" height="29"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>14/07/2020</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            To
                                                            train Students on Basic Computer Skills, Software, and Core Areas of ECE
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>4</span></p>
                                                </td>
                                                <td>
                                                    <p><span>BSNL, Hyderabad </span><span><img src={bsnl} alt="company Logo"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>12/03/2020</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            To Conduct <span zeum4c2="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="spelling" class="LI ng">Telecomm</span> Technical Programs
                                                            for Students
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>5</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            CITD
                                                        </span><span>&nbsp;</span><span><img width="29" height="27" alt="CITD Recruitment 2020 - Apply for 10 Stipendiary Trainee Posts!"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>08/06/2014</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Skill
                                                            Enhancement
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>6</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Nano Scientific Research Center Pvt.
                                                            Ltd, Hyderabad
                                                        </span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span><img width="77" height="35" alt="Nano Scientific Research Centre | LinkedIn"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>01/02/2020</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            To Conduct Workshops, Projects for
                                                            Students
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>7</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            PanTech
                                                            ProEd Pvt.Ltd, Hyderabad
                                                        </span>
                                                    </p>
                                                    <p><span><img width="104" height="42"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>03/12/2019</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            To
                                                            Provide Internships, and Guidance in doing projects for Students, Conduct
                                                            Seminars on recent developments
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>8</span></p>
                                                </td>
                                                <td>
                                                    <p><span>PGP Electronics </span><span><img width="190" height="29"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>11/08/2018</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            To Provide Industry Visits, Expert
                                                            Lectures, and In-plant Training during Summer Vacation
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>9</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Brian
                                                            O Vision&nbsp;
                                                        </span><span>&nbsp;</span><span><img width="74" height="34"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>0508/2020</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            To
                                                            Conduct Workshops, Guest Lectures and Consultancy Work
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>10</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Silicon Research Labs Pvt. Ltd</span><span></span></p>
                                                    <p><span><img width="74" height="30"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>14/12/2017</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Organizing Seminars, Workshops,
                                                            Refresher Courses for Students
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>11</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            SPNS
                                                            Technologies Pvt. Ltd
                                                        </span>
                                                    </p>
                                                    <p><span><img width="101" height="41"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>07/08/2018</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Providing
                                                            Internships for Students and conducting Seminars, Workshops
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><b><span>12</span></b></p>
                                                </td>
                                                <td>
                                                    <p><span>Mech Pro Technologies </span><span><img width="44" height="44"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>18/07/2019</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Consultancy Work, Training Students
                                                            and Staff
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>13</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Triad
                                                            Techno services
                                                        </span><span><img width="110" height="30"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>15/09/2018</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Conducting
                                                            Advance Level Courses, Guest Lectures
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>14</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Vision Krest Embedded Technologies
                                                            Pvt. Ltd&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span><span><img width="58" height="24"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>12/02/2018</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Providing Internships for the
                                                            Students, and Conducting Workshops
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>15</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            InEntirety
                                                            Innovations
                                                        </span>
                                                    </p>
                                                    <p><span><img src={inentirety}  alt="InEntirety Innovations in Ameerpet, Hyderabad - Justdial"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>12/04/2018</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Training
                                                            Students and Staff
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>16</span></p>
                                                </td>
                                                <td>
                                                    <p><span>YVM Technologies </span><span ><img width="114" height="17"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>21/09/2019</span></p>
                                                </td>
                                                <td>
                                                    <p><span>To Provide Internships, FDP’s</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>17</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            TASK
                                                        </span><span>&nbsp;</span><span><img src={task} alt="company logo"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>10/06/2014</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Oracle
                                                            Training, CRT Training, Skill Enhancement
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>18</span></p>
                                                </td>
                                                <td>
                                                    <p><span>CYIENT </span><span><img src={cyient} alt="company logo"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>04/12/2017</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Training Students</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>19</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            ZON
                                                            Innovative IT Solutions Pvt Ltd.
                                                        </span><span><img src={zon} alt="company logo"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>16/10/2017</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Campus
                                                            Recruitment Training
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>20</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Pyramid&nbsp;&nbsp;&nbsp; </span><span><img width="53" height="20" alt="Overseas Education Consultants Ahmedabad | Pyramid Consultants"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>24/07/2017</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Campus Recruitment Training</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>21</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Prometric
                                                        </span><span><img src={prometric} alt="company logo"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>20/02/2017</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Campus
                                                            Recruitment Training
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>22</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Aspiring Minds </span><span><img width="74" height="26"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>18/08/2018</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Skill Assessment Program</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>23</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Manya
                                                            Princeton review
                                                        </span><span></span>
                                                    </p>
                                                    <p><span><img width="133" height="22"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>18/11/2019</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Guidance
                                                            on University Selection and Application Process
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>24</span></p>
                                                </td>
                                                <td>
                                                    <p><span>MSKools&nbsp; </span><span><img width="119" height="24"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>08/08/2019</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Guidance on University Selection and
                                                            Application Process
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>25</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Manhattan
                                                            Review&nbsp;
                                                        </span><span><img width="100" height="25"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>10/11/2019</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Guidance
                                                            on University Selection and Application Process
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>26</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            CSC&nbsp;
                                                        </span><span><img src={csc} alt="company logo"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>15/07/2016</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Campus Recruitment Training</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>27</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Talentio&nbsp;&nbsp; </span><span><img src={talentio} alt="company logo"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>17/03/2016</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Training
                                                            Students
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>28</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Tech Mahindra </span><span><img src={tech} alt="company logo"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>15/04/2015</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Training Students</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>29</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Valmiki&nbsp;&nbsp; </span><span><img src={valmiki} alt="company logo"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>03/04/2015</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Guidance
                                                            on University Selection and Application Process
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>30</span></p>
                                                </td>
                                                <td>
                                                    <p><span>COGIN </span><span>&nbsp;&nbsp;</span><span><img src={coign} alt="company logo"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>10/07/2014</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Training Students</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>31</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Education
                                                            Matters
                                                        </span><span>&nbsp;</span><span><img width="101" height="33"/></span><span></span>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p><span>04/11/2019</span></p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <span>
                                                            Guidance
                                                            on University Selection and Application Process
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p><span>32</span></p>
                                                </td>
                                                <td>
                                                    <p><span>SOS </span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span><img width="78" height="35" alt="SOS Group of Companies | LinkedIn"/></span><span></span></p>
                                                </td>
                                                <td>
                                                    <p><span>19/07/2015</span></p>
                                                </td>
                                                <td>
                                                    <p><span>Training Students</span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default EceMous
