import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import FooterTab from './FooterTab'



const IntellectualPropertyRights = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        {/* <div className='col-lg-4 pe-lg-5'>
                            <FooterTab />
                        </div> */}
                        <div className="col-lg-12 mx-auto">
                            <div className="how-to-apply">
                                <h2>Intellectual Property Rights (IPR) Cell
                                </h2>
                            </div>
                            <div className='pt-2 pt-lg-3'>

                                <div class="how-to-apply detgails-right-content ">

                                    <p>IPR plays an important role in providing a competitive edge to any Institution. The tangible assets like inventions, designs, software, brand name and other creative & innovative ideas are more valuable than physical assets. Intellectual Property Rights are exclusive legal rights over creations of the mind. IPR give the proprietary rights to exploit intellectual creation of the mind. IPRs are essential to foster innovation and to encourage inventiveness in various areas of research and development. It also contributes to ensure profitability and better service to the society. SWEC I.P.R. Cell is governed by the University Intellectual Property Rights Policy which was established in April 2014.

                                    </p>
                                    <h6 className='pt-3'>Importance of IPR
                                    </h6>
                                    <p>It is necessary to protect the Intellectual property in order to enable organizations to earn recognition or financial benefits. Recently, IPR has become a central issue in the developed and developing countries. The rationale for this IPR lies in the need to create awareness about the importance of IPRs as a marketable financial asset and economic tool among the researchers, faculty and students of the College.
                                    </p>
                                    <h6 className='pt-3'>OBJECTIVES
                                    </h6>
                                    <p><strong>Training, Awareness and Motivation</strong></p>
                                    <div class="apply-list">

                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>To create awareness about IPR within the faculty members and students.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>To impart training on future endeavors regarding patent filing processes.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>To conduct professional workshops, seminars and training courses on IPR.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>To motivate innovators come with new ideas and help them to protect the innovation.
                                            </li>
                                        </ul>
                                    </div>
                                    <h6 className='pt-3'>IPR POLICY
                                    </h6>
                                    <p>Academic and Research Institutions are concerned with successfully managing projects and research initiatives and the protection of their IP Rights which will benefit all stake holders.
                                    </p>
                                    <div class="apply-list border-0">
                                        <h6 className='pt-3'>SCOPE OF THE POLICY
                                        </h6>
                                        <ul class="pt-2">
                                            <li><i class="ri-checkbox-circle-line"></i>
                                            IPR cell shall guide and help the faculty members and students of SWEC in patentability assessment and to apply for various IPRs such as Invention(s), Designs, Integrated Circuit Layouts and other creative works.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                            The faculty members/students desirous of filing a patent or for any other IPR application would be given the necessary advice and guidance by the IPR cell.
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>
                                            The IPR Cell committee for approval would consist of: 
                                            </li>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <strong>1.&nbsp;Dr. U&nbsp;Padmavathi</strong>
                                                </div>
                                                <div class="col-md-6">
                                                    <strong class="text-primary"> Convener</strong>
                                                </div>
                                                <div class="col-md-6">
                                                    <strong>2.&nbsp;Mr.K&nbsp;Tulasi Ram</strong>
                                                </div>
                                                <div class="col-md-6">
                                                    <strong class="text-primary"> Member</strong>
                                                </div>                                            
                                                <div class="col-md-6">
                                                    <strong>3.&nbsp;Dr. D&nbsp;Padmavathi</strong>
                                                </div>
                                                <div class="col-md-6">
                                                    <strong class="text-primary"> Member</strong>
                                                </div>
                                                <div class="col-md-6">
                                                    <strong>4.&nbsp;Mr. K Siva&nbsp;nagi Reddy</strong>
                                                </div>
                                                <div class="col-md-6">
                                                    <strong class="text-primary">Member</strong>
                                                </div>
                                                <div class="col-md-6">
                                                    <strong>5.&nbsp;Mrs. E&nbsp;Krishnaveni Reddy</strong>
                                                </div>
                                                <div class="col-md-6">
                                                    <strong class="text-primary">Member</strong>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default IntellectualPropertyRights
