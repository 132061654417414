import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const FlashMob2018 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'> Flash mob

                                    </h2>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">

                                        <div class="row">
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/flashmob/ECE flash mob 2018-2.jpg">
                                                    <img class="" src="/assets/img/gallery/flashmob/ECE flash mob 2018-2.jpg" loading="lazy" alt="gallery image" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/flashmob/EEE flash mob 2018 -5.jpg">
                                                    <img class="" src="/assets/img/gallery/flashmob/EEE flash mob 2018 -5.jpg" loading="lazy" alt="gallery image" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/flashmob/EIE flash mob 2018 - 4.jpg">
                                                    <img class="" src="/assets/img/gallery/flashmob/EIE flash mob 2018 - 4.jpg" loading="lazy" alt="gallery image" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/flashmob/Faculty flash mob - 2.jpg">
                                                    <img class="" src="/assets/img/gallery/flashmob/Faculty flash mob - 2.jpg" loading="lazy" alt="gallery image" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/flashmob/Faculty flash mob 2018.jpg">
                                                    <img class="" src="/assets/img/gallery/flashmob/Faculty flash mob 2018.jpg" loading="lazy" alt="gallery image" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/flashmob/MBA students flash mob 2018-1.jpg">
                                                    <img class="" src="/assets/img/gallery/flashmob/MBA students flash mob 2018-1.jpg" loading="lazy" alt="gallery image" />
                                                </a>
                                            </div>

                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/flashmob/Faculty flash mob 2018-5.jpg">
                                                    <img class="" src="/assets/img/gallery/flashmob/Faculty flash mob 2018-5.jpg" loading="lazy" alt="gallery image" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/flashmob/FAculty flash mob 2018-3.jpg">
                                                    <img class="" src="/assets/img/gallery/flashmob/FAculty flash mob 2018-3.jpg" loading="lazy" alt="gallery image" />
                                                </a>
                                            </div>
                                           
                                            <div class="col-md-6 col-lg-4 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/flashmob/Faculty flash mob 2018-4.jpg">
                                                    <img class="" src="/assets/img/gallery/flashmob/Faculty flash mob 2018-4.jpg" loading="lazy" alt="gallery image" />
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default FlashMob2018
