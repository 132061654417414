import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import ITLeftTab from './ITLeftTab';
import hod from './ItImages/hod/hod.jpg'

const ITHOD = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <ITLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>HOD Profile
                                </h2>
                                <img src="/assets/img/it-hod.jpeg" className='img-fluid pb-2' alt='it-hod' loading='lazy' width="300" />
                                <h6 className='pt-2'>Dr. B. Narendra Kumar
                                </h6>
                                <p className='mb-0'><strong className='text-red'>Designation: </strong>Professor &amp; HoD-IT</p>
                                <p className='mb-0'><strong className='text-red'>Department: </strong>INFORMATION TECHNOLOGY </p>
                                <p className='mb-0'><strong className='text-red'>Email ID : </strong>swecnarendra@gmail.com</p>
                                <p className='mb-0'><strong className='text-red'>Qualification : </strong>M.Tech, Ph.D </p>
                                <p className=''><strong className='text-red'>Total Experience: </strong>23 Years</p>
                                <p className='pb-0'>
                                    <strong>Membership of Professional Bodies: </strong><br />
                                    Life time member of Indian Society for Technical Education (ISTE)
                                </p>

                                <div class="apply-list">
                                    <h6>Membership of Professional Bodies:
                                    </h6>
                                    <ul class="pt-2">
                                        <li><i class="ri-checkbox-circle-line"></i> 6 years.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>12 Publications in various International, National Conferences/ Journals and Published 1 design patent and 2 patent grants.
                                        </li>

                                    </ul>
                                </div>
                                <p className='mb-1'><strong>Books:
                                </strong></p>
                                <p>Introduction to Machine Learning-A Perspective Approach, ISBN:978-93-90761-21-0
                                </p>
                                <div class="apply-list">
                                    <h6>Awards
                                    </h6>
                                    <ul class="pt-2">
                                        <li><i class="ri-checkbox-circle-line"></i>Received Prakruti Rakshak Award for the year 2015 from NGO,Hyderabad.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Received Best NSS Programme Officer Award for the year 2004 from District Collector, Mahabub Nagar.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Received Best NSS Programme Officer Award for the year 2014 from Andhra Pradesh State AIDS Control Society (APSACS),Hyderabad.
                                        </li>
                                    </ul>
                                </div>

                                <div class="apply-list">
                                    <h6>Subjects Taught
                                    </h6>
                                    <ul class="pt-2">

                                        <li><i class="ri-checkbox-circle-line"></i>Mathematical Foundations of Computer Science. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Design and Analysis of Algorithms. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Data Communication and Computer Networks.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Cryptography and Network Security and Information Security.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Data Mining and Machine Learning.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>OOAD and OOM thru UML</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Computer Design.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Computer Architecture and Organization.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Mobile Computing.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Python Programming.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Artificial Intelligence.</li>
                                    </ul>


                                </div>
                                <div class="apply-list">
                                    <h6>Area of Interest
                                    </h6>
                                    <p>Published 2 Patents and 1 patent is under processing
                                    </p>
                                    <ul class="pt-2">
                                        <li><i class="ri-checkbox-circle-line"></i>Information Security </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Machine Learning</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Deep Learning</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Artificial Intelligence</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Algorithms</li>

                                    </ul>


                                </div>
                                <div className='submit'>
                                    <p className='mb-1'><strong>Research Guidance
                                    </strong></p>
                                    <p>Number of M.Tech. Guided: 10

                                    </p>
                                    <p className='mb-1'><strong>FDPs/Workshops Organized:
                                    </strong></p>
                                    <p className='mb-1'>1.FDP/Workshops Organized -10
                                    </p>
                                    <p>2. Keynotes/Invited Lectures - 05
                                    </p>
                                </div>
                                <div className='pt-2 apply-list'>
                                    <h6>Additional Responsibilities:
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>Task Coordinator, Sridevi Women’s Engineering College (December 2018 - till date). </li>
                                        <li><i class="ri-checkbox-circle-line"></i>Media PRO, Sridevi Women’s Engineering College (December 2018 - till date).</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Member of NSS Advisory Committee, JNTUH, Hyderabad (2013-2015).</li>
                                        <li><i class="ri-checkbox-circle-line"></i>NSS Programme Officer, SSJ Engineering College (2003-2018).</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Training and Placement Officer, SSJ Engineering College (2015-2018).</li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default ITHOD
