import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import AboutLeftTab from '../About/AboutLeftTab';
import Sayanna from './AlumniReunion/Sayanna.png'
import pdf from './AlumniReunion/458OmbudsmanDetails.pdf'

const GiveranceCell = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row mx-auto justify-content-center align-items-center">
                       
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> Grievance Redressal form
                                </h2>
                                <div className="contacts-form">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="form-group">
                                                <label>Student/Faculty Full Name*</label>
                                                <input type="text" name="name" id="name" className="form-control" required="" data-error="Please enter your name" placeholder='Student/Faculty Full Name' />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="form-group">
                                                <label>JNTUH Roll No/ Faculty ID*</label>
                                                <input type="email" name="email" id="email" className="form-control" required="" data-error="Please enter your email" placeholder='JNTUH Roll No/ Faculty ID' />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <label for="degree" className='pb-1' >Degree*</label>
                                            <select id='degree' class="form-select form-control" aria-label="Default select example">
                                                <option selected="" disabled>Select a Degree</option>
                                                <option value="btexh">B.Tech</option>
                                                <option value="mba">MBA</option>

                                            </select>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <label for="Department" className='pb-1'>Department*</label>
                                            <select id='Department' class="form-select form-control" aria-label="Default select example">
                                                <option selected="" disabled>Select a Department</option>
                                                <option value="1">ECE</option>
                                                <option value="2">CSE</option>
                                                <option value="2">IT</option>
                                                <option value="1">EEE</option>
                                                <option value="2">MBA</option>
                                                <option value="2">S & H</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-6 col-sm-6 mt-4">
                                            <label for="year" className='pb-1' disabled>Academic Year*</label>
                                            <select id='year' class="form-select form-control" aria-label="Default select example">
                                                <option  disabled>Select a Department</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="2">3</option>
                                                <option value="1">4</option>
                                                
                                            </select>
                                        </div>

                                        <div className="col-lg-6 col-sm-6 mt-4">
                                            <div className="form-group">
                                                <label>Your email</label>
                                                <input type="email" name="email" id="email" className="form-control" required="" data-error="Please enter your email" placeholder=' Enter Your Email ID' />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>About complaint*</label>
                                                <textarea name="message" className="form-control" id="message" cols="30" rows="8" required="" placeholder="Write your complaints here"></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type='btn' className="default-btn">
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <div className='pt-4'>
                                    <h5>AICTE University Ombudsman
                                    </h5>

                                    <div className='row pt-3'>
                                        <div className='col-lg-6 pe-lg-3'>
                                            <h6 className='text-red'>Dr. Devi Singh
                                            </h6>
                                            <p>Former Director, IlM LucKnow,
                                                C/o Public Grievance Reclressal Cell
                                                All India Council for Technical Education
                                                Nelson Mandela Marg
                                                New Delhi- 110070</p>
                                            <p><strong>Email : </strong> pubgrv@aicte-india.org
                                            </p>
                                        </div>
                                        <div className='col-lg-6 ps-lg-3'>
                                            <img width={100} src={Sayanna} alt='JNTUH Ombudsman' className='img-fluid' loading='lazy' />
                                            <div className='pt-2'>
                                                <h6 className='text-red'>Dr. R.Sayanna
                                                </h6>
                                                <p>Former Vice-Chancellor Kakatiya University,
                                                    Warangal,Telangana State & Former Professor & Head,
                                                    Department of Physics Osmania University, Hyderabad.</p>

                                                <p><strong>E mail : </strong>ombudsperson@jntuh.ac.in</p>
                                            </div>
                                        </div>
                                        {/* <div class="detgails-right-content mt-4">
                                            <div class="related-download bg-gray">
                                                <ul>
                                                    <li>
                                                        <a href={pdf} target='_blank'><i class="fa-solid fa-file-pdf"></i> Mentoring </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default GiveranceCell
