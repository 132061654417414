import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import PlacementsLeftTab from './PlacementsLeftTab';
import acc from '../TrainingAndPlacements/20-21/accenture.png'
import cts from '../TrainingAndPlacements/20-21/cts.png'
import dxc from '../TrainingAndPlacements/20-21/dxc.png'
import cap from '../TrainingAndPlacements/20-21/cg_logo.png'
import legato from '../TrainingAndPlacements/20-21/legato.png'
import hexaware from '../TrainingAndPlacements/20-21/hexaware.png'
import cgi from '../TrainingAndPlacements/20-21/cgi.png'
import TCS from '../TrainingAndPlacements/20-21/TCS.png'
import nttdata from '../TrainingAndPlacements/20-21/ntt-data.png'
import wipro from '../TrainingAndPlacements/20-21/wipro.png'
import ibm from '../TrainingAndPlacements/20-21/ibm.png'
import societe from '../TrainingAndPlacements/20-21/societe.png'
import SAP from '../TrainingAndPlacements/20-21/SAP-LABS.png'
import tech from '../TrainingAndPlacements/20-21/techm_logotechm_logotechm_logo.png'
import amazon from '../TrainingAndPlacements/20-21/amazon.png'
import INFOSYS from '../TrainingAndPlacements/20-21/INFOSYS.png'
import SYNTEL from '../TrainingAndPlacements/20-21/SYNTEL.png'
import hcl from '../TrainingAndPlacements/20-21/HCL.png'
import maq from '../TrainingAndPlacements/20-21/maq.png'
import mtx from '../TrainingAndPlacements/20-21/mtx.png'
import dell from '../TrainingAndPlacements/20-21/dell-tech.png'
import savantis from '../TrainingAndPlacements/20-21/savantis.png'
import invesco from '../TrainingAndPlacements/20-21/invesco.png'
import TELEPERFORMANCE from '../TrainingAndPlacements/20-21/TELEPERFORMANCE.png'
import Virtusa from '../TrainingAndPlacements/20-21/Virtusa.png'
import tsystems from '../TrainingAndPlacements/20-21/t-systems.png'
import subex from '../TrainingAndPlacements/20-21/subex.png'
import micron from '../TrainingAndPlacements/20-21/micron.png'


const P1920 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <PlacementsLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> List of students selected for placements in the year 2019-2020
                                </h2>
                                <div>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop25">
                                            <tbody> 
                                                <tr class="bg-gray">
                                                    <td align="center" valign="middle"><strong>S.No</strong></td>
                                                    <td align="center" valign="middle"><strong>Name of the    Company</strong></td>
                                                    
                                                    <td align="center" valign="middle"><strong>&nbsp;Salary&nbsp;    L.P.A&nbsp;</strong></td>
                                                    <td align="center" valign="middle"><strong>Total</strong></td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">1</td>
                                                    <td align="center" valign="middle"><img src={micron} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>
                                                    <td align="center" valign="middle">12</td>
                                                    <td align="center" valign="middle">13</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">2</td>
                                                    <td align="center" valign="middle"><img src={cap} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.8</td>
                                                    <td align="center" valign="middle">128</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">3</td>
                                                    <td align="center" valign="middle"><img src={tech} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3</td>
                                                    <td align="center" valign="middle">14</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">4</td>
                                                    <td align="center" valign="middle"><img src={ibm} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">4.7</td>
                                                    <td align="center" valign="middle">10</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">5</td>
                                                    <td align="center" valign="middle"><img src={cts} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.8</td>
                                                    <td align="center" valign="middle">30</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">6</td>
                                                    <td align="center" valign="middle"><img src={dxc} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.6</td>
                                                    <td align="center" valign="middle">82</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">7</td>
                                                    <td align="center" valign="middle"><img src={wipro} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.6</td>
                                                    <td align="center" valign="middle">10</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">8</td>
                                                    <td align="center" valign="middle"><img src={nttdata} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.5</td>
                                                    <td align="center" valign="middle">4</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">9</td>
                                                    <td align="center" valign="middle"><img src={invesco} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">4.5</td>
                                                    <td align="center" valign="middle">13</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">10</td>
                                                    <td align="center" valign="middle"><img src={TCS} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.6</td>
                                                    <td align="center" valign="middle">19</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">11</td>
                                                    <td align="center" valign="middle"><img src={INFOSYS} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.6</td>
                                                    <td align="center" valign="middle">4</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">12</td>
                                                    <td align="center" valign="middle"><img src={SAP} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">7.5</td>
                                                    <td align="center" valign="middle">1</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">13</td>
                                                    <td align="center" valign="middle"><img src={TELEPERFORMANCE} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">2.4</td>
                                                    <td align="center" valign="middle">57</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">14</td>
                                                    <td align="center" valign="middle"><img src={cgi} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.4</td>
                                                    <td align="center" valign="middle">8</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">15</td>
                                                    <td align="center" valign="middle"><img src={amazon} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">2.5</td>
                                                    <td align="center" valign="middle">10</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">16</td>
                                                    <td align="center" valign="middle"><img src={Virtusa} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.6</td>
                                                    <td align="center" valign="middle">4</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">17</td>
                                                    <td align="center" valign="middle"><img src={tsystems} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">3.5</td>
                                                    <td align="center" valign="middle">2</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">18</td>
                                                    <td align="center" valign="middle"><img src={subex} alt="logo" class="img-responsive"  classaname="img-fluid" loading="lazy" /></td>

                                                    <td align="center" valign="middle">4</td>
                                                    <td align="center" valign="middle">2</td>
                                                </tr>

                                                <tr>
                                                    <td align="center" valign="middle">&nbsp;</td>
                                                    <td align="center" valign="middle">&nbsp;</td>
                                                    <td align="center" valign="middle"><strong>TOTAL</strong></td>
                                                    <td align="center" valign="middle"><strong>447</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default P1920
