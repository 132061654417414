import React,{ useEffect } from 'react'
import zonLogo from '../footer/zon-logo.png'
import { Link } from 'react-router-dom'

const Footer = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div>
            <footer>
                <section className="wed-hom-footer">
                    <div className="container">

                        <div className="row wed-foot-Link mx-auto justify-content-center">
                            <div className="col-lg-4 foot-tc-mar-t-o justify-content-center">
                                <h4>Departments</h4>
                                <ul>
                                    <li>
                                        <Link onclick="scrollWin()" to="/dept/cse" >CSE</Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/dept/ece/"  >ECE</Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/dept/it/"  >IT</Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/dept/eee/"  >EEE</Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/dept/mba/"  >MBA</Link>
                                    </li>

                                    <li>
                                        <Link onclick="scrollWin()" to="/dept/s-and-h/ ">S&amp;H Department</Link>
                                    </li>

                                    <li>
                                        <Link onclick="scrollWin()" to="/dept/library/">Library Department</Link>
                                    </li>

                                    <li>
                                        <Link onclick="scrollWin()" to="/training-and-placements/">Training &amp; Placement Cell</Link>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-4">
                                <h4 className='pt-4 pt-md-0 pb-md-0'>Quick Links</h4>

                                {/* <ul className="pe-1 ">
                                    <li>
                                        <Link onclick="scrollWin()" to="/terms-conditions">Terms &amp; Conditions </Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/website-policies">Website Policies</Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/r-and-d/innovation-incubation/">Incubation Center</Link>
                                    </li>
                                    <li>
                                        <a href='/assets/img/3.5.2 e-copies.pdf' target="_blank">MOU copies</a>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/reach-us">How to Reach Us</Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/feedback/">Feedback</Link>
                                    </li>

                                    <li>
                                        <Link onclick="scrollWin()" to="/locations">Locations</Link>
                                    </li>

                                    <li>
                                        <Link onclick="scrollWin()" to="/syllabus/">Syllabus</Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/time-tables/">Time Tables</Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/notifications/">Notifications</Link>
                                    </li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/extension-activities">Extension Activities</Link>
                                    </li>

                                    <li>
                                        <Link onclick="scrollWin()" to="/copyright-policy">Copyright Policy</Link>
                                    </li>

                                    <li>
                                        <Link onclick="scrollWin()" to="/contact-us/">Contact us</Link>
                                    </li>

                                </ul> */}
                                <ul>
                                    <li><Link onclick="scrollWin()" to="/syllabus/">Syllabus</Link></li>
                                    <li><Link onclick="scrollWin()" to="/time-tables/">Time Tables</Link></li>
                                    <li><Link onclick="scrollWin()" to="/notifications/">Notifications</Link></li>
                                    <li><Link onclick="scrollWin()" to="/extension-activities">Extension Activities</Link></li>
                                    <li><Link onclick="scrollWin()" to="/copyright-policy">Copyright Policy</Link></li>
                                    <li><Link onclick="scrollWin()" to="/intellectual-property-rights">Intellectual Property Rights</Link></li>
                                    <li>
                                        <Link onclick="scrollWin()" to="/contact-us/">Contact us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-4">
                                <h4 className='pt-4 pt-md-0 pb-md-0'>Get In Touch</h4>
                                {/* <ul>

                                    <li>
                                        <Link onclick="scrollWin()" to="/contact-us/">Contact us</Link>
                                    </li>

                                </ul> */}

                                <h4 className='pt-1'></h4>
                                <p>
                                    <i className="fa fa-map-marker-alt"></i>&nbsp; V.N.Pally, Near Gandipet, R.R. Dist. 500075.
                                </p>
                                {/* <p>
                                    <i className="fa fa-phone"></i>&nbsp; 08413 234333, 234056
                                </p> */}
                                <p>
                                    <i className="fa fa-envelope"></i>
                                    <a className="mailLink" href="mailto:admin@swec.ac.in">&nbsp; admin@swec.ac.in </a>
                                </p>
                                <ul className="d-flex">
                                    <p>
                                        <strong>For Admissions call :</strong> &nbsp;
                                    </p>
                                    <p>+91 - 99594 25729</p>
                                </ul>


                                <p className='col-5'>
                                    <ul className="socialMediaIcons d-flex pt-0">
                                        <li>
                                            <a href="https://www.facebook.com/sweccollege/" target="_blank">
                                                <i className="fa-brands fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li className='mx-2'>
                                            <a href="https://www.instagram.com/sweccollege/" target="_blank">
                                                <i className="fa-brands fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.Linkedin.com/company/srideviengg/" target="_blank">
                                                <i className="fa-brands fa-linkedin"></i>
                                            </a>
                                        </li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                        {/* <div className="row wed-foot-Link-1">

                            <div className="col-md-4">
                                <h4 className='pt-4 pb-3'>SUBSCRIBE NEWS LETTER</h4>
                                <form className="col s12" action="#">
                                    <div className="row">
                                        <div className="col s12 form-group">
                                            <input className="footerInput" type="text" placeholder="Subscribe" />
                                            <label className=""></label>
                                            <button className="footerBtn">Subscribe Now</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4 ps-md-5 pt-lg-0">
                                <h4 className='pt-4 pb-3'>SOCIAL MEDIA</h4>
                                <ul className="socialMediaIcons pt-0">
                                    <li>
                                        <a href="https://www.facebook.com/sweccollege/" target="_blank">
                                            <i className="fa-brands fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li className='mx-2'>
                                        <a href="https://www.instagram.com/sweccollege/" target="_blank">
                                            <i className="fa-brands fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.Linkedin.com/company/srideviengg/" target="_blank">
                                            <i className="fa-brands fa-Linkedin"></i>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div> */}
                    </div>
                </section >
            </footer >
            <div className="copyright-area">
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-lg-6 col-md-4">
                                <div className="social-content ">
                                    <p className='text-black'>Copyright © 2001-2023 SWEC. All Rights Reserved.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-8">
                                <div className="copy">
                                    <p className='text-black'>
                                        Design &amp; Developed by 
                                        <a href="http://zoninnovative.com/" target="_blank" className='text-black px-1'> <img
                                            className="img-fluid" src={zonLogo} width="15" style={{verticalalign:"sub"}} alt="Zon logo"
                                        /> Zon Innovative</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer
