import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import JntuhLefttab from './JntuhLefttab';

const Jntuh = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-5'>
                            <JntuhLefttab />
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="how-to-apply">
                                <h2> Academic Calendar for B-Tech
                                </h2>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for B-Tech 2023-24</h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a a href="https://ik.imagekit.io/ckjdgz4vmo/btech1.pdf?updatedAt=1696932483182" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for B-Tech I semester 2023-24 </a>
                                            </li>
                                            <li>
                                                <a a href="https://ik.imagekit.io/ckjdgz4vmo/btech2.pdf?updatedAt=1696932482320" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for B-Tech II semester 2023-24 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for B-Tech/B.Pharm 2023-24</h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a a href="https://ik.imagekit.io/ckjdgz4vmo/BTechBPharmIIIYEARIIISEMESTERS_231007_223559.pdf?updatedAt=1697606818977" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for B-Tech/B.Pharm III YearI & II semesters 2023-24 </a>
                                            </li>
                                            <li>
                                                <a a href="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/IV%20Year%20B.Tech%20%20Autonomous%20Academic%20Calendar.pdf?updatedAt=1700113880635" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for B-Tech/B.Pharm IV YearI & II semesters 2023-24 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for B-Tech/B.Pharm 2021-22</h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a a href="/assets/documents/ACADEMIC_CALENDAR_2021-22_for_B._TECH_._and_B_.PHARM_._II_YEAR_I_II_SEMESTERS_.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar for B-Tech/B.Pharm II YearI & II semesters 2021-22 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for B-Tech 2021-22</h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a a href="/assets/img/Revised Academic Calendar  - 13-08-2021.jpg" target="_blank"><i class="fa-solid fa-file-pdf"></i> Revised Academic Calendar 13-08-2021 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for B-Tech 2020-21</h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a a href="/assets/img/REVISED ACCADAMIC CALLENDER 2020-21.jpeg" target="_blank"><i class="fa-solid fa-file-pdf"></i>  Revised Academic Calendar 2020-21 </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for B-Tech 2019-20</h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a a href="/assets/documents/Academic Calendar B.Tech I Year 2019-20.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Academic Calendar B.Tech I Year 2019-20</a>
                                            </li>
                                            <li>
                                                <a a href="/assets/documents/Academic_Calendar B.Tech II,III,IV Year 2019-20.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>  Academic_Calendar B.Tech II,III,IV Year 2019-20</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-2 pt-lg-3'>
                                <h6>Academic Calendar for B-Tech 2018-19
                                </h6>
                                <div class="detgails-right-content mt-4">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a a href="/assets/documents/R18Btech/R18 B.Tech I Year Syllabus of EEE CSE &IT.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>B.Tech 1st year Syllabus for EEE, CSE and IT.</a>
                                            </li>
                                            <li>
                                                <a a href="/assets/documents/R18Btech/R18 B.Tech I Year Syllabus of ECE.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>B.Tech 1st year Syllabus for ECE. </a>
                                            </li>
                                            <li>
                                                <a a href="/assets/documents/R18Btech/Academic_Calendar_2018_19_for_BTech_II_III_IV_years.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> B.Tech I, II and III year academic calendar. </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Jntuh
