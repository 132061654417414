import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const TheIQPrincess2019 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2 className='pb-2'>The IQ Princess 2019

                                    </h2>
                                    <p class=""><i class="fa fa-trophy text-red"></i> <strong> M. Achuta Sowjanya Sowmya of Second year from SWEC got Crowned *Ms. SWEC-The IQ Princess 2019*</strong> </p>
                                    <p class=""><i class="fa fa-trophy text-red"></i> <strong> K Naga Ramyasree from Third year stood second in the event conducted by Organic Learning, LACE & AptitudeHelpline</strong> </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default TheIQPrincess2019
