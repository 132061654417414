import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import DepartmentTab from '../mainTags/CSEDepartmentTab';


const CSELibrary = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <DepartmentTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department Library
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td><p class="text-center"><strong>S.No</strong></p></td>
                                                <td><p><strong>Title</strong></p></td>
                                                <td><p><strong>Author</strong></p></td>
                                                <td><p><strong>Volumes</strong></p></td>
                                            </tr>
                                            <tr >
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Object oriented programming in C++</span></p>
                                                </td>
                                                <td >
                                                    <p ><span><span zeum4c2="PR_1_0" data-ddnwab="PR_1_0" aria-invalid="grammar" class="Lm ng">Nabaj Yoti</span> Barkakati</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>2</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Data Mining</span></p>
                                                </td>
                                                <td >
                                                    <p ><span><span zeum4c2="PR_2_0" data-ddnwab="PR_2_0" aria-invalid="spelling" class="LI ng">Vikra</span> Pudi P</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>3</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            Introduction to Automata <span zeum4c2="PR_3_0" data-ddnwab="PR_3_0" aria-invalid="spelling" class="LI ng">Thoery</span>
                                                            <span zeum4c2="PR_4_0" data-ddnwab="PR_4_0" aria-invalid="spelling" class="LI ng">Lanaguages</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>John E</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>4</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Computer Networks 4 th ed</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Andrew S Tan</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>5</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            Foundations of information
                                                            Technology
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>D S Yadav</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>6</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Web Programming Building </span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Chris Bates</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>7</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            Software Engineering <span zeum4c2="PR_5_0" data-ddnwab="PR_5_0" aria-invalid="spelling" class="LI ng">of Practioars</span>
                                                            Approach
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>Roger S Pressman</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>8</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Object Oriented Programming inC++</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Robert</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>9</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            Computer Programming and Data
                                                            Structure
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>E <span zeum4c2="PR_6_0" data-ddnwab="PR_6_0" aria-invalid="spelling" class="LI ng">Balagurma</span></span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>10</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Automata and Computability</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Detter C Kozen</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>11</span></p>
                                                </td>
                                                <td >
                                                    <p ><span><span zeum4c2="PR_7_0" data-ddnwab="PR_7_0" aria-invalid="spelling" class="LI ng">Thoery</span> of Computer Science</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>K L P Mishra</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>12</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            Managerial Economic and Financial
                                                            <span zeum4c2="PR_8_0" data-ddnwab="PR_8_0" aria-invalid="spelling" class="LI ng">Analaysis</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>A Ramachandra Aryasri</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>13</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>An introduction to web Design</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Paul S</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>14</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>A <span zeum4c2="PR_9_0" data-ddnwab="PR_9_0" aria-invalid="grammar" class="Lm ng">Txt book</span> of Engineering <span zeum4c2="PR_10_0" data-ddnwab="PR_10_0" aria-invalid="spelling" class="LI ng">Mechanis</span></span></p>
                                                </td>
                                                <td >
                                                    <p ><span>K S Khurmi</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>15</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Let Us C</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Yasvant Kanetkar</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>2</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>16</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Computer Algorithms</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Ellis Horowitz</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>17</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Computer Programming Data Structure</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>E <span zeum4c2="PR_11_0" data-ddnwab="PR_11_0" aria-invalid="spelling" class="LI ng">Balagurma</span></span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>18</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Programming with C++</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>D <span zeum4c2="PR_12_0" data-ddnwab="PR_12_0" aria-invalid="spelling" class="LI ng">ravichanran</span></span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>19</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            Highschool ENGlish <span zeum4c2="PR_13_0" data-ddnwab="PR_13_0" aria-invalid="spelling" class="LI ng">grammer</span> and
                                                            composition
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>PC Wareo, H Marthin&nbsp; </span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>20</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            Highschool ENGlish <span zeum4c2="PR_14_0" data-ddnwab="PR_14_0" aria-invalid="spelling" class="LI ng">grammer</span> and
                                                            composition
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>PC Wareo, H Marthin&nbsp; </span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>21</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>An Introduction to Java Programming</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Y Daniel Liang</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>22</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>An Introduction to Java Programming</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Y Daniel Liang</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>23</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Programming in Java</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>E Balaguruswamy</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>2</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>24</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>C &amp; Data <span zeum4c2="PR_15_0" data-ddnwab="PR_15_0" aria-invalid="spelling" class="LI ng">Stractures</span></span></p>
                                                </td>
                                                <td >
                                                    <p ><span>P Padmanabhan</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>54</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>25</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Programming in CANSI </span></p>
                                                </td>
                                                <td >
                                                    <p ><span>E Balaguruswamy</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>20</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>26</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            DataStructures A Pseudocode <span zeum4c2="PR_16_0" data-ddnwab="PR_16_0" aria-invalid="spelling" class="LI ng">Approch</span>
                                                            with C++
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>Richard&nbsp; F Gilberg</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>20</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>27</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Data <span zeum4c2="PR_17_0" data-ddnwab="PR_17_0" aria-invalid="spelling" class="LI ng">Struture</span> Pseu Applications </span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Richard&nbsp; F Gilberg</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>15</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>28</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>C Programming &amp; Data Structure</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Behrouz A Forouzan</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>21</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>29</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Let Us C</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Yashavant P Kanetkar</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>16</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>30</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Programming in C</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Pradip Dey Manas</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>12</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>31</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Java Programming with Corba</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Gerald Broase </span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>18</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>32</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            Problem Solvingand Programming
                                                            DesignC
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>Jeri R Hanly Elliot</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>20</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>33</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Fundamentals of Data <span zeum4c2="PR_18_0" data-ddnwab="PR_18_0" aria-invalid="spelling" class="LI ng">Strctures</span> </span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Ellis Horowitz</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>12</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>34</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>C and Data Structures </span></p>
                                                </td>
                                                <td >
                                                    <p ><span>E.Balagurusamy</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>26</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>35</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Programming to World Wide Web</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Robert W Sebesta</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>3</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>36</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Programming in C</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Stephen G Kochan</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>3</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>37</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Database Management Systems</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>raghu Ramakrishna</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>2</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>38</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Data Mining Techniques</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Arun K Pujari</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>2</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>39</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>The C Programming Languages</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Brain W Kernighan</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>25</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>40</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Introduction to Computers</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Peter Nortons</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>4</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>41</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            introduction to Information
                                                            Technology
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>ITL Educ.</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>12</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>42</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Programming with Ansi &amp; Turbo C</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Ashok N Kamthane</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>5</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>43</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Java the Complete Reference</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Herbert Schildt</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>4</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>44</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Basic Concepts of Information </span></p>
                                                </td>
                                                <td >
                                                    <p ><span>G Praveen Babu</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>9</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>45</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Programming in C</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Stephen G Kochan</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>46</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            data Structures Algorithms &amp;
                                                            Applications
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>Sartaj Sahni</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>3</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>47</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Programming with C++</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>K R Venugopal</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>8</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>48</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Let us C</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Yashavant P Kanetkar</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>13</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>49</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>C Programming and Data Structures</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>E Balagurusamy</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>14</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>50</span></p>
                                                </td>
                                                <td >
                                                    <p >
                                                        <span>
                                                            Computer Organization &amp;
                                                            <span zeum4c2="PR_19_0" data-ddnwab="PR_19_0" aria-invalid="spelling" class="LI ng">Architure</span>
                                                        </span>
                                                    </p>
                                                </td>
                                                <td >
                                                    <p ><span>William Stallings</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>51</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Introduction to Computers</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Peter Nortons</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>52</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Java The Complete Reference</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Herbert Schildt</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td>
                                                    <p align="center" ><span>53</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Artificial Intelligence</span></p>
                                                </td>
                                                <td >
                                                    <p ><span>Sartaj Kaushik</span></p>
                                                </td>
                                                <td >
                                                    <p align="center" ><span>1</span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CSELibrary
