import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const FirstYearOrientationDay2020 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> First Year Orientation Day 2020
                                    </h2>
                                    <div className='py-2'>
                                        <p>Sridevi Women's Engineering College conducted its Online Orientation Program for the Freshers and their parents on Dec 2nd, 2020. It was attended by 360 students and was successfully completed by lunch time. On this occasion, our Principal Dr. B. L.Malleswari gave a brief introduction of the college and the department HoD's gave an introduction of their respective departments. The Orientation program started with the lighting of the lamp and Ganesh Vandana song and ended with our National Anthem.
                                        </p>
                                    </div>
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                        <div class="row">
                                           
                                           <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/2. Fresher's Orientation Day 2020- Anchors.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/2. Fresher's Orientation Day 2020- Anchors.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                           <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/3. Fresher's Orientation Day 2020 -Principal Address.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/3. Fresher's Orientation Day 2020 -Principal Address.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                           <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/4. Fresher's Orientation Day 2020 - Training and Placement dept ppt -Mrs Sandhya Mantha.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/4. Fresher's Orientation Day 2020 - Training and Placement dept ppt -Mrs Sandhya Mantha.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                           <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/5.First year Orientation Day 2020 - Exam dept -Mr. Rajesham.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/5.First year Orientation Day 2020 - Exam dept -Mr. Rajesham.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                           <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/6. Fresher's Orientation Day 2020 - CSE dept ppt- Dr. A Gauthami Latha.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/6. Fresher's Orientation Day 2020 - CSE dept ppt- Dr. A Gauthami Latha.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                         
                                         
                                          <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/8.Fresher's Orientation Day 2020 - EEE dept ppt - Dr. D Padmavathi.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/8.Fresher's Orientation Day 2020 - EEE dept ppt - Dr. D Padmavathi.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                           
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/9.Fresher's Orientation Day 2020 -IT Dept ppt Mrs. M Kavita.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/9.Fresher's Orientation Day 2020 -IT Dept ppt Mrs. M Kavita.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                           
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/10.Fresher's Orientation Day 2020 - S&amp;H HoD address - Dr K.C. Ravikumar.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/10.Fresher's Orientation Day 2020 - S&amp;H HoD address - Dr K.C. Ravikumar.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                           
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/11.Fresher's Orientation Day 2020 - Coordinator Mrs. E Krishnaveni Reddy.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/11.Fresher's Orientation Day 2020 - Coordinator Mrs. E Krishnaveni Reddy.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                           
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/1.Fresher's Orientation Day 2020 -Dec 2nd Inauguration ceremony.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/1.Fresher's Orientation Day 2020 -Dec 2nd Inauguration ceremony.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                             <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/gallery/First Year Orientation Day 2020/7.Fresher's Orientation Day 2020 - ECE dept ppt - Dr. A Narmada.jpg">
                                                   <img src="/assets/img/gallery/First Year Orientation Day 2020/7.Fresher's Orientation Day 2020 - ECE dept ppt - Dr. A Narmada.jpg" alt="Image"  class="img-responsive center-block"
                                                   loading="lazy" />
                                               </a>
                                           </div>
                                         
                                           <div class="col-md-12 row">
                                               <p>&nbsp;</p>
                                           </div>
                                       </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default FirstYearOrientationDay2020
