import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'

import EEELeftTab from '../EEELinks/EEELeftTab';

const EEE = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>
                                    Department of Electrical and Electronics Engineering (EEE)
                                </h2>
                                <p>Electrical Engineering is one of the oldest branches from time immemorial. It is one of the fundamental branches of science and technology on which all other modern branches of engineering have evolved from. Electrical and Electronics engineers work in a variety of fields including power sector, machine design, renewable energy sector, biomedical engineering, aerospace industry, communications industry and information technology. The department of Electrical and Electronics engineering was started in 2001 with an intake of 60 students. The department has well established laboratories. The department has well qualified and dedicated faculty. The students of the department are regularly exposed to the latest trends in the field of electrical and electronics engineering through regular industrial visits, guest lectures and workshops etc.
                                </p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEE
