import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import AboutLeftTab from '../About/AboutLeftTab';
import './news.css'
import { Link } from 'react-router-dom';



const GiveranceCell = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-4 order-2 order-lg-1'>
                            <div className="additional-information">
                                <div className="list">
                                    <ul>
                                        <Link to="/news-and-events/news" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> News</li></Link>
                                        <Link to="/news-and-events/events" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users"></i> Events</li></Link>
                                        <Link to="/news-and-events/gallery" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i>Photo Gallery </li></Link>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2> News
                                </h2>
                                <div class="container">
                                    {/* <div class="admisssion-area bg-f4f6f9 ptb-50">
                                         <div class="admission-content">
                                            <div class="p-4 pl-lg-5">
                                                <h4>Dear students</h4>
                                                <p>Your safety is Our Priority. 100% Disinfection Sanitisation Fumigation Protocols under Covid-19 Management Guidelines Completed. Inch by Inch Covid-19 Free now.

                                                </p>
                                            </div>
                                            <div class="admission-image">
                                                <img src="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/7.jpg?updatedAt=1684752549594" alt="Image" />
                                                <div class="icon">
                                                    <a class="popup-youtube play-btn" href="https://youtu.be/HtTVLcdOLJY"><i class="ri-play-fill"></i></a>
                                                </div>
                                            </div>

                                        </div> 

                                    </div> */}
                                </div>
                                <div class="news-details ptb-50">
                                <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/national-youth-festival-2024">
                                                <h4>National Youth Festival 2024 
                                                </h4>
                                            </Link>
                                            <div class="date">
                                                <p>14th January, 2024</p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/national-youth-festival-2024">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/graduation-day-2023">
                                                <h4>Graduation Day
                                                </h4>
                                            </Link>
                                            <div class="date">
                                                <p>9th December, 2023</p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/graduation-day-2023">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/it-project-expo-prakalp">
                                                <h4>IT Project Expo PRAKALP 2023</h4>
                                            </Link>
                                            <div class="date">
                                                <p>8th December, 2023</p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/it-project-expo-prakalp">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/ece-project-expo-2023">
                                                <h4>ECE Project Expo 2023</h4>
                                            </Link>
                                            <div class="date">
                                                <p>8th December, 2023</p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/ece-project-expo-2023">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/ideathon-media-coverage">
                                                <h4>Ideathon - Media coverage
                                                </h4>
                                            </Link>
                                            <div class="date">
                                                <p>24th March, 2023</p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/ideathon-media-coverage">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/fest-srisamvidhan">
                                                <h4>Fest  SriSamvidhan
                                                </h4>
                                            </Link>
                                            <div class="date">
                                                <p><span>22<sup>nd</sup></span>
                                                    <span> July, 2022</span></p>
                                            </div>
                                            <div className='reply'>
                                                <Link to="/news-and-events/news/fest-srisamvidhan">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/naac-grade">
                                                <h4>NAAC Grade
                                                </h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>24<sup>th</sup></span>
                                                    <span> June, 2022</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/naac-grade">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/walk-in">
                                                <h4>Walk-in Interiview
                                                </h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>20<sup>th</sup></span>
                                                    <span> February, 2022</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/walk-in">
                                                    Read more
                                                </Link>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/womens-day-celebration-2021">
                                                <h4>Women's Day celebration 2021
                                                </h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>8<sup>th</sup></span>
                                                    <span> March, 2021</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/womens-day-celebration-2021">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/mou-texas-review">
                                                <h4>SWEC has signed a MOU with Texas Review
                                                </h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>9<sup>th</sup></span>
                                                    <span> December, 2020</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/mou-texas-review">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/news-coverage-v6">
                                                <h4>
                                                    Online Data Science, Machine Learning
                                                </h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>9<sup>th</sup></span>
                                                    <span> December, 2020</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/news-coverage-v6">
                                                    Read more
                                                </Link>

                                            </div>
                                            <p>News coverage in V6 channel about the Two Day AICTE sponsored Online International Conference on Data Science, Machine Learning and it's Applications</p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/iirs-certifcate">
                                                <h4>
                                                    IIRS Certifcate
                                                </h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>29<sup>th</sup></span>
                                                    <span> June, 2020</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/iirs-certifcate">
                                                    Read more
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/covid-19-circular">
                                                <h4>
                                                    JNTUH Covid 19 circular
                                                </h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>15<sup>th</sup></span>
                                                    <span> April, 2020</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/covid-19-circular">
                                                    Read more
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/she_team-anniversary">
                                                <h4>
                                                    SHE team 5th anniversary
                                                </h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/she_team-anniversary">
                                                    Read more
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/nss-news">
                                                <h4>NSS
                                                </h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/nss-news">
                                                    Read more

                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/freshers-day">
                                                <h4>Fresher’s Day</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>31<sup>st</sup></span><span> August, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/freshers-day">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Fresher’s Day was celebrated on August 31st 2019.</p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/national-sports-day-celebratio">
                                                <h4>National Sports Day celebration</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>29<sup>th</sup></span><span> August, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/national-sports-day-celebratio">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>National Sports day was celebrated by all the Sports Captains of the Basketball, Throw ball, Kho-Kho, Kabbadi and Volleyball.</p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/vidh-youth-2.0">
                                                <h4>VidhYouth 2.0 Poster release</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>29<sup>th</sup></span><span> August, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/vidh-youth-2.0">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>The Technical fest of SWEC, VidhYouth 2.0 is scheduled on Sept 23rd and 24th.</p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/nss-event">
                                                <h4>NSS Event - Sister’s for a Change – Gift a helmet</h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>29<sup>th</sup></span><span> August, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/nss-event">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Organized an event Sisters for a change – Gift a helmet under NSS.</p>

                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/graduation-day-2">
                                                <h4>Graduation Day</h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>10<sup>th</sup></span><span> August, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/graduation-day-2">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Organized an event Sisters for a change – Gift a helmet under NSS.</p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/first-year-orientation-day">
                                                <h4>First Year Orientation Day</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>10<sup>th</sup></span><span> August, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/first-year-orientation-day">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Organized an event Sisters for a change – Gift a helmet under NSS.</p>
                                        </div>
                                    </div>
                                    {/* <div class="comments">
                                        <div class="single-comments-box">
                                            <h4>First Year Orientation Day</h4>
                                            <div class="date">
                                                <p>
                                                    <span>2<sup>nd</sup></span><span> August, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <a href="#">Read more</a>
                                            </div>
                                            <p>Organized an event Sisters for a change – Gift a helmet under NSS.</p>
                                        </div>
                                    </div> */}
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/nba-certified-news">
                                                <h4>NBA Certified</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>24</span><span> March, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/nba-certified-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Sridevi Engineering College certified by NBA Board.</p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/pratiyogita2019-news">
                                                <h4>Pratiyogita2K19</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>24</span><span> March, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/pratiyogita2019-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Sridevi Engineering College conducted a event Pratiyogita2K19</p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/qubit-mgit-technical-fest-news">
                                                <h4>Qubit MGIT Technical fest</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>24</span><span> March, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/qubit-mgit-technical-fest-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Paper presentation in Qubit MGIT Technical fest</p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/sports-awards-2018-19">
                                                <h4>Sports Awards 2018-19</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span></span><span> March, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/sports-awards-2018-19">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>
                                                We are extremely proud of the achievements of our sports students who
                                                have brought laurels to our college.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/the-iq-princess-2019-news">
                                                <h4>The IQ Princess 2019</h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span></span><span> March, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/the-iq-princess-2019-news">
                                                    Read more
                                                </Link>

                                            </div>
                                            <p>Ms.Hyderabad-The IQ Princess 2019</p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/maithri-the-cultural-fest-news">
                                                <h4>Maithri (The Cultural Fest)</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>08</span><span> March, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/maithri-the-cultural-fest-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>MAITHRI 2K19 ( The Cultural Fest)</p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/maithri-2019-news">
                                                <h4>Maithri 2019</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>06</span><span> March, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/maithri-2019-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Maithri 2019 - Newspaper coverage</p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/sri-samvidhan-2019-news">
                                                <h4>Sri Samvidhan 2019</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>02</span><span> March, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/sri-samvidhan-2019-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>SWEC organized a management fest on March 2nd, 2019.</p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/jbrec-technical-fest-news">
                                                <h4>JBREC Technical Fest</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>27</span><span> Feb, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/jbrec-technical-fest-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>
                                                Paper Presentation in JBREC Technical Fest on the topic Ingestible
                                                Origami Robot.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/engineering-educators-awards-news">
                                                <h4>Engineering Educators Awards</h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>26</span><span>Feb, 2019</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/engineering-educators-awards-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>
                                                Trinity College Dublin and Education Matters presented the “ENGINEERING
                                                EDUCATORS AWARDS” on 26th February 2019 at the Taj Deccan Hotel, Synergy
                                                Banquette Hall.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/vidh-youth-news">
                                                <h4>VIDH YOUTH'18</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>02</span><span>Oct, 2018</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/vidh-youth-news">
                                                    Read more
                                                </Link>

                                            </div>
                                            <p>IGNITE.ILLUMINATE.</p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/college-day-news">
                                                <h4>College Day Celebrations at Sridevi Women’s Engineering College</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>16</span><span> Mar, 2018</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/college-day-news">
                                                    Read more
                                                </Link>
                                                <a href="#"></a>
                                            </div>
                                            <p>College Day Celebrations.</p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/sports-news">
                                                <h4>Cadets from Sridevi Women’s Engineering college selected</h4>
                                            </Link>
                                            <div class="date">
                                                <p>
                                                    <span>16</span><span> Mar, 2018</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/sports-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Only two girls were selected across AP and Telangana </p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <Link to="/news-and-events/news/sports-news">
                                                <h4>Sports participation and awards</h4>
                                            </Link>

                                            <div class="date">
                                                <p>
                                                    <span>16</span><span> Mar, 2018</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <Link to="/news-and-events/news/sports-news">
                                                    Read more
                                                </Link>
                                            </div>
                                            <p>Sports are helded in other colleges.</p>
                                        </div>
                                    </div>

                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <a target='_blank' href="/assets/documents/Advanced supply_Exams August_2017.pdf">
                                                <h4>JNTUH, Hyderabad</h4>
                                            </a>
                                            <div class="date">
                                                <p>
                                                    <span>07</span><span> AUG,2017</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <a target='_blank' href="/assets/documents/Advanced supply_Exams August_2017.pdf">Read more</a>
                                            </div>
                                            <p>
                                                Examination Branch –B.Tech I Year II Semester (R16 Regulations) & I Year
                                                (R15 Regulations) advanced supplementary exams August/September -2017
                                            </p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <a target='_blank' href="/assets/documents/Annexures.pdf">
                                                <h4>Promotion Annexures</h4>
                                            </a>

                                            <div class="date">
                                                <p>
                                                    <span>01</span><span> Aug,2017</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <a target='_blank' href="/assets/documents/Annexures.pdf">
                                                    Read more
                                                </a>
                                            </div>
                                            <p>B.Tech from I year to II year R16 promotion_Annexures</p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <h4>External Lab Exams</h4>
                                            <div class="date">
                                                <p>
                                                    <span>24</span><span> apr,2017</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <a href="javascipt:;">Read more</a>
                                            </div>
                                            <p>
                                                External Lab Exams will commence from 24-04-2017 ( including project viva
                                                for IV year students)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <h4>External theory Exams </h4>
                                            <div class="date">
                                                <p>
                                                    <span>18</span><span> Jan,2017</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <a href="javascipt:;">Read more</a>
                                            </div>
                                            <p>
                                                External theory exams for 3rd & 4th year students will be from 02-05-2017
                                                and for 2nd year students 03-05-2017
                                            </p>
                                        </div>
                                    </div>
                                    <div class="comments">
                                        <div class="single-comments-box">
                                            <h4>External theory Exams </h4>
                                            <div class="date">
                                                <p>
                                                    <span>21</span><span> May,2017</span>
                                                </p>
                                            </div>
                                            <div class="reply">
                                                <a href="javascipt:;">Read more</a>
                                            </div>
                                            <p>
                                                For I-year and II-sem students, external theory exams may commence from
                                                21-05-2017
                                            </p>
                                        </div>
                                    </div>





                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default GiveranceCell
