import React,{useEffect} from 'react'
import { Link } from "react-router-dom";

const DepartmentTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul className='text-start'>
                    <Link to="/dept/cse" onclick="scrollWin()"><li className='text-black'><i class="fa-sharp fa-solid fa-building"></i> <span>About the Department</span> </li></Link>
                    <Link to="/dept/cse/vision-mission" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user fa-lg"></i> <span>Vision, Mission & PEO’s </span></li></Link>
                    <Link to="/dept/cse/po-and-pso" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user fa-lg"></i> <span>PO and PSO’s </span> </li></Link>
                    <Link to="/dept/cse/hod-profile" onclick="scrollWin()"><li className='text-black'><i class="fa fa-user fa-lg"></i> <span>HOD Profile </span> </li></Link>
                    <Link to="/dept/cse/cse-faculty-list" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users fa-lg"></i> <span>Faculty List </span> </li></Link>
                    <Link to="/dept/cse/student-projects" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users fa-lg"></i> Students projects</li></Link>
                    <Link to="/dept/cse/industry-visits" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users fa-lg"></i>  Industry visits</li></Link>
                    <Link to="/dept/cse/student-development-programs" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Industry internship/summer trainings </span> </li></Link>
                    <Link to="/dept/cse/syllabus" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Syllabus</span> </li></Link>
                    <Link to="/dept/cse/placements" onclick="scrollWin()"><li className='text-black'><i class="fa fa-graduation-cap fa-lg"></i> <span>Placements & higher studies</span> </li></Link>
                    <Link to="/dept/cse/professional-bodies" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Professional bodies & activities</span> </li></Link>
                    <Link to="/dept/cse/magazines" onclick="scrollWin()"><li className='text-black'><i class="fa fa-envelope fa-lg"></i> <span>Magazines & Newsletters</span> </li></Link>
                    <Link to="/dept/cse/faculty-publications" onclick="scrollWin()"><li className='text-black'><i class="fa fa-users fa-lg"></i> <span>Faculty publications and achievements</span> </li></Link>
                    <Link to="/dept/cse/faculty-innovations" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span> Faculty innovations in teaching  & learning  </span> </li></Link>
                    <Link to="/dept/cse/workshops" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>FDP/STTPS/Workshops</span> </li></Link>
                    <Link to="/dept/cse/books-published" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i>  <span>Books Published</span></li></Link>
                    <Link to="/dept/cse/patent-publications" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i>  <span>Patent Publications </span></li></Link>
                    <Link to="/dept/cse/laboratory-projects" onclick="scrollWin()"><li className='text-black'><i class="fa fa-tasks fa-lg"></i> <span>R & D laboratory, projects & activities </span> </li></Link>
                    <Link to="/dept/cse/academic-sponsered" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Academic/sponsored research & consultancy</span> </li></Link>
                    <Link to="/dept/cse/bridge-course" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Bridge Course</span> </li></Link>
                    <Link to="/dept/cse/course-files" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Course Files </span> </li></Link>
                    <Link to="/dept/cse/laboratories" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Laboratories </span> </li></Link>
                    <Link to="/dept/cse/department-library" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Department library</span> </li></Link>
                    <Link to="/dept/cse/department-mous" onclick="scrollWin()"><li className='text-black'><i class="fa fa-book fa-lg"></i> <span>Department MOU’s</span> </li></Link>
                </ul>
            </div>
        </div>
    )
}

export default DepartmentTab
