import React,{useEffect} from 'react'
import { Link } from "react-router-dom";


const PlacementsLeftTab = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="additional-information">
            <div className="list">
                <ul>
                    <Link to="/training-and-placements" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> About TPD </li></Link>
                    <Link to="/training-and-placements/skill-enhancement" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Skill Enhancement 
                    </li></Link>
                    <Link to="/training-and-placements/2022-23" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2022-23
                    </li></Link>
                    <Link to="/training-and-placements/2021-22" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2021-22
                    </li></Link>
                    <Link to="/training-and-placements/2020-21" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2020-21
                    </li></Link>
                    <Link to="/training-and-placements/2019-20" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2019-20
                    </li></Link>
                    <Link to="/training-and-placements/2018-19" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2018-19  </li></Link>
                    <Link to="/training-and-placements/2017-18" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2017-18  </li></Link>
                    <Link to="/training-and-placements/2016-17" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2016-17
                    </li></Link>
                    <Link to="/training-and-placements/2015-16" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2015-16 </li></Link>
                    <Link to="/training-and-placements/2014-15" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2014-15 </li></Link>
                    <Link to="/training-and-placements/2013-14" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2013-14 </li></Link>
                    <Link to="/training-and-placements/2012-13" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2012-13 </li></Link>
                    {/* <Link to="" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Placements 2005-12 </li></Link> */}
                    <Link to="/training-and-placements/technical-training" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Technical Training
                    </li></Link>
                    <Link to="/training-and-placements/dept-wise-placements" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Dept Wise Placements
                    </li></Link>
                    <Link to="/training-and-placements/task" onclick="scrollWin()"><li className='text-black'><i class="fa fa-university"></i> Task </li></Link>
                </ul>
            </div>
        </div>

    )
}

export default PlacementsLeftTab
