import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import RandDLeftTab from './RandDLeftTab'


const ResearchGuide = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <RandDLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2 ">
                            <div className="how-to-apply">
                                <h2>
                                Updating soon...

                                </h2>
                              
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ResearchGuide


