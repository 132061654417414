import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import RandDLeftTab from './RandDLeftTab'
import main from './images/incubation-center2.png'
import one from './images/1.jpg'
import two from './images/2.jpg'
import three from './images/3.jpg'
import four from './images/4.jpg'
import five from './images/5.jpg'
import six from './images/6.jpg'



const InnovationIncubation = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <RandDLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2 ">
                            <div className="how-to-apply">
                                <h2>
                                    Innovation and Incubation
                                </h2>
                                <p>The College has established IOT centre in the academic year 2017 -18 in association with our Alumni Ms.N. Preeti Leela (02D21A0424, March 2002-2006), who works with Inentirety Innovations Pvt Ltd as IOT Lead. This also serves as the Industry Oriented lab where students learn the concepts and apply them.
                                </p>
                                <p>We have released SWEC IOT Newsletter on March 8th, 2018 on Women’s Day.
                                </p>
                            </div>
                            <h5 className='py-3'>Incubation centre & Business Innovation Park
                            </h5>
                            <p>SWEC-Incubation Centre & Business Innovation Park is a Technical skill development initiative supported by Sridevi Educational Society, Hyderabad. It aims to nurture technology and knowledge based entrepreneur’s right from ideological stage to starting a business venture. The Incubation Centre will focus on a right begin of start-ups.
                            </p>
                            <p>At SWEC, we observe that the students must develop intellectual talent and creativity to tackle the challenges of increasing competition, globalization & technological revolutions. Industry experienced faculty members mentor the students by imparting the necessary entrepreneurial skills which are vital ingredients in the process of developing them into young entrepreneurs. This process is enriched with sharing the practical experience of people who underwent long and tedious journey of success to become entrepreneurs, so that these aspirants could feel enthusiastic.

                            </p>
                            <p>The incubation center is aimed at transforming the students with vibrant ideas. The college supports the ideas of the students and help them to nourish those ideas into effective action plans, be with them during the process and provide the required support at any point of time which plays a vital role in Women Empowerment.

                            </p>
                            <p>SWEC as a part of Incubation center also established an IoT cell in the academic year 2014 -2015. This also serves as the Technical lab where students learn the concept, apply them and streamline their innovative ideas towards working models. Indeed these models further help in creating a business innovation park.
                            </p>

                            <div className='how-to-apply'>
                                <div class="apply-list">
                                    <h6 className='pt-3 pb-2'>Objectives:
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>To select and incubate potentially viable products.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To create physical infrastructure and support the systems necessary for business incubation activities.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>To create a network of researchers, mentors and entrepreneurs. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>To inculcate the spirit of innovation and enterprise among  students . </li>
                                        <li><i class="ri-checkbox-circle-line"></i>To promote entrepreneurship and create a nurturing ecosystem where fledgling enterprises with innovative ideas and solutions can flourish</li>
                                        <li><i class="ri-checkbox-circle-line"></i> To promote Innovation in research and business through industry-academia partnership</li>
                                    </ul>
                                </div>

                                <div>
                                    <div className='how-to-apply'>
                                        <div class="apply-list">
                                            <ul>
                                                <li><i class="ri-checkbox-circle-line"></i> Develop new products from innovative ideas</li>
                                                <li><i class="ri-checkbox-circle-line"></i> Make a prototype</li>
                                                <li><i class="ri-checkbox-circle-line"></i> Study the marketability of the products</li>
                                                <li><i class="ri-checkbox-circle-line"></i> Identify possible entrepreneurs</li>
                                                <li><i class="ri-checkbox-circle-line"></i> After entrepreneurs decision to invest, offer necessary help in setting up a startup</li>
                                                <li><i class="ri-checkbox-circle-line"></i> Transfer technical knowledge and managerial expertise </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                <h6 className='pt-3 pb-2'>Facilities in incubation center
                                </h6>
                                <p>The following facilities are available at the Incubation centre and are listed below.


                                </p>
                                <div class="apply-list">
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i> 	Sufficient area</li>
                                        <li><i class="ri-checkbox-circle-line"></i> High Speed systems with Internet Connectivity</li>
                                        <li><i class="ri-checkbox-circle-line"></i> 	Free access to labs and software </li>
                                        <li><i class="ri-checkbox-circle-line"></i> 	Power and water facility</li>
                                    </ul>

                                </div>

                                <div className='pt-4'>
                                    <div className='row'>
                                        <div className='col-12 pb-3'>
                                            <img src={main} className='img-fluid' loading='lazy' alt='Innovation' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img src={one} className='img-fluid' loading='lazy' alt='Innovation' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img src={two} className='img-fluid' loading='lazy' alt='Innovation' />
                                        </div>

                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img src={three} className='img-fluid' loading='lazy' alt='Innovation' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img src={four} className='img-fluid' loading='lazy' alt='Innovation' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img src={five} className='img-fluid' loading='lazy' alt='Innovation' />
                                        </div>
                                        <div className='col-md-6 col-lg-4 pb-3'>
                                            <img src={six} className='img-fluid' loading='lazy' alt='Innovation' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default InnovationIncubation
