import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import FacilitiesLeftTab from './FacilitiesLeftTab';
import one from '../Facilities/ict/1.jfif'
import two from '../Facilities/ict/2.jfif'
import three from '../Facilities/ict/3.jfif'
import four from '../Facilities/ict/4.jfif'


const ICT = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <FacilitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>ICT</h2>

                                <div className='pt-3'>
                                    <h6>ICT Enabled Classrooms & Seminar Halls
                                    </h6>
                                    <p className='pt-1'>SWEC has huge and spacious, airy, ventilated, well-furnished and modernized classrooms and seminar halls which can comfortably accommodate sufficient number of students.
                                    </p>
                                    <p>SWEC has been using a diverse set of ICT tools to communicate, creates, disseminate, store and manage information. ICT enabled Classrooms and Seminar Halls are the places where the effective teaching learning takes place. Provision for usage of modern teaching tools and techniques is available in all classrooms which will enhance the skills and knowledge of the students.
                                    </p>
                                    <h6 className='pt-4 pb-3'>
                                        ICT Facilities in Class Rooms & Seminar Halls:
                                    </h6>
                                    <div class="apply-list">

                                        <ul class="">
                                            <li><i class="ri-checkbox-circle-line"></i>LCD Projector </li>
                                            <li><i class="ri-checkbox-circle-line"></i>Computer
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>Internet/LAN Facility
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>Wi-Fi Facility
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>Audio Facility
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i>Smart Board
                                            </li>

                                        </ul>
                                    </div>
                                    <div className='pt-4'>
                                        <h6 className='pb-3'>Smart Classroom:
                                        </h6>
                                        <div className='row'>
                                            <div className='col-md-6 col-lg-4'>
                                                <img src={one} />
                                            </div>
                                            <div className='col-md-6 col-lg-4'>
                                                <img src={two} />
                                            </div>
                                            <div className='col-md-6 col-lg-4'>
                                                <img src={three} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-4 pt-lg-5'>
                                        <h6 className='pb-3'>Seminar Hall:
                                        </h6>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <img src={four} className='img-fluid' alt='seminarHall' />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ICT
