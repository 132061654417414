import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import JntuhLefttab from './JntuhLefttab';

const Notifications = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-4 pe-lg-5'>
                            <JntuhLefttab />
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="how-to-apply">
                                <h2>Notifications
                                </h2>
                            </div>
                            <div className='pt-2 '> 
                                {/* <h6>Academic Calendar for B-Tech/B.Pharm 2021-22</h6> */}
                                <div class="detgails-right-content mt-1">
                                    <div class="related-download bg-gray">
                                        <ul>
                                            <li>
                                                <a href="/assets/documents/12302274472066267344447_Fee Circular 2 nd B.Tech 2020-21.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>Fee Circular 2nd B.Tech 2020-21  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/18348635799472097644042947_Fee Circular 2 nd MBA 2020-21.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>Fee Circular 2nd MBA 2020-21  </a>
                                            </li>

                                             <li>
                                                <a href="/assets/documents/171537597141206034078541_Fee Circular 3 rd B.Tech 2020-21.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Fee Circular 3rd B.Tech 2020-21  </a>
                                            </li>
                                             <li>
                                                <a href="/assets/documents/Fee Circular 4 th B.Tech 2020-21.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> Fee Circular 4th B.Tech 2020-21  </a>
                                            </li>
                                             <li>
                                                <a href="/assets/documents/BTech  I_ I _Reg_Supply_and_I Year supply Exams Dec_2019_Jan_2020.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i> BTech I_ I _Reg_Supply_and_I Year supply Exams Dec 2019 - Jan 2020  </a>
                                            </li>
                                             <li>
                                                <a href="/assets/documents/BTech_IV_I_R16_Mini project panels uploading Nov2019.doc" target="_blank"><i class="fa-solid fa-file-pdf"></i> BTech_IV_I_R16_Mini project panels uploading Nov 2019  </a>
                                            </li>
                                             <li>
                                                <a href="/assets/documents/MBA- I  Sem Regular Supply exams January-2020.doc" target="_blank"><i class="fa-solid fa-file-pdf"></i> MBA- I Sem Regular Supply exams January-2020 </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/Notification for  PG Courses Plagiarism Check and uploading of project panels.doc" target="_blank"><i class="fa-solid fa-file-pdf"></i>  Notification for PG Courses Plagiarism Check and uploading of project panels </a>
                                            </li>
                                             <li>
                                                <a href="/assets/documents/Revised dates for various activites related to B.Tech B.Pharm exams dec-2019.doc" target="_blank"><i class="fa-solid fa-file-pdf"></i>Revised dates for various activites related to B.Tech B.Pharm exams Dec 2019  </a>
                                            </li>
                                            <li>
                                                <a href="/assets/documents/Guidelines for Conducting External exams with COVID-19 Pandemic.pdf" target="_blank"><i class="fa-solid fa-file-pdf"></i>Guidelines for Conducting External Exams With Covid-19 Pandemic  </a>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Notifications
