import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import MbaLeftTab from './MbaLeftTab';
import p1 from './mbaImages/p1.png';
import p2 from './mbaImages/p2.jpg';
import p3 from './mbaImages/p3.png';
import p4 from './mbaImages/p4.png';
import p5 from './mbaImages/p5.jpg';
import p6 from './mbaImages/p5.png';
import p7 from './mbaImages/p6.png';
import p8 from './mbaImages/p7.jpg';
import p9 from './mbaImages/p8.png';
import p10 from './mbaImages/p9.png';
import p11 from './mbaImages/p10.png';
import p12 from './mbaImages/p11.png';
import p13 from './mbaImages/p12.png';
import p14 from './mbaImages/p13.jpg';
import p15 from './mbaImages/p14.jpg';
import p16 from './mbaImages/p15.png';



const MbaPlacements = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>placements
                                </h2>
                                <h6 className='py-3'>Placement Details For Academic Year 2019
                                </h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td><p><strong>S.no</strong></p></td>
                                                <td><p><strong>Company Names</strong></p></td>
                                                <td><p><strong>Package</strong></p></td>
                                                <td><p><strong>Company Logo</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td><strong>S &amp; P</strong></td>
                                                <td>3,54,979</td>
                                                <td><img src={p1} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td><strong>Facset</strong></td>
                                                <td>3,50,000</td>
                                                <td><img src={p2} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td><strong>Karvy</strong></td>
                                                <td>3,00,797</td>
                                                <td><img src={p3} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td><strong>Cognizant</strong></td>
                                                <td>2,95,000</td>
                                                <td><img src={p4} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td><strong>Bank of America</strong></td>
                                                <td>2,95,075</td>
                                                <td><img src={p5} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td><strong>HSBC</strong> </td>
                                                <td>2,84,785</td>
                                                <td><img src={p6}  alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td><strong>Advance Soft</strong> </td>
                                                <td>2,75,000</td>
                                                <td><img src={p7} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td><strong>Axeness</strong> </td>
                                                <td>2,75,000</td>
                                                <td><img src={p8} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td><strong>Junaati Technologies</strong> </td>
                                                <td>2,70,275</td>
                                                <td><img src={p9} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td><strong>Amazon</strong> </td>
                                                <td>2,70,000</td>
                                                <td><img src={p10} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td><strong>Careers Dream</strong> </td>
                                                <td>2,50,225</td>
                                                <td><img src={p11} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td><strong>Infosys</strong> </td>
                                                <td>2,40,000</td>
                                                <td><img src={p12} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td><strong>Axis Bank</strong> </td>
                                                <td>2,40,000</td>
                                                <td><img src={p13} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td><strong>Hdfc</strong> </td>
                                                <td>2,40,000</td>
                                                <td><img src={p14} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>15</td>
                                                <td><strong>Wells Fargo</strong> </td>
                                                <td>2,80,000</td>
                                                <td><img src={p15} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                            <tr>
                                                <td>16</td>
                                                <td><strong>UGH</strong> </td>
                                                <td>3,20,000</td>
                                                <td><img src={p16} alt="logo" class="img-responsive img-fluid"  loading="lazy"/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="table-responsive mt-3 mb-lg-5 mb-4">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td><p class="text-center"><strong>Year Wise </strong></p></td>
                                                <td><p><strong>2019</strong></p></td>
                                                <td><p><strong>2018</strong></p></td>
                                                <td><p><strong>2017</strong></p></td>
                                                <td><p><strong>2016</strong></p></td>
                                                <td><p><strong>2015</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td><p class="text-center"><strong>Finance&amp; HR</strong></p></td>
                                                <td>50% + in progress </td>
                                                <td>78% </td>
                                                <td>77% </td>
                                                <td>76% </td>
                                                <td>74% </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default MbaPlacements
