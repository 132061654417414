import React from 'react'
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import FacilitiesLeftTab from './FacilitiesLeftTab';


const CentralFacilities = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                           <FacilitiesLeftTab />
                        </div>

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Central Facilities 
                                </h2>

                                <div class="apply-list">

                                    <ul class="pt-4">
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Cafeteria -</strong> The cafeteria is spacious, airy and well ventilated. Students have choice of menu and beverages. The fresh juice stall is the most loved joint.
                                        </li>




                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Cafeteria - </strong>The cafeteria is spacious, airy and well ventilated. Students have choice of menu and beverages. The fresh juice stall is the most loved joint.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Gymnasium - </strong>– The gym is well equipped and helps students and faculty stay fit at time convenient to them.</li>
                                        <li>
                                            <strong>Health centre - </strong>The Health centre is well equipped to handle minor injuries and health issues. We have an <strong>ambulance</strong> (24X7) that caters to emergencies and serious injuries. A well qualified doctor is
                                            available at the college premises. <strong>First aid kits</strong> are available in all labs.
                                        </li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Incinerator - </strong>The College has an incinerator that can burn 3000 napkins a day and ensures that the waste is disposed in an eco friendly and hygienic manner.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>WiFi - </strong>The WI-FI facility is run by Pioneer with 120 Mbps speed.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Mineral water plant - </strong>The College provides clean and filtered water and has installed an RO plant on the terrace.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Auditorium - </strong>The Auditorium is well equipped and has a seating capacity of 800.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Conference Hall - </strong>The Conference has a seating capacity of 300 and has an inbuilt audio video facility.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Seminar Halls - </strong>There are three Seminar halls as well with 250 seating capacity and audio visual facility.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Central Store - </strong>The College has a small store that caters to the stationery needs of the students and faculty including reprographic facility.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Water Coolers - </strong>Every floor has a water cooler connected to the Mineral water plant.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Waiting Hall - </strong>There are 4 waiting halls available for students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Guest Room - </strong>The College has a guest room that allows guests to rest in the premises if required.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Power Back Up - </strong>The College has a Power Back Up facility that gives power back up to critical labs and systems.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Fire Extinguishers - </strong>Every floor and few labs have been provided with fire extinguishers for fire safety.</li>
                                        <li><i class="ri-checkbox-circle-line"></i> <strong>Printer and Copier - </strong>College has multiple printers and copiers available for smooth functioning of the departments.</li>




                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default CentralFacilities
