import React from 'react'
import Footer from '../../footer/Footer';
import Navbar from '../../navbar/Navbar';
import AboutLeftTab from '../../About/AboutLeftTab';


const VIDHYOUTH18 = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <AboutLeftTab />

                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="col-lg-12 ps-lg-4">
                                <div className="how-to-apply">
                                    <h2> VIDH YOUTH 18
                                    </h2>
                                    <div className='py-2'>
                                        <p>
                                            Vidh Youth was an initiative taken by the students of Sridevi Women's Engg College and was held on October 1st and 2nd 2018. They had planned and organized this technical fest completely with the support of the Management, Principal and Faculty. They had invited over 37 engineering colleges across Hyderabad and received an overwhelming response from the students.
                                        </p>
                                        <p>
                                            Vidh Youth aimed at igniting the spark within the youth that illuminates the world with their knowledge and innovation. The objective of the fest was to provide a platform to all the students to share their ideas and experience with each other. This fest has helped the students enhance their skills and build interpersonal relationships that is critical to having a successful career ahead.
                                        </p>
                                        <p>
                                            We sincerely believe that VIDH YOUTH'18 has contributed in recognizing and building the skills of student fraternity.
                                        </p>
                                    </div>

                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="spotlight-group">
                                        <div class="row">
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/vidh-youth/1.jpg">
                                                    <img src="/assets/img/vidh-youth/1.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/vidh-youth/2.jpg">
                                                    <img src="/assets/img/vidh-youth/2.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/vidh-youth/3.jpg">
                                                    <img src="/assets/img/vidh-youth/3.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/vidh-youth/4.jpg">
                                                    <img src="/assets/img/vidh-youth/4.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/vidh-youth/5.jpg">
                                                    <img src="/assets/img/vidh-youth/5.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/vidh-youth/6.jpg">
                                                    <img src="/assets/img/vidh-youth/6.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/vidh-youth/7.jpg">
                                                    <img src="/assets/img/vidh-youth/7.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/vidh-youth/8.jpg">
                                                    <img src="/assets/img/vidh-youth/8.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                            <div class="col-md-6 mb-3 mb-lg-4">
                                                <a class="spotlight" href="/assets/img/vidh-youth/9.jpg">
                                                    <img src="/assets/img/vidh-youth/9.jpg" alt="Image" class="img-responsive center-block" />
                                                </a>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default VIDHYOUTH18
