import React from 'react'
import PhotoVideo from '../photosAndVideo/PhotoVideo'
import { Link } from 'react-router-dom'

const Events = () => {
    return (
        <div>

            <div className="events-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <h2>Events</h2>
                    </div>
                    <div className="row justify-content-center">

                        <div className="col-lg-4 col-md-6"
                        >
                            <div className="single-events-card">
                                <div className="events-image">
                                    <Link to="/news-and-events/news/engineering-educators-awards-news">
                                        <img src="/assets/img/gallery/Education Matters/Education Matters - Our College received award for  Academic Excellence.jpg"
                                            alt="Image" width="100%" height="278" /></Link>
                                    <div className="date">
                                        <span>2018</span>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="events-content">
                                    <div className="admin">
                                    </div>
                                    <Link to="/news-and-events/news/engineering-educators-awards-news">
                                        <h3>Educators Awards</h3>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6"
                        >
                            <div className="single-events-card">
                                <div className="events-image">
                                    <Link to="/news-and-events/news/freshers-day">
                                        <img
                                            src="/assets/img/latest-events/Fresher's Day 2019.jpg"
                                            alt="Image" width="100%" height="278" /></Link>
                                    <div className="date">
                                        <span>2019</span>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="events-content">
                                    <div className="admin">
                                    </div>
                                    <Link to="/news-and-events/news/freshers-day">
                                        <h3> Fresher’s Day</h3>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6" >
                            <div className="single-events-card">
                                <div className="events-image">
                                    <Link to="/news-and-events/events/alumni-21-22">
                                        <img src="https://ik.imagekit.io/ckjdgz4vmo/srideviGallery/1.jpg?updatedAt=1702557594912" alt="Image" width="100%" height="278" /></Link>
                                    <div className="date">
                                        <span>2020-21</span>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="events-content">
                                    <div className="admin">
                                    </div>
                                    <Link to="/news-and-events/events/alumni-21-22">
                                        <h3>Alumni</h3>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="more-health-care text-center">
                        <p>
                            <Link to="/news-and-events/events" className="read-more-btn active" onclick="scrollWin()"> See all
                                Events<i className="flaticon-next"></i></Link>
                        </p>
                    </div>
                </div>
            </div>
            <PhotoVideo />
        </div>
    )
}

export default Events
