import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EceleftTab from './EceleftTab';

const EceLaboratoryProjects = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EceleftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>R and D Grants
                                </h2>
                                <div class="table-responsive">
                                    <table class="table table-info" >

                                        <tbody>
                                            <tr class="bg-gray" >
                                                <td width="782" nowrap="" colspan="9" valign="top" >
                                                    <p align="center" >
                                                        <b>
                                                            <span>
                                                                R&amp;D GRANTS ,ACADEMIC AS WELL AS
                                                                SPONSOR
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="top" >
                                                    <p ><b><span>S.No</span></b></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <b>
                                                            <span>
                                                                Name of the
                                                                Project/ Endowments, Chairs
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <b>
                                                            <span>
                                                                Name of the
                                                                Principal Investigator/Co-investigator
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <b>
                                                            <span>
                                                                Department of
                                                                Principal Investigator
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><b><span>Year of Award</span></b></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><b><span>Amount Sanctioned</span></b></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <b>
                                                            <span>
                                                                Duration of the
                                                                project
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <b>
                                                            <span>
                                                                Name of the Funding
                                                                Agency
                                                            </span>
                                                        </b>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><b><span>Type&nbsp; (Government/non-Government)</span></b></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>1.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span>
                                                            Real time implementation of advanced
                                                            control algorithm(s) on a laboratory scale plant
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Mr.K.Tulasi Ram Associate Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2018</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2,85,000</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>On going</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span>
                                                            under collaborative Research scheme
                                                            TEQIP-III
                                                        </span>
                                                    </p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>2.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span>
                                                            Rational Orthogonal Wavelet Theory
                                                            Applied to Multipath Communication / Quality Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Dr B.L Malleswari Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>2018 </span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>170000/-</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2.1 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>3.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span>
                                                            Dynamic Adaptive Streaming for
                                                            Multi-view Video Compression / Quality Circle Advanced
                                                        </span>
                                                    </p>
                                                    <p ><span>&nbsp;R &amp; D</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Dr.V.Balaji&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2018 </span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >150000/-</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>4.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Dynamic Infrastructure supported D2D
                                                            Communications / Quality Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr.P.Suneel Kumar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2018 </span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >140000/-</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2.4 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>5.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Energy Management Strategies Optimisation /
                                                            Quality Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr.Mohan Ram.K&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2018 </span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >130000/-</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.8 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>6.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span>
                                                            Multiple motion control system of robotic
                                                            car based on iot to produce cloud service
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr.P.Suneel Kumar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2018</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>51,300/-</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>1 year</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>7.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Network Traffic Control Service Plans&nbsp; / Quality Circle Advanced R &amp; D</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr.Inderjeeth Singh&nbsp; Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2018</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >80,000</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.2 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>8.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Molecular Information Carriers for Embedded
                                                            Communications / Quality Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr B.L Malleswari&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2018</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >90,000</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.4 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>9.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Simulation of High Electron Mobility Transistors
                                                            (HEMT) / Quality Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr.P.Suneel Kumar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2018</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >70,000</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.6 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top">
                                                    <p align="right" ><span>10.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>IOT based smart healthcare kit</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Dr.B.K.Madhavi Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2017</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>50,760/-</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>1 year</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="center" ><span>11.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Research on Maximum-Power-Point-Tracking (MPPT)
                                                            Techniques / Quality Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Mr.K.Tulasiram&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Associate Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2017</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >50,000</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.9 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top">
                                                    <p align="right" ><span>12.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Smart Crossing System Using IOT</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Dr.V.R.S.Rajesh Kumar Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2017</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>55,080/</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>1 year</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>13.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Printed Passive Components: Design / Quality
                                                            Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr B.L Malleswari&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2017</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1,10,000</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.1 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>14.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Enhanced Positioning assisted Resource Sharing /
                                                            Quality Circle Advanced R&amp;D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            &nbsp;Mr.Siva
                                                            Nagi Reddy Kalli&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            Assoc. Professor
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2016</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >85,000</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.2 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top">
                                                    <p align="right" ><span>15.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span>
                                                            Body And Fall Detection System With
                                                            Heart Rate
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Dr.V.Balaji&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2016</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>51,300/-</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>1 year</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>16.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Real-time Closed-loop Hand Stimulation / Quality
                                                            Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr B.L Malleswari&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2016</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1,85,000</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.2 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>17.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span>
                                                            Challenges and Opportunities of waste
                                                            management in IOT Enabled Smart Cities: A Survey
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Dr.E.Nagabhooshanam&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2016</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>55,080/-</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>1 year</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top">
                                                    <p align="right" ><span>18.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Child Safety Wearable Device</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Dr.Bhaskar Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2015</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>53,460/-</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>1 year</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top">
                                                    <p align="right" ><span>19.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span>
                                                            A Smart Switch to connect and disconnect
                                                            Electrical
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span>Dr.J.Kaliappan Professor</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2015</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>50,760/-</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>1 year</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>20.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Risk Analysis of Wireless Wearable Antenna /
                                                            Quality Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr B.L Malleswari&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2015</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >220000/-</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.6 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>21.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            Characteristics of Wireless capsule endoscopy
                                                            antennas / Quality Circle Advanced R &amp; D
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >Dr B.L Malleswari&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Professor</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2014</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >240000/-</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>22.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            QoS Evaluation for 3D Multi-View Video
                                                            Compression / AICTE, New Delhi
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            &nbsp;Mr.Siva
                                                            Nagi Reddy Kalli&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            Assoc. Professor
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >ECE</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >2014</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >150000/-</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p ><span >1.2 years</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                            <tr >
                                                <td nowrap="" valign="top" >
                                                    <p align="right" ><span>23.</span></p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span>
                                                            QoS Evaluation for 3D Multi-View
                                                            Video Compression / AICTE, New Delhi
                                                        </span>
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p >
                                                        <span >
                                                            &nbsp;Mr.Siva
                                                            Nagi Reddy Kalli&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            Assoc. Professor
                                                        </span>
                                                    </p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>ECE</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>2013</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>1,50,000/-</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>1.5 year</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>KREST</span></p>
                                                </td>
                                                <td nowrap="" valign="top" >
                                                    <p ><span>Non Government</span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default EceLaboratoryProjects
