import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import EEELeftTab from './EEELeftTab';
import tech from '../../TrainingAndPlacements/20-21/techm_logotechm_logotechm_logo.png'
import cyient from '../../TrainingAndPlacements/20-21/cyient.png'
import inentirety from './placements/inentirety.png'
import pyramid2 from './placements/pyramid2.png'
import prometric from './placements/prometric.png'
import csc from './placements/csc.png'
import talentio from './placements/talentio.png'
import valmiki from './placements/valmiki.png'
import coign from './placements/coign.png'
import kresttechnology from './placements/krest-technology.png'
import sos from './placements/sos.png'
import SKS from './placements/SKS.png'
import Central from './placements/Central.png'
import Physitechelectronics from './placements/Physitechelectronics.png'
import BSNL from './placements/BSNL.png'
import Proimagination from './placements/Proimagination.png'
import one from './placements/1.png'
import two from './placements/2.png'
import three from './placements/3.png'
import four from './placements/4.png'
import five from './placements/5.png'
import six from './placements/6.png'
import seven from './placements/7.png'
import eight from './placements/8.png'
import nine from './placements/9.jpg'
import ten from './placements/10.jpg'
import eleven from './placements/11.jpg'
import twelve from './placements/12.jpg'
import w1 from './workshop/w1.jpeg'
import w2 from './workshop/w2.jpg'
import w3 from './workshop/w3.jpg'
import w4 from './workshop/w4.jpg'
import w5 from './workshop/w5.jpg'








const EEEworkshops = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <EEELeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Workshop and Seminar
                                </h2>
                                <p>Number of workshops/seminars conducted on Intellectual Property Rights (IPR) and Industry-Academia Innovative practices during the last five years</p>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead class="bg-gray">
                                            <tr>
                                                <th width="65"><strong>S.No</strong></th>
                                                <th width="180"><strong>Name of the workshop/ seminar</strong></th>
                                                <th width="220"><strong>Date</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    Seminar on predictive machine vision
                                                </td>
                                                <td>12-02-2019</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>
                                                    Seminar on sensor technologies and systems
                                                </td>
                                                <td>03-06-2018</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>
                                                    Seminar on roll of industrial relations in manufacturing
                                                    sectors
                                                </td>
                                                <td>27-04-2018</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>
                                                    Excel your managerial skills
                                                </td>
                                                <td>30-08-2017 </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>
                                                    Seminar on emerging trends in embedded systems
                                                </td>
                                                <td>13-02-2017 </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>
                                                    One day seminar on Electric Traction
                                                </td>
                                                <td>28-01-2016</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>
                                                    Seminar on Mobile Handset Servicing
                                                </td>
                                                <td>26-08-2015</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>
                                                    seminar-Embedded system &amp; PLC
                                                </td>
                                                <td>02-09-2014 </td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>
                                                    Workshop on Network Simulator 2
                                                </td>
                                                <td> 07-08-2014 to 08-08-2014 </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h4 className='py-2'>Workshops Conducted</h4>
                                <div className='apply-list'>
                                    <h6 className='py-2'>2018-19 Academic Year</h6>
                                    <ul >
                                        <li><i class="ri-checkbox-circle-line"></i>"Industry Automation by using PLC and SCADA" by SKS Pvt. Ltd. during 20th June  to 30th June 2018 for III B. Tech. II-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"PYTHON" by Swechha Organization on 7th to 9th September 2018 for II B. Tech. I-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Internet of Things" by InEntirety Pvt. Ltd. during 14th to 15th September  2018 for III B. Tech. I-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Application of MATLAB &amp; SIMULINK for Electrical Systems" in our college on 27th and 28th July 2018 for III B. Tech. II-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"PCB design" by Physitech Pvt. Ltd. on 25th and 26th July 2018 for II B. Tech. I-semester students. </li>
                                    </ul>
                                </div>

                                <div className='apply-list'>
                                    <h6 className='py-2'>2017-18 Academic Year </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>"PCB Design" by Swechha Organization on 7th August 2017 for II B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Industry Automation by using PLC" by SKS Pvt. Ltd. during 22nd to 27th May 2017 for III B. Tech. II-semester students.  </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Internet of Things" by InEntirety Pvt. Ltd. during 21st and 22nd September 2017 for III B. Tech. I-semester students. </li>
                                    </ul>
                                </div>

                                <div className='apply-list'>
                                    <h6 className='py-2'>2016-17 Academic Year </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>"Arduino" by Proimagination Pvt. Ltd. during 9th to 13th March 2017 for IV B. Tech. II-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"MATLAB Application to Power Electronics" in our college on 22nd and 23rd January 2017 for III B. Tech. II-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"PCB design" by Physitech Pvt. Ltd. on 25th and 26th July 2016 for II B. Tech. I-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Application of Multisim to Electrical Circuits" by Ascentronics Pvt. Ltd. on 1st and 2nd August 2016 for II B. Tech. I-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>A Three-day workshop on “Circuit Design and simulation of Electrical &amp; Electronics Circuits with Multisim &amp; MATLAB” on 14th,16th &amp; 17th September 2016.</li>
                                    </ul>
                                </div>

                                <div className='apply-list'>
                                    <h6 className='py-2'>2015-16 Academic Year
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>"Arduino" by Proimagination Pvt. Ltd. during 8th to 13th February 2016 for IV B. Tech. II-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>The Association of EEE department "DHRITAN" organized a “One Day Colloquium on Power Engineering” for 3rd and 4th year students of EEE on 30th September 2015 in association with IEEE Students Branch of SWEC.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>The student branch of "Sridevi Women’s Engineering College – SWECSB" has celebrated IEEE day on 4th October 2015. We have conducted "Group Discussion Contest" for the students of all Engineering disciplines. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>The student branch of "Sridevi Women’s Engineering College – SWECSB" organized technical paper and poster presentation contest "N’Kindle" on 22nd   and 29th Dec 2015.</li>
                                    </ul>
                                </div>

                                <h4 className='py-3'>Technical Programs
                                </h4>

                                <div className='apply-list'>
                                    <h6 className='py-2'>2017-18 Academic Year
                                    </h6>
                                    <ul >
                                        <li><i class="ri-checkbox-circle-line"></i>"Enhancing communication skills" Mrs. Merry Sushhela, Assoc. Prof., JBREC, Hyderabad 1st August 2017 for II B. Tech. I-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"6 phase Transmission" Dr. P.S. Subramanyam, Retd. Prof of EEE Dept. JNTUA, Hyderabad8th December 2017 for III B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Simulation of DC Machines" Mr. Ambati Giriprasad, Assoc. Prof., EEE Department. VNRVJIT, Hyderabad. 1st August 2017 for II B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Latest Technology in the Design of Insulators" Mr. B. Mahesh, ADE CPTI, Hyderabad31st January 2018 for III B. Tech. II-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Application of DCS to Power System Stability" Mr. K Dheeraj, Assoc. Prof., VJIT, Hyderabad10th August 2017 for IV B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Design of Synchronous Machines" by Mr. P Nishanth, Senior Engineer, BHEL on 08/08/2017 for for III &amp; IV  B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Application of Optimization Techniques to AGC" by Mrs. Esha Mittal, Asst. Prof. of EEE, on 10th October 2017 for IV B. Tech. I-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Attenuators" by Mrs. B Swetha, Asst. Prof. of EEE, on 7th March 2018 for II B. Tech. II-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Measurement of High Resistance using Megger" by Mrs. D. Swathi, Asst. Prof. of EEE, on 9th October 2017 for III B. Tech. I-semester students. </li>
                                    </ul>
                                </div>
                                <div className='apply-list'>
                                    <h6 className='py-2'>2016-17 Academic Year </h6>
                                    <ul >
                                        <li><i class="ri-checkbox-circle-line"></i>"Selection of DC machines for specific application" Dr. MD Agarwal, Retd. AGM, BHEL, Hyderabad. 21st July 2016 for II B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Power Quality Issues" Mrs. K. S. Mani, Assoc. Prof., Department of EEE, ACE Engineering College, Hyderabad. 10th January 2017 for III B. Tech. II-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Brushless Alternators" Dr. P. M Sarma, Prof. of EEE Department, IARE, Hyderabad. 27th September 2016 for III B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Practical Aspects of Traction" Dr. G Sreelaxmi, Assoc. Prof., EEE Department, CVRCE, Hyderabad. 30th September 2016 for IV B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Deregulated Power Systems" Mr. K Ramakrishna, Assoc. Prof., EEE Department, BVRIT, Hyderabad 26th July 2016 for IV B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Modelling of Power System Components" Dr. P.V. Subramanyam on 01st March 2017 for III &amp; IV B.Tech. II-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Corporate Governance and Social Responsibility" by Dr. Padmavathi, Prof. of MBA,  on 14th July 2016 for III B. Tech. I-semester students. </li>
                                    </ul>
                                </div>
                                <div className='apply-list'>
                                    <h6 className='py-2'>2015-16 Academic Year
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>"Maintenance of DC Machines" by Mr. P Nishanth, Senior Engineer, BHEL on 15/7/2015 for II B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Brushless Alternators" Mr. R Suresh Babu, Associate Prof, EEE Department, JBIET on 12/10/2015 for III B. Tech. I-semester students. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Switching Mode Power Supply (SMPS)" Mr. S Shiva Prasad, Assoc. Prof., JBIET, Hyderabad29th July 2015 for III B. Tech. I-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Vector Control of Induction Motors" Mrs. K. S. Mani, Assoc. Prof., Department of EEE, ACE Engineering College, Hyderabad. 07th January 2016 for III B. Tech. II-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Overview of 400kV substation - switchgear and protection" Mr. T Sreedhar, ADE CPTI, Hyderabad 21/01/2016 IV B. Tech. II-semester students.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>"Load Flow Analysis using MATLAB" by Dr. D. Padmavathi, Prof. of EEE, on 21st January 2016 for III B. Tech. II-semester students. </li>
                                    </ul>
                                </div>
                                <div className='apply-list'>
                                    <h6 className='py-2'>Engineering Events </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>Industrial Visit to 132kV indoor substation for EEE IV yr students in association with Dritan on 18th Aug 2015</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Industrial Visit to 220KV Gachibowli Outdoor substation for EEE III yr students in association with Dritan on 6th October 2015.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Industrial Visit to 220KV Gachibowli Outdoor substation for EEE III yr students in association with Dritan on 26th July 2016.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>Industrial Visit to PETE Transformers for EEE II yr students in association with Dritan on 17th February 2018</li>
                                    </ul>

                                </div>
                                <div className='apply-list'>
                                    <h6 className='py-2'>Professional Societies
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>The Association of EEE department "DHRITAN" organized a "One Day Colloquium on Power Engineering" for 3rd and 4th year students of EEE on 30th September 2015 in association with IEEE Students Branch of SWEC.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>The student branch of "Sridevi Women’s Engineering College – SWECSB" has celebrated IEEE day on 4th October 2015. We have conducted "Group Discussion Contest" for the students of all Engineering disciplines. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>The student branch of "Sridevi Women’s Engineering College – SWECSB" organized technical paper and poster presentation contest "N'Kindle" on 22nd  and 29th Dec 2015.</li>
                                    </ul>
                                </div>
                                <div className='apply-list'>
                                    <h6 className='py-2'>Technical Cell Details
                                    </h6>
                                    <ul>
                                        <li><i class="ri-checkbox-circle-line"></i>The Association of EEE department "DHRITAN" organized a “One Day Colloquium on Power Engineering” for 3rd and 4th year students of EEE on 30th September 2015 in association with IEEE Students Branch of SWEC.</li>
                                        <li><i class="ri-checkbox-circle-line"></i>The student branch of "Sridevi Women’s Engineering College – SWECSB" has celebrated IEEE day on 4th October 2015. We have conducted "Group Discussion Contest" for the students of all Engineering disciplines. </li>
                                        <li><i class="ri-checkbox-circle-line"></i>The student branch of "Sridevi Women’s Engineering College – SWECSB" organized technical paper and poster presentation contest "N’Kindle" on 22nd   and 29th Dec 2015.</li>
                                    </ul>
                                </div>
                                <div className='py-3 submit'>
                                    <h4>MOU’S
                                    </h4>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mtop25">
                                            <tbody>
                                                <tr class="bg-gray">
                                                    <td><p class="text-center"><strong>S.No</strong></p></td>
                                                    <td><p><strong>MOUs  signed organization</strong></p></td>
                                                    <td><p><strong>Date</strong></p></td>
                                                    <td><p><strong>MOU Duration</strong></p></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td><img src={inentirety} alt="logo" class="img-responsive" /></td>
                                                    <td>March 2017</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td><img src={cyient} alt="logo" class="img-responsive" /></td>
                                                    <td>4th December 2017</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td><img src={pyramid2} alt="logo" class="img-responsive" /></td>
                                                    <td>24th July 2017</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td><img src={prometric} alt="logo" class="img-responsive" /></td>
                                                    <td>20th February 2017</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td><img src={csc} alt="logo" class="img-responsive" /></td>
                                                    <td>15th  July 2016 </td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td><img src={talentio} alt="logo" class="img-responsive" /></td>
                                                    <td>17th march 2016</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td><img src={tech} alt="logo" class="img-responsive" /></td>
                                                    <td>15th April 2015</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td><img src={valmiki} alt="logo" class="img-responsive" /></td>
                                                    <td>3rd April 2015</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td><img src={coign} alt="logo" class="img-responsive" /></td>
                                                    <td>15th February 2015</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>10</td>
                                                    <td><img src={kresttechnology} alt="logo" class="img-responsive" /></td>
                                                    <td>17th July 2015</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>11</td>
                                                    <td><img src={sos} alt="logo" class="img-responsive" /></td>
                                                    <td>17th August 2017</td>
                                                    <td>2 years</td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td><img src={SKS} alt="logo" class="img-responsive" /></td>
                                                    <td>21st Jan 2015</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>13</td>
                                                    <td><img src={Central} alt="logo" class="img-responsive" /></td>
                                                    <td>July 2015</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>14</td>
                                                    <td><img src={Physitechelectronics} alt="logo" class="img-responsive" /></td>
                                                    <td>16th Feb 2015</td>
                                                    <td>5 years</td>
                                                </tr>
                                                <tr>
                                                    <td>15</td>
                                                    <td><img src={BSNL} alt="logo" class="img-responsive" /></td>
                                                    <td>July 2014 </td>
                                                    <td>2 years</td>
                                                </tr>
                                                <tr>
                                                    <td>15</td>
                                                    <td><img src={Proimagination} alt="logo" class="img-responsive" /></td>
                                                    <td>July 2015 </td>
                                                    <td>3 years</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>









                                </div>

                                <div className='submit'>
                                    <h6 className='text-center pb-2'>Guest lecture by Dr. P.V. Balasubramanyam form CBIT on Modeling of Power Systems Components for IV &III B.Tech on march 1 st, 2017
                                    </h6>
                                    <div className='row'>
                                        <div className='col-md-6 pb-3'>
                                            <img src={one} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={two} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                    </div>

                                </div>
                                <div className='submit'>
                                    <h6 className='text-center pb-2'>
                                        Department organized Project expo 2k17 showcasing the In house Mini & Major Projects developed by the students on 27th April,2017
                                    </h6>
                                    <div className='row'>
                                        <div className='col-md-6 pb-3'>
                                            <img src={three} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={four} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                    </div>

                                </div>
                                <div className='submit'>
                                    <h6 className='text-center pb-2'>
                                        PLC training program by SOS institute for EEE IV yr from 10th july to 14th july 2017.

                                    </h6>
                                    <div className='row'>
                                        <div className='col-md-6 pb-3'>
                                            <img src={five} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={six} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                    </div>
                                </div>
                                <div className='submit'>
                                    <h6 className='text-center pb-2'>
                                        Industrial Visit to PETE TRANSFORMERS on FEB 17th 2018.

                                    </h6>
                                    <div className='row'>
                                        <div className='col-md-6 pb-3'>
                                            <img src={seven} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={eight} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                    </div>
                                </div>

                                <div className='submit'>
                                    <h6 className='text-center pb-2'>
                                        Workshop on Application of MATLAB and Simulink for electrical systems for EEE third year students July 27-28

                                    </h6>
                                    <div className='row'>
                                        <div className='col-md-6 pb-3'>
                                            <img src={nine} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={ten} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={eleven} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <img src={twelve} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                    </div>
                                </div>
                                <div className='submit'>
                                    <h4>FDP and WEBINAR</h4>
                                    <div class="detgails-right-content mt-4 submit">
                                        <div class="related-download bg-gray">
                                            <ul>
                                                <li>
                                                    <a href="/assets/documents/EEE_SWEC_STTP_PHASE I.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>EEE SWEC STTP PHASE1 NOVEMBER 2020  </a>
                                                </li>
                                                <li>
                                                    <a href="/assets/documents/STTP_Schedule.pdf" target='_blank'><i class="fa-solid fa-file-pdf"></i>  STTP SCHEDULE NOVEMBER 2020  </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h6>WEBINAR</h6>
                                    {/* <div className='row pt-2'>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w1} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w2} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w3} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w4} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w5} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                    </div> */}
                                    <div class="css-script-ads" >
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 mb-3 mb-lg-4">
                                            <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_02.jpg">
                                                <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_02.jpg" alt="Image" class="img-responsive center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 mb-3 mb-lg-4">
                                            <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_03.jpg">
                                                <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_03.jpg" alt="Image" class="img-responsive center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 mb-3 mb-lg-4">
                                            <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0001.jpg">
                                                <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0001.jpg" alt="Image" class="img-responsive center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 mb-3 mb-lg-4">
                                            <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0003.jpg">
                                                <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0003.jpg" alt="Image" class="img-responsive center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 mb-3 mb-lg-4">
                                            <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0002.jpg">
                                                <img src="/assets/img/gallery/FDP and WEBINARS/EEE WEBINAR_JULY2020_01_page-0002.jpg" alt="Image" class="img-responsive center-block" />
                                            </a>
                                        </div>
                                        <div class="col-md-12 row">
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                                <h6>FDP(Faculty Development Program)</h6>


                                <div class="row">
                                    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 mb-3 mb-lg-4">
                                        <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_02.jpg">
                                            <img src="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_02.jpg" alt="Image" class="img-responsive center-block" />
                                        </a>
                                    </div>
                                    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 mb-3 mb-lg-4">
                                        <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0003.jpg">
                                            <img src="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0003.jpg" alt="Image" class="img-responsive center-block" />
                                        </a>
                                    </div>
                                    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 mb-3 mb-lg-4">
                                        <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0002.jpg">
                                            <img src="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0002.jpg" alt="Image" class="img-responsive center-block" />
                                        </a>
                                    </div>
                                    <div class="col-md-6 col-lg-4 mb-3 mb-lg-4 mb-3 mb-lg-4">
                                        <a class="spotlight" href="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0001.jpg">
                                            <img src="/assets/img/gallery/FDP and WEBINARS/EEE FDP_JULY2020_01_page-0001.jpg" alt="Image" class="img-responsive center-block" />
                                        </a>
                                    </div>

                                    <div class="col-md-12 row">
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                                <div>

                                    {/* <div className='row pt-2'>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w1} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w2} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w3} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w4} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                        <div className='col-md-6 col-lg-4'>
                                            <img src={w5} alt='activities' className='img-fluid' loading='lazy' />
                                        </div>
                                    </div> */}


                                </div>








                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EEEworkshops
