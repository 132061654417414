import React from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer';
import MbaLeftTab from './MbaLeftTab';


const MbaInfrastructure = () => {
    return (
        <div>
            <Navbar />
            <div className="graduate-admission pt-lg-5 pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pe-lg-4 order-2 order-lg-1">
                            <MbaLeftTab />
                        </div>
                        <div className="col-lg-8 ps-lg-4 order-1 order-lg-2">
                            <div className="how-to-apply">
                                <h2>Department Library

                                </h2>
                                <p>The Department Library contains 20 Titles with 67 copies. The Department Library is periodically updated with latest titles with a sufficient number of copies as per the syllabus. In addition to the above, the central library has 937 book titles with 5527 volumes.</p>
                                <p>The Department Library contains 20 Titles with 67 copies. The Department Library is periodically updated with latest titles with a sufficient number of copies as per the syllabus. In addition to the above, the central library has 937 book titles with 5527 volumes.
                                </p>

                                <h4 className='pt-3 pb-2'>Infrastructure</h4>
                                <p>The Department has spacious classrooms with abundant ventilation and LCD Projector with screen for audio visual learning. The Department also has a computer lab with 60 systems connected to a server for taking care of lab related subjects such as Business Communications lab and Summer Internship lab and soon with requisite software and so on. The lab details are given below.</p>

                                <div class="table-responsive mb-4 mb-lg-5">
                                    <table class="table table-bordered mtop25">
                                        <tbody>
                                            <tr class="bg-gray">
                                                <td align="center" valign="top"><p align="center"><strong>S.NO</strong></p></td>
                                                <td align="center" valign="top"><p><strong>LAB NAME</strong></p></td>
                                                <td align="center" valign="top"><p><strong>AREA </strong></p></td>
                                                <td align="center" valign="top"><p><strong>EQUIPMENT</strong></p></td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top"><p align="center">1</p></td>
                                                <td align="center" valign="top"><p>MBA    LAB PG 004</p></td>
                                                <td align="center" valign="top"><p>160    sq mts</p></td>
                                                <td align="center" valign="top"><p>Remote    Desk Top(RDP)</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>




                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default MbaInfrastructure
